import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PrintContainer, PrintComponent } from "Utils/Print";
import ReporteNotaEnvio from "Utils/NotaEnvio";
class NotaEnvio extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDespacho(this.props.match.params.id);
    }

    render() {
        const { despacho, print_state, conf_mostrar_precio_impresion } =
            this.props;

        if (!despacho) {
            return (
                <div className="grid-container padding-20">
                    <h2>Nota de envío no encontrado</h2>
                    <Link className="btn btn-primary" to={"/traslados"}>
                        Regresar a Traslados
                    </Link>
                </div>
            );
        }
        return (
            <div className="col-md-12 mt">
                {print_state.flag && <PrintComponent />}
                <PrintContainer name="notaEnvio">
                    <ReporteNotaEnvio
                        despacho={despacho}
                        conf_mostrar_precio_impresion={
                            conf_mostrar_precio_impresion
                        }
                    />
                </PrintContainer>
                <div className="mt1 text-center">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.print("notaEnvio");
                            //printActions.print('notaEnvio')
                            // window.print();
                        }}
                        className="btn btn-secondary mt-lg"
                        type={"button"}
                    >
                        <em className="fa fa-print" /> Imprimir
                    </button>
                </div>
                <Link
                    className="btn btn-tertiary align-self-center m-1"
                    to="/traslados"
                >
                    <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                    Regresar a Traslados
                </Link>
            </div>
        );
    }
}

NotaEnvio.propTypes = {};

export default NotaEnvio;
