import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderNumber,
    RenderDateTime,
} from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";
import moment from "moment";

class Actual extends Component {
    static propTypes = {
        detalle: PropTypes.object.isRequired,
        anterior: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
        try {
            return llave.replace("_", " ");
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave, anterior, resaltar) {
        try {
            if (isObject(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(Number.isInteger(llave) ? llave: llave)}:</b>
                    {Object.keys(valor).sort().map((llave2) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[llave2];
                        }
                        catch (e) { }
                        return this.formatoDetalle(valor[llave2], llave2, comparacion, resaltar);
                    })}
                </div>)
            }
            if (isArray(valor)) {
                return (<div key={llave} className="d-flex flex-column p-2 ml-0 ml-sm-3">
                    <b style={{textTransform: "capitalize", flex: "1"}}>{this.formatoLlave(llave)}:</b>
                    {valor.map((llave2, index) => {
                        let comparacion = {};
                        try {
                            comparacion = anterior[llave2];
                        }
                        catch (e) { }
                        return this.formatoDetalle(llave2, index, comparacion, resaltar);
                    })}
                </div>)
            }
            if (!isNaN(valor) && !(valor === true) && !(valor === false)) {
                let decimales = this.props.decimales ? this.props.decimales : 2;
                let aplicar3Decimales = llave.toUpperCase().includes("COSTO")
                    ? true
                    : !!llave.toUpperCase().includes("PRECIO");
                const diferentes = valor !== anterior;
                console.log("valor", valor);
                console.log("anterior", anterior);
                console.log("diferentes", diferentes);
                return (
                    <div
                        key={llave}
                        className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${
                            diferentes && resaltar ? "text-danger" : ""
                        }`}
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>{valor}</div>
                    </div>
                );
            }
            if (valor === true) {
                const diferentes = valor !== anterior;
                return (
                    <div
                        key={llave}
                        className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${
                            diferentes && resaltar ? "text-danger" : ""
                        }`}
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>Sí</div>
                    </div>
                );
            }
            if (valor === false) {
                const diferentes = valor !== anterior;
                return (
                    <div
                        key={llave}
                        className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${
                            diferentes && resaltar ? "text-danger" : ""
                        }`}
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>No</div>
                    </div>
                );
            }
            let diferentes = false;
            try {
                diferentes = valor.toString() !== anterior.toString();
            } catch (e) {}
            // Se valida si el valor dado es una fecha o no, comparando el formato en el cual viene la cadena
            const isFecha = moment(
                valor,
                "DD/MM/YYYY HH:MM:ss",
                true
            ).isValid();
            if (isFecha) {
                return (
                    <div
                        key={llave}
                        className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${
                            diferentes && resaltar ? "text-danger" : ""
                        }`}
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>
                            <RenderDateTime value={valor} />
                        </div>
                    </div>
                );
            }
            return (
                <div
                    key={llave}
                    className={`d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end ${
                        diferentes && resaltar ? "text-danger" : ""
                    }`}
                >
                    <b style={{ textTransform: "capitalize", flex: "1" }}>
                        {this.formatoLlave(llave)}:
                    </b>
                    <div style={{ flex: "2" }}>{valor.toString()}</div>
                </div>
            );
        } catch (e) {
            return (
                <div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3">
                    <b style={{ textTransform: "capitalize", flex: "1" }}>
                        {this.formatoLlave(llave)}:
                    </b>
                    <div style={{ flex: "2" }} />
                </div>
            );
        }
    }

    render() {
        const { detalle, anterior } = this.props;
        console.log("main anterior", anterior);
        console.log("main detalle", detalle);
        const resaltar = !!anterior;

        return (
            <div className="d-flex flex-1 flex-column">
                <h3 className="align-self-center">
                    <strong>Estado actual</strong>
                </h3>
                {Object.keys(detalle).map((llave) => {
                    let comparacion = {};
                    // try en dado caso el anterior no tiene la llave
                    try {
                        comparacion = anterior[llave];
                        console.log("comparacion", comparacion);
                    } catch (e) {}

                    return this.formatoDetalle(
                        detalle[llave],
                        llave,
                        comparacion,
                        resaltar
                    );
                })}
            </div>
        );
    }
}

export default Actual;
