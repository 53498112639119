import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import { SubmissionError } from "redux-form";
import Recibo from "Utils/ReciboPagoOV";
import PrintContainer from "Utils/Print/PrintContainer";
import { PrintComponent } from "Utils/Print";
import IngresoPagoForm from "./IngresoPagoForm";

export default class PagoOV extends Component {
    static propTypes = {
        saldo_inicial: PropTypes.number.isRequired,
    };

    closeModalPago = () => {
        // this.props.setModalPagoOV(false);
        this.props.finalizarPagoOV();
    };

    submitPago = (values) => {
        this.props.registrarPagoOV();
    };

    render() {
        //  State
        const {
            saldo_inicial,
            saldo,
            total_pago,
            loader_pago,
            utiliza_fel,
            codigo_nota_credito,
            nota_credito_cargada,
            impresion_recibo,
            datos_pago,
            print_state,
        } = this.props;
        //  bind
        const {
            setCodigoNotaCredito,
            consultarNotaCredito,
            limpiarNotaCredito,
            print,
        } = this.props;

        return (
            <div className="row w-100">
                {!impresion_recibo ? (
                    <IngresoPagoForm
                        onSubmit={this.submitPago}
                        cancel={this.closeModalPago}
                        setCodigoNotaCredito={setCodigoNotaCredito}
                        consultarNotaCredito={consultarNotaCredito}
                        limpiarNotaCredito={limpiarNotaCredito}
                        total_pago={total_pago}
                        saldo_inicial={saldo_inicial}
                        saldo={saldo}
                        loader_pago={loader_pago}
                        utiliza_fel={utiliza_fel}
                        codigo_nota_credito={codigo_nota_credito}
                        nota_credito_cargada={nota_credito_cargada}
                    />
                ) : datos_pago ? (
                    <div className="w-100 d-flex justify-content-center">
                        {print_state.flag && <PrintComponent />}
                        <div className="d-flex flex-column align-items-center">
                            <h2 className="m-2">
                                <strong>¿Deseas imprimir el recibo?</strong>
                            </h2>
                            <div className="d-flex flex-column flex-sm-row m-2">
                                <button
                                    type="button"
                                    className="btn btn-tertiary m-1 align-self-center"
                                    onClick={this.closeModalPago}
                                >
                                    Finalizar
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        print("reciboPagoOV");
                                    }}
                                    className="btn btn-primary m-2"
                                    type={"button"}
                                >
                                    <em className="fa fa-print" /> Imprimir
                                </button>
                            </div>
                            <div className="container-sm-vista-previa-body w-100">
                                <div className="d-flex flex-row justify-content-center w100">
                                    <PrintContainer name="reciboPagoOV">
                                        <Recibo
                                            movimiento={datos_pago}
                                            utiliza_fel={utiliza_fel}
                                        />
                                    </PrintContainer>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-sm-row mt-2">
                                <button
                                    type="button"
                                    className="btn btn-tertiary m-1 align-self-center"
                                    onClick={this.closeModalPago}
                                >
                                    Finalizar
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        print("reciboPagoOV");
                                    }}
                                    className="btn btn-primary m-2"
                                    type={"button"}
                                >
                                    <em className="fa fa-print" /> Imprimir
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        );
    }
}
