import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
export default class Recibo extends Component {
    static propTypes = {
        utiliza_fel: PropTypes.bool.isRequired,
        movimiento: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const { movimiento, utiliza_fel } = this.props;
        const sucursal = movimiento ? movimiento.sucursal : {};
        const detalles = movimiento.detalles ? movimiento.detalles : [];

        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-16">
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {sucursal.datos_empresa
                                    ? sucursal.datos_empresa.nombre_comercial
                                    : ""}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {sucursal.datos_empresa
                                    ? sucursal.datos_empresa.nombre
                                    : ""}
                            </b>
                        </div>
                    )}
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}
                </div>
                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    <span>RECIBO DE CAJA</span>
                </div>
                <div
                    className="d-flex flex-row fnt-12 pb-05 mb-2"
                    tyle={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        <RenderDate value={movimiento.fecha} />
                    </div>
                    {movimiento.usuario ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Usuario</b>
                            {movimiento.usuario.first_name
                                ? movimiento.usuario.first_name
                                : "-----"}
                        </div>
                    ) : null}
                </div>
                <div
                    className="d-flex flex-column fnt-12 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-row">
                        <div className="flex2 d-flex flex-column">
                            <b>Nombre</b>
                            {movimiento.cliente
                                ? movimiento.cliente.nombre
                                    ? movimiento.cliente.nombre
                                    : "-----"
                                : "-----"}
                        </div>
                        <div className="flex1 d-flex flex-column">
                            <b>NIT</b>
                            {movimiento.cliente
                                ? movimiento.cliente.nit
                                    ? movimiento.cliente.nit
                                    : movimiento.cliente.nombre ==
                                      "Consumidor Final"
                                    ? "CF"
                                    : movimiento.cliente.nombre
                                    ? "CF"
                                    : "-----"
                                : "-----"}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between fnt-16">
                    <b className="flex-1">CONCEPTO</b>
                    <b>TOTAL</b>
                </div>

                {detalles.map((detalle, index) => {
                    return (
                        <div className="d-flex mb fnt-11" key={index}>
                            <div className="width100">
                                <div className="d-flex">
                                    <div className="d-flex flex-row flex-1">
                                        Orden de venta No. {detalle.no_orden}
                                    </div>
                                    <div className="d-flex flex-row">
                                        <RenderCurrency
                                            value={
                                                detalle.monto
                                                    ? detalle.monto
                                                    : 0
                                            }
                                            className={"mr-1"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}

                <div
                    className="pt-05 d-flex flex-row fnt-14"
                    style={{ borderTop: "dotted 1px" }}
                >
                    <b className="flex-1">TOTAL</b>
                    <b>
                        <RenderCurrency
                            value={movimiento.total}
                            className={"ml-auto"}
                        />
                    </b>
                </div>
                <div className="d-flex flex-row fnt-14 mt-2">
                    <span className="flex-1">EFECTIVO</span>
                    <RenderCurrency
                        value={movimiento.monto_efectivo}
                        className={"ml-auto"}
                    />
                </div>
                <div className="d-flex flex-row fnt-14">
                    <span className="flex-1">TARJETA</span>
                    <RenderCurrency
                        value={movimiento.monto_tarjeta}
                        className={"ml-auto"}
                    />
                </div>
                <div className="d-flex flex-row fnt-14">
                    <span className="flex-1">CHEQUE</span>
                    <RenderCurrency
                        value={movimiento.monto_cheque}
                        className={"ml-auto"}
                    />
                </div>
                <div className="d-flex flex-row fnt-14">
                    <span className="flex-1">DEPÓSITO</span>
                    <RenderCurrency
                        value={movimiento.monto_deposito}
                        className={"ml-auto"}
                    />
                </div>
                {utiliza_fel && (
                    <div className="d-flex flex-row fnt-14">
                        <span className="flex-1">NOTA DE CRÉDITO</span>
                        <RenderCurrency
                            value={movimiento.monto_nota_credito}
                            className={"ml-auto"}
                        />
                    </div>
                )}
                <div className="d-flex flex-row fnt-14">
                    <span className="flex-1">RETENCIÓN DE IVA</span>
                    <RenderCurrency
                        value={movimiento.monto_retencion_iva}
                        className={"ml-auto"}
                    />
                </div>
                <div
                    className="d-flex flex-row fnt-14 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    <span className="flex-1">EXENCIÓN DE IVA</span>
                    <RenderCurrency
                        value={movimiento.monto_exencion_iva}
                        className={"ml-auto"}
                    />
                </div>
            </div>
        );
    }
}
