import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import { tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReporteInv } from "../../../Utils/FiltrosReportes";
import { RenderDate } from "../../../Utils/renderField/renderReadField";

const ListadoArticulosVendidos = ({
    // state
    loader,
    data,
    estado_descarga,

    fecha_inicial,
    fecha_final,
    sucursal = null,
    familia = "",
    entrada = "",
    salida = "",
    subfamilia = "",

    usuario = null,
    sucursal_param = null,

    // bind
    descargarListado,
    aplicarFiltros,
    limpiarFiltros,

    setEntrada,
    setSalida,
    setFecha,
    setSucursal,
    setFamilia,
    setSubfamilia,

    page,
    listar,
    sortChange,
}) => {
    console.log("🚀 ~ file: ListadoArticulosVendidos.jsx:44 ~ data", data)
    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page: page,
    };

    useEffect(() => {
        limpiarFiltros();
        return () => {
            const unMount = true;
            limpiarFiltros(unMount);
        };
    }, []);

    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReporteInv
                        sucursal={sucursal}
                        familia={familia}
                        subfamilia={subfamilia}
                        fecha_inicial={fecha_inicial}
                        fecha_final={fecha_final}
                        usuario={usuario}
                        entrada={entrada}
                        salida={salida}
                        setEntrada={setEntrada}
                        setSalida={setSalida}
                        sucursal_param={sucursal_param}
                        setSucursal={setSucursal}
                        setFamilia={setFamilia}
                        setSubfamilia={setSubfamilia}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        aplicarFiltros={aplicarFiltros}
                        filtroArticulosVendidos
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="codigo_producto">
                                    Código
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="nombre_producto">
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="fecha_ultima_venta"
                                    dataFormat={cell => cell === "---" ? cell : <RenderDate value={cell} />}
                                >
                                    Ult. venta
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="fecha_ultima_compra"
                                    dataFormat={cell => cell === "---" ? cell : <RenderDate value={cell} />}
                                >
                                    Ult. compra
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="ultimo_proveedor">
                                    Ult. proveedor
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="saldo_inicial"
                                    dataAlign="right"
                                >
                                    Saldo inicial
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="cantidad_vendido"
                                    dataAlign="right"
                                >
                                    Cnt. vendido
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="ultima_cantidad_comprado"
                                    dataAlign="right"
                                >
                                    Ult cnt. compra
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="devolucion_cliente"
                                    dataAlign="right"
                                >
                                    Dev. cliente
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="devolucion_proveedor"
                                    dataAlign="right"
                                >
                                    Dev. proveedor
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="reajuste_positivo"
                                    dataAlign="right"
                                >
                                    Rejs. postivo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="reajuste_negativo"
                                    dataAlign="right"
                                >
                                    Rejs. negativo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="traslados"
                                    dataAlign="right"
                                >
                                    Traslados
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="recepcion_traslados"
                                    dataAlign="right"
                                >
                                    Recepciones
                                </TableHeaderColumn>

                                {/* <TableHeaderColumn
                                    dataField="id"
                                    dataFormat={(_cell, row) => {
                                        const total_entradas =
                                            row.cantidad_comprado +
                                            row.reajuste_positivo +
                                            row.devolucion_cliente +
                                            row.recepcion_traslados;
                                        const total_salidas =
                                            row.cantidad_vendido +
                                            row.devolucion_proveedor +
                                            row.traslados +
                                            row.reajuste_negativo;
                                        const saldo =
                                            total_entradas - total_salidas;
                                        return saldo;
                                    }}
                                    dataAlign="right"
                                >
                                    Saldo
                                </TableHeaderColumn> */}

                                <TableHeaderColumn
                                    dataField="existencia"
                                    dataAlign="right"
                                >
                                    Saldo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                ></TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoArticulosVendidos.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoArticulosVendidos;
