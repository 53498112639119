import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReportes } from "../../../Utils/FiltrosReportes";

const ListadoNotasCreditoProv = ({
    // state
    loader,
    data,
    fecha_vencimiento_inicial,
    fecha_vencimiento_final,
    estado_descarga,
    proveedor,
    vendedor,
    page,

    // bind
    setFecha,
    descargarListado,
    aplicarFiltros,
    setProveedor,
    setVendedor,
    limpiarFiltros,
    listar,
    sortChange,
}) => {
    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page: page,
    };

    useEffect(() => {
        limpiarFiltros();
        return () => {
            limpiarFiltros();
        };
    }, []);
    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        proveedor={proveedor}
                        vendedor={vendedor}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setProveedor={setProveedor}
                        setVendedor={setVendedor}
                        aplicarFiltros={aplicarFiltros}
                        filtroNotasCreditoProv
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    const esReporteRecibo = true;
                                    descargarListado(esReporteRecibo);
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha de devolución
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="usuario"
                                    dataFormat={(cell) => cell.first_name}
                                >
                                    Vendedor
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="cliente_proveedor"
                                    dataFormat={(cell) => cell.nombre}
                                >
                                    Proveedor
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo">
                                    Correlativo de NC
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="no_documento">
                                    Documento de Compra
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="total_productos">
                                    No. productos
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="total"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Total
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="10%"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return activeFormatter({
                                            ver: "/notas_de_credito_al_proveedor/documento",
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoNotasCreditoProv.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoNotasCreditoProv;
