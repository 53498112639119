import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { ToastStore } from "react-toasts";
import Swal from "sweetalert2";
import { api } from "api";

// ------------------------------------
// Constants
// ------------------------------------
export const TRANSPORTES = "TRANSPORTE_TRANSPORTES";
export const TRANSPORTE = "TRANSPORTE_TRANSPORTE";
export const PAGE = "TRANSPORTE_PAGE";
export const BUSCADOR = "TRANSPORTE_BUSCADOR";
export const SORT = "TRANSPORTE_SORT";
export const LOADER = "TRANSPORTE_LOADER";

export const constants = {};

const endpoint = "transportes";
// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const transportes = store.transportes;
        const { ordering, search } = transportes;
        api.get(endpoint, { page, ordering, search })
            .then((data) => {
                if (data) {
                    dispatch(setBancos(data));
                }
                dispatch(setPage(page));
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.transportes.page;
    dispatch(listar(page));
};

export const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data)
        .then((resp) => {
            ToastStore.success("Datos almacenados correctamente");
            dispatch(push("/transportes"));
        })
        .catch((err) => {
            const msj = err.detail
                ? err.detail
                : "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((resp) => {
            dispatch(setTransporteLeer(resp));
        })
        .catch((err) => {
            const msj = err.detail ? err.detail : "Ha ocurrido un error.";
            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data)
        .then((resp) => {
            ToastStore.success("Datos almacenados correctamente");
            dispatch(push("/transportes"));
        })
        .catch((err) => {
            const msj = err.detail
                ? err.detail
                : "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then((data) => {
            ToastStore.success("Registro eliminado.");
            dispatch(listar());
        })
        .catch((error) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique que no hay usuarios asignados a la sucursal y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setBancos = (data) => ({
    type: TRANSPORTES,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setTransporteLeer = (transportes) => ({
    type: TRANSPORTE,
    transportes,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    eliminar,
    crear,
    editar,
    leer,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [TRANSPORTES]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [TRANSPORTE]: (state, { transportes }) => {
        return {
            ...state,
            transportes,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    transportes: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
};

export default handleActions(reducers, initialState);
