import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";

const CuentasPorPagarTable = ({ data, total }) => {
    // prop de total, viene de la cuenta por pagar (componente padre)
    return (
        <div className="border-top">
            <div className="d-flex mt-3">
                <h4 className="m-0 font-weight-bold">
                    Listado de cuentas por pagar
                </h4>
                <div className="flex-1 d-flex align-items-center justify-content-end mb-3">
                    <h4 className="m-0 mr-2 mr-lg-4 font-weight-bold">
                        Total a pagar:
                    </h4>
                    <h4 className="m-0 font-weight-bold text-primary">
                        <RenderCurrency value={total} />
                    </h4>
                </div>
            </div>
            <div className="responsive-table">
                <BootstrapTable data={data} hover striped>
                    <TableHeaderColumn
                        dataField="fecha_venta"
                        dataFormat={(cell) => {
                            return cell;
                        }}
                        // dataSort
                    >
                        Fecha de venta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_vencimiento"
                        dataFormat={(cell, row) => {
                            return cell;
                        }}
                    >
                        Fecha de vencimiento
                    </TableHeaderColumn>

                    {/* <TableHeaderColumn
                        dataField="proveedor__nombre"
                        dataFormat={(cell, row) => row.numero_documento}
                    >
                        No. Documento
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                        dataField="ov_pendiente"
                        dataFormat={(cell, row) => {
                            if (row.ov_pendiente && row.ov_vencida) {
                                return (
                                    <span className="font-weight-bold text-danger">
                                        Vencido
                                    </span>
                                );
                            }
                            return (
                                <div>
                                    {!cell ? (
                                        <span className="font-weight-bold text-success">
                                            Finalizado
                                        </span>
                                    ) : (
                                        <span className="font-weight-bold txt-yellow">
                                            Pendiente
                                        </span>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Estado pago
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataFormat={(cell) => cell.nombre}
                    >
                        Cliente
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="vendedor"
                        dataFormat={(cell) => cell.first_name}
                    >
                        Vendedor
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataAlign="right"
                        dataField="total"
                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="right"
                        dataField="saldo"
                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                    >
                        Saldo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        width="0"
                    ></TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    );
};

export default CuentasPorPagarTable;
