import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDate } from "Utils/renderField/renderTableField";

const ProductosSubTabla = (row) => {
    return (
        <div className="border border-primary p-2 mt-2 mb-3 bg-blanco">
            <BootstrapTable data={row.detalles} remote={false}>
                <TableHeaderColumn
                    dataField="fecha_venta"
                    dataFormat={(cell) => <RenderDate fecha={cell} />}
                >
                    Fecha venta
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_cobro"
                    dataFormat={(cell) => <RenderDate fecha={cell} />}
                >
                    Fecha de cobro
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_vencimiento"
                    dataFormat={(cell) => <RenderDate fecha={cell} />}
                >
                    Fecha de vencimiento
                </TableHeaderColumn>

                <TableHeaderColumn dataField="dias_vencidos" dataSort={false}>
                    Dias vencidos
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="codigo_venta"
                    dataSort={false}
                    isKey
                >
                    Código venta
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="total"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Total cobrado
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="total_sin_iva"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Total sin iva
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="comision"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Comisiones
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default ProductosSubTabla;
