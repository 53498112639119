import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import useCrearEditar from "../../../../hooks/useCrearEditar";
import BancosForm from "./IngresoDocumentosForm";

const CrearActualizar = ({
    crear,
    leer,
    ingreso_documentos,
    editar,
    loader,
    match,
}) => {
    const { intitalData, titulo, verDato, editarDato, onSubmit } =
        useCrearEditar({
            crear,
            leer,
            item: ingreso_documentos,
            editar,
            match,
        });

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="">
                    <BancosForm
                        intitalData={intitalData}
                        onSubmit={onSubmit}
                        ver={verDato}
                        editar={editarDato}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CrearActualizar;
