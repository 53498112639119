import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { BootstrapTable } from "react-bootstrap-table";
import { Link } from "react-router-dom";
// import _ from "lodash";
// import Modal from "react-responsive-modal";
// import { Link } from "react-router-dom";
// import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
// import LoadMask from "Utils/LoadMask/LoadMask";
// import { icons } from "icons";
// import EntregaForm from "./OrdenOnlineEntregaForm";
// import PagoForm from "./PagoForm";
// import NotaOrdenOnlineForm from "./NotaForm";
// import AsignarSucursalForm from "./AsignarSucursalForm";
import Grid from "Utils/Grid";
import { formatoMonedaQuetzales } from "../../Utils/CamposComunes";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import MarcarEnviadoModal from "./MarcarEnviadoModal";
import CambiarCantidadModal from "./CambiarCantidadModal";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { setTipoCambio } from "../../../../redux/modules/cuenta_pagar/cuenta_pagar";
const DetalleOrdenOnline = ({
    data,
    loader,
    usuario,
    detalle,
    modal,
    modal_pago,
    modal_nota,
    data_nota,
    lista_sucursales,
    modal_asignacion,
    sucursal,
    match,
    cargarOrden,
    // selectedRow,
}) => {
    const [modalEnviado, setModalEnviado] = useState(false);
    const [modalCambiar, setModalCambiar] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setModalCambiar(true);
    };

    const cliente =
        detalle && detalle.cliente_proveedor ? detalle.cliente_proveedor : {};
    console.log("🚀 ~ file: DetalleOrdenOnline.jsx:50 ~ detalle", detalle)
    console.log("🚀 ~ file: DetalleOrdenOnline.jsx:49 ~ cliente", cliente)
    const cliente_nombre = cliente.nombre ? cliente.nombre : "";
    const recibido_por =
        detalle && detalle.recibido_por ? detalle.recibido_por : "N/A";
    const guia =
        detalle && detalle.guia
    const cliente_nit = cliente.nit ? cliente.nit : "CF";
    const cliente_telefono = cliente.telefono ? cliente.telefono : "--"
    const fecha_pedido =
        detalle && detalle.creado
            ? moment(detalle.creado).format("DD-MM-YYYY")
            : /// moment(detalle.creado, "YYYY-MM-DD")
            "---";
    const detalles_orden = detalle && detalle.detalles ? detalle.detalles : [];
    const estado_pedido =
        detalle && detalle.estado_pedido ? detalle.estado_pedido : "---";

    const metodo_envio =
        detalle && detalle.metodo_envio ? detalle.metodo_envio : "---";

    const nombre_sucursal =
        detalle && detalle.sucursal && detalle.sucursal.nombre
            ? detalle.sucursal.nombre
            : "---";

    const direccion_exacta =
        detalle && detalle.sucursal
            ? `${detalle.sucursal.direccion}, ${detalle.sucursal.municipio}, ${detalle.sucursal.departamento}`
            : "---";
    const transporte =
        detalle && detalle.transporte ? detalle.transporte : "---";
    const direccion = detalle && detalle.direccion ? detalle.direccion : "---";

    const es_recoger_en_sucursal = metodo_envio === "retirar_tienda";

    const subtota_detalles_orden =
        detalles_orden && detalles_orden.length > 0
            ? detalles_orden
                .map((item) => item.sub_total)
                .reduce((a, b) => a + b)
            : 0;

    const PROCESO = 10;
    const ENVIADO = 20;
    const ENTREGADO = 30;
    const CANCELADO = 40;
    const estado_pedido_key =
        detalle && detalle.estado_pedido_key ? detalle.estado_pedido_key : null;

    const es_proceso = estado_pedido_key === PROCESO;
    const es_enviado = estado_pedido_key === ENVIADO;
    const es_entregado = estado_pedido_key === ENTREGADO;
    const es_cancelado = estado_pedido_key === CANCELADO;

    // state
    // const {
    //     data,
    //     loader,
    //     usuario,
    //     detalle,
    //     modal,
    //     modal_pago,
    //     modal_nota,
    //     data_nota,
    //     lista_sucursales,
    //     modal_asignacion,
    //     sucursal,
    // } = this.props;

    // //  Bind

    // dataFormat={(cell) => {
    //     if (cell === "retirar_tienda")
    //         return "Retirar en tienda";
    //     if (cell === "enviar_direccion")
    //         return "Enviar a domicio";
    //     return "---";
    // }}

    useEffect(() => {
        const id_orden = match.params.id;
        cargarOrden(id_orden);
    }, []);

    return (
        <div className="mt-5">
            <MarcarEnviadoModal
                open={modalEnviado}
                es_proceso={es_proceso}
                es_enviado={es_enviado}
                onClose={() => setModalEnviado(false)}
            />

            <CambiarCantidadModal
                selectedRow={selectedRow}
                open={modalCambiar}
                onClose={() => setModalCambiar(false)}
            />

            {/* //**HEADER INFO PEDIDO */}
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="d-flex align-items-center">
                    <h4>Detalle Orden</h4>

                    <div className="ml-4">
                        <span className="font-weight-bold">Estado</span>
                        <span
                            className={`ml-2 ${es_proceso
                                ? "text-danger"
                                : es_enviado
                                    ? "text-warning"
                                    : es_entregado
                                        ? "text-success"
                                        : ""
                                }`}
                        >
                            {estado_pedido}
                        </span>
                    </div>
                </div>
                {!es_entregado && (
                    <button
                        className="btn btn-primary btn-sm px-4"
                        onClick={() => setModalEnviado(true)}
                    >
                        Marcar como {es_proceso ? "Enviado" : "Entregado"}
                    </button>
                )}

            </div>

            {/* CARDS DETALLE */}
            <div className="grid-container bg-blanco d-flex flex-column flex-md-row justify-content-between mt-3 py-4">
                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center border-right-2 mb-4 mb-md-0">
                    <strong>Cliente</strong>
                    <span>{cliente_nombre}</span>
                </div>

                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center border-right-2 mb-4 mb-md-0">
                    <strong>Teléfono</strong>
                    <span>{cliente_telefono}</span>
                </div>

                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center border-right-2 mb-4 mb-md-0">
                    <strong>NIT</strong>
                    <span>{cliente_nit}</span>
                </div>

                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center">
                    <strong>Fecha realizada</strong>
                    <span>{fecha_pedido}</span>
                </div>
            </div>

            {/* //**TRANSPORTE */}
            <h4 className="mt-5">
                {es_recoger_en_sucursal ? "Sucursal Recepcion" : "Transporte"}
            </h4>

            {/* CARDS DETALLE */}
            <div className="grid-container bg-blanco d-flex flex-column flex-md-row justify-content-between mt-3 py-4">
                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center border-right-2 mb-4 mb-md-0">
                    <strong>
                        {es_recoger_en_sucursal ? "Sucursal" : "Proveedor"}
                    </strong>
                    <span>
                        {es_recoger_en_sucursal ? nombre_sucursal : transporte}
                    </span>
                </div>

                {!es_recoger_en_sucursal && !es_proceso && (
                    <div className="d-flex flex-column align-items-start flex-2 px-0 px-md-4 border-right-2 mb-4 mb-md-0">
                        <strong>Guía de rastreo</strong>
                        <span>
                            {guia}
                        </span>
                    </div>
                )}

                <div className="d-flex flex-column align-items-start flex-2 px-0 px-md-4 border-right-2 mb-4 mb-md-0">
                    <strong>
                        Dirección de
                        {es_recoger_en_sucursal ? " la sucursal" : " entrega"}
                    </strong>
                    <span>
                        {es_recoger_en_sucursal ? direccion_exacta : direccion.direccion}
                    </span>
                </div>

                <div className="d-flex flex-column flex-1 align-items-start align-items-md-center">
                    <strong>Recibido por</strong>
                    <span>{recibido_por}</span>
                </div>
            </div>

            {/* //**PRODUCTOS */}
            <h4 className="mt-5">Productos</h4>
            <div className="grid-container bg-blanco d-flex justify-content-between mt-3 py-4">
                <LoadMask loading={loader} dark blur>
                    <BootstrapTable
                        data={loader ? [] : detalles_orden}
                        pagination={false}
                    >
                        <TableHeaderColumn
                            dataField="fraccion"
                            dataSort
                            dataFormat={(cell) => {
                                return cell.producto.codigo;
                            }}
                        >
                            Código
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="fraccion"
                            dataSort
                            dataFormat={(cell) => {
                                return cell.producto.nombre;
                            }}
                        >
                            Producto
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="precio"
                            dataSort
                            dataFormat={formatoMonedaQuetzales}
                        >
                            Costo
                        </TableHeaderColumn>

                        <TableHeaderColumn dataField="cantidad" dataSort>
                            Cantidad
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="sub_total"
                            dataSort
                            dataFormat={formatoMonedaQuetzales}
                            dataAlign="right"
                        >
                            Subtotal
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataFormat={(cell, row) => {
                                if (!es_enviado && !es_entregado) {
                                    return (
                                        <div>
                                            <button
                                                className="btn btn-primary btn-sm px-4"
                                                onClick={() => handleOpenModal(row)}
                                            >
                                                Cambiar cantidad
                                            </button>
                                        </div>
                                    );
                                }
                            }}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="id" width="0" isKey />
                    </BootstrapTable>

                    <div className="d-flex justify-content-between mt-3">
                        <h4 className="font-weight-bold">Costo total</h4>
                        <h4 className="font-weight-bold">
                            {formatoMonedaQuetzales(subtota_detalles_orden)}
                        </h4>
                    </div>
                </LoadMask>
            </div>

            <div className="d-flex felx-column flex-md-row justify-content-center align-items-center mt-4">
                <Link
                    to="/pedidos_en_linea"
                    className="btn btn-outline-primary btn-sm px-4 py-2"
                >
                    Volver
                </Link>

                {/* <button className="btn btn-primary btn-sm px-4 ml-3 py-2">
                    Imprimir documento
                </button> */}
            </div>
        </div>
    );
};

export default DetalleOrdenOnline;
