import React from "react";

import DatePicker from "Utils/DatePicker";
import moment from "moment";
import { TIPO_MOVIMIENTO_BODEGA } from "../../../../utility/variables";
import Select from "react-select/lib/Select";

import { Async } from "react-select";
import useAsyncSearch from "../../../hooks/useAsyncSearch";

const listado_clientes = [];
const listado_proveedores = [];
const listado_usuarios = [];

const CamposFiltros = ({
    tipo,
    fecha_inicial,
    fecha_final,
    cliente,
    proveedor,
    usuario,
    setFecha,
    setTipo,
    setCliente,
    setProveedor,
    setUsuario,
}) => {
    const devolucion_compra = 50;
    const devolucion_venta = 70;

    const { getOptions: getClientes } = useAsyncSearch(
        "clientes",
        listado_clientes
    );
    const { getOptions: getProveedores } = useAsyncSearch(
        "proveedores",
        listado_proveedores
    );
    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios
    );

    return (
        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
            <div className="flex-3">
                <label>Tipo Movimiento</label>
                <Select
                    placeholder="Seleccionar"
                    isClearable
                    className="form-control"
                    options={TIPO_MOVIMIENTO_BODEGA}
                    valueKey="value"
                    labelKey="label"
                    value={tipo}
                    onChange={(e) => {
                        if (e) {
                            return setTipo(e.value);
                        }
                        setTipo("");
                    }}
                />
            </div>

            <div className="flex-1"></div>
            <div className="flex-3">
                <label htmlFor="">Fecha inicial</label>
                <DatePicker
                    value={fecha_inicial}
                    maxDate={
                        new Date(
                            moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")
                        )
                    }
                    onChange={(date) => {
                        setFecha("Inicial", date);
                    }}
                />
            </div>

            <div className="flex-1"></div>
            <div className="flex-3">
                <label htmlFor="">Fecha final</label>
                <DatePicker
                    value={fecha_final}
                    minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                    onChange={(date) => {
                        setFecha("Final", date);
                    }}
                />
            </div>

            {tipo == devolucion_venta && (
                <React.Fragment>
                    <div className="flex-1"></div>
                    <div className="flex-3">
                        <label>Cliente</label>
                        <Async
                            className="form-control"
                            labelKey="nombre"
                            valueKey="id"
                            searchPromptText="Escriba para buscar"
                            loadOptions={getClientes}
                            placeholder={"Clientes"}
                            onChange={(e) => {
                                setCliente(e ? e.id : "");
                            }}
                            value={cliente}
                        />
                    </div>
                </React.Fragment>
            )}

            {tipo == devolucion_compra && (
                <React.Fragment>
                    <div className="flex-1"></div>
                    <div className="flex-3">
                        <label>Proveedor</label>
                        <Async
                            className="form-control"
                            labelKey="nombre"
                            valueKey="id"
                            searchPromptText="Escriba para buscar"
                            loadOptions={getProveedores}
                            placeholder={"Proveedores"}
                            onChange={(e) => {
                                setProveedor(e ? e.id : "");
                            }}
                            value={proveedor}
                        />
                    </div>
                </React.Fragment>
            )}
            <div className="flex-1"></div>
            <div className="flex-3">
                <label>Usuario</label>
                <Async
                    className="form-control"
                    labelKey="first_name"
                    valueKey="id"
                    searchPromptText="Escriba para buscar"
                    loadOptions={getUsuarios}
                    placeholder={"Usuarios"}
                    onChange={(e) => {
                        setUsuario(e ? e.id : "");
                    }}
                    value={usuario}
                />
            </div>
        </div>
    );
};

export default CamposFiltros;
