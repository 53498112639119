import ListaFamilias from "./ListarFamilias";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/familias/familias";
// redux/modules/usuarios/usuarios

const ms2p = (state) => {
    return {
        ...state.familias,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListaFamilias);
