import React, { Component } from "react";
import Cobro from "./Cobro";
import ValeForm from "./Vale";
import { icons } from "icons";
import Recibo from "Utils/Recibo";
import Factura from "Utils/Factura";
import PropTypes from "prop-types";
import ModalAyuda from "Utils/Ayuda";
import { ToastStore } from "react-toasts";
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask/LoadMask";
import DatosVenta from "./DatosVenta/DatosVenta";
import ReactToPrint from "react-to-print";
import { ListadoAccion } from "Utils/ListadoAccion";
import { ListadoCards } from "Utils/ListadoCards";
import ExistenciaSucursal from "./ExistenciaSucursal";
import TicketCotizacion from "Utils/TicketCotizacion";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { pago_total, pago_credito } from "utility/variables";
import ClienteCotizacionForm from "../../PuntoVenta/Mostrador/clienteForm";
import { DetalleVentaCotizacion } from "Utils/Venta/DetalleVentaCotizacion";
import { PrintContainer, PrintComponent } from "Utils/Print";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
    HeaderItem,
} from "react-dropdown-advanced";

import {
    formatoNombre,
    formatoExistencia,
    buscar,
    formatoProducto,
    formatoAvatar,
    formatoAvatarMovil,
    formatoMarca,
} from "Utils/CamposComunes";

import {
    formatoAcciones,
    formatoPrecio,
    formatoExistencias,
} from "./CamposVenta";
import _ from "lodash";

import "./Venta.css";
import MovimientoForm from "../../Cajas/Detalle/Movimiento";
import { validarConfDecimales } from "../../Utils/validaConfiguracionDecimal";

class Venta extends Component {
    static propTypes = {
        agregar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        pagar: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            modal_vale: false,
            modal_existencia: false,
            modalAyuda: false,
            modal_cliente: false,
            dropdownItems: [
                new ActionItem("cobrar_credito", "Cobrar créditos"),
                new ActionItem("recibir_devolucion", "Recibir devolución"),
                new ActionItem(
                    "buscar_productos_sucursales",
                    "Buscar producto en otra sucursal"
                ),
            ],
            dropdownItemsAyuda: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem("venta", "¿Cómo realizar una venta?"),
                new ActionItem("anular_venta", "¿Cómo anular una venta?"),
                new ActionItem(
                    "venta_credito",
                    "¿Cómo realizar una venta al crédito?"
                ),
                new ActionItem(
                    "canje_vale_devolucion",
                    "¿Cómo canjear un vale de devolución?"
                ),
                new ActionItem("cuenta_cobrar", "¿Cómo cobrar un crédito?"),
                new ActionItem("devolucion", "¿Cómo recibir una devolución?"),
            ],
            dropdownItemsAyudaCobro: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem(
                    "venta_credito",
                    "¿Cómo realizar una venta al crédito?"
                ),
            ],
            itemsImagenes: [],
            itemsCuentaCobrar: [
                {
                    src: icons.cuenta_cobrar_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.cuenta_cobrar_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.cuenta_cobrar_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.cuenta_cobrar_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.cuenta_cobrar_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.cuenta_cobrar_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.cuenta_cobrar_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.cuenta_cobrar_p8,
                    alt: "Paso 7",
                },
            ],
            itemsDevolucion: [
                {
                    src: icons.devolucion_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.devolucion_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.devolucion_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.devolucion_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.devolucion_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.devolucion_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.devolucion_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.devolucion_p8,
                    alt: "Paso 8",
                },
                {
                    src: icons.devolucion_p9,
                    alt: "Paso 9",
                },
                {
                    src: icons.devolucion_p10,
                    alt: "Paso 10",
                },
                {
                    src: icons.devolucion_p11,
                    alt: "Paso 11",
                },
            ],
            itemsAnularVenta: [
                {
                    src: icons.anular_venta_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.anular_venta_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.anular_venta_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.anular_venta_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.anular_venta_p5,
                    alt: "Paso 5",
                },
            ],
            itemsVenta: [
                {
                    src: icons.venta_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.venta_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.venta_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.venta_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.venta_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.venta_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.venta_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.venta_p8,
                    alt: "Paso 8",
                },
                {
                    src: icons.venta_p9,
                    alt: "Paso 9",
                },
                {
                    src: icons.venta_p10,
                    alt: "Paso 10",
                },
                {
                    src: icons.venta_p11,
                    alt: "Paso 11",
                },
                {
                    src: icons.venta_p12,
                    alt: "Paso 11",
                },
                {
                    src: icons.venta_p13,
                    alt: "Paso 11",
                },
                {
                    src: icons.venta_p14,
                    alt: "Paso 11",
                },
                {
                    src: icons.venta_p15,
                    alt: "Paso 11",
                },
                {
                    src: icons.venta_p16,
                    alt: "Paso 16",
                },
                {
                    src: icons.venta_p17,
                    alt: "Paso 17",
                },
                {
                    src: icons.venta_p18,
                    alt: "Paso 18",
                },
            ],
            itemsVentaCredito: [
                {
                    src: icons.venta_credito_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.venta_credito_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.venta_credito_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.venta_credito_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.venta_credito_p5,
                    alt: "Paso 5",
                },
            ],
            itemsValeDevolucion: [
                {
                    src: icons.canje_vale_devolucion_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.canje_vale_devolucion_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.canje_vale_devolucion_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.canje_vale_devolucion_p4,
                    alt: "Paso 4",
                },
            ],
            itemsAyuda: [
                { texto: "¿Cómo realizar una venta?", key: "venta" },
                { texto: "¿Cómo anular una venta?", key: "anular_venta" },
                {
                    texto: "¿Cómo realizar una venta al crédito?",
                    key: "venta_credito",
                },
                {
                    texto: "¿Cómo canjear un vale de devolución?",
                    key: "canje_vale_devolucion",
                },
                { texto: "¿Cómo cobrar un crédito?", key: "cuenta_cobrar" },
                { texto: "¿Cómo recibir una devolución?", key: "devolucion" },
            ],
            itemsAyudaCobro: [
                {
                    texto: "¿Cómo realizar una venta al crédito?",
                    key: "venta_credito",
                },
            ],
        };
        this.closeModal = this.closeModal.bind(this);
    }
    componentWillMount() {
        // this.props.setMovimiento({});
        // this.props.setSeleccionados([]);
        this.props.listar(this.props.page);
    }

    closeModal(data) {
        if (data.codigo) {
            this.props.agregarVale(data.codigo);
        }
        this.setState({ modal_vale: false });
    }

    closeModalExistencias = () => {
        this.setState({ modal_existencia: false });
    };

    openModalExistencias = () => {
        this.setState({ modal_existencia: true });
    };

    ingresarValeDescuento = () => {
        this.setState({ modal_vale: true });
    };

    onClickOtrasAcciones = (item) => {
        // bind
        const { pushRouter, utiliza_fel } = this.props;

        if (item.key === "cobrar_credito") {
            pushRouter("/cuentas_por_cobrar");
        } else if (item.key === "recibir_devolucion") {
            pushRouter("devolucion");
        } else if (item.key === "buscar_productos_sucursales") {
            this.openModalExistencias();
        }
    };

    // AYUDA
    onClickAyuda = (item) => {
        if (item.key === "cuenta_cobrar") {
            this.setState({
                itemsImagenes: this.state.itemsCuentaCobrar,
            });
            this.openModalAyuda();
        } else if (item.key === "devolucion") {
            this.setState({
                itemsImagenes: this.state.itemsDevolucion,
            });
            this.openModalAyuda();
        } else if (item.key === "anular_venta") {
            this.setState({
                itemsImagenes: this.state.itemsAnularVenta,
            });
            this.openModalAyuda();
        } else if (item.key === "venta") {
            this.setState({
                itemsImagenes: this.state.itemsVenta,
            });
            this.openModalAyuda();
        } else if (item.key === "venta_credito") {
            this.setState({
                itemsImagenes: this.state.itemsVentaCredito,
            });
            this.openModalAyuda();
        } else if (item.key === "canje_vale_devolucion") {
            this.setState({
                itemsImagenes: this.state.itemsValeDevolucion,
            });
            this.openModalAyuda();
        }
    };

    closeModalAyuda = () => {
        this.setState({ modalAyuda: false });
    };
    openModalAyuda = () => {
        this.setState({ modalAyuda: true });
    };

    openModalCliente = () => {
        if (this.props.seleccionados.length) {
            this.setState({ modal_cliente: true });
        } else {
            ToastStore.error("La cotización no tiene productos seleccionados.");
        }
    };

    closeModalCliente = (data) => {
        if (
            data.nit != "" &&
            data.nit != undefined &&
            data.nombre != "" &&
            data.nombre != undefined
        ) {
            if (this.props.cotizacion_cargada != null) {
                this.props.actualizarCotizacion(data);
            } else {
                this.props.enviarCotizacion(data);
            }
        }
        this.setState({ modal_cliente: false });
    };

    componentDidUpdate() {}

    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        )
            return true;
        return false;
    }

    render() {
        // state
        const {
            listar,
            sortChange,
            loader,
            data,
            page,
            total,
            formato_factura,
            factura_personalizada,
            ver_buscador,
            ver_cobro,
            tipo_pago,
            search,
            print_state,
            conf_venta_precio_editable,
        } = this.props;

        const {
            agregar,
            movimiento,
            venta_impresion,
            cambioBuscador,
            cambioCobro,
            utiliza_fel,
            conf_cotizacion,
            ver_impresion,
            venta_cotizacion,
            seleccionados,
            cotizacion_cargada,
        } = this.props;
        const { dropdownItems, itemsImagenes, modalAyuda, dropdownItemsAyuda } =
            this.state;
        const campoBuscar = buscar;
        // bind
        const {
            resetVenta,
            pushRouter,
            leerClienteCotizacion,
            finalizarCotizacion,
        } = this.props;

        const llavesListado = [
            {
                nombre: "img_cropped",
                sort: false,
                caption: "",
                format: formatoAvatar,
                align: "center",
                width: "80px",
            },
            {
                nombre: "producto__nombre",
                sort: true,
                caption: "Nombre",
                format: formatoNombre,
                align: "left",
            },
            {
                nombre: "producto__marca",
                sort: true,
                caption: "Marca",
                format: formatoMarca,
                align: "left",
                width: "15%",
            },
            {
                nombre: "precio",
                sort: false,
                format: formatoPrecio,
                caption: "Precio",
                align: "right",
                width: "15%",
            },
            {
                nombre: "inventario_calculado",
                sort: false,
                caption: "Existencias",
                format: formatoExistencias,
                align: "right",
                width: "13%",
            },
            {
                nombre: "acciones",
                sort: false,
                caption: "Acciones",
                format: formatoAcciones({ ...this.props, cambioBuscador }),
                align: "center",
                width: "15%",
            },
        ];

        return (
            <div className="form-group mt d-flex">
                {!ver_impresion ? (
                    <div className="w-100">
                        <div
                            className="slide-container row w-100"
                            style={{ margin: "0" }}
                        >
                            <div
                                className={`
                                   col-lg-12 mb-2`}
                            >
                                {/* region LISTADO DE PRODUCTOS*/}
                                {/* Vista Movil*/}
                                <div className="d-block d-lg-none">
                                    {!ver_cobro && (
                                        <React.Fragment>
                                            <ListadoCards
                                                buscar={buscar(this.props)}
                                                loader={loader}
                                                llaves={llavesListado}
                                                data={data}
                                                listar={listar}
                                                sortChange={sortChange}
                                                titulo={"Listado de Productos"}
                                                page={page}
                                                acciones={dropdownItems}
                                                onClickOtrasAcciones={
                                                    this.onClickOtrasAcciones
                                                }
                                                pushRouter={pushRouter}
                                                itemsAyuda={dropdownItemsAyuda}
                                                actionAyuda={this.onClickAyuda}
                                                agregar={agregar}
                                                cambioBuscador={cambioBuscador}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="d-none d-lg-block">
                                    {!ver_cobro && (
                                        <React.Fragment>
                                            <ListadoAccion
                                                buscar={buscar(this.props)}
                                                loader={loader}
                                                llaves={llavesListado}
                                                data={data}
                                                listar={listar}
                                                sortChange={sortChange}
                                                titulo={"Listado de Productos"}
                                                page={page}
                                                acciones={dropdownItems}
                                                onClickOtrasAcciones={
                                                    this.onClickOtrasAcciones
                                                }
                                                pushRouter={pushRouter}
                                                itemsAyuda={dropdownItemsAyuda}
                                                actionAyuda={this.onClickAyuda}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                                {/* endregion LISTADO DE PRODUCTOS*/}

                                {/* region COBRO */}
                                {ver_cobro && (
                                    <Cobro
                                        {...this.props}
                                        print_state={print_state}
                                        cambioCobro={cambioCobro}
                                        loader={loader}
                                        ingresarValeDescuento={
                                            this.ingresarValeDescuento
                                        }
                                    />
                                )}
                                {/* endregion COBRO */}
                            </div>
                            <div className={`col-md-12 `}>
                                <LoadMask loading={loader} light blur radius>
                                    {movimiento.sucursal !== undefined ? (
                                        <div className="w-100">
                                            {
                                                // () ? (
                                                /* region RECIBO VENTA */
                                                <div
                                                    className={`w100 container-vista-previa ${
                                                        factura_personalizada &&
                                                        tipo_pago === pago_total
                                                            ? "d-none"
                                                            : ""
                                                    }`}
                                                >
                                                    {print_state.flag && (
                                                        <PrintComponent />
                                                    )}
                                                    <div className="d-flex flex-row flex-xs-column justify-content-between align-items-center container-vista-previa-head">
                                                        <b
                                                            className="negro-dark2 pl-1"
                                                            style={{
                                                                fontSize:
                                                                    "23px",
                                                            }}
                                                        >
                                                            Vista previa
                                                        </b>
                                                        <div className="text-right flex1 mr-4">
                                                            {/*SE COMENTARON LAS SIGUIENTES LINEAS PARA NO
                                                            UTILIZAR LAS printActions, DESCOMENTAR EN CASO
                                                            DE QUERER UTILIZARLAS DE NUEVO
                                                        */
                                                            /*<button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (tipo_pago==pago_total)
                                                                    printActions.print('reciboVenta');
                                                                else
                                                                    printActions.print('reciboVentaCredito');
                                                            }}
                                                            className="btn btn-primary mt-lg"
                                                            type={'button'}
                                                        >
                                                            <em className="fa fa-print" /> Imprimir
                                                        </button>*/}
                                                            {tipo_pago ==
                                                                pago_credito && (
                                                                <button
                                                                    className="btn mr-2"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        this.props.print(
                                                                            "reciboVenta"
                                                                        );
                                                                    }}
                                                                >
                                                                    <em className="fa fa-print" />{" "}
                                                                    {factura_personalizada ||
                                                                    utiliza_fel ? (
                                                                        <span>
                                                                            Factura
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            Recibo
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            )}
                                                            <ReactToPrint
                                                                trigger={() => (
                                                                    <button
                                                                        className="btn btn-secondary mt-lg"
                                                                        type={
                                                                            "button"
                                                                        }
                                                                    >
                                                                        <em className="fa fa-print" />{" "}
                                                                        Imprimir
                                                                    </button>
                                                                )}
                                                                content={() => {
                                                                    if (
                                                                        tipo_pago ==
                                                                        pago_total
                                                                    )
                                                                        return this
                                                                            .reciboVenta;
                                                                    else
                                                                        return this
                                                                            .reciboVentaCredito;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="container-vista-previa-body">
                                                        {!factura_personalizada && (
                                                            <div
                                                                className={`d-flex flex-row justify-content-center w100 ${
                                                                    tipo_pago ==
                                                                    pago_credito
                                                                        ? "print-only"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <PrintContainer
                                                                    ref={(el) =>
                                                                        (this.reciboVenta =
                                                                            el)
                                                                    }
                                                                    name="reciboVenta"
                                                                >
                                                                    {venta_impresion && (
                                                                        <Recibo
                                                                            sucursal={
                                                                                movimiento.sucursal
                                                                            }
                                                                            movimiento={
                                                                                movimiento
                                                                            }
                                                                            usuario={
                                                                                movimiento
                                                                                    .usuario
                                                                                    .first_name
                                                                                    ? movimiento
                                                                                          .usuario
                                                                                          .first_name
                                                                                    : ""
                                                                            }
                                                                            footerText={
                                                                                "TOTAL"
                                                                            }
                                                                            tipo={
                                                                                "Venta"
                                                                            }
                                                                            utiliza_fel={
                                                                                utiliza_fel
                                                                            }
                                                                        />
                                                                    )}
                                                                </PrintContainer>
                                                            </div>
                                                        )}
                                                        {tipo_pago ==
                                                            pago_credito && (
                                                            <div className="d-flex flex-row justify-content-center w100">
                                                                <PrintContainer
                                                                    ref={(el) =>
                                                                        (this.reciboVentaCredito =
                                                                            el)
                                                                    }
                                                                    name="reciboVentaCredito"
                                                                >
                                                                    {venta_impresion && (
                                                                        <ReciboVentaCredito
                                                                            sucursal={
                                                                                movimiento.sucursal
                                                                            }
                                                                            movimiento={
                                                                                movimiento
                                                                            }
                                                                            utiliza_fel={
                                                                                utiliza_fel
                                                                            }
                                                                        />
                                                                    )}
                                                                </PrintContainer>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                /* endregion RECIBO VENTA */
                                                // ):null
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            {/* region DATOS/RESUMEN DE LA VENTA*/}
                                            {cotizacion_cargada ? (
                                                <DetalleVentaCotizacion
                                                    mostrar_alerta_inventario={
                                                        true
                                                    }
                                                    seleccionados={
                                                        seleccionados
                                                    }
                                                    cotizacion_cargada={
                                                        cotizacion_cargada
                                                    }
                                                    desplegarItem={
                                                        this.props.desplegarItem
                                                    }
                                                    quitar={this.props.quitar}
                                                    sumar={this.props.sumar}
                                                    restar={this.props.restar}
                                                    ingresoCantidad={
                                                        this.props
                                                            .ingresoCantidad
                                                    }
                                                    venta_permitir_descuento={
                                                        this.props
                                                            .venta_permitir_descuento
                                                    }
                                                    pais={this.props.pais}
                                                    cambiarDescuento={
                                                        this.props
                                                            .cambiarDescuento
                                                    }
                                                    cambiarPrecio={
                                                        this.props.cambiarPrecio
                                                    }
                                                    conf_porcentaje_precio={
                                                        this.props
                                                            .conf_porcentaje_precio
                                                    }
                                                />
                                            ) : (
                                                <React.Fragment>
                                                    Meow x2
                                                    <DatosVenta
                                                        {...this.props}
                                                    />
                                                </React.Fragment>
                                            )}
                                            {/* endregion DATOS/RESUMEN DE LA VENTA*/}

                                            {/* region SECCION DE MONTO TOTAL Y PAGO*/}
                                            <div
                                                className={`align-items-center mt0 padding-5 d-flex justify-content-around border-radius-top-squa border-radius-buttom-squa flex-column  ${
                                                    !this.isMobile()
                                                        ? "bg-blanco"
                                                        : ""
                                                } ${
                                                    this.isMobile() &&
                                                    seleccionados.length <= 0
                                                        ? "grid-container-mob"
                                                        : "bg-blanco"
                                                }`}
                                            >
                                                {!ver_cobro && (
                                                    <div className="padding-10 p-lg-0 d-flex flex-column flex-sm-row display-flex-width-100 justify-content-center d-lg-none d-xl-none">
                                                        <div
                                                            className={`${
                                                                this.isMobile() &&
                                                                seleccionados.length >
                                                                    0
                                                                    ? "buttons-venta"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className={`text-center flex1 mb-2 ${
                                                                    this
                                                                        .isMobile &&
                                                                    seleccionados.length >
                                                                        0
                                                                        ? "mr-1"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <button
                                                                    className={`${
                                                                        this
                                                                            .isMobile &&
                                                                        seleccionados.length <=
                                                                            0
                                                                            ? "btn btn-secondary btn-lg btn-block"
                                                                            : "btn btn-secondary btn-lg btn-block mb-1 fnt-sm-10"
                                                                    }`}
                                                                    type="button"
                                                                    onClick={() => {
                                                                        cambioBuscador();
                                                                    }}
                                                                >
                                                                    <em className="fa fa-plus fa-lg mr"></em>
                                                                    Agregar
                                                                    Producto
                                                                </button>
                                                            </div>
                                                            <br></br>
                                                            <div
                                                                className={`text-center flex1 mb-2 ${
                                                                    this
                                                                        .isMobile &&
                                                                    seleccionados.length >
                                                                        0
                                                                        ? ""
                                                                        : ""
                                                                }`}
                                                            >
                                                                <button
                                                                    className={`${
                                                                        this
                                                                            .isMobile &&
                                                                        seleccionados.length <=
                                                                            0
                                                                            ? "btn btn-outline-tertiary btn-lg btn-block"
                                                                            : "btn btn-outline-tertiary btn-lg btn-block mb-1 fnt-sm-10"
                                                                    }`}
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                    }}
                                                                >
                                                                    <span className="mr">
                                                                        Otras
                                                                        acciones
                                                                    </span>
                                                                    <em className="fa fa-caret-down"></em>
                                                                    {dropdownItems.length >
                                                                        0 && (
                                                                        <DropDownMenu
                                                                            items={
                                                                                dropdownItems
                                                                            }
                                                                            onClick={
                                                                                this
                                                                                    .onClickOtrasAcciones
                                                                            }
                                                                            direction={
                                                                                DropDownDirection.UpLeft
                                                                            }
                                                                            className="fnt-10"
                                                                        />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <div className="text-center flex1 mb-2">
                                                                    <Link className="btn btn-outline-naranja backgr-white fnt-sm-10" to="/cuentas_por_cobrar">
                                                                        <img className="icon-img"src={icons.pagos_naranja} alt="cuentas_cobrar" />
                                                                        Cuentas por cobrar
                                                                    </Link>
                                                                </div>
                                                                <div className="text-center flex1 mb-2">
                                                                    <Link className="btn btn-outline-primary fnt-sm-10" to="/devolucion">
                                                                        <img className="icon-img"src={icons.devolucion} alt="cuentas_cobrar" />
                                                                        Generar devolución
                                                                    </Link>
                                                                </div> */}
                                                    </div>
                                                )}
                                                {seleccionados.length > 0 && (
                                                    <div className="d-lg-none flex-row display-flex-width-100 padding-bottom-10 mt-2">
                                                        <h3 className="flex1 ml">
                                                            <b>Total</b>
                                                        </h3>
                                                        <h4 className="flex1 content-total mr-2 text-right">
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        total
                                                                    }
                                                                />
                                                            </b>
                                                        </h4>
                                                    </div>
                                                )}
                                                {
                                                    <div className="flex-row display-flex-width-100 padding-bottom-10 mt-2">
                                                        <h3 className="d-none d-lg-block flex1 ml">
                                                            <b>Total</b>
                                                        </h3>
                                                        <h4 className="d-none d-lg-block flex1 content-total mr-2 text-right">
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        total
                                                                    }
                                                                />
                                                            </b>
                                                        </h4>
                                                    </div>
                                                }
                                                {!ver_cobro &&
                                                    seleccionados.length >
                                                        0 && (
                                                        <div
                                                            className="d-lg-none col-12"
                                                            style={{
                                                                borderRadius:
                                                                    "3px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <div
                                                                className={`venta-btns ${
                                                                    ver_cobro
                                                                        ? "collapsed"
                                                                        : ""
                                                                } mt0 container-botones-venta d-flex align-items-center justify-content-center`}
                                                            >
                                                                <div className="flex1 d-flex flex-row align-items-center justify-content-center">
                                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                        <button
                                                                            className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center"
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                cambioCobro();
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className="m-0 mb-1"
                                                                                src={
                                                                                    icons.pagar
                                                                                }
                                                                            />
                                                                            Pagar
                                                                        </button>
                                                                    </div>
                                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                        <button
                                                                            className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                            onClick={() => {
                                                                                this.ingresarValeDescuento();
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    icons.canje_devolucion_azul
                                                                                }
                                                                            />
                                                                            <span>
                                                                                Canjear
                                                                                devolución
                                                                            </span>
                                                                        </button>
                                                                    </div>

                                                                    {conf_cotizacion && (
                                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                            <button
                                                                                className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                                type="submit"
                                                                                onClick={() => {
                                                                                    this.openModalCliente(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    className="m-0 mb-1"
                                                                                    src={
                                                                                        icons.guardar
                                                                                    }
                                                                                />
                                                                                {cotizacion_cargada !=
                                                                                null ? (
                                                                                    <span>
                                                                                        {" "}
                                                                                        Actualizar
                                                                                        Cotización{" "}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>
                                                                                        {" "}
                                                                                        Guardar
                                                                                        Cotización{" "}
                                                                                    </span>
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    {conf_cotizacion &&
                                                                        !seleccionados.length &&
                                                                        !cotizacion_cargada && (
                                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                                <button
                                                                                    className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                                    type="submit"
                                                                                    onClick={() => {
                                                                                        pushRouter(
                                                                                            "/cotizaciones"
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        className="m-0 mb-1"
                                                                                        src={
                                                                                            icons.abrir
                                                                                        }
                                                                                    />
                                                                                    <span>
                                                                                        Abrir
                                                                                        cotización
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    {conf_cotizacion &&
                                                                        (seleccionados.length ||
                                                                            cotizacion_cargada) && (
                                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                                <button
                                                                                    className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                                    onClick={() =>
                                                                                        resetVenta()
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            icons.anular_cotizacion
                                                                                        }
                                                                                    />
                                                                                    <span>
                                                                                        Cancelar
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    {!conf_cotizacion && (
                                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                            <button
                                                                                className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                                onClick={() =>
                                                                                    resetVenta()
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        icons.anular_cotizacion
                                                                                    }
                                                                                />
                                                                                <span>
                                                                                    Cancelar
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                {!ver_cobro && (
                                                    <div className="d-none d-lg-block">
                                                        <div
                                                            className={`venta-btns ${
                                                                ver_cobro
                                                                    ? "collapsed"
                                                                    : ""
                                                            } mt0 container-botones-venta d-flex align-items-center justify-content-center`}
                                                        >
                                                            <div className="flex1 d-flex flex-row align-items-center justify-content-center">
                                                                <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                    <button
                                                                        className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center"
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            cambioCobro();
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className="m-0 mb-1"
                                                                            src={
                                                                                icons.pagar
                                                                            }
                                                                        />
                                                                        Pagar
                                                                    </button>
                                                                </div>

                                                                {/* ######################################################## */}
                                                                {/* TODO: EN ANALISIS PARA APLICAR VALES DE DESCUENTOS EN FEL */}
                                                                {!utiliza_fel && (
                                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                        <button
                                                                            className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                            onClick={() => {
                                                                                this.ingresarValeDescuento();
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    icons.canje_devolucion_azul
                                                                                }
                                                                            />
                                                                            <span>
                                                                                Canjear
                                                                                devolución
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {/* ######################################################## */}

                                                                {conf_cotizacion && (
                                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                        <button
                                                                            className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                this.openModalCliente(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className="m-0 mb-1"
                                                                                src={
                                                                                    icons.guardar
                                                                                }
                                                                            />
                                                                            {cotizacion_cargada !=
                                                                            null ? (
                                                                                <span>
                                                                                    {" "}
                                                                                    Actualizar
                                                                                    Cotización{" "}
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    {" "}
                                                                                    Guardar
                                                                                    Cotización{" "}
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {conf_cotizacion &&
                                                                    !seleccionados.length &&
                                                                    !cotizacion_cargada && (
                                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                            <button
                                                                                className="btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                                type="submit"
                                                                                onClick={() => {
                                                                                    pushRouter(
                                                                                        "/cotizaciones"
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    className="m-0 mb-1"
                                                                                    src={
                                                                                        icons.abrir
                                                                                    }
                                                                                />
                                                                                <span>
                                                                                    Abrir
                                                                                    cotización
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                {conf_cotizacion &&
                                                                    (seleccionados.length ||
                                                                        cotizacion_cargada) && (
                                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                            <button
                                                                                className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                                onClick={() =>
                                                                                    resetVenta()
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        icons.anular_cotizacion
                                                                                    }
                                                                                />
                                                                                <span>
                                                                                    Cancelar
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                {!conf_cotizacion && (
                                                                    <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                        <button
                                                                            className={`btn-venta ml-1 mr-1 rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center`}
                                                                            onClick={() =>
                                                                                resetVenta()
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    icons.anular_cotizacion
                                                                                }
                                                                            />
                                                                            <span>
                                                                                Cancelar
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* region AGREGAR VALE */}
                                                <Modal
                                                    center
                                                    showCloseIcon={false}
                                                    open={this.state.modal_vale}
                                                    onClose={this.closeModal}
                                                    classNames={{
                                                        modal: "modal-sm",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "1.2rem",
                                                            maxWidth: "400px",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div className="d-none d-lg-block col-12 grid-titulo">
                                                                Ingrese el
                                                                código del vale
                                                            </div>
                                                            <div className="d-lg-none col-12 grid-titulo">
                                                                Vale de
                                                                descuento
                                                            </div>
                                                            <ValeForm
                                                                styles={{
                                                                    modal: {
                                                                        minWidth:
                                                                            "100%",
                                                                    },
                                                                }}
                                                                onSubmit={
                                                                    this
                                                                        .closeModal
                                                                }
                                                                close={
                                                                    this
                                                                        .closeModal
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Modal>
                                                {/* endregion AGREGAR VALE */}

                                                {/* region Modal Existencia por sucursal */}
                                                <Modal
                                                    showCloseIcon={false}
                                                    open={
                                                        this.state
                                                            .modal_existencia
                                                    }
                                                    onClose={
                                                        this
                                                            .closeModalExistencias
                                                    }
                                                    classNames={{
                                                        modal: "modal-bienvenida",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding:
                                                                "0  0 1.2rem 0",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div className="w-100 d-flex align-items-center justify-content-end">
                                                                <button
                                                                    className="btn btn-cerrar-modal"
                                                                    style={{
                                                                        borderTopLeftRadius:
                                                                            "0",
                                                                        borderTopRightRadius:
                                                                            "10px",
                                                                        borderBottomRightRadius:
                                                                            "0",
                                                                        borderBottomLeftRadius:
                                                                            "10px",
                                                                        fontSize:
                                                                            "1em",
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        this.closeModalExistencias();
                                                                    }}
                                                                >
                                                                    <em className="fa fa-times"></em>
                                                                </button>
                                                            </div>
                                                            <ExistenciaSucursal
                                                                search_venta={
                                                                    search
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Modal>

                                                {/* MODAL DE CLIENTE */}
                                                <Modal
                                                    showCloseIcon={false}
                                                    open={
                                                        this.state.modal_cliente
                                                    }
                                                    onClose={
                                                        this.closeModalCliente
                                                    }
                                                    classNames={{
                                                        modal: "modal-sm",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            padding: "1.2rem",
                                                            maxWidth: "400px",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div className="col-12 grid-titulo">
                                                                Datos cliente
                                                            </div>
                                                            <ClienteCotizacionForm
                                                                loader={loader}
                                                                onSubmit={
                                                                    this
                                                                        .closeModalCliente
                                                                }
                                                                close={
                                                                    this
                                                                        .closeModalCliente
                                                                }
                                                                leerCliente={
                                                                    leerClienteCotizacion
                                                                }
                                                                changeDatosClientes={() => {}}
                                                                es_cotizacion={
                                                                    true
                                                                }
                                                                initialValues={
                                                                    cotizacion_cargada ==
                                                                    null
                                                                        ? {
                                                                              nit: "CF",
                                                                          }
                                                                        : {
                                                                              nombre: cotizacion_cargada.nombre,
                                                                              correo: cotizacion_cargada.correo,
                                                                              nit: cotizacion_cargada.nit,
                                                                          }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Modal>

                                                {/* endregion Modal Existencia por sucursal */}
                                                {/* Modal Ayuda */}
                                                <ModalAyuda
                                                    open={modalAyuda}
                                                    closeModal={
                                                        this.closeModalAyuda
                                                    }
                                                    openModal={
                                                        this.openModalAyuda
                                                    }
                                                    items={itemsImagenes}
                                                />
                                            </div>
                                            {/* endregion SECCION DE MONTO TOTAL Y PAGO*/}
                                        </div>
                                    )}
                                </LoadMask>
                            </div>
                        </div>

                        {/* region FACTURA PERSONALIZADA */}
                        {movimiento.sucursal !== undefined ? (
                            <div
                                className={`slide-container col-12 row ${
                                    factura_personalizada ? "mt-3" : "d-none"
                                } mt-4`}
                            >
                                {factura_personalizada ? (
                                    <div
                                        className={`w100 container-vista-previa ${
                                            tipo_pago == pago_credito
                                                ? "print-only"
                                                : ""
                                        }`}
                                    >
                                        <div
                                            className="d-flex flex-row flex-xs-column justify-content-between align-items-center container-vista-previa-head pl-1 pr-1"
                                            style={{ zIndex: "1" }}
                                        >
                                            <b
                                                className="negro-dark2"
                                                style={{ fontSize: "23px" }}
                                            >
                                                Vista previa
                                            </b>
                                            <div className="text-right flex1">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.print(
                                                            "reciboVenta"
                                                        );
                                                    }}
                                                    className="btn btn-secondary mt-lg"
                                                    type={"button"}
                                                >
                                                    <em className="fa fa-print" />{" "}
                                                    Imprimir
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-12 col-md-12 col-sm-12 mt-10 container-vista-previa-body justify-content-center"
                                            style={{ zIndex: "0" }}
                                        >
                                            <PrintContainer
                                                ref={(el) =>
                                                    (this.componentRef = el)
                                                }
                                                name="reciboVenta"
                                                className="impresion-venta"
                                            >
                                                {venta_impresion &&
                                                    venta_impresion.map(
                                                        (factura, index) => {
                                                            return (
                                                                <Factura
                                                                    className={`${
                                                                        index !=
                                                                        0
                                                                            ? "salto-pagina"
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    datos_venta={
                                                                        factura
                                                                    }
                                                                    formato_factura={
                                                                        formato_factura
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                {/* <Factura datos_venta={datos_venta} formato_factura={formato_factura}/> */}
                                            </PrintContainer>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {/* endregion FACTURA PERSONALIZADA*/}
                    </div>
                ) : (
                    <div className="slide-container row w-100 d-flex justify-content-center">
                        {print_state.flag && <PrintComponent />}
                        <div className="col-12 col-lg-8 container-vista-previa">
                            <div
                                style={{ backgroundColor: "transparent" }}
                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                            >
                                <div className="w-100 text-center">
                                    <h5 className="negro-dark2">
                                        Vista previa cotización
                                    </h5>
                                    <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print(
                                                    "ticketCotizacion"
                                                );
                                            }}
                                            style={{ width: "150px" }}
                                            className="btn mr-1 btn-secondary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir
                                        </button>
                                        <button
                                            style={{ width: "150px" }}
                                            className="btn ml-1 btn-outline-secondary mt-lg"
                                            type="button"
                                            onClick={() => {
                                                finalizarCotizacion();
                                            }}
                                        >
                                            <em className="fa fa-check" />{" "}
                                            Finalizar cotización
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="container-vista-previa-body">
                                <div className="d-flex flex-row justify-content-center w100">
                                    <PrintContainer name="ticketCotizacion">
                                        {venta_cotizacion && (
                                            <TicketCotizacion
                                                cotizacion={venta_cotizacion}
                                            />
                                        )}
                                    </PrintContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Venta;
