import moment from "moment";
import React, { useState, useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import UsuarioForm from "./UsuarioForm";

const CrearActualizar = (props) => {
    const { match } = props;

    const { params, path, url } = match;

    const editarDato = url.includes("editar");

    const titulo = editarDato ? "Editar" : "Nuevo";

    const id = params.id;

    const isUpdated = url.includes("editar");

    //Files
    const [files, setFiles] = useState([]);
    const [oldFiles, setOldFiles] = useState([]);
    const [removeFiles, setRemoveFiles] = useState([]);

    const onChangeFile = async (file) => {
        let files_data = [...files];

        let file_exist = false;
        files.map((item) => {
            if (item.name == file.name) {
                file_exist = true;
            }
        });

        oldFiles.map((item) => {
            if (item.nombre == file.name) {
                file_exist = true;
            }
        });

        if (file_exist) {
            let mensajeError = "Archivo de evidencia ya existente";
            NotificationManager.error(mensajeError, "Error", 7000);
        } else {
            files_data.push({ name: file.name, file: file });
            setFiles(files_data);
        }
    };

    const onRemoveOldFile = async (file) => {
        let files_data = [...oldFiles];
        let data_removeFiles = [...removeFiles];
        data_removeFiles.push(file.id);

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }
        setRemoveFiles(data_removeFiles);
        setOldFiles(files_data);
    };

    const onRemoveFile = async (file) => {
        let files_data = [...files];

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }

        setFiles(files_data);
    };

    const onSubmit = (dataForm) => {
        let data_files = [...files];
        let old_data_files = [...oldFiles];
        const body = { ...dataForm };

        let new_files = data_files.map((file) => {
            return { name: file.name };
        });

        body.new_files = new_files;
        body.remove_files = removeFiles;

        if (body.foto == undefined) {
            body.foto = null;
        } else if (body.foto && body.foto == "") {
            body.foto = null;
        } else if (body.foto && typeof body.foto === "object") {
            let nameFile = body.foto.name;
            let newFile = new File([body.foto], "foto", {
                type: body.foto.type,
            });
            data_files.push({ name: newFile.name, file: newFile });
            body.foto = nameFile;
        } else if (body.foto && typeof body.foto === "string") {
            // console.log("No ha cambiado la foto");
            body.foto = null;
        }

        if (body.sucursales instanceof Object) {
            body.sucursales = [
                {
                    id: body.sucursales.id,
                },
            ];
        } else {
            body.sucursales = [
                {
                    id: body.sucursales,
                },
            ];
        }

        body.username = body.username;
        body.birthday = moment(body.birthday).format("YYYY-MM-DD");

        if (id) {
            props.editar(id, body, data_files);
            // props.editar(id, body, data_files);
        } else {
            // props.crear(body, data_files);
            props.crear(body, data_files);
        }
    };

    useEffect(() => {
        if (id) {
            props.leer(id);
        }

        //if (props.item && props.item.files) {
        //    setOldFiles(props.item.files);
        //}

        return () => {
            props.removerItem();
        };
    }, []);

    useEffect(() => {
        if (props.item && props.item.files) {
            setOldFiles(props.item.files);
        }
    }, [props.item]);

    // *** Datos formulario anterior
    const {
        loader,
        configuracion,
        reportes,
        sucursales,
        despacho,
        bodega,
        punto_venta_mostrador_caja,
        tipo_punto_venta,
        utiliza_fel,
    } = props;

    // bind
    const { changePermisos } = props;

    return (
        <React.Fragment>
            <LoadMask loading={loader} light blur radius>
                <UsuarioForm
                    usuariosDatos={[]}
                    onSubmit={onSubmit}
                    view={false}
                    update={isUpdated}
                    id={id}
                    files={files}
                    onChangeFile={onChangeFile}
                    onRemoveFile={onRemoveFile}
                    onRemoveOldFile={onRemoveOldFile}
                    oldFiles={oldFiles}
                    data={props.item}
                    configuracion={configuracion}
                    reportes={reportes}
                    despacho={despacho}
                    bodega={bodega}
                    utiliza_fel={utiliza_fel}
                    punto_venta_mostrador_caja={punto_venta_mostrador_caja}
                    tipo_punto_venta={tipo_punto_venta}
                    changeValue={props.changeValue}
                    sucursales={sucursales}
                    changePermisos={changePermisos}
                />
            </LoadMask>
        </React.Fragment>
    );
};

export default CrearActualizar;
