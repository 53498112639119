import { useState, useEffect } from "react";

const useCrearEditarProveedor = ({
    crear,
    match,
    item,
    location,
    contactos,
    leer,
    editar,
    setProveedores = () => {},
}) => {
    const {
        contactos: array_data,
        id_contactos_eliminar: eliminar,
        nuevos_contactos: nuevo,
    } = contactos;
    // *** Files
    const [files, setFiles] = useState([]);
    const [oldFiles, setOldFiles] = useState([]);
    const [removeFiles, setRemoveFiles] = useState([]);

    // URL
    // true, si la url contiene editar
    const editarDato = location.pathname.includes("editar");

    const titulo = editarDato ? "Editar" : "Nuevo";

    const id = match.params.id;

    // Si se esta actualizando o viendo un registro, devolver la data del item, sino (se esta creando) devolver un objeto vacio
    const intitalData = editarDato ? item : {};

    // *** Files
    const onChangeFile = async (file) => {
        let files_data = [...files];

        files_data.push({ name: file.name, file: file });
        setFiles(files_data);
    };

    const onRemoveOldFile = async (file) => {
        let files_data = [...oldFiles];
        let data_removeFiles = [...removeFiles];

        if (file.id) {
            data_removeFiles.push(file.id);
        }

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }
        setRemoveFiles(data_removeFiles);
        setOldFiles(files_data);
    };

    const onRemoveFile = async (file) => {
        let files_data = [...files];

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }

        setFiles(files_data);
    };

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };

        let data_files = [...files];

        // *** Datos de array_data, al editar o agregar un proveedor
        // Si el array de array_data, no esta vacio, agregar los array_data al body
        if (array_data.length > 0) body.array_data = array_data;

        // Si el array de eliminar, no esta vacio, agregar eliminar al body
        if (eliminar.length > 0) body.eliminar = eliminar;

        // Si el array de nuevo, no esta vacio, agregar nuevo al body
        if (nuevo.length > 0) body.nuevo = nuevo;

        // *** Files
        let new_files = data_files.map((file) => {
            return { name: file.name };
        });

        body.archivos = new_files;
        body.eliminar_archivos = removeFiles;

        if (editarDato) return editar(id, body, data_files);
        crear(body, data_files);
    };

    useEffect(() => {
        // si se esta editando o viendo el registro, cargar los datos para llenar el formulario (item, variable que contiene los datos)
        if (id) leer(id);
    }, []);

    useEffect(() => {
        if (editarDato) {
            if (item.archivos) {
                setOldFiles(item.archivos);
            }
        } else {
            setFiles([]);
        }
    }, [item]);

    // *** Set Contactos
    const { contactos: contactosData } = intitalData;

    useEffect(() => {
        // Si se esta editando el dato, existe los contactos registrados, y setearlos al array contactos de proveedores
        if (contactosData && contactosData.length > 0) {
            setProveedores({
                contactos: contactosData,
                id_contactos_eliminar: [],
                nuevos_contactos: [],
            });
        } else {
            setProveedores({
                id_contactos_eliminar: [],
                nuevos_contactos: [],
                contactos: [],
            });
        }
    }, [contactosData]);

    return {
        id,
        titulo,
        intitalData,
        editarDato,
        files,
        oldFiles,
        onChangeFile,
        onRemoveOldFile,
        onRemoveFile,
        onSubmit,
    };
};

export default useCrearEditarProveedor;
