import React, { useEffect } from "react";
import _ from "lodash";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { formatoEmpty } from "Utils/CamposComunes";
import CamposFiltros from "./CamposFiltros";
import { RenderDate } from "../../Utils/renderField/renderReadField";

const ListadoMovimientos = ({
    listar,
    page,
    data,
    fecha_final,
    fecha_inicial,
    tipo,
    cliente,
    proveedor,
    usuario,
    loader = false,

    sortChange,
    setFecha,
    setTipo,
    setCliente,
    setProveedor,
    setUsuario,
    limpiar,
}) => {
    useEffect(() => {
        listar(page);

        return () => {
            limpiar();
        };
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="grid-container">
                        <div className="grid-titulo">
                            Listado de Movimientos
                            {/* <Toolbar
                                titulo={"Bancos"}
                                buscar={buscar}
                                search={search}
                            /> */}
                        </div>
                        <CamposFiltros
                            fecha_final={fecha_final}
                            fecha_inicial={fecha_inicial}
                            tipo={tipo}
                            cliente={cliente}
                            proveedor={proveedor}
                            usuario={usuario}
                            setFecha={setFecha}
                            setTipo={setTipo}
                            setCliente={setCliente}
                            setProveedor={setProveedor}
                            setUsuario={setUsuario}
                        />
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="tipo_movimiento"
                                    dataFormat={(cell) => {
                                        if (cell === "Baja")
                                            return "Devolucion Compra";
                                        if (cell === "Despacho")
                                            return "Traslado";
                                        if (cell === "Devolucion")
                                            return "Devolucion Venta";
                                        if (cell) return cell;
                                        return "---";
                                    }}
                                >
                                    Tipo Movimiento
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="codigo"
                                    dataFormat={formatoEmpty}
                                >
                                    Codigo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="concepto"
                                    dataFormat={formatoEmpty}
                                >
                                    Concepto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cliente_proveedor"
                                    dataFormat={(cell, row) => {
                                        const cliente_devolucion =
                                            row.cliente_devolucion;

                                        if (cliente_devolucion) {
                                            return cliente_devolucion;
                                        }
                                        if (cell) {
                                            return cell.nombre;
                                        }
                                        return "---";
                                    }}
                                >
                                    Cliente/Proveedor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="usuario"
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.first_name;
                                        }
                                        return "---";
                                    }}
                                >
                                    Usuario
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        const { nota_credito, codigo_venta } =
                                            row;

                                        const link_to =
                                            nota_credito && codigo_venta
                                                ? `nota_credito/${codigo_venta}`
                                                : "resumen_de_ingreso";

                                        if (nota_credito) cell = nota_credito;
                                        return activeFormatter({
                                            ver: `/bodega/${link_to}`,
                                        })(cell);
                                    }}
                                    width={"10rem"}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListadoMovimientos;
