import _, { values } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import moment from "moment/moment";
import DatePicker from "Utils/DatePicker";
import { Field, reduxForm, formValueSelector } from "redux-form";

import { credito, cotizacion } from "utility/constantes";

import { ListaProductosOV } from "../../camposOV";

import validarNit from "validar-nit-gt";

import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
    NIT,
} from "utility/validation";

import {
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    //AsyncSelectField,
    renderAsyncSelectField,
    renderCurrency,
    SelectField,
    renderFieldReadOnly,
} from "Utils/renderField/renderField";

import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";

import { renderSearchSelect } from "../../../Utils/renderField/renderField";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";

const validate = (values) => {
    const errors = {};

    /*if (values.nit) {
        let res = validarNit(values.nit);
        if (res == false) {
            errors.nit = "Escriba un nit válido o CF";
            errors.nombre = "Escriba un nit válido o CF";
        }
    }

    if (!values.nombre) errors.nombre = "Es requerido";*/

    return errors;
};

let listado_direcciones = [];

const Form = (props) => {
    const {
        handleSubmit,
        data_form,
        total_costo,
        venta_costo,
        total,
        anteriorPaso,
        seleccionados,
        enviarCotizacion,
        editarCotizacion,
        cliente_id,
        clienteValue,
        registrarOV,
        descuentos,
        porcentaje_descuento,
        total_descuento,

        //ModalClientes
        listarCliente,
        abrirModalCliente,
        enviar_correo,
        estado_descarga,
        loader,

        match,
    } = props;

    const editar_cotizacion = match.path.includes("editar");
    const id_cotizacion = match.params.id;
    const { id } = match.params;

    const { getOptions: getDirecciones } = useAsyncSearch(
        "clientes_direcciones",
        listado_direcciones,
        { id_cliente: cliente_id ? cliente_id : "" }
    );

    useEffect(() => {
        listado_direcciones = [];
    }, [cliente_id]);

    return (
        <React.Fragment>
            <form
                onSubmit={handleSubmit}

                //onKeyPress={(e) => {
                //    if (e.key == "Enter") {
                //        cargar_Cliente();
                //    } else false;
                //}}
            >
                <div className="grid-titulo padding-10">
                    Finalizar venta al credito
                </div>
                {/*  Row 1 */}
                <div className="row px-1 py-2">
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="">Cliente</label>
                        <div className="d-flex pr-0">
                            <div className="d-flex flex-column w-100">
                                <Field
                                    name="cliente"
                                    component={renderFieldReadOnly}
                                    className="form-control"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="nit">NIT</label>
                        <div className="d-flex flex-column pr-0">
                            <div
                                className="d-flex flex-column pr-0"
                                style={{
                                    borderBottom: "2px solid  #B8B8B8",
                                }}
                            >
                                {(data_form && (
                                    <React.Fragment>
                                        No. {data_form.nit_cliente}
                                    </React.Fragment>
                                )) ||
                                    "--------"}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="">Direccion</label>
                        <Field
                            name="direccion"
                            component={renderFieldReadOnly}
                            className="form-control"
                            disabled={true}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="correo">Correo</label>
                        <div
                            className="d-flex flex-column pr-0"
                            style={{ maxWidth: "650px" }}
                        >
                            <Field
                                name="correo"
                                rows={2}
                                component={renderField}
                                className="form-control"
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="row px-1 py-2">
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <div className="d-flex flex-column pr-0">
                            <Field
                                name="nombre"
                                rows={2}
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="direccion">Direccion</label>
                        <div className="d-flex flex-column pr-0">
                            <Field
                                name="direccion"
                                rows={2}
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div> */}
                <div className="row px-1 py-2">
                    {/* <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="enviar_correo">Enviar a correo</label>
                        <div
                            className="d-flex flex-column pr-0"
                            style={{ maxWidth: "650px" }}
                        >
                            <div className="d-flex mt-1">
                                <label className="mr-2">No</label>
                                <Field
                                    name="enviar_correo"
                                    component={renderSwitch}
                                    type="text"
                                    className="form-control"
                                    disabled={venta_costo ? venta_costo : false}
                                />
                                <label className="ml-2">Si</label>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row px-1 py-2">
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="nombre">Fecha</label>
                        <div
                            className="d-flex flex-column pr-0"
                            style={{
                                maxWidth: "650px",
                                borderBottom: "2px solid  #B8B8B8",
                            }}
                        >
                            {(data_form && data_form.fecha && (
                                <span>
                                    {moment(data_form.fecha).format(
                                        "DD/MM/YYYY"
                                    )}
                                </span>
                            )) ||
                                "--------"}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                        <label htmlFor="">Total</label>
                        <div
                            className="p-1 text-right"
                            style={{
                                borderBottom: "2px solid  #B8B8B8",
                            }}
                        >
                            {venta_costo
                                ? !descuentos && (
                                      <RenderCurrency value={total_costo} />
                                  )
                                : (!venta_costo && !descuentos && (
                                      <RenderCurrency value={total} />
                                  )) ||
                                  (descuentos && (
                                      <RenderCurrency value={total_descuento} />
                                  ))}
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                        <button
                            type="button"
                            className="btn btn-tertiary mr-1"
                            onClick={anteriorPaso}
                        >
                            Regresar
                        </button>
                        <button
                            className="btn btn-primary ml-1"
                            type="button"
                            onClick={() => {
                                registrarOV(
                                    total,
                                    editar_cotizacion,
                                    id_cotizacion
                                );
                            }}
                            disabled={loader || estado_descarga}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-check"
                                }`}
                            ></em>
                            &nbsp; Finalizar
                        </button>
                    </div>
                </div>
            </form>

            <div className="col-12">
                <ListaProductosOV
                    seleccionados={seleccionados}
                    total={total}
                    porcentaje_descuento={
                        porcentaje_descuento ? porcentaje_descuento : 5
                    }
                    descuento={descuentos ? descuentos : false}
                    total_costo={total_costo ? total_costo : 0}
                    venta_costo={venta_costo ? venta_costo : false}
                />
            </div>
        </React.Fragment>
    );
};

let CreditoForm = reduxForm({
    form: "wizardCrearOVForm",
    // a unique name for the form
    //form: "MediosPagosForm",
    destroyOnUnmount: false, //        <------ preserve DatePickerform data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(Form);

const selector = formValueSelector("wizardCrearOVForm");
CreditoForm = connect((state) => {
    const data_form = state.form.wizardCrearOVForm.values;
    const fecha = moment().format("YYYY-MM-DD");
    const tipo_venta = selector(state, "tipo_venta");
    const descuentos = selector(state, "descuentos");
    const porcentaje_descuento = selector(state, "porcentaje_descuento");
    const venta_costo = selector(state, "venta_costo"); //enviar_correo
    const enviar_correo = selector(state, "enviar_correo");
    const cliente_id = selector(state, "cliente_id");
    const clienteValue = selector(state, "cliente");
    const total_descuento = selector(state, "total_descuento");

    if (data_form.venta_costo == true) {
        data_form.descuentos = false;
        data_form.porcentaje_descuento = 0;
    }

    return {
        clienteValue,
        tipo_venta,
        data_form,
        venta_costo,
        enviar_correo,
        cliente_id,
        descuentos,
        porcentaje_descuento: porcentaje_descuento ? porcentaje_descuento : 5,
        total_descuento,
        initialValues: {
            nit: "CF",
            cliente: "Consumidor final",
            fecha_limite: fecha,
        },
    };
})(CreditoForm);

export default CreditoForm;
