import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { RenderDate, RenderNumber } from "Utils/renderField/renderReadField";

export default class ReciboEntregaOV extends Component {
    static propTypes = {
        utiliza_fel: PropTypes.bool.isRequired,
        movimiento: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        //  State
        const { movimiento, utiliza_fel } = this.props;
        const sucursal = movimiento ? movimiento.sucursal : {};
        const empresa = movimiento ? movimiento.empresa : {};
        const detalles = movimiento.detalles ? movimiento.detalles : [];

        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-16">
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {empresa ? empresa.nombre_comercial : ""}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {empresa ? empresa.nombre : ""}
                            </b>
                        </div>
                    )}
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}
                </div>
                <div className="mt mb d-flex flex-row justify-content-center fnt-14">
                    <span>COMPROBANTE DE ENTREGA DE OV</span>
                </div>
                <div
                    className="d-flex flex-row fnt-12 mb05"
                    tyle={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha</b>
                        <RenderDate value={movimiento.fecha_recepcion} />
                    </div>
                    {movimiento.recepcionado_por ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Usuario</b>
                            {movimiento.recepcionado_por.first_name
                                ? movimiento.recepcionado_por.first_name
                                : "-----"}
                        </div>
                    ) : null}
                </div>
                <div
                    className="d-flex flex-row fnt-12 mb05"
                    tyle={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>No. Orden</b>
                        {movimiento.orden_venta
                            ? movimiento.orden_venta.no_orden
                            : "-----"}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Cliente</b>
                        {movimiento.orden_venta
                            ? movimiento.orden_venta.cliente.nombre
                            : "-----"}
                    </div>
                </div>
                <div
                    className="d-flex flex-row fnt-12 mb05"
                    tyle={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>Tipo entrega</b>
                        {movimiento.orden_venta
                            ? movimiento.orden_venta.nombre_tipo_entrega
                            : "-----"}
                    </div>
                    {movimiento.orden_venta &&
                        movimiento.orden_venta.tipo_entrega == 20000 && (
                            <div className="flex1 d-flex flex-column">
                                <b>Sucursal entrega</b>
                                {movimiento.orden_venta.sucursal_destino
                                    ? movimiento.orden_venta.sucursal_destino
                                          .nombre
                                    : "-----"}
                            </div>
                        )}
                </div>
                <div
                    className="d-flex flex-row fnt-12 mb05"
                    tyle={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>Encargado entrega</b>
                        {movimiento.orden_venta
                            ? movimiento.orden_venta.encargado
                            : "-----"}
                    </div>
                </div>
                {movimiento.orden_venta &&
                    movimiento.orden_venta.tipo_entrega == 20000 && (
                        <div
                            className="d-flex flex-row fnt-12 mb05"
                            tyle={{ borderBottom: "dotted 1px" }}
                        >
                            <div className="flex1 d-flex flex-column">
                                <b>Dirección entrega</b>
                                <span>
                                    {movimiento.orden_venta
                                        ? `${movimiento.orden_venta.sucursal_destino.departamento}, `
                                        : ""}
                                    {movimiento.orden_venta
                                        ? `${movimiento.orden_venta.sucursal_destino.municipio}, `
                                        : ""}
                                    {movimiento.orden_venta
                                        ? movimiento.orden_venta
                                              .sucursal_destino.direccion
                                        : "-----"}
                                </span>
                            </div>
                        </div>
                    )}
                <div
                    className="d-flex flex-column fnt-12 pb-05 mb-2"
                    style={{ borderBottom: "dotted 1px" }}
                >
                    <div className="flex1 d-flex flex-column">
                        <b>Nota</b>
                        {movimiento.concepto ? movimiento.concepto : "------"}
                    </div>
                </div>

                <div className="d-flex flex-row justify-content-between fnt-16">
                    <b className="flex-1">ARTÍCULOS</b>
                    <b>CANT.</b>
                </div>

                {detalles.map((detalle) => {
                    return (
                        <div className="d-flex mb fnt-11" key={detalle.id}>
                            <div className="width100">
                                <div className="d-flex">
                                    <div className="d-flex flex-1 flex-row">
                                        {`
                                                    ${
                                                        detalle.fraccion
                                                            .codigo_barras
                                                            ? `${detalle.fraccion.codigo_barras} / `
                                                            : ""
                                                    }
                                                    ${detalle.producto} / ${
                                            detalle.fraccion.producto.a_granel
                                                ? detalle.unidad_de_medida
                                                      .nombre
                                                : detalle.fraccion.nombre
                                        }
                                                    `}
                                    </div>
                                    <div className="d-flex flex-row">
                                        <RenderNumber
                                            value={detalle.cantidad}
                                            decimalScale={
                                                detalle.fraccion.producto
                                                    .a_granel
                                                    ? 3
                                                    : 2
                                            }
                                            className={"ml-2 text-right"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
