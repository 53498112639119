import PropTypes from "prop-types";
import Barcode from "react-barcode";
import React, { Component } from "react";
import {
    RenderDateTime,
    RenderDate,
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";

import moment from "moment";

import NumerosALetras from "utility/convertToLetters";
export default class TicketCotizacion extends Component {
    static propTypes = {
        cotizacion: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    percentage(percent, total) {
        return ((percent / 100) * total).toFixed(2);
    }

    render() {
        const { cotizacion, pagina } = this.props;
        const movimiento = cotizacion ? cotizacion.movimiento : null;
        const sucursal = movimiento.sucursal;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const usuario = movimiento.usuario ? movimiento.usuario.first_name : "";

        const fecha = movimiento.creado ? movimiento.creado : "";

        const total = movimiento.total_cobrar_pagar
            ? movimiento.total_cobrar_pagar
            : 0;
        let fin_items = pagina * 15;

        const img_encabezado = require("assets/img/encabezado-hojas.png");

        const tiene_descuento =
            parseFloat(movimiento.porcentaje_descuento_recargo) > 0;

        const codigo_cotizacion =
            cotizacion && cotizacion.codigo ? cotizacion.codigo : "---";

        return (
            <div id="IMPRESION-HOJA-OV" className="w-100">
                <div className="print-shadow hoja-carta">
                    {/* ENCABEZADO */}
                    <div className="encabezado">
                        <div className="flex4 d-flex align-items-start justify-content-between">
                            <div className="item bg-transparent ml-4 text-center">
                                <img
                                    src={img_encabezado}
                                    alt="encabezado-ov"
                                    width="200"
                                />
                                <div className="font-weight-bold mt-3">
                                    Dia &nbsp;&nbsp;&nbsp; Mes
                                    &nbsp;&nbsp;&nbsp; Año
                                </div>
                                <div className="">
                                    <span className="ml-4 mr-2">
                                        {moment(fecha, "YYYY/MM/DD").date()}{" "}
                                    </span>
                                    <span className="ml-4 mr-2">
                                        {moment(fecha, "YYYY/MM/DD").month() +
                                            1}{" "}
                                    </span>
                                    <span className="ml-3 mr-3">
                                        {moment(fecha, "YYYY/MM/DD").year()}
                                    </span>
                                </div>
                            </div>
                            <div className="item bg-transparent text-center flex2">
                                {/* flex2*/}
                                <div className="font-weight-bold">
                                    {datos_empresa.nombre_comercial
                                        ? datos_empresa.nombre_comercial.toUpperCase()
                                        : ""}
                                </div>

                                <div className="mt-2 encabezado-item-datos font-weight-bold">
                                    {movimiento.nombre_emisor}:{" "}
                                    {movimiento.nit_emisor}
                                </div>

                                <div className="mt-3">
                                    {movimiento.direccion_exacta_emisor}
                                </div>
                                <div>{movimiento.direccion_emisor}</div>
                                <div>
                                    Tels:{" "}
                                    {sucursal.telefono ||
                                        "7772-2013 / 7772-2493"}
                                </div>
                                <div className="encabezado-item-datos mt-2">
                                    email:
                                    ventas@remocar.com.gt&nbsp;|&nbsp;wwww.remocar.com.gt
                                </div>
                            </div>

                            <div className="item bg-transparent ml-5">
                                <div className="font-weight-bold mt-3">
                                    COTIZACION
                                </div>
                                <div className="font-weight-bold mt-3">
                                    Correlativo
                                </div>
                                <div>{codigo_cotizacion}</div>
                            </div>
                        </div>
                    </div>

                    {/* INFO*/}
                    <div className="info-ov">
                        <div className="info-ov-item">
                            <div className="title"></div>
                            <div className="info-ov-item-body">
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="item">
                                        <b className="ml-3 mr-2">Cliente:</b>
                                        <span>
                                            {movimiento.cliente_proveedor
                                                ? movimiento.cliente_proveedor
                                                      .nombre
                                                    ? movimiento
                                                          .cliente_proveedor
                                                          .nombre
                                                    : "-----"
                                                : "-----"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <b className="mr-2">Vendedor:</b>
                                        <span>{usuario}</span>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mt-1 mb-1">
                                    <div className="item">
                                        <b className="ml-3 mr-2">NIT:</b>
                                        <span>
                                            {movimiento.cliente_proveedor
                                                ? movimiento.cliente_proveedor
                                                      .nit
                                                    ? movimiento
                                                          .cliente_proveedor.nit
                                                    : "CF"
                                                : "CF"}
                                        </span>
                                    </div>

                                    <div className="item">
                                        <b className="mr-2">Direccion:</b>
                                        <span>
                                            {cotizacion.direccion
                                                ? cotizacion.direccion.direccion
                                                : "-----"}
                                        </span>
                                    </div>
                                    {/* <div className="item">
                                        <b className="mr-2">
                                            Nombre cotizacion:
                                        </b>
                                        <span>
                                            {cotizacion.nombre
                                                ? cotizacion.nombre
                                                : "-----"}
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="info-ov ">
                        <div className="info-ov-item">
                            <div className="title"></div>
                        </div>
                    </div>

                    {/* PRODUCTOS */}
                    <div className="productos-ov impresion-tabla">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th
                                        className="text-center"
                                        style={{ width: "80px" }}
                                    >
                                        CANTIDAD
                                    </th>
                                    <th
                                        className="text-left"
                                        style={{ minWidth: "160px" }}
                                    >
                                        CODIGO
                                    </th>
                                    <th
                                        style={{
                                            width: "500px",
                                        }}
                                    >
                                        DESCRIPCION
                                    </th>
                                    <th
                                        className="text-center"
                                        style={{ width: "130px" }}
                                    >
                                        UNIDAD
                                    </th>
                                    {cotizacion &&
                                        parseFloat(cotizacion.descuento) > 0 &&
                                        movimiento.venta_costo == false && (
                                            <th
                                                className="text-center"
                                                style={{ width: "130px" }}
                                            >
                                                DESCUENTO
                                            </th>
                                        )}
                                    <th
                                        className="text-center"
                                        style={{ width: "130px" }}
                                    >
                                        SUBTOTAL
                                    </th>
                                    <th
                                        className="text-right"
                                        style={{
                                            width: "110px !important",
                                        }}
                                    >
                                        IMPUESTO
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {detalles &&
                                    detalles
                                        .slice(fin_items - 15, fin_items)
                                        .map((detalle, idx) => {
                                            //total = total + detalle.sub_total;
                                            return (
                                                <tr>
                                                    <td
                                                        scope="row"
                                                        className="text-center"
                                                    >
                                                        {
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        }
                                                    </td>
                                                    <td className="text-left">
                                                        {` ${
                                                            detalle.fraccion
                                                                .producto
                                                                ? `${detalle.fraccion.producto.codigo} `
                                                                : ""
                                                        } `}
                                                    </td>
                                                    <td className="text-left">
                                                        {`${detalle.producto} `}
                                                    </td>
                                                    <td className="text-left">
                                                        <RenderCurrency
                                                            value={
                                                                cotizacion.venta_al_costo ===
                                                                true
                                                                    ? detalle.costo
                                                                    : detalle.precio_original &&
                                                                      detalle.desc_porcentaje
                                                                    ? detalle.precio_original
                                                                    : detalle.precio
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    </td>
                                                    {tiene_descuento &&
                                                        movimiento.venta_costo ==
                                                            false && (
                                                            <React.Fragment>
                                                                <td className="text-left">
                                                                    {"Q."}
                                                                    {this.percentage(
                                                                        movimiento.porcentaje_descuento_recargo,
                                                                        detalle.precio_original &&
                                                                            detalle.desc_porcentaje
                                                                            ? detalle.precio_original
                                                                            : detalle.precio
                                                                    )}
                                                                </td>
                                                                <td className="text-left">
                                                                    {"Q."}
                                                                    {(
                                                                        (
                                                                            detalle.cantidad *
                                                                            detalle.precio_original
                                                                        ).toFixed(
                                                                            2
                                                                        ) -
                                                                        (
                                                                            this.percentage(
                                                                                movimiento.porcentaje_descuento_recargo,
                                                                                detalle.precio_original &&
                                                                                    detalle.desc_porcentaje
                                                                                    ? detalle.precio_original
                                                                                    : detalle.precio
                                                                            ) *
                                                                            detalle.cantidad
                                                                        ).toFixed(
                                                                            2
                                                                        )
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                            </React.Fragment>
                                                        )}
                                                    {!tiene_descuento && (
                                                        <td className="text-left">
                                                            <RenderCurrency
                                                                value={
                                                                    cotizacion.venta_al_costo ===
                                                                    true
                                                                        ? detalle.costo *
                                                                          detalle.cantidad
                                                                        : (detalle.precio_original &&
                                                                          detalle.desc_porcentaje
                                                                              ? detalle.precio_original
                                                                              : detalle.precio) *
                                                                          detalle.cantidad
                                                                }
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        </td>
                                                    )}

                                                    <td className="text-left">
                                                        <RenderCurrency
                                                            value={this.percentage(
                                                                12,
                                                                detalle.sub_total
                                                            )}
                                                            className={"mr-1"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                            </tbody>
                        </table>
                    </div>
                    <div className="line text-center font-weight-bold"></div>

                    {/* FOOTER */}
                    <div className="info-ov ">
                        <div className="info-ov-item">
                            <div className="title"></div>
                            <div className="info-ov-item-body">
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="footer-text flex3 ">
                                        <div>
                                            {movimiento &&
                                                movimiento.paginas &&
                                                pagina ==
                                                    movimiento.paginas && (
                                                    <React.Fragment>
                                                        <b className="mr-2">
                                                            EN LETRAS:
                                                        </b>{" "}
                                                        <span>
                                                            {NumerosALetras.NumerosALetras(
                                                                total
                                                            )}
                                                        </span>{" "}
                                                    </React.Fragment>
                                                )}
                                        </div>
                                        <div>
                                            <b className="mr-2"></b>
                                            <span></span>
                                        </div>
                                        <div>
                                            <b className="mr-2"></b>
                                        </div>
                                    </div>

                                    <div className="item text-right total-ov font-weight-bold">
                                        {movimiento &&
                                            movimiento.paginas &&
                                            pagina == movimiento.paginas && (
                                                <React.Fragment>
                                                    TOTAL &nbsp;
                                                    <RenderCurrency
                                                        value={cotizacion.total}
                                                        className={"ml-auto"}
                                                    />
                                                </React.Fragment>
                                            )}

                                        {tiene_descuento &&
                                            movimiento.venta_costo == false && (
                                                <div>
                                                    DESCUENTO APLICADO:{" "}
                                                    {
                                                        movimiento.porcentaje_descuento_recargo
                                                    }
                                                    %
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="no_pagina d-flex flex-row justify-content-center imp-pt-2">
                        <span className="text-center">Pag.{pagina}</span>
                    </div>
                </div>
            </div>
        );
    }
}
