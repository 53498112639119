import ListadoBancos from "./ListadoBancos.jsx";
import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/bancos/reporte_documentos";

const ms2p = (state) => {
    return {
        ...state.reporte_documentos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoBancos);
