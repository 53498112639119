import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderDate,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

export default class OrdenCompra extends Component {
    static propTypes = {
        orden_venta: PropTypes.object.isRequired,
        detalles_ov: PropTypes.array.isRequired,
    };

    render() {
        //  State
        const { orden_venta, detalles_ov } = this.props;
        const img_encabezado = require("assets/img/encabezado-hojas.png");
        return (
            <div id="IMPRESION-HOJA-OV" className="w-100">
                {detalles_ov.map((pagina, index) => {
                    return (
                        <div key={index} className="print-shadow hoja-carta">
                            {/* ENCABEZADO */}
                            <div className="encabezado">
                                <div className="flex3 item-img">
                                    <img
                                        src={img_encabezado}
                                        alt="encabezado-ov"
                                    />
                                </div>
                                <div className="flex4 d-flex align-items-start justify-content-between">
                                    <div className="item bg-transparent">
                                        {/* <b>Método de pago</b>
                                            <p className="m-0">Contado</p> */}
                                    </div>
                                    <div className="item">
                                        <b>No. Orden</b>
                                        <p className="m-0">
                                            {orden_venta.no_orden || "-----"}
                                        </p>
                                    </div>
                                    <div className="item">
                                        <b>Total</b>
                                        <p className="m-0">
                                            <RenderCurrency
                                                value={orden_venta.total || 0}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* INFO*/}
                            <div className="info-ov">
                                {/* DATOS GENERALES */}
                                <div className="info-ov-item">
                                    <div className="title">
                                        DATOS GENERALES:
                                    </div>
                                    <div className="info-ov-item-body">
                                        <div className="item">
                                            <b>Fecha</b>
                                            <p>
                                                <RenderDate
                                                    value={orden_venta.fecha}
                                                />
                                            </p>
                                        </div>
                                        <div className="item">
                                            <b>Cliente</b>
                                            <p>
                                                {orden_venta.cliente
                                                    ? orden_venta.cliente.nombre
                                                    : "-----"}
                                            </p>
                                        </div>
                                        <div className="item">
                                            <b>Emitida por</b>
                                            <p>
                                                {/*orden_venta.emitida_por
                                                    .first_name
                                                    ? orden_venta.emitida_por
                                                          .first_name
                                                : ""*/}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* ENTREGA */}
                                <div className="info-ov-item flex2">
                                    <div className="title">ENTREGA:</div>
                                    <div className="info-ov-item-body">
                                        <div className="d-flex justify-content-between">
                                            <div className="item flex2">
                                                <b>Fecha</b>
                                                <p>
                                                    <RenderDate
                                                        value={
                                                            orden_venta.fecha_entrega
                                                        }
                                                    />
                                                </p>
                                            </div>
                                            <div className="item">
                                                <b>Encargado</b>
                                                <p>{orden_venta.encargado}</p>
                                            </div>
                                            <div className="item">
                                                <b>Tipo entrega</b>
                                                <p>
                                                    {
                                                        orden_venta.nombre_tipo_entrega
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        {orden_venta.tipo_entrega != 10000 && (
                                            <div className="d-flex justify-content-between">
                                                <div className="item">
                                                    <b>Sucursal</b>
                                                    <p>
                                                        {
                                                            orden_venta.nombre_sucursal
                                                        }
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <b>Dirección:</b>
                                                    <p>
                                                        {
                                                            orden_venta.direccion_sucursal
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {orden_venta.tipo_entrega != 10000 && (
                                            <div className="d-flex justify-content-between">
                                                <div className="item">
                                                    <b>Departamento:</b>
                                                    <p>
                                                        {
                                                            orden_venta.departamento_sucursal
                                                        }
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <b>Municipio:</b>
                                                    <p>
                                                        {
                                                            orden_venta.municipio_sucursal
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* PAGO */}
                                <div className="info-ov-item">
                                    <div className="title">PAGO:</div>
                                    <div className="info-ov-item-body">
                                        <div className="item">
                                            <b>Fecha límite</b>
                                            <p>
                                                <RenderDate
                                                    value={
                                                        orden_venta.fecha_fin_pago
                                                    }
                                                />
                                            </p>
                                        </div>
                                        <div className="item">
                                            <b>Plazo</b>
                                            {orden_venta.periodo_pago == 0 ? (
                                                <p>Inmediato</p>
                                            ) : (
                                                <p>
                                                    {orden_venta.periodo_pago}{" "}
                                                    Días
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* DESCRIPCION */}
                            <div className="info-ov">
                                {/* DATOS GENERALES */}
                                <div className="info-ov-item">
                                    <div className="title">DESCRIPCIÓN:</div>
                                    <div className="info-ov-item-body">
                                        <div className="item">
                                            <p>
                                                {orden_venta.descripcion ||
                                                    "-----"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* PRODUCTOS */}
                            <div className="productos-ov impresion-tabla">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th
                                                className="text-right"
                                                style={{ width: "80px" }}
                                            >
                                                Cantidad
                                            </th>
                                            <th
                                                className="text-right"
                                                style={{
                                                    width: "110px !important",
                                                }}
                                            >
                                                Precio (Unidad)
                                            </th>
                                            <th
                                                className="text-right"
                                                style={{
                                                    width: "110px !important",
                                                }}
                                            >
                                                Subtotal
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pagina.detalles.map((detalle, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td scope="row">
                                                        <span>
                                                            <b>
                                                                {
                                                                    detalle.producto
                                                                }
                                                            </b>
                                                            <div>
                                                                {detalle
                                                                    .fraccion
                                                                    .producto
                                                                    .a_granel
                                                                    ? detalle
                                                                          .fraccion
                                                                          .unidad_de_medida
                                                                          .nombre
                                                                    : detalle
                                                                          .fraccion
                                                                          .nombre}
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.precio
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.sub_total
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="no_pagina d-flex flex-row justify-content-center imp-pt-2">
                                <span className="text-center">
                                    Pag. {pagina.no_pagina} de{" "}
                                    {detalles_ov.length}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
