import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReportes } from "../../../Utils/FiltrosReportes";

const ListadoCuentaPagar = (props) => {
    // state
    const {
        loader,
        data,
        fecha_vencimiento_inicial,
        fecha_vencimiento_final,
        estado_descarga,

        proveedor,
        vendedor,
        cliente,
    } = props;
    // bind
    const {
        setFecha,
        descargarListado,
        aplicarFiltros,
        setProveedor,
        setVendedor,
        setCliente,
        limpiarFiltros,
    } = props;

    //  OPCIONES
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.listarReporteRecibo;
    options.onSortChange = props.sortChange;

    useEffect(() => {
        limpiarFiltros();
        return () => {
            const enReporteRecibo = true;
            limpiarFiltros(enReporteRecibo);
        };
    }, []);
    return (
        <div className="mb-5">
            <div className="row">
                {/* RESUMEN */}
                {/* <div className="col-12">
                        <ResumenCuentaCobrar />
                    </div> */}
                {/* RESUMEN FIN */}

                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={cliente}
                        proveedor={proveedor}
                        vendedor={vendedor}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={setCliente}
                        setProveedor={setProveedor}
                        setVendedor={setVendedor}
                        aplicarFiltros={aplicarFiltros}
                        filtroReciboCP
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    const esReporteRecibo = true;
                                    descargarListado(esReporteRecibo);
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="fecha_pago"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha de pago
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo">
                                    Correlativo de pago
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo_compra">
                                    Correlativo de Compra
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="fecha_compra"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha de Compra
                                </TableHeaderColumn>

                                {/* <TableHeaderColumn
                                    dataField="vendedor"
                                    dataFormat={(cell) => cell.first_name}
                                >
                                    Vendedor
                                </TableHeaderColumn> */}

                                <TableHeaderColumn
                                    dataField="proveedor"
                                    dataFormat={(cell) => cell.nombre}
                                >
                                    Proveedor
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="observaciones">
                                    observaciones
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="total"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Total pagado
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="10%"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        const id = row.es_abono
                                            ? cell
                                            : row.id_compra;
                                        return activeFormatter({
                                            ver: "/reporte_recibos_cuenta_por_pagar/documento",
                                        })(id, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoCuentaPagar.propTypes = {
    listarReporteRecibo: PropTypes.func.isRequired,
};

export default ListadoCuentaPagar;
