import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadMask from "Utils/LoadMask/LoadMask";
import { acciones_bitacora } from "utility/variables";
import { RenderDateTime } from "../../Utils/renderField/renderReadField";
import Anterior from './Anterior';
import Actual from './Actual';
import {validarConfDecimales} from "../../Utils/validaConfiguracionDecimal";


class DetalleBitacora extends Component {
    static propTypes = {
        bitacora: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getBitacora(this.props.match.params.id);
    }

    render() {
        const {loading, bitacora, conf_empresa} = this.props;
        const descripcion = _.find(acciones_bitacora, {label: bitacora.accion}) ? _.find(acciones_bitacora, {label: bitacora.accion}) : {descripcion: bitacora.accion};
        let decimalScale = validarConfDecimales(conf_empresa);

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                            <div style={{flex: "1"}}>
                                <h3><b>Detalle de Bitácora</b></h3>
                            </div>
                            <LoadMask loading={loading} light blur>
                                <div className="d-flex flex-column flex-md-row mb-3">
                                    <div className="d-flex flex-column flex-1">
                                        <strong>Responsable</strong>
                                        {bitacora.usuario.first_name || bitacora.usuario.email}
                                    </div>
                                    <div className="d-flex flex-column flex-2">
                                        <strong>Descripción</strong>
                                        {descripcion.descripcion}
                                    </div>
                                    <div className="d-flex flex-column flex-1">
                                        <strong>Fecha y hora</strong>
                                        <RenderDateTime value={bitacora.creado} />
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-sm-row">
                                    {!!bitacora.detalle_anterior && (
                                        <Anterior detalle={JSON.parse(bitacora.detalle_anterior)} decimales={decimalScale} />
                                    )}
                                    {!!bitacora.detalle && (
                                        <Actual detalle={JSON.parse(bitacora.detalle)} anterior={JSON.parse(bitacora.detalle_anterior)} decimales={decimalScale} />
                                    )}
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetalleBitacora;
