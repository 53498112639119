import { icons } from "icons";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { formValueSelector, reduxForm } from "redux-form";
import ClienteVentaForm from "../../../Ventas/Venta/ClienteForm";
import ClienteMCEVentaForm from "../../../Ventas/Venta/ClienteMCEForm";
import NumberFormat from "react-number-format";
import ReactToPrint from "react-to-print";
import { actions as printActions } from "Utils/Print";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { pago_total, pago_credito } from "utility/variables";
import {
    RenderCurrency,
    RenderNumber,
    RenderDate,
} from "Utils/renderField/renderReadField";
import DatePicker from "Utils/DatePicker";
import { validarConfDecimales } from "../../../Utils/validaConfiguracionDecimal";
import { setEstadoFormCliente } from "../../../../../redux/modules/venta/venta";

import { credito, cotizacion, factura } from "utility/constantes";

import Pago from "./Pago";
import { connect } from "react-redux";

const Cobro = (props) => {
    //  state
    const {
        total,
        total_costo,
        venta_costo,
        movimiento,
        factura_personalizada,
        tipo_pago,
        fecha_vencimiento,
        efectivo,
        tarjeta,
        nit_cliente,
        nombre_cliente,
        loader,
        pais,
        redondeo,
        conf_venta_redondeo,
        conf_mostrar_referencia,
        tipo_punto_venta,
        print_state,
        utiliza_fel,
        cheque,
        transferencia,
        deposito,
        nota,
        nota_credito,
        codigo_nota_credito,
        nota_credito_cargada,
        estado_form_cliente,
        retencion_iva,
        exencion_iva,
        conf_ventas_al_credito,
        conf_habilitar_campo_cliente,
        buscar_nombre,
        anteriorPaso,
        tipo_venta,
        descuentos,
        porcentaje_descuento,
        estado_descarga,
    } = props;

    //  bind
    const {
        setTipoPago,
        setEfectivo,
        setTarjeta,
        setCheque,
        setTransferencia,
        setDeposito,
        leerCliente,
        limpiarDatosCliente,
        changeDatosClientes,
        setFechaVencimientoCredito,
        ingresarValeDescuento,
        setRedondeo,
        pagarVentaCotizacon,
        setNota,
        setCodigoNotaCredito,
        consultarNotaCredito,
        limpiarNotaCredito,
        setRetencionIva,
        setExencionIva,
        leerClienteNombre,
    } = props;

    //  CALCULO DEL VUELTO
    let vuelto;
    let monto_pagado = 0;
    let clienteVentaForm;
    if (tarjeta["monto"]) monto_pagado += tarjeta["monto"];
    if (efectivo["monto"]) monto_pagado += efectivo["monto"];
    if (cheque["monto"]) monto_pagado += cheque["monto"];
    if (transferencia["monto"]) monto_pagado += transferencia["monto"];

    if (deposito) monto_pagado += deposito;
    if (retencion_iva) monto_pagado += retencion_iva;
    if (exencion_iva) monto_pagado += exencion_iva;
    if (nota_credito) monto_pagado += nota_credito;

    vuelto = monto_pagado - total;
    if (venta_costo) vuelto = monto_pagado - total_costo;
    if (descuentos)
        vuelto = monto_pagado - (total - total * (porcentaje_descuento / 100));
    if (vuelto < 0) vuelto = 0;

    //  CALCULO DEL MONTO PENDIENTE DE PAGO
    let monto_pendiente = total - monto_pagado;

    let numeroDecimales = validarConfDecimales(props.conf_empresa);

    const { match } = props;
    const editar_cotizacion = match.path.includes("editar");
    const id_cotizacion = match.params.id;

    return (
        <React.Fragment>
            <div className="">
                <div className="cobro-container grid-container mt0">
                    <Pago {...props} />
                    {/*<div className="d-flex flex-row flex-xs-column justify-content-between align-items-center">
                        <div
                            className="flex3 p15"
                            style={{ backgroundColor: "white" }}
                        >
                            <h3 className="negro-dark2">
                                <b>
                                    {tipo_pago == pago_total ? (
                                        factura_personalizada ? (
                                            <span>Factura</span>
                                        ) : (
                                            <span>Recibo</span>
                                        )
                                    ) : (
                                        <span>Crédito</span>
                                    )}{" "}
                                    a nombre de
                                </b>
                            </h3>
                        </div>
                        <div
                            className="flex1 p15"
                            style={{ backgroundColor: "white" }}
                        >
                            <div className="d-none d-lg-block">
                                <div
                                    className="align-self-center negro-dark2 fnt-lg-24 fnt-sm-20"
                                    style={{ flex: "1" }}
                                >
                                    Monto total
                                </div>
                                <div
                                    className="text-center negro-dark2 fnt-lg-22 fnt-sm-18"
                                    style={{ flex: "1" }}
                                >
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>
                                </div>
                            </div>

                            <div className="monto-total container-bckg-orange d-lg-none">
                                <div
                                    className="align-self-center negro-dark2 fnt-lg-24 fnt-sm-20"
                                    style={{ flex: "1" }}
                                >
                                    <b>Monto total</b>
                                </div>
                                <div
                                    className="text-right negro-dark2 fnt-lg-22 fnt-sm-18"
                                    style={{ flex: "1" }}
                                >
                                    <b>
                                        <RenderCurrency value={total} />
                                    </b>
                                </div>
                            </div>

                            {conf_venta_redondeo && (
                                <div className="flex1 mt-3">
                                    <h4 className="negro fnt-lg-20 fnt-sm-14">
                                        Redondear
                                    </h4>
                                    <div className="d-flex align-items-center">
                                        <button
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRedondeo("abajo");
                                            }}
                                            className={`btn-icon btn-icon-primary ${
                                                redondeo == "abajo" &&
                                                "btn-icon-activo"
                                            } mr1 d-flex align-items-center justify-content-center`}
                                            title="Hacia abajo"
                                        >
                                            <em className="fa fa-arrow-down fnt-18" />
                                        </button>
                                        <button
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRedondeo("arriba");
                                            }}
                                            className={`btn-icon btn-icon-primary ${
                                                redondeo == "arriba" &&
                                                "btn-icon-activo"
                                            } mr1 d-flex align-items-center justify-content-center`}
                                            title="Hacia arriba"
                                        >
                                            <em className="fa fa-arrow-up fnt-18" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>*/}

                    {tipo_venta != cotizacion && (
                        <Tabs
                            className="content-tabs"
                            selectedIndex={tipo_pago == pago_total ? 0 : 1}
                            onSelect={(tabIndex) =>
                                setTipoPago(tabIndex == 0 ? pago_total : 200)
                            }
                        >
                            <TabList className="tab_list d-flex flex-row justify-content-between">
                                <Tab
                                    disabled={
                                        tipo_pago == pago_credito &&
                                        movimiento.sucursal !== undefined
                                    }
                                    className="content-tab-naranja flex1 text-center"
                                    selectedClassName="content-tab-naranja-active"
                                >
                                    {tipo_pago == pago_total ? (
                                        <img
                                            style={{
                                                maxWidth: "30px",
                                                marginRight: "10px",
                                            }}
                                            src={icons.efectivo_tarjeta_a}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                maxWidth: "30px",
                                                marginRight: "10px",
                                            }}
                                            src={icons.efectivo_tarjeta}
                                        />
                                    )}
                                    Efectivo/Tarjeta
                                </Tab>
                                {/*conf_ventas_al_credito === true && (
                                <Tab
                                    disabled={
                                        tipo_pago == pago_total &&
                                        movimiento.sucursal !== undefined
                                    }
                                    className="content-tab-naranja flex1 text-center"
                                    selectedClassName="content-tab-naranja-active"
                                >
                                    {tipo_pago == pago_credito ? (
                                        <img
                                            style={{
                                                maxWidth: "30px",
                                                marginRight: "10px",
                                            }}
                                            src={icons.dar_credito_a}
                                        />
                                    ) : (
                                        <img
                                            style={{
                                                maxWidth: "30px",
                                                marginRight: "10px",
                                            }}
                                            src={icons.dar_credito}
                                        />
                                    )}
                                    Dar crédito
                                </Tab>
                            )*/}
                            </TabList>

                            {/* PANEL EFECTIVO/TARJETA */}
                            <TabPanel className="pt-1 pb-1">
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.efectivo}
                                        />
                                    </div>
                                    <h4
                                        className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                        style={{ flex: "1.3" }}
                                    >
                                        Monto recibido en Efectivo
                                    </h4>
                                    <div
                                        className="align-self-center negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <NumberFormat
                                            placeholder="Cobro en efectivo"
                                            className="form-control text-right"
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            decimalScale={numeroDecimales}
                                            fixedDecimalScale={true}
                                            value={efectivo["monto"]} //efectivo
                                            thousandSeparator={true}
                                            prefix={pais == "GT" ? "Q " : "$ "}
                                            onValueChange={(values) => {
                                                setEfectivo(
                                                    parseFloat(values.value),
                                                    "monto"
                                                );
                                            }}
                                        />
                                        {/*
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="Descripcion de efectivo"
                                            value={efectivo["descripcion"]}
                                            onChange={(e) => {
                                                setEfectivo(
                                                    e.target.value,
                                                    "descripcion"
                                                );
                                            }}
                                        />*/}
                                    </div>
                                    <div style={{ flex: "0.2" }} />
                                </div>
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.tarjeta}
                                        />
                                    </div>
                                    <h4
                                        className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                        style={{ flex: "1.3" }}
                                    >
                                        Monto Recibido en Tarjeta
                                    </h4>
                                    <div
                                        className="align-self-center negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <NumberFormat
                                            placeholder="Cobro en tarjeta"
                                            className="form-control text-right"
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            decimalScale={numeroDecimales}
                                            fixedDecimalScale={true}
                                            value={tarjeta["monto"]}
                                            thousandSeparator={true}
                                            prefix={pais == "GT" ? "Q " : "$ "}
                                            onValueChange={(values) => {
                                                setTarjeta(
                                                    parseFloat(values.value),
                                                    total,
                                                    "monto"
                                                );
                                            }}
                                        />
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="No de Documento - tarjeta"
                                            value={tarjeta["descripcion"]}
                                            onChange={(e) => {
                                                setTarjeta(
                                                    e.target.value,
                                                    null,
                                                    "descripcion"
                                                );
                                            }}
                                        />
                                    </div>
                                    <div style={{ flex: "0.2" }} />
                                </div>

                                {/* CHEQUE */}
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.cheque}
                                        />
                                    </div>
                                    <h4
                                        className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                        style={{ flex: "1.3" }}
                                    >
                                        Monto Recibido en Cheque
                                    </h4>
                                    <div
                                        className="align-self-center negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <NumberFormat
                                            placeholder="Cobro en cheque"
                                            className="form-control text-right"
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            decimalScale={numeroDecimales}
                                            fixedDecimalScale={true}
                                            value={cheque["monto"]}
                                            thousandSeparator={true}
                                            prefix={pais == "GT" ? "Q " : "$ "}
                                            onValueChange={(values) => {
                                                setCheque(
                                                    parseFloat(values.value),
                                                    total,
                                                    "monto"
                                                );
                                            }}
                                        />{" "}
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="No de Documento - cheque"
                                            value={cheque["descripcion"]}
                                            onChange={(e) => {
                                                setCheque(
                                                    e.target.value,
                                                    null,
                                                    "descripcion"
                                                );
                                            }}
                                        />
                                    </div>
                                    <div style={{ flex: "0.2" }} />
                                </div>

                                {/* TRANSFERENCIA ELECTRONICA */}
                                <div
                                    style={{ backgroundColor: "white" }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.cheque}
                                        />
                                    </div>
                                    <h4
                                        className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                        style={{ flex: "1.3" }}
                                    >
                                        Monto Recibido en Transferencia
                                    </h4>
                                    <div
                                        className="align-self-center negro-dark2"
                                        style={{ flex: "1" }}
                                    >
                                        <NumberFormat
                                            placeholder="Cobro en transferencia"
                                            className="form-control text-right"
                                            disabled={
                                                movimiento.sucursal !==
                                                undefined
                                            }
                                            decimalScale={numeroDecimales}
                                            fixedDecimalScale={true}
                                            value={transferencia["monto"]}
                                            thousandSeparator={true}
                                            prefix={pais == "GT" ? "Q " : "$ "}
                                            onValueChange={(values) => {
                                                setTransferencia(
                                                    parseFloat(values.value),
                                                    total,
                                                    "monto"
                                                );
                                            }}
                                        />
                                        <input
                                            type="text"
                                            className="form-control text-right"
                                            placeholder="No de Documento - transferencia"
                                            value={transferencia["descripcion"]}
                                            onChange={(e) => {
                                                setTransferencia(
                                                    e.target.value,
                                                    null,
                                                    "descripcion"
                                                );
                                            }}
                                        />
                                    </div>
                                    <div style={{ flex: "0.2" }} />
                                </div>

                                {/* NOTA */}

                                <div
                                    style={{
                                        backgroundColor: "white",
                                        fontSize: "18px",
                                    }}
                                    className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                >
                                    <div
                                        style={{ flex: "0.5" }}
                                        className="align-self-center text-center d-xs-none"
                                    >
                                        <img
                                            style={{ maxWidth: "35px" }}
                                            src={icons.cambio}
                                        />
                                    </div>
                                    <div
                                        className="d-none d-lg-block align-self-center"
                                        style={{ flex: "1.3" }}
                                    >
                                        <b>Vuelto en efectivo</b>
                                    </div>
                                    <div
                                        className="d-lg-none align-self-center"
                                        style={{
                                            flex: "1.3",
                                            backgroundColor: "#E2E2E2",
                                        }}
                                    >
                                        <b>Vuelto en efectivo</b>
                                    </div>
                                    <div
                                        className="d-none d-lg-block align-self-center text-right"
                                        style={{
                                            flex: "1",
                                            marginRight: "15px",
                                        }}
                                    >
                                        <b>
                                            <RenderCurrency value={vuelto} />
                                        </b>
                                    </div>
                                    <div
                                        className="d-lg-none align-self-center text-right"
                                        style={{
                                            flex: "1",
                                            marginRight: "15px",
                                            backgroundColor: "#E2E2E2",
                                        }}
                                    >
                                        <b>
                                            <RenderCurrency value={vuelto} />
                                        </b>
                                    </div>
                                    <div style={{ flex: "0.2" }} />
                                </div>
                            </TabPanel>
                            {/* PANEL CREDITO */}
                            {conf_ventas_al_credito === true && (
                                <TabPanel className="pt-1 pb-1">
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={
                                                    icons.fecha_vencimiento_gris
                                                }
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Fecha de vencimiento
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <DatePicker
                                                value={fecha_vencimiento}
                                                minDate={new Date()}
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                onChange={(date) => {
                                                    setFechaVencimientoCredito(
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                className=""
                                                style={{ maxWidth: "35px" }}
                                                src={icons.efectivo}
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Anticipo en Efectivo
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="Anticipo en efectivo"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={efectivo}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setEfectivo(
                                                        parseFloat(values.value)
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                className=""
                                                style={{ maxWidth: "35px" }}
                                                src={icons.tarjeta}
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Anticipo en Tarjeta
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="Anticipo en tarjeta"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={tarjeta}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setTarjeta(
                                                        parseFloat(
                                                            values.value
                                                        ),
                                                        total
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    {/* CHEQUE */}
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={icons.cheque}
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Anticipo en Cheque
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="Anticipo en cheque"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={cheque}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setCheque(
                                                        parseFloat(
                                                            values.value
                                                        ),
                                                        total
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    {/* DEPOSITO */}
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={icons.deposito}
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Anticipo en Depósito
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="Anticipo en depósito"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={deposito}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setDeposito(
                                                        parseFloat(
                                                            values.value
                                                        ),
                                                        total
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    {/* RETENCION IVA */}
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={
                                                    icons.retencion_exencion_iva
                                                }
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Monto Recibido por Retención de IVA
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="cobro en retención de IVA"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={retencion_iva}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setRetencionIva(
                                                        parseFloat(
                                                            values.value
                                                        ),
                                                        total
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>

                                    {/* EXENCION IVA */}
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={
                                                    icons.retencion_exencion_iva
                                                }
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Monto Recibido por Exención de IVA
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <NumberFormat
                                                placeholder="cobro en retención de IVA"
                                                className="form-control text-right"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                decimalScale={numeroDecimales}
                                                fixedDecimalScale={true}
                                                value={exencion_iva}
                                                thousandSeparator={true}
                                                prefix={
                                                    pais == "GT" ? "Q " : "$ "
                                                }
                                                onValueChange={(values) => {
                                                    setExencionIva(
                                                        parseFloat(
                                                            values.value
                                                        ),
                                                        total
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    {/* NOTA DE CRÉDITO */}
                                    {utiliza_fel && (
                                        <div
                                            style={{ backgroundColor: "white" }}
                                            className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                        >
                                            <div
                                                style={{ flex: "0.5" }}
                                                className="align-self-center text-center d-xs-none"
                                            >
                                                <img
                                                    style={{ maxWidth: "35px" }}
                                                    src={icons.nota_credito}
                                                />
                                            </div>
                                            <div
                                                className="d-flex flex-column"
                                                style={{ flex: "1.3" }}
                                            >
                                                <h4 className="negro-dark2 fnt-lg-18 fnt-sm-16">
                                                    Monto Recibido en Nota de
                                                    Crédito
                                                </h4>

                                                {/* INFO NOTA CREDITO */}
                                                {nota_credito_cargada && (
                                                    <div className="w-100 py-2 px-1 d-flex flex-column">
                                                        {/* FECHA */}
                                                        <div className="d-flex mb">
                                                            <b className="mr-1">
                                                                Fecha
                                                            </b>{" "}
                                                            <RenderDate
                                                                value={
                                                                    nota_credito_cargada
                                                                        .nota_credito_fel
                                                                        .fecha_emision
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex mb">
                                                            <b className="mr-1">
                                                                Código
                                                            </b>{" "}
                                                            {
                                                                nota_credito_cargada.codigo
                                                            }
                                                        </div>
                                                        <div className="d-flex mb">
                                                            <b className="mr-1">
                                                                Número de
                                                                autorización
                                                            </b>{" "}
                                                            {
                                                                nota_credito_cargada
                                                                    .nota_credito_fel
                                                                    .uuid
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="negro-dark2"
                                                style={{ flex: "1" }}
                                            >
                                                <div className="d-flex justify-content-end">
                                                    {nota_credito_cargada ? (
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={
                                                                limpiarNotaCredito
                                                            }
                                                            disabled={
                                                                movimiento.sucursal !==
                                                                undefined
                                                            }
                                                        >
                                                            Eliminar
                                                        </button>
                                                    ) : (
                                                        <input
                                                            id="nota_credito"
                                                            name="nota_credito"
                                                            type="text"
                                                            placeholder="Código o número de autorización"
                                                            className="form-control"
                                                            disabled={
                                                                movimiento.sucursal !==
                                                                undefined
                                                            }
                                                            ref={(node) => {
                                                                ref_codigo_nota_credito =
                                                                    node;
                                                                if (
                                                                    ref_codigo_nota_credito
                                                                ) {
                                                                    ref_codigo_nota_credito.value =
                                                                        codigo_nota_credito;
                                                                }
                                                            }}
                                                            onKeyPress={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    "Enter"
                                                                ) {
                                                                    e.preventDefault();
                                                                    if (
                                                                        ref_codigo_nota_credito.value
                                                                    ) {
                                                                        consultarNotaCredito(
                                                                            total
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                            onChange={() => {
                                                                setCodigoNotaCredito(
                                                                    ref_codigo_nota_credito.value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                {nota_credito_cargada && (
                                                    <div className="w-100 text-right my-3 fnt-18">
                                                        <b>
                                                            <RenderCurrency
                                                                value={
                                                                    nota_credito_cargada.total_importe
                                                                }
                                                            />
                                                        </b>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    flex: "0.2",
                                                    height: "30px",
                                                }}
                                                className="d-flex justify-content-center"
                                            >
                                                {!nota_credito_cargada && (
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={() =>
                                                            consultarNotaCredito(
                                                                total
                                                            )
                                                        }
                                                        disabled={
                                                            movimiento.sucursal !==
                                                            undefined
                                                        }
                                                    >
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {/* NOTA */}
                                    <div
                                        style={{ backgroundColor: "white" }}
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                style={{ maxWidth: "35px" }}
                                                src={icons.notas}
                                            />
                                        </div>
                                        <h4
                                            className="align-self-center negro-dark2 fnt-lg-18 fnt-sm-16"
                                            style={{ flex: "1.3" }}
                                        >
                                            Nota
                                        </h4>
                                        <div
                                            className="align-self-center negro-dark2"
                                            style={{ flex: "1" }}
                                        >
                                            <textarea
                                                id="notas"
                                                name="textarea"
                                                disabled={
                                                    movimiento.sucursal !==
                                                    undefined
                                                }
                                                className="form-control"
                                                value={nota}
                                                placeholder="Ingrese la nota"
                                                onChange={(event) => {
                                                    setNota(event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                    <div
                                        className="d-flex justify-content-between grid-end-seccion padding-5 mb-2 mt-2"
                                        style={{ fontSize: "18px" }}
                                    >
                                        <div
                                            style={{ flex: "0.5" }}
                                            className="align-self-center text-center d-xs-none"
                                        >
                                            <img
                                                className=""
                                                style={{ maxWidth: "35px" }}
                                                src={icons.cambio}
                                            />
                                        </div>
                                        <div
                                            className="d-none d-lg-block align-self-center"
                                            style={{ flex: "1.3" }}
                                        >
                                            <b>Monto pendiente de pago</b>
                                        </div>
                                        <div
                                            className="d-lg-none align-self-center"
                                            style={{
                                                flex: "1.3",
                                                backgroundColor: "#E2E2E2",
                                            }}
                                        >
                                            <b>Monto pendiente de pago</b>
                                        </div>
                                        <div
                                            className="d-none d-lg-block align-self-center text-right"
                                            style={{
                                                flex: "1",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <b>
                                                <RenderCurrency
                                                    value={monto_pendiente}
                                                />
                                            </b>
                                        </div>
                                        <div
                                            className="d-lg-none align-self-center text-right"
                                            style={{
                                                flex: "1",
                                                marginRight: "15px",
                                                backgroundColor: "#E2E2E2",
                                            }}
                                        >
                                            <b>
                                                <RenderCurrency
                                                    value={monto_pendiente}
                                                />
                                            </b>
                                        </div>
                                        <div style={{ flex: "0.2" }} />
                                    </div>
                                </TabPanel>
                            )}
                        </Tabs>
                    )}
                </div>
                {/* Buttons */}
                {tipo_venta != cotizacion ? (
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <button
                                type="button"
                                className="btn btn-tertiary mr-1"
                                onClick={anteriorPaso}
                            >
                                Regresar
                            </button>
                            {/*movimiento.sucursal === undefined && (
                            <button
                                className="btn btn-tertiary m-1"
                                type="button"
                                onClick={() => {
                                    this.props.cambioCobro();
                                }}
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar
                            </button>
                        )*/}

                            <button
                                className="btn btn-primary m-1"
                                type="button"
                                onClick={() => {
                                    // editar_cotizacion
                                    //     ? props.editarCotizacion(total)
                                    //     : props.registrarOV(total);
                                    props.registrarOV(
                                        total,
                                        editar_cotizacion,
                                        id_cotizacion
                                    );
                                }}
                                disabled={loader || estado_descarga}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-check"
                                    }`}
                                ></em>
                                &nbsp; Finalizar venta
                            </button>
                        </div>
                        {/*movimiento.sucursal === undefined && !utiliza_fel && (
                        <div className="d-flex flex-column flex-sm-row justify-content-center mt">
                            <button
                                className="btn btn-outline-secondary m-2 sm-col-12"
                                onClick={() => {
                                    ingresarValeDescuento();
                                }}
                            >
                                <em className="fa fa-minus fa-lg mr"></em>
                                Vale de descuento
                            </button>
                        </div>
                    )*/}

                        {/* <button className="btn btn-outline-primary ml-1 mt" onClick={() => {
                        ingresarValeDescuento();
                    }}>
                        <em className="fa fa-minus fa-lg mr"></em>
                        Vale de descuento
                    </button> */}

                        {tipo_pago == pago_credito &&
                            movimiento.sucursal != undefined && (
                                <div className="d-flex flex-row align-items-center justify-content-center">
                                    {/*<ReactToPrint
                                    trigger={() => {
                                            <a className="text-primary venta_vale">
                                                <b>IMPRIMIR {factura_personalizada ? (<span>FACTURA</span>) : (<span>RECIBO</span>)}</b>
                                            </a>
                                        }
                                    }
                                    content={() => 'reciboVenta' }
                                />*/}
                                    {/* <a  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.print('reciboVenta');
                                }}>
                                    <b>IMPRIMIR {factura_personalizada ? (<span>FACTURA</span>) : (<span>RECIBO</span>)}</b>
                                </a> */}
                                </div>
                            )}
                    </div>
                ) : (
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <button
                                type="button"
                                className="btn btn-tertiary mr-1"
                                onClick={anteriorPaso}
                            >
                                Regresar
                            </button>

                            <button
                                className="btn btn-primary m-1"
                                type="button"
                                onClick={() => {
                                    console.log("Registrando cotizacion...");
                                    //props.registrarOV(total);
                                }}
                                disabled={loader}
                            >
                                <img src={icons.save} alt="" />
                                Finalizar cotizacion
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

let CobroForm = reduxForm({
    form: "wizardCrearOVForm",
    // a unique name for the form
    //form: "MediosPagosForm",
    destroyOnUnmount: false, //        <------ preserve DatePickerform data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate,
})(Cobro);

const selector = formValueSelector("wizardCrearOVForm");

CobroForm = connect((state) => {
    const descuentos = selector(state, "descuentos");
    const porcentaje_descuento = selector(state, "porcentaje_descuento");
    const total_descuento = selector(state, "total_descuento");

    return {
        descuentos,
        porcentaje_descuento: porcentaje_descuento ? porcentaje_descuento : 5,
        total_descuento,
    };
})(CobroForm);

export default CobroForm;
