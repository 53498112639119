import React from "react";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { required } from "../../../../../utility/validation";
import { renderField } from "Utils/renderField/renderField";
import {
    renderDatePicker,
    renderNumberFormat,
    renderSearchSelect,
    renderSelectField,
    renderTextArea,
} from "../../../Utils/renderField/renderField";
import { TIPO_MOVIMIENTO_EDICION } from "../../../../../utility/variables";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";

const listado_bancos = [];
const IngresoDocumentosForm = ({
    intitalData,
    onSubmit,
    ver = false,
    editar = false,
}) => {
    const { getOptions: getBancos } = useAsyncSearch("bancos", listado_bancos);

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    monto: 0,
                    ...intitalData,
                }}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                render={({ handleSubmit, submitting, form }) => {
                    if (!window.setFormValue)
                        window.setFormValue = form.mutators.setValue;
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            <div className="mb-5 card card-small px-3 py-4">
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="fecha">Fecha</label>
                                        <Field
                                            name="fecha"
                                            component={renderDatePicker}
                                            className="form-control"
                                            validate={required}
                                            disabled={ver}
                                        />
                                        {/* <Field
                                        name="nombre_banco"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    /> */}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="codigo_documento">
                                            Código de Documento
                                        </label>
                                        <Field
                                            name="codigo_documento"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                            disabled={ver}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="banco">
                                            No. Cuenta
                                        </label>
                                        <Field
                                            name="banco"
                                            component={renderSearchSelect}
                                            loadOptions={getBancos}
                                            labelKey="banco_cuenta"
                                            valueKey="id"
                                            className="form-control"
                                            disabled={ver}
                                        />
                                        {/* <Field
                                        name="direccion"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    /> */}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="tipo_movimiento">
                                            Tipo de movimiento
                                        </label>
                                        <Field
                                            name="tipo_movimiento"
                                            component={renderSelectField}
                                            options={TIPO_MOVIMIENTO_EDICION}
                                            placeholder="Seleccionar"
                                            // parse={setTipoOrigen}
                                            type="text"
                                            className="form-control"
                                            disabled={ver}
                                        />
                                        {/* <Field
                                        name="saldo"
                                        component={renderNumberFormat}
                                        type="text"
                                        prefix={"Q "}
                                        decimalScale={2}
                                        placeholder="Q"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    /> */}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>

                                <div className="d-flex flex-column flex-md-row">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="monto">Monto</label>
                                        <Field
                                            name="monto"
                                            component={renderNumberFormat}
                                            type="text"
                                            prefix={"Q "}
                                            decimalScale={2}
                                            placeholder="Q"
                                            className="form-control"
                                            validate={required}
                                            disabled={ver}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="observacion">
                                            Observación
                                        </label>
                                        <Field
                                            name="observacion"
                                            component={renderTextArea}
                                            type="text"
                                            rows={3}
                                            className="form-control"
                                            disabled={ver}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column-reverse flex-md-row justify-content-center py-4">
                                    <Link
                                        to="/conciliacion_bancaria/"
                                        className="btn btn-outline-secondary mt-4 mt-md-0 mr-0 mr-md-3"
                                    >
                                        Volver
                                    </Link>
                                    {!ver && (
                                        <button
                                            type="submit"
                                            className="btn btn-secondary"
                                            disabled={submitting}
                                        >
                                            {editar ? "Editar" : "Agregar"}{" "}
                                            Documento
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default IngresoDocumentosForm;
