import moment from "moment";
import React from "react";
import { Async } from "react-select";
import DatePicker from "Utils/DatePicker";
import useAsyncSearch from "../../../hooks/useAsyncSearch";

const listado_usuarios = [];
const FiltrosCompletados = ({
    fecha_completado_inicial,
    fecha_completado_final,
    usuario_pendiente,
    usuario_completado,
    setFecha,
    setUsuarioValue,
}) => {
    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios
    );

    return (
        <div>
            <div className="row col-8 col-sm-12 col-md-10">
                <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                    <label htmlFor="">Fecha inicial</label>
                    <DatePicker
                        value={fecha_completado_inicial}
                        maxDate={
                            new Date(
                                moment(
                                    moment().format("YYYY-MM-DD"),
                                    "YYYY-MM-DD"
                                )
                            )
                        }
                        onChange={(date) => {
                            setFecha(
                                "Inicial",
                                date,
                                false,
                                "fecha_completado_inicial"
                            );
                        }}
                    />
                </div>
                <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                    <label htmlFor="">Fecha final</label>
                    <DatePicker
                        value={fecha_completado_final}
                        minDate={
                            new Date(
                                moment(fecha_completado_inicial, "YYYY-MM-DD")
                            )
                        }
                        onChange={(date) => {
                            setFecha(
                                "Final",
                                date,
                                false,
                                "fecha_completado_final"
                            );
                        }}
                    />
                </div>
                <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                    <label>Enviado por</label>
                    <Async
                        className="form-control"
                        labelKey="first_name"
                        valueKey="id"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getUsuarios}
                        placeholder={"Usuario"}
                        onChange={(e) => {
                            setUsuarioValue(e, true);
                        }}
                        value={usuario_pendiente}
                    />
                </div>
                <div className="col-12 col-lg-3 col-md-3 form-group pl-0">
                    <label>Recibido por</label>
                    <Async
                        className="form-control"
                        labelKey="first_name"
                        valueKey="id"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getUsuarios}
                        placeholder={"Usuario"}
                        onChange={(e) => {
                            setUsuarioValue(e);
                        }}
                        value={usuario_completado}
                    />
                </div>
            </div>
        </div>
    );
};

export default FiltrosCompletados;
