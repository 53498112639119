import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";

const endpoint = "movimientos";

export const DATA = "NC_CLI_DATA";
export const LOADER = "NC_CLI_LOADER";
export const SORT = "NC_CLI_SORT";
export const PAGE = "NC_CLI_PAGE";
export const ESTADO_DESCARGA = "NC_CLI_ESTADO_DESCARGA";

export const FECHA_FINAL = "NC_CLI_FECHA_FINAL";
export const FECHA_INICIAL = "NC_CLI_FECHA_INICIAL";
export const PROVEEDOR = "NC_CLI_PROVEEDOR";
export const CLIENTE = "NC_CLI_CLIENTE";
export const VENDEDOR = "NC_CLI_VENDEDOR";

// ------------------------------------
// Actions
// ------------------------------------

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();

        const {
            ordering,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            cliente,
            vendedor,
        } = store.reporte_nc_cliente;

        let params = {
            ordering,
            page,
            fecha_inicial: fecha_vencimiento_inicial,
            fecha_final: fecha_vencimiento_final,

            cliente: cliente ? cliente.id : "",
            vendedor,
        };
        api.get(`${endpoint}/reporte_nc_cliente`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const setCliente = (value) => (dispatch) => {
    dispatch(_setCliente(value));
    // dispatch(listar());
};

export const setProveedor = (value) => (dispatch) => {
    dispatch(_setProveedor(value));
    // dispatch(listar());
};

export const setVendedor = (value) => (dispatch) => {
    dispatch(_setVendedor(value));
    // dispatch(listar());
};

export const aplicarFiltros = () => (dispatch) => {
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_nc_cliente = store.reporte_nc_cliente;
    if (key === "Inicial") {
        const fecha_vencimiento_inicial = new Date(value);
        const fecha_vencimiento_final = new Date(
            reporte_nc_cliente.fecha_vencimiento_final
        );
        // if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_vencimiento_inicial = new Date(
            reporte_nc_cliente.fecha_vencimiento_inicial
        );
        const fecha_vencimiento_final = new Date(value);
        if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
            dispatch(setFechaFinal(value));
    }
    // dispatch(listar());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        ordering,
        fecha_vencimiento_inicial,
        fecha_vencimiento_final,

        cliente,
        vendedor,
    } = store.reporte_nc_cliente;

    let params = {
        ordering,
        fecha_inicial: fecha_vencimiento_inicial,
        fecha_final: fecha_vencimiento_final,

        cliente: cliente ? cliente.id : "",
        vendedor,
    };

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_nc_cliente`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const limpiarFiltros =
    (enReporteRecibo = false) =>
    (dispatch) => {
        dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
        dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
        dispatch(setCliente(null));

        dispatch(setVendedor(""));
        dispatch(setProveedor(""));
        dispatch(setData({}));
    };
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setFechaInicial = (fecha_vencimiento_inicial) => ({
    type: FECHA_INICIAL,
    fecha_vencimiento_inicial,
});

export const setFechaFinal = (fecha_vencimiento_final) => ({
    type: FECHA_FINAL,
    fecha_vencimiento_final,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setProveedor = (proveedor) => ({
    type: PROVEEDOR,
    proveedor,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const actions = {
    listar,
    setCliente,
    sortChange,
    setFecha,

    setProveedor,
    aplicarFiltros,
    setVendedor,

    descargarListado,
    limpiarFiltros,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_vencimiento_inicial }) => {
        return {
            ...state,
            fecha_vencimiento_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_vencimiento_final }) => {
        return {
            ...state,
            fecha_vencimiento_final,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [PROVEEDOR]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    ordering: "",
    loader: false,
    fecha_vencimiento_inicial: moment().format("YYYY-MM-DD"),
    fecha_vencimiento_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,

    cliente: "",
    proveedor: "",
    vendedor: "",
};

export default handleActions(reducers, initialState);
