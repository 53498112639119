import React, { useEffect } from "react";
import _ from "lodash";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { formatoEmpty } from "Utils/CamposComunes";
import { TIPO_MOVIMIENTO_EDICION } from "../../../../../utility/variables";
import CamposFiltros from "./CamposFiltros";

const ListadoBancos = ({
    listar,
    page,
    data,
    loader = false,
    usuario,
    fecha_inicial,
    fecha_final,
    banco,
    sortChange,
    estado_descarga,
    buscar,
    search,
    setFecha,
    setUsuario,
    descargarListado,
    setBanco,
    limpiar,
}) => {
    useEffect(() => {
        listar(page);

        return () => {
            limpiar();
        };
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <button
                        className="btn btn-secondary m-1 align-self-center"
                        disabled={estado_descarga}
                        onClick={() => {
                            descargarListado();
                        }}
                    >
                        <em
                            className={`fa ${
                                estado_descarga
                                    ? "fa-spinner fa-pulse"
                                    : "fa-download"
                            }`}
                        ></em>
                        &nbsp;Descargar Reporte
                    </button>
                    {/* <Link
                        className="btn btn-primary mb-sm-10"
                        to="/ingreso_documentos/crear/"
                    >
                        <em className="fa fa-plus"></em> Agregar Documento
                    </Link> */}

                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Listado de Movimientos"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <CamposFiltros
                            usuario={usuario}
                            fecha_inicial={fecha_inicial}
                            fecha_final={fecha_final}
                            banco={banco}
                            setFecha={setFecha}
                            setUsuario={setUsuario}
                            setBanco={setBanco}
                        />
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={!data.results ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="codigo_documento"
                                    dataSort
                                    dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataSort
                                    // dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="banco"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.nombre_banco;
                                        }
                                        return "---";
                                    }}
                                    // width={"20rem"}
                                >
                                    Banco
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="banco"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.numero_cuenta;
                                        }
                                        return "---";
                                    }}
                                    // width={"20rem"}
                                >
                                    No. Cuenta
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo_movimiento"
                                    dataSort
                                    dataFormat={(cell) => {
                                        try {
                                            if (cell) {
                                                return TIPO_MOVIMIENTO_EDICION[
                                                    cell
                                                ]["label"];
                                            }
                                        } catch (error) {
                                            return "---";
                                        }
                                    }}
                                    // width={"20rem"}
                                >
                                    Tipo movimiento
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="vendedor"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.first_name;
                                        }
                                        return "---";
                                    }}
                                >
                                    Vendedores
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="monto"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        if (
                                            row.tipo_movimiento === 6 ||
                                            row.tipo_movimiento === 8
                                        ) {
                                            return "---";
                                        }
                                        const costo_quetzales = parseFloat(
                                            cell || 0
                                        ).toFixed(2);
                                        return `Q ${costo_quetzales}`;
                                    }}
                                    // width={"20rem"}
                                >
                                    Monto Entrada
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="monto"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        if (
                                            row.tipo_movimiento !== 6 &&
                                            row.tipo_movimiento !== 8
                                        ) {
                                            return "---";
                                        }
                                        const costo_quetzales = parseFloat(
                                            cell || 0
                                        ).toFixed(2);
                                        return `Q ${costo_quetzales}`;
                                    }}
                                    // width={"20rem"}
                                >
                                    Monto Salida
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="proveedor"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.nombre;
                                        }
                                        return "---";
                                    }}
                                >
                                    Proveedor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={activeFormatter({
                                        ver: "/conciliacion_bancaria/detalle",
                                    })}
                                    width={"10rem"}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListadoBancos;
