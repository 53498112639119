import _ from "lodash";
import { api } from "api";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";

/*

import useAsyncOptions from "../../../../hooks/useAsyncOptions";
import SubFamiliaForm from "./SubFamilia/SubFamiliaForm"*/
import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
} from "../../../../utility/validation";

import {
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    //AsyncSelectField,
    renderAsyncSelectField,
    renderCurrency,
    SelectField,
} from "Utils/renderField/renderField";

import SubFamiliaForm from "./SubFamilia/SubFamiliaForm";

const FamiliasForm = ({
    usuariosDatos,
    onSubmit,
    view = false,
    update = false,
    id,
    subFamilias,
    setSubFamilia,
    data,
}) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                mutators={{
                    // expect (field, value) args from the mutator
                    setInput: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                initialValues={{
                    panel_pedidos: false,
                    ...data,
                }}
                render={({ handleSubmit, submitting, values, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="mb-5  px-3 py-4">
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">Nombre *</label>
                                    <Field
                                        name="nombre"
                                        component={renderField}
                                        placeholder="Familia"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Porcentaje de mayorista *
                                    </label>
                                    <Field
                                        name="porcentaje_mayorista"
                                        component={renderPercent}
                                        decimalScale={2}
                                        placeholder="%"
                                        suffix={"%"}
                                        type="number"
                                        className="form-control"
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Porcentaje de minorista *
                                    </label>
                                    <Field
                                        name="porcentaje_minorista"
                                        component={renderPercent}
                                        decimalScale={2}
                                        placeholder="%"
                                        suffix={"%"}
                                        type="number"
                                        className="form-control"
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Porcentaje de distribuidor *
                                    </label>
                                    <Field
                                        name="porcentaje_utilidad"
                                        component={renderPercent}
                                        decimalScale={2}
                                        placeholder="%"
                                        suffix={"%"}
                                        type="number"
                                        className="form-control"
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Publicar en panel de pedidos de clientes
                                        *
                                    </label>
                                    <div className="d-flex">
                                        <label className="mr-2">No</label>
                                        <Field
                                            name="panel_pedidos"
                                            component={renderSwitch}
                                            // placeholder="Ingrese nombre del donante"
                                            type="text"
                                            className="form-control"
                                            disabled={view}
                                        />
                                        <label className="ml-2">Si</label>
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            {/* Tabla de SubFamilias */}
                            <SubFamiliaForm
                                setSubFamilia={setSubFamilia}
                                subFamilias={subFamilias}
                                ver={view}
                                editar={update}
                            />
                        </div>
                        <hr />
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4">
                            <Link
                                to="/familias/"
                                className="btn btn-outline-secondary mt-4 mt-md-0"
                            >
                                Volver
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    {update ? "Actualizar" : "Agregar"} Familia
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default FamiliasForm;
