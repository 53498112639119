import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { SucursalOption, SucursalValue } from "../../SucursalOption";
import { Link } from "react-router-dom";
import { PrintContainer, actions as printActions } from "Utils/Print";
import { icons } from "icons";
import { DropDownMenu, DropDownDirection } from "react-dropdown-advanced";

class ToolBarProducts extends Component {
    render() {
        //  state

        const { sucursal, sucursales, oficina, setOficina, empresa } =
            this.props;

        return (
            <React.Fragment>
                {this.props.buscar !== undefined && (
                    <div className="react-bs-table-tool-bar mt-0 ">
                        <div className={`${""}`}>
                            <div className="row align-items-md-center mb1 ">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-3 p-0">
                                    {this.props.titulo}
                                </div>

                                <div className="col-12 col-xs-12 col-sm-12 col-md-5 ">
                                    <div className="nav navbar-right sucursal-nav-select">
                                        <Select
                                            optionComponent={SucursalOption}
                                            valueComponent={SucursalValue}
                                            className="col-12 col-xs-12 col-sm-12 col-md-10 mt-1 b-transparent"
                                            onChange={(value) => {
                                                setOficina(value.id);
                                                //this.setSucursal(value.id);
                                            }}
                                            backspaceRemovesValue={false}
                                            searchable={false}
                                            clearable={false}
                                            value={oficina}
                                            placeholder="Sucursal"
                                            options={sucursales}
                                            valueKey={"id"}
                                            labelKey={"nombre"}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-xs-12 col-sm-12 col-md-4 ">
                                    <input
                                        autoComplete="off"
                                        id="buscar"
                                        type="text"
                                        name="buscar"
                                        placeholder="Buscar"
                                        autoFocus={
                                            this.props.autoFocus
                                                ? this.props.autoFocus
                                                : false
                                        }
                                        ref={(node) => {
                                            this.buscar = node;
                                            if (this.buscar) {
                                                this.buscar.value =
                                                    this.props.search;
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                if (
                                                    this.props.agregarPrimer !==
                                                    undefined
                                                ) {
                                                    if (this.buscar.value) {
                                                        this.props.agregarPrimer(
                                                            this.buscar.value
                                                        );
                                                        this.buscar.value = "";
                                                    }
                                                }
                                            }
                                        }}
                                        onChange={() => {
                                            if (this.buscar) {
                                                this.props.buscar(
                                                    this.buscar.value
                                                );
                                            }
                                        }}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

ToolBarProducts.propTypes = {
    filtar: PropTypes.any,
    titulo: PropTypes.any,
    subtitulo: PropTypes.any,
    editar: PropTypes.any,
    buscar: PropTypes.any,
    regresar: PropTypes.any,
    url_impresion: PropTypes.any,
};

export default ToolBarProducts;
