import { api } from "api";
import Swal from "sweetalert2";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";
import moment from "moment";

const endpoint = "bodegas";

export const DATA = "VENTAS_POR_FAMILIAS_DATA";
export const LOADER = "VENTAS_POR_FAMILIA_LOADER";
export const SORT = "VENTAS_POR_FAMILIA_SORT";
export const PAGE = "VENTAS_POR_FAMILIA_PAGE";
export const ESTADO_DESCARGA = "VENTAS_POR_FAMILIA_ESTADO_DESCARGA";
export const FILTRO = "REPORTE_GASTOS_FILTRO";

export const SUCURSAL = "VENTAS_POR_FAMILIA_SUCURSAL";
export const FAMILIA = "VENTAS_POR_FAMILIA_FAMILIA";
export const SUBFAMILIA = "VENTAS_POR_FAMILIA_SUBFAMILIA";
export const FECHA_FINAL = "VENTAS_POR_FAMILIA_FECHA_FINAL";
export const FECHA_INICIAL = "VENTAS_POR_FAMILIA_FECHA_INICIAL";
export const VENDEDOR = "VENTAS_POR_FAMILIA_VENDEDOR";
export const TOTAL_VENTAS = "VENTAS_POR_FAMILIA_TOTAL_VENTAS"
export const TOTAL_CANTIDADES = "VENTAS_POR_FAMILIA_TOTAL_CANTIDADES"
//export const SALIDA_ARTICULOS_VENDIDOS = "VENTAS_POR_FAMILIA_SALIDA";
// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const {
            ordering,
            filtro,
            familia,
            subfamilia,
            sucursal,
            fecha_final,
            fecha_inicial,
            vendedor,
        } = store.reporte_ventas_por_familia;

        let params = {
            ordering,
            filtro,
            page,

            familia,
            subfamilia,
            param_s: sucursal ? sucursal : "",
            fecha_final,
            fecha_inicial,
            vendedor,
            es_articulos_vendidos: 1,
        };
        api.get(`${endpoint}/reporte_ventas_por_familia`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    if (resp.extra_data && resp.extra_data.gran_total_ventas && resp.extra_data.gran_total_cantidades)
                        dispatch(setTotalVentas(resp.extra_data.gran_total_ventas));
                        dispatch(setTotalCantidad(resp.extra_data.gran_total_cantidades));
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const setSucursal = (value) => (dispatch) => {
    dispatch(_setSucursal(value));
    // dispatch(listar());
};

export const setFamilia = (value) => (dispatch) => {
    dispatch(_setFamilia(value));
    // dispatch(listar());
};


export const setSubfamilia = (value) => (dispatch) => {
    dispatch(_setSubfamilia(value));
    // dispatch(listar());
};

export const aplicarFiltros = () => (dispatch) => {
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const filtroChange = (tipo) => (dispatch) => {
    dispatch(setFiltro(tipo));
    dispatch(listar());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        ordering,
        filtro,
        familia,
        subfamilia,
        sucursal,
        fecha_final,
        fecha_inicial,
        vendedor,
    } = store.reporte_ventas_por_familia;

    let params = {
        ordering,
        filtro,

        familia,
        subfamilia,
        param_s: sucursal ? sucursal : "",
        fecha_final,
        fecha_inicial,
        vendedor,
        es_articulos_vendidos: 1,
    };

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_articulos_por_familia`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_ventas_por_familia = store.reporte_ventas_por_familia;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        //const fecha_final = new Date(reporte_ventas_por_familia.fecha_final);
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(
            reporte_ventas_por_familia.fecha_inicial
        );
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
};

export const limpiarFiltros =
    (unMount = false) =>
    (dispatch) => {
        dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
        dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));

        if (unMount) {
            dispatch(setSucursal(null));
        }

        dispatch(setSubfamilia(""));
        dispatch(setFiltro(""));
        dispatch(setFamilia([]));
        dispatch(setData({}));
        dispatch(setVendedor(""));
        dispatch(setTotalCantidad(""));
        dispatch(setTotalVentas(""));
    };

export const setVendedor = (value) => (dispatch) => {
    dispatch(_setVendedor(value));
    // dispatch(listar());
};
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setFiltro = (filtro) => ({
    type: FILTRO,
    filtro,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setFamilia = (familia) => ({
    type: FAMILIA,
    familia,
});

export const _setSubfamilia = (subfamilia) => ({
    type: SUBFAMILIA,
    subfamilia,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});
export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});
export const setTotalVentas = (gran_total_ventas) => ({
    type: TOTAL_VENTAS,
    gran_total_ventas,
});
export const setTotalCantidad = (gran_total_cantidades) => ({
    type: TOTAL_CANTIDADES,
    gran_total_cantidades,
});



export const actions = {
    listar,

    sortChange,
    filtroChange,

    setSucursal,
    setFamilia,
    setSubfamilia,
    setFecha,
    aplicarFiltros,

    descargarListado,
    limpiarFiltros,

    setVendedor,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },

    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [FAMILIA]: (state, { familia }) => {
        return {
            ...state,
            familia,
        };
    },
    [SUBFAMILIA]: (state, { subfamilia }) => {
        return {
            ...state,
            subfamilia,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
    [TOTAL_VENTAS]: (state, { gran_total_ventas }) => {
        return {
            ...state,
            gran_total_ventas,
        };
    },
    [TOTAL_CANTIDADES]: (state, { gran_total_cantidades }) => {
        return {
            ...state,
            gran_total_cantidades,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    ordering: "",
    loader: false,
    filtro: "",
    estado_descarga: false,

    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    sucursal: "",
    familia: [],
    subfamilia: "",
    vendedor: "",
    gran_total_ventas:0,
    gran_total_cantidades:0,
    //salida:"",
};

export default handleActions(reducers, initialState);