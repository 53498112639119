import React, { useEffect } from "react";

import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReportes } from "../../../Utils/FiltrosReportes";
import {
    ExpandColumn,
    ProductosSubTabla,
} from "../../Comisiones/Listado/ReporteContadoTabla/SubTablaComponents";

const ListadoEntradas = ({
    loader,
    data,
    page,
    cliente,
    fecha_vencimiento_inicial,
    fecha_vencimiento_final,
    estado_descarga,
    totales,

    tipo_reporte,
    proveedor,
    tipo_movimiento,
    vendedor,
    sucursal,

    usuario = null,
    sucursal_param = null,

    setFecha,
    descargarListado,
    aplicarFiltros,

    setTipoReporte,
    setProveedor,
    setTipoMovimiento,
    setVendedor,
    setCliente,
    setSucursal,

    listar,
    sortChange,
    limpiarFiltros,
}) => {
    useEffect(() => {
        limpiarFiltros();
        return () => {
            limpiarFiltros();
        };
    }, []);

    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page,
    };

    const { total_cantidad, total_movimiento } = totales;

    const isExpandableRow = () => true;

    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={cliente}
                        tipo_reporte={tipo_reporte}
                        proveedor={proveedor}
                        tipo_movimiento={tipo_movimiento}
                        vendedor={vendedor}
                        sucursal={sucursal}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={setCliente}
                        setTipoReporte={setTipoReporte}
                        setProveedor={setProveedor}
                        setTipoMovimiento={setTipoMovimiento}
                        setVendedor={setVendedor}
                        setSucursal={setSucursal}
                        aplicarFiltros={aplicarFiltros}
                        filtroEntradas
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                striped
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                expandableRow={isExpandableRow}
                                expandComponent={ProductosSubTabla}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent: ExpandColumn,
                                    columnWidth: 50,
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="sucursal"
                                    dataFormat={(cell, row) => {
                                        try {
                                            return cell.nombre;
                                        } catch (error) {
                                            return "---";
                                        }
                                    }}
                                >
                                    Sucursal
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="usuario"
                                    dataFormat={(cell, row) => {
                                        try {
                                            return cell.first_name;
                                        } catch (error) {
                                            return "---";
                                        }
                                    }}
                                >
                                    Vendedor
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="tipo">
                                    Movimiento
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="proveedor"
                                    dataFormat={(cell, row) => {
                                        if (cell) {
                                            return cell;
                                        } else {
                                            try {
                                                return row.cliente_proveedor
                                                    .nombre;
                                            } catch (error) {
                                                return "---";
                                            }
                                        }
                                    }}
                                >
                                    Proveedor/Cliente
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="fecha_movimiento"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo">
                                    Correlativo Compra
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="documento_compra">
                                    Documento
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="cantidad_detalles">
                                    Cantidad
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="sub_total_movimiento"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Monto Total
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                    dataAlign="center"
                                ></TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>

                        {/* Totales */}
                        <div className="d-flex flex-column flex-md-row justify-content-center">
                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>Total Cantidad:</strong>
                                </label>
                                <label>{total_cantidad}</label>
                            </div>

                            <div>
                                <label>
                                    <strong>Total Monto:</strong>
                                </label>
                                <label>
                                    <RenderCurrency value={total_movimiento} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoEntradas.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoEntradas;
