import React from "react";
import { connect } from "react-redux";
import { renderField } from "../Utils/renderField/renderField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FileUploader from "../Utils/FileUploader/FileUploader";
import { icons } from "icons";

const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
        errors.first_name = "Campo requerido";
    }

    // if (!values.email) {
    //     errors.email = 'Campo requerido'
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = "Dirección de correo invalida";
        }
    }
    // if (!values.telefono) {
    //     errors.telefono = 'Campo requerido'
    // }
    return errors;
};

const Form = (props) => {
    // state
    const { usuario } = props;
    // bind
    const { handleSubmit, onFileChange, editarPerfil, cambioEdicion } = props;

    return (
        <div className="w-100">
            <form>
                <div className="card card-small py-4 mb-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 content-drop-zone">
                            <FileUploader
                                onFileChange={onFileChange}
                                icono={"add-img"}
                                frase={"perfil."}
                                clave={"foto_perfil"}
                                width="70%"
                                source={usuario.foto ? usuario.foto : null}
                            />
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-12">
                            <div className="row-fields">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="first_name">
                                            Nombre *
                                        </label>
                                        <Field
                                            name="first_name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row-fields">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Correo </label>
                                        <Field
                                            name="email"
                                            component={renderField}
                                            type="email"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4 w-100">
                    <button
                        type="button"
                        className="btn btn-outline-secondary m-1 align-self-center"
                        onClick={() => {
                            cambioEdicion();
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary m-1 align-self-center"
                        onClick={handleSubmit((values) => {
                            editarPerfil();
                        })}
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};

let PerfilForm = reduxForm({
    form: "PerfilForm",
    validate,
})(Form);

export default PerfilForm;
