import React, { useState, useEffect } from "react";
import { api } from "api";
import { Async } from "react-select";
import Select from "react-select/lib/Select";
import { MultiSelect } from "react-multi-select-component";

import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { TIPO_CLIENTE } from "../../../../utility/constantes";
import { ESTADO_INVENTARIO, TIPO_REPORTE, TIPO_MOVIMIENTO_ARTICULOS_ENTRADAS, TIPO_MOVIMIENTO_ARTICULOS_SALIDAS } from "../../../../utility/variables";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import { FAMILIA } from "../../../../redux/modules/reportes/articulos_vendidos";
import NumberFormat from "react-number-format";

const listado_sucursales = [];
const listado_familias = [];

let listado_subfamilias = [];
const listado_usuarios = [];


const FiltrosReporteInv = ({
    sucursal = null,
    estado_inventario = "",
    familia = "",
    entrada = "",
    salida = "",
    subfamilia = "",
    precio = "",
    fecha_inicial,
    fecha_final,
    tipo_reporte = "",
    cantidad_articulos = "",
    vendedor,

    usuario = null,
    sucursal_param = null,

    setSalida,
    setEntrada,
    setSucursal,
    setEstadoInventario,
    setFamilia,
    setSubfamilia,
    setPrecio,
    setTipoReporte,
    setCantidadArticulos,
    setFechaInicial,
    setFechaFinal,
    setVendedor,

    aplicarFiltros,

    filtroArticulosVendidos = false,
    filtroAriculosVenta = false,
    filtroArticulosVendidosPorFamilia = false
}) => {

    const es_global = usuario ? usuario.es_global : false; // Si el usuario tiene acceso a todas las sucursales

    if (!es_global) {
        setSucursal(sucursal_param)
    }

    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        { es_vendedor: 1 }
    );
    
    const { getOptions: getSucursales } = useAsyncSearch(
        "sucursales",
        listado_sucursales
    );

    const { getOptions: getFamilias } = useAsyncSearch(
        "familia",
        listado_familias
    );

    const [id_familia, SetIdFamilia] = useState([]);
    // const [id_entrada, SetIdEntrada] = useState([]);
    // const [id_salida, SetIdSalida] = useState([]);
    useEffect(() => {
        if (filtroArticulosVendidos) {
            SetIdFamilia(familia.map((fam) => fam.value));
        }
    }, [familia]);

    const { getOptions: getSubfamilias } = useAsyncSearch(
        "sub-familia",
        listado_subfamilias,
        {
            familia_id: filtroArticulosVendidos || filtroArticulosVendidosPorFamilia ? id_familia : familia,
        }
    );





    // const [setentrada, selectedEntradaValue] = useState([]);
    // const [setsalida, selectedSalidaValue] = useState([]);

    const [familiaOptions, setFamiliaOptions] = useState([]);
    function getFamilias2() {
        api.get(`familia`, { es_multiple: true })
            .catch((err) => {
                console.log(err);
            })
            .then((resp) => {
                setFamiliaOptions(resp.results ? resp.results : []);
            });
    }

    useEffect(() => {
        getFamilias2();
    }, []);

    useEffect(() => {
        if (usuario && usuario.sucursales && !es_global) {
            setSucursal(sucursal_param);
        }
    }, [usuario]);

    return (
        <div className="d-flex flex-column grid-container px-5 pt-4 pb-2 mb-5">
            <h1 className="title-text border-bottom">Filtros</h1>

            {(filtroArticulosVendidos || filtroAriculosVenta || filtroArticulosVendidosPorFamilia) && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        <label htmlFor="">Fecha inicial</label>
                        <DatePicker
                            value={fecha_inicial}
                            maxDate={
                                new Date(
                                    moment(
                                        moment().format("YYYY-MM-DD"),
                                        "YYYY-MM-DD"
                                    )
                                )
                            }
                            onChange={(date) => {
                                setFechaInicial("Inicial", date);
                            }}
                        />
                    </div>
                    <div className="flex-1"></div>
                    <div className="flex-8">
                        <label htmlFor="">Fecha final</label>
                        <DatePicker
                            value={fecha_final}
                            minDate={
                                new Date(moment(fecha_inicial, "YYYY-MM-DD"))
                            }
                            onChange={(date) => {
                                setFechaFinal("Final", date);
                            }}
                        />
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {!filtroArticulosVendidos && !filtroAriculosVenta && !filtroArticulosVendidosPorFamilia && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        <label>Sucursal</label>
                        <Async
                            labelKey="nombre"
                            valueKey="id"
                            searchPromptText="Escriba para buscar"
                            loadOptions={getSucursales}
                            placeholder={"Sucursal"}
                            disabled={!es_global}
                            onChange={(e) => {
                                setSucursal(e ? e.id : "");
                            }}
                            value={es_global ? sucursal : sucursal_param}
                        />
                    </div>
                    <div className="flex-1"></div>
                    <div className="flex-8">
                        <label>Estado del inventario</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable
                            className="form-control"
                            options={ESTADO_INVENTARIO}
                            valueKey="value"
                            labelKey="label"
                            value={estado_inventario}
                            onChange={(e) => {
                                return setEstadoInventario(e ? e.id : "");
                            }}
                        />
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {filtroAriculosVenta && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        <label>Tipo de reporte</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable
                            className="form-control"
                            options={TIPO_REPORTE}
                            valueKey="value"
                            labelKey="label"
                            value={tipo_reporte}
                            onChange={(e) => {
                                if (e && e.id !== 1) {
                                    setCantidadArticulos("");
                                }
                                return setTipoReporte(e ? e.id : "");
                            }}
                        />
                    </div>
                    <div className="flex-1"></div>

                    <div className="flex-8">
                        <label>Cantidad de articulos</label>
                        <NumberFormat
                            disabled={tipo_reporte !== 1}
                            className="form-control"
                            fixedDecimalScale={true}
                            value={cantidad_articulos}
                            thousandSeparator={true}
                            prefix={""}
                            onValueChange={(values) => {
                                const { value } = values;
                                setCantidadArticulos(value);
                            }}
                        />
                        {/* <Async
                         className="form-control"
                         labelKey="nombre"
                         valueKey="id"
                         searchPromptText="Escriba para buscar"
                         loadOptions={getSucursales}
                         placeholder={"Sucursal"}
                         onChange={(e) => {
                             setSucursal(e ? e.id : "");
                         }}
                         value={sucursal}
                     /> */}
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                <div className="flex-8">
                    <label>Familia</label>

                    {filtroArticulosVendidos ? (
                        <MultiSelect
                            options={familiaOptions ? familiaOptions : []}
                            value={familia}
                            onChange={setFamilia}
                            // onChange={(e) => {
                            //     setSelected(e ? e.lavel : "");
                            //     setSubfamilia("");
                            // }}
                            labelledBy="Select"
                        />
                    ) : (
                        <Async
                            className="form-control"
                            labelKey="nombre"
                            valueKey="id"
                            searchPromptText="Escriba para buscar"
                            loadOptions={getFamilias}
                            placeholder={"Familia"}
                            onChange={(e) => {
                                setFamilia(e ? e.id : "");
                                listado_subfamilias = [];
                                setSubfamilia("");
                            }}
                            value={familia}
                        />
                    )}
                </div>
                <div className="flex-1"></div>

                <div className="flex-8">
                    <label>Subfamilia</label>
                    <Async
                        key={familia ? familia : ""}
                        className="form-control"
                        labelKey="nombre"
                        valueKey="id"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getSubfamilias}
                        placeholder={"Subfamilia"}
                        onChange={(e) => {
                            setSubfamilia(e ? e.id : "");
                        }}
                        value={subfamilia}
                        disabled={!familia}
                    />
                </div>
                <div className="flex-1"></div>
            </div>

            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                <div className="flex-8">
                    {filtroArticulosVendidos || filtroAriculosVenta || filtroArticulosVendidosPorFamilia ? (
                        <React.Fragment>
                            <label>Sucursal</label>
                            <Async
                                className="form-control"
                                labelKey="nombre"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getSucursales}
                                placeholder={"Sucursal"}
                                disabled={!es_global && sucursal_param}
                                onChange={(e) => {
                                    setSucursal(e ? e.id : "");
                                }}
                                value={es_global ? sucursal : sucursal_param}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <label>Precio</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={TIPO_CLIENTE}
                                valueKey="value"
                                labelKey="label"
                                value={precio}
                                onChange={(e) => {
                                    return setPrecio(e ? e : "");
                                }}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div className="flex-1"></div>

                <div className="flex-8">
                    {(filtroArticulosVendidosPorFamilia) && (
                        <React.Fragment>
                            <label>Vendedor</label>
                            <Async
                                className="form-control"
                                labelKey="first_name"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getUsuarios}
                                placeholder={"Vendedor"}
                                onChange={(e) => {
                                    setVendedor(e ? e.id : "");
                                }}
                                value={vendedor}
                            />
                        </React.Fragment>
                    )}
                </div>
                <div className="flex-1"></div>
            </div>

            {(filtroArticulosVendidos) && (
                <div>
                    <h1 className="title-text border-bottom">Movimientos</h1>
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">

                        <div className="flex-8">
                            <label>Entradas</label>
                            <MultiSelect
                                options={TIPO_MOVIMIENTO_ARTICULOS_ENTRADAS}
                                value={entrada ? entrada : []}
                                onChange={(selectedOptions) => setEntrada(selectedOptions)}
                            />
                        </div>
                        <div className="flex-1"></div>

                        <div className="flex-8">
                            <label>Salidas</label>
                            <MultiSelect
                                options={TIPO_MOVIMIENTO_ARTICULOS_SALIDAS}
                                value={salida ? salida : []}
                                onChange={(selectedOptions) => setSalida(selectedOptions)}
                            />
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </div>
            )}


            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                <div className="flex-8"></div>
                <div className="flex-1"></div>

                <div className="flex-8 d-flex justify-content-end">
                    <button
                        className="btn btn-primary px-4"
                        // disabled={estado_descarga}
                        onClick={() => aplicarFiltros()}
                    >
                        <em className="fa fa-filter"></em>
                        &nbsp;Filtrar
                    </button>
                </div>
                {/* <div className="flex-1"></div> */}
            </div>
        </div>
    );
};

export default FiltrosReporteInv;
