import React from "react";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { required } from "../../../../../utility/validation";
import { renderField } from "Utils/renderField/renderField";
import {
    renderNumberFormat,
    renderSelectField,
    renderSwitch,
    renderTextArea,
} from "../../../Utils/renderField/renderField";
import { tipos_venta_sc } from "utility/constantes";

const MarcarEnviadoForm = ({ es_recoger_en_tienda, onClose, onSubmit }) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} className="form-validate ">
                        <div className="">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="nombre">Tipo de venta</label>
                                <Field
                                    name="tipo_venta"
                                    component={renderSelectField}
                                    options={tipos_venta_sc}
                                    // border_red={esCotizacion}
                                    item_vacio={true}
                                    validate={required}
                                />
                            </div>

                            {!es_recoger_en_tienda && (
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="nombre">
                                        Número de Guía
                                    </label>
                                    <Field
                                        name="numero_guia"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        placeholder="Número de Guía"
                                        validate={required}
                                        // disabled={ver}
                                    />
                                </div>
                            )}

                            <div className="d-flex flex-md-row justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary mr-3"
                                    disabled={submitting}
                                    onClick={onClose}
                                >
                                    Volver
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default MarcarEnviadoForm;
