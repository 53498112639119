import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { validate, validators } from "validate-redux-form";
import {
    renderSelectField,
    renderTextArea,
} from "../../Utils/renderField/renderField";
import { ListadoAccion } from "../../Utils/ListadoAccion";
import { DetalleAccion } from "../../Utils/DetalleAccion";
import { icons } from "icons";
import {
    buscar,
    formatoAcciones,
    formatoNumeroDocumento,
    formatoFecha,
    formatoTipoPago,
    formatoTipoVenta,
    formatoCodigo,
    formatoCodigoOC,
    formatoProveedorOC,
    formatoNombre,
    formatoDefaultValue,
    formatoMonedaQuetzales,
} from "Utils/CamposComunes";

import { formatoTotalBaja, formatoSubtotal } from "./CamposDetalle";
import { TIPO_DEVOLUCION } from "../../../../utility/variables";
import { RenderReadCurrency } from "../../Utils/renderField/renderFieldsCurrency";

const Form = (props) => {
    const {
        loader,
        listar,
        listarOV,
        sortChange,
        seleccionados,
        page,
        ver_buscador,
        cambio_buscador,
        codigo_doc,
        data,
        setFecha,
        fecha_compra_final,
        fecha_compra_inicial,
        setFiltros,
        buscarCodigo,
        total = 0,
        limpiar,
    } = props;
    const {
        handleSubmit,
        actionAyuda,
        quitar,
        proveedor_cliente,
        setProveedorCliente,
    } = props;

    // Estado para el tipo de devolucion
    const [tipoDevolucion, setTipoDevolucion] = useState(1);

    const llavesListadoOV = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },

        {
            nombre: "producto__codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },

        {
            nombre: "numero_documento",
            sort: true,
            caption: "No. documento",
            format: formatoNumeroDocumento,
            align: "left",
        },
        {
            nombre: "no_orden",
            sort: false,
            caption: "Código orden",
            format: formatoCodigoOC,
            align: "left",
        },
        {
            nombre: "proveedor",
            sort: false,
            caption: "Proveedor",
            format: formatoProveedorOC,
            align: "left",
        },
        {
            nombre: "fecha",
            sort: false,
            caption: "Fecha",
            format: formatoFecha,
            align: "left",
        },
        {
            nombre: "tipo_pago",
            sort: false,
            caption: "Tipo de pago",
            format: formatoTipoPago,
            align: "left",
        },
        {
            nombre: "cantidad",
            sort: false,
            caption: "Cantidad",
            format: formatoDefaultValue,
            align: "left",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Acciones",
            format: formatoAcciones(props),
            align: "center",
            width: "90px",
        },
    ];
    const llavesListadoOC = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },

        {
            nombre: "producto__codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },

        // {
        //     nombre: "numero_documento",
        //     sort: true,
        //     caption: "No. documento",
        //     format: formatoNumeroDocumento,
        //     align: "left",
        // },
        // {
        //     nombre: "no_orden",
        //     sort: false,
        //     caption: "Código orden",
        //     format: formatoCodigoOC,
        //     align: "left",
        // },
        {
            nombre: "proveedor",
            sort: false,
            caption: "Cliente",
            format: formatoProveedorOC,
            align: "left",
        },
        {
            nombre: "fecha",
            sort: false,
            caption: "Fecha",
            format: formatoFecha,
            align: "left",
        },
        {
            nombre: "tipo_pago",
            sort: false,
            caption: "Tipo de venta",
            format: formatoTipoVenta,
            align: "left",
        },
        {
            nombre: "cantidad",
            sort: false,
            caption: "Cantidad",
            format: formatoDefaultValue,
            align: "left",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Acciones",
            format: formatoAcciones({ ...props, ver_detalle: false }),
            align: "center",
            width: "90px",
        },
    ];

    const llavesDetalleOV = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },

        {
            nombre: "producto__codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "no_orden",
            sort: false,
            caption: "Código orden",
            format: formatoCodigoOC,
            align: "left",
        },
        {
            nombre: "proveedor",
            sort: false,
            caption: "Proveedor",
            format: formatoProveedorOC,
            align: "left",
        },
        {
            nombre: "cantidad",
            sort: false,
            caption: "Cantidad",
            format: formatoDefaultValue,
            align: "left",
        },
        {
            nombre: "costo_compra",
            sort: false,
            caption: "Costo compra",
            format: formatoMonedaQuetzales,
            align: "left",
        },
        {
            caption: "Cantidad que da de baja",
            nombre: "baja",
            format: formatoTotalBaja(props),
            width: "180px",
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: formatoSubtotal, // usando la funcion de formatoSubtotal, y mandandole como parametro el costo del input de importacion, el valor del input de origen y el valor del input de tipo de cambio
            align: "right",
        },
    ];

    const llavesDetalleOC = [
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },

        {
            nombre: "producto__codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },
        // {
        //     nombre: "no_orden",
        //     sort: false,
        //     caption: "Código orden",
        //     format: formatoCodigoOC,
        //     align: "left",
        // },
        {
            nombre: "proveedor",
            sort: false,
            caption: "Cliente",
            format: formatoProveedorOC,
            align: "left",
        },
        {
            nombre: "cantidad",
            sort: false,
            caption: "Cantidad",
            format: formatoDefaultValue,
            align: "left",
        },
        {
            nombre: "costo_compra",
            sort: false,
            caption: "Costo compra",
            format: formatoMonedaQuetzales,
            align: "left",
        },
        {
            caption: "Cantidad que da de baja",
            nombre: "baja",
            format: formatoTotalBaja(props),
            width: "180px",
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            format: formatoSubtotal, // usando la funcion de formatoSubtotal, y mandandole como parametro el costo del input de importacion, el valor del input de origen y el valor del input de tipo de cambio
            align: "right",
        },
    ];

    // useEffect(() => {
    //     if (tipoDevolucion !== false) {
    //         limpiar();
    //     }
    // }, [tipoDevolucion]);

    return (
        <form onSubmit={handleSubmit} className="row form-group">
            <div className="col-12 mb">
                <div className="grid-container pl-5 pr-5 pt-2 pb-2 d-flex flex-column">
                    <div className="form-group mt-3 mb-4">
                        <label htmlFor="tipo_compra">Tipo de devolución</label>

                        <div className="d-flex flex-column flex-md-row pr-0">
                            <div className="d-flex flex-column">
                                <Field
                                    name="tipo_compra"
                                    component={renderSelectField}
                                    options={TIPO_DEVOLUCION}
                                    disabled={true}
                                    // parse={(value) => {
                                    //     setTipoDevolucion(value);
                                    //     return value;
                                    // }}
                                    placeholder="Seleccionar"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div
                        style={{
                            position: "absolute",
                            top: "25px",
                            right: "25px",
                        }}
                    >
                        <button
                            className="btn btn-ayuda fnt-16 d-flex align-items-center justify-content-center"
                            style={{ width: "2rem", height: "2rem" }}
                            onClick={actionAyuda}
                        >
                            <img
                                src={icons.ayuda}
                                alt="ayuda"
                                title="Ayuda"
                                style={{ margin: "0", height: "1.1rem" }}
                            />
                        </button>
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="motivo">
                            Ingrese el motivo de la devolución
                        </label>
                        <Field
                            name="motivo"
                            rows={2}
                            component={renderTextArea}
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="slide-container row" style={{ margin: "0" }}>
                {(tipoDevolucion == 1 || tipoDevolucion == 2) && (
                    <div
                        className={`col-12 pr-md-0 ${
                            ver_buscador ? "form-activo" : ""
                        } slide my-4`}
                    >
                        {data === false && (
                            <ListadoAccion
                                buscar={buscar(props)}
                                loader={loader}
                                llaves={
                                    tipoDevolucion == 1
                                        ? llavesListadoOV
                                        : tipoDevolucion == 2
                                        ? llavesListadoOC
                                        : []
                                }
                                data={data !== false > 0 ? data : {}}
                                listar={
                                    tipoDevolucion == 1
                                        ? listar
                                        : tipoDevolucion == 2
                                        ? listarOV
                                        : () => {}
                                }
                                sortChange={sortChange}
                                titulo={"Ordenes de compra"}
                                page={page}
                                collapse={false}
                                animationCollapse={false}
                                filtros_fecha={true}
                                valores_filtros_fecha={{
                                    fecha_compra_inicial,
                                    fecha_compra_final,
                                    setFecha,
                                }}
                                grid_filtros={true}
                                setFiltros={setFiltros}
                                codigo_doc={codigo_doc}
                                buscarCodigo={buscarCodigo}
                                proveedor_cliente={proveedor_cliente}
                                setProveedorCliente={setProveedorCliente}
                                tipoDevolucion={tipoDevolucion}
                            />
                        )}
                        {data !== false && (
                            <ListadoAccion
                                buscar={buscar(props)}
                                loader={loader}
                                llaves={
                                    tipoDevolucion == 1
                                        ? llavesListadoOV
                                        : tipoDevolucion == 2
                                        ? llavesListadoOC
                                        : []
                                }
                                data={data !== false > 0 ? data : {}}
                                listar={
                                    tipoDevolucion == 1
                                        ? listar
                                        : tipoDevolucion == 2
                                        ? listarOV
                                        : () => {}
                                }
                                sortChange={sortChange}
                                titulo={"Ordenes de compra"}
                                page={page}
                                collapse={false}
                                animationCollapse={false}
                                filtros_fecha={true}
                                valores_filtros_fecha={{
                                    fecha_compra_inicial,
                                    fecha_compra_final,
                                    setFecha,
                                }}
                                grid_filtros={true}
                                setFiltros={setFiltros}
                                codigo_doc={codigo_doc}
                                buscarCodigo={buscarCodigo}
                                proveedor_cliente={proveedor_cliente}
                                setProveedorCliente={setProveedorCliente}
                                tipoDevolucion={tipoDevolucion}
                            />
                        )}
                        <div className="d-lg-none d-xl-none grid-container align-items-center mt0 padding-10 d-flex justify-content-around flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                    cambio_buscador();
                                }}
                            >
                                <img src={icons.cancelar} alt="" /> Cancelar
                            </button>
                        </div>
                    </div>
                )}

                {(tipoDevolucion == 1 || tipoDevolucion == 2) && (
                    <div
                        className={`col-12 ${
                            !ver_buscador ? "form-activo" : ""
                        } form-trasero mt-1`}
                    >
                        <DetalleAccion
                            data={seleccionados}
                            titulo={"Lista de ordenes a devolver"}
                            llaves={
                                tipoDevolucion == 1
                                    ? llavesDetalleOV
                                    : tipoDevolucion == 2
                                    ? llavesDetalleOC
                                    : []
                            }
                            loader={loader}
                            cambio_buscador={cambio_buscador}
                            quitar={quitar}
                        />

                        <div
                            style={{
                                borderRadius: "0",
                                padding: "4px 2px 2px 2px",
                                borderTop: "none",
                            }}
                            className="grid-container border-top mt0 d-flex flex-column flex-md-row justify-content-between mb-5"
                        >
                            <div
                                className="d-flex justify-content-around align-items-end p-1 p-md-2 flex-column flex-1"
                                style={{ backgroundColor: "#FFF" }}
                            >
                                <div className="text-right">
                                    <div>Monto total</div>
                                    <h5>
                                        <b>
                                            <RenderReadCurrency value={total} />
                                        </b>
                                    </h5>
                                </div>
                            </div>
                            <div
                                className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                                style={{ backgroundColor: "#FFF" }}
                            >
                                <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                    <Link
                                        className="btn btn-tertiary mr-1"
                                        to={"/bodega"}
                                    >
                                        <img src={icons.cancelar} alt="" />
                                        Cancelar
                                    </Link>
                                    <button
                                        disabled={loader}
                                        className="btn btn-primary pull-right ml-1"
                                        type="submit"
                                    >
                                        <i className="fa fa-check mr"></i>
                                        Finalizar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!tipoDevolucion && (
                <div className="grid-container w-100">
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: 200 }}
                    >
                        <h3>Seleccione un tipo de devolucion</h3>
                    </div>
                    <div className="d-flex">
                        <Link
                            className="btn btn-outline-primary mr-1"
                            to={"/bodega"}
                        >
                            Regresar
                        </Link>
                    </div>
                </div>
            )}
        </form>
    );
};

const BajasProducto = reduxForm({
    // a unique name for the form
    form: "BajasProducto",
    validate: (data) => {
        return validate(data, {
            motivo: validators.exists()("Campo requerido"),
        });
    },
})(Form);

export default BajasProducto;
