import { connect } from 'react-redux'
import DetalleOrdenCompra from './DetalleOrdenCompra'
import { actions } from '../../../../redux/modules/cuenta_pagar/cuenta_pagar'
import { print } from "../../../../redux/modules/print/print";


const ms2p = (state) => {
    let pago_pendiente = 0;
    const { orden_compra, movimiento_caja } = state.cuenta_pagar;
    const values = state.form.PagoOrdenCompra ? state.form.PagoOrdenCompra.values: {};
    if (orden_compra && values.tipo_abono) {
        pago_pendiente = orden_compra.saldo;
        if (parseInt(values.tipo_abono) == 1000) {
            if (movimiento_caja) {
                pago_pendiente = orden_compra.saldo - movimiento_caja.total;
                if (pago_pendiente < 0)
                    pago_pendiente = 0;
            }
        }else {
            const monto = values.monto ? parseFloat(values.monto) : 0;
            const descuento = values.descuento? values.descuento : 0;
            const aplicar_descuento = values.aplicar_descuento? values.aplicar_descuento : false;
            pago_pendiente = orden_compra.saldo - monto ;
            if (aplicar_descuento && descuento && descuento > 0){
                pago_pendiente = pago_pendiente - descuento
            }
        }
    }

    return {
        ...state.cuenta_pagar,
        pais: state.usuario.me.pais,
        conf_existencia: state.usuario.conf_existencia_con_decimales,
        pago_pendiente,
        print_state: state.print,
    }
}

const md2p = {
    ...actions,
    print: (name) => dispatch => dispatch(print(name)),
}

export default connect(ms2p, md2p)(DetalleOrdenCompra)
