import React, { useEffect } from "react";

import LoadMask from "Utils/LoadMask/LoadMask";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";

const TableCreditos = ({ loader, data_resumen, getDataResumen }) => {
    useEffect(() => {
        getDataResumen();
    }, []);

    //  OPCIONES
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    // options.onPageChange = data_resumen.clientes_con_creditos ? data_resumen.clientes_con_creditos:[];

    return (
        <LoadMask loading={loader} light blur>
            <div className="header-tabla-resumen">
                <h3 className="mt-2 ml-3">Top 5 clientes con créditos</h3>
            </div>
            <BootstrapTable
                data={loader ? [] : data_resumen.clientes_con_creditos}
                remote
                hover
                options={options}
                tableHeaderClass="tabla-resumen"
                tableBodyClass="tabla-resumen"
            >
                <TableHeaderColumn
                    width="0%"
                    isKey
                    dataField="cliente_proveedor_id"
                >
                    id
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cliente_proveedor__nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                >
                    Nombre del cliente
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_pendiente"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => <RenderNumero monto={cell} />}
                    dataAlign="right"
                >
                    Cant. de créditos
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Total
                </TableHeaderColumn>
            </BootstrapTable>
        </LoadMask>
    );
};

export default TableCreditos;
