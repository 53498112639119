import { handleActions } from "redux-actions";
import { push, replace, goBack } from "react-router-redux";
import { change as changeForm, initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";
import { ToastStore } from "react-toasts";

import { actions as ventasActions } from "../venta/venta";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "CLIENTE_DATA";
export const LOADER = "CLIENTE_LOADER";
export const PAGE = "CLIENTE_PAGE";
export const BUSCADOR = "CLIENTE_BUSCADOR";
export const ESTADO_DESCARGA = "CLIENTE_ESTADO_DESCARGA";
export const TIPO_CLIENTE = "CLIENTE_TIPO_CLIENTE";
export const MUNICIPIO = "CLIENTE_MUNICIPIO";
export const DEPARTAMENTO = "CLIENTE_DEPARTAMENTO";
export const VENDEDOR = "CLIENTE_VENDEDOR";
export const ITEM = "CLIENTE_SET_ITEM";
export const ITEM_CREDITO = "CLIENTE_ITEM_CREDITO";

const endpoint = "clientes";

// ------------------------------------
// Actions
// ------------------------------------
const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get(`${endpoint}/estado_descarga`, { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = error.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const cliente = store.cliente;
    const {
        ordering,
        search,
        tipo_cliente,
        departamento,
        municipio,
        vendedor,
    } = cliente;

    const departamento_value = departamento ? departamento.value : "";
    const municipio_value = municipio
        ? municipio.label
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
        : "";

    const tipo_cliente_value = tipo_cliente ? tipo_cliente.id : "";

    dispatch(setEstadoDescarga(true));

    api.get(`${endpoint}/descargar_excel`, {
        ordering,
        search,
        departamento: departamento_value,
        municipio: municipio_value,
        tipo_cliente: tipo_cliente_value,
        vendedor,
    })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

export const leerCliente = (nit) => (dispatch, getStore) => {
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()

    const store = getStore();
    // const data = store.form.ClienteForm.values;

    api.get(`${endpoint}/${nit}/por_nit/`)
        .catch((err) => {
            // si no existe retorna un 404,
            // no hacer nada
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                //dispatch(changeForm("ClienteForm", "nombre", data.nombre));
                dispatch(setItem(data));
                //dispatch(
                //    changeForm("ClienteForm", "direccion", data.direccion)
                //);
            }
        });
};

export const leer = (id) => (dispatch, getStore) => {
    // esta accion obtiene un cliente para editarlo,
    // no confundir con leerCliente()
    dispatch(setLoader(true));
    api.get(endpoint + "/" + id)
        .then((data) => {
            dispatch(setItem(data));
            //dispatch(initializeForm("ClienteCrear", data));
        })
        .catch((error) => {
            Swal("ERROR", error.detail || "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const leerCredito = (id) => (dispatch) => {
    dispatch(setLoader(true));
    // esta accion obtiene el credito del cliente para editarlo,
    api.get(`cliente_creditos/${id}/`)
        .then((data) => {
            dispatch(setItemCredito(data));
            dispatch(setLoader(false));
        })
        .catch((error) => {
            Swal("ERROR", error.detail || "Ha ocurrido un error.", "error");
            dispatch(setItemCredito({}));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editarCredito = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`cliente_creditos/${id}/`, data)
        .then((data) => {
            if (data) {
                ToastStore.success("Crédito actualizado");
                dispatch(leer(data.cliente));
                dispatch(setItemCredito({}));
            }
        })
        .catch((err) => {
            const msj =
                err.detail ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        });
};

/*export const crearCliente = () => (dispatch, getStore) => {
    // esta acción se usa para crear un cliente
    // desde el CRUD de clientes, no confundir con crear()
    const store = getStore();
    const data = store.form.ClienteCrear.values;
    dispatch(setLoader(true));
    api.post(endpoint, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                // ir atras (normalmente hacia el grid)
                dispatch(goBack());
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};*/

export const crearCliente =
    (data, files = []) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));

        api.postAttachments(endpoint, data, files)
            .then((response) => {
                dispatch(push("/configuracion/clientes"));
                ToastStore.success("Nuevo cliente almacenado");
                dispatch(listar());
            })
            .catch((err) => {
                const msj =
                    err.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
                Swal("ERROR", msj, "error");
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const editar =
    (id, data, files = []) =>
    (dispatch, getStore) => {
        // esta acción se usa para crear un cliente
        // desde el CRUD de clientes, no confundir con crear()
        const store = getStore();
        //const data = store.form.ClienteCrear.values;
        //const id = data.id;

        dispatch(setLoader(true));
        //api.put(`${endpoint}/${id}/`, data)
        api.putAttachments(`${endpoint}/${id}/`, data, files)
            .then((data) => {
                ToastStore.success("Cliente actualizado");
                dispatch(push("/configuracion/clientes"));
            })
            .catch((err) => {
                const msj =
                    err.detail ||
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
                Swal("ERROR", msj, "error");
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const crear = () => (dispatch, getStore) => {
    // esta acción se usa para crar a un cliente rapidamente
    // desde la venta al ingresar el nombre y nit
    // no confundir con crearCliente()
    const store = getStore();
    const data = store.form.ClienteForm.values;
    dispatch(setLoader(true));
    api.post(endpoint, data)
        .catch((err) => {
            const msj =
                err.detail ||
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        })
        .then((data) => {
            if (data) {
                dispatch(push(`/punto_de_venta/${data.id}`));
            }
            dispatch(ventasActions.setDatosCliente(data));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const ventaCF =
    (ruta = "ventas") =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.post(endpoint, { nit: "C.F.", nombre: "Consumidor Final" })
            .catch((err) => {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentarlo.",
                    "error"
                );
            })
            .then((data) => {
                if (data) {
                    dispatch(push(`/${ruta}/${data.id}`));
                }

                //  DATOS DE C.F.
                const dataCF = {
                    nombre: "Consumidor Final",
                    nit: "C.F.",
                    direccion: "Ciudad",
                };

                dispatch(ventasActions.setDatosCliente(dataCF));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const cliente = store.cliente;
        const {
            ordering,
            search,
            tipo_cliente,
            departamento,
            municipio,
            vendedor,
        } = cliente;

        const departamento_value = departamento ? departamento.value : "";
        const municipio_value = municipio
            ? municipio.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
            : "";

        const tipo_cliente_value = tipo_cliente ? tipo_cliente.id : "";
        api.get(endpoint, {
            page,
            ordering,
            search,
            departamento: departamento_value,
            municipio: municipio_value,
            tipo_cliente: tipo_cliente_value,
            vendedor,
        })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setData(data));
                }
                dispatch(setPage(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.cliente.page;
    dispatch(listar(page));
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then((data) => {
            if (data) {
                ToastStore.success("Registro eliminado.");
                dispatch(listar());
            }
        })
        .catch((error) => {
            Swal(
                "ERROR",
                error.detail ||
                    "Ha ocurrido un error, espere un momento y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const removerItem = () => (dispatch) => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const removerItemCredito = () => (dispatch) => {
    dispatch({
        type: ITEM_CREDITO,
        item: {},
    });
};

export const filtrarTipoCliente = (tipo_cliente) => (dispatch) => {
    dispatch(setTipoCliente(tipo_cliente));
    dispatch(listar());
};

export const filtrarTipoClienteSinListar = (tipo_cliente) => (dispatch) => {
    dispatch(setTipoCliente(tipo_cliente));
};

export const filtrarDepartamento = (departamento) => (dispatch) => {
    dispatch(setDepartamento(departamento));
    dispatch(listar());
};

export const filtrarMunicipio = (municipio) => (dispatch) => {
    dispatch(setMunicipio(municipio));
    dispatch(listar());
};

export const filtrarVendedor = (vendedor) => (dispatch) => {
    dispatch(setVendedor(vendedor));
    dispatch(listar());
};

// limpiar filtros
export const limpiar = () => (dispatch) => {
    dispatch(setTipoCliente({ id: "" }));
    dispatch(setDepartamento({ value: "" }));
    dispatch(setMunicipio({ label: "" }));
    dispatch(setVendedor(""));
};

// ------------------------------------
// PureActions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

const setItem = (item) => ({
    type: ITEM,
    item,
});

const setItemCredito = (item_credito) => ({
    type: ITEM_CREDITO,
    item_credito,
});

const setTipoCliente = (tipo_cliente) => ({
    type: TIPO_CLIENTE,
    tipo_cliente,
});

const setMunicipio = (municipio) => ({
    type: MUNICIPIO,
    municipio,
});

const setDepartamento = (departamento) => ({
    type: DEPARTAMENTO,
    departamento,
});

const setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const actions = {
    crear,
    crearCliente,
    editar,
    ventaCF,
    leerCliente,
    leer,
    listar,
    buscar,
    sortChange,
    setPage,
    setBuscador,
    eliminar,
    descargarListado,
    removerItem,
    filtrarTipoCliente,
    filtrarDepartamento,
    filtrarMunicipio,
    filtrarTipoClienteSinListar,
    filtrarVendedor,
    leerCredito,
    editarCredito,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM_CREDITO]: (state, { item_credito }) => {
        return {
            ...state,
            item_credito,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [TIPO_CLIENTE]: (state, { tipo_cliente }) => {
        return {
            ...state,
            tipo_cliente,
        };
    },
    [MUNICIPIO]: (state, { municipio }) => {
        return {
            ...state,
            municipio,
        };
    },
    [DEPARTAMENTO]: (state, { departamento }) => {
        return {
            ...state,
            departamento,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    cliente: {},
    item: {},
    item_credito: {},
    loader: false,
    page: 1,
    search: "",
    ordering: "",
    estado_descarga: false,
    tipo_cliente: { id: "" },
    departamento: { value: "" },
    municipio: { label: "" },
    vendedor: "",
};

export default handleActions(reducers, initialState);
