import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime as RDateTime,
    RenderCurrency,
    RenderDate,
} from "Utils/renderField/renderReadField";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";

class DetalleComisiones extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        //  state
        const { loader, detalle, venta_permitir_descuento } = this.props;
        const movimiento = detalle.movimiento
            ? detalle.movimiento
            : { detalles: [], usuario: {} };
        const cliente = detalle.cliente_proveedor
            ? detalle.cliente_proveedor
            : {};
        //  bind

        //  options
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;

        let total_abonado = 0;
        if (detalle.abonos) {
            detalle.abonos.forEach((item) => {
                total_abonado += item.total;
            });
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="mb-1 mt-1">
                            <LoadMask loading={loader} light blur>
                                <div className="d-flex flex-column padding-15">
                                    <div className="d-flex flex-column flex-md-row justify-content-between">
                                        <h3>
                                            <b>Detalle Cuenta por Cobrar</b>
                                        </h3>
                                        <h3 className="text-right">
                                            {movimiento.anulado ? (
                                                <b className="text-danger">
                                                    Anulado
                                                </b>
                                            ) : (
                                                <b className="text-success">
                                                    Activo
                                                </b>
                                            )}
                                        </h3>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Sucursal</b>
                                            {movimiento.sucursal
                                                ? movimiento.sucursal.nombre
                                                : "-----"}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Fecha vencimiento</b>
                                            <RenderDate
                                                value={
                                                    detalle.fecha_vencimiento
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Usuario</b>
                                            {movimiento.usuario.first_name}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Fecha / hora</b>
                                            <RDateTime
                                                value={movimiento.creado}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Comprador</b>
                                            {cliente.nombre}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>NIT</b>
                                            {cliente.nit ? cliente.nit : "C/F"}
                                        </div>
                                    </div>
                                    {/* MONTOS DE CUENTA */}
                                    <div className="mt-3 d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column border-bottom flex-1 w-100 ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Total de venta</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                movimiento.total_cobrar_pagar
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Anticipo efectivo</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                movimiento.monto_efectivo
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Anticipo tarjeta</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                movimiento.monto_tarjeta
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Anticipo cheque</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                movimiento.monto_cheque
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Anticipo depósito</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                movimiento.monto_deposito
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column border-bottom flex-1 w-100 ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>
                                                        Total saldo al crédito
                                                    </b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                detalle.saldo_inicial
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Total abonado</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                total_abonado
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                            <div className="d-flex flex-row">
                                                <h6 className="flex3">
                                                    <b>Saldo</b>
                                                </h6>
                                                <h6 className="text-right">
                                                    <b>
                                                        <RenderCurrency
                                                            value={
                                                                detalle.saldo
                                                            }
                                                        />
                                                    </b>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    {/* FIN MONTOS DE CUENTA */}
                                    {/* SECCION DE VENTA */}
                                    <h4 className="mt-4">
                                        <b>
                                            Venta (
                                            {detalle.venta_data
                                                ? detalle.venta_data.codigo
                                                : ""}
                                            )
                                        </b>
                                    </h4>
                                    <BootstrapTable
                                        data={loader ? [] : movimiento.detalles}
                                        hover
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            width="0%"
                                            dataField="id"
                                            isKey
                                        >
                                            ID
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="nombre"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <div>
                                                        {/* VALIDAR SI ES VALE EL ITEM */}
                                                        {row.es_vale ? (
                                                            <b>
                                                                Descuento (
                                                                {
                                                                    row.vale
                                                                        .codigo
                                                                }
                                                                )
                                                            </b>
                                                        ) : (
                                                            <span>
                                                                <b>
                                                                    {
                                                                        row.producto
                                                                    }
                                                                </b>
                                                                <div>
                                                                    {
                                                                        row
                                                                            .fraccion
                                                                            .nombre
                                                                    }
                                                                </div>
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        >
                                            Producto
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="precio"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={
                                                            row.es_vale
                                                                ? cell * -1
                                                                : cell
                                                        }
                                                    />
                                                );
                                            }}
                                        >
                                            Precio
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="cantidad"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderNumero
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Cantidad
                                        </TableHeaderColumn>
                                        {!!venta_permitir_descuento && (
                                            <TableHeaderColumn
                                                dataField="desc_monto"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={
                                                                cell
                                                                    ? cell *
                                                                      row.cantidad
                                                                    : 0
                                                            }
                                                        />
                                                    );
                                                }}
                                            >
                                                Monto descuento
                                            </TableHeaderColumn>
                                        )}

                                        {!!venta_permitir_descuento && (
                                            <TableHeaderColumn
                                                dataField="desc_porcentaje"
                                                dataAlign="right"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderPorcentaje
                                                            monto={
                                                                cell ? cell : 0
                                                            }
                                                        />
                                                    );
                                                }}
                                            >
                                                Descuento
                                            </TableHeaderColumn>
                                        )}
                                        <TableHeaderColumn
                                            dataField="sub_total"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Subtotal
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                    <div className="d-flex flex-column border-bottom">
                                        <div className="w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                                            <h4>
                                                <b>Total</b>
                                            </h4>
                                            <h4>
                                                <b>
                                                    <RenderCurrency
                                                        value={
                                                            movimiento.total_cobrar_pagar
                                                        }
                                                    />
                                                </b>
                                            </h4>
                                        </div>
                                    </div>
                                    {/* FIN SECCION DE VENTA */}

                                    {/* SECCION DE ABONOS */}
                                    <h4 className="mt-4">
                                        <b>Abonos</b>
                                    </h4>
                                    <BootstrapTable
                                        data={loader ? [] : detalle.abonos}
                                        hover
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            width="0%"
                                            dataField="id"
                                            isKey
                                        >
                                            ID
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="creado"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderDateTime
                                                        fecha={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="sucursal__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>{row.sucursal.nombre}</div>
                                            )}
                                        >
                                            Sucursal
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="usuario__first_name"
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    {row.usuario.first_name}
                                                </div>
                                            )}
                                        >
                                            Usuario
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="concepto"
                                            dataFormat={(cell, row) => (
                                                <div>{row.concepto}</div>
                                            )}
                                        >
                                            Nota
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="monto_tarjeta"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Monto tarjeta
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="monto_efectivo"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Monto efectivo
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="monto_cheque"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Monto cheque
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="monto_deposito"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Monto depósito
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataAlign="right"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                );
                                            }}
                                        >
                                            Total
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                    <div className="d-flex flex-column border-bottom">
                                        <div className="w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                                            <h4>
                                                <b>Total</b>
                                            </h4>
                                            <h4>
                                                <b>
                                                    <RenderCurrency
                                                        value={total_abonado}
                                                    />
                                                </b>
                                            </h4>
                                        </div>
                                    </div>
                                    {/* FIN SECCION DE ABONOS */}
                                </div>
                            </LoadMask>
                            <div className="d-flex justify-content-center flex-row w-100 mt-3 mb-3">
                                <Link
                                    className="btn btn-tertiary m-1"
                                    to={"/reporte_cuenta_por_cobrar"}
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    />{" "}
                                    Regresar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DetalleComisiones.propTypes = {
    leer: PropTypes.func.isRequired,
    detalle: PropTypes.object,
    venta_permitir_descuento: PropTypes.bool,
};

export default DetalleComisiones;
