import React, { Component } from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { icons } from "icons";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    formatoCostoDolares,
    formatoMonedaQuetzales,
} from "Utils/CamposComunes";
import { Async } from "react-select";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
// const imgDefault = require("../../../../../assets/img/icons/uploading-img.png")
function formatoAvatar(cell, row) {
    if (cell !== "") {
        return (
            <img
                className="imagen-sm"
                src={cell}
                alt=""
                style={{ width: "38px", height: "38px" }}
            />
        );
    } else {
        return (
            <img
                className="imagen-sm"
                src={icons.img_default}
                alt=""
                style={{ width: "38px", height: "38px" }}
            />
        );
    }
}

const formatoVistaImprimir = (id) => {
    return (
        <Link to={`/productos/imprimir_codigos/${id}/`}>
            {/* <img className="action-img" title="Imprimir códigos" src={icons.imprimir} alt="Imprimir códigos" style={{maxWidth: '22px'}}/> */}
            <em
                className="fa fa-ticket"
                title="Imprimir códigos"
                style={{ fontSize: "1.6em" }}
            />
        </Link>
    );
};

const listado_familias = [];
let listado_subfamilias = [];

class Detalle extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool,
    };

    static defaultProps = {
        loader: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            show_select: false,
        };
    }

    componentDidMount() {
        this.props.listarProductos(this.props.page);
    }

    componentWillUnmount() {
        this.props.limpiar();
    }

    isExpandableRow(row) {
        return true;
    }

    // function priceFormatter(cell, row) {
    //     console.log("DATOS FRACCIONES: ", cell)
    //     return (
    //         <div className="text-right">${cell}</div>
    //     )
    //     // return `<i class='glyphicon glyphicon-usd'></i> ${cell}`;
    // }

    expandComponent(row) {
        return (
            // pagination={data.sucursales.length > 10}
            <BootstrapTable data={row.fracciones} remote={false}>
                <TableHeaderColumn dataField="nombre" dataSort={false}>
                    Presentación
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="codigo_barras"
                    isKey={true}
                    dataSort={false}
                >
                    Código de barras
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                >
                    Precio
                </TableHeaderColumn>
                {/* <TableHeaderColumn width="20%" dataField="id" dataAlign="center"
                                dataFormat={activeFormatter({ver: '/sucursal/detalle', editar: '/sucursal/editar', eliminar})}>Acciones</TableHeaderColumn> */}
            </BootstrapTable>
            // <BSSucursal data={row} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = "";

        if (isExpandableRow) {
            if (isExpanded) {
                return (
                    <div className="text-center">
                        <em className="fa fa-caret-down" />
                    </div>
                );
            } else {
                return (
                    <div className="text-center">
                        <em className="fa fa-caret-right" />
                    </div>
                );
            }
        } else {
            content = " ";
        }
        return <div> {content} </div>;
    }

    render() {
        // state
        const { data, loader, page, estado_descarga, familia, sub_familia } =
            this.props;

        // bind
        const {
            listarProductos,
            eliminar,
            sortChange,
            descargarListado,
            setFamiliaValue,
            setSubFamiliaValue,
        } = this.props;

        // local
        const options = {
            ...tableOptions,
            noDataText: loader ? "Cargando..." : tableOptions.noDataText,
            onPageChange: listarProductos,
            onSortChange: sortChange,
            page,
        };

        const { getOptions: getFamilias } = useAsyncSearch(
            "familia",
            listado_familias
        );
        const { getOptions: getSubfamilias } = useAsyncSearch(
            "sub-familia",
            listado_subfamilias,
            {
                familia_id: familia ? familia.id : "",
            }
        );

        console.log(data);
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt">
                        <Link
                            className="btn btn-primary mb-sm-10"
                            to="/productos/crear/"
                        >
                            <em className="fa fa-plus"></em> Agregar Producto
                        </Link>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-secondary mb-sm-10 align-self-center"
                            disabled={estado_descarga}
                            onClick={() => {
                                descargarListado();
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-download"
                                }`}
                            ></em>
                            &nbsp;Descargar Listado
                        </button>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Productos"}
                                buscar={this.props.buscar}
                                search={this.props.search}
                            />
                        </div>
                        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                            <div className="flex-3">
                                <label>Familia</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getFamilias}
                                    placeholder={"Familia"}
                                    onChange={(e) => {
                                        // cambiar el estado de la familia
                                        setFamiliaValue(e);

                                        // cambiar el estado a show_select
                                        this.setState({
                                            show_select:
                                                !this.state.show_select,
                                        });

                                        // cambiar el estado de la subfamilia
                                        listado_subfamilias = [];
                                        setSubFamiliaValue(null);
                                    }}
                                    value={familia}
                                />
                            </div>
                            <div className="flex-1"></div>
                            {/* Recargar el select, segun el estado show_select */}
                            {this.state.show_select && (
                                <div className="flex-3">
                                    <label>Sub Familia</label>
                                    <Async
                                        className="form-control"
                                        labelKey="nombre"
                                        valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        loadOptions={getSubfamilias}
                                        placeholder={"Sub Familia"}
                                        onChange={(e) => {
                                            setSubFamiliaValue(e);
                                        }}
                                        disabled={!familia}
                                        value={sub_familia}
                                    />
                                </div>
                            )}
                            {/* Recargar el select, segun el estado show_select */}
                            {!this.state.show_select && (
                                <div className="flex-3">
                                    <label>Sub Familia</label>
                                    <Async
                                        className="form-control"
                                        labelKey="nombre"
                                        valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        loadOptions={getSubfamilias}
                                        placeholder={"Sub Familia"}
                                        onChange={(e) => {
                                            setSubFamiliaValue(e);
                                        }}
                                        disabled={!familia}
                                        value={sub_familia}
                                    />
                                </div>
                            )}
                            <div className="flex-3"></div>
                            <div className="flex-1"></div>
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                // expandableRow={this.isExpandableRow}
                                // expandComponent={this.expandComponent}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    // expandColumnComponent: this.expandColumnComponent,
                                    // columnWidth: 50
                                }}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="img_cropped"
                                    width="10%"
                                    dataFormat={formatoAvatar}
                                    dataAlign="center"
                                />
                                {/* <TableHeaderColumn isKey dataSort dataField='id'>ID</TableHeaderColumn> */}
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="codigo" dataSort>
                                    Codigo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="codigo_equivalente_1"
                                    dataSort
                                >
                                    Código equivalente 1
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fracciones"
                                    dataSort
                                    dataFormat={(value) => {
                                        try {
                                            return `Q. ${value[0].costo.toFixed(
                                                2
                                            )}`;
                                        } catch (error) {
                                            return "---";
                                        }
                                    }}
                                >
                                    Costo Q
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="costo_dolares"
                                    dataSort
                                    dataFormat={formatoCostoDolares}
                                >
                                    Costo $
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="precio_mostrador_calculado"
                                    dataSort
                                    dataFormat={(cell, row) => {
                                        try {
                                            return `Q. ${cell.toFixed(2)}`;
                                        } catch (error) {
                                            return "---";
                                        }
                                    }}
                                >
                                    Precio mostrador
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="subfamilia"
                                    dataFormat={(cell) => cell.nombre}
                                >
                                    Sub-familia
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={activeFormatter({
                                        ver: "/productos/detalle",
                                        editar: "/productos/editar",
                                        eliminar,
                                        // adicional: formatoVistaImprimir,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='id' dataAlign="center" width="20%"
                            dataFormat={activeFormatter({ver:'/productos/detalle', editar: '/productos/editar/', eliminar: eliminar })}>Acciones</TableHeaderColumn> */}
                                {/* <TableHeaderColumn dataField='id' dataAlign="center" width="20%"
                            dataFormat={activeFormatter({ editar: '/productos/editar', eliminar: eliminar, ver:'/productos/detalle' })}>Acciones</TableHeaderColumn> */}
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Detalle;
