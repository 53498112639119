import React from "react";
import { icons } from "icons";
import { Field, reduxForm } from "redux-form";
import LoadMask from "Utils/LoadMask/LoadMask";
import { renderTextArea, renderCurrency } from "Utils/renderField/renderField";
import {
    RenderDate,
    RenderCurrency,
    RenderDateTime,
} from "Utils/renderField/renderReadField";

const validate = (values, props) => {
    let errors = {};
    const { saldo, total_pago } = props;

    if (total_pago <= 0) {
        errors._error = "Ingresa los montos de pago";
    } else {
        if (saldo < 0) {
            errors._error = "El pago total no puede superar el saldo pendiente";
        }
    }

    return errors;
};

const Form = (props) => {
    // State
    const {
        error,
        saldo_inicial,
        saldo,
        loader_pago,
        total_pago,
        utiliza_fel,
        codigo_nota_credito,
        nota_credito_cargada,
        submitFailed,
    } = props;
    //  Bind
    const {
        handleSubmit,
        cancel,
        setCodigoNotaCredito,
        consultarNotaCredito,
        limpiarNotaCredito,
    } = props;

    let ref_codigo_nota_credito = "";

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 p-0">
            <LoadMask loading={loader_pago} dark blur>
                <div className="padding-15 p-sm-0 pt-sm-1">
                    <div className="d-flex justify-content-around flex-column">
                        <div className="d-flex flex-row form-group justify-content-end grid-end-seccion-ov py-1 m-0">
                            <h6
                                className="mr-3 mb-0 font-weight-bold"
                                style={{ color: "#1D8424" }}
                            >
                                Saldo pendiente
                            </h6>
                            <h6 className="mb-0" style={{ color: "#1D8424" }}>
                                <RenderCurrency
                                    value={saldo_inicial}
                                    className={"font-weight-bold"}
                                />
                            </h6>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.efectivo}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto recibido en Efectivo
                            </h4>
                            <div className="d-flex flex-column flex-1 negro-dark2">
                                <Field
                                    name="efectivo"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.tarjeta}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto Recibido en Tarjeta
                            </h4>
                            <div className="d-flex flex-column flex-1 negro-dark2">
                                <Field
                                    name="tarjeta"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.cheque}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto Recibido en Cheque
                            </h4>
                            <div className="d-flex flex-column flex-1 negro-dark2">
                                <Field
                                    name="cheque"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.deposito}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto Recibido en Depósito
                            </h4>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="deposito"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.retencion_exencion_iva}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto Recibido en Retención de IVA
                            </h4>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="retencion_iva"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.retencion_exencion_iva}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1.6" }}
                            >
                                Monto Recibido en Exención de IVA
                            </h4>
                            <div className="d-flex flex-column flex-1">
                                <Field
                                    name="exencion_iva"
                                    component={renderCurrency}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {/* NOTA DE CRÉDITO */}

                        {utiliza_fel && (
                            <div
                                style={{ backgroundColor: "white" }}
                                className="d-flex justify-content-between grid-end-seccion-ov py-2 m-0"
                            >
                                <div
                                    style={{ flex: "0.4" }}
                                    className="align-self-center text-center d-xs-none"
                                >
                                    <img
                                        style={{ maxWidth: "30px" }}
                                        src={icons.nota_credito}
                                    />
                                </div>
                                <div
                                    className="d-flex flex-column"
                                    style={{ flex: "1.3" }}
                                >
                                    <h4 className="negro-dark2 fnt-lg-16 fnt-sm-12">
                                        Monto Recibido en Nota de Crédito
                                    </h4>

                                    {/* INFO NOTA CREDITO */}
                                    {nota_credito_cargada && (
                                        <div className="w-100 py-2 px-1 d-flex flex-column">
                                            {/* FECHA */}
                                            <div className="d-flex mb">
                                                <b className="mr-1">Fecha</b>{" "}
                                                <RenderDate
                                                    value={
                                                        nota_credito_cargada
                                                            .nota_credito_fel
                                                            .fecha_emision
                                                    }
                                                />
                                            </div>
                                            <div className="d-flex mb">
                                                <b className="mr-1">Código</b>{" "}
                                                {nota_credito_cargada.codigo}
                                            </div>
                                            <div className="d-flex mb">
                                                <b className="mr-1">
                                                    Número de autorización
                                                </b>{" "}
                                                {
                                                    nota_credito_cargada
                                                        .nota_credito_fel.uuid
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="negro-dark2"
                                    style={{ flex: "1.3" }}
                                >
                                    <div className="d-flex justify-content-end">
                                        {nota_credito_cargada ? (
                                            <button
                                                className="btn btn-danger"
                                                onClick={limpiarNotaCredito}
                                            >
                                                Eliminar
                                            </button>
                                        ) : (
                                            <input
                                                id="nota_credito"
                                                name="nota_credito"
                                                type="text"
                                                placeholder="Código o número de autorización"
                                                className="form-control"
                                                ref={(node) => {
                                                    ref_codigo_nota_credito = node;
                                                    if (
                                                        ref_codigo_nota_credito
                                                    ) {
                                                        ref_codigo_nota_credito.value = codigo_nota_credito;
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        if (
                                                            ref_codigo_nota_credito.value
                                                        ) {
                                                            consultarNotaCredito(
                                                                total_pago
                                                            );
                                                        }
                                                    }
                                                }}
                                                onChange={() => {
                                                    setCodigoNotaCredito(
                                                        ref_codigo_nota_credito.value
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                    {nota_credito_cargada && (
                                        <div className="w-100 text-right my-3 fnt-18">
                                            <b>
                                                <RenderCurrency
                                                    value={
                                                        nota_credito_cargada.total_importe
                                                    }
                                                />
                                            </b>
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{ flex: "0.2", height: "30px" }}
                                    className="d-flex justify-content-center"
                                >
                                    {!nota_credito_cargada && (
                                        <button
                                            className="btn btn-secondary ml-2"
                                            onClick={() =>
                                                consultarNotaCredito(total_pago)
                                            }
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="d-flex flex-row form-group justify-content-between grid-end-seccion-ov py-2 m-0">
                            <div
                                style={{ flex: "0.4" }}
                                className="align-self-center text-center d-xs-none"
                            >
                                <img
                                    style={{ maxWidth: "30px" }}
                                    src={icons.notas}
                                />
                            </div>
                            <h4
                                className="align-self-center negro-dark2 fnt-lg-16 fnt-sm-12 m-0"
                                style={{ flex: "1" }}
                            >
                                Nota
                            </h4>
                            <div
                                className="d-flex flex-column negro-dark2"
                                style={{ flex: "1.5" }}
                            >
                                <Field
                                    name="nota"
                                    rows={2}
                                    component={renderTextArea}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="grid-end-seccion-ov py-3 m-0">
                            <div className="d-flex flex-row justify-content-between">
                                <div
                                    style={{ flex: "0.4" }}
                                    className="align-self-center text-center d-xs-none"
                                ></div>
                                <h5
                                    className="align-self-center negro-dark m-0"
                                    style={{ flex: "1.6" }}
                                >
                                    Total
                                </h5>
                                <div className="d-flex flex-column flex-1 negro-dark text-right">
                                    <h5 className="m-0">
                                        <RenderCurrency
                                            value={total_pago}
                                            className={"font-weight-bold"}
                                        />
                                    </h5>
                                </div>
                            </div>
                        </div>
                        {submitFailed && error && (
                            <div className="w-100 text-center mt-3">
                                <span className="invalid-feedback d-block font-weight-bold">
                                    {error}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="d-flex justify-content-center flex-row mt-3">
                        <button
                            type="button"
                            className="btn btn-tertiary m-1 align-self-center"
                            onClick={cancel}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary m-1 align-self-center"
                        >
                            Registrar
                        </button>
                    </div>
                </div>
            </LoadMask>
        </form>
    );
};

const IngresoPagoForm = reduxForm({
    form: "IngresoPagoOV",
    validate,
})(Form);

export default IngresoPagoForm;
