import { connect } from "react-redux";
//import { actions } from "../../../../redux/modules/venta/venta";
import { actions } from "../../../../redux/modules/venta/venta";
import Venta from "./Venta";
import { push } from "react-router-redux";
import { print } from "../../../../redux/modules/print/print";

const ms2p = (state) => {
    let total = 0;
    state.venta.seleccionados.forEach((producto) => {
        if (!producto.es_vale) {
            const precio = producto.precio ? parseFloat(producto.precio) : 0;
            const cantidad = producto.cantidad
                ? parseFloat(producto.cantidad)
                : 0;
            const desc_porcentaje = producto.desc_porcentaje
                ? parseFloat(producto.desc_porcentaje)
                : 0;
            const desc_monto = producto.desc_monto
                ? parseFloat(producto.desc_monto)
                : 0;
            total = total + precio * cantidad;
        } else {
            total -= producto.total_importe;
        }
    });
    if (state.usuario.conf_venta_redondeo) {
        const decimal = parseFloat((total % 1).toFixed(2));
        const moneda_mas_pequeno = 0.05;
        const cantidad_monedas = Math.floor(decimal / moneda_mas_pequeno);
        const { redondeo } = state.venta;
        const reciduo = parseFloat(
            (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
        );
        if (redondeo && reciduo != 0) {
            if (redondeo == "arriba") {
                total =
                    Math.floor(total) +
                    (cantidad_monedas + 1) * moneda_mas_pequeno;
            } else {
                total =
                    Math.floor(total) + cantidad_monedas * moneda_mas_pequeno;
            }
        }
    }
    return {
        ...state.venta,
        pais: state.usuario.me.pais,
        sucursal: state.usuario.sucursal,
        formato_factura: state.usuario.formato_factura,
        factura_personalizada: state.usuario.factura_personalizada,
        venta_permitir_descuento: state.usuario.venta_permitir_descuento,
        total: parseFloat(total.toFixed(2)),
        utiliza_fel: state.usuario.utiliza_fel,
        conf_cotizacion: state.usuario.conf_cotizacion,
        conf_venta_redondeo: state.usuario.conf_venta_redondeo,
        conf_venta_precio_editable: state.usuario.conf_venta_precio_editable,
        print_state: state.print,
        conf_porcentaje_precio: state.usuario.conf_porcentaje_precio,
        conf_mostrar_referencia: state.usuario.conf_mostrar_referencia,
        conf_ventas_al_credito: state.usuario.conf_ventas_al_credito,
        conf_habilitar_campo_cliente:
            state.usuario.conf_habilitar_campo_cliente,
        buscar_nombre: state.usuario.buscar_nombre,
        conf_empresa: state.usuario, // se utiliza en DatosVentas para verificar la configuracion de  decimales
    };
};

const md2p = {
    ...actions,
    pushRouter: (link) => (dispatch) => dispatch(push(link)),
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(Venta);
