import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import { tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency } from "Utils/renderField/renderReadField";
// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReporteInv } from "../../../Utils/FiltrosReportes";
import { RenderDate, RenderNumber } from "../../../Utils/renderField/renderReadField";
import { formatoCostoQuetzales, } from "../../../Utils/CamposComunes";
import NumberFormat from "react-number-format";


const ListadoArticulosVendidosPorFamilia = ({
    // state
    loader,
    data,
    estado_descarga,

    fecha_inicial,
    fecha_final,
    sucursal = null,
    familia = "",
    subfamilia = "",
    vendedor = "",
    gran_total_ventas,
    gran_total_cantidades,

    usuario = null,
    sucursal_param = null,

    // bind
    descargarListado,
    aplicarFiltros,
    limpiarFiltros,

    setFecha,
    setSucursal,
    setFamilia,
    setSubfamilia,
    setVendedor,

    page,
    listar,
    sortChange,
}) => {
    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page: page,
    };

    useEffect(() => {
        limpiarFiltros();
        return () => {
            const unMount = true;
            limpiarFiltros(unMount);
        };
    }, []);

    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReporteInv
                        sucursal={sucursal}
                        familia={familia}
                        subfamilia={subfamilia}
                        fecha_inicial={fecha_inicial}
                        fecha_final={fecha_final}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        vendedor={vendedor}
                        setSucursal={setSucursal}
                        setFamilia={setFamilia}
                        setSubfamilia={setSubfamilia}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setVendedor={setVendedor}
                        aplicarFiltros={aplicarFiltros}
                        filtroArticulosVendidosPorFamilia
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                        }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="codigo_producto">
                                    Código
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo_equivalente">
                                    Código Equivalente
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="nombre_producto">
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="total_cantidad"
                                    dataAlign="right"
                                >
                                    Cnt. vendido
                                </TableHeaderColumn>


                                <TableHeaderColumn
                                    dataField="total_ventas"
                                    dataAlign="right"
                                    dataFormat={formatoCostoQuetzales}
                                >
                                    Total Q.
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                ></TableHeaderColumn>
                            </BootstrapTable>

                            <div className="d-flex flex-column flex-md-row justify-content-center">
                                <div className="mr-0 mr-md-4">
                                    <label>
                                        <strong>Total Cantidad: &nbsp;</strong>
                                    </label>
                                    <label>
                                        <RenderNumber
                                            value={gran_total_cantidades.cantidades}
                                        />
                                    </label>
                                </div>

                                <div className="mr-0 mr-md-4">
                                    <label>
                                        <strong>Total Ventas: &nbsp;</strong>
                                    </label>
                                    <label>
                                        <RenderCurrency
                                            value={gran_total_ventas.ventas}
                                        />
                                    </label>
                                </div>
                            </div>

                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoArticulosVendidosPorFamilia.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoArticulosVendidosPorFamilia;
