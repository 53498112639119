import React, { Component } from "react";
import _ from "lodash";
import { icons } from "icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ActionItem, HeaderItem } from "react-dropdown-advanced";

function formatoNivel(cell, row) {
    const {
        es_encargado_tienda,
        es_auxiliar_gerencia,
        es_gerente,
        es_jefe_bodega,
        es_motorista,
        es_vendedor,
        es_vendedor_mayorista,
        es_vendedor_distribuidor,
    } = row;

    if (es_encargado_tienda) return "Encargado de Tienda";
    if (es_auxiliar_gerencia) return "Auxiliar de Gerencia";
    if (es_gerente) return "Gerente";
    if (es_jefe_bodega) return "Jefe de Bodega";
    if (es_motorista) return "Motorista";
    if (es_vendedor) return "Vendedor";
    if (es_vendedor_mayorista) return "Vendedor Mayorista";
    if (es_vendedor_distribuidor) return "Vendedor Distribuidor";
    return "---";
}

function formatoEstado(cell, row) {
    const { activo } = row;
    if (activo) return <span style={{ color: "#067618" }}>Activo</span>;
    return <span style={{ color: "#F20004" }}>Inactivo</span>;
}

function formatoSucursales(cell, row) {
    if (row.es_global) return "Todas";
    if (!cell.length > 0) {
        return "Todas";
    }
    return (
        <div>
            {cell.map((item, index) => {
                return (
                    <span key={index}>
                        {index != 0 ? ", " : ""}
                        {item.nombre}
                    </span>
                );
            })}
        </div>
    );
}
class ListadoUsuarios extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dropdownItems: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem("agregar_usuario", "¿Como agrego un usuario?"),
                new ActionItem("editar", "¿Cómo editar un usuario?"),
                new ActionItem("eliminar", "¿Cómo eliminar un usuario?"),
            ],
            modalAyuda: false,
            itemsImagenes: [],
            itemsCrearEditar: [
                {
                    src: icons.usuario_crear_editar_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.usuario_crear_editar_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.usuario_crear_editar_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.usuario_crear_editar_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.usuario_crear_editar_p5,
                    alt: "Paso 5",
                },
            ],
            itemsEditar: [
                {
                    src: icons.usuario_accion_editar,
                    alt: "Editar",
                },
            ],
            itemsEliminar: [
                {
                    src: icons.usuario_accion_eliminar,
                    alt: "Eliminar",
                },
            ],
        };
    }
    componentWillMount() {
        this.props.listar(this.props.page);
    }

    onClickAyuda = (item) => {
        if (item.key === "agregar_usuario") {
            this.setState({
                itemsImagenes: this.state.itemsCrearEditar,
            });
            this.openModal();
        } else if (item.key === "eliminar") {
            this.setState({
                itemsImagenes: this.state.itemsEliminar,
            });
            this.openModal();
        } else if (item.key === "editar") {
            this.setState({
                itemsImagenes: this.state.itemsEditar,
            });
            this.openModal();
        }
    };

    closeModal = () => {
        this.setState({ modalAyuda: false });
    };
    openModal = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        // state
        const { data, loader } = this.props;

        // bind
        const { eliminar } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex justify-content-end">
                        <Link
                            className="btn btn-secondary mb-3 flex-md-0 flex-1"
                            to="/configuracion/usuarios/crear/"
                        >
                            Agregar Usuario
                        </Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Usuarios"}
                                buscar={this.props.buscar}
                                search={this.props.search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                trStyle={(row, rowIdx) => {
                                    return {
                                        backgroundColor:
                                            rowIdx % 2 === 0
                                                ? "#FFFFFF"
                                                : "#F8F8F8",
                                    };
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="first_name"
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="last_name"
                                    dataSort
                                >
                                    Apellido
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    dataFormat={formatoNivel}
                                >
                                    Rol
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="activo"
                                    dataFormat={formatoEstado}
                                >
                                    Estado
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="sucursales"
                                    dataFormat={formatoSucursales}
                                >
                                    Sucursales
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => {
                                        if (row.sucursal === null) return "";
                                        return activeFormatter({
                                            editar: "/configuracion/usuarios/editar",
                                            eliminar,
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoUsuarios;
