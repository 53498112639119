import { connect } from 'react-redux';
import { crear, setProveedores } from "../../../../redux/modules/proveedor/proveedor";
import Crear from './ProveedoresCrear';


const ms2p = (state) => {
    return {
        ...state.proveedor,
    };
};

const md2p = { crear, setProveedores };

export default connect(ms2p, md2p)(Crear);
