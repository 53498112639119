import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderCurrency } from "Utils/renderField/renderReadField";

import { FiltrosReportes } from "../../../Utils/FiltrosReportes";

import ReporteComisionTabla from "./ReporteComisionTabla";

const ListadoComisiones = ({
    //state
    loader,
    fecha_vencimiento_inicial,
    fecha_vencimiento_final,
    estado_descarga,

    cliente,
    vendedor,
    sucursal,
    total_comisiones,

    usuario = null,
    sucursal_param = null,

    // bind
    setFecha,
    descargarListado,
    aplicarFiltros,
    setDataValue,
    setSucursal,

    setCliente,
    setVendedor,
}) => {
    useEffect(() => {
        return () => {
            setDataValue({});
        };
    }, []);

    const {
        total_venta_sin_iva,
        total_devolucion_descuento,
        total_neto,
        total_comision,
    } = total_comisiones;

    return (
        <div className="mb-5">
            <div className="row">
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={cliente}
                        vendedor={vendedor}
                        sucursal={sucursal}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={setCliente}
                        setVendedor={setVendedor}
                        setSucursal={setSucursal}
                        aplicarFiltros={aplicarFiltros}
                        setDataValue={setDataValue}
                        filtroComisionMensual
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text mt-3">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <ReporteComisionTabla />

                            <div className="d-flex flex-column flex-md-row justify-content-center">
                                <div className="mr-0 mr-md-4">
                                    <label>
                                        <strong>T. Venta sin iva:</strong>
                                    </label>
                                    <label>
                                        <RenderCurrency
                                            value={total_venta_sin_iva}
                                        />
                                    </label>
                                </div>

                                <div className="mr-0 mr-md-4">
                                    <label>
                                        <strong>T. Dev. & Desc.:</strong>
                                    </label>
                                    <label>
                                        <RenderCurrency
                                            value={total_devolucion_descuento}
                                        />
                                    </label>
                                </div>

                                <div className="mr-0 mr-md-4">
                                    <label>
                                        <strong>T. Neto:</strong>
                                    </label>
                                    <label>
                                        <RenderCurrency value={total_neto} />
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        <strong>T. Comisión:</strong>
                                    </label>
                                    <label>
                                        <RenderCurrency
                                            value={total_comision}
                                        />
                                    </label>
                                </div>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoComisiones.propTypes = {
    listarReporte: PropTypes.func.isRequired,
};

export default ListadoComisiones;
