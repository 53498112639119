import { connect } from "react-redux";
import TableProducts from "./tableProducts";
//import DetailProduct from "../detailProduct";

import { actions } from "../../../../../redux/modules/buscador_producto/buscador_producto";

const ms2p = (state) => {
    const sucursales = state.usuario.me.todas_sucursales
        ? state.usuario.me.todas_sucursales
        : [];
    let sucursal = state.usuario.sucursal
        ? parseInt(state.usuario.sucursal)
        : "empresa";

    let empresa = state.usuario.me.empresa;
    if (!_.find(sucursales, { id: "empresa" }))
        sucursales.unshift({ nombre: "Todas", id: "empresa" });

    return {
        ...state.buscador_producto,
        data: state.buscador_producto.data,
        estado_descarga: state.buscador_producto.estado_descarga,
        sucursales,
        sucursal,
        empresa,
        permisos_producto: state.usuario.me.producto,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(TableProducts);
