import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/comision_mensual";
import ListadoComisiones from "./ListadoComisiones";

const ms2p = (state) => {
    return {
        ...state.reporte_comision_mensual,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoComisiones);
