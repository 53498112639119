import { connect } from "react-redux";
import { setProveedores } from "../../../../redux/modules/proveedor/proveedor";
import ContactosForm from "./ContactosForm";

const ms2p = (state) => {
    return {
        proveedores: state.proveedor.contactos,
    };
};

const md2p = { setProveedores };

export default connect(ms2p, md2p)(ContactosForm);
