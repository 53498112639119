import React, { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { tableOptions } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";

const LitadoProveedores = ({
    page,
    listar,
    data,
    loader,
    estado_descarga,
    eliminar,
    descargarListado,
    buscar,
    search,
    sortChange,
}) => {
    useEffect(() => {
        listar(page);
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <Link
                            className="btn btn-secondary mb-3 flex-md-0 flex-1"
                            to="/configuracion/proveedores/crear/"
                        >
                            Crear proveedor
                        </Link>
                        {/* &nbsp;&nbsp;
                <button
                    className="btn btn-secondary align-self-center mb-sm-10"
                    disabled={estado_descarga}
                    onClick={() => {
                        descargarListado();
                    }}
                >
                    <em
                        className={`fa ${
                            estado_descarga
                                ? "fa-spinner fa-pulse"
                                : "fa-download"
                        }`}
                    ></em>
                    &nbsp;Descargar Listado
                </button> */}
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo="Listado"
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                trStyle={(row, rowIdx) => {
                                    return {
                                        backgroundColor:
                                            rowIdx % 2 === 0
                                                ? "#FFFFFF"
                                                : "#F8F8F8",
                                    };
                                }}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataSort
                                    width="5%"
                                >
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nit"
                                    dataFormat={(cell) => {
                                        return cell ? cell : "CF";
                                    }}
                                >
                                    Nit
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="telefono">
                                    Teléfono
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo_proveedor"
                                    dataFormat={(cell) => {
                                        return cell === 2
                                            ? "Local"
                                            : cell === 1
                                            ? "Importación"
                                            : cell === 3
                                            ? "Servicios"
                                            : "---";
                                    }}
                                >
                                    Tipo de proveedor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => {
                                        if (row.sucursal === null) return "";
                                        return activeFormatter({
                                            editar: "/configuracion/proveedores/editar",
                                            eliminar,
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

LitadoProveedores.propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    eliminar: PropTypes.func.isRequired,
    buscar: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    listar: PropTypes.func.isRequired,
};

export default LitadoProveedores;
