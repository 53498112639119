import React, { useState } from "react";

import { useParams, Link } from "react-router-dom";
import { Form, Field } from "react-final-form";

import useAsyncOptions from "../../../../hooks/useAsyncOptions";

import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
    NIT,
} from "../../../../../utility/validation";

import {
    renderField,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    renderCurrency,
    renderSelectField,
    renderAsyncSelectField,
} from "Utils/renderField/renderField";

import VerificacionPasswordForm from "./verificacionPasswordForm";

import UpdateProductPrice from "./updateProductPrice";

const UpdateProduct = ({
    setSelected,
    data = {},
    view = false,
    update = false,
    verificarContrasena,
    producto,
    fracciones,
    cambiarPrecioCosto,
    closeModalProducts,
}) => {
    //Verificacion password
    const [verificado, setVerificado] = useState(false);

    const verificar = (data) => {
        verificarContrasena(data, setVerificado);
    };

    const actualizarProducto = (data) => {
        cambiarPrecioCosto(data, closeModalProducts);
    };

    return (
        <React.Fragment>
            <div className="form-validate mt-2 mb-lg">
                <div>
                    <hr />

                    {/* Form 0 */}

                    <VerificacionPasswordForm
                        onSubmit={verificar}
                        verificado={verificado}
                        className="mb-2"
                    />

                    {(!verificado && (
                        <React.Fragment>
                            <div className="verification-false">
                                <span>No verificado</span>
                            </div>
                        </React.Fragment>
                    )) ||
                        (verificado && (
                            <React.Fragment>
                                <div className="verification-true">
                                    <span>Verificado</span>
                                </div>
                            </React.Fragment>
                        ))}

                    {/* Form 1 */}

                    <UpdateProductPrice
                        onSubmit={actualizarProducto}
                        producto={producto}
                        fracciones={fracciones}
                        setSelected={setSelected}
                        verificado={verificado}
                        className="mt-2"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default UpdateProduct;
