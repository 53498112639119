import React, { Component } from "react";
import { icons } from "icons";
import PropTypes from "prop-types";
import BajasForm from "./BajasForm";
import ModalAyuda from "Utils/Ayuda";
import { SeleccionSucursal } from "../../Utils/SeleccionSucursal";

class ReajusteProducto extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {
            ver_buscador: false,
            modalAyuda: false,
            itemsBodegaBaja: [
                // {
                //     src: icons.bodega_baja_p1,
                //     alt: "Paso 1"
                // },
                {
                    src: icons.bodega_baja_p2,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_baja_p3,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_baja_p4,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_baja_p5,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_baja_p6,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_baja_p7,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_baja_p8,
                    alt: "Paso 7",
                },
            ],
        };
    }
    // componentWillMount() {
    //     this.props.listar();
    // }
    cambioBuscador() {
        this.setState({ ver_buscador: !this.state.ver_buscador });
    }
    closeModal = () => {
        this.setState({ modalAyuda: false });
    };
    openModal = () => {
        this.setState({ modalAyuda: true });
    };

    // desomnar el componente
    componentWillUnmount() {
        this.props.limpiar();
    }

    render() {
        const { sucursal } = this.props;
        const { modalAyuda, itemsBodegaBaja } = this.state;

        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Para registrar una baja elija una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <BajasForm
                    {...this.props}
                    onSubmit={this.props.efectuarBaja}
                    ver_buscador={this.state.ver_buscador}
                    cambio_buscador={this.cambioBuscador.bind(this)}
                    actionAyuda={this.openModal}
                />
                <ModalAyuda
                    open={modalAyuda}
                    closeModal={this.closeModal}
                    openModal={this.openModal}
                    items={itemsBodegaBaja}
                />
            </React.Fragment>
        );
    }
}

export default ReajusteProducto;
