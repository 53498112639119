import classNames from "classnames";
import React, { useEffect, useState } from "react";

import {
    ValidateNitDpiAPI,
    validarDpi,
} from "../../../../../utility/validation";
import validarNit from "validar-nit-gt";
import Select from "react-select/lib/Select";
import {
    ID_DOC_EXT,
    ID_DPI,
    ID_NIT,
    TIPOS_ID_CLIENTE,
} from "../../../../../utility/variables";

const CambiarDatosForm = ({
    textButton = "Facturar",
    estado_descarga,
    modal,
    datos_cliente,
    onSubmit = () => {},
    setModal,
}) => {
    const [nit, setNit] = useState("");
    const [nombre, setNombre] = useState("");
    const [error, setError] = useState({
        invalidNit: false,
        invalidNombre: false,
        messageNit: "",
        messageNombre: "",
    });
    const [tipoId, setTipoId] = useState(1);

    const { cliente, datos_cambiados, tipo_documento } = datos_cliente;

    const { messageNombre, messageNit, invalidNit, invalidNombre } = error;

    const validacionNit = () => {
        if (nit.length == 0) {
            setError({
                invalidNit: true,
                messageNit: "El campo NIT es requerido",
            });
            return;
        }

        if (
            !validarNit(nit) &&
            nit.toUpperCase() != "CF" &&
            nit.toUpperCase() != "C/F" &&
            nit.toUpperCase() != "C-F"
        ) {
            setError({
                ...error,
                invalidNit: true,
                messageNit: "NIT inválido",
            });
            return;
        }

        if (nombre.length == 0) {
            setError({
                ...error,
                invalidNit: true,
                messageNit: "El NIT no existe",
            });
            return;
        }
        onSubmit({ nit, nombre, tipoId });
    };

    const validacionDpi = () => {
        if (nit.length == 0) {
            setError({
                invalidNit: true,
                messageNit: "El campo DPI es requerido",
            });
            return;
        }

        if (!validarDpi(nit)) {
            setError({
                ...error,
                invalidNit: true,
                messageNit: "DPI inválido",
            });
            return;
        }

        if (nombre.length == 0) {
            setError({
                ...error,
                invalidNit: true,
                messageNit: "El DPI no existe",
            });
            return;
        }
        onSubmit({ nit, nombre, tipoId });
    };

    const validacionDocExt = () => {
        if (nit.length == 0) {
            setError({
                ...error,
                invalidNit: true,
                messageNit: "El campo Numero de Identificación es requerido",
            });
            return;
        }

        if (nombre.length == 0) {
            setError({
                ...error,
                invalidNombre: true,
                messageNombre: "El campo Nombre es requerido",
            });
            return;
        }

        onSubmit({ nit, nombre, tipoId });
    };

    const VALIDACIONES = {
        [ID_NIT]: validacionNit,
        [ID_DPI]: validacionDpi,
        [ID_DOC_EXT]: validacionDocExt,
    };

    const handleSubmit = () => {
        VALIDACIONES[tipoId]();
    };

    useEffect(() => {
        if (tipoId == ID_NIT || tipoId == ID_DPI) {
            const esDpi = tipoId === ID_DPI;
            const validacionInput = esDpi ? validarDpi : validarNit;
            const validacionCondicional = esDpi
                ? validacionInput(nit)
                : validacionInput(nit) && nit != "CF";

            if (validacionCondicional) {
                ValidateNitDpiAPI(nit, esDpi)
                    .then((res) => {
                        setNombre(res.data);
                        setError({
                            invalidNit: false,
                            messageNit: "",
                        });
                    })
                    .catch((err) => {
                        // console.log("error------------", err.error);
                        // setNombre("");
                        setError({
                            invalidNit: true,
                            messageNit: err.error,
                        });
                    });
            } else {
                setNombre("");
            }
            if (
                (nit.toUpperCase() == "CF" ||
                    nit.toUpperCase() == "C/F" ||
                    nit.toUpperCase() == "C-F") &&
                !esDpi
            ) {
                setNombre("Consumidor Final");
            }
        } else {
            if (nit.length > 0) {
                setError({
                    ...error,
                    invalidNit: false,
                    messageNit: "",
                });
            }

            // console.log("nombre.length > 0", nombre.length > 0);
            if (nombre.length > 0) {
                setError({
                    ...error,
                    invalidNombre: false,
                    messageNombre: "",
                });
            }
        }
    }, [nit, nombre]);

    useEffect(() => {
        if (datos_cambiados) {
            setNit(cliente.no_doc_cliente);
            setNombre(cliente.nombre_cliente);
            setTipoId(tipo_documento);
        }

        // console.log(tipo_documento);
    }, [datos_cambiados]);

    // console.log("error", error);

    return (
        <React.Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                className="form-validate w-100"
            >
                <div className="d-flex flex-column">
                    <div className="form-group has-feedback flex-1 mb-3">
                        <label>Tipo de Identificación</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable
                            className="form-control"
                            options={TIPOS_ID_CLIENTE}
                            valueKey="value"
                            labelKey="label"
                            value={tipoId}
                            onChange={(e) => {
                                setTipoId(e ? e.value : 1);
                            }}
                        />
                    </div>

                    <div className="form-group has-feedback flex-1 mb-3">
                        <label htmlFor="nit">
                            Numero de{" "}
                            {tipoId === 1
                                ? "NIT"
                                : tipoId === 2
                                ? "DPI"
                                : "Identificación"}
                        </label>
                        <input
                            placeholder={
                                tipoId === ID_NIT
                                    ? "NIT"
                                    : tipoId === ID_DPI
                                    ? "DPI"
                                    : "Identificación"
                            }
                            id="nit"
                            autoComplete="off"
                            value={nit}
                            onChange={(e) => {
                                setNit(e.target.value);
                            }}
                            className={classNames("form-control", {
                                "is-invalid": invalidNit,
                            })}
                        />
                        {invalidNit && (
                            <div className="invalid-feedback">{messageNit}</div>
                        )}
                    </div>

                    <div className="form-group has-feedback flex-1 mb-3">
                        <label htmlFor="nombre_cliente">
                            Nombre del Cliente*
                        </label>
                        <input
                            placeholder="Nombre del Cliente"
                            id="nombre_cliente"
                            disabled={tipoId !== ID_DOC_EXT ? true : false}
                            autoComplete="off"
                            value={nombre}
                            onChange={(e) => {
                                setNombre(e.target.value);
                            }}
                            className={classNames("form-control", {
                                "is-invalid": invalidNombre,
                            })}
                        />
                        {invalidNombre && (
                            <div className="invalid-feedback">
                                {messageNombre}
                            </div>
                        )}
                    </div>
                </div>

                <div className="d-flex justify-content-center flex-1 mt-3">
                    <button
                        className="btn btn-outline-primary mr-3"
                        style={{ padding: ".6rem 2rem" }}
                        type="button"
                        onClick={() => {
                            setModal({ ...modal, openSecond: false });
                        }}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-primary px-5"
                        style={{ padding: ".6rem 2rem" }}
                        type="submit"
                        disabled={estado_descarga}
                    >
                        <em
                            className={`fa ${
                                estado_descarga ? "fa-spinner fa-pulse" : ""
                            }`}
                        />
                        &nbsp;{textButton}
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
};

export default CambiarDatosForm;
