import React, { useEffect, useState } from "react";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import moment from "moment";

function rowClassNameFormat(row, rowIdx) {
    return !!row.anulado ? "underline-row" : "";
}
export const TablaOC = (props) => {
    const [unselectable, setUnselectable] = useState([]);

    const { cuenta_pagar, seleccionados, setCuentasPagar, setSeleccionados } =
        props;

    const selectRowProp2 = {
        mode: "checkbox",
        bgColor: "#d4eaff",
        unselectable: unselectable,
        selected: seleccionados,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSeleccionados([...seleccionados, row.id]);
                setCuentasPagar([...cuenta_pagar, row]);
            } else {
                const changeSelected = seleccionados.filter(
                    (item) => item !== row.id
                );
                setSeleccionados(changeSelected);

                const changeCuentaPagar = cuenta_pagar.filter(
                    (item) => item.id !== row.id
                );
                setCuentasPagar(changeCuentaPagar);
            }
        },
        onSelectAll: (isSelect, rows) => {
            // console.log(isSelect);
            // console.log(rows);
            if (isSelect && seleccionados.length === 0) {
                setSeleccionados(rows.map((item) => item.id));
                setCuentasPagar(rows);
            } else if (!isSelect && seleccionados.length === 0) {
                setSeleccionados([]);
                setCuentasPagar([]);
            } else if (isSelect && seleccionados.length > 0) {
                const changeSelected = rows.map((item) => item.id);
                const changeCuentaPagar = rows;
                setSeleccionados([...seleccionados, ...changeSelected]);
                setCuentasPagar([...cuenta_pagar, ...changeCuentaPagar]);
            } else if (!isSelect && seleccionados.length > 0) {
                const changeSelected = seleccionados.filter(
                    (item) => rows.find((i) => i.id === item) === undefined
                );
                const changeCuentaPagar = cuenta_pagar.filter(
                    (item) => rows.find((i) => i.id === item.id) === undefined
                );
                setSeleccionados(changeSelected);
                setCuentasPagar(changeCuentaPagar);
            }
        },
    };

    const { datos_listado } = props;

    useEffect(() => {
        const list_of_unselectable = [];
        if (datos_listado.results) {
            datos_listado.results.map((item) => {
                if (item.pago_finalizado) {
                    list_of_unselectable.push(item.id);
                }
            });
            setUnselectable(list_of_unselectable);
        }
    }, [datos_listado.results]);

    return (
        <BootstrapTable
            data={props.loader ? [] : props.datos_listado.results}
            remote
            pagination
            hover
            selectRow={selectRowProp2}
            fetchInfo={{ dataTotalSize: props.datos_listado.count }}
            trClassName={rowClassNameFormat}
            options={props.options}
        >
            <TableHeaderColumn
                dataField="fecha"
                dataFormat={(cell) => <RenderDate value={cell} />}
                // dataSort
            >
                Fecha de venta
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_fin_pago"
                dataFormat={(cell, row) => {
                    return (
                        <div>
                            {row.tipo_pago ? (
                                <RenderDate value={cell} />
                            ) : (
                                <span>-----</span>
                            )}
                        </div>
                    );
                }}
            >
                Fecha de vencimiento
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="modificado"
                dataFormat={(cell, row) => {
                    return (
                        <div>
                            {row.pago_finalizado ? (
                                <RenderDate value={cell} />
                            ) : (
                                <span>-----</span>
                            )}
                        </div>
                    );
                }}
            >
                Fecha de Pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="proveedor__nombre"
                dataFormat={(cell, row) => row.numero_documento}
            >
                No. Documento
            </TableHeaderColumn>
            {/* <TableHeaderColumn dataField="no_orden">Código</TableHeaderColumn> */}
            <TableHeaderColumn
                dataField="pago_finalizado"
                dataFormat={(cell, row) => {
                    if (row.oc_vencida && !row.pago_finalizado) {
                        return (
                            <span className="font-weight-bold text-danger">
                                Vencido
                            </span>
                        );
                    }
                    return (
                        <div>
                            {cell ? (
                                // <img
                                //     style={{ margin: "0", height: "1.5rem" }}
                                //     src={icons.check}
                                //     alt="Finalizado"
                                // />
                                <span className="font-weight-bold text-success">
                                    Finalizado
                                </span>
                            ) : (
                                // <img
                                //     style={{ margin: "0", height: "1.5rem" }}
                                //     src={icons.reloj}
                                //     alt="Pendiente"
                                // />
                                <span className="font-weight-bold txt-yellow">
                                    Pendiente
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Estado pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="proveedor__nombre"
                dataFormat={(cell, row) => <div>{row.proveedor.nombre}</div>}
            >
                Proveedor
            </TableHeaderColumn>

            {/* <TableHeaderColumn
                dataField="tipo_pago"
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell == 1000 ? (
                                <span className="font-weight-bold text-success">
                                    Contado
                                </span>
                            ) : (
                                <span className="font-weight-bold primary">
                                    Crédito
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Tipo de pago
            </TableHeaderColumn> */}

            {/* <TableHeaderColumn
                dataAlign="left"
                dataField="emitida_por"
                dataFormat={(cell) => {
                    if (cell) {
                        return cell.first_name;
                    }
                    return "---";
                }}
            >
                Realizado por
            </TableHeaderColumn> */}

            <TableHeaderColumn
                dataAlign="right"
                dataField="total"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Total
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign="right"
                dataField="total_retencion"
                dataFormat={(cell) => (
                    <RenderCurrency
                        className={cell > 0 ? "font-weight-bold" : ""}
                        value={cell}
                    />
                )}
            >
                Total retención
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign="right"
                dataField="total_abono"
                dataFormat={(cell) => (
                    <RenderCurrency
                        className={cell > 0 ? "font-weight-bold" : ""}
                        value={cell}
                    />
                )}
            >
                Total abono
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign="right"
                dataField="saldo"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Saldo
            </TableHeaderColumn>
            <TableHeaderColumn
                isKey
                dataField="id"
                dataAlign="center"
                dataFormat={(cell, row) => {
                    const created = moment(row.creado).add(24, "hours");
                    const now = moment();
                    // console.log('creado ', created)
                    // console.log('ahora', now)
                    // console.log('row',  row)
                    let anular_activo = true;
                    if (created <= now) {
                        anular_activo = false;
                    }
                    if (row.anular_deshabilitado === true) {
                        anular_activo = false;
                    }
                    return activeFormatter({
                        ver: "/cuentas_por_pagar",
                        icono_pagar: true,
                    })(cell, row);
                }}
            >
                Acciones
            </TableHeaderColumn>
        </BootstrapTable>
    );
};
