import CajaVenta from "./CajaVenta";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions } from "../../../../../redux/modules/punto_venta/caja";
import { print } from "../../../../../redux/modules/print/print";

const ms2p = (state) => {
    let total = 0;
    state.punto_venta_caja.detalle_venta.forEach((producto) => {
        if (!producto.es_vale) {
            const precio = producto.precio ? parseFloat(producto.precio) : 0;
            const cantidad = producto.cantidad
                ? parseFloat(producto.cantidad)
                : 0;
            total = total + precio * cantidad;
        } else {
            total -= producto.total_importe;
        }
    });
    if (state.usuario.conf_venta_redondeo) {
        const decimal = parseFloat((total % 1).toFixed(2));
        const moneda_mas_pequeno = 0.05;
        const cantidad_monedas = Math.floor(decimal / moneda_mas_pequeno);
        const { redondeo } = state.punto_venta_caja;
        const reciduo = parseFloat(
            (decimal - cantidad_monedas * moneda_mas_pequeno).toFixed(2)
        );
        if (redondeo && reciduo != 0) {
            if (redondeo == "arriba") {
                total =
                    Math.floor(total) +
                    (cantidad_monedas + 1) * moneda_mas_pequeno;
            } else {
                total =
                    Math.floor(total) + cantidad_monedas * moneda_mas_pequeno;
            }
        }
    }

    return {
        ...state.punto_venta_caja,
        ...state.usuario,
        total: parseFloat(total.toFixed(2)),
        pais: state.usuario.me.pais,
        formato_factura: state.usuario.formato_factura,
        factura_personalizada: state.usuario.factura_personalizada,
        utiliza_fel: state.usuario.utiliza_fel,
        conf_venta_redondeo: state.usuario.conf_venta_redondeo,
        conf_ventas_al_credito: state.usuario.conf_ventas_al_credito,
        conf_mostrar_referencia: state.usuario.conf_mostrar_referencia,
        conf_habilitar_campo_cliente: state.usuario.conf_habilitar_campo_cliente,
        buscar_nombre: state.usuario.buscar_nombre,
        print_state: state.print,
    };
};

const md2p = {
    ...actions,
    setNavbarCollapse: (estado) => (dispatch) =>
        dispatch(setNavbarCollapse(estado)),
    pushRouter: (link) => (dispatch) => dispatch(push(link)),
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(CajaVenta);
