import "../orden_venta.css";
import PagoOV from "./PagoOV";
import { icons } from "icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FACTURADO } from "variables";
import React, { Component } from "react";
import ReciboOV from "Utils/ReciboOV";
import OrdenVenta from "Utils/OrdenVenta";
import Modal from "react-responsive-modal";
import ReciboPagoOV from "Utils/ReciboPagoOV";
import LoadMask from "Utils/LoadMask/LoadMask";
import ReciboEntregaOV from "Utils/ReciboEntregaOV";
import { TabProductos, TabPagos, TabEntregas } from "../camposOV";
import FacturaCambiaria from "Utils/FacturaCambiaria";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { envio, factura, credito, cotizacion } from "utility/constantes";
import { PrintContainer, PrintComponent } from "Utils/Print";
import {
    RenderDateTime,
    RenderDate,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

export default class DetalleOrdenVenta extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
        };
    }

    componentWillMount() {
        const id_ov = this.props.match.params.id;
        this.props.getDetalleOV(id_ov);
    }

    cambioTab = (tabVal) => {
        this.setState({ tab: tabVal });
        if (tabVal == 1) {
            this.props.listarCuentaOV(1);
        }
    };

    openModalPago = () => {
        this.props.setModalPagoOV(true);
    };
    closeModalPago = () => {
        this.props.setModalPagoOV(false);
    };

    render() {
        //  State
        const {
            loader_ov,
            orden_venta,
            utiliza_fel,
            print_state,
            detalles_ov,
            modal_pago,
            data_cuenta_ov,
            page_cuenta_ov,
            loader_cuenta_ov,
            datos_pago,
            movimiento_entrega,
        } = this.props;
        //  Bind
        const {
            print,
            retimbradoFEL,
            listarCuentaOV,
            anularPartida,
            getDatosPago,
            seleccionImpresionEntrega,
            //ReImpresion
            seleccionImpresionOFEL,
        } = this.props;

        //  Constants
        const datos_fel = orden_venta ? orden_venta.datos_fel : null;

        const timbrado = datos_fel
            ? datos_fel.estado_factura == FACTURADO
                ? true
                : false
            : false;
        const datos_anulacion = datos_fel
            ? datos_fel.datos_anulacion && datos_fel.respuesta_anulacion
                ? true
                : false
            : false;
        return (
            <div className="row">
                <div className="col-12">
                    <LoadMask loading={loader_ov} light blur radius>
                        <div
                            id="DETALLE-OV"
                            className="grid-container padding-15"
                        >
                            {/* ENCABEZADO */}
                            <div className="encabezado">
                                {/* <div className="flex1">
                                    {orden_venta && detalles_ov && (
                                        <button
                                            className="btn btn-primary mb-2 mr-3"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                print("impresionOVDetalleOV");
                                            }}
                                        >
                                            Reimprimir OV
                                        </button>
                                    )}
                                </div> */}
                                <div className="flex3 d-flex align-items-start justify-content-between">
                                    <div className="item">
                                        <b>Codigo</b>
                                        <p className="m-0">
                                            {orden_venta
                                                ? orden_venta.codigo
                                                : "-----"}
                                        </p>
                                    </div>
                                    <div className="item">
                                        <b>Total</b>
                                        <p className="m-0">
                                            <RenderCurrency
                                                value={
                                                    orden_venta
                                                        ? orden_venta.total
                                                        : 0
                                                }
                                            />
                                        </p>
                                    </div>
                                    {orden_venta &&
                                        orden_venta.tipo_venta == credito && (
                                            <React.Fragment>
                                                <div className="item">
                                                    <b>Pagado</b>
                                                    <p className="m-0">
                                                        <RenderCurrency
                                                            value={
                                                                orden_venta
                                                                    ? orden_venta.total_abono
                                                                    : 0
                                                            }
                                                        />
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <b>Saldo</b>
                                                    <p className="m-0">
                                                        <RenderCurrency
                                                            value={
                                                                orden_venta
                                                                    ? orden_venta.saldo
                                                                    : 0
                                                            }
                                                        />
                                                    </p>
                                                </div>
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>

                            {/* INFO */}
                            {orden_venta && (
                                <div className="info-ov">
                                    <div className="info-ov-item mt-3 mb-3">
                                        <div className="title">VENTA:</div>
                                        <div className="info-ov-item-body">
                                            <div className="item">
                                                <b>Tipo de venta</b>
                                                <p>
                                                    {orden_venta.tipo_venta ==
                                                    envio ? (
                                                        <span className="font-weight-bold text-success">
                                                            ENVIO
                                                        </span>
                                                    ) : (
                                                        <span className="font-weight-bold text-success">
                                                            {orden_venta.tipo_venta ==
                                                            credito
                                                                ? "CREDITO"
                                                                : orden_venta.tipo_venta ==
                                                                  factura
                                                                ? "DOCUMENTO TRIBUTARIO ELECTRÓNICO - FEL"
                                                                : "---"}
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                            {!orden_venta.venta_costo && (
                                                <div className="item">
                                                    <b>Descuento</b>
                                                    {orden_venta.descuento_venta ? (
                                                        <p className="font-weight-bold text-danger">
                                                            Si
                                                        </p>
                                                    ) : (
                                                        <p className="font-weight-bold text-success">
                                                            No
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                            {orden_venta.porcentaje_descuento ? (
                                                <div className="item">
                                                    <b>
                                                        Porcentaje de descuento
                                                    </b>
                                                    <p>
                                                        {
                                                            orden_venta.porcentaje_descuento
                                                        }{" "}
                                                        %
                                                    </p>
                                                </div>
                                            ) : (
                                                orden_venta.venta_costo && (
                                                    <div className="item">
                                                        <b>Venta al costo</b>
                                                        <p className="font-weight-bold text-danger">
                                                            Si
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                            {orden_venta.venta_costo && (
                                                <div className="item"></div>
                                            )}
                                            <div className="item d-none d-md-block d-lg-block"></div>
                                        </div>
                                    </div>
                                    {/* DATOS GENERALES */}
                                    <div className="info-ov-item">
                                        <div className="title">
                                            DATOS GENERALES:
                                        </div>
                                        <div className="info-ov-item-body">
                                            <div className="item">
                                                <b>Fecha</b>
                                                <p>
                                                    <RenderDate
                                                        value={
                                                            orden_venta.creado
                                                        }
                                                    />
                                                </p>
                                            </div>
                                            <div className="item">
                                                <b>Cliente</b>
                                                <p>
                                                    {orden_venta.cliente_proveedor
                                                        ? orden_venta
                                                              .cliente_proveedor
                                                              .nombre
                                                        : "-----"}
                                                </p>
                                            </div>
                                            <div className="item">
                                                <b>Emitida por</b>
                                                <p>
                                                    {orden_venta.usuario
                                                        .first_name
                                                        ? orden_venta.usuario
                                                              .first_name
                                                        : "-----"}
                                                </p>
                                            </div>
                                            <div className="item d-none d-md-block d-lg-block"></div>
                                        </div>
                                    </div>
                                    {/* ENTREGA */}
                                    {/*<div className="info-ov-item flex2">
                                        <div className="title">ENTREGA:</div>
                                        <div className="info-ov-item-body">
                                            <div className="flex1 d-flex flex-column">
                                                <div className="d-flex justify-content-between">
                                                    <div className="item">
                                                        <b>Fecha</b>
                                                        <p>
                                                            <RenderDate
                                                                value={
                                                                    orden_venta.fecha_entrega
                                                                }
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <b>Encargado</b>
                                                        <p>
                                                            {
                                                                orden_venta.encargado
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <b>Tipo entrega</b>
                                                        <p>
                                                            {
                                                                orden_venta.nombre_tipo_entrega
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="item d-none d-md-block d-lg-block"></div>
                                                </div>
                                                {orden_venta.tipo_entrega !=
                                                    10000 && (
                                                    <div className="d-flex justify-content-between">
                                                        <div className="item">
                                                            <b>Sucursal</b>
                                                            <p>
                                                                {
                                                                    orden_venta.nombre_sucursal
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="item">
                                                            <b>Dirección:</b>
                                                            <p>
                                                                {
                                                                    orden_venta.direccion_sucursal
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="item">
                                                            <b>Departamento:</b>
                                                            <p>
                                                                {
                                                                    orden_venta.departamento_sucursal
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="item">
                                                            <b>Municipio:</b>
                                                            <p>
                                                                {
                                                                    orden_venta.municipio_sucursal
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>*/}
                                    {/* PAGO */}
                                    {orden_venta.tipo_venta == credito && (
                                        <div className="info-ov-item">
                                            <div className="title">PAGO:</div>
                                            <div className="info-ov-item-body">
                                                <div className="item">
                                                    <b>Fecha límite</b>
                                                    <p>
                                                        {orden_venta.fecha_fin_pago}
                                                   
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <b>Plazo</b>
                                                    {orden_venta.periodo_pago ==
                                                    0 ? (
                                                        <p>Inmediato</p>
                                                    ) : (
                                                        <p>
                                                            {
                                                                orden_venta.periodo_pago
                                                            }{" "}
                                                            Días
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="item d-none d-md-block d-lg-block"></div>
                                                <div className="item d-none d-md-block d-lg-block"></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex">
                                        <div className="info-ov-item">
                                            <div
                                                className={`title ${
                                                    utiliza_fel
                                                        ? "title-h-45 d-flex align-items-end"
                                                        : ""
                                                }`}
                                            >
                                                DESCRIPCIÓN:
                                            </div>
                                            <div className="info-ov-item-body">
                                                <div className="item">
                                                    <p>
                                                        {orden_venta.descripcion ||
                                                            "-----"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {utiliza_fel && (
                                            <div className="info-ov-item">
                                                <div className="title title-h-45 d-flex align-items-end justify-content-between">
                                                    Factura FEL:
                                                    {!timbrado &&
                                                        !datos_anulacion && (
                                                            <button
                                                                className="btn btn-tertiary mb-2 mr-3"
                                                                disabled={
                                                                    orden_venta &&
                                                                    !orden_venta.activo
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    retimbradoFEL(
                                                                        orden_venta.id
                                                                    );
                                                                }}
                                                            >
                                                                Retimbrar
                                                            </button>
                                                        )}
                                                    {datos_fel &&
                                                        datos_fel.estado_factura ==
                                                            FACTURADO && (
                                                            <button
                                                                className="btn btn-secondary mb-2 mr-3"
                                                                disabled={
                                                                    orden_venta &&
                                                                    !orden_venta.activo
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    print(
                                                                        "facturaFelDetalleOV"
                                                                    );
                                                                }}
                                                            >
                                                                Reimprimir
                                                            </button>
                                                        )}
                                                </div>

                                                <div className="info-ov-item-body">
                                                    <div className="d-flex flex-column">
                                                        {datos_fel && (
                                                            <div className="item d-flex">
                                                                <b className="mr-2">
                                                                    Fecha
                                                                    certificación:
                                                                </b>
                                                                <p>
                                                                    <RenderDateTime
                                                                        value={
                                                                            datos_fel.fechaCertificacion
                                                                        }
                                                                    />
                                                                </p>
                                                            </div>
                                                        )}
                                                        <div className="item d-flex">
                                                            <b className="mr-2">
                                                                Nombre:
                                                            </b>
                                                            <p>
                                                                {orden_venta.nombre ||
                                                                    "Consumidor Final"}
                                                            </p>
                                                        </div>
                                                        <div className="item d-flex">
                                                            <b className="mr-2">
                                                                Nit:
                                                            </b>
                                                            <p>
                                                                {orden_venta.nit ||
                                                                    "CF"}
                                                            </p>
                                                        </div>
                                                        <div className="item d-flex">
                                                            <b className="mr-2">
                                                                Dirección:
                                                            </b>
                                                            <p>
                                                                {orden_venta.direccion ||
                                                                    "Ciudad"}
                                                            </p>
                                                        </div>
                                                        {datos_fel && (
                                                            <div className="item d-flex">
                                                                <b className="mr-2">
                                                                    Número:
                                                                </b>
                                                                <p>
                                                                    {
                                                                        datos_fel.numero
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {datos_fel && (
                                                            <div className="item d-flex">
                                                                <b className="mr-2">
                                                                    Serie:
                                                                </b>
                                                                <p>
                                                                    {
                                                                        datos_fel.serie
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                        {datos_fel && (
                                                            <div className="item d-flex">
                                                                <b className="mr-2">
                                                                    No.
                                                                    Autorización:
                                                                </b>
                                                                <p>
                                                                    {
                                                                        datos_fel.uuid
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            )}
                            {/* TABS */}
                            {orden_venta && (
                                <div className="mt-3 row margin-x-15-neg">
                                    <Tabs
                                        className="content-tabs"
                                        selectedIndex={this.state.tab}
                                        onSelect={(tabIndex) =>
                                            this.cambioTab(tabIndex)
                                        }
                                    >
                                        <TabList className="tab_list d-flex flex-row align-items-center">
                                            <Tab
                                                className="content-tab-venta py-2 px-3 text-center"
                                                selectedClassName="content-tab-venta-active"
                                            >
                                                PRODUCTOS
                                            </Tab>
                                            {/* {orden_venta.tipo_venta ==
                                                credito && (
                                                <Tab
                                                    className="content-tab-venta py-2 px-3 text-center"
                                                    selectedClassName="content-tab-venta-active"
                                                >
                                                    PAGOS
                                                </Tab>
                                            )} */}
                                            {/*<Tab
                                                className="content-tab-venta py-2 px-3 text-center"
                                                selectedClassName="content-tab-venta-active"
                                            >
                                                ENTREGAS
                                            </Tab>*/}

                                            {/* <button className="mx-3 d-none d-lg-block btn btn-secondary btn-finalizar" disabled={orden_compra.estado == 20} onClick={() => finalizarOC(orden_compra.id)}>FINALIZAR</button> */}
                                        </TabList>
                                        {/* TAB DE LISTADO DE PRODUCTOS */}
                                        <TabPanel>
                                            <TabProductos
                                                orden_venta={orden_venta}
                                                loader={loader_ov}
                                            />
                                        </TabPanel>

                                        {/* TAB DE LISTADO DE ABONOS A LA CUENTA POR PAGAR */}
                                        {/* {orden_venta.tipo_venta == credito && (
                                            <TabPanel>
                                                <TabPagos
                                                    data={data_cuenta_ov}
                                                    loader={loader_cuenta_ov}
                                                    page={page_cuenta_ov}
                                                    list={listarCuentaOV}
                                                    activo={
                                                        orden_venta
                                                            ? orden_venta.activo
                                                            : false
                                                    }
                                                    saldo={
                                                        orden_venta
                                                            ? orden_venta.saldo_cuenta
                                                            : 0
                                                    }
                                                    openModalPago={
                                                        this.openModalPago
                                                    }
                                                    anularPartida={
                                                        anularPartida
                                                    }
                                                    getDatosPago={getDatosPago}
                                                />
                                            </TabPanel>
                                        )} */}

                                        {/*<TabPanel>
                                            <TabEntregas
                                                orden_venta={orden_venta}
                                                loader={loader_ov}
                                                seleccionImpresionEntrega={
                                                    seleccionImpresionEntrega
                                                }
                                            />
                                            </TabPanel>*/}
                                    </Tabs>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-center flex-row footer-container">
                            <Link
                                className="btn btn-primary m-1 align-self-center"
                                to="/ordenes_de_venta/"
                            >
                                <img src={icons.cancelar} alt="" /> Regresar
                            </Link>
                        </div>
                    </LoadMask>
                </div>
                {print_state.flag && <PrintComponent />}
                {/* FACTURA CAMBIARIA */}
                {/* {utiliza_fel && (
                    <div className="w100 print-only">
                        <PrintContainer name="facturaFelDetalleOV">
                            {orden_venta && (
                                <FacturaCambiaria orden_venta={orden_venta} />
                            )}
                        </PrintContainer>
                    </div>
                )} */}

                {/* HOJA OV */}
                {orden_venta && detalles_ov && (
                    <div className="w100 print-only">
                        <PrintContainer
                            name="impresionOVDetalleOV"
                            className="print-space"
                        >
                            {/*<OrdenVenta
                                orden_venta={orden_venta}
                                detalles_ov={detalles_ov}
                />*/}{" "}
                            <ReciboOV
                                sucursal={orden_venta.sucursal}
                                movimiento={orden_venta}
                                usuario={
                                    orden_venta.usuario.first_name
                                        ? orden_venta.usuario.first_name
                                        : ""
                                }
                                footerText={"TOTAL"}
                                tipo={"Venta"}
                                utiliza_fel={false}
                            />
                        </PrintContainer>
                    </div>
                )}

                {/* MODAL PAGO OV */}
                <Modal
                    open={modal_pago}
                    onClose={this.closeModalPago}
                    classNames={{ modal: "modal-sm" }}
                >
                    <div style={{ padding: "1.2rem", maxWidth: "550px" }}>
                        <div className="row">
                            <div className="col-12 grid-titulo-ov pt-0">
                                Agregar pago
                            </div>
                            <PagoOV styles={{ modal: { minWidth: "100%" } }} />
                        </div>
                    </div>
                </Modal>

                {/* RECIBO VENTA CREDITO */}
                <div className="w100 print-only">
                    <PrintContainer
                        name="detalleOVReimprescionReciboPagoOV"
                        className="impresion-venta d-table w-100"
                    >
                        {datos_pago && (
                            <ReciboPagoOV
                                movimiento={datos_pago}
                                utiliza_fel={false}
                            />
                        )}
                    </PrintContainer>
                </div>
                {/* COMPROBANTE ENTREGA */}
                <div className="w100 print-only">
                    <PrintContainer
                        name="detalleOVReimprecionComprobanteEntrega"
                        className="impresion-venta d-table w-100"
                    >
                        {movimiento_entrega && (
                            <ReciboEntregaOV
                                movimiento={movimiento_entrega}
                                utiliza_fel={false}
                            />
                        )}
                    </PrintContainer>
                </div>
            </div>
        );
    }
}
