import React, { useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { activeFormatter } from "../../Utils/Acciones/Acciones";

const datos_tabla = {};
const ContactosTabla = ({ proveedores, setProveedores }) => {
    // Datos del contexto de proveedores

    const { contactos, id_contactos_eliminar, nuevos_contactos } = proveedores;

    // datos de la tabla, agreguarle el valor results, y asignarle el array contactos
    datos_tabla.results = contactos;

    // Funcion para eliminar un contacto de la tabla
    const eliminarContacto = (id) => {
        const nueva_lista = contactos.filter((contacto) => contacto.id !== id);
        // Si el id no es un string (id de un contacto de la bd)
        if (typeof id !== "string") {
            setProveedores({
                ...proveedores,
                contactos: nueva_lista,
                id_contactos_eliminar: [...id_contactos_eliminar, id],
            });
        } else {
            // contacto local (no guardado en la db)
            const nueva_lista_nuevos_contactos = nuevos_contactos.filter(
                (contacto) => contacto.id !== id
            );
            setProveedores({
                ...proveedores,
                contactos: nueva_lista,
                nuevos_contactos: nueva_lista_nuevos_contactos,
            });
        }
    };

    const rowEmpty = (row) => (row ? row : "---");

    useEffect(() => {
        // setProveedores({ contactos, id_contactos_eliminar: [], nuevos_contactos: [] });
        document
            .querySelector(".react-bs-container-header")
            .classList.add("border-top-blue", "pt-4");

        document
            .querySelector(".react-bs-table-container")
            .classList.add("border-bootom-blue", "pb-4");
    }, []);
    return (
        <React.Fragment>
            <h3 className="contenido-1 txt-bold txt-blue mb-2">
                Listado de contactos
            </h3>

            <BootstrapTable
                data={datos_tabla.results}
                remote
                hover
                trStyle={(row, rowIdx) => {
                    return {
                        backgroundColor:
                            rowIdx % 2 === 0 ? "#FFFFFF" : "#F8F8F8",
                    };
                }}
            >
                <TableHeaderColumn dataField="nombre" dataSort width="10rem">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="puesto"
                    dataFormat={rowEmpty}
                    dataSort
                    width="10rem"
                >
                    Puesto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="telefono"
                    dataFormat={rowEmpty}
                    dataSort
                    width="8rem"
                >
                    Teléfono
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="correo"
                    dataFormat={rowEmpty}
                    dataSort
                    width="10rem"
                >
                    Correo electrónico
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    width="7rem"
                    isKey
                    dataFormat={(cell, row) => {
                        return activeFormatter({
                            eliminar: (id) => eliminarContacto(id),
                        })(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            </BootstrapTable>
        </React.Fragment>
    );
};

export default ContactosTabla;
