import React from "react";
import Form from "../Crear/ProveedoresCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import { withRouter } from "react-router-dom";
import useCrearEditarProveedor from "../../../hooks/useCrearEditarProveedor";

const ProveedorEditar = ({
    leer,
    editar,
    loader,
    match,
    item,
    location,
    contactos,
    setProveedores,
}) => {
    const {
        intitalData,
        files,
        oldFiles,
        onChangeFile,
        onRemoveOldFile,
        onRemoveFile,
        onSubmit,
    } = useCrearEditarProveedor({ match, item, location, contactos, leer, editar, setProveedores });

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <LoadMask loading={loader} light blur radius>
                    <div className=" padding-15">
                        <Form
                            onSubmit={onSubmit}
                            initialValues={intitalData}
                            files={files}
                            onChangeFile={onChangeFile}
                            onRemoveFile={onRemoveFile}
                            onRemoveOldFile={onRemoveOldFile}
                            oldFiles={oldFiles}
                        />
                    </div>
                </LoadMask>
            </div>
        </div>
    );
};

export default withRouter(ProveedorEditar);
