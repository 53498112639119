import React, { useEffect, useContext } from "react";

import { standardActions } from "../../Utils/Grid/StandardActions";
import Grid from "Utils/Grid";
//import { DireccionesContext } from "../../../../../context/DireccionesContext";

import {
    departamentosOptions,
    municipiosOptions,
} from "../../../../utility/constantes";

import { DEPARTAMENTOS, MUNICIPIOS } from "Utils/departamentos_municipios";

const datos_tabla = {};
const DireccionesTabla = ({ direcciones, setDirecciones }) => {
    // Datos del contexto de direcciones
    //const { direcciones, setDirecciones } = useContext(DireccionesContext);
    const { contactos, id_contactos_eliminar, nuevos_contactos } = direcciones;

    // datos de la tabla, agreguarle el valor results, y asignarle el array contactos
    datos_tabla.results = contactos;

    // Funcion para eliminar un contacto de la tabla
    const eliminarContacto = (id) => {
        const nueva_lista = contactos.filter((contacto) => contacto.id !== id);

        // Si el id no es un string (id de un contacto de la bd)
        if (typeof id !== "string") {
            setDirecciones({
                ...direcciones,
                contactos: nueva_lista,
                id_contactos_eliminar: [...id_contactos_eliminar, id],
            });
        } else {
            // contacto local (no guardado en la db)
            const nueva_lista_nuevos_contactos = nuevos_contactos.filter(
                (contacto) => contacto.id !== id
            );
            setDirecciones({
                ...direcciones,
                contactos: nueva_lista,
                nuevos_contactos: nueva_lista_nuevos_contactos,
            });
        }
    };

    useEffect(() => {
        document
            .querySelector(".react-bs-container-header")
            .classList.add("border-top-blue", "pt-4");

        document
            .querySelector(".react-bs-table-container")
            .classList.add("border-bootom-blue", "pb-4");

        document.querySelector(".react-bs-table-pagination").style.display =
            "none";
    }, []);

    return (
        <React.Fragment>
            <h3 className="contenido-1 txt-bold txt-blue mb-2">
                Listado de direcciones
            </h3>

            <Grid
                hover
                striped
                data={datos_tabla}
                // loading={loader}
                // onPageChange={listar}
                // onSortChange={onSortChange}
            >
                <TableHeaderColumn dataField="direccion" dataSort width="10rem">
                    Direccion
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="direccion_exacta"
                    dataSort
                    width="10rem"
                >
                    Direccion exacta
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="departamento"
                    dataSort
                    width="10rem"
                    dataFormat={(data) => {
                        if (data != null)
                            return (
                                <div className="">
                                    {
                                        DEPARTAMENTOS.find(
                                            (dep) => dep.id === data
                                        ).label
                                    }
                                </div>
                            );
                        else return <div className="">data</div>;
                    }}
                >
                    Departamento
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="municipio"
                    dataSort
                    width="10rem"
                    dataFormat={(data, all) => {
                        if (data != null)
                            return (
                                <div className="">
                                    {
                                        MUNICIPIOS.find(
                                            (mun) => mun.id === data
                                        ).label
                                    }
                                </div>
                            );
                        else return <div className="">data</div>;
                    }}
                >
                    Municipio
                </TableHeaderColumn>
                <TableHeaderColumn dataField="telefono" dataSort width="8rem">
                    Teléfono
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    width="7rem"
                    isKey
                    dataFormat={standardActions({
                        eliminar: (id) => eliminarContacto(id),
                    })}
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default DireccionesTabla;
