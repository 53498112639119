import PropTypes from "prop-types";
import Barcode from "react-barcode";
import React, { Component } from "react";
import {
    RenderDateTime,
    RenderDate,
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";

import "./ticket.css";

export default class TicketCotizacion extends Component {
    static propTypes = {
        cotizacion: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const { cotizacion } = this.props;
        const movimiento = cotizacion ? cotizacion.movimiento : null;
        const sucursal = movimiento.sucursal;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const usuario = movimiento.usuario ? movimiento.usuario.first_name : "";

        const tiene_descuento = parseFloat(cotizacion.descuento) > 0;
        const porcentaje_descuento = tiene_descuento
            ? parseFloat(cotizacion.descuento) / 100
            : 0;
        const es_venta_al_costo = cotizacion.venta_al_costo;

        const codigo_cotizacion =
            cotizacion && cotizacion.codigo ? cotizacion.codigo : "---";

        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    <div className="w-100 text-center">
                        <div className="w-100 text-center">
                            <img
                                src={require("../../../../../assets/img/remocar-logo.png")}
                                width="25%"
                            />
                        </div>
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {datos_empresa.nombre}
                            </b>
                        </div>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>

                    {/* <div className="mt-2 font-weight-bold">
                        {movimiento.nombre_emisor}: {movimiento.nit_emisor}
                    </div>

                    <div className="mt-3">
                        {movimiento.direccion_exacta_emisor}
                    </div>
                    <div>{movimiento.direccion_emisor}</div>
                    <div>Tels: 7762-2013 / 7772-2493</div>
                    <div className="mt-2">
                        email:
                        ventas@remocar.com.gt&nbsp;|&nbsp;wwww.remocar.com.gt
                    </div> */}
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}

                    <div className="item bg-transparent">
                        <div className="font-weight-bold">COTIZACION</div>
                    </div>
                    <div className="flex1 d-flex">
                        <b>Correlativo: </b>
                        {codigo_cotizacion}
                    </div>
                    <div className="flex1 d-flex">
                        <b>FECHA Y HORA: </b>
                        <RenderDateTime value={cotizacion.creado} />
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    {/* <div className="flex1 d-flex flex-column">
                        <b>Nombre</b>
                        {cotizacion.nombre ? cotizacion.nombre : "-----"}
                    </div> */}
                    <div className="flex1 d-flex flex-column">
                        <b>NIT</b>
                        {cotizacion.nit ? cotizacion.nit : "-----"}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Cliente</b>
                        {cotizacion.movimiento.cliente_proveedor.nombre}
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    {usuario ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Vendedor</b>
                            {usuario}
                        </div>
                    ) : null}

                    <div className="flex1 d-flex flex-column">
                        <b>Dirección</b>
                        {cotizacion.direccion.direccion}
                    </div>
                </div>
                {/* //** ---------------BODY */}
                <div
                    className={`grid-ticket ${tiene_descuento ? "grid-4" : ""}`}
                >
                    <b className="flex-1 pl-2 pr-2">CÓDIGO</b>
                    <b className="flex-2">ARTÍCULOS</b>
                    {tiene_descuento && <b className="flex-1">DESCUENTO</b>}
                    <b className="flex-2 d-flex justify-content-end">
                        SUB TOTAL
                    </b>

                    {detalles.map((detalle) => {
                        const precio_descuento =
                            detalle.precio -
                            detalle.precio * porcentaje_descuento;
                        return (
                            <React.Fragment key={detalle.id}>
                                {!detalle.es_vale && (
                                    <React.Fragment>
                                        {/* //** -------------------CODIGO */}
                                        <div className="flex-1 d-flex justify-content-start pl-2 pr-2 fnt-11">
                                            {detalle.fraccion.producto
                                                ? `${detalle.fraccion.producto.codigo}`
                                                : ""}
                                        </div>

                                        {/* //** --------------- ARTICULOS */}
                                        <div className="flex-2 d-flex flex-column fnt-11">
                                            <span>{detalle.producto}</span>
                                            <div className="d-flex flex-row">
                                                <RenderCurrency
                                                    value={
                                                        es_venta_al_costo
                                                            ? detalle.costo
                                                            : detalle.precio_original &&
                                                              detalle.desc_porcentaje
                                                            ? detalle.precio_original
                                                            : detalle.precio
                                                    }
                                                    className={"mr-1"}
                                                />
                                                <div className="mr-1">X</div>
                                                {detalle.fraccion.producto
                                                    .a_granel ? (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                        decimalScale={3}
                                                        className={"mr-1"}
                                                    />
                                                ) : (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                        className={"mr-1"}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        {/* //** -------------------DESCUENTO */}
                                        {tiene_descuento && (
                                            <div className="flex-1 fnt-11">
                                                Q.{" "}
                                                {(
                                                    detalle.precio_original *
                                                    porcentaje_descuento
                                                ).toFixed(2)}
                                            </div>
                                        )}

                                        {/* //** -------------------SUB TOTAL */}
                                        <div className="flex-2 d-flex justify-content-end align-items-end fnt-11">
                                            {detalle.precio_original &&
                                            detalle.desc_porcentaje ? (
                                                <RenderCurrency
                                                    value={
                                                        detalle.cantidad *
                                                        (es_venta_al_costo
                                                            ? detalle.costo
                                                            : detalle.precio_original)
                                                        // detalle.precio_original
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            ) : (
                                                <RenderCurrency
                                                    value={
                                                        detalle.cantidad *
                                                        (es_venta_al_costo
                                                            ? detalle.costo
                                                            : tiene_descuento
                                                            ? precio_descuento
                                                            : detalle.precio)
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>

                <div
                    className="d-flex flex-column"
                    style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
                >
                    {tiene_descuento && (
                        <div className="d-flex flex-row justify-content-between fnt-12">
                            <b>DESCUENTO APLICADO</b>
                            <b>{parseInt(cotizacion.descuento)}%</b>
                        </div>
                    )}

                    <div className="d-flex flex-row justify-content-between fnt-12">
                        <b>TOTAL</b>
                        <b>
                            <RenderCurrency
                                value={movimiento.total}
                                className={"ml-auto"}
                            />
                        </b>
                    </div>
                </div>
            </div>
        );
    }
}
