import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { CLIENTE } from "./ventas";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";

const endpoint = "cuenta_pagar";
const endpoint_lista = "cuenta_pagar/reporte_lista";
const endpoint_reporte_recibo = "cuenta_pagar/reporte_recibo";
const endpoint_resumen = "cuenta_pagar/reporte_resumen";

export const DATA = "CUENTA_PAGAR_DATA";
export const SET_DETALLE = "CUENTA_PAGAR_SET_DETALLE";
export const DATA_RESUMEN = "CUENTA_PAGAR_DATA_RESUMEN";
export const LOADER = "CUENTA_PAGAR_LOADER";
export const SORT = "CUENTA_PAGAR_SORT";
export const SET_CLIENTE = "CUENTA_PAGAR_SET_CLIENTE";
export const SET_TIPO = "CUENTA_PAGAR_SET_TIPO";
export const PAGE = "CUENTA_PAGAR_PAGE";
export const FILTRO = "REPORTE_GASTOS_FILTRO";
export const FECHA_INICIAL = "CUENTA_PAGAR_FECHA_INICIAL";
export const FECHA_FINAL = "CUENTA_PAGAR_FECHA_FINAL";
export const ESTADO_DESCARGA = "CUENTA_PAGAR_ESTADO_DESCARGA";

export const TIPO_PROVEEDOR = "CUENTA_PAGAR_TIPO_PROVEEDOR";
export const PROVEEDOR = "CUENTA_PROVEEDOR";
export const TIPO_MOVIMIENTO = "CUENTA_TIPO_MOVIMIENTO";
export const VENDEDOR = "CUENTA_PAGAR_VENDEDOR";

// ------------------------------------
// Actions
// ------------------------------------
export const getDataResumen = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint_resumen}`)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setDataResumen(resp));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const cliente = store.reporte_cuenta_pagar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_proveedor,
            proveedor,
            tipo_movimiento,
            vendedor,
        } = store.reporte_cuenta_pagar;

        const tipo_movimiento_selccionados = tipo_movimiento.map((mov) =>
            parseInt(mov.value)
        );

        const tipo_proveedor_id = tipo_proveedor ? tipo_proveedor.id : "";
        let params = {
            ordering,
            filtro,
            page,
            fecha_inicial: fecha_vencimiento_inicial,
            fecha_final: fecha_vencimiento_final,

            tipo_proveedor_id,
            proveedor,
            tipo_movimiento: tipo_movimiento_selccionados,
            vendedor,
        };
        if (cliente) params.cliente_id = cliente.id;
        api.get(`${endpoint_lista}`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listarReporteRecibo =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const cliente = store.reporte_cuenta_pagar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            proveedor,
            vendedor,
        } = store.reporte_cuenta_pagar;

        let params = {
            ordering,
            filtro,
            page,
            fecha_inicial: fecha_vencimiento_inicial,
            fecha_final: fecha_vencimiento_final,

            proveedor,
            vendedor,
        };
        if (cliente) params.cliente_id = cliente.id;
        api.get(`${endpoint_reporte_recibo}`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            dispatch(push("/reporte_cuenta_por_cobrar"));
        })
        .then((data) => {
            if (data) dispatch(setDetalle(data));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setCliente = (value) => (dispatch) => {
    dispatch(_setCliente(value));
    // dispatch(listar());
};

export const setTipoProveedor = (value) => (dispatch) => {
    dispatch(_setTipoProveedor(value));
    // dispatch(listar());
};

export const setProveedor = (value) => (dispatch) => {
    dispatch(_setProveedor(value));
    // dispatch(listar());
};

export const setTipoMovimiento = (value) => (dispatch) => {
    dispatch(_setTipoMovimiento(value));
    // dispatch(listar());
};

export const setVendedor = (value) => (dispatch) => {
    dispatch(_setVendedor(value));
    // dispatch(listar());
};

export const aplicarFiltros =
    (esReporteRecibo = false) =>
    (dispatch) => {
        if (esReporteRecibo) {
            dispatch(listarReporteRecibo());
        } else {
            dispatch(listar());
        }
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const filtroChange = (tipo) => (dispatch) => {
    dispatch(setFiltro(tipo));
    dispatch(listar());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_cuenta_pagar = store.reporte_cuenta_pagar;
    if (key === "Inicial") {
        const fecha_vencimiento_inicial = new Date(value);
        const fecha_vencimiento_final = new Date(
            reporte_cuenta_pagar.fecha_vencimiento_final
        );
        // if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_vencimiento_inicial = new Date(
            reporte_cuenta_pagar.fecha_vencimiento_inicial
        );
        const fecha_vencimiento_final = new Date(value);
        if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
            dispatch(setFechaFinal(value));
    }
    // dispatch(listar());
};

export const descargarListado =
    (esReporteRecibo = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const cliente = store.reporte_cuenta_pagar.cliente;
        const {
            ordering,
            filtro,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            tipo_proveedor,
            proveedor,
            tipo_movimiento,
            vendedor,
        } = store.reporte_cuenta_pagar;

        const tipo_movimiento_selccionados = tipo_movimiento.map((mov) =>
            parseInt(mov.value)
        );

        const tipo_proveedor_id = tipo_proveedor ? tipo_proveedor.id : "";

        let params = {
            ordering,
            filtro,
            fecha_inicial: fecha_vencimiento_inicial,
            fecha_final: fecha_vencimiento_final,

            tipo_proveedor_id,
            proveedor,
            tipo_movimiento: tipo_movimiento_selccionados,
            vendedor,
        };
        if (cliente) params.cliente_id = cliente.id;
        dispatch(setEstadoDescarga(true));
        api.get(
            `${endpoint}/${
                esReporteRecibo
                    ? "descargar_excel_reporte_recibo"
                    : "descargar_excel_reporte"
            }`,
            params
        )
            .catch((err) => {
                Swal(
                    "¡Error al descargar!",
                    "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                    "error"
                );
                dispatch(setEstadoDescarga(false));
            })
            .then((data) => {
                if (data) {
                    Swal(
                        "¡Descarga en proceso!",
                        "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                        "info"
                    );
                    dispatch(setEstadoDescarga(true));
                    dispatch(esperarDescarga(data.id));
                }
            });
    };

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const limpiarFiltros =
    (enReporteRecibo = false) =>
    (dispatch) => {
        console.log("limpiando", enReporteRecibo);
        // if (enReporteRecibo) {
        //     dispatch(listarReporteRecibo());
        // } else {
        //     dispatch(listar());
        // }
        dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
        dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
        dispatch(setCliente(null));

        dispatch(setDataResumen({}));
        dispatch(setDetalle({}));

        dispatch(setTipoProveedor(""));
        dispatch(setVendedor(""));
        dispatch(setTipoMovimiento([]));
        dispatch(setFiltro(""));
        dispatch(setProveedor(""));
        dispatch(setData({}));
    };
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setDataResumen = (data_resumen) => ({
    type: DATA_RESUMEN,
    data_resumen,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setDetalle = (detalle) => ({
    type: SET_DETALLE,
    detalle,
});

export const setFiltro = (filtro) => ({
    type: FILTRO,
    filtro,
});

export const setFechaInicial = (fecha_vencimiento_inicial) => ({
    type: FECHA_INICIAL,
    fecha_vencimiento_inicial,
});

export const setFechaFinal = (fecha_vencimiento_final) => ({
    type: FECHA_FINAL,
    fecha_vencimiento_final,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setTipoProveedor = (tipo_proveedor) => ({
    type: TIPO_PROVEEDOR,
    tipo_proveedor,
});

export const _setProveedor = (proveedor) => ({
    type: PROVEEDOR,
    proveedor,
});

export const _setTipoMovimiento = (tipo_movimiento) => ({
    type: TIPO_MOVIMIENTO,
    tipo_movimiento,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const actions = {
    listar,
    listarReporteRecibo,
    getDataResumen,
    setCliente,
    sortChange,
    leer,
    filtroChange,
    setFecha,

    setTipoProveedor,
    setProveedor,
    setTipoMovimiento,
    aplicarFiltros,
    setVendedor,

    descargarListado,
    limpiarFiltros,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_RESUMEN]: (state, { data_resumen }) => {
        return {
            ...state,
            data_resumen,
        };
    },
    [SET_DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_vencimiento_inicial }) => {
        return {
            ...state,
            fecha_vencimiento_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_vencimiento_final }) => {
        return {
            ...state,
            fecha_vencimiento_final,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [TIPO_PROVEEDOR]: (state, { tipo_proveedor }) => {
        return {
            ...state,
            tipo_proveedor,
        };
    },
    [PROVEEDOR]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [TIPO_MOVIMIENTO]: (state, { tipo_movimiento }) => {
        return {
            ...state,
            tipo_movimiento,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    data_resumen: {},
    detalle: {},
    cliente: null,
    ordering: "",
    loader: false,
    filtro: "",
    fecha_vencimiento_inicial: moment().format("YYYY-MM-DD"),
    fecha_vencimiento_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,

    tipo_proveedor: "",
    proveedor: "",
    tipo_movimiento: [],
    vendedor: "",
};

export default handleActions(reducers, initialState);
