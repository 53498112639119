import React, { Component } from "react";
import PerfilForm from "./PerfilForm";
import CambioContraForm from "./CambioContraForm";
import { withRouter } from "react-router-dom";

import "./Perfil.css";
import foto_perfil_defecto from "../../../../assets/img/avatar-placeholder.png";

class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editar: false,
            cambio_perfil: false,
            cambio_contrasena: false,
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.editarPerfil = this.editarPerfil.bind(this);
        // Regresar 1 paso en el hostorial de navegación
        this.goBack = this.goBack.bind(this);
    }

    onFileChange(e, file, key) {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            // this.setState({ loaded: false });
            reader.onload = (e) => {
                this.setState({ [key]: file });
            };
            reader.readAsDataURL(file);
        }
    }
    componentWillMount() {
        // this.props.leer(this.props.match.params.id);
    }

    cambioEdicion = () => {
        this.props.editarPerfil();
        this.setState({
            editar: !this.state.editar,
            cambio_perfil: !this.state.cambio_perfil,
        });
    };

    cambioEdicionContra = () => {
        // this.props.editarPerfil();
        this.setState({
            editar: !this.state.editar,
            cambio_contrasena: !this.state.cambio_contrasena,
        });
    };

    editarPerfil() {
        const fotos = [{ name: "foto", file: this.state.foto_perfil }];
        this.props.guardar(fotos, this.cambioEdicion);
    }
    cambioContrasena = () => {
        // console.log("CAMBIO CONTRA")
        this.props.actualizarContrasena(this.cambioEdicionContra);
    };

    // Funcion para regresar 1 paso en el hostorial de navegación
    goBack() {
        this.props.history.goBack();
    }

    render() {
        // State
        const { editar, cambio_contrasena, cambio_perfil } = this.state;
        const { usuario } = this.props;

        // console.log(usuario);
        // Bind
        // const { editarPerfil } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="padding-15">
                        {editar ? (
                            <div className="grid-cuerpo padding-15">
                                {cambio_perfil ? (
                                    <div className="row">
                                        <PerfilForm
                                            usuario={usuario}
                                            onFileChange={this.onFileChange}
                                            editarPerfil={this.editarPerfil}
                                            cambioEdicion={this.cambioEdicion}
                                        />
                                    </div>
                                ) : null}
                                {cambio_contrasena ? (
                                    <CambioContraForm
                                        cambioEdicionContra={
                                            this.cambioEdicionContra
                                        }
                                        cambioContrasena={this.cambioContrasena}
                                    />
                                ) : null}
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="card card-small padding-15 pb-5">
                                    {/* IMAGEN */}
                                    <div className="row">
                                        {/* Columna 1 */}
                                        <div className="col-lg-5 col-md-6 col-12 content-drop-zone">
                                            {usuario.foto_cropped ? (
                                                <img
                                                    className="imagen-perfil"
                                                    src={usuario.foto_cropped}
                                                    width="200"
                                                    height="200"
                                                />
                                            ) : (
                                                <img
                                                    className="imagen-perfil"
                                                    src={foto_perfil_defecto}
                                                    width="200"
                                                    height="200"
                                                />
                                            )}
                                        </div>

                                        {/* Columna 2 */}
                                        <div className="ml-0 ml-lg-4 col-lg-5 col-md-6 col-12">
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Nombre
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.first_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Apellido
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.last_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Correo
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.email}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-text-secondary m-1 align-self-center txt-blue txt-semibold contenido-3 underline"
                                                    onClick={() => {
                                                        this.cambioEdicionContra();
                                                    }}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                    }}
                                                >
                                                    Cambiar contraseña
                                                </button>
                                            </div>
                                        </div>

                                        {/* Columna 1 */}
                                        <div className="ml-0 ml-lg-4 mt-5 col-lg-5 col-md-6 col-12">
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Fecha de nacimiento
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.birthday}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Dirección
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.direccion}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        DPI
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.dpi}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Rol
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.es_admin
                                                            ? "Administrador"
                                                            : usuario.es_bodeguero
                                                            ? "Bodeguero"
                                                            : usuario.es_cajero
                                                            ? "Cajero"
                                                            : usuario.es_vendedor_mostrador
                                                            ? "Vendedor Mostrador"
                                                            : usuario.es_gerente
                                                            ? "Gerente"
                                                            : usuario.es_jefe_bodega
                                                            ? "Jefe de Bodega"
                                                            : usuario.es_encargado_tienda
                                                            ? "Encargado de Tienda"
                                                            : "Sin permisos"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Columna 2 */}
                                        <div className="mt-5 col-lg-5 col-md-6 col-12">
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Depto. de nacimiento
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.departamento}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Número de teléfono
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.telefono}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        NIT
                                                    </label>
                                                    <div className="input-view">
                                                        {usuario.nit}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row row-fields">
                                                <div className="col-12">
                                                    <label className="label-view">
                                                        Viáticos semanales
                                                    </label>
                                                    <div className="input-view">
                                                        Q.{usuario.viaticos}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4 w-100">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary m-1 align-self-center"
                                        onClick={this.goBack}
                                    >
                                        Volver
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary m-1 align-self-center"
                                        onClick={() => {
                                            this.cambioEdicion();
                                        }}
                                    >
                                        <em className="fa fa-pencil mr-2"></em>{" "}
                                        Editar
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Perfil);
