import React, { useState } from "react";
import FileMultiUploader from "./FileUploader/FileMultiUploader";

const MultiUploadFile = ({
    files,
    setFiles,
    oldFiles,
    setOldFiles,
    removeFiles,
    setRemoveFiles,
    data = [],
}) => {
    const onChangeFile = async (file) => {
        let files_data = [...files];

        let file_exist = false;

        /*files.map((item) => {
            if (item.name == file.name) {
                file_exist = true;
            }
        });

        oldFiles.map((item) => {
            if (item.nombre == file.name) {
                file_exist = true;
            }
        });

        if (file_exist) {
            let mensajeError = "Archivo de evidencia ya existente";
            NotificationManager.error(mensajeError, "Error", 7000);
        } else {
            files_data.push({ name: file.name, file: file });
            setFiles(files_data);
        }*/
        files_data.push({ name: file.name, file: file });
        setFiles(files_data);
    };

    const onRemoveOldFile = async (file) => {
        let files_data = [...oldFiles];
        let data_removeFiles = [...removeFiles];
        data_removeFiles.push(file.id);
        //console.log('Se ha eliminado: ', file.id);

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }
        setRemoveFiles(data_removeFiles);
        setOldFiles(files_data);
    };

    const onRemoveFile = async (file) => {
        let files_data = [...files];

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }

        setFiles(files_data);
    };

    return (
        <React.Fragment>
            <FileMultiUploader onChange={onChangeFile} />

            <br />
            {oldFiles && oldFiles.length > 0 && (
                <div className="row">
                    {oldFiles.map((item) => (
                        <div
                            key={item.id}
                            className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g "
                        >
                            <a
                                href={item.documento}
                                download={item.nombre}
                                type="button"
                                className="btn btn-light btn-lg btn-old-files mr-3 mt-3 multi-file-box-a  text-truncate"
                            >
                                {item.nombre}
                            </a>
                            <span className="icon-exito  mt-3 multi-file-box-b">
                                <i
                                    onClick={() => {
                                        onRemoveOldFile(item);
                                    }}
                                    className="fa fa-times mt-3"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <br />
            {files && files.length > 0 && (
                <div className="row">
                    {files.map((item, index) => (
                        <div
                            key={index}
                            className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g"
                        >
                            <button
                                type="button"
                                className="btn btn-success btn-success-upload btn-lg mr-3 mt-3 multi-file-box-a text-truncate"
                            >
                                {item.name}
                            </button>
                            <span className="icon-exito  mt-3 multi-file-box-b ">
                                <i
                                    onClick={() => {
                                        onRemoveFile(item);
                                    }}
                                    className="fa fa-times mt-3"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </div>
                    ))}
                </div>
            )}

            <br />
        </React.Fragment>
    );
};

export default MultiUploadFile;
