import ListadoTransportes from "./ListadoTransportes.jsx";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/transportes/transportes";

const ms2p = (state) => {
    return {
        ...state.transportes,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoTransportes);
