import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { tableOptions } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import {
    departamentosOptions,
    municipiosOptions,
    TIPO_CLIENTE,
} from "../../../../utility/constantes";
import Select, { Async } from "react-select";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { formatoTrueFalse } from "../../Utils/CamposComunes";

const listado_usuarios = [];
const ListadoClientes = (props) => {
    const [municipioOp, setmunicipioOp] = useState(false);

    // state
    const {
        data,
        loader,
        estado_descarga,
        tipo_cliente,
        departamento,
        municipio,
        vendedor,
    } = props;

    // bind
    const {
        eliminar,
        descargarListado,
        filtrarTipoCliente,
        filtrarDepartamento,
        filtrarMunicipio,
        filtrarTipoClienteSinListar,
        filtrarVendedor,
        limpiar,
    } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.listar;
    options.onSortChange = props.sortChange;

    useEffect(() => {
        props.listar(props.page);

        return () => {
            limpiar();
        };
    }, []);


    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        {
            es_vendedor_clientes:true
        }
    );

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mt">
                    {console.log(data)}
                    <Link
                        className="btn btn-primary"
                        to="/configuracion/clientes/crear/"
                    >
                        {" "}
                        <em className="fa fa-plus"></em> Agregar Cliente
                    </Link>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-secondary m-1 align-self-center"
                        disabled={estado_descarga}
                        onClick={() => {
                            descargarListado();
                        }}
                    >
                        <em
                            className={`fa ${
                                estado_descarga
                                    ? "fa-spinner fa-pulse"
                                    : "fa-download"
                            }`}
                        ></em>
                        &nbsp;Descargar Listado
                    </button>
                </div>
                <div className="grid-container">
                    <div className="grid-titulo">
                        <Toolbar
                            titulo={"Clientes"}
                            buscar={props.buscar}
                            search={props.search}
                        />
                    </div>
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                        <div className="flex-3">
                            <label>Tipo Cliente</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={TIPO_CLIENTE}
                                valueKey="label"
                                labelKey="label"
                                value={tipo_cliente}
                                onChange={(e) => {
                                    filtrarTipoCliente(e);
                                }}
                                disabled={
                                    municipioOp !== false &&
                                    municipioOp !== undefined
                                }
                            />
                        </div>

                        <div className="flex-1"></div>
                        <div className="flex-3">
                            <label>Vendedor</label>

                            <Async
                                className="form-control"
                                labelKey="first_name"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getUsuarios}
                                placeholder={"Vendedor"}
                                onChange={(e) => {
                                    filtrarVendedor(e ? e.id : "");
                                }}
                                value={vendedor}
                            />
                        </div>

                        <div className="flex-1"></div>
                        <div className="flex-3">
                            <label>Departamento</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={departamentosOptions}
                                valueKey="label"
                                labelKey="label"
                                value={departamento}
                                onChange={(e) => {
                                    setmunicipioOp(
                                        municipiosOptions[e ? e.id : ""]
                                    );
                                    if (!municipioOp) {
                                        filtrarTipoClienteSinListar({
                                            id: 1,
                                            value: "Mayorista",
                                            label: "Mayorista",
                                        });
                                    }
                                    filtrarDepartamento(e);
                                }}
                            />
                        </div>
                        <div className="flex-1"></div>
                        <div className="flex-3">
                            <label>Municipio</label>

                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={municipioOp}
                                valueKey="label"
                                labelKey="label"
                                value={municipio}
                                onChange={(e) => {
                                    filtrarMunicipio(e);
                                }}
                                disabled={municipioOp === false}
                            />
                        </div>
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                width="5%"
                            >
                                ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nombre" dataSort>
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nit">
                                Nit
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="dias_credito">
                                Dias de credito
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="tipo_cliente"
                                dataSort
                                width="10rem"
                                dataFormat={(data) => {
                                    if (data != null)
                                        return (
                                            <div className="">
                                                {
                                                    TIPO_CLIENTE.find(
                                                        (cli) => cli.id === data
                                                    ).label
                                                }
                                            </div>
                                        );
                                    else return <div className="">data</div>;
                                }}
                            >
                                Tipo Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="creado_por"
                                dataFormat={(cell) => {
                                    if (cell) {
                                        return cell.first_name;
                                    }
                                    return "---";
                                }}
                            >
                                Creado por
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="vendedor"
                                dataFormat={(cell) => {
                                    if (cell) {
                                        return cell.first_name;
                                    }
                                    return "---";
                                }}
                            >
                                Vendedor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tiene_credito"
                                dataFormat={formatoTrueFalse}
                            >
                                Tiene Crédito
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="prefijo_sucursal_credito"
                                dataFormat={(cell, rows) => {
                                    if (cell.length > 0) {
                                        return (
                                            cell.length > 0 &&
                                            cell.map((i) => i.prefijo)
                                        );
                                    }
                                    return "---";
                                }}
                            >
                                sucursal
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                width="20%"
                                dataFormat={(cell, row) => {
                                    if (row.sucursal === null) return "";
                                    return activeFormatter({
                                        editar: "/configuracion/clientes/editar",
                                        eliminar,
                                    })(cell, row);
                                }}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export default ListadoClientes;

ListadoClientes.propTypes = {
    data: PropTypes.object.isRequired,
    loader: PropTypes.bool.isRequired,
    eliminar: PropTypes.func.isRequired,
    buscar: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    listar: PropTypes.func.isRequired,
};
