import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import useCrearEditar from "../../../../hooks/useCrearEditar";
import BancosForm from "./BancosForm";

const CrearActualizar = ({ crear, leer, bancos, editar, loader, match }) => {
    const { intitalData, titulo, verDato, editarDato, onSubmit } =
        useCrearEditar({ crear, leer, item: bancos, editar, match });

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="">
                    <BancosForm
                        intitalData={intitalData}
                        onSubmit={onSubmit}
                        ver={verDato}
                        editar={editarDato}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CrearActualizar;
