import React, { useEffect } from "react";
import { icons } from "icons";

import { envio, factura, credito, cotizacion } from "utility/constantes";

import { BreakLine } from "Utils/tableOptions";
import { accionesReciboFactura } from "../camposOV";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { FACTURADO, PROCESO, ANULADA, ERROR } from "variables";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export const TablaOV = (props) => {
    useEffect(() => {
        return () => {
            props.limpiarOV();
        };
    }, []);

    return (
        <BootstrapTable
            data={props.loader ? [] : props.datos_listado.results}
            remote
            pagination
            hover
            fetchInfo={{ dataTotalSize: props.datos_listado.count }}
            options={props.options}
        >
            <TableHeaderColumn
                dataField="creado"
                dataFormat={(cell) => <RenderDate value={cell} />}
                dataSort
            >
                Fecha
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="codigo"
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell ? (
                                <span className="font-weight-bold">
                                    {cell ? cell : "------------"}
                                </span>
                            ) : (
                                <span className="font-weight-bold ">
                                    {"------------"}
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Codigo
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="tipo_venta"
                dataFormat={(cell) => {
                    return (
                        //envio, factura, credito, cotizacion
                        <div>
                            {cell == envio ? (
                                <span className="font-weight-bold text-success">
                                    ENVIO
                                </span>
                            ) : cell == factura ? (
                                <span className="font-weight-bold text-success">
                                    FACTURA
                                </span>
                            ) : cell == credito ? (
                                <span className="font-weight-bold text-success">
                                    CREDITO
                                </span>
                            ) : cell == cotizacion ? (
                                <span className="font-weight-bold text-success">
                                    COTIZACION
                                </span>
                            ) : (
                                <span className="font-weight-bold text-danger">
                                    "----------"
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Tipo de venta
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="pago_finalizado"
                dataFormat={(cell) => {
                    return (
                        //envio, factura, credito, cotizacion
                        <div>
                            {cell ? (
                                <img
                                    style={{ margin: "0", height: "1.5rem" }}
                                    src={icons.check}
                                    alt="Finalizado"
                                />
                            ) : (
                                <img
                                    style={{ margin: "0", height: "1.5rem" }}
                                    src={icons.reloj}
                                    alt="Pendiente"
                                />
                            )}
                        </div>
                    );
                }}
            >
                Pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="cliente_proveedor"
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell ? (
                                <span className="font-weight-bold">
                                    {cell.nombre ? cell.nombre : "------------"}
                                </span>
                            ) : (
                                <span className="font-weight-bold ">
                                    {"------------"}
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Cliente
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="usuario"
                dataFormat={(cell) => {
                    if (cell) {
                        return cell.first_name;
                    }
                    return "---";
                }}
            >
                Vendedor
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="facturado"
                dataFormat={(cell, row) => {
                    // if (row.tipo_venta != envio && row.tipo_venta != credito) {
                    //     return "---";
                    // }
                    return (
                        <span
                            className="font-weight-bold"
                            style={{ color: cell ? "green" : "red" }}
                        >
                            {cell ? "Si" : "No"}
                        </span>
                    );
                }}
            >
                Facturado
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="total"
                dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                dataAlign="right"
            >
                Total
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="saldo"
                dataFormat={(cell, row) => {
                    if (row.tipo_venta == credito) {
                        return <RenderCurrency value={cell ? cell : 0} />;
                    }
                    return <span>---</span>;
                }}
            >
                Saldo
            </TableHeaderColumn>

            {/* <TableHeaderColumn
                dataField="porcentaje_descuento_nc"
                dataAlign="right"
            >
                Descuento aplicado
            </TableHeaderColumn> */}

            {!props.anulados ? (
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    width="170px"
                    dataFormat={(cell, row) => {
                        return activeFormatter({
                            ver: "/ordenes_de_venta/detalle",
                            // anular: props.anular,
                            adicional: accionesReciboFactura,
                            datos_adicional: {
                                data: row,
                                // utiliza_fel: props.utiliza_fel,
                                factura_personalizada:
                                    props.factura_personalizada,
                                // retimbradoFEL: props.retimbradoFEL,
                                seleccionImpresionOFEL:
                                    props.seleccionImpresionOFEL,
                                setOpen: props.setOpen,
                                open: props.open,
                            },
                            facturar: { row, setModal: props.setModal },
                            // descuento: {
                            //     row,
                            //     setModal: props.setModalDescuento,
                            // },
                        })(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            ) : (
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    dataFormat={(cell, row) => {
                        return activeFormatter({
                            ver: "/ordenes_de_venta/detalle",
                        })(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            )}
        </BootstrapTable>
    );
};
