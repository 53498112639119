import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/bodega/devoluciones";
import { actions as bajasActions } from "../../../../redux/modules/bodega/bajas";
import Bajas from "./Bajas";

const ms2p = (state) => {
    let total = 0;
    state.devoluciones.seleccionados.forEach((producto) => {
        const costo = producto.costo_compra ? producto.costo_compra : 0;
        const cantidad = producto.baja ? parseFloat(producto.baja) : 0;
        total = total + costo * cantidad;
    });
    return {
        ...state.devoluciones,
        sucursal: state.usuario.sucursal,
        //*** Habilitar la opcion ver detalle de la orden de compara en formatoAcciones
        ver_detalle: true,
        total,
    };
};

const md2p = { ...actions, ...bajasActions };

export default connect(ms2p, md2p)(Bajas);
