import _ from "lodash";
import { icons } from "icons";
import React, { Component, Fragment } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { Async } from "react-select";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const listado_usuarios = [];
const listado_clientes = [];
class ListadoOrdenOnline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalListar: null,
        };
    }

    componentDidMount() {
        // this.props.listar(this.props.page);
        // //  Interval para actualizar el listado de ordenes a cada 30 segundos
        // let intervalListar = setInterval(this.listarOrdenes.bind(this), 30000);
        // this.setState({ intervalListar });

        const pedidos_linea_usuario = 1;
        this.props.listar(this.props.page, pedidos_linea_usuario);
    }
    componentWillUnmount() {
        //  Se limpia el interval para que ya no siga actualizando
        clearInterval(this.state.intervalListar);
    }

    listarOrdenes() {
        const pedidos_linea_usuario = 1;
        this.props.listar(this.props.page, pedidos_linea_usuario);
    }

    imageFormatter(cell, row) {
        if (cell === true)
            return <img src={icons.check} style={{ width: "20px" }} />;
        else cell === false;
        return <img src={icons.reloj} style={{ width: "20px" }} />;
    }

    formaPago(cell, row) {
        let forma_pago = row.forma_pago;

        if (forma_pago === "EFE") {
            return <div>Efectivo</div>;
        } else if (forma_pago === "TAR") {
            return <div>Tarjeta</div>;
        } else if (forma_pago === "CHE") {
            return <div>Cheque</div>;
        } else if (forma_pago === "DEP") {
            return <div>Deposito</div>;
        } else if (forma_pago === "TRA") {
            return <div>Transferencia</div>;
        } else if (forma_pago === "TRE") {
            return <div>Transferencia electronica</div>;
        } else if (forma_pago === "PCE") {
            return <div>Pago contra entrega</div>;
        } else if (forma_pago === "PT") {
            return <div>Pago en tienda</div>;
        } else {
            return <div>{forma_pago}</div>;
        }
    }

    render() {
        // state
        const {
            data,
            loader,
            search,
            buscar,
            sucursal,
            estado,
            config,
            fecha_final,
            fecha_inicial,
            es_gerente,
            es_jefe_bodega,
            vendedor,
            cliente,
            tab,
        } = this.props;
        // Bind
        const {
            anular,
            estadoChange,
            setFecha,
            setVendedor,
            setCliente,
            changeTab,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        const { getOptions: getUsuarios } = useAsyncSearch(
            "usuarios",
            listado_usuarios,
            { es_vendedor: 1 }
        );

        const { getOptions: getClientes } = useAsyncSearch(
            "clientes",
            listado_clientes,
            { es_cliente: 1 }
        );

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Ordenes online"}
                                buscar={buscar}
                                search={search}
                            />

                            {/* <div className="padding-10 row">
                                <div className="col-12 fnt-16 text-left d-flex justify-content-between text-left">
                                    <div>
                                        <a
                                            href={`${config.url_tienda}/tienda/${config.slug}`}
                                            target="_blank"
                                            className="btn btn-outline-secondary"
                                        >
                                            Ver tienda
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3 ">
                            <div className="flex-3">
                                <label htmlFor="">Fecha inicial</label>
                                <DatePicker
                                    value={fecha_inicial}
                                    maxDate={
                                        new Date(
                                            moment(
                                                moment().format("YYYY-MM-DD"),
                                                "YYYY-MM-DD"
                                            )
                                        )
                                    }
                                    onChange={(date) => {
                                        setFecha("Inicial", date);
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                <label htmlFor="">Fecha final</label>
                                <DatePicker
                                    value={fecha_final}
                                    minDate={
                                        new Date(
                                            moment(fecha_inicial, "YYYY-MM-DD")
                                        )
                                    }
                                    onChange={(date) => {
                                        setFecha("Final", date);
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                {/* {(es_gerente || es_jefe_bodega) && ( */}
                                <React.Fragment>
                                    <label>Vendedor</label>
                                    <Async
                                        labelKey="first_name"
                                        valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        className="form-control"
                                        loadOptions={getUsuarios}
                                        placeholder={"Vendedor"}
                                        onChange={(e) => {
                                            setVendedor(e ? e.id : "");
                                        }}
                                        value={vendedor}
                                    />
                                </React.Fragment>
                                {/* )} */}
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                {/* {(es_gerente || es_jefe_bodega) && ( */}
                                <React.Fragment>
                                    <label>Cliente</label>
                                    <Async
                                        labelKey="nombre"
                                        valueKey="id"
                                        searchPromptText="Escriba para buscar"
                                        className="form-control"
                                        loadOptions={getClientes}
                                        placeholder={"Cliente"}
                                        onChange={(e) => {
                                            setCliente(e ? e.id : "");
                                        }}
                                        value={cliente}
                                    />
                                </React.Fragment>
                                {/* )} */}
                            </div>
                            <div className="flex-1"></div>
                        </div>

                        <Tabs
                            className="mt-2 content-tabs"
                            selectedIndex={tab == 10 ? 0 : tab == 20 ? 1 : 2}
                            onSelect={(tabIndex) =>
                                changeTab(
                                    tabIndex == 0 ? 10 : tabIndex == 1 ? 20 : 30
                                )
                            }
                        >
                            <TabList className="tab_list d-flex flex-row justify-content-between">
                                <Tab
                                    className="content-tab-venta flex1 text-center fnt-14"
                                    style={{ marginBottom: "0px" }}
                                    selectedClassName="content-tab-venta-active"
                                >
                                    Pendientes
                                </Tab>

                                <Tab
                                    className="content-tab-venta flex1 text-center fnt-14"
                                    style={{ marginBottom: "0px" }}
                                    selectedClassName="content-tab-venta-active"
                                >
                                    Historial
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="cliente_proveedor"
                                            dataFormat={(cell) => (
                                                <div>
                                                    {cell ? cell.nombre : "---"}
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Cliente
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="cantidad_productos"
                                            dataSort
                                            dataAlign="center"
                                        >
                                            cantidad
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="codigo_orden"
                                            dataSort
                                            dataAlign="center"
                                        >
                                            Código de orden
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="sucursal__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    {row.sucursal ? (
                                                        <span>
                                                            {
                                                                row.sucursal
                                                                    .nombre
                                                            }
                                                        </span>
                                                    ) : (
                                                        <span className="naranja font-weight-bold">
                                                            Sin asignar
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Surcursal destino
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell, row) => {
                                                const to_decimal =
                                                    cell.toFixed(2);
                                                return `Q ${to_decimal}`;
                                            }}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Total
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="creado"
                                            dataFormat={(cell) => (
                                                <RenderDate value={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha realizada
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="vendedor_asignado"
                                            dataSort
                                        >
                                            Vendedor asignado
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            dataAlign="center"
                                            width={"120px"}
                                            dataFormat={(cell, row) => {
                                                // if (estado) {
                                                //     return activeFormatter({
                                                //         ver: "/pedidos_en_linea/detalle",
                                                //         editar: "/pedidos_en_linea/editar",
                                                //         editar_activo:
                                                //             row.enviada == true ||
                                                //             row.pagada == true ||
                                                //             row.anulado == true ||
                                                //             row.sucursal == null
                                                //                 ? false
                                                //                 : true,
                                                //         anular,
                                                //         anular_activo:
                                                //             row.enviada == true ||
                                                //             row.pagada == true ||
                                                //             row.anulado == true ||
                                                //             row.sucursal == null
                                                //                 ? false
                                                //                 : true,
                                                //     })(cell, row);
                                                // } else {
                                                //     return activeFormatter({
                                                //         ver: "/pedidos_en_linea/detalle",
                                                //     })(cell, row);
                                                // }
                                                return activeFormatter({
                                                    ver: "/pedidos_en_linea/detalle",
                                                })(cell, row);
                                            }}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </TabPanel>

                            <TabPanel>
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="cliente_proveedor"
                                            dataFormat={(cell) => (
                                                <div>
                                                    {cell ? cell.nombre : "---"}
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Cliente
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="cantidad_productos"
                                            dataSort
                                            dataAlign="center"
                                        >
                                            cantidad
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="codigo_orden"
                                            dataSort
                                            dataAlign="center"
                                        >
                                            Código de orden
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="sucursal__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    {row.sucursal ? (
                                                        <span>
                                                            {
                                                                row.sucursal
                                                                    .nombre
                                                            }
                                                        </span>
                                                    ) : (
                                                        <span className="naranja font-weight-bold">
                                                            Sin asignar
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Surcursal destino
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={(cell, row) => {
                                                const to_decimal =
                                                    cell.toFixed(2);
                                                return `Q ${to_decimal}`;
                                            }}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Total
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="creado"
                                            dataFormat={(cell) => (
                                                <RenderDate value={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha realizada
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="vendedor_asignado"
                                            dataSort
                                        >
                                            Vendedor asignado
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            dataAlign="center"
                                            width={"120px"}
                                            dataFormat={(cell, row) => {
                                                return activeFormatter({
                                                    ver: "/pedidos_en_linea/detalle",
                                                })(cell, row);
                                            }}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoOrdenOnline;
