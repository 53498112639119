import React, { useState } from "react";

import { Form, Field } from "react-final-form";

import {
    required,
    composeValidators,
    maxValue,
    minValue,
} from "utility/validation";
import { renderNumberFormat } from "Utils/renderField/renderField";

const FormDescuentos = ({
    total_venta,
    estado_fel,
    onSubmit,
    onCloseModal,
}) => {
    const [descuento, setDescuento] = useState(0);
    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="form-validate mb-lg">
                    <div className="">
                        <div className="form-group has-feedback m-2 flex-3">
                            <label htmlFor="descuento">Descuento</label>
                            <Field
                                name="descuento"
                                component={renderNumberFormat}
                                placeholder="%"
                                type="text"
                                suffix={"%"}
                                className="form-control"
                                validate={composeValidators(
                                    minValue(1),
                                    maxValue(100),
                                    required
                                )}
                                parse={(value) => {
                                    if (value && value >= 1 && value <= 100) {
                                        setDescuento(value);
                                    } else {
                                        setDescuento(0);
                                    }
                                    return value;
                                }}
                            />
                            <small className="text-secondary mt-2">
                                Descuento aplicado: Q.
                                {((descuento / 100) * total_venta).toFixed(2)}
                            </small>
                        </div>

                        <div className="d-flex px-0 px-md-4 mt-5">
                            <button
                                className="btn btn-outline-primary mr-3"
                                style={{ padding: ".6rem 3rem" }}
                                onClick={() => {
                                    onCloseModal();
                                }}
                                type="button"
                            >
                                Cancelar
                            </button>

                            <button
                                className="btn btn-primary px-5"
                                style={{ padding: ".6rem 2rem" }}
                                disabled={estado_fel}
                                type="submit"
                            >
                                <em
                                    className={`fa ${
                                        estado_fel ? "fa-spinner fa-pulse" : ""
                                    }`}
                                />
                                &nbsp;Continuar
                            </button>
                        </div>
                    </div>
                </form>
            )}
        />
    );
};

export default FormDescuentos;
