import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { RenderCurrency } from "Utils/renderField/renderReadField";

const Card = ({
    loader,
    estadisticas,
    creditosVigentes = false,
    creditosVencer = false,
    creditosVencidos = false,
    mt = false,
}) => {
    const titulo_card = creditosVigentes
        ? "Total créditos vigentes"
        : creditosVencer
        ? "Total créditos por vencer"
        : creditosVencidos
        ? "Total créditos vencidos"
        : "";

    const {
        total_creditos,
        total_creditos_count,

        total_creditos_por_vencer,
        total_creditos_por_vencer_count,

        total_creditos_vencidos,
        total_creditos_vencidos_count,
    } = estadisticas;

    const creditos_count_card = creditosVigentes
        ? total_creditos_count
        : creditosVencer
        ? total_creditos_por_vencer_count
        : creditosVencidos
        ? total_creditos_vencidos_count
        : 0;

    const total_creditos_card = creditosVigentes
        ? total_creditos
        : creditosVencer
        ? total_creditos_por_vencer
        : creditosVencidos
        ? total_creditos_vencidos
        : 0;

    const texto_creditos =
        creditos_count_card > 1 || creditos_count_card == 0
            ? " créditos"
            : " crédito";

    return (
        <div className={`item-total-creditos ${mt ? "mt-3" : ""}`}>
            <LoadMask loading={loader} light blur>
                <div className="w-100 d-flex flex-row aling-items-center justify-content-center">
                    <div
                        className={`item-texto d-flex align-items-center justify-content-center ${
                            creditosVigentes
                                ? "card-green"
                                : creditosVencer
                                ? "card-yellow"
                                : creditosVencidos
                                ? "card-red"
                                : ""
                        }`}
                    >
                        <b>{titulo_card}</b>
                    </div>
                    <div className="item-total d-flex flex-column align-items-center justify-content-center">
                        <div className="item-total-cantidad">
                            {creditos_count_card} {texto_creditos}
                        </div>
                        <b>
                            <RenderCurrency value={total_creditos_card} />
                        </b>
                    </div>
                </div>
            </LoadMask>
        </div>
    );
};

export default Card;
