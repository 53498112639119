export const listado_permisos = {
    es_gerente: {
        nombre: "GERENTE",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: true,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: true,
                    },
                },
            },
            permisos_bodega: {
                titulo: "Permisos Bodega",
                checked: true,
                subItems: {
                    bodeigreso_mercaderiaga: {
                        titulo: "Listado de movimientos",
                        checked: true,
                    },
                    baja_mercaderia: {
                        titulo: "Devolucion Proveedor",
                        checked: true,
                    },
                    salida_mercaderia: {
                        titulo: "Devolucion Cliente",
                        checked: true,
                    },
                    reajuste_mercaderia: {
                        titulo: "Hacer Reajuste",
                        checked: true,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: true,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: false,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: true,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: false,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: true,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: true,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: true,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: true,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: true,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: true,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: true,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: true,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: true,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: "reporete cuenta por cobrar",
                        checked: true,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporete cuenta por pagar",
                        checked: true,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: true,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: true,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: true,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: true,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: true,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: true,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: true,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: true,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: true,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: true,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: true,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: true
                    }
                },
            },
        },
    },
    es_encargado_tienda: {
        nombre: "Encargado de tienda",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: true,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: true,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: true,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: false,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: true,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: false,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: false,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: "reporete cuenta por cobrar",
                        checked: true,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporete cuenta por pagar",
                        checked: false,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: true,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: true,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: true,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: false,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: true,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: true,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: true,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: false
                    }
                },
            },
        },
    },
    es_auxiliar_gerencia: {
        nombre: "AUXILIAR GERENCIA",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: true,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: true,
                    },
                },
            },
            permisos_bodega: {
                titulo: "Permisos Bodega",
                checked: true,
                subItems: {
                    bodeigreso_mercaderiaga: {
                        titulo: "Listado de movimientos",
                        checked: true,
                    },
                    baja_mercaderia: {
                        titulo: "Devolucion Proveedor",
                        checked: true,
                    },
                    salida_mercaderia: {
                        titulo: "Devolucion Cliente",
                        checked: true,
                    },
                    reajuste_mercaderia: {
                        titulo: "Hacer Reajuste",
                        checked: true,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: true,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: false,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: true,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: false,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: true,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: true,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: true,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: true,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: true,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: true,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: true,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: true,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: true,
                name: "transportes",
                subItems: {},
            },
        },
    },

    es_jefe_bodega: {
        nombre: "JEFE BODEGA",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: true,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: true,
                    },
                },
            },
            Permisos_bodega: {
                titulo: "Permisos Bodega",
                checked: true,
                subItems: {
                    bodeigreso_mercaderiaga: {
                        titulo: "Listado de movimientos",
                        checked: true,
                    },
                    baja_mercaderia: {
                        titulo: "Devolucion Proveedor",
                        checked: true,
                    },
                    salida_mercaderia: {
                        titulo: "Devolucion Cliente",
                        checked: false,
                    },
                    reajuste_mercaderia: {
                        titulo: "Hacer Reajuste",
                        checked: true,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: true,
                subItems: {},
            },
            productos: {
                titulo: "Productos",
                checked: true,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: " cuenta por cobrar",
                        checked: false,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporte cuenta por pagar",
                        checked: true,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: false,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: true,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: true,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: false,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: true,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: true,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: false,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: true,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: true,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: false,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: true
                    }
                },
            },
        },
    },

    es_vendedor: {
        nombre: "VENDEDORES",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: false,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: false,
                    },
                },
            },
            inventario: {
                titulo: "Permisos Bodega",
                checked: true,
                subItems: {
                    bodeigreso_mercaderiaga: {
                        titulo: "Listado de movimientos",
                        checked: true,
                    },
                    baja_mercaderia: {
                        titulo: "Devolucion Proveedor",
                        checked: false,
                    },
                    salida_mercaderia: {
                        titulo: "Devolucion Cliente",
                        checked: true,
                    },
                    reajuste_mercaderia: {
                        titulo: "Hacer Reajuste",
                        checked: false,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: false,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: false,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: true,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: false,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: false,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: "reporete cuenta por cobrar",
                        checked: true,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporete cuenta por pagar",
                        checked: false,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: true,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: false,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: true,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: false,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: false,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: false,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: true,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: false
                    }
                },
            },
        },
    },

    es_vendedor_mayorista: {
        nombre: "VENDEDOR/MAYORISTA",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: false,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: false,
                    },
                },
            },
            inventario: {
                titulo: "Permisos Bodega",
                checked: true,
                subItems: {
                    bodeigreso_mercaderiaga: {
                        titulo: "Listado de movimientos",
                        checked: true,
                    },
                    baja_mercaderia: {
                        titulo: "Devolucion Proveedor",
                        checked: false,
                    },
                    salida_mercaderia: {
                        titulo: "Devolucion Cliente",
                        checked: true,
                    },
                    reajuste_mercaderia: {
                        titulo: "Hacer Reajuste",
                        checked: false,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: false,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: true,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: false,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: false,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: false,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: "reporete cuenta por cobrar",
                        checked: true,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporete cuenta por pagar",
                        checked: false,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: true,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: false,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: false,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: false,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: false,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: false,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: true,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: false
                    }
                },
            },
        },
    },

    es_vendedor_distribuidor: {
        nombre: "VENDEDOR/DISTRIBUIDOR",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: true,
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: true,
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: true,
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: false,
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: false,
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: false,
                subItems: {},
            },
            ordenes_venta: {
                titulo: "Ordenes de venta",
                checked: false,
                name: "orden_venta",
                subItems: {
                    mayorista: {
                        titulo: "Mayorista",
                        checked: false,
                        name: "mayorista",
                    },
                    minorista: {
                        titulo: "Minorista",
                        checked: false,
                        name: "minorista",
                    },
                    distribuidor: {
                        titulo: "Distribuidor",
                        checked: true,
                        name: "distribuidor",
                    },
                },
            },
            productos: {
                titulo: "Productos",
                checked: false,
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
            permisos_reportes: {
                titulo: "Permisos Reportes",
                checked: true,
                subItems: {
                    reporte_cuenta_por_cobrar: {
                        titulo: "reporete cuenta por cobrar",
                        checked: true,
                    },
                    reporte_cuenta_por_pagar: {
                        titulo: "reporete cuenta por pagar",
                        checked: false,
                    },
                    reporte_kardex: {
                        titulo: "reporte kardex",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_cobrar: {
                        titulo: "reporte recibos cuenta por cobrar",
                        checked: true,
                    },
                    reporte_recibos_cuenta_por_pagar: {
                        titulo: "reporte recibos cuenta por pagar",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comisiones",
                        checked: false,
                    },
                    reporte_comisiones: {
                        titulo: "reporte comision mensual",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_proveedor: {
                        titulo: "reporte notas de credito al proveedor",
                        checked: false,
                    },
                    reporte_notas_de_credito_al_cliente: {
                        titulo: "reporte notas de credito al cliente",
                        checked: true,
                    },
                    reporte_articulos_inventario: {
                        titulo: "reporte articulos inventario",
                        checked: false,
                    },
                    reporte_articulos_vendidos: {
                        titulo: "reporte articulos vendidos",
                        checked: false,
                    },
                    reporte_ventas_por_familia: {
                        titulo: "reporte ventas por familia",
                        checked: false,
                    },
                    reporte_articulos_de_venta: {
                        titulo: "reporte articulos de venta",
                        checked: false,
                    },
                    reporte_entradas: {
                        titulo: "reporte entradas",
                        checked: false,
                    },
                    reporte_salidas: {
                        titulo: "reporte salidas",
                        checked: true,
                    },
                    reporte_caja: {
                        titulo: "reporte caja",
                        checked: true,
                    },
                    reporte_reajustes: {
                        titulo: "reporte reajustes",
                        checked: false
                    }
                },
            },
        },
    },

    es_motorista: {
        nombre: "MOTORISTAS",
        permisos: {
            dashboard: {
                titulo: "Dashboard",
                checked: false,
                name: "dashboard",
                subItems: {},
            },
            inventario: {
                titulo: "Inventario",
                checked: false,
                name: "bodega",
                subItems: {
                    bodega: {
                        titulo: "Bodega",
                        checked: false,
                        name: "bodega",
                    },
                    traslados: {
                        titulo: "Traslados",
                        checked: false,
                        name: "despacho_despachos",
                    },
                    recibir_otra_sucursal: {
                        titulo: "Recibir de otra sucursal",
                        checked: false,
                        name: "despacho_recepciones",
                    },
                },
            },
            ordenes_compra: {
                titulo: "Ordenes de compra",
                checked: false,
                name: "orden_compra",
                subItems: {},
            },
            productos: {
                titulo: "Productos",
                checked: false,
                name: "producto",
                subItems: {},
            },
            configuracion: {
                titulo: "Configuracion",
                checked: false,
                name: "configuracion",
                subItems: {
                    usuarios: {
                        titulo: "Usuarios",
                        checked: false,
                        name: "conf_usuario",
                    },
                    sucursales: {
                        titulo: "Sucursales",
                        checked: false,
                        name: "conf_sucursal",
                    },
                    clientes: {
                        titulo: "Clientes",
                        checked: false,
                        name: "conf_cliente",
                    },
                    proveedores: {
                        titulo: "Proveedores",
                        checked: false,
                        name: "conf_proveedor",
                    },
                },
            },
            bitacora: {
                titulo: "Bitacora",
                checked: false,
                name: "bitacora",
                subItems: {},
            },
            bancos: {
                titulo: "Bancos",
                checked: false,
                name: "bancos",
                subItems: {},
            },
            transportes: {
                titulo: "Transportes",
                checked: false,
                name: "transportes",
                subItems: {},
            },
        },
    },
};
