import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDateTime } from "Utils/renderField/renderTableField";
import FiltrosPendientes from "../../Filtros/FiltrosPendientes";
import FiltrosCompletados from "../../Filtros/FiltrosCompletados";

class ListadoRecepcionDespacho extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.listar(this.props.page);
    }

    // limpiar datos al desmontar el componente
    componentWillUnmount() {
        this.props.limpiar();
    }

    render() {
        // state
        const {
            data,
            loader,
            search,
            tab,
            fecha_compra_final,
            fecha_compra_inicial,
            usuario,
        } = this.props;

        const { fecha_pendiente_inicial, fecha_completado_inicial } =
            fecha_compra_inicial;
        const { fecha_pendiente_final, fecha_completado_final } =
            fecha_compra_final;

        const { usuario_pendiente, usuario_completado } = usuario;

        // bind
        const { buscar, changeTab, setFecha, setUsuarioValue } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Tabs
                        className="content-tabs"
                        selectedIndex={tab == 10 ? 0 : 1}
                        onSelect={(tabIndex) =>
                            changeTab(tabIndex == 0 ? 10 : 20)
                        }
                    >
                        <TabList className="tab_list d-flex flex-row justify-content-between">
                            <Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                RECEPCIONES PENDIENTES
                            </Tab>
                            <Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                RECEPCIONES COMPLETADAS
                            </Tab>
                        </TabList>
                        {/* PANEL PENDIENTES */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar
                                        titulo={"Listado"}
                                        buscar={buscar}
                                        search={search}
                                    />
                                </div>
                                {/* //** Filtros */}
                                <FiltrosPendientes
                                    fecha_pendiente_inicial={
                                        fecha_pendiente_inicial
                                    }
                                    fecha_pendiente_final={
                                        fecha_pendiente_final
                                    }
                                    usuario_pendiente={usuario_pendiente}
                                    setFecha={setFecha}
                                    setUsuarioValue={setUsuarioValue}
                                />
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="sucursal__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>{row.sucursal.nombre}</div>
                                            )}
                                            dataSort
                                        >
                                            Enviado desde
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="creado"
                                            isKey
                                            dataFormat={(cell) => (
                                                <RenderDateTime fecha={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            dataFormat={(cell, row) => {
                                                return (
                                                    <Link
                                                        className="btn btn-secondary"
                                                        to={`/recepciones_de_traslados/recibir/${cell}`}
                                                    >
                                                        <em className="fa fa-check"></em>{" "}
                                                        Recibir traslado
                                                    </Link>
                                                );
                                            }}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                        {/* PANEL ACEPTADOS */}
                        <TabPanel>
                            <div className="grid-container">
                                <div className="grid-titulo">
                                    <Toolbar
                                        titulo={"Listado"}
                                        buscar={buscar}
                                        search={search}
                                    />
                                </div>
                                {/* //*** Filtros */}
                                <FiltrosCompletados
                                    fecha_completado_inicial={
                                        fecha_completado_inicial
                                    }
                                    fecha_completado_final={
                                        fecha_completado_final
                                    }
                                    usuario_pendiente={usuario_pendiente}
                                    usuario_completado={usuario_completado}
                                    setFecha={setFecha}
                                    setUsuarioValue={setUsuarioValue}
                                />
                                <LoadMask loading={loader} dark blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{
                                            dataTotalSize: data.count,
                                        }}
                                        options={options}
                                    >
                                        <TableHeaderColumn
                                            dataField="sucursal__nombre"
                                            dataFormat={(cell, row) => (
                                                <div>{row.sucursal.nombre}</div>
                                            )}
                                            dataSort
                                        >
                                            Enviado desde
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="fecha_recepcion"
                                            dataFormat={(cell) => (
                                                <RenderDateTime fecha={cell} />
                                            )}
                                            dataSort
                                        >
                                            Fecha recepción
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            isKey
                                            dataField="id"
                                            dataAlign="center"
                                            dataFormat={activeFormatter({
                                                ver: "/recepciones_de_traslados/visualizacion",
                                            })}
                                        >
                                            Acciones
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

ListadoRecepcionDespacho.propTypes = {};

export default ListadoRecepcionDespacho;
