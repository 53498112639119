import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RenderDateTime, RenderNumber } from "Utils/renderField/renderReadField";

export default class TicketEntrega extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const { sucursal, movimiento, usuario, utiliza_fel,direccion_cliente,telefono_cliente,notas_cliente } = this.props;
        const datos_empresa = sucursal.datos_empresa ? sucursal.datos_empresa : {};
        const detalles = movimiento.detalles ? movimiento.detalles : [];
        return(
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    {
                        utiliza_fel ? (
                            <div className="w-100 text-center">
                                <b className="text-uppercase">{datos_empresa.nombre_comercial}</b>
                            </div>
                        ) : (
                            <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                <img
                                    src={require('../../../../../assets/img/remocar-logo.png')}
                                    width="25%"
                                    />
                            </div>
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    {datos_empresa.nombre}
                                </b>
                            </div>
                            </div>
                            )
                    }
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {
                        (sucursal.telefono || sucursal.telefono != "") && (
                            <div className="w-100 text-center">
                                <b className="text-uppercase">Tel. {sucursal.telefono}</b>
                            </div>
                        )
                    }

                    <div className="w-100 text-center">
                        <b className="text-uppercase"> despacho #{movimiento.id}</b>
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    
                    <div className="flex1 d-flex flex-column">
                        <b>Código de venta</b>
                        {movimiento.codigo_venta ? movimiento.codigo_venta : ""}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        <RenderDateTime value={movimiento.creado}/>
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Nombre</b>
                        {
                            movimiento.cliente_proveedor ? (
                                movimiento.cliente_proveedor.nombre ? movimiento.cliente_proveedor.nombre : "-----"
                            ) : "-----"
                        }
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>NIT</b>
                        {
                            movimiento.cliente_proveedor ? (
                                movimiento.cliente_proveedor.nit ? movimiento.cliente_proveedor.nit : ( movimiento.cliente_proveedor.nombre =="Consumidor Final" ? "C/F": "-----")
                            ) : "-----"
                        }
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb">
                    {
                        usuario ? (
                            <div className="flex1 d-flex flex-column">
                                <b>Usuario</b>
                                {usuario}
                            </div>
                        ): null
                    }
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Direccion</b>
                        {
                            direccion_cliente ? direccion_cliente : "-----"
                        }
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Telefono</b>
                        {
                            telefono_cliente ? telefono_cliente : "-----"
                        }
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Notas</b>
                        {
                            notas_cliente ? notas_cliente  : "-----"
                        }
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between fnt-16" style={{borderTop: "dotted 1px", paddingTop:"10px"}}>
                    <b>ARTÍCULOS</b>
                    <b>CANTIDAD</b>
                </div>
                {detalles.map((detalle) => {
                    return (
                        <div className="w-100" key={detalle.id}>
                            {
                                !detalle.es_vale && (
                                    <div className="W-100 d-flex flex-row mb fnt-11">
                                        <div className="d-flex flex-3 flex-row">
                                            {
                                                `
                                                ${detalle.fraccion.codigo_barras ? `${detalle.fraccion.codigo_barras} / ` : ""}
                                                ${detalle.producto} / ${detalle.fraccion.producto.a_granel ? detalle.unidad_de_medida.nombre : detalle.fraccion.nombre}
                                                `
                                            }
                                        </div>
                                        <div className="d-flex flex-1 flex-row text-right">
                                            {
                                                detalle.fraccion.producto.a_granel ? (
                                                    <RenderNumber value={detalle.cantidad} decimalScale={3} className={"w-100 mr-1"} />
                                                ) : (
                                                    <RenderNumber value={detalle.cantidad} className={"w-100 mr-1"} />
                                                )
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                    );
                })}

                <div className="mt-2 d-flex flex-row align-items-center justify-content-center" style={{borderTop: "dotted 1px", paddingTop:"15px"}}>
                    <div className="w-100 d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                        <span>TICKET DE DESPACHO</span>
                    </div>
                </div>
            </div>
        )
    }
}