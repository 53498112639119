import moment from "moment";
import { useEffect } from "react";

export const useCrearEditar = ({ crear, leer, item, editar, match }) => {
    const { params, path, url } = match;
    const { id } = params;

    useEffect(() => {
        // si se esta editando o viendo el registro, cargar los datos para llenar el formulario (item, variable que contiene los datos)
        if (id) leer(id);
    }, []);

    // true, si la url contiene ver
    const verDato = path.includes("detalle");

    // true, si la url contiene editar
    const editarDato = path.includes("editar");

    const titulo = verDato ? "Ver" : editarDato ? "Editar" : "Nuevo";

    // Si se esta actualizando o viendo un registro, devolver la data del item, sino (se esta creando) devolver un objeto vacio
    const intitalData = verDato || editarDato ? item : {};

    const onSubmit = (dataForm) => {
        const datos_enviados = { ...dataForm };

        // enviar solo el id de los objetos
        if (datos_enviados.usuario)
            datos_enviados.usuario = datos_enviados.usuario.id;

        if (datos_enviados.empresa)
            datos_enviados.empresa = datos_enviados.empresa.id;

        // formatear la fecha
        if (datos_enviados.fecha) {
            const myMomentObject = moment(datos_enviados.fecha).format(
                "YYYY-MM-DD"
            );
            datos_enviados.fecha = myMomentObject;
        }

        // Si es actualizar, retornar editar para que crear no se ejecute
        if (editarDato) return editar(id, datos_enviados);

        crear(datos_enviados);
    };

    return { intitalData, titulo, verDato, editarDato, onSubmit };
};

export default useCrearEditar;
