import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/comisiones";
import DetalleComisiones from "./DetalleComisiones";

const ms2p = (state) => {
    return {
        ...state.reporte_comisiones,
        venta_permitir_descuento: state.usuario.venta_permitir_descuento,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleComisiones);
