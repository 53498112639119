import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";

export default class FacturaCambiaria extends Component {
    static propTypes = {
        orden_venta: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        //  State
        const { orden_venta } = this.props;
        const sucursal = orden_venta.sucursal;
        const datos_empresa = orden_venta.empresa ? orden_venta.empresa : {};
        const datos_fel = orden_venta.datos_fel ? orden_venta.datos_fel : null;
        const detalles = orden_venta.detalles ? orden_venta.detalles : [];
        return (
            <div className="print-shadow vista-recibo-80mm">
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    {datos_empresa.logo_ticket && (
                        <img
                            className="mb-3"
                            src={datos_empresa.logo_ticket}
                            width="100"
                        />
                    )}
                    <div className="w-100 text-center">
                        <b className="text-uppercase">
                            {sucursal.nombre_comercial
                                ? sucursal.nombre_comercial
                                : datos_empresa.nombre_comercial}
                        </b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">
                            {datos_empresa.nombre_emisor}
                        </b>
                    </div>
                    <div className="w-100 text-center text-uppercase">
                        {sucursal.direccion}
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            Tel. {sucursal.telefono}
                        </div>
                    )}
                    <div className="w-100 text-center">
                        <div className="w-100 text-center">
                            Nit. {datos_empresa.nit}
                        </div>
                    </div>
                    {datos_fel && (
                        <div className="w-100 text-center mt-3">
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    FACTURA CAMBIARIA
                                </b>
                                <br />
                                <b className="text-uppercase">
                                    {datos_fel.tipo} - DOCUMENTO TRIBUTARIO
                                    ELECTRÓNICO
                                </b>
                            </div>
                        </div>
                    )}
                </div>
                {datos_fel && (
                    <div className="w-100">
                        {datos_fel.estado_factura == FACTURADO && (
                            <div className="w-100">
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column">
                                        <b>Serie</b>
                                        {datos_fel.serie ? datos_fel.serie : ""}
                                    </div>
                                    <div className="flex1 d-flex flex-column">
                                        <b>Número</b>
                                        {datos_fel.numero
                                            ? datos_fel.numero
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>No. Orden venta</b>
                        {orden_venta.no_orden ? orden_venta.no_orden : ""}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Fecha y hora</b>
                        {datos_fel && (
                            <RenderDateTime value={datos_fel.fecha_emision} />
                        )}
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    <div className="flex1 d-flex flex-column">
                        <b>Nombre</b>
                        {orden_venta.nombre || "Consumidor Final"}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>NIT</b>
                        {orden_venta.nit || "CF"}
                    </div>
                </div>
                <div className="d-flex flex-column fnt-12 mb">
                    <div className="flex1 d-flex flex-column mb05">
                        <b>Dirección</b>
                        {orden_venta.direccion || "Ciudad"}
                    </div>
                    <div className="flex1 d-flex flex-column">
                        <b>Usuario</b>
                        {orden_venta.emitida_por.first_name}
                    </div>
                </div>

                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "10px" }}
                >
                    <b>ARTÍCULOS</b>
                    <b>SUB TOTAL</b>
                </div>
                {detalles.map((detalle) => {
                    return (
                        <div className="d-flex mb fnt-11" key={detalle.id}>
                            <div className="width100">
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row">
                                        {`
                                            ${
                                                detalle.fraccion.codigo_barras
                                                    ? `${detalle.fraccion.codigo_barras} / `
                                                    : ""
                                            }
                                            ${detalle.producto} / ${
                                            detalle.fraccion.producto.a_granel
                                                ? detalle.fraccion
                                                      .unidad_de_medida.nombre
                                                : detalle.fraccion.nombre
                                        }
                                        `}
                                    </div>
                                    <div className="d-flex flex-row">
                                        <RenderCurrency
                                            value={detalle.precio}
                                            className={"mr-1"}
                                        />
                                        <div className="mr-1">X</div>
                                        {detalle.fraccion.producto.a_granel ? (
                                            <RenderNumber
                                                value={detalle.cantidad}
                                                decimalScale={3}
                                                className={"mr-1"}
                                            />
                                        ) : (
                                            <RenderNumber
                                                value={detalle.cantidad}
                                                className={"mr-1"}
                                            />
                                        )}
                                        <RenderCurrency
                                            value={detalle.sub_total}
                                            className={"ml-auto"}
                                        />
                                    </div>
                                </div>
                                {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                {/* {detalle.precio_original &&
                                    detalle.desc_porcentaje > 0 && (
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row pl-20">
                                                Descuento (
                                                {detalle.desc_porcentaje}%)
                                            </div>
                                            <div className="d-flex flex-row pl-20">
                                                <span className="mr-1">
                                                    -{" "}
                                                    <RenderCurrency
                                                        value={
                                                            detalle.desc_monto
                                                        }
                                                    />
                                                </span>
                                                <div className="mr-1">X</div>
                                                {detalle.fraccion.producto
                                                    .a_granel ? (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                        decimalScale={3}
                                                        className={"mr-1"}
                                                    />
                                                ) : (
                                                    <RenderNumber
                                                        value={detalle.cantidad}
                                                        className={"mr-1"}
                                                    />
                                                )}
                                                <span className="ml-auto">
                                                    -{" "}
                                                    <RenderCurrency
                                                        value={
                                                            detalle.cantidad *
                                                            detalle.desc_monto
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    )} */}
                                {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                            </div>
                        </div>
                    );
                })}
                {/* {movimiento.reciduo_redondeo != 0 && (
                    <div className="width100 mb">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex3 flex-row">
                                Redondeo
                            </div>
                            <div className="d-flex flex1 flex-row align-items-center justify-content-end">
                                <RenderCurrency
                                    value={movimiento.reciduo_redondeo}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
                >
                    <b>TOTAL</b>
                    <b>
                        <RenderCurrency
                            value={orden_venta.total}
                            className={"ml-auto"}
                        />
                    </b>
                </div>
                <div className="fnt-16">
                    <div className="w-100 mt-3">
                        {/* DENOMINACION */}
                        {datos_fel && (
                            <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                {datos_fel.frase_fel}
                            </div>
                        )}
                        {datos_fel && (
                            <div className="d-flex flex-row fnt-12 mb05">
                                <div className="flex1 d-flex flex-column align-items-center">
                                    <b>COMPLEMENTOS</b>
                                    {datos_fel.tipo == "FCAM" ? (
                                        <p className="text-uppercase">
                                            Abonos de Factura Cambiaria
                                        </p>
                                    ) : (
                                        <p className="text-uppercase">
                                            Abonos de Factura Cambiaria de
                                            Pequeño Contribuyente
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                        {datos_fel && (
                            <div className="w-100">
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column align-items-center text-center">
                                        <b>AUTORIZACIÓN FEL</b>
                                        {datos_fel.uuid ? datos_fel.uuid : ""}
                                    </div>
                                </div>
                                <div className="d-flex flex-row fnt-12">
                                    <div className="flex1 d-flex flex-column align-items-center">
                                        <b>FECHA CERTIFICACIÓN</b>
                                        <RenderDateTime
                                            value={datos_fel.fechaCertificacion}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* DATOS CERTIFICADOR */}
                        <div className="d-flex flex-row fnt-12 mt-3">
                            <div className="flex1 d-flex flex-column align-items-center">
                                <b>CERTIFICADOR</b>
                                INFILE, S.A. -- NIT: 12521337
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
