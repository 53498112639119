import React from "react";

import DatePicker from "Utils/DatePicker";
import { Async } from "react-select";
import moment from "moment";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";

const listado_bancos = [];
const listado_usuarios = [];
const CamposFiltros = ({
    usuario,
    fecha_inicial,
    fecha_final,
    banco,
    setFecha,
    setUsuario,
    setBanco,
}) => {
    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        {
            vendedor: 1,
        }
    );

    const { getOptions: getBancos } = useAsyncSearch("bancos", listado_bancos);

    return (
        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
            <div className="flex-3">
                <label htmlFor="">Fecha inicial</label>
                <DatePicker
                    value={fecha_inicial}
                    maxDate={
                        new Date(
                            moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")
                        )
                    }
                    onChange={(date) => {
                        setFecha("Inicial", date);
                    }}
                />
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
                <label htmlFor="">Fecha final</label>
                <DatePicker
                    value={fecha_final}
                    minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                    onChange={(date) => {
                        setFecha("Final", date);
                    }}
                />
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
                <label>Vendedor</label>
                <Async
                    className="form-control"
                    labelKey="first_name"
                    valueKey="id"
                    searchPromptText="Escriba para buscar"
                    loadOptions={getUsuarios}
                    placeholder={"Usuario"}
                    onChange={(e) => {
                        setUsuario(e);
                    }}
                    value={usuario}
                />
            </div>

            <div className="flex-1"></div>
            <div className="flex-3">
                <label>Banco</label>
                <Async
                    className="form-control"
                    labelKey="banco_cuenta"
                    valueKey="id"
                    searchPromptText="Escriba para buscar"
                    loadOptions={getBancos}
                    placeholder={"Bancos"}
                    onChange={(e) => {
                        setBanco(e);
                    }}
                    value={banco}
                />
            </div>
        </div>
    );
};

export default CamposFiltros;
