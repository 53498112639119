import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Line } from "react-chartjs-2";

class GraficaMovimiento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: "100%",
        };
    }

    render() {
        //  state
        const { data_grafica, loader_grafica, loader, pais, producto } =
            this.props;
        {
            console.log(producto);
        }
        {
            console.log("data", data_grafica);
        }
        const translate_month = (month) => {
            var result = month;

            switch (month) {
                case "Jan":
                    result = "Ene";
                    break;
                case "Apr":
                    result = "Abr";
                    break;
                case "Aug":
                    result = "Ago";
                    break;
                case "Dec":
                    result = "Dic";
                    break;
            }
            return result;
        };

        const translate_this_label = (label) => {
            const month = label.match(
                /Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/g
            );

            if (!month) return label;

            const translation = translate_month(month[0]);
            return label.replace(month, translation, "g");
        };

        const data = {
            labels: [],
            datasets: [
                {
                    label: `Movimientos del producto`,
                    data: [],
                    type: "line",
                    borderColor: "rgba(19, 106, 198, 1)",
                    pointBackgroundColor: "rgba(19, 106, 198, 1)",
                    backgroundColor: "rgba(19, 106, 198, 0.5)",
                    borderWidth: 2,
                },
            ],
        };

        const lineOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        type: "time",
                        // tooltipFormat:'MM/DD/YYYY',
                        distribution: "linear",
                        time: {
                            displayFormats: {
                                month: "YYYY MMM",
                            },
                        },
                        ticks: {
                            // Here's where the magic happens:
                            callback: function (label, index, labels) {
                                return translate_this_label(label);
                            },
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: false,
                            callback: function (value, index, values) {
                                value = parseFloat(value);
                                let value_abs = Math.abs(value);
                                if (value_abs >= 1000) {
                                    return value
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return value.toFixed(2);
                                }
                            },
                        },
                    },
                ],
            },
            legend: {
                display: true,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let value = tooltipItem.yLabel;

                        value = parseFloat(value);
                        let value_abs = Math.abs(value);
                        if (value_abs >= 1000) {
                            return (
                                `Existencias: ` +
                                value
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            );
                        } else {
                            return `Existencias: ` + value.toFixed(0);
                        }
                    },
                    beforeLabel: function (tooltipItem, data) {
                        let value = tooltipItem.xLabel;
                        value = new Date(value);

                        let day = value.getDate();
                        let month = value.getMonth() + 1;
                        let year = value.getFullYear();
                        let hour = value.getHours();
                        let min = value.getMinutes();

                        if (min < 10) {
                            min = `0${min}`;
                        }

                        if (month < 10) {
                            return `${day}-0${month}-${year} ${hour}:${min}`;
                        } else {
                            return `${day}-${month}-${year}`;
                        }
                    },
                    title: function (tooltipItem, data) {
                        return null;
                    },
                },
            },
            elements: {
                line: {
                    tension: 0.000001,
                },
            },
        };

        if (data_grafica) {
            data_grafica.forEach((historial) => {
                const item = {
                    x: historial.creado,
                    y: historial.existencia_final,
                };
                data.datasets[0].data.push(item);
            });
        }

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <LoadMask loading={loader_grafica} light blur>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <Line
                                    height={500}
                                    data={data}
                                    options={lineOptions}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default GraficaMovimiento;
