import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";

import {
    required,
    composeValidators,
    email,
    isEmpty,
    dpi,
    NIT,
} from "../../../../utility/validation";

import {
    renderField,
    renderSwitch,
    renderSelectField,
    renderSearchSelect,
} from "Utils/renderField/renderField";

import { TIPO_CLIENTE } from "../../../../utility/constantes";

import MultiUploadFile from "Utils/Archivos/Archivos";

import DireccionesForm from "../Direcciones/DireccionesForm";
import CreditosForm from "../Creditos/CreditosForm";
import useAsyncSearch from "../../../hooks/useAsyncSearch";

const listado_usuarios = [];
const ClientesForm = ({
    onSubmit,
    view = false,
    editar = false,
    files,
    setFiles,
    oldFiles,
    setOldFiles,
    removeFiles,
    setRemoveFiles,
    data,
    creditos,
    setCreditos,
    direcciones,
    setDirecciones,
    match,
}) => {
    const [errors, setErrors] = useState({
        // tipo_cliente: null,
        email: null,
        password: null,
    });

    const [tipoClienteState, setTipoClienteState] = useState(0);

    useEffect(() => {
        setTipoClienteState(data.tipo_cliente);
    }, [data]);

    const isObjEmpty = (obj) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) return false;
        }

        return true;
    };

    const validation = (dataForm) => {
        const form = { ...dataForm };

        const errors_local = {};

        // if (isEmpty(form.tipo_cliente) || tipoClienteState === 0) {
        //     errors_local["tipo_cliente"] = "Campo requerido";
        // }

        if (
            form.pedidos_online == true &&
            isEmpty(form.email) &&
            form.change_email
        ) {
            errors_local["email"] = "Campo requerido";
        } else if (!form.pedidos_online) {
            form.email = null;
        }

        if (
            form.pedidos_online == true &&
            isEmpty(form.password) &&
            form.change_email
        ) {
            errors_local["password"] = "Campo requerido";
        } else if (!form.pedidos_online) {
            form.password = null;
        }

        //Segunda validacion
        if (form.email) {
            let validation = email(form.email);

            if (validation) errors_local["email"] = validation;
        }

        let checkErrors = isObjEmpty(errors_local);

        if (checkErrors) {
            const { tiene_usuario_tienda, pedidos_online } = dataForm;
            if (!tiene_usuario_tienda && pedidos_online) {
                dataForm.change_email = true;
            }

            onSubmit(dataForm);
        } else {
            setErrors(errors_local);
        }
    };

    const staticError = (value) => {
        return <div className="d-flex invalid-feedback">{value}</div>;
    };

    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        {
            es_mayorista: true,
        }
    );

    const { getOptions: getUsuarios2 } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        {
            es_distribuidor: true,
        }
    );

    const tiene_usuario_tienda =
        data && data.tiene_usuario_tienda ? true : false;

    return (
        <React.Fragment>
            <Form
                onSubmit={validation}
                mutators={{
                    // expect (field, value) args from the mutator
                    setInput: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                initialValues={{
                    precio_costo: false,
                    pedidos_online: false,
                    change_email: true,
                    ...data,
                }}
                render={({ handleSubmit, submitting, values, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="mb-5 card card-small px-3 py-4">
                            <div className="d-flex justify-content-between border-bottom mb-4">
                                <h3 className="contenido-1 txt-bold black">
                                    Datos
                                </h3>
                                <span className="font-weight-bold primary">
                                    ID Cliente: {data.id}
                                </span>
                            </div>

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="nombre">
                                        Nombre comercial
                                    </label>
                                    <Field
                                        name="nombre"
                                        component={renderField}
                                        //placeholder="Ingrese nombre del cliente"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="nombre_fel">
                                        Nombre FEL
                                    </label>
                                    <Field
                                        name="nombre_fel"
                                        component={renderField}
                                        //placeholder="Ingrese nombre del cliente"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="dpi">DPI *</label>
                                    <Field
                                        name="dpi"
                                        component={renderField}
                                        // placeholder="Ingrese nombre del donante"
                                        type="text"
                                        className="form-control"
                                        validate={composeValidators(dpi)}
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="nit">NIT *</label>
                                    <Field
                                        name="nit"
                                        component={renderField}
                                        // placeholder="Ingrese nombre del donante"
                                        type="text"
                                        className="form-control"
                                        validate={composeValidators(
                                            required,
                                            NIT
                                        )}
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="tipo_cliente">
                                        Tipo de cliente *
                                    </label>
                                    <Field
                                        name="tipo_cliente"
                                        component={renderSelectField}
                                        options={TIPO_CLIENTE}
                                        className="form-control"
                                        item_vacio={true}
                                        value_assign={"id"}
                                        assigned_value={true}
                                        parse={(value) => {
                                            setTipoClienteState(
                                                parseInt(value)
                                            );
                                            return value;
                                        }}
                                        validate={required}
                                    />

                                    {/* {errors &&
                                        errors["tipo_cliente"] &&
                                        !values.tipo_cliente &&
                                        staticError(errors["tipo_cliente"])} */}
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    {tipoClienteState === 3 ? (
                                        <React.Fragment>
                                            <label htmlFor="vendedor">
                                                Vendedor
                                            </label>
                                            <Field
                                                name="vendedor"
                                                component={renderSearchSelect}
                                                loadOptions={getUsuarios2}
                                                labelKey="first_name"
                                                valueKey="id"
                                                className="form-control"
                                            />
                                        </React.Fragment>
                                    ) : tipoClienteState === 1 ? (
                                        <React.Fragment>
                                            <label htmlFor="vendedor">
                                                Vendedor
                                            </label>
                                            <Field
                                                name="vendedor"
                                                component={renderSearchSelect}
                                                loadOptions={getUsuarios}
                                                labelKey="first_name"
                                                valueKey="id"
                                                className="form-control"
                                            />
                                        </React.Fragment>
                                    ) : null}
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="email">Email *</label>
                                    <Field
                                        name="email"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={view}
                                        validate={email}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            {/* //*** Formulario de Direcciones del cliente */}
                            <DireccionesForm
                                direcciones={direcciones}
                                setDirecciones={setDirecciones}
                                ver={view}
                                editar={editar}
                            />

                            <CreditosForm
                                creditos={creditos}
                                setCreditos={setCreditos}
                                ver={view}
                                editar={editar}
                                match={match}
                            />

                            <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-4">
                                Notas
                            </h3>

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="precio_costo">
                                        Acepta venta a precio costo *
                                    </label>
                                    <div className="d-flex">
                                        <label className="mr-2">No</label>
                                        <Field
                                            name="precio_costo"
                                            component={renderSwitch}
                                            type="text"
                                            className="form-control"
                                            disabled={view}
                                        />
                                        <label className="ml-2">Si</label>
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Carga de archivos
                                    </label>

                                    <MultiUploadFile
                                        files={files}
                                        setFiles={setFiles}
                                        oldFiles={oldFiles}
                                        setOldFiles={setOldFiles}
                                        removeFiles={removeFiles}
                                        setRemoveFiles={setRemoveFiles}
                                    />
                                </div>
                            </div>

                            <br />
                            <h3 className="contenido-1 border-bottom txt-bold black mb-4">
                                Pedidos en línea
                            </h3>
                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="pedidos_online">
                                        Aceptar pedidos en linea *
                                    </label>
                                    <div className="d-flex">
                                        <label className="mr-2">No</label>
                                        <Field
                                            name="pedidos_online"
                                            component={renderSwitch}
                                            type="text"
                                            className="form-control"
                                            disabled={view}
                                        />
                                        <label className="ml-2">Si</label>
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="email">
                                        Email *{" "}
                                        {tiene_usuario_tienda ? (
                                            <span className="text-secondary">
                                                Este usuario ya posee un usuaro
                                                en la tienda
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </label>
                                    <Field
                                        name="email"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={
                                            editar && values.pedidos_online
                                                ? tiene_usuario_tienda
                                                : view ||
                                                  (values.pedidos_online &&
                                                      values.change_email)
                                                ? false
                                                : true
                                        }
                                    />
                                    {errors &&
                                        errors["email"] &&
                                        !values.email &&
                                        staticError(errors["email"])}
                                    {errors &&
                                        errors["email"] &&
                                        values.email &&
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                            values.email
                                        ) &&
                                        staticError("Email invalido")}
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="password">
                                        Contraseña *
                                    </label>
                                    <Field
                                        name="password"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={
                                            editar && values.pedidos_online
                                                ? tiene_usuario_tienda
                                                : view ||
                                                  (values.pedidos_online &&
                                                      values.change_email)
                                                ? false
                                                : true
                                        }
                                    />
                                    {errors &&
                                        errors["password"] &&
                                        !values.password &&
                                        staticError(errors["password"])}
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-end py-4">
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    //disabled={submitting || pristine}
                                >
                                    {editar ? "Actualizar" : "Agregar"} Cliente
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default ClientesForm;
