import React, { useState, useEffect } from "react";
import { icons } from "icons";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { credito, cotizacion, factura } from "utility/constantes";
import moment from "moment/moment";
import ReciboOV from "Utils/ReciboOV";
import Factura from "Utils/Factura";
import PropTypes from "prop-types";
import ModalAyuda from "Utils/Ayuda";
import { ToastStore } from "react-toasts";
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask/LoadMask";
import ReactToPrint from "react-to-print";
import { ListadoAccion } from "Utils/ListadoAccion";
import { DetalleAccion } from "Utils/DetalleAccion";
import { ListadoCards } from "Utils/ListadoCards";
import TicketCotizacion from "Utils/TicketCotizacion";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { pago_total, pago_credito } from "utility/variables";
import { DetalleVentaCotizacion } from "Utils/Venta/DetalleVentaCotizacion";
import { PrintContainer, PrintComponent } from "Utils/Print";

import Cobro from "../../Ventas/Venta/Cobro";
import ValeForm from "../../Ventas/Venta/Vale";
import DatosVenta from "../../Ventas/Venta/DatosVenta/DatosVenta";
import ClienteCotizacionForm from "../../PuntoVenta/Mostrador/clienteForm";
import ExistenciaSucursal from "../../Ventas/Venta/ExistenciaSucursal"; //./ExistenciaSucursal

import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

import {
    formatoNombre,
    formatoExistencia,
    buscar,
    formatoProducto,
    formatoAvatar,
    formatoAvatarMovil,
    formatoMarca,
    formatoCodigo,
} from "Utils/CamposComunes";

import {
    renderSwitch,
    renderField,
    renderTextArea,
    renderDatePicker,
    renderSelectField,
    renderFieldReadOnly,
} from "Utils/renderField/renderField";

import {
    formatoAcciones,
    formatoPrecio,
    formatoExistencias,
} from "../../Ventas/Venta/CamposVenta";
import _ from "lodash";

import { tipos_venta } from "utility/constantes";

import "./../../Ventas/Venta/Venta.css";
import MovimientoForm from "../../Cajas/Detalle/Movimiento";
import { validarConfDecimales } from "../../Utils/validaConfiguracionDecimal";
import {
    formatoCantidadDetail,
    formatoPrecioDetail,
    formatoSubtotal,
} from "../camposOV";
import ModalFacturar from "../../CuentaCobrar/Listado/ModalFacturar";
import { ID_NIT } from "../../../../utility/variables";

const validate = (values) => {
    const errors = {};
    if (!values.cliente) {
        errors.cliente = "Campo requerido";
    }

    if (!values.direccion) {
        errors.direccion = "Campo requerido";
    }

    return errors;
};

const initial_state_cliente = {
    datos_cambiados: false,
    tipo_documento: ID_NIT,
    cliente: {
        nombre_cliente: null,
        no_doc_cliente: null,
    },
};

const Form = (props) => {
    // state
    const {
        clienteValue,
        direcciones,
        enviar_correo,
        venta_costo,
        listar,
        data_form,
        sortChange,
        loader,
        data,
        page,
        total,
        formato_factura,
        factura_personalizada,
        ver_buscador,
        ver_cobro,
        tipo_pago,
        search,
        print_state,
        conf_venta_precio_editable,
        pushRouter,
        //State
        state,
        setState,
        onClickAyuda,
        onClickOtrasAcciones,
        //Cliente
        listarCliente,
        abrirModalCliente,
        //Producto
        quitar,
        //Nav Add
        producto_actual,
        setProductoActual,
        tipo_venta,

        datos_cliente,
        setDatosCliente,

        match,
    } = props;

    const {
        agregar,
        movimiento,
        venta_impresion,
        cambioBuscador,
        cambioCobro,
        utiliza_fel,
        conf_cotizacion,
        ver_impresion,
        venta_cotizacion,
        seleccionados,
        cotizacion_cargada,
        setTipoVenta,

        //Send
        handleSubmit,
        customer,
    } = props;

    const [esCotizacion, setEsCotizacion] = useState(false);
    // const [datosCliente, setDatosCliente] = useState(initial_state_cliente);

    const [modal, setModal] = useState({
        open: false,
        openSecond: false,
        data: {},
    });

    const llavesListado = [
        {
            nombre: "img_cropped",
            sort: false,
            caption: "",
            format: formatoAvatar,
            align: "center",
            width: "80px",
        },
        {
            nombre: "codigo",
            sort: false,
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            // width: "100px",
        },
        {
            nombre: "producto__nombre",
            sort: true,
            caption: "Nombre",
            format: formatoNombre,
            align: "left",
        },
        //{
        //    nombre: "producto__marca",
        //    sort: true,
        //    caption: "Marca",
        //    format: formatoMarca,
        //    align: "left",
        //    width: "15%",
        //},
        {
            nombre: "precio",
            sort: false,
            format: formatoPrecio,
            caption: "Precio",
            align: "right",
            width: "15%",
        },
        {
            nombre: "inventario_calculado",
            sort: false,
            caption: "Existencias",
            format: formatoExistencias,
            align: "right",
            width: "13%",
        },
        {
            nombre: "acciones",
            sort: false,
            caption: "Acciones",
            format: formatoAcciones({ ...props, cambioBuscador }),
            align: "center",
            width: "15%",
        },
    ];

    const llavesDetalle = [
        {
            nombre: "codigo",
            caption: "Codigo",
            format: formatoCodigo,
            align: "left",
            width: "100px",
        },
        {
            caption: "Producto",
            nombre: "producto",
            width: "30%",
            format: formatoProducto,
        },
        {
            caption: "Cantidad",
            nombre: "cantidad",
            width: "130px",
            format: formatoCantidadDetail(props),
        },
        {
            caption: "Precio",
            nombre: "precio",
            width: "100px",
            format: formatoPrecio,
        },
        {
            caption: "Subtotal",
            nombre: "subtotal",
            width: "80px",
            format: formatoSubtotal,
            align: "right",
        },
    ];

    const { dropdownItems, itemsImagenes, modalAyuda, dropdownItemsAyuda } =
        state;

    const { path } = match;
    const editar_cotizacion = path.includes("editar");
    //Descuento
    const ingresarValeDescuento = () => {
        setState({ ...state, modal_vale: true });
    };

    useEffect(() => {
        listar();
        return () => {
            // second
        };
    }, []);

    useEffect(() => {
        if (editar_cotizacion) {
            setTipoVenta(40000);
        } else {
            // setTipoVenta(10000);
        }
    }, []);

    const { cliente, datos_cambiados, tipo_documento } = datos_cliente;

    // console.log("datos_cliente", datos_cliente);
    // console.log("setDatosCliente", setDatosCliente);

    const value_nit =
        data_form && data_form.values && data_form.values.nit_cliente
            ? data_form.values.nit_cliente
            : "CF";

    const value_nit_input = datos_cambiados
        ? cliente.no_doc_cliente
        : value_nit;

    const validacion_limite_venta_cf =
        total > 2500 && value_nit_input === "CF" && tipo_venta == factura;

    return (
        <React.Fragment>
            <ModalFacturar modal={modal} setModal={setModal} esNuevaOV />
            <form onSubmit={handleSubmit}>
                <div className="grid-container">
                    <div className="grid-titulo padding-15">
                        Nueva orden de venta
                    </div>
                    <div className="row py-4 px-5">
                        <div className="col-sm-12">
                            {/*  Row 1 */}
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="fecha">Fecha</label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="fecha"
                                            component={renderDatePicker}
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="fecha_entrega">
                                        Tipo de venta
                                    </label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="tipo_venta"
                                            component={renderSelectField}
                                            options={tipos_venta}
                                            border_red={esCotizacion}
                                            item_vacio={false}
                                            parse={(value) => {
                                                if (value == 40000)
                                                    setEsCotizacion(true);
                                                else setEsCotizacion(false);

                                                setTipoVenta(value);
                                                return value;
                                            }}
                                        />
                                    </div>
                                    {/*/<label htmlFor="cliente">Cliente</label>
                                    <div className="d-flex pr-0">
                                        <button
                                            style={{
                                                width: "37px",
                                                height: "37px",
                                            }}
                                            type="button"
                                            className="btn btn-secondary mr-3"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                listarCliente();
                                                abrirModalCliente();
                                            }}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                        <div className="d-flex flex-column w-100">
                                            <Field
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    listarCliente();
                                                    abrirModalCliente();
                                                }}
                                                name="cliente"
                                                component={renderFieldReadOnly}
                                                className="form-control"
                                            />
                                        </div>
                                            </div>*/}
                                </div>
                            </div>

                            {/* Row 2 */}

                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 form-group">
                                    <label htmlFor="descripcion">
                                        Descripción
                                    </label>
                                    <div className="d-flex flex-column pr-0">
                                        <Field
                                            name="descripcion"
                                            rows={2}
                                            component={renderTextArea}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---- Orden Venta ---- */}
                <div className="grid-container mt">
                    {!ver_cobro && (
                        <React.Fragment>
                            <form onSubmit={handleSubmit}>
                                <div className="d-flex justify-content-between py-1 px-2">
                                    <h4 className="font-weight-bold">
                                        Seleccionar Cliente
                                    </h4>

                                    <button
                                        className="btn btn-primary px-5"
                                        // style={{ padding: ".6rem 2rem" }}
                                        type="button"
                                        onClick={() => {
                                            setModal({
                                                open: false,
                                                openSecond: true,
                                                data: {},
                                            });
                                        }}
                                    >
                                        Cambiar datos de cliente
                                    </button>
                                </div>
                                {/*  Row 1 */}
                                <div className="row px-1 py-2">
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                        <label htmlFor="">Cliente</label>
                                        <div className="d-flex pr-0">
                                            <button
                                                style={{
                                                    width: "37px",
                                                    height: "37px",
                                                }}
                                                type="button"
                                                className="btn btn-secondary mr-3"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    listarCliente();
                                                    abrirModalCliente();
                                                }}
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                            <div className="d-flex flex-column w-100">
                                                <Field
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        listarCliente();
                                                        abrirModalCliente();
                                                    }}
                                                    name="cliente"
                                                    component={
                                                        renderFieldReadOnly
                                                    }
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                        <label htmlFor="nit_cliente">Nit</label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{
                                                borderBottom:
                                                    "2px solid  #B8B8B8",
                                            }}
                                        >
                                            {(data_form && (
                                                <React.Fragment>
                                                    No. {value_nit_input}
                                                </React.Fragment>
                                            )) ||
                                                "--------"}
                                        </div>
                                    </div>

                                    {datos_cambiados && (
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label htmlFor="nombre">
                                                Nombre
                                            </label>
                                            <div
                                                className="d-flex flex-column pr-0"
                                                style={{
                                                    borderBottom:
                                                        "2px solid  #B8B8B8",
                                                }}
                                            >
                                                {cliente.nombre_cliente}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row px-1 py-2">
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                        <label htmlFor="enviar_correo">
                                            Enviar a correo
                                        </label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{ maxWidth: "650px" }}
                                        >
                                            <div className="d-flex mt-1">
                                                <label className="mr-2">
                                                    No
                                                </label>
                                                <Field
                                                    name="enviar_correo"
                                                    component={renderSwitch}
                                                    type="text"
                                                    className="form-control"
                                                    disabled={
                                                        venta_costo
                                                            ? venta_costo
                                                            : false
                                                    }
                                                />
                                                <label className="ml-2">
                                                    Si
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                        <label htmlFor="correo">Correo</label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{ maxWidth: "650px" }}
                                        >
                                            <Field
                                                name="correo"
                                                rows={2}
                                                component={renderField}
                                                className="form-control"
                                                disabled={
                                                    enviar_correo ? true : false
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                        <label htmlFor="">Direccion</label>
                                        <Field
                                            name="direccion"
                                            component={renderSelectField}
                                            options={direcciones}
                                            placeholder="Seleccionar"
                                            value_assign={
                                                tipo_venta == credito
                                                    ? "direccion"
                                                    : "id"
                                            }
                                            label_assign={"direccion"}
                                            assigned_value={true}
                                            assigned_label={true}
                                            item_vacio={true}
                                            type="text"
                                            className="form-control"
                                            disabled={
                                                clienteValue ? false : true
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row px-1 py-2">
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group"></div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group"></div>
                                    {/* {tipo_venta == credito && (
                                            <div className="col-12 col-md-6 col-lg-6 form-group d-flex flex-column justify-content-end">
                                                <label htmlFor="fecha_limite">
                                                    Fecha límite
                                                </label>
                                                <div className="p-1">
                                                    <Field
                                                        name="fecha_limite"
                                                        component={renderDatePicker}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        )} */}
                                </div>
                            </form>
                        </React.Fragment>
                    )}
                </div>
                <div className="form-group mt d-flex">
                    <div className="w-100">
                        <div
                            className="slide-container row w-100"
                            style={{ margin: "0" }}
                        >
                            <div className={`col-lg-12 mb-2`}>
                                {/* region LISTADO DE PRODUCTOS*/}
                                {/* Vista Movil*/}
                                <div className="d-block d-lg-none">
                                    {!ver_cobro && (
                                        <React.Fragment>
                                            <ListadoCards
                                                buscar={buscar(props)}
                                                loader={loader}
                                                llaves={llavesListado}
                                                data={data}
                                                listar={listar}
                                                sortChange={sortChange}
                                                titulo={"Listado de Productos"}
                                                page={page}
                                                //acciones={dropdownItems}
                                                //onClickOtrasAcciones={
                                                //    onClickOtrasAcciones
                                                //}
                                                pushRouter={pushRouter}
                                                //itemsAyuda={dropdownItemsAyuda}
                                                //actionAyuda={onClickAyuda}
                                                agregar={agregar}
                                                cambioBuscador={cambioBuscador}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="d-none d-lg-block">
                                    {!ver_cobro && (
                                        <React.Fragment>
                                            <ListadoAccion
                                                buscar={buscar(props)}
                                                loader={loader}
                                                llaves={llavesListado}
                                                data={data}
                                                listar={listar}
                                                sortChange={sortChange}
                                                titulo={"Listado de Productos"}
                                                page={page}
                                                //acciones={dropdownItems}
                                                //onClickOtrasAcciones={
                                                //    onClickOtrasAcciones
                                                //}
                                                pushRouter={pushRouter}
                                                //itemsAyuda={dropdownItemsAyuda}
                                                //actionAyuda={onClickAyuda}
                                            />
                                        </React.Fragment>
                                    )}
                                </div>
                                {/* endregion LISTADO DE PRODUCTOS*/}

                                {/* region COBRO */}
                                {ver_cobro && (
                                    <Cobro
                                        {...props}
                                        print_state={print_state}
                                        cambioCobro={cambioCobro}
                                        loader={loader}
                                        ingresarValeDescuento={
                                            ingresarValeDescuento
                                        }
                                    />
                                )}
                                {/* endregion COBRO */}
                            </div>

                            <div className={`col-md-12 `}>
                                {/*{customer && (   )}*/}
                                <div>
                                    {/*/<DatosVenta {...props} />*/}

                                    {/*<DetalleAccion
                                        data={seleccionados}
                                        titulo={"Lista de Productos"}
                                        llaves={llavesDetalle}
                                        loader={false}
                                        //agregar={setVerBuscador}
                                        quitar={quitar}
                                    />*/}
                                    <DetalleAccion
                                        data={seleccionados}
                                        titulo={"Lista de Productos"}
                                        llaves={llavesDetalle}
                                        loader={loader}
                                        agregar={agregar}
                                        quitar={quitar}
                                        producto_form={true}
                                        ov_field={true}
                                        cliente_id={customer}
                                        //tipo_origen={tipo_origen}
                                        producto_actual={producto_actual}
                                        setProductoActual={setProductoActual}
                                    />
                                </div>

                                {/* -------------------------------------------------- */}

                                <div
                                    style={{
                                        borderRadius: "0",
                                        padding: "4px 2px 2px 2px",
                                        borderTop: "none",
                                    }}
                                    className="grid-container bg-primary mt0 d-flex flex-column flex-md-row justify-content-between"
                                >
                                    <div
                                        className="d-flex justify-content-around align-items-end p-1 p-md-2 flex-column flex-1"
                                        style={{ backgroundColor: "#FFF" }}
                                    >
                                        <div className="text-right">
                                            <div>Monto total</div>
                                            <h5>
                                                <b>
                                                    <RenderCurrency
                                                        value={total}
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-around align-items-center p-1 p-md-2 flex-column flex-3"
                                        style={{ backgroundColor: "#FFF" }}
                                    >
                                        <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                            {/* <button
                                    type="button"
                                    className="btn btn-tertiary mr-1"
                                    onClick={() => {
                                        // limpiarDatosOC();
                                        goBack();
                                    }}
                                >
                                    {" "}
                                    <img src={icons.cancelar} alt="" /> Cancelar
                                </button> */}
                                            <Link
                                                className="btn btn-tertiary mr-1 align-self-center"
                                                to="/ordenes_de_venta"
                                            >
                                                Cancelar
                                            </Link>
                                            <button
                                                className="btn btn-primary ml-1"
                                                type="submit"
                                                disabled={
                                                    validacion_limite_venta_cf
                                                }
                                            >
                                                {/* <i className="fa fa-check mr" /> */}
                                                Siguiente
                                            </button>
                                        </div>
                                        {validacion_limite_venta_cf && (
                                            <span className="text-danger">
                                                No se puede facturar con el nit
                                                CF una venta mayor a Q2500,
                                                cambie los datos del cliente
                                                para continuar
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* --------------------------------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

let DatosGenerales = reduxForm({
    form: "wizardCrearOVForm", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(Form);

const selector = formValueSelector("wizardCrearOVForm");
DatosGenerales = connect((state) => {
    const data_form = state.form.wizardCrearOVForm;
    const clienteValue = selector(state, "cliente");
    const fecha = moment().format("YYYY-MM-DD");
    const customer = selector(state, "cliente_id");

    return {
        clienteValue,
        data_form,
        initialValues: {
            fecha: fecha,
            // tipo_venta: 40000,
        },
        customer,
    };
})(DatosGenerales);

export default DatosGenerales;
