import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { Async } from "react-select";
import { api } from "api";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment/moment";

class DetalleVenta extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            fracciones: [],
            fraccion: null,
        };
    }

    onChangeSucursal = (sucursal) => {
        this.props.setSucursalGeneral(sucursal);
    };

    render() {
        const {
            loader,
            sucursal_general,
            data_general,
            fecha_inicial_general,
            fecha_final_general,
            estado_descarga,
        } = this.props;
        const options = _.cloneDeep(tableOptions);

        //  Bind
        const { descargarListadoGeneral } = this.props;
        const getSucursales = (search) => {
            return api
                .get("sucursales", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page_general;
        options.onPageChange = this.props.listarGeneral;
        options.onSortChange = this.props.sortChangeGeneral;

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="mb-1 mt-1">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <h3>
                                        <b>Historial general de inventario</b>
                                    </h3>
                                    <div
                                        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                        style={{ flex: "3" }}
                                    >
                                        {" "}
                                        &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Inicio</label>
                                            <DatePicker
                                                value={fecha_inicial_general}
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    this.props.setFechaGeneral(
                                                        "Inicial",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Fin</label>
                                            <DatePicker
                                                value={fecha_final_general}
                                                minDate={
                                                    new Date(
                                                        moment(
                                                            fecha_inicial_general,
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    this.props.setFechaGeneral(
                                                        "Final",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Sucursal</label>
                                            <Async
                                                className="form-control"
                                                placeholder="Seleccionar sucursal"
                                                onChange={this.onChangeSucursal}
                                                searchPromptText="Escriba para buscar"
                                                valueKey={"id"}
                                                labelKey={"nombre"}
                                                loadOptions={getSucursales}
                                                value={this.props.sucursal_general}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {sucursal_general ? (
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="grid-container">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <div
                                        className="d-flex flex-column flex-md-row flex-lg-row justify-content-between mb-2"
                                        style={{ flex: "1" }}
                                    >
                                        <h3>
                                            <b>Listado</b>
                                        </h3>
                                        <button
                                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                            disabled={estado_descarga}
                                            onClick={() => {
                                                descargarListadoGeneral();
                                            }}
                                        >
                                            <em
                                                className={`fa ${
                                                    estado_descarga
                                                        ? "fa-spinner fa-pulse"
                                                        : "fa-download"
                                                }`}
                                            ></em>
                                            &nbsp;Descargar Listado
                                        </button>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-end mt-3">
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total entrada:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data_general.total_entrada
                                                                ? data_general.total_entrada
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total salida:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data_general.total_salida
                                                                ? data_general.total_salida
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total PC:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data_general.total_pc
                                                                ? data_general.total_pc
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total PV:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data_general.total_pv
                                                                ? data_general.total_pv
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total PC sin IVA:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data_general.total_pc_sin_iva
                                                                ? data_general.total_pc_sin_iva
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total importe:
                                                <br/>
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data_general.total_importe
                                                                ? data_general.total_importe
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-end mt-3">
                                        <div className="col-12 col-md-3 col-lg-2">
                                            <h5 className="text-right">
                                                Saldo inicial:
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data_general.saldo_inicial
                                                                ? data_general.saldo_inicial
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <LoadMask loading={loader} light blur>
                                        {/* <GraficaMovimiento/> */}
                                        <BootstrapTable
                                            data={loader ? [] : data_general.results}
                                            remote
                                            pagination
                                            hover
                                            fetchInfo={{
                                                dataTotalSize: data_general ? data_general.count : 0,
                                            }}
                                            options={options}
                                        >
                                            <TableHeaderColumn
                                                dataSort
                                                isKey
                                                dataField="creado"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderDateTime
                                                            fecha={cell}
                                                        />
                                                    );
                                                }}
                                            >
                                                Fecha
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField="fraccion__producto__nombre"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Producto
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="entrada"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderNumero
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Entrada
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="salida"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderNumero
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Salida
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField="motivo"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Movimiento
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pc"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PC
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pv"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PV
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pc_sin_iva"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PC sin IVA
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="importe"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Importe
                                            </TableHeaderColumn>
                                            {/* <TableHeaderColumn
                                                dataSort
                                                dataField="existencia_actual"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Cuanto habia
                                            </TableHeaderColumn> */}
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="existencia_final"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderNumero
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Saldo
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default DetalleVenta;
