import React, { useEffect } from "react";
import {
    RenderDateTime,
    RenderCurrency,
    RenderDate
} from "Utils/renderField/renderReadField";

import "./recibo.css";

const ReciboPago = ({
    movimiento,
    sucursal,
    utiliza_fel,
    tipo,
    documento_pago_multiple,
    enReciborPorPagar = false,
}) => {
    let datos_empresa = sucursal.datos_empresa;
    let total = 0;

    return (
        <div
            className={`print-shadow ${
                tipo == "Venta" ? "vista-recibo-80mm" : ""
            }`}
        >
            {/* //*** HEADER */}
            <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                <div className="w-100 text-center">
                    <div className="w-100 text-center">
                        <img
                            src={require("../../../../../assets/img/remocar-logo.png")}
                            width="25%"
                        />
                    </div>
                    <b className="text-uppercase">
                        {datos_empresa && datos_empresa.nombre}
                    </b>
                </div>
                <div className="w-100 text-center">
                    <b className="text-uppercase">{sucursal.nombre}</b>
                </div>
                <div className="w-100 text-center text-uppercase">
                    {sucursal.direccion}
                </div>
                {(sucursal.telefono || sucursal.telefono != "") && (
                    <div className="w-100 text-center">
                        Tel. {sucursal.telefono}
                    </div>
                )}
                {utiliza_fel && (
                    <div className="w-100 text-center">
                        <div className="w-100 text-center">
                            Nit. {datos_empresa && datos_empresa.nit}
                        </div>
                    </div>
                )}
            </div>
            <div className="d-flex justify-content-between">
                <div className="flex-1">
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Fecha del pago</b>
                            {Object.keys(movimiento).length > 0 &&
                                <RenderDate value={movimiento[0].fecha} />}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>
                                {enReciborPorPagar
                                    ? "Fecha de la venta"
                                    : "Fecha de la compra"}
                            </b>
                            {Object.keys(movimiento).length > 0 &&
                                movimiento[0].fecha_compra}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>{enReciborPorPagar ? "Proveedor" : "Cliente"}</b>
                            {Object.keys(movimiento).length > 0 &&
                                movimiento[0].cliente_proveedor &&
                                movimiento[0].cliente_proveedor.nombre}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Usuario</b>
                            {Object.keys(movimiento).length > 0 &&
                                movimiento[0].vendedor &&
                                movimiento[0].vendedor}
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Código documento</b>
                            {(Object.keys(movimiento).length > 0 &&
                                movimiento[0].num_documento) ||
                                "---"}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>No Documento</b>
                            {(Object.keys(movimiento).length > 0 &&
                                movimiento[0].codigo) ||
                                "---"}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Observacíon</b>
                            {(Object.keys(movimiento).length > 0 &&
                                movimiento[0].info_pago) ||
                                "---"}
                        </div>
                    </div>
                </div>
            </div>
            {/* //*** FIN HEADER */}

            {/* //*** BODY */}
            <div className="grid-recibo mt-4 fnt-11">
                <b>Código de {enReciborPorPagar ? "compra" : "venta"}</b>
                <b>Código de pago</b>
                <b>Total</b>
                <b>Pago</b>
                <b>Abono</b>
                <b className="align-end">Saldo</b>
                {Object.keys(movimiento).length > 0 &&
                    movimiento.map((detalle, index) => {
                        total += detalle.pago;
                        return (
                            <React.Fragment key={index}>
                                {/* //*** CODIGO DE VENTA  */}
                                <span>
                                    {enReciborPorPagar
                                        ? movimiento[index].codigo_compra
                                        : movimiento[index].codigo_venta}
                                </span>

                                {/* //*** CODIGO DE PAGO  */}
                                <span>
                                    {detalle.cuenta_cobrar_pagar &&
                                    detalle.cuenta_cobrar_pagar.orden_compra
                                        ? detalle.cuenta_cobrar_pagar
                                              .orden_compra.numero_documento
                                        : detalle.codigo}
                                </span>

                                {/* //*** TOTAL  */}
                                <RenderCurrency
                                    value={detalle.total}
                                    className={"mr-auto"}
                                />

                                {/* //*** MONTO  */}
                                <RenderCurrency
                                    value={
                                        documento_pago_multiple
                                            ? detalle.total
                                            : detalle.pago
                                    }
                                    className={"mr-auto"}
                                />

                                {/* //*** ABONO  */}
                                <RenderCurrency
                                    value={detalle.abono}
                                    className={"mr-auto"}
                                />

                                {/* //*** SALDO  */}
                                <RenderCurrency
                                    value={
                                        documento_pago_multiple
                                            ? detalle.saldo
                                            : detalle.total - detalle.abono
                                    }
                                    className={"ml-auto"}
                                />
                            </React.Fragment>
                        );
                    })}
            </div>
            {/* //*** FIN BODY */}

            {/* //*** FOOTER */}
            {/* {!documento_pago_multiple && ( */}
            <div
                className="d-flex flex-row justify-content-between fnt-16"
                style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
            >
                <b>TOTAL PAGADO</b>
                <b>
                    <RenderCurrency value={total} className={"ml-auto"} />
                </b>
            </div>
            {/* )} */}
            {/* //*** FIN FOOTER */}
        </div>
    );
};

export default ReciboPago;
