import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { validations } from "validations";
import ResetearForm from "./ResetearForm";
import "../login.css";
import logoRemocar from "../../../../../assets/img/remocar-logo.png";
class Resetear extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    restablecerPass = (data) => {
        this.props.restablecerPass({
            ...data,
            token: this.props.match.params.token,
        });
    };

    render() {
        if (localStorage.getItem("token")) {
            return <Redirect to="/dashboard" />;
        }
        return (
            <div className="fondo-login">
                <div className="login-wrapper">
                    {/* <div className="d-none d-md-block d-lg-none container-img-fondo">
              <img className="img-login" src={require('../../../../../assets/img/fondo-login-tablet.png')} />
          </div> */}
                    <div className="contenedor-form-login">
                        <div>
                            <img
                                alt=""
                                className="img-login"
                                src={logoRemocar}
                            />
                        </div>
                        <div className="container-controls">
                            <div id="login" className="panel-body">
                                {/* <p className="text-center pv">INGRESE SU USUARIO Y CONTRASEÑA.</p> */}
                                <ResetearForm
                                    onSubmit={this.restablecerPass}
                                    loader={this.props.loader}
                                />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Resetear;
