import React, { useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import {
    formatoEmpty,
    formatoAceptaTarjeta,
    formatoMonedaQuetzales,
} from "Utils/CamposComunes";
import { TIPO_MOVIMIENTO } from "../../../../../utility/variables";

const tempData = {
    count: 2,
    next: null,
    previous: null,
    results: [
        {
            id: 1,
            fecha: "22/06/2022",
            numero_cuenta: "4345345234523",
            banco: "Banco SA",
            codigo: "DAF92D",
            tipo_movimiento: "Boleta de depósito",
            vendedor: "Fulanito",
            monto: 100.33,
        },
        {
            id: 1,
            fecha: "22/06/2022",
            numero_cuenta: "4345345234523",
            banco: "Banco SA",
            codigo: "DAF92D",
            tipo_movimiento: "Boleta de depósito",
            vendedor: "Juanito",
            monto: 1945.43,
        },
    ],
};

const ListadoBancos = ({
    listar,
    page,
    data,
    loader = false,
    sortChange,
    eliminar,
    buscar,
    search,
}) => {
    useEffect(() => {
        listar(page);
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Link
                        className="btn btn-primary mb-sm-10"
                        to="/ingreso_documentos/crear/"
                    >
                        <em className="fa fa-plus"></em> Agregar Documento
                    </Link>

                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Documentos"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={!data.results ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="codigo_documento"
                                    dataSort
                                    // dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha"
                                    dataSort
                                    // dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="banco"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.nombre_banco;
                                        }
                                        return "---";
                                    }}
                                    // width={"20rem"}
                                >
                                    Banco
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="banco"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.numero_cuenta;
                                        }
                                        return "---";
                                    }}
                                    // width={"20rem"}
                                >
                                    No. Cuenta
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo_movimiento"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return TIPO_MOVIMIENTO[cell][
                                                "label"
                                            ];
                                        }
                                        return "---";
                                    }}
                                    // width={"20rem"}
                                >
                                    Tipo movimiento
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="vendedor"
                                    dataSort
                                    dataFormat={(cell) => {
                                        if (cell) {
                                            return cell.first_name;
                                        }
                                        return "---";
                                    }}
                                    // dataFormat={formatoMonedaQuetzales}
                                    // width={"20rem"}
                                >
                                    Vendedores
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="monto"
                                    dataSort
                                    dataFormat={formatoMonedaQuetzales}
                                    // width={"20rem"}
                                >
                                    Monto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={activeFormatter({
                                        // ver: "/bancos/detalle",
                                        editar: "/ingreso_documentos/editar",
                                        eliminar,
                                        // adicional: formatoVistaImprimir,
                                    })}
                                    width={"10rem"}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListadoBancos;
