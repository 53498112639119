import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda, RenderDate } from "Utils/renderField/renderTableField";

const ProductosSubTabla = (row) => {
    return (
        <div className="border border-primary bg-blanco">
            <BootstrapTable
                data={row.creditos}
                remote={false}
                className="react-bs-table-container-creditos"
            >
                <TableHeaderColumn
                    dataField="credito_sucursal"
                    dataSort={false}
                    dataAlign="left"
                    width="20%"
                >
                    Sucursal
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="limite_credito"
                    dataSort={false}
                    isKey
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                >
                    Limite
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="credito_utilizado"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                >
                    Utilizado
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="credito_utilizado"
                    dataFormat={(cell, row) => {
                        return "Q " + (row.limite_credito - cell);
                    }}
                    dataSort={false}
                    dataAlign="left"
                >
                    Disponible
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="dias_credito"
                    dataSort={false}
                    dataAlign="left"
                    dataFormat={(cell, row) => {
                        return row.dias_credito + " Dias";
                    }}
                >
                    Validez
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="dias_tomados"
                    dataSort={false}
                    dataAlign="left"
                    dataFormat={(cell, row) => {
                        return row.dias_tomados + " Dias";
                    }}
                >
                    Tomados
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="dias_credito"
                    dataFormat={(cell, row) => {
                        const dias_tomados =
                            row.dias_tomados < 0
                                ? row.dias_tomados * -1
                                : row.dias_tomados;
                        return cell - dias_tomados + " Dias";
                    }}
                    dataSort={false}
                    dataAlign="left"
                >
                    Restantes
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="primer_credito"
                    dataSort={false}
                    dataAlign="left"
                    dataFormat={cell => cell === 0 ? cell : <RenderDate fecha={cell} />}
                >
                    Primer Credito
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default ProductosSubTabla;
