import React from "react";
import { api } from "api";
import { icons } from "icons";
import { Field, reduxForm } from "redux-form";
import { renderField, renderSearchSelect } from "Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values, props) => {
    const errors = {};
    if (!values.nit) errors.nit = "Campo requerido";
    if (!values.nombre) errors.nombre = "Campo requerido";

    if (!props.es_cotizacion) if (!values.caja) errors.caja = "Campo requerido";

    if (props.es_cotizacion) {
        if (values.correo) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!values.correo.match(mailformat)) {
                errors.correo = "Correo inválido";
            }
        }
    }
    return errors;
};

const listadoCajas = [];

const Form = (props) => {
    const {
        handleSubmit,
        close,
        leerCliente,
        changeDatosClientes,
        es_cotizacion,
        loader,
        loader_cliente,
    } = props;

    const change = (e) => {
        const value = e.target.value;

        // buscar el nit solo cuando tiene al menos 5 letras
        if (value.length > 5) {
            leerCliente(value);
        }
        changeDatosClientes("nit", value);
    };

    const getCajas = (search) => {
        return api
            .get("cajas/?solo_esta_sucursal=true", { search })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listadoCajas, { id: item.id }))
                        listadoCajas.push(item);
                });
                return { options: listadoCajas };
            });
    };

    return (
        <form onSubmit={handleSubmit} className="col-sm-12 pt-2">
            <div className="form-group">
                <div className="row">
                    {!es_cotizacion && (
                        <div className="col-12 form-group">
                            <label htmlFor="caja">Caja</label>
                            <Field
                                name="caja"
                                component={renderSearchSelect}
                                loadOptions={getCajas}
                                labelKey="nombre"
                                valueKey="id"
                                className="form-control"
                            />
                        </div>
                    )}
                    <div className="col-12 form-group">
                        <label htmlFor="nit">Nit</label>
                        <Field
                            name="nit"
                            label="Nit"
                            component={renderField}
                            type="text"
                            className="form-control"
                            onChange={change}
                        />
                    </div>
                    <div className="col-12 form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                            name="nombre"
                            label="Nombre del cliente"
                            component={renderField}
                            type="text"
                            className="form-control"
                            autoFocus={true}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("nombre", value);
                            }}
                        />
                    </div>
                    {es_cotizacion && (
                        <div className="col-12 form-group">
                            <label htmlFor="correo">Enviar cotización a</label>
                            <Field
                                name="correo"
                                label="Correo del cliente"
                                component={renderField}
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    changeDatosClientes("correo", value);
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-center flex-row">
                    <button
                        type="button"
                        className="btn btn-tertiary m-1 align-self-center"
                        onClick={(e) => {
                            e.stopPropagation();
                            close({});
                        }}
                        disabled={loader_cliente}
                    >
                        <img src={icons.cancelar} alt="" />
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary m-1 align-self-center"
                        disabled={loader || loader_cliente}
                    >
                        <img src={icons.save} alt="" />
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    );
};

const ClienteCotizacionForm = reduxForm({
    // a unique name for the form
    form: "ClienteCotizacionForm",
    validate,
})(Form);

export default ClienteCotizacionForm;
