import { handleActions } from "redux-actions";

import { api } from "api";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
export const MOVIMIENTO = "MOVIMIENTOS_MOVIMIENTO";
export const MOVIMIENTOS = "MOVIMIENTOS_MOVIMIENTOS";
export const PAGE = "MOVIMIENTOS_PAGE";
export const BUSCADOR = "MOVIMIENTOS_BUSCADOR";
export const SORT = "MOVIMIENTOS_SORT";
export const LOADER = "MOVIMIENTOS_LOADER";
export const FECHA_INICIAL = "MOVIMIENTOS_FECHA_INICIAL";
export const FECHA_FINAL = "MOVIMIENTOS_FECHA_FINAL";
export const TIPO = "MOVIMIENTOS_TIPO";
export const CLIENTE = "MOVIMIENTOS_CLIENTE";
export const PROVEEDOR = "MOVIMIENTOS_PROVEEDOR";
export const USUARIO = "MOVIMIENTOS_USUARIO";

export const constants = {};

const endpoint = "movimientos";
// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const listado_movimientos = store.listado_movimientos;
        const {
            ordering,
            search,
            fecha_final,
            fecha_inicial,
            tipo,
            cliente,
            proveedor,
            usuario,
        } = listado_movimientos;

        api.get(endpoint, {
            page,
            ordering,
            search,
            listado_movimientos: 1,
            fecha_final,
            fecha_inicial,
            tipo,
            cliente,
            proveedor,
            usuario,
        })
            .then((data) => {
                if (data) {
                    dispatch(setBancos(data));
                }
                dispatch(setPage(page));
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.listado_movimientos.page;
    dispatch(listar(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const listado_movimientos = store.listado_movimientos;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(listado_movimientos.fecha_final);
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(listado_movimientos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const setTipo = (tipo) => (dispatch) => {
    dispatch(_setTipo(tipo));
    dispatch(listar());
};

export const setCliente = (cliente) => (dispatch) => {
    dispatch(_setCliente(cliente));
    dispatch(listar());
};

export const setProveedor = (proveedor) => (dispatch) => {
    dispatch(_setProveedor(proveedor));
    dispatch(listar());
};

export const setUsuario = (usuario) => (dispatch) => {
    dispatch(_setUsuario(usuario));
    dispatch(listar());
};

// Limipiar el estado de redux
export const limpiar = () => (dispatch) => {
    dispatch(setBuscador(""));
    dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
    dispatch(setTipo(""));
    dispatch(setCliente(""));
    dispatch(setProveedor(""));
    dispatch(setUsuario(""));
    dispatch(listar());
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setBancos = (data) => ({
    type: MOVIMIENTO,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setBancoLeer = (bancos) => ({
    type: MOVIMIENTOS,
    bancos,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const _setTipo = (tipo) => ({
    type: TIPO,
    tipo,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const _setProveedor = (proveedor) => ({
    type: PROVEEDOR,
    proveedor,
});

export const _setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    setFecha,
    setTipo,
    setCliente,
    setProveedor,
    setUsuario,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [MOVIMIENTO]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MOVIMIENTOS]: (state, { bancos }) => {
        return {
            ...state,
            bancos,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [TIPO]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [PROVEEDOR]: (state, { proveedor }) => {
        return {
            ...state,
            proveedor,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    bancos: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    tipo: "",
    cliente: "",
    proveedor: "",
    usuario: "",
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
};

export default handleActions(reducers, initialState);
