import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
//import TituloBorde from "../../../Utils/TituloBorde";
import FamiliasForm from "./FamiliasForm";

//import useCrearFamilia from "../../../../hooks/useCrearFamilia";
//import { NotificationManager } from "react-notifications";

const CrearActualizar = ({
    crear,
    leer,
    item,
    editar,
    loader,
    removerItem,
    empresa,
}) => {
    const { id } = useParams();

    const [subFamilias, setSubFamilia] = useState({
        familia: [],
        id_familia_eliminar: [],
        nuevas_familias: [],
    });

    const {
        familia,
        id_familia_eliminar: eliminar,
        nuevas_familias: nuevo,
    } = subFamilias;

    useEffect(() => {
        // si se esta editando o viendo el registro, cargar los datos para llenar el formulario (item, variable que contiene los datos)
        if (id) leer(id);

        return () => {
            removerItem();
        };
    }, []);

    useEffect(() => {
        // Si se esta editando el dato, existe los contactos registrados, y setearlos al array subFamilias de Familias
        if (id && item.sub_familias && item.sub_familias.length > 0) {
            setSubFamilia({
                ...familia,
                familia: item.sub_familias,
                id_familia_eliminar: [],
                nuevas_familias: [],
            });
        } else if (!id) {
            setSubFamilia({ ...familia, familia: [] });
        }
    }, [item]);

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };

        // Si es actualizar, retornar editar para que crear no se ejecute
        if (body.usuario) body.usuario = body.usuario.id;

        //console.log("LLego 2");
        // *** Datos de contactos, al editar o agregar un proveedor
        // Si el array de contactos, no esta vacio, agregar los contactos al body
        if (familia && familia.length > 0) body.familia = familia;
        //
        // Si el array de eliminar, no esta vacio, agregar eliminar al body
        if (eliminar && eliminar.length > 0) body.eliminar = eliminar;
        //
        // Si el array de nuevo, no esta vacio, agregar nuevo al body
        if (nuevo && nuevo.length > 0) body.nuevo = nuevo;

        // Obrener el id de la empresa del usuario logueado
        if (empresa) body.empresa = empresa;
        //
        //console.log("LLego 3");

        if (id) editar(id, body);
        else crear(body);
    };

    return (
        <React.Fragment>
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="grid-container mt0 mb-3">
                        <LoadMask loading={loader} dark blur>
                            <div className="grid-titulo padding-15">
                                {id ? "Editar Familia" : "Nueva Familia"}
                            </div>
                            <div className="grid-cuerpo padding-15 p-sm-0 pt-sm-1 pb-sm-1 mt">
                                <FamiliasForm
                                    subFamilias={subFamilias}
                                    setSubFamilia={setSubFamilia}
                                    onSubmit={onSubmit}
                                    update={id ? true : false}
                                    data={item}
                                />
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CrearActualizar;
