import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "Utils/renderField/renderField";

const validate = (values) => {
    const errors = {};
    if (values.nit) {
        let nit = values.nit;
        if (!nit) {
            return true;
        }

        var nitRegExp = new RegExp("^[0-9]+(-?[0-9kK])?$");

        if (!nitRegExp.test(nit)) {
            if (nit == "CF" || nit == "cf") {
                return true;
            } else {
                errors.nit = "Nit invalido";
            }
        }

        nit = nit.replace(/-/, "");
        var lastChar = nit.length - 1;
        var number = nit.substring(0, lastChar);
        var expectedCheker = nit
            .substring(lastChar, lastChar + 1)
            .toLowerCase();

        var factor = number.length + 1;
        var total = 0;

        for (var i = 0; i < number.length; i++) {
            var character = number.substring(i, i + 1);
            var digit = parseInt(character, 10);

            total += digit * factor;
            factor = factor - 1;
        }

        var modulus = (11 - (total % 11)) % 11;
        var computedChecker = modulus == 10 ? "k" : modulus.toString();
        if (expectedCheker !== computedChecker) {
            errors.nit = "Nit invalido";
        }
    }
    return errors;
};

const Form = (props) => {
    const {
        leerCliente,
        changeDatosClientes,
        disabled,
        estado_movimiento,
        conf_mostrar_referencia,
    } = props;
    const change = (e) => {
        const value = e.target.value;
        // buscar el nit solo cuando tiene al menos 5 letras
        if (value.length > 5) {
            leerCliente(value);
        }
        if(props.utiliza_fel && props.conf_habilitar_campo_cliente){
            if(value === "CF" | value === "cf"){
                console.log("entra aqui")
                props.setEstadoFormCliente(false)
            }
            else{
                props.setEstadoFormCliente(true)
            }
        }
        changeDatosClientes("nit", value);
    };
    return (
        <form className="mt1">
            <div className="form-group ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        {/*<label htmlFor="nit">Nit</label>*/}
                        <Field
                            name="nit"
                            label="Ingrese un nit válido o CF"
                            component={renderField}
                            type="text"
                            className="form-control"
                            onChange={change}
                            disabled={estado_movimiento}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        {/*<label htmlFor="nombre">Nombre completo</label>*/}
                        <Field
                            name="nombre"
                            label="Nombre del cliente"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={disabled}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("nombre", value);
                            }}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        {/*<label htmlFor="direccion">Dirección</label>*/}
                        <Field
                            name="direccion"
                            label="Dirección"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("direccion", value);
                            }}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        {/*<label htmlFor="telefono">Teléfono</label>*/}
                        <Field
                            name="telefono"
                            label="Telefono"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("telefono", value);
                            }}
                        />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        {/*<label htmlFor="extra">Notas</label>*/}
                        <Field
                            name="extra"
                            label="Notas"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("notas", value);
                            }}
                        />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="direccion">Referencia</label>
                        <Field
                            name="referencia"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                        />
                    </div>
                    {conf_mostrar_referencia==true ?
                                 <div className="col-md-4 form-group">
                                 <label htmlFor="mostrar_referencia">
                                     Imprimir referencia
                                 </label>
                                 <br />
                                 <label className="switch">
                                     <Field
                                         name="mostrar_referencia"
                                         component="input"
                                         type="checkbox"
                                     />
                                     <span className="slider round" />
                                 </label>
                             </div>
                    :""
                        }
                </div>
            </div>
        </form>
    );
};

const ClienteVentaForm = reduxForm({
    // a unique name for the form
    form: "ClienteVentaForm",
    validate,
})(Form);

export default ClienteVentaForm;
