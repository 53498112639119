import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import ToolBarProducts from "./ToolbarProducts";
import {
    RenderImage,
    RenderDateTime,
    RenderMoneda,
    RenderNumber,
} from "Utils/renderField/renderTableField";
import { icons } from "icons";
import LoadMask from "Utils/LoadMask/LoadMask";

const BSTable = () => {
    return <p>?</p>;
};

import editar_icono from "../../../../../../assets/img/iconos/editar-icono.svg";
import ver_icono from "../../../../../../assets/img/icons/ver.png";

const TableProducts = ({
    props = {},
    loader = false,
    buscar,
    search,
    page,
    data = [],
    listarProductos,
    eliminar,
    sortChange,
    descargarListado,
    closeModalProducts,
    leerProducto,

    selected,
    setSelected,
    sucursales,
    sucursal,
    empresa,
    permisos_producto,
    oficina,
    setOficina,
}) => {
    // const [oficina, setOficina] = useState(null);
    /*const formatoAvatar = (cell, row) => {
        if (cell !== "") {
            return (
                <img
                    className="imagen-sm"
                    src={cell}
                    alt=""
                    style={{ width: "28px", height: "28px" }}
                />
            );
        } else {
            return (
                <img
                    className="imagen-sm"
                    src={icons.img_default}
                    alt=""
                    style={{ width: "28px", height: "28px" }}
                />
            );
        }
    }; */

    // local
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listarProductos,
        onSortChange: sortChange,
        page,
    };

    useEffect(() => {
        let oficina_var = _.find(sucursales, { id: sucursal });
        if (!oficina_var) {
            oficina_var = {};
        }
        sucursales.forEach((item, index) => {
            item.index = index;
            item.sucursal_activa = oficina_var.id;
        });

        setOficina(oficina_var);
    }, [sucursales, sucursal]);

    useEffect(() => {
        //     if (oficina) {
        //         if (oficina.id) listarProductos(page, oficina.id);
        //         else listarProductos(page, oficina);
        //     }
        listarProductos(1);
    }, [oficina]);

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                    {permisos_producto && (
                        <div className="d-flex justify-content-end">
                            <a
                                className="btn btn-primary mb-sm-10 mt1"
                                target="_blank"
                                href="/#/productos/crear/"
                            >
                                <em className="fa fa-plus"></em> Agregar
                                Producto
                            </a>
                        </div>
                    )}
                    <div className="">
                        <div className="grid-titulo">
                            <ToolBarProducts
                                oficina={oficina}
                                setOficina={setOficina}
                                sucursales={sucursales}
                                sucursal={sucursal}
                                empresa={empresa}
                                titulo={"Buscar producto"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            striped
                            hover
                            remote
                            pagination
                        >
                            <TableHeaderColumn
                                dataField="nombre"
                                dataFormat={(cell, row) => {
                                    return (
                                        <RenderImage
                                            src={row.producto.img_cropped}
                                            alt={cell}
                                        />
                                    );
                                }}
                                dataAlign="center"
                            >
                                Img
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="codigo"
                                dataSort
                                dataFormat={(cell, row) => {
                                    try {
                                        return ` ${row.producto.codigo}`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Codigo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="codigo_equivalente_1"
                                dataSort
                                dataFormat={(cell, row) => {
                                    try {
                                        return ` ${
                                            row.producto.codigo_equivalente_1 ||
                                            "---"
                                        }`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Equivalente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                                tdStyle={{ whiteSpace: "normal" }}
                                dataFormat={(cell, row) => {
                                    try {
                                        return ` ${row.producto.nombre}`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Nombre
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="costo_dolares"
                                dataSort
                                dataFormat={(cell, row) => {
                                    try {
                                        return `$. ${row.costo_dolares.toFixed(
                                            2
                                        )}`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Costo $
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="costo_quetzales"
                                dataSort
                                dataFormat={(cell, row) => {
                                    try {
                                        return `Q. ${row.costo.toFixed(2)}`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Costo Q
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="precio_mostrador_calculado"
                                dataSort
                                dataFormat={(cell, row) => {
                                    try {
                                        return `Q. ${cell.toFixed(2)}`;
                                    } catch (error) {
                                        return "---";
                                    }
                                }}
                            >
                                Precio mostrador
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="entradas_totales"
                                dataSort
                                dataFormat={(cell, row) => {
                                    if (cell) {
                                        return cell;
                                    }
                                    return 0;
                                }}
                                dataAlign="right"
                            >
                                Entrada
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="salidas_totales"
                                dataSort
                                dataFormat={(cell, row) => {
                                    if (cell) {
                                        return cell;
                                    }
                                    return 0;
                                }}
                                dataAlign="right"
                            >
                                Salida
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                // width="9%"
                                dataField="inventario"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div
                                            className={
                                                row.minimo_existencias !==
                                                    null &&
                                                row.minimo_existencias >= cell
                                                    ? "rojo"
                                                    : ""
                                            }
                                        >
                                            {row.minimo_existencias !== null &&
                                                row.minimo_existencias >=
                                                    cell && (
                                                    <i className="fa fa-exclamation-circle" />
                                                )}
                                            &nbsp;
                                            <label className="font-weight-bold font-p">
                                                {cell}
                                            </label>
                                        </div>
                                    );
                                }}
                                dataAlign="right"
                            >
                                Inventario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                width="20%"
                                dataFormat={(cell, row) => {
                                    return (
                                        <React.Fragment>
                                            {/* <img
                                                onClick={() => {
                                                    setSelected({
                                                        view: "DETAIL",
                                                        product:
                                                            row.producto.id,
                                                    });
                                                }}
                                                className="action-img"
                                                title="Ver"
                                                src={require("../../../../../../assets/img/icons/ver.png")}
                                                alt="Ver"
                                                width="17"
                                            /> */}
                                            <Link
                                                // onClick={this.editar(id)}
                                                to={`/busqueda_general/detalle/${row.producto.id}`}
                                                // target="_blank"
                                                onClick={() => {
                                                    closeModalProducts();
                                                    leerProducto(
                                                        row.producto.id
                                                    );
                                                }}
                                            >
                                                <img
                                                    // onClick={this.editar(id)}
                                                    className="action-img"
                                                    title="Editar"
                                                    src={ver_icono}
                                                    alt="Editar"
                                                    width="17"
                                                />
                                            </Link>
                                        </React.Fragment>
                                    );
                                }}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export default TableProducts;
