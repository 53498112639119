import React, { useEffect, useState } from "react";
// import { Async } from "react-select";
import { RenderMoneda } from "Utils/renderField/renderTableField";
// import useAsyncSearch from "../../../../hooks/useAsyncSearch";

let listado_facturas = [];
const Encabezado = ({
    factura,
    idFactura,
    // id_factura,

    //? ----------------------SELECT PARA SELECCIONAR FACTURAS----------------------
    // disabled_async = false,
    // factura_seleccionada,
    // setFacturaSeleccionada,
    // setIdFactura,
    //? ----------------------SELECT PARA SELECCIONAR FACTURAS----------------------
}) => {
    useEffect(() => {
        return () => {
            listado_facturas = [];
        };
    }, [idFactura]);
    //? ----------------------SELECT PARA SELECCIONAR FACTURAS----------------------
    // const [first, setfirst] = useState(true);
    // const { getOptions: getFacturas } = useAsyncSearch(
    //     "devoluciones/get_facturas_fel",
    //     listado_facturas,
    //     {
    //         id_venta: idFactura,
    //     },
    //     true
    // );

    // useEffect(() => {
    //     if (id_factura) {
    //         setfirst(false);
    //     }
    // }, []);
    //? ----------------------SELECT PARA SELECCIONAR FACTURAS----------------------

    return (
        <React.Fragment>
            <div className="d-flex flex-column mb-3 flex-sm-row justify-content-between">
                <div className="d-flex flex-column">
                    <h5 className="font-weight-bold">Código de venta</h5>
                    <div className="text-primary">
                        <h4>{factura.codigo}</h4>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <h5 className="font-weight-bold">Cliente</h5>
                    <div className="text-primary">{factura.cliente.nombre}</div>
                </div>
                <div className="d-flex flex-column">
                    <h5 className="font-weight-bold">Nit</h5>
                    <div className="text-primary">
                        {factura.cliente.nit
                            ? factura.cliente.nit
                            : "Consumidor Final"}
                    </div>
                </div>
                <div className="d-flex flex-column mr-5">
                    <h5 className="font-weight-bold">Total Venta</h5>
                    <div className="text-primary text-right">
                        <RenderMoneda
                            monto={
                                factura
                                    ? factura.movimiento.total_cobrar_pagar
                                    : 0
                            }
                        />
                    </div>
                </div>
            </div>

            {/* //? ----------------------SELECT PARA SELECCIONAR FACTURAS---------------------- */}
            {/* <div className="d-flex mb-4 ">
                <div className="flex-1">
                    <label>Factura</label>
                    <Async
                        className="form-control"
                        labelKey="label"
                        valueKey="index"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getFacturas}
                        placeholder={"Factura"}
                        disabled={disabled_async}
                        onChange={(e) => {
                            setFacturaSeleccionada(e ? e.index : "");
                            setIdFactura(e ? e.id : "");
                            setfirst(false);
                        }}
                        value={
                            first && listado_facturas
                                ? listado_facturas[0]
                                : factura_seleccionada
                        }
                    />
                </div>
                <div className="flex-1" />
                <div className="flex-1" />
                <div className="flex-1" />
            </div> */}
            {/* //? ----------------------SELECT PARA SELECCIONAR FACTURAS---------------------- */}
        </React.Fragment>
    );
};

export default Encabezado;
