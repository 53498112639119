import _ from "lodash";
import { icons } from "icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import Filtros from "../../Utils/Filtros";

//import TicketCotizacion from "Utils/TicketCotizacion";
//import { PrintContainer, PrintComponent } from "Utils/Print";

class ListadoCotizacion extends Component {
    static propTypes = {};

    componentWillMount() {
        this.props.listar();
    }

    componentWillUnmount() {
        this.props.limpiarVentaCotizacion();
    }

    render() {
        // State
        const {
            data,
            search,
            page,
            loader,
            reImprimirCotizacion,
            //print_state,
            //venta_cotizacion,
            // filtros
            fecha_inicial,
            fecha_final,
            vendedor,
            cliente,
            tipo_cotizacion,
        } = this.props;

        // Bind
        const {
            listar,
            buscar,
            cargarCotizacionPuntoVenta,
            eliminarCotizacion,
            escanearCodigoBarras,
            open,
            setOpen,

            // filtros
            setFechaFinal,
            setFechaInicial,
            setVendedor,
            setCliente,
            setTipoCotizacion,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        // options.onSortChange = sortChange;

        const formatoAcciones = (cell, row, setOpen) => {
            const styles = {
                maxWidth: "20px",
            };

            if (row.finalizado) {
                styles.cursor = "not-allowed";
            }
            return (
                <div>
                    {row.movimiento && (
                        <div className="d-flex flex-row align-items-center">
                            {/* <img onClick={() => {
                                    
                                }} style={{maxWidth: "20px"}} className="action-img" title="Leer código de barras" src={icons.codigo_barras} alt="codigo barras"/> */}

                            <img
                                onClick={() => {
                                    if (!row.finalizado) {
                                        cargarCotizacionPuntoVenta(row);
                                    }
                                }}
                                style={styles}
                                className="action-img"
                                title="Cargar cotización"
                                src={
                                    !row.finalizado
                                        ? icons.upload
                                        : icons.upload_disabled
                                }
                                alt="cargar"
                            />

                            <img
                                onClick={(e) => {
                                    e.preventDefault();
                                    // reImprimirCotizacion(cell);
                                    setOpen({
                                        ...open,
                                        open: true,
                                        printFunction: () =>
                                            reImprimirCotizacion(cell),
                                    });
                                    //seleccionImpresionOFEL(
                                    //    extra.data.id_venta,
                                    //    extra.data.tipo_venta,
                                    //    "impresionOVListaOV"
                                    //);
                                }}
                                className="action-img"
                                title="Reimprimir Cotizacion"
                                src={icons.print}
                                alt="Reimprimir Cotizacion"
                                style={{ maxWidth: "20px" }}
                            />
                            <img
                                onClick={() => {
                                    eliminarCotizacion(cell);
                                }}
                                style={{ maxWidth: "20px" }}
                                className="action-img"
                                title="Eliminar cotización"
                                src={icons.eliminar}
                                alt="eliminar"
                            />
                        </div>
                    )}
                </div>
            );
        };

        return (
            <div className="row">
                <div className="col-12">
                    <div className="">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Cotizaciones"}
                                buscar={buscar}
                                search={search}
                                agregarPrimer={escanearCodigoBarras}
                                autoFocus={true}
                            />
                        </div>
                        <div>
                            <Filtros
                                fecha_inicial={fecha_inicial}
                                fecha_final={fecha_final}
                                vendedor={vendedor}
                                cliente={cliente}
                                tipo_cotizacion={tipo_cotizacion}
                                setFechaInicial={setFechaInicial}
                                setFechaFinal={setFechaFinal}
                                setVendedor={setVendedor}
                                setCliente={setCliente}
                                setTipoCotizacion={setTipoCotizacion}
                                fitro_por_tipo={true}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="creado"
                                    dataFormat={(cell, row) => (
                                        <div
                                            className={
                                                row.finalizado
                                                    ? "text-underline text-gray"
                                                    : ""
                                            }
                                        >
                                            <RenderDateTime fecha={cell} />
                                        </div>
                                    )}
                                    dataSort
                                >
                                    Fecha
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="fecha_expiracion"
                                    dataFormat={(cell) => (
                                        <RenderDateTime fecha={cell} />
                                    )}
                                    dataSort
                                >
                                    Fecha expiración
                                </TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataField="codigo"
                                    dataFormat={(cell, row) => (
                                        <span
                                            className={
                                                row.finalizado
                                                    ? "text-underline text-gray"
                                                    : ""
                                            }
                                        >
                                            {cell}
                                        </span>
                                    )}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="movimiento"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <span
                                                className={
                                                    row.finalizado
                                                        ? "text-underline text-gray"
                                                        : ""
                                                }
                                            >
                                                {cell.cliente_proveedor
                                                    .nombre || "---"}
                                            </span>
                                        );
                                    }}
                                >
                                    Cliente
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="movimiento"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <span
                                                className={
                                                    row.finalizado
                                                        ? "text-underline text-gray"
                                                        : ""
                                                }
                                            >
                                                {cell.usuario.first_name ||
                                                    "---"}
                                            </span>
                                        );
                                    }}
                                >
                                    Vendedor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="total"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div
                                                className={
                                                    row.finalizado
                                                        ? "text-underline text-gray"
                                                        : ""
                                                }
                                            >
                                                <RenderMoneda monto={cell} />
                                            </div>
                                        );
                                    }}
                                    dataSort
                                    dataAlign="left"
                                >
                                    Total
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="left"
                                    dataFormat={(cell, row) =>
                                        formatoAcciones(cell, row, setOpen)
                                    }
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoCotizacion;
