import { connect } from "react-redux";
import ListadoOrdenCompra from "./ListadoOrdenCompra";
import { actions } from "../../../../redux/modules/cuenta_cobrar/cuenta_cobrar";

const ms2p = (state) => {
    return {
        ...state.cuenta_cobrar,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoOrdenCompra);
