import React from "react";
import { renderField, renderTextArea } from "Utils/renderField/renderField";

import { Link } from "react-router-dom";
import {
    composeValidators,
    email,
    NIT,
    required,
} from "../../../../utility/validation";
import { Form, Field } from "react-final-form";
import {
    renderNumber,
    renderNumberFormat,
    renderSelectField,
} from "../../Utils/renderField/renderField";
import { TIPO_PROVEEDOR } from "../../../../utility/variables";
import ContactosForm from "../Contactos";
import MultiUploadFile from "../../Utils/MultipleUploadFile/MultiUploadFile";

const FormRender = ({
    cancelar,
    onSubmit,
    initialValues = {},
    files,
    onChangeFile,
    onRemoveFile,
    onRemoveOldFile,
    oldFiles,
}) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="row mt">
                    <div
                        className="mb-5 card card-small px-3 py-4"
                        style={{ width: "100%" }}
                    >
                        <h3 className="contenido-1 border-bottom txt-bold black mb-4">
                            Datos
                        </h3>
                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="nombre">Nombre</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="dias_credito">
                                    Dias de crédito
                                </label>
                                <Field
                                    name="dias_credito"
                                    component={renderNumber}
                                    type="number"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="limite_credito">
                                    Límite de crédito
                                </label>
                                <Field
                                    name="limite_credito"
                                    component={renderNumberFormat}
                                    placeholder="Q"
                                    prefix={"Q"}
                                    type="number"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="nit">Nit*</label>
                                <Field
                                    name="nit"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={composeValidators(required, NIT)}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <h3 className="contenido-1 border-bottom txt-bold black mb-4">
                            Contacto
                        </h3>
                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="direccion">Dirección</label>
                                <Field
                                    name="direccion"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="tipo_proveedor">
                                    Tipo de proveedor
                                </label>
                                <Field
                                    name="tipo_proveedor"
                                    component={renderSelectField}
                                    options={TIPO_PROVEEDOR}
                                    placeholder="Seleccionar"
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field
                                    name="telefono"
                                    component={renderNumberFormat}
                                    numberFormat="#### ####"
                                    type="number"
                                    validate={required}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="correo">Correo</label>
                                <Field
                                    name="correo"
                                    component={renderField}
                                    placeholder="@"
                                    type="text"
                                    className="form-control"
                                    validate={email}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div
                                className="form-group has-feedback m-2"
                                style={{ flex: 7 }}
                            >
                                <label htmlFor="extra">Notas</label>
                                <Field
                                    name="extra"
                                    component={renderTextArea}
                                    // validate={required}
                                    className="w-100"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        {/* //*** Formulario de Contactos del proveedor */}
                        <ContactosForm />

                        <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-5">
                            Documentos
                        </h3>
                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="foto">Carga de archivos</label>
                                <MultiUploadFile
                                    files={files}
                                    onChangeFile={onChangeFile}
                                    onRemoveFile={onRemoveFile}
                                    onRemoveOldFile={onRemoveOldFile}
                                    data={oldFiles}
                                />
                                <label className="contenido-4 gray">
                                    * Patente, RTU, DPI (.pdf, .jpg, .png,
                                    .docx)
                                </label>
                            </div>
                        </div>
                        {/* <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="Extra">Extra</label>
                                <Field
                                    name="extra"
                                    component={renderTextArea}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3"></div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div> */}
                    </div>

                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4 w-100">
                        {!!cancelar ? (
                            <button
                                className="btn btn-outline-primary m-1 align-self-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    cancelar();
                                }}
                            >
                                Cancelar
                            </button>
                        ) : (
                            <Link
                                className="btn btn-outline-primary m-1 align-self-center"
                                to="/configuracion/proveedores"
                            >
                                Volver
                            </Link>
                        )}
                        <button
                            type="submit"
                            className="btn btn-secondary m-1 align-self-center"
                            disabled={submitting}
                        >
                            Crear proveedor
                        </button>
                    </div>
                </form>
            )}
        />
    );
};

export default FormRender;
