import { connect } from "react-redux";
import PagoOV from "./PagoOV";
import { actions } from "../../../../../redux/modules/orden_venta";
import { print } from "../../../../../redux/modules/print/print";

const mapStateToProps = (state) => {
    let saldo_inicial = 0;
    let saldo = 0;
    let total_pago = 0;
    const { orden_venta } = state.orden_venta;

    try {
        const {
            efectivo,
            tarjeta,
            cheque,
            deposito,
            nota_credito,
            retencion_iva,
            exencion_iva,
        } = state.form.IngresoPagoOV.values;

        saldo_inicial = parseFloat(
            orden_venta ? orden_venta.saldo_cuenta.toFixed(2) : 0
        );

        total_pago =
            parseFloat(efectivo ? efectivo : 0) +
            parseFloat(tarjeta ? tarjeta : 0) +
            parseFloat(cheque ? cheque : 0) +
            parseFloat(deposito ? deposito : 0) +
            parseFloat(retencion_iva ? retencion_iva : 0) +
            parseFloat(exencion_iva ? exencion_iva : 0) +
            parseFloat(nota_credito ? nota_credito : 0);
        saldo = saldo_inicial - total_pago;
    } catch (e) {
        saldo_inicial = orden_venta
            ? parseFloat(orden_venta.saldo_cuenta.toFixed(2))
            : 0;
        saldo = saldo_inicial;
        total_pago = 0;
    }

    return {
        ...state.orden_venta,
        saldo,
        total_pago,
        saldo_inicial,
        utiliza_fel: state.usuario.utiliza_fel,
        print_state: state.print,
    };
};

const mapDispatchToProps = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(mapStateToProps, mapDispatchToProps)(PagoOV);
