import React, { useState, useEffect, useContext } from "react";

import { Form, Field } from "react-final-form";
import {
    required,
    email,
    composeValidators,
    maxLength,
    minLength,
} from "../../../../utility/validation";
import {
    renderField,
    renderNumber,
    //SelectField,
    renderInteger,
    renderSelectField,
    SelectField,
} from "Utils/renderField/renderField";
import DireccionesTabla from "./DireccionesTabla";

//import {
//    departamentosOptions,
//    municipiosOptions,
//} from "../../../../utility/constantes";
import { DEPARTAMENTOS, MUNICIPIOS } from "Utils/departamentos_municipios";
import { municipiosOptions } from "../../../../utility/constantes";

const DireccionesForm = ({
    ver = false,
    editar = false,
    direcciones,
    setDirecciones,
}) => {
    // Datos del contexto de proveedores
    //const { direcciones, setDirecciones } = useContext(DireccionesContext);
    const { contactos, nuevos_contactos } = direcciones;

    // Generador de un id
    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, "");
        return `ID${id}`;
    };

    // Submit al formulario Contactos del Direcciones
    const onSubmit = (value, event) => {
        value.id = makeRandomId();
        // Si se esta editando el dato, agregar los datos del formulario al array nuevos_contactos
        if (editar) {
            setDirecciones({
                ...direcciones,
                contactos: [...contactos, value],
                nuevos_contactos: [...nuevos_contactos, value],
            });
        } else {
            // sino, solo al array contactos
            setDirecciones({
                ...direcciones,
                contactos: [...contactos, value],
            });
        }

        // Limpiar el formulario
        event.reset({
            address: "",
            exact_address: "",
            telephone: "",
        });
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{}}
                render={({ handleSubmit, submitting, form, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-5">
                            Direcciones
                        </h3>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="address">Direccion *</label>
                                <Field
                                    name="direccion"
                                    component={renderField}
                                    // placeholder="Ingrese nombre del donante"
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                    disabled={ver}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="exact_address">
                                    Direccion exacta *
                                </label>
                                <Field
                                    name="direccion_exacta"
                                    component={renderField}
                                    // placeholder="Ingrese nombre del donante"
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                    disabled={ver}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="departamento">
                                    Departamento *
                                </label>

                                <Field
                                    name="departamento"
                                    component={renderSelectField}
                                    options={DEPARTAMENTOS}
                                    className="form-control"
                                    validate={required}
                                    item_vacio={true}
                                    value_assign={"id"}
                                    assigned_value={true}
                                />
                                {/*
                                
                                <Field
                                    valueKey="label"
                                    name="municipio"
                                    component={renderSelectField}
                                    options={MUNICIPIOS}
                                    className="form-control"
                                />*/}
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="municipio">Municipio *</label>
                                <Field
                                    name="municipio"
                                    component={renderSelectField}
                                    //options={MUNICIPIOS}
                                    className="form-control"
                                    options={
                                        values.departamento
                                            ? municipiosOptions[
                                                  values.departamento
                                              ]
                                            : []
                                    }
                                    validate={required}
                                    item_vacio={true}
                                    value_assign={"id"}
                                    assigned_value={true}
                                    disabled={
                                        ver || values.departamento
                                            ? false
                                            : true
                                    }
                                />

                                {/* <Field
                                    key={
                                        values.municipio
                                            ? `key_mun${values.municipio}`
                                            : `key_mun_02`
                                    }
                                    name="municipio"
                                    component={renderSelectField}
                                    options={
                                        values.departamento ? MUNICIPIOS : []
                                    }
                                    placeholder="Seleccionar"
                                    type="text"
                                    className=""
                                    valueKey="value"
                                    validate={required}
                                    disabled={
                                        ver || values.departamento
                                            ? false
                                            : true
                                    }
                                />}
                                {/*
                               <Field
                                    valueKey="label"
                                    name="municipio"
                                    component={renderSelectField}
                                    options={MUNICIPIOS}
                                    className="form-control"
                                />*/}
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field
                                    name="telefono"
                                    component={renderInteger}
                                    numberFormat="#### ####"
                                    placeholder=""
                                    type="number"
                                    className="form-control"
                                    validate={composeValidators(
                                        maxLength(8),
                                        minLength(8)
                                    )}
                                    disabled={ver}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3"></div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-end py-4">
                            {!ver && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Agregar Direccion
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
            <DireccionesTabla
                direcciones={direcciones}
                setDirecciones={setDirecciones}
                data={{}}
            />
        </React.Fragment>
    );
};

export default DireccionesForm;
