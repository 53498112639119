import React, { useEffect, useState } from "react";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import moment from "moment";

function rowClassNameFormat(row, rowIdx) {
    return !!row.anulado ? "underline-row" : "";
}
export const TablaOC = (props) => {
    const [unselectable, setUnselectable] = useState([]);

    const {
        cuenta_pagar,
        seleccionados,
        setCuentasPagar,
        setSeleccionados,
        setModal,
    } = props;

    const selectRowProp2 = {
        mode: "checkbox",
        bgColor: "#d4eaff",
        unselectable: unselectable,
        selected: seleccionados,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSeleccionados([...seleccionados, row.id]);
                setCuentasPagar([...cuenta_pagar, row]);
            } else {
                const changeSelected = seleccionados.filter(
                    (item) => item !== row.id
                );
                setSeleccionados(changeSelected);

                const changeCuentaPagar = cuenta_pagar.filter(
                    (item) => item.id !== row.id
                );
                setCuentasPagar(changeCuentaPagar);
            }
        },
        onSelectAll: (isSelect, rows) => {
            // console.log(isSelect);
            // console.log(rows);
            if (isSelect && seleccionados.length === 0) {
                setSeleccionados(rows.map((item) => item.id));
                setCuentasPagar(rows);
            } else if (!isSelect && seleccionados.length === 0) {
                setSeleccionados([]);
                setCuentasPagar([]);
            } else if (isSelect && seleccionados.length > 0) {
                const changeSelected = rows.map((item) => item.id);
                const changeCuentaPagar = rows;
                setSeleccionados([...seleccionados, ...changeSelected]);
                setCuentasPagar([...cuenta_pagar, ...changeCuentaPagar]);
            } else if (!isSelect && seleccionados.length > 0) {
                const changeSelected = seleccionados.filter(
                    (item) => rows.find((i) => i.id === item) === undefined
                );
                const changeCuentaPagar = cuenta_pagar.filter(
                    (item) => rows.find((i) => i.id === item.id) === undefined
                );
                setSeleccionados(changeSelected);
                setCuentasPagar(changeCuentaPagar);
            }
        },
    };

    const { datos_listado } = props;

    useEffect(() => {
        const list_of_unselectable = [];
        if (datos_listado.results) {
            datos_listado.results.map((item) => {
                if (!item.ov_pendiente || item.oc_vencida) {
                    list_of_unselectable.push(item.id);
                }
            });
            setUnselectable(list_of_unselectable);
        }
    }, [datos_listado.results]);
    return (
        <BootstrapTable
            data={props.loader ? [] : props.datos_listado.results}
            remote
            pagination
            hover
            selectRow={selectRowProp2}
            fetchInfo={{ dataTotalSize: props.datos_listado.count }}
            trClassName={rowClassNameFormat}
            options={props.options}
        >
            <TableHeaderColumn
                dataField="fecha_venta"
                dataFormat={(cell) => <RenderDate value={cell} />}

                // dataSort
            >
                Fecha de venta
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_vencimiento"
                dataFormat={(cell) => <RenderDate value={cell} />}

            >
                Fecha de vencimiento
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_pago"
                dataFormat={(cell, row) => {
                    return (
                        <div>
                            {!row.ov_pendiente ? <RenderDate value={cell}/> : <span>-----</span>}
                        </div>
                    );
                }}
            >
                Fecha de Pago
            </TableHeaderColumn>

            <TableHeaderColumn dataField="codigo_venta" width="100px">
                Codigo de venta
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="ov_pendiente"
                dataFormat={(cell, row) => {
                    if (row.ov_pendiente && row.ov_vencida) {
                        return (
                            <span className="font-weight-bold text-danger">
                                Vencido
                            </span>
                        );
                    }
                    return (
                        <div>
                            {!cell ? (
                                <span className="font-weight-bold text-success">
                                    Finalizado
                                </span>
                            ) : (
                                <span className="font-weight-bold txt-yellow">
                                    Pendiente
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Estado pago
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="codigo_pago"
                width="100px"
                dataFormat={(col, row) => {
                    if (!row.ov_pendiente) {
                        return col;
                    }
                    return <span>---</span>;
                }}
            >
                Codigo de pago
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="cliente"
                dataFormat={(cell) => cell.nombre}
            >
                Cliente
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="vendedor"
                dataFormat={(cell) => cell.first_name}
            >
                Vendedor
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="facturado"
                dataFormat={(cell) => (
                    <span
                        className="font-weight-bold"
                        style={{ color: cell ? "green" : "red" }}
                    >
                        {cell ? "Si" : "No"}
                    </span>
                )}
            >
                Facturado
            </TableHeaderColumn>
            <TableHeaderColumn dataField="factura_venta">
                Correlativo Venta
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign="right"
                dataField="total"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Total
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="total_abono"
                dataFormat={(cell) => (
                    <RenderCurrency
                        className={cell > 0 ? "font-weight-bold" : ""}
                        value={cell}
                    />
                )}
            >
                Total abono
            </TableHeaderColumn>
            <TableHeaderColumn
                dataAlign="right"
                dataField="saldo"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Saldo
            </TableHeaderColumn>
            <TableHeaderColumn
                isKey
                dataField="id"
                dataAlign="center"
                dataFormat={(cell, row) => {
                    return activeFormatter({
                        ver: "/cuentas_por_cobrar",
                        icono_pagar: true,
                        facturar: { row, setModal },
                    })(cell, row);
                }}
            >
                Acciones
            </TableHeaderColumn>
        </BootstrapTable>
    );
};
