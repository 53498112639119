import React, { useEffect, useState } from "react";
import { validateFirefoxBrowser } from "../../../../../Utils/validations";
import Checkbox from "../Checkbox";

export const CheckboxContenedor = ({ nombre_permiso, permisos }) => {
    const [isFirefoxBrowser, setIsFirefoxBrowser] = useState(false);

    useEffect(() => {
        const validate_browser = validateFirefoxBrowser();
        setIsFirefoxBrowser(validate_browser);
    }, []);

    return (
        <React.Fragment>
            <div
                className={`${
                    !isFirefoxBrowser
                        ? "container_permiso"
                        : "container_permiso-flex"
                } w-100`}
            >
                {Object.keys(permisos).map((permiso, index) => {
                    const { titulo, checked, subItems } = permisos[permiso];

                    //if (permisos.ordenes_venta) console.log(permisos);

                    if (Object.keys(subItems).length > 0) {
                        return (
                            <div className="d-flex flex-column" key={index}>
                                <Checkbox
                                    titulo={titulo}
                                    checked={checked}
                                    subitem={1}
                                />
                                <div className="d-flex flex-column pl-4">
                                    {Object.keys(subItems).map(
                                        (subItem, index) => {
                                            const { titulo, checked } =
                                                subItems[subItem];

                                            //console.log(titulo, checked);
                                            return (
                                                <div
                                                    className="d-flex flex-row w-100"
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        titulo={titulo}
                                                        checked={checked}
                                                        subitem={2}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <React.Fragment>
                            <Checkbox
                                titulo={titulo}
                                checked={checked}
                                disabled={true}
                                key={index}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
};
