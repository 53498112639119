import React from "react";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { ExpandColumn, ProductosSubTabla } from "./SubTablaComponents";

const ReporteTabla = ({ loader, data, page, listarReporte, sortChange }) => {
    console.log("🚀 ~ file: ReporteCreditoTabla.jsx:9 ~ ReporteTabla ~ data:", data)
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listarReporte,
        onSortChange: sortChange,
        page,
    };

    const isExpandableRow = () => true;

    return (
        <BootstrapTable
            data={loader ? [] : data.results}

            remote
            striped
            pagination
            hover
            fetchInfo={{ dataTotalSize: data.count }}
            options={options}
            expandableRow={isExpandableRow}
            expandComponent={ProductosSubTabla}
            expandColumnOptions={{
                expandColumnVisible: true,
                expandColumnComponent: ExpandColumn,
                columnWidth: 50,
            }}
        >
            <TableHeaderColumn
                dataField="vendedor_asignado"
                dataFormat={(cell, row) => {
                    return cell && cell.first_name ? cell.first_name : "---";
                }}
            >
                Venedor
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="creado"
                dataFormat={(cell) => <RenderDate value={cell} />}
            >
                Fecha de cobro
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="cliente_proveedor"
                dataFormat={(cell, row) => {
                    return cell.nombre ? cell.nombre : "";
                }}
            >
                Cliente
            </TableHeaderColumn>

            <TableHeaderColumn dataField="info_pago">
                Observaciones
            </TableHeaderColumn>

            <TableHeaderColumn dataField="codigo" dataAlign="right">
                Codigo de venta
            </TableHeaderColumn>

            <TableHeaderColumn dataField="num_documento" dataAlign="right">
                Num Recibo
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="total"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Total Cobro
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="total_sin_iva"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Total Cobro sin iva
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="comision"
                dataFormat={(cell) => <RenderCurrency value={cell} />}
            >
                Comisión
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="id"
                width="0"
                tdStyle={BreakLine}
                thStyle={BreakLine}
                isKey
                dataAlign="center"
                dataFormat={(cell, row) => {
                    console.log(row);
                    return activeFormatter({
                        ver: "/reporte_cuenta_por_pagar",
                    })(cell, row);
                }}
            >
                Acciones
            </TableHeaderColumn>
        </BootstrapTable>
    );
};

export default ReporteTabla;
