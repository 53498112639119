import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { ToastStore } from "react-toasts";
import { api } from "api";

const SET_LOADER = "SET_LOADER_FAMILIAS";
const DATA = "SET_DATA_FAMILIAS";
const ITEM = "SET_ITEM_FAMILIAS";
const SET_PAGE = "SET_PAGE_FAMILIAS";
const SEARCH = "SET_SEARCH_FAMILIAS";

const setItem = (item) => ({
    type: ITEM,
    item,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});

export const crear =
    (data, files = []) =>
    (dispatch) => {
        dispatch({ type: SET_LOADER, loader: true });

        api.post(`familia`, data)
            .then((response) => {
                dispatch(push("/familias"));
                ToastStore.success("Datos almacenados correctamente");
                //NotificationManager.success(
                //    "Registro creado exitosamente",
                //    "Éxito",
                //    4000
                //);
                dispatch(listar());
            })
            .catch((error) => {
                let msg = "Error al crear registro";
                if (error.msg) msg = error.msg;
                NotificationManager.error(msg, "Error", 4000);
            })
            .finally(() => {
                dispatch({ type: SET_LOADER, loader: false });
            });
    };

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch({ type: SET_LOADER, loader: true });

        const params = { page };

        const store = getStore().familias;
        const { ordering, search } = store;
        params.search = search;

        api.get("familia", params)
            .then((response) => {
                if (response) {
                    dispatch({
                        type: DATA,
                        data: response,
                    });
                    dispatch({
                        type: SET_PAGE,
                        page: page,
                    });
                }
            })
            .catch((error) => {
                NotificationManager.error(
                    "Error al obtener registros",
                    "Error",
                    4000
                );
            })
            .finally(() => {
                dispatch({ type: SET_LOADER, loader: false });
            });
    };

export const leer = (id) => (dispatch) => {
    const read_one = true;
    const params = { read_one };
    dispatch({ type: SET_LOADER, loader: true });
    api.get(`${"familia"}/${id}`, params)
        .then((response) => {
            dispatch(setItem(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

////(id, data) => (dispatch) => {
const editar =
    (id, data = []) =>
    (dispatch) => {
        dispatch({ type: SET_LOADER, loader: true });

        api.put(`${"familia"}/${id}`, data)
            .then((response) => {
                dispatch(push("/familias"));
                NotificationManager.success(
                    "Registro actualizado exitosamente",
                    "Éxito",
                    4000
                );
                dispatch(listar());
            })
            .catch((error) => {
                NotificationManager.error(
                    "Error al actualizar registro",
                    "Error",
                    4000
                );
            })
            .finally(() => {
                dispatch({ type: SET_LOADER, loader: false });
            });
    };

const eliminar = (id) => (dispatch) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.eliminar(`${"familia"}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch(() => {
            NotificationManager.success(
                "Error en la transacción",
                "Éxito",
                3000
            );
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const removerItem = () => (dispatch) => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
    buscar,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    page: 1,
    item: {},
    search: "",
    filtro: "",
};

export default handleActions(reducers, initialState);
