import React from "react";

import { Form, Field } from "react-final-form";
import {
    renderSearchSelect,
    renderSelectField,
    renderField,
    renderTextArea,
} from "Utils/renderField/renderField";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";
import { required } from "../../../../../utility/validation";
import { FORMAS_PAGO } from "../../../../../utility/variables";

const listado_bancos = [];
const PagoCompletoForm = ({ onSubmit, disabled }) => {
    const { getOptions: getBancos } = useAsyncSearch("bancos", listado_bancos);
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                monto: 0,
            }}
            render={({ handleSubmit, submitting, form }) => {
                return (
                    <form onSubmit={handleSubmit} className="form-validate">
                        <div className="">
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="banco">No. Cuenta</label>
                                    <Field
                                        name="banco"
                                        component={renderSearchSelect}
                                        loadOptions={getBancos}
                                        labelKey="banco_cuenta"
                                        valueKey="id"
                                        className="form-control"
                                        disabled={disabled}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="numero_recibo">
                                        Número de Recibo
                                    </label>
                                    <Field
                                        name="numero_recibo"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="forma_pago">
                                        Formas de pago
                                    </label>
                                    <Field
                                        name="forma_pago"
                                        component={renderSelectField}
                                        options={FORMAS_PAGO}
                                        placeholder="Seleccionar"
                                        type="text"
                                        className="form-control"
                                        disabled={disabled}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="numero_documento">
                                        Número de Documento
                                    </label>
                                    <Field
                                        name="numero_documento"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="observacion">
                                        Observación
                                    </label>
                                    <Field
                                        name="observacion"
                                        component={renderTextArea}
                                        options={FORMAS_PAGO}
                                        placeholder="Seleccionar"
                                        type="text"
                                        className="form-control"
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-lg-end pb-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting || disabled}
                                >
                                    Finalizar Pago
                                </button>
                                {/* {!disabled && (
                                )} */}
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default PagoCompletoForm;
