import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";
import { Form, Field } from "react-final-form";

import useAsyncOptions from "../../../../hooks/useAsyncOptions";

import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
    NIT,
} from "../../../../../utility/validation";

import {
    renderField,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    renderCurrency,
    renderSelectField,
    renderAsyncSelectField,
} from "Utils/renderField/renderField";
import { set } from "lodash";

const UpdateProductPrice = ({
    setSelected,
    view = false,
    verificado,
    producto,
    onSubmit,
}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        let newData = {};

        if (producto.id) {
            newData.id = producto.id;
        }

        if (producto.fracciones) {
            if (producto.fracciones[0]) {
                newData.fraccion_id = producto.fracciones[0].id;
                newData.costo = producto.fracciones[0].costo;
            }

            //if (producto.fracciones[0]) setFracciones(producto.fracciones[0]);
        }
        if (producto.costo_dolares) {
            newData.costo_dolares = producto.costo_dolares;
            //if (producto.costo_dolares) setFracciones(producto.fracciones[0]);
        }

        if (producto.precio_de_mostrador) {
            newData.precio_mostrador = producto.precio_de_mostrador;
        } else {
            newData.precio_mostrador = 0;
        }

        if (producto.precio_costo) {
            newData.precio_costo = producto.precio_costo;
        } else {
            newData.precio_costo = false;
        }

        setData(newData);
    }, [producto]);

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                mutators={{
                    // expect (field, value) args from the mutator
                    setInput: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                initialValues={{
                    //precio_costo: true,
                    ...data,
                }}
                render={({ handleSubmit, submitting, values, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className=" px-3 py-1">
                            {/* Form 0 */}

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Costo en Dolares
                                    </label>
                                    <Field
                                        name="costo_dolares"
                                        component={renderCurrency}
                                        pais={"US"}
                                        placeholder="$"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={!verificado}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Costo en Quetzales
                                    </label>

                                    <Field
                                        name="costo"
                                        component={renderCurrency}
                                        placeholder="Q"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={!verificado}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            {/* Form 1 */}

                            <div className="d-flex flex-column flex-md-row mb-4">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">
                                        Precio mostrador
                                    </label>

                                    <Field
                                        name="precio_mostrador"
                                        component={renderCurrency}
                                        placeholder="Q"
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={!verificado}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">Precio costo *</label>
                                    <div className="d-flex">
                                        <label className="mr-2">Familia</label>
                                        <Field
                                            name="precio_costo"
                                            component={renderSwitch}
                                            // placeholder="Ingrese nombre del donante"
                                            type="text"
                                            className="form-control"
                                            disabled={!verificado}
                                        />
                                        <label className="ml-2">
                                            Mostrador
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-2">
                            <button
                                type="button"
                                onClick={() => {
                                    setSelected({
                                        view: "LIST",
                                        product: undefined,
                                    });
                                }}
                                className="btn btn-outline-secondary mt-4 mt-md-0"
                            >
                                Volver
                            </button>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    Actualizar Producto
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default UpdateProductPrice;
