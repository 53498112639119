import { connect } from "react-redux";
import OCTipoPago from "./OCTipoPago";
import {
    actions,
    setVerBuscador,
} from "../../../../../redux/modules/orden_compra/orden_compra";

const ms2p = (state) => {
    let total = 0;
    state.ordenes_compra.seleccionados.forEach((producto) => {
        // Costo de importacion del producto
        const costo_importacion_value =
            state.ordenes_compra.costo_importacion_value;

        // Costo del tipo de cambio
        const tipo_cambio_value =
            state.ordenes_compra.tipo_cambio_value;

        // Costo del producto, depende del valor del costo de importacion
        const costo_valor =
            costo_importacion_value > 0
                ? producto.costo_dolares * tipo_cambio_value
                : producto.costo;

        const costo = costo_valor ? parseFloat(costo_valor) : 0;
        const cantidad = producto.cantidad ? parseFloat(producto.cantidad) : 0;
        const costo_importacion_state = costo_importacion_value || 0;

        // Calculo del porcentaje del costo de importacion
        const costo_importacion = costo_importacion_state / 100;
        const total_temp = costo * cantidad; // Total sin el porcentaje
        const porcentaje =
            costo_importacion >= 0 ? total_temp * costo_importacion : 0;
        total = total_temp + porcentaje + total; // Total con el porcentaje, si es mayor a 0
    });
    return {
        ...state.ordenes_compra,
        pais: state.usuario.me.pais,
        total,
    };
};

const md2p = {
    ...actions,
    setVerBuscador: (estado) => (dispatch) => dispatch(setVerBuscador(estado)),
};

export default connect(ms2p, md2p)(OCTipoPago);
