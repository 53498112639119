import _ from "lodash";
import "../producto.css";
import React from "react";
import { api } from "api";
import { icons } from "icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { CAPACIDAD, PESO } from "utility/variables";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import FileUploader from "../../Utils/FileUploader/FileUploader";
import FraccionesComponent from "../Fraccion/FraccionContainer";
import {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderPorcentaje,
    AsyncSelectField,
} from "Utils/renderField/renderField";
import { RenderCurrency as RCurrency } from "Utils/renderField/renderReadField";
import Catalogo from "../Crear/Catalogo";
import TreeCategoria from "../TreeCategoria";
import { preciosSucursal } from "../camposProducto";
import { renderSelectField } from "../../Utils/renderField/renderField";

// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};
    if (!values.nombre) errors.nombre = "Campo requerido";

    //if (!values.descripcion) errors.descripcion = "Campo requerido";

    if (values.tipo_producto != "100") {
        if (!values.precio && values.precio != "0")
            errors.precio = "Campo requerido";
        if (values.codigo_barras) {
            // if(values.codigo_barras.length > 20)
            //     errors.codigo_barras = 'Debe tener 20 caracteres o menos.';
            if (!/^\w+$/i.test(values.codigo_barras))
                errors.codigo_barras = "Debe tener solo números y/o letras.";
        }
        if (values.descripcion_codigo_barras) {
            if (values.descripcion_codigo_barras.length > 20)
                errors.descripcion_codigo_barras =
                    "Debe tener 20 caracteres o menos.";
        }
    } else {
        values.precio = null;
        values.precio2 = null;
        values.precio3 = null;
        values.precio4 = null;
        values.precio5 = null;
        values.codigo_barras = null;
        values.descripcion_codigo_barras = null;
    }
    if (!values.inventariable) {
        values.alertar = false;
        values.minimo_existencias = null;
        values.tiene_fracciones = false;
    }
    if (values.alertar) {
        if (!values.minimo_existencias)
            errors.minimo_existencias = "Campo requerido";
    } else {
        values.minimo_existencias = null;
    }

    if (values.tienda_online) {
        if (!values.precio_online) {
            errors.precio_online = "Campo requerido";
        }
    }

    if (values.precios_sucursal) {
        if (values.precios_sucursal.length) {
            const preciosSucursalArrayErrors = [];
            values.precios_sucursal.forEach((precio_sucursal, index) => {
                const precioSucursalErrors = {};
                if (
                    !precio_sucursal["precio"] &&
                    precio_sucursal["precio"] != "0"
                )
                    precioSucursalErrors.precio = "Campo requerido";
                preciosSucursalArrayErrors[index] = precioSucursalErrors;
            });

            if (preciosSucursalArrayErrors.length)
                errors.precios_sucursal = preciosSucursalArrayErrors;
        }
    }

    return errors;
};

const Form = (props) => {
    // state
    const {
        unidades_de_medida_empresa,
        tipoProductoValue,
        fraccionadoValue,
        inventariableValue,
        alertaValue,
        unidadDeMedidaValue,
        seleccion_unidades_medida,
        foto_producto,
        conf_tienda_online,
        tienda_onlineValue,
        nombres_precios,
        conf_porcentaje_precio,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
    } = props;
    const {
        categorias,
        categorias_seleccionados,
        categoriaSelect,
        categorias_expanded,
        sucursales,
        sucursal,
        sucursal_activo,
    } = props;
    // bind
    const {
        handleSubmit,
        editarProducto,
        onFileChange,
        producto,
        cambioSeleccionUnidad,
        cambioTipoProducto,
        loader,
        asignarCategoria,
        setCategoriasExpanded,
        changeSucursal,
        changeSucursalActivo,
        cambioPorcentajePrecio,
    } = props;

    //  unidades de medida por el tipo
    let unidades_medida = [];
    // let unidades_de_medida_seleccionados= [];
    //  peso
    if (tipoProductoValue == "1000") {
        unidades_medida = _.filter(
            unidades_de_medida_empresa,
            function (unidad) {
                return unidad.tipo == PESO;
            }
        );
    } else if (tipoProductoValue == "2000") {
        unidades_medida = _.filter(
            unidades_de_medida_empresa,
            function (unidad) {
                return unidad.tipo == CAPACIDAD;
            }
        );
    }
    unidades_medida = _.orderBy(unidades_medida, ["capacidad"], ["asc"]);
    // unidades_medida.forEach((unidad) => {
    //     const esta = _.find(unidades_medidas_seleccionadas, function(id_unidad) { return id_unidad == unidad.id})
    //     if (esta) {
    //         unidad.seleccionado = true
    //         unidades_de_medida_seleccionados.push(unidad)
    //     } else {
    //         unidad.seleccionado = false
    //     }

    // })

    const subFamilias = [
        {
            id: 1,
            label: "Beck Arnley",
        },
        {
            id: 2,
            label: "Carro provisional",
        },
        {
            id: 3,
            label: "Casma internacional",
        },
        {
            id: 4,
            label: "King steel importación",
        },
        {
            id: 5,
            label: "Kotec importación",
        },
    ];

    const tiene_imagen = producto.imagen ? true : foto_producto ? true : false;

    const listaSubFamilias = [];

    const getSubFamilias = (search) => {
        return (
            api
                //.get("sub-familia", { search })
                .get("sub-familia", { async_options: true, search })
                .catch(() => {})
                .then((data) => {
                    data.results.forEach((item) => {
                        if (!_.find(listaSubFamilias, { id: item.id }))
                            listaSubFamilias.push(item);
                    });
                    return { options: listaSubFamilias };
                })
        );
    };

    return (
        <div className="grid-container mt">
            <LoadMask loading={loader} dark blur>
                <div className="grid-titulo padding-15">
                    Edición de producto
                </div>
                <div className="grid-cuerpo padding-15  p-sm-0 pt-sm-1 pb-sm-1 mt">
                    <form>
                        <div>
                            <div className="row">
                                <div className="col-sm-6 content-drop-zone">
                                    <div className="row">
                                        <div className="col-12">
                                            <FileUploader
                                                onFileChange={onFileChange}
                                                icono={"add-img"}
                                                frase={"producto."}
                                                clave={"foto_producto"}
                                                width="100%"
                                                source={
                                                    producto.imagen
                                                        ? producto.imagen
                                                        : null
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre *</label>
                                        <Field
                                            name="nombre"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="codigo">Código *</label>
                                        <Field
                                            name="codigo"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    {/* {Boolean(!fraccionadoValue) ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="codigo_barras">
                                                    Código de barras
                                                </label>
                                                <Field
                                                    name="codigo_barras"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {Boolean(!fraccionadoValue) ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="descripcion_codigo_barras">
                                                    Descripción código de barras
                                                </label>
                                                <Field
                                                    name="descripcion_codigo_barras"
                                                    component={renderField}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {Boolean(!fraccionadoValue) &&
                                    conf_tienda_online ? (
                                        <div className="row-fields">
                                            <div className="form-group">
                                                <label htmlFor="tienda_online">
                                                    Publicar en tienda online
                                                </label>
                                                <br />
                                                <label className="switch">
                                                    <Field
                                                        name="tienda_online"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    ) : null} */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 form-group mb-1 mt-3">
                                    <label htmlFor="codigo_barras">
                                        Código de barras
                                    </label>
                                    <Field
                                        name="codigo_barras"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-6 form-group mb-1 mt-3">
                                    <label htmlFor="subfamilia">
                                        Categoría del producto (Subfamilia)
                                    </label>
                                    <Field
                                        name="subfamilia"
                                        component={AsyncSelectField}
                                        loadOptions={getSubFamilias}
                                        labelKey="nombre"
                                        valueKey="id"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="row-fields d-flex flex-column col-6 m-0 p-0">
                                <div className="col-12 form-group">
                                    <label htmlFor="codigo_equivalente_1">
                                        Código equivalente 1
                                    </label>
                                    <Field
                                        name="codigo_equivalente_1"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label htmlFor="codigo_equivalente_2">
                                        Código equivalente 2
                                    </label>
                                    <Field
                                        name="codigo_equivalente_2"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <label htmlFor="codigo_equivalente_3">
                                        Código equivalente 3
                                    </label>
                                    <Field
                                        name="codigo_equivalente_3"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            {/* ASIGNACION DE PRECIOS POR SUCURSAL */}
                            {/* {Boolean(!fraccionadoValue) && (
                                <div className="row row-fields">
                                    <div className="col-12 col-md-12 col-lg-12 form-group">
                                        <label>Precios por sucursal</label>
                                        <FieldArray
                                            name="precios_sucursal"
                                            component={preciosSucursal}
                                            sucursales={sucursales}
                                            sucursal={sucursal}
                                            changeSucursal={changeSucursal}
                                            sucursal_activo={sucursal_activo}
                                            nombres_precios={nombres_precios}
                                            changeSucursalActivo={
                                                changeSucursalActivo
                                            }
                                            conf_porcentaje_precio={
                                                conf_porcentaje_precio
                                            }
                                            cambioPorcentajePrecio={
                                                cambioPorcentajePrecio
                                            }
                                            precios_sucursalValue={
                                                precios_sucursalValue
                                            }
                                            nombreFormulario="ProductoEditar"
                                        />
                                    </div>
                                </div>
                            )} */}

                            <div className="row row-fields">
                                {/* <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                    <label htmlFor="vendible">Vendible</label>
                                    <br />
                                    <label className="switch">
                                        <Field
                                            name="vendible"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                    <label htmlFor="inventariable">
                                        Inventariable
                                    </label>
                                    <br />
                                    <label className="switch">
                                        <Field
                                            name="inventariable"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                {Boolean(
                                    inventariableValue && !fraccionadoValue
                                ) ? (
                                    <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                        <label htmlFor="alertar">Alertar</label>
                                        <br />
                                        <label className="switch">
                                            <Field
                                                name="alertar"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                ) : null} */}
                                {Boolean(alertaValue) &&
                                Boolean(
                                    inventariableValue && !fraccionadoValue
                                ) ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <label htmlFor="minimo_existencias">
                                            Mínimo existencia *
                                        </label>
                                        <Field
                                            name="minimo_existencias"
                                            component={renderNumber}
                                            type="number"
                                            className="form-control"
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className="row row-fields">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <label htmlFor="descripcion">
                                        Descripción
                                    </label>
                                    <Field
                                        name="descripcion"
                                        component={renderTextArea}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {/* {Boolean(inventariableValue) &&
                            !tienda_onlineValue ? (
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <label htmlFor="tipo_producto">
                                            Este producto se va a maneja como
                                        </label>
                                        <br />
                                        <div className="d-flex flex-column flex-lg-row ml-3 mt-3">
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="1"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "1",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Simple &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="100"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "100",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Fraccionado &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="1000"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "1000",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Peso &nbsp;
                                                </label>
                                            </div>
                                            <div className="radio c-radio c-radio-nofont d-flex">
                                                <label className="negro-dark2 font-weight-normal">
                                                    <Field
                                                        name="tipo_producto"
                                                        component="input"
                                                        type="radio"
                                                        value="2000"
                                                        onChange={() => {
                                                            cambioTipoProducto(
                                                                "2000",
                                                                "editar"
                                                            );
                                                            cambioSeleccionUnidad(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                    <span />
                                                    Capacidad
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null} */}
                            {tienda_onlineValue && conf_tienda_online && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="categorias">
                                                Seleccione la categoría
                                            </label>
                                            <br />
                                            <small className="fnt-12">
                                                Puede seleccionar hasta un
                                                máximo de 3 categorías
                                            </small>
                                        </div>
                                        <TreeCategoria
                                            categorias={categorias}
                                            asignarCategoria={asignarCategoria}
                                            setCategoriasExpanded={
                                                setCategoriasExpanded
                                            }
                                            categorias_seleccionados={
                                                categorias_seleccionados
                                            }
                                            categorias_expanded={
                                                categorias_expanded
                                            }
                                        />
                                        {categoriaSelect && (
                                            <div className="invalid-feedback d-block my-2 fnt-10 ml-2">
                                                Campo requerido
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* //************************************Nuevo******************************************************************************** */}
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="precio">Costo Q</label>
                                <Field
                                    name="precio"
                                    component={renderCurrency}
                                    placeholder="Q"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="costo_dolares">
                                    Costo $
                                </label>
                                <Field
                                    name="costo_dolares"
                                    component={renderCurrency}
                                    placeholder="Q"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {/* <div className="d-flex flex-column flex-lg-row">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="precio_de_mostrador">
                                    Precio Mostrador
                                </label>
                                <Field
                                    name="precio_de_mostrador"
                                    component={renderCurrency}
                                    placeholder="Q"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div> */}
                        {/* 
                        <div className="d-flex flex-column flex-lg-row ml-3 mt-5">
                            <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                <label htmlFor="producto_servicio">
                                    Producto o servicio
                                </label>
                                <br />
                                <div className="d-flex align-content-center">
                                    <label className="mr-2">producto</label>
                                    <label className="switch">
                                        <Field
                                            name="producto_servicio"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <label className="ml-2">servicio</label>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                <label htmlFor="kit">Kit</label>
                                <br />
                                <div className="d-flex align-content-center">
                                    <label className="mr-2">si</label>
                                    <label className="switch">
                                        <Field
                                            name="kit"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <label className="ml-2">no</label>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                <label htmlFor="circulacion">
                                    Sacar de circulación
                                </label>
                                <br />
                                <div className="d-flex align-content-center">
                                    <label className="mr-2">no</label>
                                    <label className="switch">
                                        <Field
                                            name="circulacion"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <label className="ml-2">si</label>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-12 form-group">
                                <label htmlFor="publicar_panel_clientes">
                                    Publicar en panel de clientes
                                </label>
                                <br />
                                <div className="d-flex align-content-center">
                                    <label className="mr-2">si</label>
                                    <label className="switch">
                                        <Field
                                            name="publicar_panel_clientes"
                                            component="input"
                                            type="checkbox"
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <label className="ml-2">no</label>
                                </div>
                            </div>
                        </div> */}
                    </form>
                    {/* //************************************Nuevo******************************************************************************** */}

                    {/* SECCION DE CRUD DE FRACCIONES */}
                    {Boolean(fraccionadoValue) &&
                    Boolean(inventariableValue) ? (
                        <div className="row row-fields">
                            <FraccionesComponent />
                        </div>
                    ) : null}
                </div>
                {/* FOOTER */}
                <div className="d-flex justify-content-center flex-row footer-container">
                    <Link
                        className="btn btn-tertiary m-1 align-self-center"
                        to="/productos"
                    >
                        <img src={icons.cancelar} alt="" />
                        Cancelar
                    </Link>
                    <button
                        type="button"
                        className="btn btn-primary m-1 align-self-center"
                        onClick={handleSubmit((values) => {
                            // validacionCustom(values, crearProducto);
                            editarProducto();
                        })}
                    >
                        <img src={icons.save} alt="" />
                        Guardar
                    </button>
                </div>
            </LoadMask>
        </div>
    );
};

let ProductoForm = reduxForm({
    // a unique name for the form
    form: "ProductoEditar",
    validate,
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("ProductoEditar");

ProductoForm = connect((state) => {
    const store = state.producto;
    const form = state.form.ProductoEditar;
    let categoriaSelect = false;
    const tienda_onlineValue = selector(state, "tienda_online");
    if (form && tienda_onlineValue) {
        const { submitFailed, submitSucceeded } = form;
        categoriaSelect =
            store.categorias_seleccionados.length <= 0 &&
            (submitFailed || submitSucceeded);
    }

    const tipoProductoValue = selector(state, "tipo_producto");
    const fraccionadoValue = parseInt(tipoProductoValue) == 100 ? true : false;
    const inventariableValue = selector(state, "inventariable");
    const alertaValue = selector(state, "alertar");
    const unidadDeMedidaValue = selector(state, "unidad_de_medida");
    //  PRECIOS
    const precio2Value = selector(state, "precio2");
    const precio3Value = selector(state, "precio3");
    const precio4Value = selector(state, "precio4");
    const precio5Value = selector(state, "precio5");
    const precios_sucursalValue = selector(state, "precios_sucursal");
    return {
        tipoProductoValue,
        fraccionadoValue,
        inventariableValue,
        alertaValue,
        unidadDeMedidaValue,
        tienda_onlineValue,
        categoriaSelect,
        precio2Value,
        precio3Value,
        precio4Value,
        precio5Value,
        precios_sucursalValue,
    };
})(ProductoForm);

export default ProductoForm;
