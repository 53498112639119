import ListadoMovimientos from "./ListadoMovimientos.jsx";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/bodega/listado_movimientos";

const ms2p = (state) => {
    return {
        ...state.listado_movimientos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoMovimientos);
