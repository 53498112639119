import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import moment from "moment/moment";
import DatePicker from "Utils/DatePicker";
import { Field, reduxForm, formValueSelector } from "redux-form";

import { credito, cotizacion } from "utility/constantes";

import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
} from "utility/validation";

import {
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    //AsyncSelectField,
    renderAsyncSelectField,
    renderCurrency,
    renderSelectField,
    renderFieldReadOnly,
} from "Utils/renderField/renderField";

import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";

const validate = (values, props) => {
    const errors = {};
    //if (
    //    values.enviar_correo &&
    //    values.enviar_correo == true &&
    //    !values.correo
    //) {
    //    errors.correo = "Campo requerido";
    //}

    return errors;
};

const Form = (props) => {
    //  Bind
    const {
        handleSubmit,
        anteriorPaso,
        cambioPlazo,
        seleccionFechaLimite,
        setDireccionEntrega,
        //Form Retrive
        cliente,
        clienteValue,
        fechaLimiteValue,
        total,
        //total_descuento,
        tipo_venta,
        total_costo,
        venta_costo,
        venta_descuentos,
        total_descuento,
        //Cliente
        listarCliente,
        abrirModalCliente,
        data_form,
        enviar_correo,
        direcciones,
    } = props;

    return (
        <div>
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <div className="grid-titulo padding-10">Medios de pago</div>

                    {/* <div className="row px-1 py-2">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="">Cliente</label>
                            <div className="d-flex pr-0">
                                <button
                                    style={{
                                        width: "37px",
                                        height: "37px",
                                    }}
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        listarCliente();
                                        abrirModalCliente();
                                    }}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                                <div className="d-flex flex-column w-100">
                                    <Field
                                        onClick={(e) => {
                                            e.preventDefault();
                                            listarCliente();
                                            abrirModalCliente();
                                        }}
                                        name="cliente"
                                        component={renderFieldReadOnly}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="fecha_entrega">Nit</label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{
                                    borderBottom: "2px solid  #B8B8B8",
                                }}
                            >
                                {(data_form && (
                                    <React.Fragment>
                                        No. {data_form.nit_cliente}
                                    </React.Fragment>
                                )) ||
                                    "--------"}
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="tipo_entrega">Fecha</label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{
                                    maxWidth: "650px",
                                    borderBottom: "2px solid  #B8B8B8",
                                }}
                            >
                                {(data_form && data_form.fecha && (
                                    <span>
                                        {moment(data_form.fecha).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </span>
                                )) ||
                                    "--------"}
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row px-1 py-2">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="enviar_correo">
                                Enviar a correo
                            </label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{ maxWidth: "650px" }}
                            >
                                <div className="d-flex mt-1">
                                    <label className="mr-2">No</label>
                                    <Field
                                        name="enviar_correo"
                                        component={renderSwitch}
                                        type="text"
                                        className="form-control"
                                        disabled={
                                            venta_costo ? venta_costo : false
                                        }
                                    />
                                    <label className="ml-2">Si</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="correo">Correo</label>
                            <div
                                className="d-flex flex-column pr-0"
                                style={{ maxWidth: "650px" }}
                            >
                                <Field
                                    name="correo"
                                    rows={2}
                                    component={renderField}
                                    className="form-control"
                                    disabled={enviar_correo ? false : true}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="">Direccion</label>
                            <Field
                                name="direccion"
                                component={renderSelectField}
                                options={direcciones}
                                placeholder="Seleccionar"
                                value_assign={"direccion"}
                                label_assign={"direccion"}
                                assigned_value={true}
                                assigned_label={true}
                                item_vacio={true}
                                type="text"
                                className="form-control"
                                disabled={clienteValue ? false : true}
                            />
                        </div>
                    </div> */}
                    <div className="row px-1 py-2">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group"></div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group"></div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                            <label htmlFor="">Total</label>
                            <div
                                className="p-1 text-right"
                                style={{
                                    borderBottom: "2px solid  #B8B8B8",
                                }}
                            >
                                {venta_costo
                                    ? !data_form.porcentaje_descuento && (
                                        <RenderCurrency value={total_costo} />
                                    )
                                    : (!venta_costo &&
                                        !data_form.porcentaje_descuento && (
                                            <RenderCurrency value={total} />
                                        )) ||
                                    (data_form.porcentaje_descuento && (
                                        <RenderCurrency
                                            value={total_descuento}
                                        />
                                    ))}
                            </div>
                        </div>
                        {tipo_venta == credito && (
                            <div className="col-12 col-md-6 col-lg-6 form-group d-flex flex-column justify-content-end">
                                <label htmlFor="fecha_limite">
                                    Fecha límite
                                </label>
                                <div className="p-1">
                                    <Field
                                        name="fecha_limite"
                                        component={renderDatePicker}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </React.Fragment>
        </div>
    );
};

let Pago = reduxForm({
    form: "wizardCrearOVForm",
    // a unique name for the form
    //form: "MediosPagosForm",
    destroyOnUnmount: false, //        <------ preserve DatePickerform data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
})(Form);

const selector = formValueSelector("wizardCrearOVForm");
Pago = connect((state) => {
    const data_form = state.form.wizardCrearOVForm.values;
    const clienteValue = selector(state, "cliente");
    const fecha = moment().format("YYYY-MM-DD");
    const tipo_venta = selector(state, "tipo_venta");
    const venta_costo = selector(state, "venta_costo");
    const venta_descuentos = selector(state, "descuentos");
    const enviar_correo = selector(state, "enviar_correo");
    const total_descuento = selector(state, "total_descuento");

    if (data_form.venta_costo == true) {
        data_form.descuentos = false;
        data_form.porcentaje_descuento = 0;
    }

    return {
        clienteValue,
        tipo_venta,
        data_form,
        venta_costo,
        venta_descuentos,
        enviar_correo,
        total_descuento,
        initialValues: {
            fecha_limite: fecha,
        },
    };
})(Pago);

export default Pago;
