import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastStore } from "react-toasts";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from "react-accessible-accordion";
import { niveles_usuario } from "variables";
import "./accordion.css";
import "./menu.css";
import { icons } from "icons";
import Modal from "react-responsive-modal";
import Bienvenida from "Utils/Bienvenida";
import { PVN } from "utility/variables";

// Imagenes
import logo_remocar from "../../../../assets/img/remocar-logo.png";
import logo_inicio from "../../../../assets/img/iconos/home-icon.svg";
import logo_bodega from "../../../../assets/img/iconos/bodega-icono.svg";
import ordenes_compra_logo from "../../../../assets/img/iconos/shopping-cart-gray.svg";
import logo_familias from "../../../../assets/img/iconos/family-gray.svg";
import bitacora_logo from "../../../../assets/img/iconos/binnacle-gray.svg";
import transportes_icono from "../../../../assets/img/iconos/truck-gray.svg";
import cuentas_pagar_logo from "../../../../assets/img/iconos/bills-due-gray.svg";
import orden_venta from "../../../../assets/img/menu/orden_venta.png";
import report_icon from "../../../../assets/img/iconos/report-icon.svg";
import comisiones from "../../../../assets/img/iconos/commission.svg";
import kardex_icon from "../../../../assets/img/iconos/kardex.svg";
import articulos_venta from "../../../../assets/img/iconos/articulos-venta.png";
import entradas_icono from "../../../../assets/img/iconos/entradas-icono.png";
import salidas_icono from "../../../../assets/img/iconos/outcome_report.svg";
import caja_icono from "../../../../assets/img/iconos/cashier.svg";
import comision_icono from "../../../../assets/img/iconos/comision-icono.png";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { ver_sucursal: false, open: false };
        this.toggleSucursal = this.toggleSucursal.bind(this);
    }
    componentWillMount() {
        this.logOut = this.logOut.bind(this);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.url !== nextProps.url) {
            this.props.closeMenu();
        }
        if (nextProps.bienvenida_visto !== this.props.bienvenida_visto) {
            if (this.props.vista_bienvenida) {
                this.setState({ open: !nextProps.bienvenida_visto });
            }
        }
    }
    closeModal = () => {
        this.setState({ open: false });
    };
    openModal = () => {
        this.setState({ open: true });
    };
    marcarVisto = () => {
        this.closeModal();
        if (this.props.bienvenida_visto != true)
            this.props.marcarVistoBienvenida();
    };

    mostrarItem = (item) => {
        const { usuario } = this.props;
        let entra = false;
        switch (item) {
            case "dashboard":
                entra = usuario.dashboard;
                break;
            case "bodega":
                entra = usuario.bodega;
                break;
            case "punto_venta":
                entra = usuario.punto_venta;
                break;
            case "punto_venta_mostrador_caja":
                entra = usuario.punto_venta_mostrador_caja;
                break;
            case "punto_venta_mostrador":
                entra = usuario.punto_venta_mostrador;
                break;
            case "punto_venta_caja":
                entra = usuario.punto_venta_caja;
                break;
            case "producto":
                entra = usuario.producto;
                break;
            case "gasto":
                entra = usuario.gasto;
                break;
            case "reporte":
                entra = usuario.reporte;
                break;
            case "reporte_balance_general":
                entra = usuario.reporte_balance_general;
                break;
            case "reporte_apertura_cierre":
                entra = usuario.reporte_apertura_cierre;
                break;
            case "reporte_bodega":
                entra = usuario.reporte_bodega;
                break;
            case "reporte_venta":
                entra = usuario.reporte_venta;
                break;
            case "reporte_devolucion":
                entra = usuario.reporte_devolucion;
                break;
            case "reporte_cuenta_cobrar":
                entra = usuario.reporte_cuenta_cobrar;
                break;
            case "reporte_kardex":
                entra = usuario.reporte_kardex;
                break;
            case "reporte_gasto":
                entra = usuario.reporte_gasto;
                break;
            case "reporte_productos_mas_vendidos":
                entra = usuario.reporte_productos_mas_vendidos;
                break;
            case "reporte_total_invertido":
                entra = usuario.reporte_total_invertido;
                break;
            case "reporte_ganancia_bruta":
                entra = usuario.reporte_ganancia_bruta;
                break;
            case "reporte_mejores_clientes":
                entra = usuario.reporte_mejores_clientes;
                break;
            case "reporte_despachos":
                entra = usuario.reporte_despachos;
                break;
            case "reporte_historial_precios":
                entra = usuario.reporte_historial_precios;
                break;
            case "reporte_movimientos":
                entra = usuario.reporte_movimientos;
                break;
            case "reporte_historial_bajas_salidas":
                entra = usuario.reporte_historial_bajas_salidas;
                break;
            case "reporte_descuentos_recargos":
                entra = usuario.reporte_descuentos_recargos;
                break;
            case "reporte_cuenta_por_cobrar":
                entra = usuario.reporte_cuenta_por_cobrar;
                break;
            case "reporte_cuenta_por_pagar":
                entra = usuario.reporte_cuenta_por_pagar;
                break;
            case "reporte_karex":
                entra = usuario.reporte_karex;
                break;
            case "reporte_recibos_cuenta_por_cobrar":
                entra = usuario.reporte_recibos_cuenta_por_cobrar;
                break;
            case "reporte_recibos_cuenta_por_pagar":
                entra = usuario.reporte_recibos_cuenta_por_pagar;
                break;
            case "reporte_comisiones":
                entra = usuario.reporte_comisiones;
                break;
            case "reporte_comision_mensusal":
                entra = usuario.reporte_comision_mensusal;
            case "reporte_notas_de_credito_al_proveedor":
                entra = usuario.reporte_notas_de_credito_al_proveedor;
                break;
            case "reporte_notas_de_credito_al_cliente":
                entra = usuario.reporte_notas_de_credito_al_cliente;
                break;
            case "reporte_articulos_inventario":
                entra = usuario.reporte_articulos_inventario;
                break;
            case "reporte_articulos_vendidos":
                entra = usuario.reporte_articulos_vendidos;
                break;
            case "reporte_articulos_de_venta":
                entra = usuario.reporte_articulos_de_venta;
                break;
            case "reporte_ventas_por_familia":
                entra = usuario.reporte_ventas_por_familia;
                break;
            case "reporte_entradas":
                entra = usuario.reporte_entradas;
                break;
            case "reporte_salidas":
                entra = usuario.reporte_salidas;
                break;
            case "reporte_caja":
                entra = usuario.reporte_caja;
                break;
            case "reporte_reajustes":
                entra = usuario.reporte_reajustes;
                break;
            case "configuracion":
                entra = usuario.configuracion;
                break;
            case "conf_tienda":
                entra = usuario.conf_tienda;
                break;
            case "conf_usuario":
                entra = usuario.conf_usuario;
                break;
            case "conf_sucursal":
                entra = usuario.conf_sucursal;
                break;
            case "conf_cliente":
                entra = usuario.conf_cliente;
                break;
            case "conf_proveedor":
                entra = usuario.conf_proveedor;
                break;
            case "conf_caja":
                entra = usuario.conf_caja;
                break;
            case "conf_destino_salida":
                entra = usuario.conf_destino_salida;
                break;
            case "conf_adicionales_nota_fel":
                entra = usuario.conf_adicionales_nota_fel;
                break;
            case "bitacora":
                entra = usuario.bitacora;
                break;
            case "peril":
                entra = usuario.perfil;
                break;
            case "despacho":
                entra = usuario.despacho;
                break;
            case "despacho_despachos":
                entra = usuario.despacho_despachos;
                break;
            case "despacho_recepciones":
                entra = usuario.despacho_recepciones;
                break;
            case "despacho_recepciones_orden_compra":
                entra = usuario.despacho_recepciones_orden_compra;
                break;
            case "orden_compra":
                entra = usuario.orden_compra;
                break;
            case "orden_venta":
                entra =
                    usuario.ov_mayorista ||
                    usuario.ov_minorista ||
                    usuario.ov_distribuidor;
                break;
            case "orden_online":
                entra = usuario.orden_online;
                break;
            case "bancos":
                entra = usuario.bancos;
                break;
            case "transportes":
                entra = usuario.transportes;
                break;
        }
        if (
            (usuario.is_superuser && item != "orden_venta") ||
            (usuario.es_propietario && item != "orden_venta")
        )
            entra = true;
        if (entra) return "";
        else return "d-none";
    };

    logOut(event) {
        this.props.logOut();
    }
    toggleSucursal() {
        this.setState({ ver_sucursal: !this.state.ver_sucursal });
    }
    render() {
        const {
            url,
            usuario,
            sucursales,
            sucursal,
            setSucursal,
            utiliza_fel,
            vista_bienvenida,
            tipo_punto_venta,
            conf_tienda_online,
        } = this.props;
        // state
        const { goToPerfil } = this.props;
        return (
            <div style={{ position: "relative" }}>
                <div
                    className={`v-menu v-menu-front ${
                        !this.state.ver_sucursal ? "" : "collapsed"
                    }`}
                >
                    <div
                        className="w-100flex-column align-items-center justify-content-center"
                        style={{ textAlign: "center", marginBottom: "30px", marginLeft: "25px" }}
                    >
                        <a
                            href="/#/dashboard"
                            className="imagen-sidebar d-flex align-items-center justify-content-center"
                        >
                            <img
                                className="img-responsive logo"
                                src={logo_remocar}
                                alt="Logo"
                            />
                            <img
                                className="img-responsive logo-icon"
                                src={logo_remocar}
                                alt="Logo"
                            />
                        </a>
                        {vista_bienvenida && (
                            <strong
                                className="texto-version"
                                onClick={() => {
                                    this.openModal();
                                }}
                            >
                                Versión {vista_bienvenida.version}
                            </strong>
                        )}
                    </div>
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "dashboard"
                        )} ${url.includes("/dashboard") ? "activo" : ""}`}
                        to={"/dashboard"}
                    >
                        <img title="Inicio" src={logo_inicio} alt="" />
                        <div>Inicio</div>
                    </Link>

                    <Accordion
                        className={`sidebar-item menu-item ${this.mostrarItem(
                            "bodega"
                        )} ${
                            url.includes("/bodega")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Bodega"
                                        src={icons.bodega}
                                        alt=""
                                    />
                                    <div>Inventario</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`sidebar-item menu-item ${this.mostrarItem(
                                        "bodega"
                                    )} ${
                                        url.includes("/bodega") ? "activo" : ""
                                    }`}
                                    to={"/bodega"}
                                >
                                    <img
                                        className=""
                                        title="Bodega"
                                        src={logo_bodega}
                                        alt=""
                                    />
                                    <div>Bodega</div>
                                </Link>
                                <Link
                                    className={`sidebar-item menu-item ${this.mostrarItem(
                                        "despacho"
                                    )} ${
                                        url.includes("/traslados")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/traslados"}
                                >
                                    <img
                                        className=""
                                        title="Traslados"
                                        src={icons.reporte_despachos}
                                        alt=""
                                    />
                                    <div>Traslados</div>
                                </Link>
                                <Link
                                    className={`sidebar-item menu-item ${this.mostrarItem(
                                        "despacho_recepciones"
                                    )} ${
                                        url.includes(
                                            "/recepciones_de_traslados"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/recepciones_de_traslados"}
                                >
                                    <img
                                        className=""
                                        title="Recibir_traslado"
                                        src={icons.despachos}
                                        alt=""
                                    />
                                    <div>Recibir de otra sucursal</div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    {/* ORDENES DE COMPRA */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_compra"
                        )} ${
                            url.includes("/ordenes_de_compra") ? "activo" : ""
                        }`}
                        to={"/ordenes_de_compra"}
                    >
                        <img
                            className=""
                            title="Ordenes de compra"
                            src={ordenes_compra_logo}
                            alt=""
                        />
                        <div>Ordenes de compra</div>
                    </Link>

                    {/* CUENTAS POR PAGAR */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_compra"
                        )} ${
                            url.includes("/cuentas_por_pagar") ? "activo" : ""
                        }`}
                        to={"/cuentas_por_pagar"}
                    >
                        <img
                            className=""
                            title="Familias"
                            src={cuentas_pagar_logo}
                            alt="Cuentas por pagar"
                        />
                        <div>Cuentas por pagar</div>
                    </Link>

                    {/* ORDENES DE VENTA */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_venta"
                        )} ${
                            url.includes("/ordenes_de_venta") ? "activo" : ""
                        }`}
                        to={"/ordenes_de_venta"}
                    >
                        <img
                            className=""
                            title="Ordenes de venta"
                            src={orden_venta}
                            alt=""
                        />
                        <div>Ordenes de venta</div>
                    </Link>

                    {/* CUENTAS POR PAGAR */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_venta"
                        )} ${
                            url.includes("/cuentas_por_cobrar") ? "activo" : ""
                        }`}
                        to={"/cuentas_por_cobrar"}
                    >
                        <img
                            className=""
                            title="Cuentas por cobrar"
                            src={cuentas_pagar_logo}
                            alt="Cuentas por cobrar"
                        />
                        <div>Cuentas por cobrar</div>
                    </Link>

                    {/* FAMILIA */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_compra"
                        )} ${url.includes("/familias") ? "activo" : ""}`}
                        to={"/familias"}
                    >
                        <img
                            className=""
                            title="Familias"
                            src={logo_familias}
                            alt=""
                        />
                        <div>Familias</div>
                    </Link>

                    {/* DESPACHOS */}
                    {/*                     
                    <Accordion
                        className={`sidebar-item menu-item ${this.mostrarItem(
                            "despacho"
                        )} ${
                            url.includes("/despacho") ||
                            url.includes("/recepciones_de_despachos")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Despachos"
                                        src={icons.despachos}
                                        alt=""
                                    />
                                    <div>Despachos</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item sub-menu ${this.mostrarItem(
                                        "despacho_despachos"
                                    )} ${
                                        url.includes("/despachos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/despachos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Despachos"
                                            src={icons.reporte_despachos}
                                            alt=""
                                        />
                                        <div>Despachar a otra sucursal</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item sub-menu ${this.mostrarItem(
                                        "despacho_recepciones"
                                    )} ${
                                        url.includes(
                                            "/recepciones_de_despachos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/recepciones_de_despachos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recepciones"
                                            src={icons.despachos}
                                            alt=""
                                        />
                                        <div>Recibir de otra sucursal</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item sub-menu ${this.mostrarItem(
                                        "despacho_recepciones_orden_compra"
                                    )} ${
                                        url.includes(
                                            "/recepciones_de_ordenes_de_compra"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/recepciones_de_ordenes_de_compra"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recepciones"
                                            src={icons.despachos}
                                            alt=""
                                        />
                                        <div>Recibir orden de compra</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion> */}

                    {/* PUNTO DE VENTA */}
                    {/* {tipo_punto_venta == PVN ? (
                        <Link
                            className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                "punto_venta"
                            )} ${
                                url.includes("/punto_de_venta") ? "activo" : ""
                            }`}
                            to={"/punto_de_venta"}
                        >
                            <img
                                className=""
                                title="Ventas"
                                src={icons.ventas}
                                alt=""
                            />
                            <div>Punto de Venta</div>
                        </Link>
                    ) : (
                        <div>
                            {usuario.punto_venta_mostrador && (
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "punto_venta_mostrador"
                                    )} ${
                                        url.includes(
                                            "/punto_de_venta/mostrador"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/punto_de_venta/mostrador"}
                                >
                                    <img
                                        className="sub-item  align-self-center"
                                        title="Mostrador"
                                        src={icons.mostrador}
                                        alt=""
                                    />
                                    <div>Mostrador</div>
                                </Link>
                            )}
                            {usuario.punto_venta_caja && (
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "punto_venta_caja"
                                    )} ${
                                        url.includes("/punto_de_venta/caja")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/punto_de_venta/caja"}
                                >
                                    <img
                                        className="sub-item  align-self-center"
                                        title="Caja"
                                        src={icons.punto_venta_caja}
                                        alt=""
                                    />
                                    <div>Caja</div>
                                </Link>
                            )}
                        </div>
                    )} */}

                    {/* PRODUCTOS */}
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "producto"
                        )} ${url.includes("/producto") ? "activo" : ""}`}
                        to={"/productos"}
                    >
                        <img
                            className=""
                            title="Productos"
                            src={icons.producto}
                            alt=""
                        />
                        <div>Productos</div>
                    </Link>

                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "orden_online"
                        )} ${
                            url.includes("/pedidos_en_linea") ? "activo" : ""
                        }`}
                        to={"/pedidos_en_linea"}
                    >
                        <img
                            className=""
                            title="Pedidos en línea"
                            src={icons.orden_online}
                            alt=""
                        />
                        <div>Pedidos en línea</div>
                    </Link>

                    {/* REPORTAR GASTOS */}
                    {/* <Link
                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                            "gasto"
                        )} ${url.includes("/gastos") ? "activo" : ""}`}
                        to={"/gastos"}
                    >
                        <img
                            className=""
                            title="Reportar Gastos"
                            src={icons.gastos}
                            alt=""
                        />
                        <div>Reportar Gastos</div>
                    </Link> */}

                    {/* REPORTES */}
                    <Accordion
                        className={`sidebar-item menu-item ${this.mostrarItem(
                            "reporte"
                        )} ${
                            url.includes("/reporte")
                                ? "activo parent no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Reportes"
                                        src={report_icon}
                                        alt=""
                                    />
                                    <div>Reportes</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {/* <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_balance_general"
                                    )} ${
                                        url.includes("/reporte_balance_general")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_balance_general"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Balance General"
                                            src={icons.balance_general}
                                            alt=""
                                        />
                                        <div>Balance General</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_apertura_cierre"
                                    )} ${
                                        url.includes(
                                            "/reporte_apertura_cierre_caja"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_apertura_cierre_caja"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Apertura y cierre"
                                            src={icons.apertura_cierre}
                                            alt=""
                                        />
                                        <div>Apertura y cierre</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_bodega"
                                    )} ${
                                        url.includes("/reporte_bodega")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_bodega"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Bodega"
                                            src={icons.reporte_bodega}
                                            alt=""
                                        />
                                        <div>Bodega</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_venta"
                                    )} ${
                                        url.includes("/reporte_ventas")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_ventas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Ventas"
                                            src={icons.reporte_ventas}
                                            alt=""
                                        />
                                        <div>Ventas</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_devolucion"
                                    )} ${
                                        url.includes("/reporte_devoluciones")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_devoluciones"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Devoluciones"
                                            src={icons.reporte_devoluciones}
                                            alt=""
                                        />
                                        <div>Devoluciones</div>
                                    </div>
                                </Link> */}
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_cuenta_por_cobrar"
                                    )} ${
                                        url.includes(
                                            "/reporte_cuenta_por_cobrar"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_cuenta_por_cobrar"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cuentas por cobrar"
                                            src={icons.cuentas_por_cobrar}
                                            alt=""
                                        />
                                        <div>Cuentas por cobrar</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_cuenta_por_pagar"
                                    )} ${
                                        url.includes(
                                            "/reporte_cuenta_por_pagar"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_cuenta_por_pagar"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cuentas por pagar"
                                            src={icons.cuentas_por_cobrar}
                                            alt=""
                                        />
                                        <div>Cuentas por pagar</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_kardex"
                                    )} ${
                                        url.includes("/reporte_kardex")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_kardex"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cuentas por cobrar"
                                            src={kardex_icon}
                                            alt=""
                                        />
                                        <div>Kardex</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_recibos_cuenta_por_cobrar"
                                    )} ${
                                        url.includes(
                                            "/reporte_recibos_cuenta_por_cobrar"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_recibos_cuenta_por_cobrar"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recibos Cuentas cobrar"
                                            src={icons.document_icon}
                                            alt=""
                                        />
                                        <div>Recibo cuentas cobrar</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_recibos_cuenta_por_pagar"
                                    )} ${
                                        url.includes(
                                            "/reporte_recibos_cuenta_por_pagar"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_recibos_cuenta_por_pagar"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Recibos Cuentas pagar"
                                            src={icons.document_icon}
                                            alt=""
                                        />
                                        <div>Recibo cuentas pagar</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_comisiones"
                                    )} ${
                                        url.includes("/reporte_comisiones")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_comisiones"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Comisiones"
                                            src={comisiones}
                                            alt=""
                                        />
                                        <div>Comisiones</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_comision_mensusal"
                                    )} ${
                                        url.includes("/comision_mensual")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/comision_mensual"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Comision venta mensual"
                                            src={comisiones}
                                            alt="Icono de comisiones"
                                        />
                                        <div>Comision mensual</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_notas_de_credito_al_proveedor"
                                    )} ${
                                        url.includes(
                                            "/notas_de_credito_al_proveedor"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/notas_de_credito_al_proveedor"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Notas credito proveedor"
                                            src={icons.document_icon}
                                            alt=""
                                        />
                                        <div>Notas credito prov.</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_notas_de_credito_al_cliente"
                                    )} ${
                                        url.includes(
                                            "/notas_de_credito_al_cliente"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/notas_de_credito_al_cliente"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Notas credito cliente"
                                            src={icons.document_icon}
                                            alt=""
                                        />
                                        <div>Notas credito cli.</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_articulos_inventario"
                                    )} ${
                                        url.includes(
                                            "/reporte_articulos_inventario"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_articulos_inventario"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Articulos Inventario"
                                            src={icons.productos_report}
                                            alt=""
                                        />
                                        <div>Articulos Inventario</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_articulos_vendidos"
                                    )} ${
                                        url.includes("/articulos_vendidos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/articulos_vendidos"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Artículos vendidos"
                                            src={articulos_venta}
                                            alt=""
                                        />
                                        <div>Artículos vendidos</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_articulos_de_venta"
                                    )} ${
                                        url.includes("/articulos_de_venta")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/articulos_de_venta"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Artículos de venta"
                                            src={icons.cambio}
                                            alt=""
                                        />
                                        <div>Artículos de venta</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_ventas_por_familia"
                                    )} ${
                                        url.includes("/reporte_ventas_por_familia")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/reporte_ventas_por_familia"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Ventas por familia"
                                            src={articulos_venta}
                                            alt=""
                                        />
                                        <div>Ventas por familia</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_entradas"
                                    )} ${
                                        url.includes("/entradas")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/entradas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Entradas"
                                            src={entradas_icono}
                                            alt=""
                                        />
                                        <div>Entradas</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_salidas"
                                    )} ${
                                        url.includes("/salidas") ? "activo" : ""
                                    }`}
                                    to={"/salidas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Salidas"
                                            src={salidas_icono}
                                            alt="Icono de salidas"
                                            style={{
                                                width: "15px",
                                                marginTop: "-3px",
                                            }}
                                        />
                                        <div>Salidas</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_caja"
                                    )} ${
                                        url.includes("/caja") ? "activo" : ""
                                    }`}
                                    to={"/caja"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Caja"
                                            src={caja_icono}
                                            alt="Icono de caja"
                                            style={{
                                                width: "15px",
                                                marginTop: "-3px",
                                            }}
                                        />
                                        <div>Caja</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "reporte_reajustes"
                                    )} ${
                                        url.includes("/reporte_reajustes") ? "activo" : ""
                                    }`}
                                    to={"/reporte_reajustes"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item align-self-center"
                                            title="Reajustes"
                                            src={caja_icono}
                                            alt="Icono de reajustes"
                                            style={{
                                                width: "15px",
                                                marginTop: "-3px"
                                            }}
                                        />
                                        <div>Reajustes</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>

                    <Accordion
                        className={`sidebar-item menu-item ${this.mostrarItem(
                            "configuracion"
                        )} ${
                            url.includes("/configuracion")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Configuración"
                                        src={icons.configuracion}
                                        alt=""
                                    />
                                    <div>Configuración</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                {/* <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_tienda"
                                    )} ${
                                        url.includes("/general") ? "activo" : ""
                                    }`}
                                    to={"/configuracion/general"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Tienda"
                                            src={icons.tienda}
                                            alt=""
                                        />
                                        <div>Tienda</div>
                                    </div>
                                </Link> */}
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_usuario"
                                    )} ${
                                        url.includes("/usuario") ? "activo" : ""
                                    }`}
                                    to={"/configuracion/usuarios"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Usuarios"
                                            src={icons.usuarios}
                                            alt=""
                                        />
                                        <div>Usuarios</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_sucursal"
                                    )} ${
                                        url.includes("/sucursal")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/sucursales"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Sucursales"
                                            src={icons.sucursales}
                                            alt=""
                                        />
                                        <div>Sucursales</div>
                                    </div>
                                </Link>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_cliente"
                                    )} ${
                                        url.includes("/clientes")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/clientes"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Clientes"
                                            src={icons.clientes}
                                            alt=""
                                        />
                                        <div>Clientes</div>
                                    </div>
                                </Link>

                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_proveedor"
                                    )} ${
                                        url.includes("/proveedores")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/proveedores"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Proveedores"
                                            src={icons.proveedores}
                                            alt=""
                                        />
                                        <div>Proveedores</div>
                                    </div>
                                </Link>
                                {/* <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_caja"
                                    )} ${
                                        url.includes("/configuracion/cajas")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/configuracion/cajas"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Cajas"
                                            src={icons.cajas}
                                            alt=""
                                        />
                                        <div>Cajas</div>
                                    </div>
                                </Link> */}
                                {/* <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "conf_destino_salida"
                                    )} ${
                                        url.includes(
                                            "/configuracion/destinos_salida_de_productos"
                                        )
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={
                                        "/configuracion/destinos_salida_de_productos"
                                    }
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <img
                                            className="sub-item  align-self-center"
                                            title="Destinos de salida"
                                            src={icons.destino_salida}
                                            alt=""
                                        />
                                        <div>Destinos de salida</div>
                                    </div>
                                </Link> */}
                                {/* {utiliza_fel && (
                                    <Link
                                        className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                            "conf_adicionales_nota_fel"
                                        )} ${
                                            url.includes(
                                                "/configuracion/adicionales_nota_fel"
                                            )
                                                ? "activo"
                                                : ""
                                        }`}
                                        to={
                                            "/configuracion/adicionales_nota_fel"
                                        }
                                    >
                                        <div className="d-flex flex-row align-content-start">
                                            <img
                                                className="sub-item  align-self-center"
                                                title="Destinos de salida"
                                                src={icons.adicionales_nota_fel}
                                                alt=""
                                            />
                                            <div>Adicionales nota crédito</div>
                                        </div>
                                    </Link>
                                )} */}
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>

                    {/* //*** Gestion de Bancos */}
                    <Accordion
                        className={`sidebar-item menu-item ${this.mostrarItem(
                            "bancos"
                        )} ${
                            url.includes("/bancos")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        className=""
                                        title="Bancos"
                                        src={icons.home}
                                        alt=""
                                    />
                                    <div>Bancos</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`sidebar-item menu-item ${
                                        url.includes("/bancos") ? "activo" : ""
                                    }`}
                                    to={"/bancos"}
                                >
                                    <img
                                        className=""
                                        title="Gestion de bancos"
                                        src={icons.home}
                                        alt=""
                                    />
                                    <div>Gestion de cuentas</div>
                                </Link>
                                <Link
                                    className={`sidebar-item menu-item ${this.mostrarItem(
                                        "bodega"
                                    )} ${
                                        url.includes("/ingreso_documentos")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/ingreso_documentos"}
                                >
                                    <img
                                        className=""
                                        title="Ingreso de documentos"
                                        src={icons.reporte_despachos}
                                        alt=""
                                    />
                                    <div>Ingreso de documentos</div>
                                </Link>
                                <Link
                                    className={`sidebar-item menu-item ${this.mostrarItem(
                                        "bodega"
                                    )} ${
                                        url.includes("/conciliacion_bancaria")
                                            ? "activo"
                                            : ""
                                    }`}
                                    to={"/conciliacion_bancaria"}
                                >
                                    <img
                                        className=""
                                        title="reporte de documentos"
                                        src={icons.despachos}
                                        alt=""
                                    />
                                    <div>Conciliacion bancaria</div>
                                </Link>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>

                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "bitacora"
                        )} ${url.includes("/bitacora") ? "activo" : ""}`}
                        to={"/bitacora"}
                    >
                        <img
                            className=""
                            title="Bitácora"
                            src={bitacora_logo}
                            alt=""
                        />
                        <div>Bitácora</div>
                    </Link>
                    <Link
                        className={`item-principal text-center sidebar-item menu-item ${this.mostrarItem(
                            "transportes"
                        )} ${url.includes("/transportes") ? "activo" : ""}`}
                        to={"/transportes"}
                    >
                        <img
                            className=""
                            title="Transportes"
                            src={transportes_icono}
                            alt=""
                        />
                        <div>Transportes</div>
                    </Link>
                    <Accordion
                        className={`sidebar-item menu-item d-block d-md-none ${
                            url.includes("/perfil")
                                ? "activo no-border-sidebar"
                                : ""
                        }`}
                    >
                        <AccordionItem>
                            <AccordionItemTitle>
                                <a
                                    href="#"
                                    className="blue-hover"
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {usuario.foto_cropped ? (
                                        <img
                                            className="img-usuario"
                                            src={usuario.foto_cropped}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="img-usuario"
                                            src={icons.perfil}
                                            alt=""
                                        />
                                    )}
                                    <div>{usuario.first_name}</div>
                                </a>
                                <div
                                    className="accordion__arrow"
                                    role="presentation"
                                />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                                <Link
                                    className={`text-center sidebar-item menu-item ${this.mostrarItem(
                                        "bitacora"
                                    )} ${
                                        url.includes("/perfil") ? "activo" : ""
                                    }`}
                                    to={"/perfil"}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <i
                                            className="fa fa-user"
                                            style={{
                                                fontSize: "20px",
                                                paddingRight: "10px",
                                            }}
                                        />
                                        <div>Perfil</div>
                                    </div>
                                </Link>
                            </AccordionItemBody>
                            <AccordionItemBody>
                                <a
                                    onClick={this.logOut}
                                    className={`text-center sidebar-item menu-item ${
                                        url.includes("/login") ? "activo" : ""
                                    }`}
                                    to={"/login"}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="d-flex flex-row align-content-start">
                                        <i
                                            className="fa fa-lock"
                                            style={{
                                                fontSize: "20px",
                                                paddingRight: "10px",
                                            }}
                                        />
                                        <div>Cerrrar sesión</div>
                                    </div>
                                </a>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    {/* <a href="/#/login" onClick={this.logOut} className="text-center sidebar-item menu-item logout"><em className="fa fa-sign-out" /><div>Cerrar Sesión</div></a> */}
                </div>
                {/* MODAL */}
                <Modal
                    open={this.state.open}
                    onClose={this.closeModal}
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                    classNames={{ modal: "modal-bienvenida" }}
                >
                    <div style={{ padding: "1.2rem" }} className="w-100">
                        <div className="row">
                            {vista_bienvenida && (
                                <Bienvenida
                                    formato={vista_bienvenida.formato}
                                    className="w-100"
                                    marcarVisto={this.marcarVisto}
                                />
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Menu;
