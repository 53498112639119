import { connect } from "react-redux";
import ModalFacturar from "./ModalFacturar";
import {
    facturarVenta,
    setEstadoDescargaValue,
    setDatosCliente,
} from "../../../../../redux/modules/venta/venta";

const ms2p = (state) => {
    return {
        estado_descarga: state.venta.estado_descarga,
        datos_cliente: state.venta.datos_cliente,
    };
};

const md2p = {
    facturarVenta: facturarVenta,
    setEstadoDescargaValue: setEstadoDescargaValue,
    setDatosCliente,
};

export default connect(ms2p, md2p)(ModalFacturar);
