import { connect } from "react-redux";
import ListadoCotizacion from "./ListadoCotizacion";
import { actions } from "../../../../redux/modules/cotizacion/cotizacion";
const ms2p = (state) => {
    return {
        ...state.cotizaciones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoCotizacion);
