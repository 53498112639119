import React, { useEffect, useState } from "react";

import { Form, Field } from "react-final-form";
import {
    renderSelectField,
    renderField,
    renderNumberFormat,
    renderTextArea,
} from "Utils/renderField/renderField";

import {
    composeValidators,
    maxValue,
    minValue,
    required,
} from "../../../../../utility/validation";
import { FORMAS_PAGO } from "../../../../../utility/variables";

const PagoAbonosForm = ({
    onSubmit,
    disabled,
    isModal = false,
    codigo_cobro,
    setCodigoCobro,
    saldo,
}) => {
    const [obtenerCodigo, setObtenerCodigo] = useState(false);

    useEffect(() => {
        if (obtenerCodigo) {
            setCodigoCobro();
        }
        return () => {
            setCodigoCobro(true);
        };
    }, [obtenerCodigo]);

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, form, values }) => {
                values.numero_documento =
                    codigo_cobro && codigo_cobro.codigo
                        ? codigo_cobro.codigo
                        : "";
                return (
                    <form onSubmit={handleSubmit} className="form-validate">
                        <div className="">
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="numero_documento">
                                        Codigo de pago
                                    </label>
                                    <Field
                                        name="numero_documento"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="forma_pago">
                                        Formas de pago
                                    </label>
                                    <Field
                                        name="forma_pago"
                                        component={renderSelectField}
                                        options={FORMAS_PAGO}
                                        placeholder="Seleccionar"
                                        type="text"
                                        className="form-control"
                                        parse={(value) => {
                                            setObtenerCodigo(true);
                                            return value;
                                        }}
                                        disabled={disabled}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="numero_recibo">
                                        Número de Recibo
                                    </label>
                                    <Field
                                        name="numero_recibo"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="monto">Monto</label>
                                    <Field
                                        name="monto"
                                        component={renderNumberFormat}
                                        type="text"
                                        prefix={"Q "}
                                        decimalScale={2}
                                        placeholder="Q"
                                        className="form-control"
                                        validate={composeValidators(
                                            required,
                                            minValue(0.01),
                                            maxValue(saldo)
                                        )}
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="observacion">
                                        Observación
                                    </label>
                                    <Field
                                        name="observacion"
                                        component={renderTextArea}
                                        options={FORMAS_PAGO}
                                        placeholder="Seleccionar"
                                        type="text"
                                        className="form-control"
                                        disabled={disabled}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>

                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-lg-end pb-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting || disabled}
                                >
                                    Registrar Pago
                                </button>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default PagoAbonosForm;
