import React, { Component } from "react";
import PropTypes from "prop-types";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { isArray, isObject } from "utility/validation";
import moment from "moment";

class Anterior extends Component {
    static propTypes = {
        detalle: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
    }
    formatoLlave(llave) {
        try {
            return llave.replace("_", " ");
        } catch (e) {
            return llave;
        }
    }

    formatoDetalle(valor, llave) {
        try {
            if (isObject(valor)) {
                return (
                    <div
                        key={llave}
                        className="d-flex flex-column p-2 ml-0 ml-sm-3"
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(
                                Number.isInteger(llave) ? llave + 1 : llave
                            )}
                            :
                        </b>
                        {Object.keys(valor)
                            .sort()
                            .map((llave2) => {
                                return this.formatoDetalle(
                                    valor[llave2],
                                    llave2
                                );
                            })}
                    </div>
                );
            }
            if (isArray(valor)) {
                return (
                    <div
                        key={llave}
                        className="d-flex flex-column p-2 ml-0 ml-sm-3"
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        {valor.map((llave2, index) => {
                            return this.formatoDetalle(llave2, index);
                        })}
                    </div>
                );
            }
            if (parseFloat(valor) === parseFloat(valor)) {
                let decimales = this.props.decimales ? this.props.decimales : 2;

                let result = Date.parse(valor);

                let aplicar3Decimales = llave.toUpperCase().includes("COSTO")
                    ? true
                    : !!llave.toUpperCase().includes("PRECIO");
                return (
                    <div
                        key={llave}
                        className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end"
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>
                            {isNaN(result) ? (
                                <RenderNumber
                                    value={valor}
                                    decimalScale={
                                        aplicar3Decimales ? decimales : 0
                                    }
                                />
                            ) : (
                                valor
                            )}
                        </div>
                    </div>
                );
            }
            if (valor === true) {
                return (
                    <div
                        key={llave}
                        className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end"
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>Sí</div>
                    </div>
                );
            }
            if (valor === false) {
                return (
                    <div
                        key={llave}
                        className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end"
                    >
                        <b style={{ textTransform: "capitalize", flex: "1" }}>
                            {this.formatoLlave(llave)}:
                        </b>
                        <div style={{ flex: "2" }}>No</div>
                    </div>
                );
            }
            return (
                <div
                    key={llave}
                    className="d-flex flex-row p-2 ml-0 ml-sm-3 align-items-end"
                >
                    <b style={{ textTransform: "capitalize", flex: "1" }}>
                        {this.formatoLlave(llave)}:
                    </b>
                    <div style={{ flex: "2" }}>{valor.toString()}</div>
                </div>
            );
        } catch (e) {
            return (
                <div key={llave} className="d-flex flex-row p-2 ml-0 ml-sm-3">
                    <b style={{ textTransform: "capitalize", flex: "1" }}>
                        {this.formatoLlave(llave)}:
                    </b>
                    <div style={{ flex: "2" }} />
                </div>
            );
        }
    }

    render() {
        const { detalle } = this.props;

        return (
            <div className="d-flex flex-1 flex-column">
                <h3 className="align-self-center">
                    <strong>Estado anterior</strong>
                </h3>
                {Object.keys(detalle).map((llave) => {
                    return this.formatoDetalle(detalle[llave], llave);
                })}
            </div>
        );
    }
}

export default Anterior;
