import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import numeral from "numeral";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

class ProductoDetalleRender extends Component {
    static propTypes = {
        producto: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }
    render() {
        //  state
        const { producto, btn_editar } = this.props;
        //  bind

        return (
            <div className="col-sm-12">
                <div className="grid-container padding-15">
                    <div className="grid-titulo padding-15">
                        {producto.nombre}
                    </div>
                    <div className="grid-cuerpo padding-15">
                        {/* IMAGEN */}
                        <div className="row">
                            <div className="col-sm-6 content-drop-zone">
                                <img
                                    src={producto.imagen}
                                    width="200"
                                    height="200"
                                />
                            </div>
                            <div className="col-sm-6 contentainer-prices-cost secondary">
                                <div>
                                    <strong>Costo: </strong>
                                    <RenderCurrency value={producto.precio} />
                                </div>
                                {/* <div>
                                    <strong>Precio mostrador: </strong><RenderCurrency value={producto.precio_mostrador_calculado} />
                                </div> */}
                            </div>
                        </div>

                        {/* INFO */}
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Nombre</label>
                                <div className="input-view">
                                    {producto.nombre}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Codigo</label>
                                <div className="input-view">
                                    {producto.codigo || "---"}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Código barras
                                </label>
                                <div className="input-view">
                                    {producto.codigo_barras || "---"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Subfamilia</label>
                                <div className="input-view">
                                    {(producto.subfamilia &&
                                        producto.subfamilia.nombre &&
                                        producto.subfamilia.nombre) ||
                                        "---"}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Código equivalente 1
                                </label>
                                <div className="input-view">
                                    {producto.codigo_equivalente_1 || "---"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Código equivalente 2
                                </label>
                                <div className="input-view">
                                    {producto.codigo_equivalente_2 || "---"}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Código equivalente 3
                                </label>
                                <div className="input-view">
                                    {producto.codigo_equivalente_3 || "---"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Precio de mostrador
                                </label>
                                <div className="input-view">
                                    Q. {producto.precio_mostrador_calculado}
                                </div>
                            </div>
                        </div>

                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Entradas totales
                                </label>
                                <div className="input-view">
                                    {producto.entradas_totales}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Salidas totales
                                </label>
                                <div className="input-view">
                                    {producto.salidas_totales}
                                </div>
                            </div>
                        </div>

                        <div className="row row-fields">
                            <div className="col-sm-12">
                                <label className="label-view">
                                    Descripción
                                </label>
                                <div className="producto-descripcion">
                                    {producto.descripcion}
                                </div>
                            </div>
                        </div>

                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Producto o servicio
                                </label>
                                <div className="input-view">
                                    {producto.producto_servicio
                                        ? "Servicio"
                                        : "Producto"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Kit</label>
                                <div className="input-view">
                                    {producto.kit ? "Si" : "No"}
                                </div>
                            </div>
                        </div>

                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Sacar de circulación
                                </label>
                                <div className="input-view">
                                    {producto.circulacion ? "Si" : "No"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Publicar en panel de clientes
                                </label>
                                <div className="input-view">
                                    {producto.publicar_panel_clientes
                                        ? "Si"
                                        : "No"}
                                </div>
                            </div>
                        </div>

                        <div className="border-bottom border-dark pt-4" />

                        <div className="d-flex mb-5 mt-4">
                            <div className="flex-2">
                                <label className="label-view">
                                    Usuario que realizó la ult. modificación
                                </label>
                                <div className="input-view">
                                    {producto.update_by || "---"}
                                </div>
                            </div>
                            <div className="flex-1" />

                            <div className="flex-2">
                                <label className="label-view">
                                    Fecha última compra
                                </label>
                                <div className="input-view">
                                    {producto.modificado}
                                </div>
                            </div>
                            <div className="flex-1" />

                            <div className="flex-2">
                                <label className="label-view">
                                    Último proveedor al que se le compró
                                </label>
                                <div className="input-view">
                                    {producto.ultimo_proveedor || "---"}
                                </div>
                            </div>
                            <div className="flex-1" />
                        </div>

                        <div className="d-flex mb-5 mt-4">
                            <div className="flex-2">
                                <label className="label-view">
                                    Fecha última venta
                                </label>
                                <div className="input-view">
                                    {producto.fecha_venta || "---"}
                                </div>
                            </div>
                            <div className="flex-1" />

                            <div className="flex-2">
                                <label className="label-view">
                                    Último movimiento
                                </label>
                                <div className="input-view">
                                    {producto.ultimo_movimiento || "---"}
                                </div>
                            </div>
                            <div className="flex-1" />

                            <div className="flex-2">
                                <label className="label-view">
                                    Último documento de entrada
                                </label>
                                <div className="input-view">
                                    {producto.ultimo_documento_entrada && (
                                        <Link
                                            className="btn btn-tertiary mr-1"
                                            to={`/productos/documento/${producto.ultimo_documento_entrada}`}
                                        >
                                            Ver documento
                                        </Link>
                                    )}
                                    {!producto.ultimo_documento_entrada && (
                                        <button
                                            className="btn btn-tertiary mr-1"
                                            disabled={true}
                                        >
                                            Ver documento
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="flex-1" />
                        </div>

                        <div className="d-flex mb-5 mt-4">
                            <div className="flex-2">
                                <label className="label-view">
                                    Último documento de salida
                                </label>
                                <div className="input-view">
                                    {producto.ultimo_documento_salida && (
                                        <Link
                                            className="btn btn-tertiary mr-1"
                                            to={`/productos/documento/${producto.ultimo_documento_salida}`}
                                        >
                                            Ver documento
                                        </Link>
                                    )}
                                    {!producto.ultimo_documento_salida && (
                                        <button
                                            className="btn btn-tertiary mr-1"
                                            disabled={true}
                                        >
                                            Ver documento
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="flex-1" />

                            <div className="flex-2"></div>
                            <div className="flex-1" />

                            <div className="flex-2"></div>
                            <div className="flex-1" />
                        </div>

                        {/* FOOTER */}
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                {!btn_editar && (
                                    <React.Fragment>
                                        <Link
                                            className="btn btn-tertiary mr"
                                            to="/productos/"
                                        >
                                            <i
                                                className="fa fa-arrow-left"
                                                aria-hidden="true"
                                            ></i>
                                            Regresar
                                        </Link>
                                        <Link
                                            className="btn btn-primary ml"
                                            to={
                                                "/productos/editar/" +
                                                producto.id
                                            }
                                        >
                                            <i
                                                className="fa fa-pencil"
                                                aria-hidden="true"
                                            ></i>
                                            Editar
                                        </Link>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductoDetalleRender;
