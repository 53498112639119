import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { formatoMonedaQuetzales } from "../../../Utils/CamposComunes";
import CambiarDatosForm from "./CambiarDatosForm";

const ModalFacturar = ({
    modal,
    estado_descarga,
    setModal,
    facturarVenta,
    setEstadoDescargaValue,
    es_orden_venta = false,
    datos_cliente = {},
    esNuevaOV = false,
    setDatosCliente = () => {},
}) => {
    const { open, openSecond, data } = modal;

    const no_doc_cliente = data.no_doc_cliente;
    const nombre_cliente = data.nombre_cliente;

    const datos_cliente_cambiados =
        no_doc_cliente && nombre_cliente ? true : false;

    const nit_cliente_actual = data.cliente
        ? data.cliente.nit || "CF"
        : data.cliente_proveedor
        ? data.cliente_proveedor.nit || "CF"
        : "CF";

    const nit_cliente = datos_cliente_cambiados
        ? no_doc_cliente
        : nit_cliente_actual;

    const nombre_cliente_actual =
        (data.cliente && data.cliente.nombre) ||
        (data.cliente_proveedor && data.cliente_proveedor.nombre);

    const limite_facturacion_cf = 2500;
    const total_facturar = data && data.total ? data.total : 0;
    const no_puede_facturar_cf =
        nit_cliente === "CF" && total_facturar > limite_facturacion_cf;

    useEffect(() => {
        setEstadoDescargaValue(false);

        return () => {
            setEstadoDescargaValue(false);
        };
    }, []);

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() =>
                    setModal({ open: false, data: {}, openSecond: false })
                }
                classNames={{ modal: "modal-md" }}
                center
            >
                <div
                    className="d-flex flex-column align-items-center justify-content-around px-4 py-3"
                    style={{ minWidth: "30vw", minHeight: "25vh" }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2 className="mb-3 font-weight-bolder">
                            ¿Facturar
                            {es_orden_venta ? " orden de venta" : " cuenta"}?
                        </h2>
                        <p>
                            Se facturará la
                            {es_orden_venta
                                ? " orden de venta "
                                : " cuenta por cobrar "}
                            al cliente{" "}
                            <strong>
                                {datos_cliente_cambiados
                                    ? nombre_cliente
                                    : nombre_cliente_actual}
                            </strong>
                        </p>
                        <p style={{ marginTop: -17 }}>
                            con el número de nit{" "}
                            <strong className="text-primary">
                                {nit_cliente}
                            </strong>
                        </p>

                        {no_puede_facturar_cf && (
                            <p className="text-danger">
                                <em>
                                    El total a facturar es mayor a Q.
                                    {formatoMonedaQuetzales(
                                        limite_facturacion_cf
                                    )}
                                    . por lo que se debe cambiar el número de
                                    nit del cliente.
                                </em>
                            </p>
                        )}
                    </div>

                    <div className="d-flex px-0 px-md-4">
                        <button
                            className="btn btn-outline-primary mr-3"
                            style={{ padding: ".6rem 2rem" }}
                            onClick={() => {
                                setModal({ ...modal, openSecond: true });
                            }}
                        >
                            Cambiar Datos
                        </button>

                        <button
                            className="btn btn-primary px-5"
                            style={{ padding: ".6rem 2rem" }}
                            disabled={estado_descarga || no_puede_facturar_cf}
                            onClick={() => {
                                if (datos_cliente_cambiados) {
                                    const data_cliente = {
                                        nombre: nombre_cliente,
                                        nit: no_doc_cliente,
                                    };
                                    facturarVenta(
                                        data.id_venta,
                                        false,
                                        setModal,
                                        data_cliente
                                    );
                                } else {
                                    facturarVenta(
                                        data.id_venta,
                                        false,
                                        setModal
                                    );
                                }
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga ? "fa-spinner fa-pulse" : ""
                                }`}
                            />
                            &nbsp;Continuar
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openSecond}
                onClose={() => setModal({ ...modal, openSecond: false })}
                classNames={{ modal: "modal-md" }}
                center
            >
                <div
                    className="d-flex flex-column align-items-center justify-content-around px-4 py-3"
                    style={{ minWidth: "30vw", minHeight: "25vh" }}
                >
                    <h2 className="mb-4 font-weight-bolder">
                        Cambiar datos del Cliente
                    </h2>

                    <CambiarDatosForm
                        textButton={esNuevaOV ? "Cambiar" : "Facturar"}
                        estado_descarga={estado_descarga}
                        modal={modal}
                        datos_cliente={datos_cliente}
                        setModal={setModal}
                        onSubmit={(data_cliente) => {
                            const { nombre, nit, tipoId } = data_cliente;

                            if (esNuevaOV) {
                                setDatosCliente({
                                    cliente: {
                                        nombre_cliente: nombre,
                                        no_doc_cliente: nit,
                                    },
                                    datos_cambiados: true,
                                    tipo_documento: tipoId,
                                });
                                setModal({ ...modal, openSecond: false });
                            } else if (!esNuevaOV) {
                                facturarVenta(
                                    data.id_venta,
                                    false,
                                    setModal,
                                    data_cliente
                                );
                            }
                        }}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ModalFacturar;
