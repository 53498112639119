import React from "react";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { required } from "../../../../../utility/validation";
import { renderField } from "Utils/renderField/renderField";
import {
    renderNumberFormat,
    renderSwitch,
    renderTextArea,
} from "../../../Utils/renderField/renderField";

const BancosForm = ({ intitalData, onSubmit, ver = false, editar = false }) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{  saldo: 0 , ...intitalData}}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="mb-5 card card-small px-3 py-4">
                            <h3 className="contenido-1 border-bottom txt-bold black mb-2">
                                Datos de la cuenta
                            </h3>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="nombre_banco">Banco</label>
                                    <Field
                                        name="nombre_banco"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="numero_cuenta">
                                        No. Cuenta
                                    </label>
                                    <Field
                                        name="numero_cuenta"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="direccion">Dirección</label>
                                    <Field
                                        name="direccion"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="saldo">Saldo</label>
                                    <Field
                                        name="saldo"
                                        component={renderNumberFormat}
                                        type="text"
                                        prefix={"Q "}
                                        decimalScale={2}
                                        placeholder="Q"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="observacion">
                                        Observación
                                    </label>
                                    <Field
                                        name="observacion"
                                        component={renderTextArea}
                                        type="text"
                                        rows={3}
                                        className="form-control"
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="acepta_tarjeta">
                                        Acepta tarjeta
                                    </label>
                                    <div className="d-flex align-content-end mt-2">
                                        <label className="mr-2">No</label>
                                        <Field
                                            name="acepta_tarjeta"
                                            component={renderSwitch}
                                            type="text"
                                            className="form-control"
                                            disabled={ver}
                                        />
                                        <label className="ml-2">Si</label>
                                    </div>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-center py-4">
                                <Link
                                    to="/bancos/"
                                    className="btn btn-outline-secondary mt-4 mt-md-0 mr-0 mr-md-3"
                                >
                                    Volver
                                </Link>
                                {!ver && (
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        disabled={submitting}
                                    >
                                        {editar ? "Editar" : "Agregar"} Banco
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default BancosForm;
