import { connect } from "react-redux";
import Listado from "./ListadoOrdenOnline";
import { actions } from "../../../../redux/modules/orden_online/orden_online";

const ms2p = (state) => ({
    ...state.ordenes_online,
    sucursal: state.usuario.sucursal,
    config: state.configuracion.config,
    es_gerente: state.usuario.me.es_gerente,
    es_jefe_bodega: state.usuario.me.es_jefe_bodega,
});

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Listado);
