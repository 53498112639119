import React from "react";

import DatePicker from "Utils/DatePicker";
import { Async } from "react-select";
import moment from "moment";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
import Select from "react-select/lib/Select";
import { TIPO_COTIZACION, TIPO_VENTA } from "../../../../utility/variables";

const listado_usuarios = [];
const listado_clientes = [];
const Filtros = ({
    fecha_inicial,
    fecha_final,
    vendedor,
    cliente,
    tipo_cotizacion,
    tipo_venta,
    setFechaInicial,
    setFechaFinal,
    setVendedor,
    setCliente,
    setTipoCotizacion,
    setTipoVenta,
    fitro_por_tipo = false,
    fitro_tipo_venta = false,
}) => {
    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        { es_vendedor: 1 }
    );

    const { getOptions: getClientes } = useAsyncSearch(
        "clientes",
        listado_clientes,
        { es_cliente: 1 }
    );

    return (
        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
            <div className="flex-3">
                <label htmlFor="">Fecha inicial</label>
                <DatePicker
                    value={fecha_inicial}
                    maxDate={
                        new Date(
                            moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")
                        )
                    }
                    onChange={(date) => {
                        setFechaInicial(date);
                    }}
                />
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
                <label htmlFor="">Fecha final</label>
                <DatePicker
                    value={fecha_final}
                    minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                    onChange={(date) => {
                        setFechaFinal(date);
                    }}
                />
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
                <label>Vendedor</label>
                <Async
                    className="form-control"
                    labelKey="first_name"
                    valueKey="id"
                    searchPromptText="Escriba para buscar"
                    loadOptions={getUsuarios}
                    placeholder={"Vendedor"}
                    onChange={(e) => {
                        setVendedor(e);
                    }}
                    value={vendedor}
                />
            </div>

            <div className="flex-1"></div>
            <div className="flex-3">
                <label>Cliente</label>
                <Async
                    className="form-control"
                    labelKey="nombre"
                    valueKey="id"
                    searchPromptText="Escriba para buscar"
                    loadOptions={getClientes}
                    placeholder={"Cliente"}
                    onChange={(e) => {
                        setCliente(e);
                    }}
                    value={cliente}
                />
            </div>

            {fitro_por_tipo && (
                <React.Fragment>
                    <div className="flex-1"></div>
                    <div className="flex-3">
                        <label>Tipo Cotizacion</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable
                            className="form-control"
                            options={TIPO_COTIZACION}
                            valueKey="value"
                            labelKey="label"
                            value={tipo_cotizacion}
                            onChange={(e) => {
                                return setTipoCotizacion(e ? e.value : "");
                            }}
                        />
                    </div>
                </React.Fragment>
            )}

            {fitro_tipo_venta && (
                <React.Fragment>
                    <div className="flex-1"></div>
                    <div className="flex-3">
                        <label>Tipo Venta</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable
                            className="form-control"
                            options={TIPO_VENTA}
                            valueKey="value"
                            labelKey="label"
                            value={tipo_venta}
                            onChange={(e) => {
                                return setTipoVenta(e ? e.value : "");
                            }}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default Filtros;
