import React from "react";

import { Form, Field } from "react-final-form";
import {
    required,
    email,
    composeValidators,
} from "../../../../utility/validation";
import {
    renderField,
    renderNumberFormat,
} from "../../Utils/renderField/renderField";

import ContactosTabla from "./ContactosTabla";

const ContactosForm = ({ proveedores, setProveedores }) => {
    const { contactos, nuevos_contactos } = proveedores;

    // Generador de un id
    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, "");
        return `ID${id}`;
    };

    // Submit al formulario Contactos del proveedor
    const onSubmit = (value, event) => {
        value.id = makeRandomId();
        setProveedores({
            ...proveedores,
            contactos: [...contactos, value],
            nuevos_contactos: [...nuevos_contactos, value],
        });

        // Limpiar el formulario
        event.reset({
            nombre: "",
            puesto: "",
            telefono: "",
            correo: "",
        });
    };
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                // initialValues={proveedorDatos}
                render={({ handleSubmit, submitting, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-5">
                            Contactos del proveedor
                        </h3>
                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="nombre">Nombre</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    placeholder=""
                                    type="text"
                                    className=""
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="puesto">Puesto</label>
                                <Field
                                    name="puesto"
                                    component={renderField}
                                    placeholder=""
                                    type="text"
                                    className=""
                                    // validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="telefono">Teléfono</label>
                                <Field
                                    name="telefono"
                                    component={renderNumberFormat}
                                    numberFormat="#### ####"
                                    placeholder=""
                                    type="number"
                                    className="form-control"
                                    // validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>

                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="correo">
                                    Correo electrónico
                                </label>
                                <Field
                                    name="correo"
                                    component={renderField}
                                    placeholder="@"
                                    type="text"
                                    className="form-control"
                                    validate={composeValidators(
                                        email,
                                        // required
                                    )}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-end py-4">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={submitting}
                            >
                                Agregar Contacto
                            </button>
                        </div>
                    </form>
                )}
            />
            <ContactosTabla
                setProveedores={setProveedores}
                proveedores={proveedores}
            />
        </React.Fragment>
    );
};

export default ContactosForm;
