import { connect } from "react-redux";
import CrearOrdenCompra from "./CrearOrdenCompra";
import {
    actions,
    setVerBuscador,
} from "../../../../redux/modules/orden_compra/orden_compra";
import { formValueSelector } from "redux-form";

const selector = formValueSelector("NuevaOrdenCompra");

const ms2p = (state) => {
    let total = 0;
    state.ordenes_compra.seleccionados.forEach((producto) => {
        // Tipo de origen, 1 importacion o 2 local
        const origen = selector(state, "origen") || 2;
        const tipo_cambio = selector(state, "tipo_cambio") || 0;

        // Costo del producto, depende del tipo de origen
        const costo_valor =
            origen === "1"
                ? producto.costo_dolares * tipo_cambio
                : producto.costo;

        const costo = costo_valor ? parseFloat(costo_valor) : 0;
        const cantidad = producto.cantidad ? parseFloat(producto.cantidad) : 0;

        // Calculo del porcentaje del costo de importacion
        const costo_importacion =
            (selector(state, "costo_importacion") || 0) / 100;
        const total_temp = costo * cantidad; // Total sin el porcentaje
        const porcentaje =
            costo_importacion >= 0 ? total_temp * costo_importacion : 0;
        total = total_temp + porcentaje + total; // Total con el porcentaje, si es mayor a 0
    });
    return {
        sucursal: state.usuario.sucursal,
        ...state.ordenes_compra,
        pais: state.usuario.me.pais,
        conf_empresa: state.usuario,
        total,
        costo_importacion: selector(state, "costo_importacion"),
        origen: selector(state, "origen") || 2,
        tipo_cambio: selector(state, "tipo_cambio") || 0,
        tipo_compra: selector(state, "tipo_compra") || 0,
    };
};

const md2p = {
    ...actions,
    setVerBuscador: (estado) => (dispatch) => dispatch(setVerBuscador(estado)),
};

export default connect(ms2p, md2p)(CrearOrdenCompra);
