import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import { tableOptions } from "Utils/tableOptions";
import {
    RenderCurrency,
    RenderCurrencySimple,
} from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReporteInv } from "../../../Utils/FiltrosReportes";

const ListadoArticulosInventario = (props) => {
    useEffect(() => {
        props.limpiarFiltros();
        return () => {
            props.limpiarFiltros();
        };
    }, []);

    // state
    const {
        loader,
        data,
        estado_descarga,

        sucursal = null,
        estado_inventario = "",
        familia = "",
        subfamilia = "",
        precio = "",
        usuario = null,
        sucursal_param = null,
    } = props;
    // bind
    const {
        descargarListado,
        aplicarFiltros,

        setSucursal,
        setEstadoInventario,
        setFamilia,
        setSubfamilia,
        setPrecio,
    } = props;

    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: props.listar,
        onSortChange: props.sortChange,
        page: props.page,
    };

    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReporteInv
                        sucursal={sucursal}
                        estado_inventario={estado_inventario}
                        familia={familia}
                        subfamilia={subfamilia}
                        precio={precio}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        setSucursal={setSucursal}
                        setEstadoInventario={setEstadoInventario}
                        setFamilia={setFamilia}
                        setSubfamilia={setSubfamilia}
                        setPrecio={setPrecio}
                        aplicarFiltros={aplicarFiltros}
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="codigo_producto">
                                    Código
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo_equivalente">
                                    Código Equivalente
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="nombre_producto">
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="costo_quetzales"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Costo Q
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="costo_dolares_producto"
                                    dataFormat={(cell) => (
                                        <RenderCurrencySimple
                                            value={cell}
                                            prefix="$"
                                        />
                                    )}
                                >
                                    Costo $
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="costo_calculado"
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Precio
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="total_inventario"
                                    dataAlign="right"
                                    dataFormat={(cell) => cell || 0}
                                >
                                    Saldo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                ></TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoArticulosInventario.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoArticulosInventario;
