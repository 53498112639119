import { connect } from "react-redux";
import { actions } from "../../../../../../redux/modules/reportes/cuenta_cobrar";
import TableCreditos from "./TableCreditos";

const ms2p = (state) => {
    return {
        ...state.reporte_cuenta_cobrar,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TableCreditos);
