import React from "react"

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { RenderCurrency } from "../../../../../Utils/renderField/renderReadField"

const ProductosSubTabla = row => {
    return (
        <div className="border border-primary p-2 mt-2 mb-3 bg-blanco">
            <BootstrapTable
                data={row.detalles}
                remote={false}
            >
                <TableHeaderColumn
                    dataField="codigo"
                    isKey
                >
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                >
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataFormat={cell => <RenderCurrency value={cell} />}
                >
                    Costo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_ajustada"
                >
                    Cantidad Ajustada
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="subtotal"
                    dataFormat={cell => <RenderCurrency value={cell} />}
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    )
}

export default ProductosSubTabla