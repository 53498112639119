import React, { useEffect, useRef, useState } from "react";

import "react-responsive-modal/styles.css";
import { Modal as RModal } from "react-responsive-modal";
import PagoCompletoForm from "../../Detalle/PagosForm/PagoCompletoForm";
import CuentasPorPagarTable from "./CuentasPorPagarTable";

import "./Modal.css";

const Modal = ({
    seleccionados,
    cuenta_pagar,
    finalizarMultiplesCuentas,
    total = 0,
    disabled_modal,
    codigo_cobro,
    setCodigoCobro,
}) => {
    // Modal
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const onSubmit = (data) => {
        data.cuentas_a_pagar = cuenta_pagar;
        finalizarMultiplesCuentas(data, total, setOpen);
        // setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setCodigoCobro();
        }
    }, [open]);

    return (
        <div>
            <button
                type="button"
                className="btn btn-primary px-4"
                onClick={onOpenModal}
                disabled={disabled_modal}
            >
                Realizar cobros
            </button>

            <RModal
                open={open}
                onClose={onCloseModal}
                classNames={{ modal: "modal-lg" }}
                center
            >
                <h2>Finalizar cobros</h2>
                <PagoCompletoForm
                    onSubmit={onSubmit}
                    disabled={false}
                    isModal={true}
                    codigo_cobro={codigo_cobro}
                    setCodigoCobro={setCodigoCobro}
                />

                <CuentasPorPagarTable data={cuenta_pagar} total={total} />
            </RModal>
        </div>
    );
};

export default Modal;
