import { connect } from "react-redux";
import ListadoOrdenVenta from "./ListadoOrdenVenta";
import { actions } from "../../../../redux/modules/orden_venta";
import {
    reImpresionVenta,
    reImprimirCotizacion,
    limpiarVentaCotizacion,
    facturarVenta,
} from "../../../../redux/modules/venta/venta";

const ms2p = (state) => {
    return {
        ...state.orden_venta,
        print_state: state.print,
        //print_state_cotizacion: state.venta.print_state,
        venta_cotizacion: state.venta.venta_cotizacion,
        utiliza_fel: state.usuario.utiliza_fel,
        factura_personalizada: state.usuario.factura_personalizada,
        estado_descarga: state.venta.estado_descarga,
    };
};

const md2p = {
    ...actions,
    re_imprimir: reImpresionVenta,
    reImprimirCotizacion: reImprimirCotizacion,
    limpiarVentaCotizacion: limpiarVentaCotizacion,
    facturarVenta: facturarVenta,
};

export default connect(ms2p, md2p)(ListadoOrdenVenta);
