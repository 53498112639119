import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";

class DetalleDespacho extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDespacho(this.props.match.params.id);
    }

    render() {
        const { despacho, loader } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;

        if (!despacho) {
            return (
                <div className="grid-container padding-20 text-center">
                    <h2>Detalle no encontrado</h2>
                    <Link
                        className="btn btn-tertiary"
                        to={"/recepciones_de_traslados"}
                    >
                        <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                        Regresar
                    </Link>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-1">
                        <LoadMask loading={loader} light blur radius>
                            <div className="form-group">
                                <div className="grid-titulo padding-15">
                                    Encabezado
                                </div>
                                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1 mt">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado desde
                                            </label>
                                            <div className="input-view">
                                                {despacho.sucursal.nombre}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Fecha y hora de recepción
                                            </label>
                                            <div className="input-view">
                                                <RenderDateTime
                                                    fecha={
                                                        despacho.fecha_recepcion
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.usuario.first_name}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Transportado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.responsable_transporte
                                                    ? despacho.responsable_transporte
                                                    : "-----"}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Recibido por
                                            </label>
                                            <div className="input-view">
                                                {
                                                    despacho.recepcionado_por
                                                        .first_name
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <Link
                                                className="btn btn-primary mr-1 mt-2"
                                                to={`/recepciones_de_traslados/nota_de_recepcion/${despacho.id}`}
                                            >
                                                Ver documento
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row mt">
                                        <div className="col-12 negro-dark2 font-weight-bold">
                                            <h4>Listado</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BootstrapTable
                                data={loader ? [] : despacho.detalles}
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    width="0%"
                                    dataField="codigo"
                                    isKey
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <span>
                                                    <b>{row.producto}</b>
                                                </span>
                                            </div>
                                        );
                                    }}
                                >
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    width="100px"
                                    dataField="cantidad"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderNumero monto={cell} />;
                                    }}
                                >
                                    Enviados
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    width="100px"
                                    dataField="cantidad_recibido"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderNumero monto={cell} />;
                                    }}
                                >
                                    Recibidos
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nota"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                >
                                    Notas
                                </TableHeaderColumn>
                            </BootstrapTable>
                            <div className="row d-flex align-items-center justify-content-center mt">
                                <Link
                                    className="btn btn-tertiary"
                                    to={"/recepciones_de_traslados"}
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    />{" "}
                                    Regresar{" "}
                                </Link>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

DetalleDespacho.propTypes = {};

export default DetalleDespacho;
