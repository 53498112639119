import { api } from "api";
import Swal from "sweetalert2";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";
import { TIPO_CLIENTE } from "../../../utility/constantes";

const endpoint = "bodegas";

export const DATA = "ARTICULOS_INVENTARIO_DATA";
export const LOADER = "ARTICULOS_INVENTARIO_LOADER";
export const SORT = "ARTICULOS_INVENTARIO_SORT";
export const PAGE = "ARTICULOS_INVENTARIO_PAGE";
export const ESTADO_DESCARGA = "ARTICULOS_INVENTARIO_ESTADO_DESCARGA";
export const FILTRO = "REPORTE_GASTOS_FILTRO";

export const SUCURSAL = "ARTICULOS_INVENTARIO_SUCURSAL";
export const ESTADO_INVENTARIO = "ARTICULOS_INVENTARIO_ESTADO_INVENTARIO";
export const FAMILIA = "ARTICULOS_INVENTARIO_FAMILIA";
export const SUBFAMILIA = "ARTICULOS_INVENTARIO_SUBFAMILIA";
export const PRECIO = "ARTICULOS_INVENTARIO_PRECIO";

// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();

        const {
            ordering,
            filtro,

            estado_inventario,
            familia,
            subfamilia,
            precio,
            sucursal,
        } = store.reporte_articulos_inventario;

        // const estado_inventario_id = estado_inventario
        //     ? estado_inventario.id
        //     : "";

        const precio_value = precio ? precio.id : "";
        let params = {
            ordering,
            filtro,
            page,

            estado_inventario,
            familia,
            subfamilia,
            precio: precio_value,
            param_s: sucursal ? sucursal : "",
        };

        api.get(`${endpoint}/reporte_articulos_inventario`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const setSucursal = (value) => (dispatch) => {
    dispatch(_setSucursal(value));
    // dispatch(listar());
};

export const setEstadoInventario = (value) => (dispatch) => {
    dispatch(_setEstadoInventario(value));
    // dispatch(listar());
};

export const setFamilia = (value) => (dispatch) => {
    dispatch(_setFamilia(value));
    // dispatch(listar());
};

export const setSubfamilia = (value) => (dispatch) => {
    dispatch(_setSubfamilia(value));
    // dispatch(listar());
};

export const setPrecio = (value) => (dispatch) => {
    dispatch(_setPrecio(value));
    // dispatch(listar());
};

export const aplicarFiltros =
    (esReporteRecibo = false) =>
    (dispatch) => {
        dispatch(listar());
    };

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const filtroChange = (tipo) => (dispatch) => {
    dispatch(setFiltro(tipo));
    dispatch(listar());
};

export const descargarListado =
    (esReporteRecibo = false) =>
    (dispatch, getStore) => {
        const store = getStore();

        const {
            ordering,
            filtro,

            estado_inventario,
            familia,
            subfamilia,
            precio,
            sucursal,
        } = store.reporte_articulos_inventario;

        const precio_value = precio ? precio.id : "";
        let params = {
            ordering,
            filtro,

            estado_inventario,
            familia,
            subfamilia,
            precio: precio_value,
            param_s: sucursal ? sucursal : "",
        };

        dispatch(setEstadoDescarga(true));
        api.get(
            `${endpoint}/${
                esReporteRecibo
                    ? "descargar_excel_reporte_recibo"
                    : "descargar_excel_reporte"
            }`,
            params
        )
            .catch((err) => {
                Swal(
                    "¡Error al descargar!",
                    "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                    "error"
                );
                dispatch(setEstadoDescarga(false));
            })
            .then((data) => {
                if (data) {
                    Swal(
                        "¡Descarga en proceso!",
                        "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                        "info"
                    );
                    dispatch(setEstadoDescarga(true));
                    dispatch(esperarDescarga(data.id));
                }
            });
    };

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const limpiarFiltros =
    (enReporteRecibo = false) =>
    (dispatch) => {
        console.log("limpiando", enReporteRecibo);
        // if (enReporteRecibo) {
        //     dispatch(listarReporteRecibo());
        // } else {
        //     dispatch(listar());
        // }

        dispatch(setSucursal(null));

        // dispatch(setDataResumen({}));
        // dispatch(setDetalle({}));

        dispatch(setEstadoInventario(""));
        dispatch(setPrecio(TIPO_CLIENTE[1]));
        dispatch(setSubfamilia(""));
        dispatch(setFiltro(""));
        dispatch(setFamilia(""));
        dispatch(setData({}));
    };
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setFiltro = (filtro) => ({
    type: FILTRO,
    filtro,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setEstadoInventario = (estado_inventario) => ({
    type: ESTADO_INVENTARIO,
    estado_inventario,
});

export const _setFamilia = (familia) => ({
    type: FAMILIA,
    familia,
});

export const _setSubfamilia = (subfamilia) => ({
    type: SUBFAMILIA,
    subfamilia,
});

export const _setPrecio = (precio) => ({
    type: PRECIO,
    precio,
});

export const actions = {
    listar,

    sortChange,
    filtroChange,

    setSucursal,
    setEstadoInventario,
    setFamilia,
    setSubfamilia,
    setPrecio,
    aplicarFiltros,

    descargarListado,
    limpiarFiltros,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },

    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [ESTADO_INVENTARIO]: (state, { estado_inventario }) => {
        return {
            ...state,
            estado_inventario,
        };
    },
    [FAMILIA]: (state, { familia }) => {
        return {
            ...state,
            familia,
        };
    },
    [SUBFAMILIA]: (state, { subfamilia }) => {
        return {
            ...state,
            subfamilia,
        };
    },
    [PRECIO]: (state, { precio }) => {
        return {
            ...state,
            precio,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    ordering: "",
    loader: false,
    filtro: "",
    estado_descarga: false,

    sucursal: "",
    estado_inventario: "",
    familia: "",
    subfamilia: "",
    precio: TIPO_CLIENTE[1],
};

export default handleActions(reducers, initialState);
