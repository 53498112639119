import React, { useEffect, useState } from "react";

import { PrintContainer, PrintComponent } from "Utils/Print";
import ReciboPago from "Utils/Recibo/ReciboPago";

export const DetalleOrdenCompra = (props) => {
    const [sucursal, setSucursal] = useState("");
    const { documentos_pago, print_state, match, getDocumentosPagos, print } =
        props;
    useEffect(() => {
        getDocumentosPagos(match.params.id);
    }, []);

    useEffect(() => {
        if (documentos_pago) {
            let sucursal_data = documentos_pago[0]
                ? documentos_pago[0].sucursal
                : {};
            setSucursal(sucursal_data);
        }
    }, [documentos_pago]);

    const enReciborPorPagar = match.path.includes(
        "reporte_recibos_cuenta_por_pagar"
    );

    return (
        <div className="row">
            {print_state.flag && <PrintComponent />}

            <div className="col-md-12 mt-4">
                <PrintContainer name="orden">
                    <ReciboPago
                        {...props}
                        movimiento={documentos_pago ? documentos_pago : {}}
                        sucursal={sucursal}
                        tipo={"Ingreso"}
                        footerText={"Costo Total"}
                        enReciborPorPagar={enReciborPorPagar}
                    />
                </PrintContainer>
            </div>

            <button
                onClick={(e) => {
                    e.preventDefault();
                    print("orden");
                }}
                className="btn btn-secondary mt-3"
                type={"button"}
            >
                <em className="fa fa-print" /> Imprimir
            </button>
        </div>
    );
};
export default DetalleOrdenCompra;
