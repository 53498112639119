import { handleActions } from "redux-actions";
import _ from "lodash";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import { api } from "api";
import uuidv1 from "uuid/v1";
import { ToastStore } from "react-toasts";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------

export const PRODUCTOS = "DEVOLUCION_PRODUCTOS";
export const SELECCIONADOS = "DEVOLUCION_SELECCIONADOS";
export const MOVIMIENTO = "DEVOLUCION_MOVIMIENTO";
export const PAGE = "DEVOLUCION_PAGE";
export const BUSCADOR = "DEVOLUCION_BUSCADOR";
export const SORT = "DEVOLUCION_SORT";
export const LOADER = "DEVOLUCION_LOADER";
export const PAGINAS_IMPRESION = "DEVOLUCION_PAGINAS_IMPRESION";
export const UUID_REQ_PRODUCTOS = "VENTA_UUID_REQ_PRODUCTOS";
export const ESTADO_DESCARGA = "DEVOLUCION_ESTADO_DESCARGA";
export const FILTRO = "DEVOLUCION_FILTRO";
export const ORDENES_COMPRA = "DEVOLUCION_ORDENES_COMPRA";
export const FECHA_COMPRA_INICIAL = "FECHA_COMPRA_INICIAL";
export const FECHA_COMPRA_FINAL = "FECHA_COMPRA_FINAL";
export const CODIGO_DOC = "CODIGO_DOC";
const PROVEEDOR_CLIENTE = "PROVEEDOR_CLIENTE";

export const constants = {
    PRODUCTOS,
    SELECCIONADOS,
    MOVIMIENTO,
    PAGE,
    BUSCADOR,
    SORT,
    LOADER,
};

const endpoint = "ordenes_compra";

// ------------------------------------
// Actions
// ------------------------------------

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();

        const devolucion = store.devoluciones;
        const {
            search,
            fecha_compra_final,
            fecha_compra_inicial,
            codigo_doc,
            proveedor_cliente,
        } = devolucion;

        // Obtener el id del usuario, si este existe
        const proveedor_id = proveedor_cliente ? proveedor_cliente.id : "";

        const filtros = {
            page,
            ordering: "-fecha",
            search: search,
            mostarar_todas_ordenes: true,
            codigo_doc,
            proveedor_id,
        };
        if (fecha_compra_final) {
            filtros.fecha_compra_final =
                moment(fecha_compra_final).format("YYYY-MM-DD");
        }
        if (fecha_compra_inicial) {
            filtros.fecha_compra_inicial =
                moment(fecha_compra_inicial).format("YYYY-MM-DD");
        }

        api.get(`${endpoint}/listar_productos_oc`, filtros)
            .then((data) => {
                if (data) {
                    dispatch(setOrdenes(data));
                }
                dispatch(setPage(page));
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listarOV =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();

        const devolucion = store.devoluciones;
        const {
            search,
            fecha_compra_final,
            fecha_compra_inicial,
            codigo_doc,
            proveedor_cliente,
        } = devolucion;

        // Obtener el id del usuario, si este existe
        const cliente_id = proveedor_cliente ? proveedor_cliente.id : "";

        const filtros = {
            page,
            ordering: "-fecha",
            search: search,
            mostarar_todas_ordenes: true,
            codigo_doc,
            cliente_id,
        };
        if (fecha_compra_final) {
            filtros.fecha_compra_final =
                moment(fecha_compra_final).format("YYYY-MM-DD");
        }
        if (fecha_compra_inicial) {
            filtros.fecha_compra_inicial =
                moment(fecha_compra_inicial).format("YYYY-MM-DD");
        }

        api.get(`ordenes_venta/listar_productos_ov`, filtros)
            .then((data) => {
                if (data) {
                    dispatch(setOrdenes(data));
                }
                dispatch(setPage(page));
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    // dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.devoluciones.page;
    dispatch(listar(page));
};

export const agregar = (producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.devoluciones.seleccionados;
    if (
        _.find(seleccionados, { id: producto.id, no_orden: producto.no_orden })
    ) {
        // dispatch(buscar(""));
        // dispatch(listar());
    } else {
        let producto_data = _.cloneDeep(producto);

        dispatch(setSeleccionados([...seleccionados, producto_data]));
    }
};

export const agregarPrimer = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.devoluciones;
    const { ordering } = producto;
    api.get(endpoint, { ordering, search })
        .catch((err) => {})
        .then((data) => {
            if (data) {
                if (data.count > 0) {
                    const seleccionados = store.devoluciones.seleccionados;
                    const producto = data.results[0];
                    if (!_.find(seleccionados, { id: producto.id })) {
                        // TODO configuracion de ver si agrega o solo filtra
                        dispatch(
                            setSeleccionados([
                                data.results[0],
                                ...seleccionados,
                            ])
                        );
                    }
                }
            }
        })
        .finally(() => {
            dispatch(buscar(""));
            dispatch(setLoader(false));
        });
};

export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.devoluciones.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    if (item) {
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const producto = store.devoluciones;
    const { ordering, search, filtro } = producto;
    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_existencia_producto`, {
        ordering,
        search,
        filtro,
    })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// Filtros por fecha
export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const ordenes_compra = store.devoluciones;
    if (key === "Inicial") {
        // const fecha_compra_inicial = new Date(value);
        // const fecha_compra_final = new Date(ordenes_compra.fecha_compra_final);
        dispatch(setFechaCompraInicial1(value));
        // if (fecha_compra_final >= fecha_compra_inicial)
    } else {
        const fecha_compra_inicial = new Date(
            ordenes_compra.fecha_compra_inicial
        );
        const fecha_compra_final = new Date(value);
        if (fecha_compra_final >= fecha_compra_inicial)
            dispatch(setFechaCompraFinal1(value));
    }
    // dispatch(listar());
};

export const filtroChange = (filtro) => (dispatch) => {
    dispatch(_setFiltroChange(filtro));
    dispatch(listar());
    dispatch(listarOV());
};

export const setFiltros = (tipoDevolucion) => (dispatch, getStore) => {
    const store = getStore();
    const devoluciones = store.devoluciones;
    const { fecha_compra_final, fecha_compra_inicial } = devoluciones;
    if (!fecha_compra_final || !fecha_compra_inicial) {
        return ToastStore.warning(
            "Los campos de fecha y busqueda son necesarios"
        );
    }
    if (tipoDevolucion == 1) {
        dispatch(listar());
    } else {
        dispatch(listarOV());
    }
};

export const buscarCodigo = (codigo) => (dispatch) => {
    dispatch(setCodigoDoc(codigo));
};

export const setProveedorCliente = (value) => (dispatch) => {
    dispatch(_setProveedorCliente(value));
};

// Limiar estado redux
export const limpiar = () => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setSeleccionados([]));
    dispatch(setFechaCompraInicial1(moment().format("YYYY-MM-DD")));
    dispatch(setFechaCompraFinal1(moment().format("YYYY-MM-DD")));
    dispatch(setCodigoDoc(""));
    dispatch(_setProveedorCliente(""));
    dispatch(setEstadoDescarga(false));
    // dispatch(listar());
    dispatch(setOrdenes(false));
    // dispatch(listarOV());
    dispatch(setLoader(false));
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setOrdenes = (data) => ({
    type: PRODUCTOS,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const setMovimiento = (movimiento) => ({
    type: MOVIMIENTO,
    movimiento,
});

export const setPaginasImpresion = (paginas_impresion) => ({
    type: PAGINAS_IMPRESION,
    paginas_impresion,
});

export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setFiltroChange = (filtro) => ({
    type: FILTRO,
    filtro,
});

export const setOrdenesCompra = (ordenes_compra) => ({
    type: ORDENES_COMPRA,
    ordenes_compra,
});

export const setFechaCompraInicial1 = (fecha_compra_inicial) => ({
    type: FECHA_COMPRA_INICIAL,
    fecha_compra_inicial,
});

export const setFechaCompraFinal1 = (fecha_compra_final) => ({
    type: FECHA_COMPRA_FINAL,
    fecha_compra_final,
});

export const setCodigoDoc = (codigo_doc) => ({
    type: CODIGO_DOC,
    codigo_doc,
});

export const _setProveedorCliente = (proveedor_cliente) => ({
    type: PROVEEDOR_CLIENTE,
    proveedor_cliente,
});

export const actions = {
    listar,
    listarOV,
    buscar,
    sortChange,
    agregar,
    quitar,
    filtroChange,
    agregarPrimer,
    setSeleccionados,
    descargarListado,
    setFecha,
    setFiltros,
    buscarCodigo,
    setProveedorCliente,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [PRODUCTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [MOVIMIENTO]: (state, { movimiento }) => {
        return {
            ...state,
            movimiento,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGINAS_IMPRESION]: (state, { paginas_impresion }) => {
        return {
            ...state,
            paginas_impresion,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },
    [ORDENES_COMPRA]: (state, { ordenes_compra }) => {
        return {
            ...state,
            ordenes_compra,
        };
    },
    [FECHA_COMPRA_INICIAL]: (state, { fecha_compra_inicial }) => {
        return {
            ...state,
            fecha_compra_inicial,
        };
    },
    [FECHA_COMPRA_FINAL]: (state, { fecha_compra_final }) => {
        return {
            ...state,
            fecha_compra_final,
        };
    },
    [CODIGO_DOC]: (state, { codigo_doc }) => {
        return {
            ...state,
            codigo_doc,
        };
    },
    [PROVEEDOR_CLIENTE]: (state, { proveedor_cliente }) => {
        return {
            ...state,
            proveedor_cliente,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: { id: 3 },
    seleccionados: [],
    movimiento: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    paginas_impresion: {
        paginas: 0,
        listado_pagina: [],
    },
    uuid_req_productos: "",
    estado_descarga: false,
    filtro: "todos",
    ordenes_compra: {},
    fecha_compra_final: moment().format("YYYY-MM-DD"),
    fecha_compra_inicial: moment().format("YYYY-MM-DD"),
    codigo_doc: "",
    proveedor_cliente: "",
};

export default handleActions(reducers, initialState);
