import CrearActualizar from "./CrearActualizar";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/familias/familias";

const ms2p = (state) => {
    return {
        ...state.familias,
        empresa: state.login.me.empresa,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearActualizar);
