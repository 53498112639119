import React, { useEffect, useContext } from "react";
import { standardActions } from "Utils/Grid/StandardActions";
import Grid from "Utils/Grid";

//import useCrearFamilia from "../../../../../hooks/useCrearFamilia";
//import { SubFamiliasContext } from "../../../../../context/SubFamiliasContext";

const datos_tabla = {};
const SubFamiliaTabla = ({ subFamilias, setSubFamilia, loader = false }) => {
    // Datos del contexto de sub-familia
    const { familia, id_familia_eliminar, nuevas_familias } = subFamilias;

    // datos de la tabla, agreguarle el valor results, y asignarle el array sub-familia
    datos_tabla.results = familia;

    // Funcion para eliminar una sub-familia de la tabla
    const eliminarFamilia = (id) => {
        const nueva_lista = familia.filter((contacto) => contacto.id !== id);

        // Si el id no es un string (id de un contacto de la bd)
        if (typeof id !== "string") {
            setSubFamilia({
                ...subFamilias,
                familia: nueva_lista,
                id_familia_eliminar: [...id_familia_eliminar, id],
            });
        } else {
            //sub-familia local (no guardado en la db)
            const nueva_lista_nueva_sub_familia = nuevas_familias.filter(
                (familia) => familia.id !== id
            );

            setSubFamilia({
                ...subFamilias,
                familia: nueva_lista,
                nuevas_familias: nueva_lista_nueva_sub_familia,
            });
        }
    };

    useEffect(() => {
        document
            .querySelector(".react-bs-container-header")
            .classList.add("border-top-blue", "pt-4");

        document
            .querySelector(".react-bs-table-container")
            .classList.add("border-bootom-blue", "pb-4");

        document.querySelector(".react-bs-table-pagination").style.display =
            "none";
    }, []);

    return (
        <React.Fragment>
            <h3 className="contenido-1 txt-bold txt-blue mb-2">
                Listado de Sub-Familias
            </h3>

            <Grid
                hover
                striped
                data={datos_tabla}
                loading={loader}
                //onPageChange={listar}
                //onSortChange={onSortChange}
            >
                <TableHeaderColumn dataField="nombre" dataSort width="10rem">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    width="7rem"
                    isKey
                    dataFormat={standardActions({
                        eliminar: (id) => eliminarFamilia(id),
                    })}
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default SubFamiliaTabla;
