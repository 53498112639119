import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderField } from "Utils/renderField/renderField";
import {
    NIT,
    required,
    composeValidators,
    email,
    ValidateNitDpiAPI,
} from "../../../../../utility/validation";
import validarNit from "validar-nit-gt";

const DatosForm = (props) => {
    var initialName = nombre
    //  State
    const { desde_ov } = props;
    //  Bind
    const { handleSubmit, cancelar, nombre, setNombre } = props;
    return (
        <form onSubmit={handleSubmit} initialValues={initialName} className="w-100">
            {desde_ov && (
                <div className="grid-titulo mb-3">
                    <div className="px-3 pb-2">Datos Cliente</div>
                </div>
            )}
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 form-group">
                                <label htmlFor="nit">Nit</label>
                                <Field
                                    name="nit"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={composeValidators(required, NIT)}
                                    parse={(nit) => {
                                        if (validarNit(nit) && nit != "CF") {
                                            const res = ValidateNitDpiAPI(nit)
                                                .then((res) => {
                                                    console.log(res.data);
                                                    setNombre(res.data);
                                                })
                                                .catch((err) => {
                                                    setNombre("");
                                                });
                                        } else {
                                            setNombre("");
                                        }

                                        if (
                                            nit.toUpperCase() == "CF" ||
                                            nit.toUpperCase() == "C/F" ||
                                            nit.toUpperCase() == "C-F"
                                        ) {
                                            setNombre("Consumidor Final");
                                        }

                                        return nit;
                                    }}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group">
                                <label htmlFor="nombre">Nombre</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    disabled={true}
                                    type="text"
                                    className="form-control"
                                    validate={() => required(nombre)}
                                    defaultValue={nombre}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 form-group">
                                <label htmlFor="direccion">Dirección</label>
                                <Field
                                    name="direccion"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 form-group">
                                <label htmlFor="correo">Correo</label>
                                <Field
                                    name="correo"
                                    component={composeValidators(
                                        renderField,
                                        email
                                    )}
                                    type="email"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 form-group"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center flex-row">
                {cancelar && desde_ov ? (
                    <button
                        className="btn btn-tertiary m-1 align-self-center"
                        onClick={(e) => {
                            // e.preventDefault();
                            cancelar();
                        }}
                    >
                        Regresar
                    </button>
                ) : (
                    <Link
                        className="btn btn-tertiary m-1 align-self-center"
                        to="/configuracion/clientes"
                    >
                        Cancelar
                    </Link>
                )}
                <button
                    type="submit"
                    className="btn btn-primary m-1 align-self-center"
                >
                    {desde_ov ? "Guardar" : "Siguiente"}
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "wizardClientForm", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DatosForm);
