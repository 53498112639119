import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/articulos_vendidos";
import ListadoArticulosVendidos from "./ListadoArticulosVendidos";

const ms2p = (state) => {
    return {
        ...state.reporte_articulos_vendidos,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoArticulosVendidos);
