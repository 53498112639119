import { connect } from "react-redux";
import SalidaProductos from "./SalidaProductos";
import {
    listar,
    buscar,
    sortChange,
    agregar,
    agregarPrimer,
} from "../../../../redux/modules/bodega/bodega";

import { actions } from "../../../../redux/modules/bodega/salida_producto";

const mapStateToProps = (state) => ({
    sucursal: state.usuario.sucursal,
    loader: state.bodega.loader,
    data: state.bodega.data,
    page: state.bodega.page,
    seleccionados: state.bodega.seleccionados,
    search: state.bodega.search,
});

const mapDispatchToProps = {
    listar,
    buscar,
    agregar,
    sortChange,
    agregarPrimer,
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalidaProductos);
