import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import ReciboOV from "Utils/ReciboOV";
import Recibo from "Utils/Recibo/TicketOV";
// import TicketOV from "Utils/Recibo/TicketOV";
import OrdenVenta from "Utils/OrdenVenta";
import LoadMask from "Utils/LoadMask/LoadMask";
import { TablaOV } from "./ComponentesListadoOV";
import { tableOptions } from "Utils/tableOptions";
import FacturaCambiaria from "Utils/FacturaCambiaria";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import FormatoCotizacion from "Utils/TicketCotizacion";
import TicketCotizacion from "Utils/TicketCotizacion/ticket";
import { PrintContainer, PrintComponent } from "Utils/Print";

//Cotizacion
import { ListadoCotizacion } from "../../Cotizacion";
import Filtros from "../../Utils/Filtros";
import Modal from "react-responsive-modal";
import ModalFacturar from "../../CuentaCobrar/Listado/ModalFacturar";
import ModalDescuentos from "./ModalDescuentos";

const ListadoOrdenVenta = (props) => {
    const [modal, setModal] = useState({
        open: false,
        openSecond: false,
        data: {},
    });

    const [modalDescuento, setModalDescuento] = useState({
        open: false,
        data: {},
    });

    const [open, setOpen] = useState({
        open: false,
        printFunction: () => {},
        type: 0,
    });

    //  State
    const {
        tab,
        page_ov,
        data_ov = [],
        search_ov,
        loader_ov,
        print_state,
        utiliza_fel,
        orden_venta,
        detalles_ov,
        factura_personalizada,
        // filtros
        fecha_inicial,
        fecha_final,
        vendedor,
        cliente,
        tipo_venta,
    } = props;
    //  Bind
    const {
        anular,
        buscarOV,
        listarOV,
        changeTab,
        sortChangeOV,
        retimbradoFEL,
        seleccionImpresionOFEL,
        //ReImpresion
        re_imprimir,
        reImprimirCotizacion,
        venta_cotizacion,

        // filtros
        setFechaFinal,
        setFechaInicial,
        setVendedor,
        setCliente,
        setTipoVenta,

        // Facturacion
        facturarVenta,
        estado_descarga,
    } = props;

    //  Options
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader_ov ? "Cargando..." : options.noDataText;
    options.page = page_ov;
    options.onPageChange = listarOV;
    options.onSortChange = sortChangeOV;

    const ticket = 1;
    const factura = 2;

    let pagina = 0;

    useEffect(() => {
        props.listarOV();
    }, []);

    return (
        <div className="row mt">
            <Modal
                classNames={{ modal: "modal-md" }}
                onClose={() =>
                    setOpen({
                        open: false,
                        printFunction: () => {},
                        type: 0,
                    })
                } //cerrarModalCliente
                showCloseIcon={true}
                open={open.open}
                center
            >
                <div
                    className="d-flex flex-column px-2 py-2"
                    style={{ minWidth: "30vw" }}
                >
                    <h2>
                        <span className="ml-2">Reimpresión de documento</span>
                    </h2>

                    <div className="d-flex flex-column px-0 px-md-4">
                        <button
                            className="btn btn-primary my-3"
                            onClick={() => {
                                setOpen({
                                    ...open,
                                    open: false,
                                    type: factura,
                                });
                                open.printFunction();
                            }}
                        >
                            <i className="fa fa-print" />
                            <span className="ml-2">Reimprimir documento</span>
                        </button>

                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                setOpen({
                                    ...open,
                                    open: false,
                                    type: ticket,
                                });
                                open.printFunction();
                            }}
                        >
                            <i className="fa fa-print" />
                            <span className="ml-2">Reimprimir ticket</span>
                        </button>
                    </div>
                </div>
            </Modal>

            <ModalFacturar
                modal={modal}
                setModal={setModal}
                es_orden_venta={true}
            />

            <ModalDescuentos
                modal={modalDescuento}
                setModal={setModalDescuento}
            />

            {/* 
            <button
                className="btn btn-primary"
                disabled={estado_descarga}
                onClick={() => facturarVenta(103)}
            >
                <em
                    className={`fa ${
                        estado_descarga ? "fa-spinner fa-pulse" : "fa-download"
                    }`}
                ></em>
                &nbsp;test facturación
            </button> */}

            <div className="col-lg-12 col-md-12 col-sm-12">
                <Link className="btn btn-primary" to="/ordenes_de_venta/nueva/">
                    <em className="fa fa-plus"></em> Agregar Orden Venta
                </Link>

                <Tabs
                    className="mt-2 content-tabs"
                    selectedIndex={tab == 10 ? 0 : tab == 20 ? 1 : 2}
                    onSelect={(tabIndex) =>
                        changeTab(tabIndex == 0 ? 10 : tabIndex == 1 ? 20 : 30)
                    }
                >
                    <TabList className="tab_list d-flex flex-row justify-content-between">
                        {/*<Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                ORDENES DE VENTA ACTIVAS
                    </Tab>*/}
                        <Tab
                            className="content-tab-venta flex1 text-center fnt-14"
                            style={{ marginBottom: "0px" }}
                            selectedClassName="content-tab-venta-active"
                        >
                            ORDENES DE VENTA FINALIZADAS
                        </Tab>
                        <Tab
                            className="content-tab-venta flex1 text-center fnt-14"
                            style={{ marginBottom: "0px" }}
                            selectedClassName="content-tab-venta-active"
                        >
                            COTIZACIONES
                        </Tab>
                        {/*<Tab
                                className="content-tab-venta flex1 text-center fnt-14"
                                style={{ marginBottom: "0px" }}
                                selectedClassName="content-tab-venta-active"
                            >
                                ORDENES DE VENTA ANULADAS
                </Tab>*/}
                    </TabList>

                    {/* OV Finalizadas */}
                    <TabPanel>
                        <div className="grid-container">
                            <div className="grid-titulo">
                                <Toolbar
                                    titulo={"Listado"}
                                    buscar={buscarOV}
                                    search={search_ov}
                                />
                            </div>
                            <div>
                                <Filtros
                                    fecha_inicial={fecha_inicial}
                                    fecha_final={fecha_final}
                                    vendedor={vendedor}
                                    cliente={cliente}
                                    tipo_venta={tipo_venta}
                                    setFechaInicial={setFechaInicial}
                                    setFechaFinal={setFechaFinal}
                                    setVendedor={setVendedor}
                                    setCliente={setCliente}
                                    setTipoVenta={setTipoVenta}
                                    fitro_tipo_venta
                                />
                            </div>
                            <LoadMask loading={loader_ov} dark blur>
                                <TablaOV
                                    loader={loader_ov}
                                    datos_listado={data_ov}
                                    options={options}
                                    utiliza_fel={utiliza_fel}
                                    factura_personalizada={
                                        factura_personalizada
                                    }
                                    anular={anular}
                                    retimbradoFEL={retimbradoFEL}
                                    seleccionImpresionOFEL={
                                        seleccionImpresionOFEL
                                    }
                                    limpiarOV={props.limpiarOV}
                                    open={open}
                                    setOpen={setOpen}
                                    modal={modal}
                                    setModal={setModal}
                                    modalDescuento={modalDescuento}
                                    setModalDescuento={setModalDescuento}
                                />
                            </LoadMask>
                        </div>
                    </TabPanel>

                    {/* Cotizaciones */}
                    <TabPanel>
                        <div className="grid-container">
                            <ListadoCotizacion
                                reImprimirCotizacion={reImprimirCotizacion}
                                limpiarVentaCotizacion={
                                    props.limpiarVentaCotizacion
                                }
                                open={open}
                                setOpen={setOpen}
                            />
                        </div>
                    </TabPanel>
                </Tabs>
                {print_state.flag && <PrintComponent />}

                {/* HOJA OV-- */}
                {orden_venta && orden_venta.paginas && (
                    <div className="w100 print-only">
                        <PrintContainer
                            name="impresionOVListaOV"
                            className="print-space"
                        >
                            {open.type === factura && (
                                <React.Fragment>
                                    {[...Array(orden_venta.paginas)].map(
                                        (x, i) => {
                                            pagina = pagina + 1;
                                            return (
                                                <React.Fragment>
                                                    <ReciboOV
                                                        pagina={pagina}
                                                        sucursal={
                                                            orden_venta.sucursal
                                                        }
                                                        movimiento={orden_venta}
                                                        usuario={
                                                            orden_venta.usuario
                                                                .first_name
                                                                ? orden_venta
                                                                      .usuario
                                                                      .first_name
                                                                : ""
                                                        }
                                                        footerText={"TOTAL"}
                                                        tipo={"Venta"}
                                                        utiliza_fel={false}
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            )}

                            {open.type === ticket && (
                                <React.Fragment>
                                    {[...Array(orden_venta.paginas)].map(
                                        (x, i) => {
                                            pagina = pagina + 1;
                                            return (
                                                <React.Fragment>
                                                    <Recibo
                                                        pagina={pagina}
                                                        sucursal={
                                                            orden_venta.sucursal
                                                        }
                                                        movimiento={orden_venta}
                                                        usuario={
                                                            orden_venta.usuario
                                                                .first_name
                                                                ? orden_venta
                                                                      .usuario
                                                                      .first_name
                                                                : ""
                                                        }
                                                        footerText={"TOTAL"}
                                                        tipo={"Venta"}
                                                        utiliza_fel={false}
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            )}
                        </PrintContainer>
                    </div>
                )}
                {venta_cotizacion && (
                    <div className="w100 print-only">
                        <PrintContainer name="ticketCotizacion">
                            {open.type === factura && (
                                <React.Fragment>
                                    {venta_cotizacion &&
                                        venta_cotizacion.paginas &&
                                        [
                                            ...Array(venta_cotizacion.paginas),
                                        ].map((x, i) => {
                                            pagina = pagina + 1;
                                            return (
                                                <React.Fragment>
                                                    <FormatoCotizacion
                                                        pagina={pagina}
                                                        cotizacion={
                                                            venta_cotizacion
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                </React.Fragment>
                            )}

                            {open.type === ticket && (
                                <TicketCotizacion
                                    cotizacion={venta_cotizacion}
                                />
                            )}
                        </PrintContainer>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListadoOrdenVenta;
