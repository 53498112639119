import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import CambiarCantidadForm from "./CambiarCantidadForm";
// import classNames from "classnames";

const CambiarCantidadModal = ({
    open,
    onClose,
    selectedRow,
    cambiarCantidad,
    // marcarEnviado,
    detalle,
}) => {
    // const metodo_envio =
    //     detalle && detalle.metodo_envio ? detalle.metodo_envio : "---";
    // const es_recoger_en_tienda = metodo_envio === "retirar_tienda";

    const handleOnSubmit = (values) => {
        cambiarCantidad(values, onClose, selectedRow);
    };

    console.log("detalle", detalle);

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                classNames={{ modal: "modal-md" }}
                center
            >
                <div
                    style={{ minWidth: "25vw", minHeight: "25vh" }}
                    className="p-4"
                >
                    <CambiarCantidadForm
                        selectedRow={selectedRow}
                        onClose={onClose}
                        onSubmit={handleOnSubmit}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default CambiarCantidadModal;
