import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderNumero } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
import { formatoCantidadRecibido } from "./camposReceocionDespacho";
import { icons } from "icons";
import { DESPACHO_COMPLETO } from "utility/variables";

class CrearRecepcionDespacho extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDespacho(this.props.match.params.id);
    }

    render() {
        //  State
        const { despacho, loader, seleccionados, tiene_alertas } = this.props;
        //  Bind
        const { setNotaProducto, registrarRecepcion } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;

        if (!despacho) {
            return (
                <div className="grid-container padding-20 text-center">
                    <LoadMask loading={loader} light blur radius>
                        <h2>Detalle no encontrado</h2>
                        <Link className="btn btn-primary" to={"/traslados"}>
                            <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                            />{" "}
                            Regresar
                        </Link>
                    </LoadMask>
                </div>
            );
        } else {
            if (despacho.estado_despacho == DESPACHO_COMPLETO) {
                return (
                    <div className="grid-container padding-20 text-center">
                        <h2>Recepción del despacho completado</h2>
                        <Link className="btn btn-primary" to={"/traslados"}>
                            <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                            />{" "}
                            Regresar
                        </Link>
                    </div>
                );
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container pb-1">
                        <LoadMask loading={loader} light blur radius>
                            <div className="w-100">
                                <div className="grid-titulo padding-15">
                                    Encabezado
                                </div>
                                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1 mt">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Enviado desde
                                            </label>
                                            <div className="input-view">
                                                {despacho.sucursal.nombre}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Fecha y hora
                                            </label>
                                            <div className="input-view">
                                                <RenderDateTime
                                                    fecha={despacho.creado}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Despachado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.usuario.first_name}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                                            <label className="label-view">
                                                Transportado por
                                            </label>
                                            <div className="input-view">
                                                {despacho.responsable_transporte
                                                    ? despacho.responsable_transporte
                                                    : "-----"}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt">
                                        <div className="col-12 negro-dark2 font-weight-bold d-flex flex-wrap justify-content-between align-items-center mb">
                                            <h4 style={{ margin: "0" }}>
                                                Listado
                                            </h4>
                                            {/* <div className="azul fnt-sm-10 font-weight-bold d-flex align-items-center">
                                                <em className="fa fa-info-circle"></em>
                                                &nbsp;LA RECEPCIÓN ES ÚNICA EN CADA DESPACHO
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BootstrapTable
                                data={loader ? [] : seleccionados}
                                remote
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    width="0%"
                                    dataField="codigo"
                                    isKey
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                <span>
                                                    <b>{row.producto}</b>
                                                </span>
                                            </div>
                                        );
                                    }}
                                >
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    width="100px"
                                    dataField="cantidad"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return <RenderNumero monto={cell} />;
                                    }}
                                >
                                    Enviados
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    width="150px"
                                    dataField="cantidad_recibido"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={formatoCantidadRecibido({
                                        ...this.props,
                                    })}
                                >
                                    Recibidos
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nota"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, producto) => {
                                        return (
                                            <div className="w-100 d-flex align-items-center justify-content-center">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Escribe la nota"
                                                    ref={(node) => {
                                                        this.nota = node;
                                                        if (
                                                            this.nota &&
                                                            producto.nota !==
                                                                undefined
                                                        ) {
                                                            this.nota.value =
                                                                producto.nota;
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        if (this.nota) {
                                                            setNotaProducto(
                                                                e.target.value,
                                                                producto
                                                            );
                                                        }
                                                    }}
                                                />
                                                &nbsp;&nbsp;
                                                {producto.tiene_alerta &&
                                                    (producto.nota ==
                                                        undefined ||
                                                        producto.nota == null ||
                                                        producto.nota ==
                                                            "") && (
                                                        <em
                                                            className="fa fa-exclamation-triangle"
                                                            style={{
                                                                color: "#EC2C30",
                                                            }}
                                                        ></em>
                                                    )}
                                            </div>
                                        );
                                    }}
                                >
                                    Notas
                                </TableHeaderColumn>
                            </BootstrapTable>
                            <div className="row">
                                {tiene_alertas && (
                                    <div className="w-100 mt-4">
                                        <div className="text-center text-warning fnt-sm-10 font-weight-bold">
                                            <em className="fa fa-info-circle"></em>
                                            &nbsp;LA RECEPCIÓN SOLO SE PUEDE
                                            HACER UNA VEZ, NO PUEDEN QUEDAR
                                            PRODUCTOS PENDIENTES DE DESPACHO
                                        </div>
                                        <div
                                            className="text-center fnt-sm-10 font-weight-bold"
                                            style={{ color: "#EC2C30" }}
                                        >
                                            <em className="fa fa-exclamation-triangle"></em>
                                            &nbsp;ES NECESARIO QUE LLENES LOS
                                            CAMPOS CON ALERTA PARA JUSTIFICAR LA
                                            ENTREGA INCOMPLETA DEL PRODUCTO
                                        </div>
                                    </div>
                                )}
                                <div
                                    className="w-100 d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: "#FFF",
                                        padding: "15px",
                                    }}
                                >
                                    <Link
                                        className="btn btn-tertiary mr-1"
                                        to={"/recepciones_de_traslados"}
                                    >
                                        <img src={icons.cancelar} alt="" />
                                        Cancelar
                                    </Link>

                                    <button
                                        className="btn btn-primary ml-1"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            registrarRecepcion();
                                        }}
                                        disabled={tiene_alertas}
                                    >
                                        <i className="fa fa-check mr" />
                                        Finalizar
                                    </button>
                                </div>
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

CrearRecepcionDespacho.propTypes = {
    loader: PropTypes.bool.isRequired,
};

export default CrearRecepcionDespacho;
