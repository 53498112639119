import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import { tableOptions } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReporteInv } from "../../../Utils/FiltrosReportes";
import {
    formatoCostoDolares,
    formatoCostoQuetzales,
} from "../../../Utils/CamposComunes";

const ListadoArticulosVenta = ({
    // state
    loader,
    data,
    estado_descarga,

    fecha_inicial,
    fecha_final,
    sucursal = null,
    familia = "",
    subfamilia = "",
    tipo_reporte = "",
    cantidad_articulos = "",

    usuario = null,
    sucursal_param = null,

    // bind
    descargarListado,
    aplicarFiltros,
    limpiarFiltros,

    setFecha,
    setSucursal,
    setFamilia,
    setSubfamilia,
    setTipoReporte,
    setCantidadArticulos,

    page,
    listar,
    sortChange,
}) => {
    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page: page,
    };

    useEffect(() => {
        limpiarFiltros();
        return () => {
            limpiarFiltros();
        };
    }, []);

    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReporteInv
                        sucursal={sucursal}
                        familia={familia}
                        subfamilia={subfamilia}
                        fecha_inicial={fecha_inicial}
                        fecha_final={fecha_final}
                        tipo_reporte={tipo_reporte}
                        cantidad_articulos={cantidad_articulos}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        setSucursal={setSucursal}
                        setFamilia={setFamilia}
                        setSubfamilia={setSubfamilia}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setTipoReporte={setTipoReporte}
                        setCantidadArticulos={setCantidadArticulos}
                        aplicarFiltros={aplicarFiltros}
                        filtroAriculosVenta
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn dataField="codigo_producto">
                                    Código
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo_equivalente">
                                    Código equivalente
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="nombre_producto">
                                    Nombre
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="cantidad_vendido"
                                    dataAlign="right"
                                >
                                    Vendido
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="cantidad_comprado"
                                    dataAlign="right"
                                >
                                    Comprado
                                </TableHeaderColumn>

                                {/* ultima_cantidad_comprado */}
                                <TableHeaderColumn
                                    dataField="contidad_comprado_fecha"
                                    dataAlign="right"
                                >
                                    Comprado en fechas
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="existencia"
                                    dataAlign="right"
                                >
                                    Existencia
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="costo_quetzales"
                                    dataAlign="right"
                                    dataFormat={formatoCostoQuetzales}
                                >
                                    Costo Q
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="costo_dolares"
                                    dataAlign="right"
                                    dataFormat={formatoCostoDolares}
                                >
                                    Costo $
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                ></TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoArticulosVenta.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoArticulosVenta;
