import React, { useEffect, useState, Suspense, lazy } from "react";

import LoadMask from "Utils/LoadMask/LoadMask";
import { standardActions } from "../../Utils/Grid/StandardActions";
import Grid from "Utils/Grid";

const ModalEditar = lazy(() => import("./ModalEditar"));
//import { creditosContext } from "../../../../../context/creditosContext";

const datos_tabla = {};
const CreditosTabla = ({ creditos, setCreditos, match }) => {
    const [infoModal, setInfoModal] = useState({
        id: null,
        local_data: false,
        isUpdate: false,
    });

    const { id, local_data, isUpdate } = infoModal;
    // Modal
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    // Datos del contexto de creditos
    //const { creditos, setCreditos } = useContext(creditosContext);
    const { credito, id_credito_eliminar, nuevos_credito } = creditos;

    // datos de la tabla, agreguarle el valor results, y asignarle el array contactos
    datos_tabla.results = credito;

    // Funcion para eliminar un contacto de la tabla
    const eliminarCredito = (id) => {
        const nueva_lista = credito.filter((credito) => credito.id !== id);

        // Si el id no es un string (id de un contacto de la bd)
        if (typeof id !== "string") {
            setCreditos({
                ...creditos,
                credito: nueva_lista,
                id_credito_eliminar: [...id_credito_eliminar, id],
            });
        } else {
            // contacto local (no guardado en la db)
            const nueva_lista_nuevos_credito = nuevos_credito.filter(
                (contacto) => contacto.id !== id
            );
            setCreditos({
                ...creditos,
                credito: nueva_lista,
                nuevos_credito: nueva_lista_nuevos_credito,
            });
        }
    };

    useEffect(() => {
        document
            .querySelector(".react-bs-container-header")
            .classList.add("border-top-blue", "pt-4");

        document
            .querySelector(".react-bs-table-container")
            .classList.add("border-bootom-blue", "pb-4");

        document.querySelector(".react-bs-table-pagination").style.display =
            "none";
    }, []);

    useEffect(() => {
        const update = match.path.includes("editar");
        setInfoModal({ ...infoModal, isUpdate: update });
    }, [match]);

    return (
        <React.Fragment>
            {open && (
                <Suspense fallback={null}>
                    <ModalEditar
                        open={open}
                        onCloseModal={onCloseModal}
                        id={id}
                        local_data={local_data}
                        isUpdate={isUpdate}
                        creditos={creditos}
                        setCreditos={setCreditos}
                    />
                </Suspense>
            )}

            <h3 className="contenido-1 txt-bold txt-blue mb-2">
                Listado de creditos
            </h3>

            <Grid
                hover
                striped
                data={datos_tabla}
                pagination={false}
                // loading={loader}
                // onPageChange={listar}
                // onSortChange={onSortChange}
            >
                <TableHeaderColumn
                    dataField="dias_credito"
                    dataSort
                    width="10rem"
                >
                    Dias de credito
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="limite_credito"
                    dataSort
                    width="10rem"
                >
                    Limite de credito
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="sucursal_id"
                    dataFormat={(cell, row) => {
                        return row.sucursal_id ? row.sucursal_id.nombre : "";
                    }}
                    dataSort
                    width="8rem"
                >
                    Sucursal
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    width="7rem"
                    isKey
                    dataFormat={standardActions({
                        eliminar: (id) => eliminarCredito(id),
                        editarModal: (id) => {
                            const type_id = typeof id;
                            onOpenModal();
                            setInfoModal({
                                ...infoModal,
                                id: id,
                                local_data: type_id === "string",
                            });
                            // setId(id);
                        },
                    })}
                >
                    Acciones
                </TableHeaderColumn>
            </Grid>
        </React.Fragment>
    );
};

export default CreditosTabla;
