import { api } from "api"
import { ToastStore } from "react-toasts"
import { handleActions } from "redux-actions"
import Swal from "sweetalert2"
import moment from "moment"
import { cloneDeep } from "lodash"

const endpoint = "movimientos"

export const DATA = "R_REAJUSTES_DATA"
export const LOADER = "R_REAJUSTES_LOADER"
export const PAGE = "R_REAJUSTES_PAGE"
export const ESTADO_DESCARGA = "R_REAJUSTES_ESTADO_DESCARGA"
export const TOTALES = "R_REAJUSTES_TOTALES"

export const FECHA_INICIAL = "R_REAJUSTES_FECHA_INICIAL"
export const FECHA_FINAL = "R_REAJUSTES_FECHA_FINAL"
export const SUCURSAL = "R_REAJUSTES_SUCURSAL"
export const USUARIO = "R_REAJUSTES_USUARIO"
export const TIPO_REAJUSTE = "R_REAJUSTES_TIPO"

// ------------------------------------
// Actions
// ------------------------------------

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true))
    const store = getStore()
    const {
        ordering,
        fecha_inicial,
        fecha_final,
        sucursal,
        usuario_filtro,
        tipo_reajuste
    } = store.reporte_reajustes

    let params = {
        ordering,
        page,
        fecha_inicial,
        fecha_final,
        param_sucursal: sucursal,
        usuario: usuario_filtro,
        tipo_reajuste
    }
    api.get(`${endpoint}/reporte_reajustes`, params)
        .catch(err => {
            Swal("Error", "Ha ocurrido un error.", "error")
        })
        .then(res => {
            if(res) {
                // * Se crea una copia del response para filtrar los reajustes positivos y negativos según se requiera
                let response = cloneDeep(res)
                // filtramos los movimientos que tengan detalles que concuerden con el tipo de reajustes solicitados (positivos o negativos)
                response.results = response.results.filter(movimiento => {
                    let detalles = movimiento.detalles.filter(detalle => (detalle.tipo_detalle === response.extra_data.tipo_reajuste))
                    return detalles.length > 0
                })
                response.results.forEach(movimiento => {
                    let detalles = movimiento.detalles.filter(detalle => (detalle.tipo_detalle === response.extra_data.tipo_reajuste))
                    movimiento.detalles = detalles
                })
                dispatch(setTotales(response.extra_data))
                dispatch(setData(response))
                dispatch(setPage(page))
            }
        })
        .finally(() => {
            dispatch(setLoader(false))
        })
}

export const setTotales = value => dispatch => {
    dispatch(_setTotales(value))
}

export const setSucursal = value => dispatch => {
    dispatch(_setSucursal(value))
}

export const setUsuario = value => dispatch => {
    dispatch(_setUsuario(value))
}

export const setTipoReajuste = value => dispatch => {
    dispatch(_setTipoReajuste(value))
}

export const aplicarFiltros = () => (dispatch, getStore) => {
    const store = getStore()
    const { sucursal } = store.reporte_reajustes

    if(!sucursal) {
        ToastStore.error("Debe seleccionar sucursal")
    } else {
        dispatch(listar())
    }
}

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore()
    const reporte_reajustes = store.reporte_reajustes
    if (key === "Inicial") {
        dispatch(setFechaInicial(value))
    } else {
        const fecha_inicial = new Date(
            reporte_reajustes.fecha_inicial
        )
        const fecha_final = new Date(value)
        if (fecha_final >= fecha_inicial)
            dispatch(setFechaFinal(value))
    }
}

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore()
    const {
        ordering,
        fecha_inicial,
        fecha_final,
        sucursal,
        usuario_filtro,
        tipo_reajuste
    } = store.reporte_reajustes

    let params = {
        ordering,
        fecha_inicial,
        fecha_final,
        param_sucursal: sucursal,
        usuario: usuario_filtro,
        tipo_reajuste
    }

    dispatch(setEstadoDescarga(true))
    api.get(`${endpoint}/descargar_excel_reporte_reajustes`, params)
        .catch(err => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intente más tarde.",
                "error"
            )
            dispatch(setEstadoDescarga(false))
        })
        .then(data => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                )
                dispatch(setEstadoDescarga(true))
                dispatch(esperarDescarga(data.id))
            }
        })
}

const esperarDescarga = id => dispatch => {
    let intervalPromise

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch(err => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde"
                if (err.estado) {
                    msg = err.observaciones
                }
                dispatch(setEstadoDescarga(false))
                clearInterval(intervalPromise)
                Swal("¡Error al descargar!", mgs, "error")
            })
            .then(resp => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true))
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise)
                    let nombre = resp.archivo
                     ? resp.archivo.split("/media/archivos/")[1]
                     : "Reporte_reajustes.xlsx"
                    const context = {
                        name: nombre,
                        url: resp.archivo
                    }

                    dispatch(setEstadoDescarga(false))
                    dispatch(descargaArchivo(context))
                }
            })
    }

    listener()
    intervalPromise = setInterval(listener, 1000)
}

const descargaArchivo = context => dispatch => {
    let elem = document.createElement("a")
    elem.href = context.url
    elem.download = context.name
    elem.target = "hiddenIframe"
    elem.click()
    dispatch(setEstadoDescarga(false))
    ToastStore.success("Archivo descargado exitosamente")
}

export const limpiarFiltros = () => dispatch => {
    dispatch(setFechaInicial(moment().format("YYYY-MM-DD")))
    dispatch(setFechaFinal(moment().format("YYYY-MM-DD")))
    dispatch(
        setTotales({
            total_reajustado: 0,
            total_costo_reajustado: 0
        })
    )

    dispatch(setUsuario(""))
    dispatch(setSucursal(""))
    dispatch(setTipoReajuste(1))
    dispatch(setData({}))
}

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = data => ({
    type: DATA,
    data
})

export const setLoader = loader => ({
    type: LOADER,
    loader
})

export const setPage = page => ({
    type: PAGE,
    page
})

export const setFechaInicial = fecha_inicial => ({
    type: FECHA_INICIAL,
    fecha_inicial
})

export const setFechaFinal = fecha_final => ({
    type: FECHA_FINAL,
    fecha_final
})

export const setEstadoDescarga = estado_descarga => ({
    type: ESTADO_DESCARGA,
    estado_descarga
})

export const _setTotales = totales => ({
    type: TOTALES,
    totales
})

export const _setSucursal = sucursal => ({
    type: SUCURSAL,
    sucursal
})

export const _setUsuario = usuario_filtro => ({
    type: USUARIO,
    usuario_filtro
})

export const _setTipoReajuste = tipo_reajuste => ({
    type: TIPO_REAJUSTE,
    tipo_reajuste
})

export const actions = {
    listar,
    setTotales,
    setFecha,

    setSucursal,
    aplicarFiltros,
    setUsuario,
    setTipoReajuste,

    descargarListado,
    limpiarFiltros
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data
        }
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        }
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page
        }
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial
        }
    },
    [FECHA_FINAL]: (state, {fecha_final}) => {
        return {
            ...state,
            fecha_final
        }
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga
        }
    },
    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales
        }
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal
        }
    },
    [USUARIO]: (state, { usuario_filtro }) => {
        return {
            ...state,
            usuario_filtro
        }
    },
    [TIPO_REAJUSTE]: (state, { tipo_reajuste }) => {
        return {
            ...state,
            tipo_reajuste
        }
    }
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    loader: false,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
    sucursal: "",
    usuario_filtro: "",
    tipo_reajuste: 1,
    totales: {
        total_reajustado: 0,
        total_costo_reajustado: 0
    }
}

export default handleActions(reducers, initialState)