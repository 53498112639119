import React from "react";
import { api } from "api";

import { Form, Field } from "react-final-form";
import { required } from "../../../../utility/validation";
import {
    renderInteger,
    renderCurrency,
    renderSearchSelect2,
} from "Utils/renderField/renderField";
import CreditosTabla from "./CreditosTabla";

const DireccionesForm = ({
    ver = false,
    view = false,
    editar = false,
    creditos,
    setCreditos,
    match,
}) => {
    const { credito, nuevos_credito } = creditos;

    // Generador de un id
    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, "");
        return `ID${id}`;
    };

    const datos_tabla = {};
    datos_tabla.results = credito;

    // Submit al formulario Contactos del Direcciones
    const onSubmit = (value, event) => {
        var reppido = null;
        value.id = makeRandomId();
        credito.forEach((datos) => {
            if (datos.sucursal_id.id === value.sucursal_id.id) {
                reppido = true;
            } else {
                console.log("repetido");
            }
        });

        if (reppido === true) {
            // Limpiar el formulario
            event.reset({
                address: "",
                exact_address: "",
                telephone: "",
            });
            reppido = null;
        } else {
            // Si se esta editando el dato, agregar los datos del formulario al array nuevos_credito
            if (editar) {
                setCreditos({
                    ...creditos,
                    credito: [...credito, value],
                    nuevos_credito: [...nuevos_credito, value],
                });
            } else {
                // sino, solo al array creditos
                setCreditos({
                    ...creditos,
                    credito: [...credito, value],
                });
            }
            // Limpiar el formulario
            event.reset({
                address: "",
                exact_address: "",
                telephone: "",
            });
            reppido = null;
        }
    };

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                return { options: data.results };
            });
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{}}
                render={({ handleSubmit, submitting, form, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-5">
                            creditos
                        </h3>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="dias_credito">
                                    Dias de credito *
                                </label>
                                <Field
                                    name="dias_credito"
                                    component={renderInteger}
                                    // placeholder="Ingrese nombre del donante"
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="limite_credito">
                                    Limite de credito *
                                </label>
                                <Field
                                    name="limite_credito"
                                    component={renderCurrency}
                                    // placeholder="Ingrese nombre del donante"
                                    type="text"
                                    className="form-control"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="telefono">Sucursal *</label>
                                <Field
                                    className="form-control"
                                    placeholder="Seleccionar"
                                    name="sucursal_id"
                                    value="id"
                                    getObjetvalue={true}
                                    component={renderSearchSelect2}
                                    loadOptions={getSucursales}
                                    labelKey="nombre"
                                    valueKey="nombre"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3"></div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-end py-4">
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Agregar Credito
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
            <CreditosTabla
                creditos={creditos}
                setCreditos={setCreditos}
                data={{}}
                match={match}
            />
        </React.Fragment>
    );
};

export default DireccionesForm;
