import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { PVMCE, PVN } from "utility/variables";

import {
    composeValidators,
    dpi,
    email,
    integer,
    maxLength,
    minLength,
    NIT,
    required,
} from "../../../../../utility/validation";
import {
    departamentosOptions,
    municipiosOptions,
} from "../../../../../utility/variables";
import {
    AsyncSelectField,
    renderCurrency,
    renderDatePicker,
    renderField,
    renderFieldCheck,
    renderFieldRadio,
    renderNumber,
    renderNumberFormat,
    renderPhotoUploader,
    renderSearchSelect,
    renderSelectField,
    renderSwitch,
} from "../../../Utils/renderField/renderField";
import MultiUploadFile from "../../../Utils/MultipleUploadFile/MultiUploadFile";
import { api } from "api";

export const listaProveedores = [];

import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import FormPermisos from "./FormPermisos/FormPermisos";
import { validateFirefoxBrowser } from "../../../Utils/validations";

const UsuarioForm = (props) => {
    //  Valores de checkbox de permisos
    const {
        usuariosDatos,
        onSubmit,
        view = false,
        update,
        id,
        files,
        onChangeFile,
        onRemoveFile,
        onRemoveOldFile,
        oldFiles,
        data,

        rolValue,
        tipo_punto_venta,
    } = props;

    const [permiso, setPermiso] = useState("");
    const [sucursal, setSucursal] = useState(false);
    const [isFirefoxBrowser, setIsFirefoxBrowser] = useState(false);

    // State para el collapse
    const [collapse, setCollapse] = useState(!update);

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => { })
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaProveedores, { id: item.id }))
                        listaProveedores.push(item);
                });
                return { options: listaProveedores };
            });
    };

    let roles = [
        { id: 1, value: "", label: "Seleccione un rol" },
        {
            id: "es_encargado_tienda",
            value: "es_encargado_tienda",
            label: "Encargado de Tienda",
        },
        {
            id: "es_gerente",
            value: "es_gerente",
            label: "Gerente",
        },
        {
            id: "es_auxiliar_gerencia",
            value: "es_auxiliar_gerencia",
            label: "Auxiliar de gerencia",
        },
        {
            id: "es_jefe_bodega",
            value: "es_jefe_bodega",
            label: "Jefe de bodega",
        },
        {
            id: "es_vendedor",
            value: "es_vendedor",
            label: "Vendedores",
        },
        {
            id: "es_vendedor_mayorista",
            value: "es_vendedor_mayorista",
            label: "Vendedor mayorista",
        },
        {
            id: "es_vendedor_distribuidor",
            value: "es_vendedor_distribuidor",
            label: "Vendedor Distribuidor",
        },
        {
            id: "es_motorista",
            value: "es_motorista",
            label: "Motoristas",
        },
        {
            id: "personalizado",
            value: "personalizado",
            label: "Personalizado",
        },
    ];

    useEffect(() => {
        const radio_true = document.querySelector("#radio-true");
        radio_true.click();

        const validate_browser = validateFirefoxBrowser();
        setIsFirefoxBrowser(validate_browser);
    }, []);

    useEffect(() => {
        if (data.es_global === "false") {
            setSucursal(true);
        }

        if (data.rol) {
            setPermiso(data.rol);
        }
    }, [data]);

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit} // validation
                initialValues={{
                    comisiones: false,
                    viaticos: 0,
                    activo: true,
                    porcentaje_comision_mensual: 0,
                    ...data,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                    // expect (field, value) args from the mutator
                }}
                render={({ handleSubmit, submitting, values, form }) => {
                    //window.setFormValue = form.mutators.setValue;

                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_mayorista
                    ) {
                        form.change("ov_minorista", false);
                        form.change("ov_distribuidor", false);
                    }

                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_minorista
                    ) {
                        form.change("ov_mayorista", false);
                        form.change("ov_distribuidor", false);
                    }

                    if (
                        values.rol &&
                        values.rol == "personalizado" &&
                        values.ov_distribuidor
                    ) {
                        form.change("ov_minorista", false);
                        form.change("ov_mayorista", false);
                    }

                    if (values.rol && values.rol != "personalizado") {
                        form.change("ov_minorista", false);
                        form.change("ov_mayorista", false);
                        form.change("ov_distribuidor", false);
                    }
                    //     window.setFormValue("minorista", true);

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            <div className="mb-5 card card-small px-3 py-4">
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4">
                                    Datos del Usuario
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Estado</label>
                                        <div className="d-flex">
                                            <label className="mr-2">
                                                Desactivado
                                            </label>
                                            <Field
                                                name="activo"
                                                component={renderSwitch}
                                                // placeholder="Ingrese nombre del donante"
                                                type="text"
                                                className="form-control"
                                            />
                                            <label className="ml-2">
                                                Activado
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Nombre *</label>
                                        <Field
                                            name="first_name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Apellidos *
                                        </label>
                                        <Field
                                            name="last_name"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Fecha de nacimiento
                                        </label>
                                        <Field
                                            name="birthday"
                                            component={renderDatePicker}
                                            placeholder=""
                                            type="date"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="direccion">
                                            Dirección*
                                        </label>
                                        <Field
                                            name="direccion"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="telefono">
                                            Telefono
                                        </label>
                                        <Field
                                            name="telefono"
                                            component={renderField}
                                            placeholder="#### ####"
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                minLength(8),
                                                maxLength(8),
                                                integer
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="email">
                                            Correo electónico
                                        </label>
                                        <Field
                                            name="email"
                                            component={renderField}
                                            placeholder="@"
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                email
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Departamento de nacimiento
                                        </label>

                                        <Field
                                            name="departamento"
                                            component={renderSelectField}
                                            options={departamentosOptions}
                                            placeholder="Seleccionar"
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                            disabled={view}
                                            parse={(value) => {
                                                form.change(
                                                    "municipio",
                                                    undefined
                                                );
                                                return value;
                                            }}
                                        />
                                    </div>

                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Municipio nacimiento
                                        </label>

                                        <Field
                                            key={
                                                values.municipio
                                                    ? `key_mun${values.municipio}`
                                                    : `key_mun_02`
                                            }
                                            name="municipio"
                                            component={renderSelectField}
                                            options={
                                                values.departamento
                                                    ? municipiosOptions[
                                                    values.departamento
                                                    ]
                                                    : []
                                            }
                                            placeholder="Seleccionar"
                                            type="text"
                                            className="form-control"
                                            valueKey="value"
                                            validate={required}
                                            disabled={
                                                view || values.departamento
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Sucursales
                                </h3>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="empresa-sucursal">
                                        Selección de sucursales
                                    </label>
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            <Field
                                                type="radio"
                                                name="es_global"
                                                label="Puede acceder a toda la empresa"
                                                value="true"
                                                id="radio-true"
                                                component={renderFieldRadio}
                                                parse={(value) => {
                                                    setSucursal(false);
                                                    return value;
                                                }}
                                            />
                                        </div>
                                        <div className="flex-2">
                                            <Field
                                                type="radio"
                                                name="es_global"
                                                label="Puede acceder solo a una sucursal"
                                                value="false"
                                                component={renderFieldRadio}
                                                parse={(value) => {
                                                    setSucursal(true);
                                                    return value;
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        {sucursal && (
                                            <React.Fragment>
                                                <label htmlFor="sucursales">
                                                    Selección de sucursales
                                                </label>
                                                <Field
                                                    name="sucursales"
                                                    component={AsyncSelectField}
                                                    loadOptions={getSucursales}
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    validate={required}
                                                    className="form-control"
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Accesos
                                </h3>
                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="usuario">Usuario</label>
                                        <Field
                                            name="usuario"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">Contraseña</label>
                                        <Field
                                            name="password"
                                            component={renderField}
                                            placeholder=""
                                            type="password"
                                            className="form-control"
                                            validate={update ? "" : required}
                                        // disabled={view || update}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Documentos personales
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">DPI</label>
                                        <Field
                                            name="dpi"
                                            component={renderField}
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                dpi
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">NIT</label>
                                        <Field
                                            name="nit"
                                            component={renderField}
                                            placeholder=""
                                            type="text"
                                            className="form-control"
                                            validate={composeValidators(
                                                required,
                                                NIT
                                            )}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="foto">Fotografía</label>
                                        <Field
                                            name="foto"
                                            component={renderPhotoUploader}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Viáticos semanalmente
                                        </label>
                                        <Field
                                            name="viaticos"
                                            component={renderNumber}
                                            type="text"
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="rol">
                                            Selección de roles
                                        </label>

                                        <Field
                                            name="rol"
                                            component={renderSelectField}
                                            options={roles}
                                            placeholder="Seleccionar"
                                            type="text"
                                            parse={(value) => {
                                                setPermiso(value);
                                                return value;
                                            }}
                                            className="form-control"
                                            validate={required}
                                        />
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>

                                    <div className="form-group has-feedback m-2 flex-3 d-flex">
                                        {(permiso === "es_gerente" ||
                                            permiso === "es_encargado_tienda" ||
                                            permiso === "es_vendedor" ||
                                            permiso ===
                                            "es_vendedor_mayorista" ||
                                            permiso ===
                                            "es_vendedor_distribuidor" ||
                                            values.ov_mayorista ||
                                            values.ov_minorista ||
                                            values.ov_distribuidor) && (
                                                <React.Fragment>
                                                    <div className="form-group has-feedback flex-1">
                                                        <label htmlFor="realiza_descuentos">
                                                            Realiza descuentos:
                                                        </label>
                                                        <div className="d-flex align-items-center">
                                                            <label className="mr-2">
                                                                No
                                                            </label>
                                                            <Field
                                                                name="realiza_descuentos"
                                                                component={
                                                                    renderSwitch
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                            />
                                                            <label className="ml-2">
                                                                Si
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group has-feedback flex-1">
                                                        <label htmlFor="realiza_venta_costo">
                                                            Realiza venta al costo:
                                                        </label>
                                                        <div className="d-flex align-items-center">
                                                            <label className="mr-2">
                                                                No
                                                            </label>
                                                            <Field
                                                                name="realiza_venta_costo"
                                                                component={
                                                                    renderSwitch
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                            />
                                                            <label className="ml-2">
                                                                Si
                                                            </label>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                {/* Listado de permisos */}

                                <FormPermisos
                                    nombre_permiso={permiso}
                                    is_update={update}
                                    collapse={collapse}
                                    setCollapse={setCollapse}
                                />

                                {/* Permisos de ov */}
                                {values.rol &&
                                    values.rol == "personalizado" &&
                                    collapse == true && (
                                        <div
                                            className={`${!isFirefoxBrowser
                                                    ? "container_permiso"
                                                    : "container_permiso-flex"
                                                } mt-5`}
                                        >
                                            <div className="d-flex flex-column">
                                                <label htmlFor="name">
                                                    Ordenes de Venta
                                                </label>
                                                <div className="d-flex flex-column pl-4">
                                                    <div className="d-flex ">
                                                        <Field
                                                            name={
                                                                "ov_mayorista"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>Mayorista</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "ov_minorista"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>Minorista</label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "ov_distribuidor"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Distribuidor
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <label htmlFor="name">
                                                    Permisos Bodega
                                                </label>
                                                <div className="d-flex flex-column pl-4">
                                                    <div className="d-flex ">
                                                        <Field
                                                            name={
                                                                "igreso_mercaderia"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Listado de
                                                            movimientos
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "baja_mercaderia"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Devolucion Proveedor
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "salida_mercaderia"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Devolucion Cliente
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reajuste_mercaderia"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            Hacer Reajuste
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <label htmlFor="name">
                                                    Permisos Reportes
                                                </label>
                                                <div className="d-flex flex-column pl-4">
                                                    <div className="d-flex ">
                                                        <Field
                                                            name={
                                                                "reporte_cuenta_por_cobrar"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte cuenta por cobrar
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_cuenta_por_pagar"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte cuenta por pagar
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_kardex"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte kardex
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_recibos_cuenta_por_cobrar"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte recibos cuenta por cobrar
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_recibos_cuenta_por_pagar"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte recibos cuenta por pagar
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_comisiones"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte comisiones
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_notas_de_credito_al_proveedor"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte notas de credito al proveedor
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_notas_de_credito_al_cliente"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte notas de credito al cliente
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_articulos_inventario"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte articulos inventario
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_articulos_vendidos"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte articulos vendidos
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_articulos_de_venta"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte articulos de venta
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_entradas"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte entradas
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_salidas"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte salidas
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_caja"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte caja
                                                        </label>
                                                    </div>
                                                    <div className="d-flex">
                                                        <Field
                                                            name={
                                                                "reporte_reajustes"
                                                            }
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>
                                                            reporte reajustes
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <hr className="mb-2" />
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Carga de archivos
                                        </label>

                                        <MultiUploadFile
                                            id={id}
                                            files={files}
                                            onChangeFile={onChangeFile}
                                            onRemoveFile={onRemoveFile}
                                            onRemoveOldFile={onRemoveOldFile}
                                            data={oldFiles}
                                        />
                                    </div>
                                </div>
                                <h3 className="contenido-1 border-bottom txt-bold black mb-4 pt-4">
                                    Comisiones
                                </h3>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Aplicar Comisiones
                                        </label>
                                        <div className="d-flex">
                                            <label className="mr-2">No</label>
                                            <Field
                                                name="comisiones"
                                                component={renderSwitch}
                                                type="text"
                                                className="form-control"
                                            />
                                            <label className="ml-2">Si</label>
                                        </div>
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3"></div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Comisión crédito
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="comision_credito"
                                                component={renderNumberFormat}
                                                placeholder="%"
                                                type="text"
                                                suffix={"%"}
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="comision_credito"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Días de validéz
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="validez"
                                                component={renderNumber}
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="validez"
                                                component={renderNumber}
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-4">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Comisión contado
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="comision_contado"
                                                component={renderNumberFormat}
                                                placeholder="%"
                                                type="text"
                                                suffix={"%"}
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="comision_contado"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="name">
                                            Meta de venta mensual
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="meta_venta"
                                                component={renderCurrency}
                                                placeholder="Q"
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}

                                        {!values.comisiones && (
                                            <Field
                                                name="meta_venta"
                                                component={renderCurrency}
                                                placeholder="Q"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>
                                </div>
                                <div className="d-flex flex-column flex-md-row mb-5">
                                    <div className="form-group has-feedback m-2 flex-3">
                                        <label htmlFor="porcentaje_comision_mensual">
                                            Comisión meta de venta mensual
                                        </label>
                                        {values.comisiones && (
                                            <Field
                                                name="porcentaje_comision_mensual"
                                                component={renderNumberFormat}
                                                placeholder="%"
                                                suffix={"%"}
                                                type="text"
                                                className="form-control"
                                                validate={required}
                                            />
                                        )}
                                        {!values.comisiones && (
                                            <Field
                                                name="porcentaje_comision_mensual"
                                                component={renderNumber}
                                                placeholder="%"
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group has-feedback m-2 flex-1"></div>

                                    <div className="form-group has-feedback m-2 flex-3" />
                                    <div className="form-group has-feedback m-2 flex-1" />
                                </div>
                            </div>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4">
                                <Link
                                    to="/configuracion/usuarios/"
                                    className="btn btn-outline-primary mt-4 mt-md-0"
                                >
                                    Regresar
                                </Link>

                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    {update ? "Actualizar" : "Agregar"} Usuario
                                </button>
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default UsuarioForm;
