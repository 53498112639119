import React, { useState, useEffect } from "react";
import _ from "lodash";
import { api } from "api";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
} from "../../../../../utility/validation";

import {
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    //AsyncSelectField,
    renderAsyncSelectField,
    renderCurrency,
    SelectField,
} from "Utils/renderField/renderField";
import UpdateProductPrice from "./updateProductPrice";

const VerificacionPasswordForm = ({
    data = {},
    view = false,
    update = false,
    onSubmit,
    verificado,
}) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                mutators={{
                    // expect (field, value) args from the mutator
                    setInput: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                initialValues={{
                    ...data,
                }}
                render={({ handleSubmit, submitting, values, form }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="mb-3 mt-2  px-3 py-1">
                            {/* Form 0 */}
                            <div className="d-flex flex-column flex-md-row mb-2 ">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="name">Contraseña *</label>

                                    <Field
                                        name="password"
                                        component={renderField}
                                        placeholder="Q"
                                        type="password"
                                        autocomplete="off"
                                        className="form-control"
                                        validate={required}
                                        disabled={verificado}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3  d-flex justify-content-center ">
                                    <button
                                        type="submit"
                                        className="btn btn-danger btn-bodega mt-4"
                                        disabled={verificado}
                                    >
                                        Verificar
                                    </button>
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            {/*  */}
                        </div>
                        {/*  <div className="d-flex flex-column-reverse flex-md-row justify-content-between py-4">
                            <button
                                type="button"
                                onClick={() => {
                                    setSelected({
                                        view: "LIST",
                                        product: undefined,
                                    });
                                }}
                                className="btn btn-outline-secondary mt-4 mt-md-0"
                            >
                                Volver
                            </button>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    Actualizar Producto
                                </button>
                            )}
                            </div>*/}
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default VerificacionPasswordForm;
