import React, { useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import {
    formatoEmpty,
    formatoAceptaTarjeta,
    formatoMonedaQuetzales,
} from "Utils/CamposComunes";

const ListadoTransportes = ({
    listar,
    page,
    data,
    loader = false,
    sortChange,
    eliminar,
    buscar,
    search,
}) => {
    useEffect(() => {
        listar(page);
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <Link
                        className="btn btn-primary mb-sm-10"
                        to="/transportes/crear/"
                    >
                        <em className="fa fa-plus"></em> Agregar Transporte
                    </Link>

                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Transportes"}
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="telefono"
                                    dataSort
                                    dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Teléfono
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="url_rastreo"
                                    dataSort
                                    dataFormat={formatoEmpty}
                                    // width={"20rem"}
                                >
                                    Url Rastreo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={activeFormatter({
                                        // ver: "/transportes/detalle",
                                        editar: "/transportes/editar",
                                        eliminar,
                                        // adicional: formatoVistaImprimir,
                                    })}
                                    width={"10rem"}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListadoTransportes;
