import React, { useState, Component } from "react";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";

import { pago_total, pago_credito } from "utility/variables";

import { icons } from "icons";
import ReciboOV from "Utils/ReciboOV";
import Factura from "Utils/Factura";
import PropTypes from "prop-types";
import ModalAyuda from "Utils/Ayuda";
import { ToastStore } from "react-toasts";
import Modal from "react-responsive-modal";
import ReactToPrint from "react-to-print";
import { ListadoAccion } from "Utils/ListadoAccion";
import { ListadoCards } from "Utils/ListadoCards";
import TicketCotizacion from "Utils/TicketCotizacion";
import ReciboVentaCredito from "Utils/ReciboVentaCredito";
import { DetalleVentaCotizacion } from "Utils/Venta/DetalleVentaCotizacion";
import { PrintContainer, PrintComponent } from "Utils/Print";
import TicketCotizacion2 from "Utils/TicketCotizacion/ticket";
import TicketOV from "Utils/Recibo/TicketOV";

export default class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTicket: false,
        };
    }

    render() {
        // state
        const {
            listar,
            sortChange,
            loader,
            data,
            page,
            total,
            formato_factura,
            factura_personalizada,
            ver_buscador,
            ver_cobro,
            tipo_pago,
            search,
            print_state,
            conf_venta_precio_editable,
        } = this.props;

        const {
            agregar,
            movimiento,
            venta_impresion,
            cambioBuscador,
            cambioCobro,
            utiliza_fel,
            conf_cotizacion,
            ver_impresion,
            venta_cotizacion,
            seleccionados,
            cotizacion_cargada,
        } = this.props;

        // bind
        const {
            resetVenta,
            pushRouter,
            leerClienteCotizacion,
            finalizarCotizacion,
        } = this.props;

        let pagina = 0;

        return (
            <div className={`col-md-12 `}>
                <LoadMask loading={loader} light blur radius>
                    {movimiento.sucursal !== undefined ? (
                        <div className="w-100">
                            {
                                // () ? (
                                /* region RECIBO VENTA */
                                <div
                                    className={`w100 container-vista-previa ${
                                        factura_personalizada &&
                                        tipo_pago === pago_total
                                            ? "d-none"
                                            : ""
                                    }`}
                                >
                                    {print_state.flag && <PrintComponent />}
                                    <div className="d-flex flex-row flex-xs-column justify-content-between align-items-center container-vista-previa-head">
                                        <b
                                            className="pl-1"
                                            style={{
                                                fontSize: "23px",
                                            }}
                                        >
                                            Vista previa
                                        </b>
                                        <div className="text-right flex1 mr-4">
                                            {/*SE COMENTARON LAS SIGUIENTES LINEAS PARA NO
                                                            UTILIZAR LAS printActions, DESCOMENTAR EN CASO
                                                            DE QUERER UTILIZARLAS DE NUEVO
                                                        */
                                            /*<button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (tipo_pago==pago_total)
                                                                    printActions.print('reciboVenta');
                                                                else
                                                                    printActions.print('reciboVentaCredito');
                                                            }}
                                                            className="btn btn-primary mt-lg"
                                                            type={'button'}
                                                        >
                                                            <em className="fa fa-print" /> Imprimir
                                                        </button>*/}
                                            {tipo_pago == pago_credito && (
                                                <button
                                                    className="btn mr-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.print(
                                                            "reciboVenta"
                                                        );
                                                    }}
                                                >
                                                    <em className="fa fa-print" />{" "}
                                                    {factura_personalizada ||
                                                    utiliza_fel ? (
                                                        <span>Factura</span>
                                                    ) : (
                                                        <span>Recibo</span>
                                                    )}
                                                </button>
                                            )}
                                            <button
                                                onClick={(e) => {
                                                    this.setState({
                                                        isTicket: false,
                                                    });
                                                    e.preventDefault();
                                                    if (tipo_pago == pago_total)
                                                        this.props.print(
                                                            "reciboVenta"
                                                        );
                                                }}
                                                style={{ width: "200px" }}
                                                className="btn mr-1 btn-primary mt-lg"
                                                type={"button"}
                                            >
                                                <em className="fa fa-print" />
                                                Imprimir documento
                                            </button>

                                            <button
                                                onClick={(e) => {
                                                    this.setState({
                                                        isTicket: true,
                                                    });
                                                    e.preventDefault();
                                                    if (tipo_pago == pago_total)
                                                        this.props.print(
                                                            "reciboVenta"
                                                        );
                                                }}
                                                style={{ width: "150px" }}
                                                className="btn mr-1 btn-primary mt-lg"
                                                type={"button"}
                                            >
                                                <em className="fa fa-print" />
                                                Imprimir ticket
                                            </button>

                                            <Link
                                                className="btn btn-outline-secondary ml-1 align-self-center"
                                                to="/ordenes_de_venta"
                                            >
                                                Regresar
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="container-vista-previa-body">
                                        {!factura_personalizada && (
                                            <div
                                                className={`d-flex flex-row justify-content-center w100 ${
                                                    tipo_pago == pago_credito
                                                        ? "print-only"
                                                        : ""
                                                }`}
                                            >
                                                <PrintContainer
                                                    ref={(el) =>
                                                        (this.reciboVenta = el)
                                                    }
                                                    name="reciboVenta"
                                                >
                                                    {!this.state.isTicket ? (
                                                        <React.Fragment>
                                                            {venta_impresion &&
                                                                venta_impresion.paginas &&
                                                                [
                                                                    ...Array(
                                                                        venta_impresion.paginas
                                                                    ),
                                                                ].map(
                                                                    (x, i) => {
                                                                        pagina =
                                                                            pagina +
                                                                            1;
                                                                        return (
                                                                            <React.Fragment>
                                                                                <ReciboOV
                                                                                    pagina={
                                                                                        pagina
                                                                                    }
                                                                                    sucursal={
                                                                                        movimiento.sucursal
                                                                                    }
                                                                                    movimiento={
                                                                                        movimiento
                                                                                    }
                                                                                    usuario={
                                                                                        movimiento
                                                                                            .usuario
                                                                                            .first_name
                                                                                            ? movimiento
                                                                                                  .usuario
                                                                                                  .first_name
                                                                                            : ""
                                                                                    }
                                                                                    footerText={
                                                                                        "TOTAL"
                                                                                    }
                                                                                    tipo={
                                                                                        "Venta"
                                                                                    }
                                                                                    utiliza_fel={
                                                                                        utiliza_fel
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                )}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {[
                                                                ...Array(
                                                                    venta_impresion.paginas
                                                                ),
                                                            ].map((x, i) => {
                                                                pagina =
                                                                    pagina + 1;
                                                                return (
                                                                    <React.Fragment>
                                                                        <TicketOV
                                                                            pagina={
                                                                                pagina
                                                                            }
                                                                            sucursal={
                                                                                venta_impresion.sucursal
                                                                            }
                                                                            movimiento={
                                                                                venta_impresion
                                                                            }
                                                                            usuario={
                                                                                venta_impresion
                                                                                    .usuario
                                                                                    .first_name
                                                                                    ? venta_impresion
                                                                                          .usuario
                                                                                          .first_name
                                                                                    : ""
                                                                            }
                                                                            footerText={
                                                                                "TOTAL"
                                                                            }
                                                                            tipo={
                                                                                "Venta"
                                                                            }
                                                                            utiliza_fel={
                                                                                false
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </PrintContainer>
                                            </div>
                                        )}

                                        {tipo_pago == pago_credito && (
                                            <div className="d-flex flex-row justify-content-center w100">
                                                <PrintContainer
                                                    ref={(el) =>
                                                        (this.reciboVentaCredito =
                                                            el)
                                                    }
                                                    name="reciboVentaCredito"
                                                >
                                                    {venta_impresion && (
                                                        <ReciboVentaCredito
                                                            sucursal={
                                                                movimiento.sucursal
                                                            }
                                                            movimiento={
                                                                movimiento
                                                            }
                                                            utiliza_fel={
                                                                utiliza_fel
                                                            }
                                                        />
                                                    )}
                                                </PrintContainer>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                /* endregion RECIBO VENTA */
                                // ):null
                            }
                        </div>
                    ) : (
                        <div className="slide-container row w-100 d-flex justify-content-center">
                            {print_state.flag && <PrintComponent />}
                            <div
                                style={{ backgroundColor: "transparent" }}
                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                            >
                                <div className="w-100 text-center">
                                    {/*<h5 className="negro-dark2">
                                            Vista previa cotización
                                        </h5>*/}
                                    <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print(
                                                    "ticketCotizacion"
                                                );
                                                this.setState({
                                                    isTicket: false,
                                                });
                                            }}
                                            style={{ width: "200px" }}
                                            className="btn mr-1 btn-primary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir documento
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print(
                                                    "ticketCotizacion"
                                                );
                                                this.setState({
                                                    isTicket: true,
                                                });
                                            }}
                                            style={{ width: "150px" }}
                                            className="btn mr-1 btn-primary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir ticket
                                        </button>
                                        {/*<button
                                                style={{ width: "150px" }}
                                                className="btn ml-1 btn-outline-secondary mt-lg"
                                                type="button"
                                                onClick={() => {
                                                    finalizarCotizacion();
                                                }}
                                            >
                                                <em className="fa fa-check" />{" "}
                                                Finalizar cotización
                                            </button>*/}

                                        <Link
                                            className="btn btn-outline-secondary ml-1 align-self-center"
                                            to="/ordenes_de_venta"
                                        >
                                            <em className="fa fa-check" />{" "}
                                            Finalizar
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="container-vista-previa-body w100">
                                <div className="d-flex flex-row justify-content-center ">
                                    <PrintContainer name="ticketCotizacion">
                                        {!this.state.isTicket ? (
                                            <React.Fragment>
                                                {venta_cotizacion &&
                                                    venta_cotizacion.paginas &&
                                                    [
                                                        ...Array(
                                                            venta_cotizacion.paginas
                                                        ),
                                                    ].map((x, i) => {
                                                        pagina = pagina + 1;
                                                        return (
                                                            <React.Fragment>
                                                                <TicketCotizacion
                                                                    pagina={
                                                                        pagina
                                                                    }
                                                                    cotizacion={
                                                                        venta_cotizacion
                                                                    }
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {venta_cotizacion && (
                                                    <TicketCotizacion2
                                                        cotizacion={
                                                            venta_cotizacion
                                                        }
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </PrintContainer>
                                </div>
                            </div>
                        </div>
                    )}
                </LoadMask>
            </div>
        );
    }
}
