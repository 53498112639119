import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "Utils/renderField/renderTableField";

const DevDesSubTabla = (row) => {
    return (
        <div className="border border-primary p-2 mt-2 mb-3 bg-blanco">
            <BootstrapTable data={row.detalles} remote={false}>
                <TableHeaderColumn dataField="id" width="0" isKey>
                    Correlativo
                </TableHeaderColumn>

                <TableHeaderColumn dataField="fecha_movimiento">
                    Fecha
                </TableHeaderColumn>

                <TableHeaderColumn dataField="correlativo">
                    Correlativo
                </TableHeaderColumn>

                <TableHeaderColumn dataField="tipo" dataSort={false}>
                    Tipo
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="total"
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                >
                    Total
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="total_sin_iva"
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Total sin iva
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default DevDesSubTabla;
