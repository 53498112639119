import React from "react";

import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { required } from "../../../../utility/validation";
import { renderField } from "Utils/renderField/renderField";
import {
    renderNumberFormat,
    renderSwitch,
    renderTextArea,
} from "../../Utils/renderField/renderField";

const TransportesForm = ({
    intitalData,
    onSubmit,
    ver = false,
    editar = false,
}) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={{ saldo: 0, ...intitalData }}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="mb-5 card card-small px-3 py-4">
                            <h3 className="contenido-1 border-bottom txt-bold black mb-2">
                                Datos de la cuenta
                            </h3>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="nombre">Nombre</label>
                                    <Field
                                        name="nombre"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        placeholder="Guatex, CargoExpreso..."
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <Field
                                        name="telefono"
                                        component={renderNumberFormat}
                                        numberFormat="#### ####"
                                        type="number"
                                        validate={required}
                                        className="form-control"
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-3">
                                    <label htmlFor="url_rastreo">
                                        URL Rastreo
                                    </label>
                                    <Field
                                        name="url_rastreo"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        validate={required}
                                        disabled={ver}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                                <div className="form-group has-feedback m-2 flex-3"></div>
                                <div className="form-group has-feedback m-2 flex-1"></div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-center py-4">
                                <Link
                                    to="/transportes/"
                                    className="btn btn-outline-secondary mt-4 mt-md-0 mr-0 mr-md-3"
                                >
                                    Volver
                                </Link>
                                {!ver && (
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        disabled={submitting}
                                    >
                                        {editar ? "Editar" : "Agregar"} Transporte
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default TransportesForm;
