import { connect } from "react-redux";
import Modal from "./Modal";
import { actions } from "../../../../../redux/modules/cuenta_pagar/cuenta_pagar";

const ms2p = (state) => {
    return {
        ...state.cuenta_pagar,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Modal);
