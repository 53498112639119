import React, { useState, useEffect } from "react";
import { icons } from "icons";
import StepWizard from "Utils/StepWizard";
import LoadMask from "Utils/LoadMask/LoadMask";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { envio, factura, credito, cotizacion } from "utility/constantes";
//import DatosForm from "../../Ventas/Venta/DatosGeneralesOVForm";
import Venta from "../../Ventas/Venta";
import ListadoCliente from "./ListadoClientes";

//Data components
import DatosGenerales from "./DatosGenerales";
import PuntoVenta from "./PuntoVenta";
//import PagoForm from "./Pago";
//import Pago from "./Pago/Pago";
//import Cobro from "./Pago/Cobro";
import Ticket from "./Ticket/Ticket";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";

import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
    HeaderItem,
} from "react-dropdown-advanced";
import Cobro from "./Pago/Cobro";
import CotizacionForm from "./Cotizacion/CotizacionForm";
import CreditoForm from "./Credito/CreditoForm";
import DatosClienteForm from "./Clientes/RegistarClienteForm";
import { initial_state_cliente } from "../../../../redux/modules/venta/venta";

const CrearOrdenVenta = (props) => {
    //Consts
    const {
        paso,
        siguientePaso,
        loader,
        datos,
        //  Cliente
        loader_cliente,
        search_cliente,
        page_cliente,
        data_cliente,
        municipios,
        modal_cliente,
        setModalNuevoCliente,
        nuevoCliente,
        modal_listado_cliente,
        //nuevoCliente,
        listarCliente,
        buscarCliente,
        setMunicipios,
        seleccionarCliente,
        setModalListadoCliente,
        tipo_venta,
        sucursal,
        setDatosCliente,

        match,
    } = props;

    //Modal Clientes
    //const [open, setOpen] = useState(false);
    const onOpenModal = () => setModalListadoCliente(true);
    const onCloseModal = () => setModalListadoCliente(false);

    const abrirModalCliente = () => {
        setModalNuevoCliente(true);
    };
    const cerrarModalCliente = () => {
        setModalNuevoCliente(false);
    };

    //  STEPS
    const steps = [
        {
            value: 1,
            label: "Datos Generales",
        },
        {
            value: 2,
            label: "Punto de venta",
        },
        {
            value: 3,
            label: "Pago",
        },
        {
            value: 4,
            label: "Confirmación",
        },
    ];
    const [nombre, setNombre] = useState("");
    //  STEPS COTIZACION
    const steps_cotizacion = [
        {
            value: 1,
            label: "Datos Generales",
        },
        {
            value: 2,
            label: "Punto de venta",
        },
        {
            value: 3,
            label: "Confirmación",
        },
        {
            value: 4,
            label: "Vista previa",
        },
    ];

    const [state, setState] = useState({
        modal_vale: false,
        modal_existencia: false,
        modalAyuda: false,
        modal_cliente: false,
        dropdownItems: [
            new ActionItem("cobrar_credito", "Cobrar créditos"),
            new ActionItem("recibir_devolucion", "Recibir devolución"),
            new ActionItem(
                "buscar_productos_sucursales",
                "Buscar producto en otra sucursal"
            ),
        ],
        dropdownItemsAyuda: [
            new HeaderItem("¿Con qué necesitas ayuda?"),
            new ActionItem("venta", "¿Cómo realizar una venta?"),
            new ActionItem("anular_venta", "¿Cómo anular una venta?"),
            new ActionItem(
                "venta_credito",
                "¿Cómo realizar una venta al crédito?"
            ),
            new ActionItem(
                "canje_vale_devolucion",
                "¿Cómo canjear un vale de devolución?"
            ),
            new ActionItem("cuenta_cobrar", "¿Cómo cobrar un crédito?"),
            new ActionItem("devolucion", "¿Cómo recibir una devolución?"),
        ],
        dropdownItemsAyudaCobro: [
            new HeaderItem("¿Con qué necesitas ayuda?"),
            new ActionItem(
                "venta_credito",
                "¿Cómo realizar una venta al crédito?"
            ),
        ],
        itemsImagenes: [],
        itemsCuentaCobrar: [
            {
                src: icons.cuenta_cobrar_p1,
                alt: "Paso 1",
            },
            {
                src: icons.cuenta_cobrar_p2,
                alt: "Paso 2",
            },
            {
                src: icons.cuenta_cobrar_p3,
                alt: "Paso 3",
            },
            {
                src: icons.cuenta_cobrar_p4,
                alt: "Paso 4",
            },
            {
                src: icons.cuenta_cobrar_p5,
                alt: "Paso 5",
            },
            {
                src: icons.cuenta_cobrar_p6,
                alt: "Paso 6",
            },
            {
                src: icons.cuenta_cobrar_p7,
                alt: "Paso 7",
            },
            {
                src: icons.cuenta_cobrar_p8,
                alt: "Paso 7",
            },
        ],
        itemsDevolucion: [
            {
                src: icons.devolucion_p1,
                alt: "Paso 1",
            },
            {
                src: icons.devolucion_p2,
                alt: "Paso 2",
            },
            {
                src: icons.devolucion_p3,
                alt: "Paso 3",
            },
            {
                src: icons.devolucion_p4,
                alt: "Paso 4",
            },
            {
                src: icons.devolucion_p5,
                alt: "Paso 5",
            },
            {
                src: icons.devolucion_p6,
                alt: "Paso 6",
            },
            {
                src: icons.devolucion_p7,
                alt: "Paso 7",
            },
            {
                src: icons.devolucion_p8,
                alt: "Paso 8",
            },
            {
                src: icons.devolucion_p9,
                alt: "Paso 9",
            },
            {
                src: icons.devolucion_p10,
                alt: "Paso 10",
            },
            {
                src: icons.devolucion_p11,
                alt: "Paso 11",
            },
        ],
        itemsAnularVenta: [
            {
                src: icons.anular_venta_p1,
                alt: "Paso 1",
            },
            {
                src: icons.anular_venta_p2,
                alt: "Paso 2",
            },
            {
                src: icons.anular_venta_p3,
                alt: "Paso 3",
            },
            {
                src: icons.anular_venta_p4,
                alt: "Paso 4",
            },
            {
                src: icons.anular_venta_p5,
                alt: "Paso 5",
            },
        ],
        itemsVenta: [
            {
                src: icons.venta_p1,
                alt: "Paso 1",
            },
            {
                src: icons.venta_p2,
                alt: "Paso 2",
            },
            {
                src: icons.venta_p3,
                alt: "Paso 3",
            },
            {
                src: icons.venta_p4,
                alt: "Paso 4",
            },
            {
                src: icons.venta_p5,
                alt: "Paso 5",
            },
            {
                src: icons.venta_p6,
                alt: "Paso 6",
            },
            {
                src: icons.venta_p7,
                alt: "Paso 7",
            },
            {
                src: icons.venta_p8,
                alt: "Paso 8",
            },
            {
                src: icons.venta_p9,
                alt: "Paso 9",
            },
            {
                src: icons.venta_p10,
                alt: "Paso 10",
            },
            {
                src: icons.venta_p11,
                alt: "Paso 11",
            },
            {
                src: icons.venta_p12,
                alt: "Paso 11",
            },
            {
                src: icons.venta_p13,
                alt: "Paso 11",
            },
            {
                src: icons.venta_p14,
                alt: "Paso 11",
            },
            {
                src: icons.venta_p15,
                alt: "Paso 11",
            },
            {
                src: icons.venta_p16,
                alt: "Paso 16",
            },
            {
                src: icons.venta_p17,
                alt: "Paso 17",
            },
            {
                src: icons.venta_p18,
                alt: "Paso 18",
            },
        ],
        itemsVentaCredito: [
            {
                src: icons.venta_credito_p1,
                alt: "Paso 1",
            },
            {
                src: icons.venta_credito_p2,
                alt: "Paso 2",
            },
            {
                src: icons.venta_credito_p3,
                alt: "Paso 3",
            },
            {
                src: icons.venta_credito_p4,
                alt: "Paso 4",
            },
            {
                src: icons.venta_credito_p5,
                alt: "Paso 5",
            },
        ],
        itemsValeDevolucion: [
            {
                src: icons.canje_vale_devolucion_p1,
                alt: "Paso 1",
            },
            {
                src: icons.canje_vale_devolucion_p2,
                alt: "Paso 2",
            },
            {
                src: icons.canje_vale_devolucion_p3,
                alt: "Paso 3",
            },
            {
                src: icons.canje_vale_devolucion_p4,
                alt: "Paso 4",
            },
        ],
        itemsAyuda: [
            { texto: "¿Cómo realizar una venta?", key: "venta" },
            { texto: "¿Cómo anular una venta?", key: "anular_venta" },
            {
                texto: "¿Cómo realizar una venta al crédito?",
                key: "venta_credito",
            },
            {
                texto: "¿Cómo canjear un vale de devolución?",
                key: "canje_vale_devolucion",
            },
            { texto: "¿Cómo cobrar un crédito?", key: "cuenta_cobrar" },
            { texto: "¿Cómo recibir una devolución?", key: "devolucion" },
        ],
        itemsAyudaCobro: [
            {
                texto: "¿Cómo realizar una venta al crédito?",
                key: "venta_credito",
            },
        ],
    });

    // AYUDA
    const onClickAyuda = (item) => {
        if (item.key === "cuenta_cobrar") {
            setState({
                ...state,
                itemsImagenes: state.itemsCuentaCobrar,
            });
            //openModalAyuda();
        } else if (item.key === "devolucion") {
            setState({
                ...state,
                itemsImagenes: state.itemsDevolucion,
            });
            //openModalAyuda();
        } else if (item.key === "anular_venta") {
            setState({
                ...state,
                itemsImagenes: state.itemsAnularVenta,
            });
            //openModalAyuda();
        } else if (item.key === "venta") {
            setState({
                ...state,
                itemsImagenes: state.itemsVenta,
            });
            //openModalAyuda();
        } else if (item.key === "venta_credito") {
            setState({
                itemsImagenes: state.itemsVentaCredito,
            });
            //openModalAyuda();
        } else if (item.key === "canje_vale_devolucion") {
            setState({
                itemsImagenes: state.itemsValeDevolucion,
            });
            //openModalAyuda();
        }
    };

    const onClickOtrasAcciones = (item) => {
        // bind
        const { utiliza_fel } = props;

        if (item.key === "cobrar_credito") {
            pushRouter("/cuentas_por_cobrar");
        } else if (item.key === "recibir_devolucion") {
            pushRouter("devolucion");
        } else if (item.key === "buscar_productos_sucursales") {
            console.log("Open modal existencias");
            //this.openModalExistencias();
        }
    };

    useEffect(() => {
        return () => {
            setDatosCliente(initial_state_cliente);
            props.removerAll();
        };
    }, []);

    if (sucursal === undefined) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="grid-titulo padding-5">
                            Para registrar una venta elija una sucursal
                        </div>
                        <div className="padding-15">
                            <SeleccionSucursal />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt-3">
                        <StepWizard
                            steps={
                                tipo_venta != cotizacion &&
                                tipo_venta != credito
                                    ? steps
                                    : steps_cotizacion
                            }
                            active={paso}
                        />
                        <LoadMask loading={loader} dark blur>
                            {/*
                                        {...this.props}
                                        ver_buscador={ver_buscador}
                                    loader={loader}
                                    productos={productos}
                                    page={page}
                                    seleccionados={seleccionados}
                                    total={total}
                                    search={search}
                                    onSubmit={siguientePaso}
                                    listar={listar}
                                    buscar={buscar}
                                    sortChange={sortChange}
                                    setVerBuscador={setVerBuscador}
                                    asignarDatos={asignarDatos}
                                    //  PRODUCTO
                                    pais={pais}
                                    quitar={quitar}
                                    agregar={agregar}
                                    asignarPrecio={asignarPrecio}
                                    limpiarDatosOC={limpiarDatosOC}
                                    asignarCantidad={asignarCantidad}
                                    
                                    />*/}

                            {paso == 1 && (
                                <React.Fragment>
                                    <DatosGenerales
                                        {...props}
                                        onClickAyuda={onClickAyuda}
                                        onClickOtrasAcciones={
                                            onClickOtrasAcciones
                                        }
                                        state={state}
                                        setState={setState}
                                        abrirModalCliente={onOpenModal}
                                        onSubmit={siguientePaso}
                                    />
                                </React.Fragment>
                            )}

                            {paso == 2 && (
                                <React.Fragment>
                                    <PuntoVenta
                                        {...props}
                                        onSubmit={siguientePaso}
                                    />
                                    {/*<EntregaForm />*/}
                                </React.Fragment>
                            )}

                            {paso == 3 && (
                                <React.Fragment>
                                    {tipo_venta == envio && (
                                        <Cobro
                                            {...props}
                                            abrirModalCliente={onOpenModal}
                                        />
                                    )}

                                    {tipo_venta == factura && (
                                        <Cobro
                                            {...props}
                                            abrirModalCliente={onOpenModal}
                                        />
                                    )}

                                    {tipo_venta == cotizacion && (
                                        <React.Fragment>
                                            <CotizacionForm
                                                {...props}
                                                abrirModalCliente={onOpenModal}
                                            />
                                        </React.Fragment>
                                    )}

                                    {tipo_venta == credito && (
                                        <React.Fragment>
                                            <CreditoForm
                                                {...props}
                                                abrirModalCliente={onOpenModal}
                                            />
                                        </React.Fragment>
                                    )}
                                    {/* (tipo_venta == envio ||
                                        tipo_venta == cotizacion) */}
                                </React.Fragment>
                            )}

                            {paso == 4 && (
                                <React.Fragment>
                                    <Ticket {...props} />
                                    {/*< <ConfirmacionForm /> >*/}
                                </React.Fragment>
                            )}
                            {/*<Cobro {...props} />*/}
                        </LoadMask>
                    </div>
                </div>
            </div>
            {/*<div className="row">
                <div className="col-12">
                    <div className="grid-container">
                        <h1 className="grid-titulo padding-15">
                            Punto de venta
                        </h1>
                    </div>
                </div>
            </div>*/}
            {/*<hr />
            <Venta />
        <hr />*/}

            {/* MODAL CLIENTES */}
            <Modal
                classNames={{ modal: "modal-md" }}
                open={modal_listado_cliente}
                showCloseIcon={true}
                onClose={onCloseModal}
            >
                <div
                    className="p-0 grid-clientes-ov"
                    style={{ maxWidth: "500px !important" }}
                >
                    <ListadoCliente
                        loader={loader_cliente}
                        search={search_cliente}
                        page={page_cliente}
                        data={data_cliente}
                        buscar={buscarCliente}
                        listar={listarCliente}
                        cerrar={onCloseModal}
                        sucursal={sucursal}
                        abrirModalCliente={abrirModalCliente}
                        seleccionarCliente={seleccionarCliente}
                        tipo_venta={tipo_venta}
                        setDatosCliente={setDatosCliente}
                    />
                </div>
            </Modal>

            {/* MODAL NUEVO CLIENTE */}
            <Modal
                classNames={{ modal: "modal-md" }}
                onClose={cerrarModalCliente} //cerrarModalCliente
                showCloseIcon={true}
                open={modal_cliente}
            >
                <div
                    className="p-0 grid-clientes-ov"
                    style={{ maxWidth: "500px !important" }}
                >
                    <LoadMask loading={loader_cliente} dark blur>
                        <DatosClienteForm
                            setNombre={setNombre}
                            nombre={nombre}
                            desde_ov={true}
                            municipios={municipios}
                            onSubmit={() => nuevoCliente(nombre)}
                            setMunicipios={setMunicipios}
                            cancelar={() => {
                                cerrarModalCliente();
                                onOpenModal();
                            }}
                        />
                    </LoadMask>
                </div>
            </Modal>
            <br />
        </React.Fragment>
    );
};

export default CrearOrdenVenta;
