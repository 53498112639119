import moment from "moment/moment";
import { PVN, PVMCE } from "utility/variables";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import TituloBorde from "../Utils/TituloBorde/TituloBorde";

import "./dashboard.css";
import defaultPeril from "../../../../assets/img/avatar-placeholder.png";
import bunting from "../../../../assets/img/bunting.svg";
import Cake from "../../../../assets/img/cake.svg";
import CakeIcon from "../../../../assets/img/cake.png";

const Dashboard = ({
    data,
    loader,
    usuario,
    tipo_punto_venta,
    setDataDashboard,
}) => {
    useEffect(() => {
        if (localStorage.getItem("sucursal")) {
            setDataDashboard();
        }
    }, []);

    const existen_cumpleneros = data && data.length > 0;

    const dias = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
    ];

    if (
        !usuario.dashboard &&
        !usuario.es_propietario &&
        !usuario.is_superuser &&
        tipo_punto_venta != ""
    ) {
        let sin_permisos = true;
        if (localStorage.getItem("sucursal")) {
            if (usuario.bodega) {
                sin_permisos = false;
                return <Redirect to="/bodega" />;
            } else if (usuario.bodega == undefined) {
                sin_permisos = false;
            }

            if (usuario.despacho_despachos) {
                sin_permisos = false;
                return <Redirect to="/despachos" />;
            } else if (usuario.despacho_despachos == undefined) {
                sin_permisos = false;
            }

            if (usuario.despacho_recepciones) {
                sin_permisos = false;
                return <Redirect to="/recepciones_de_traslados" />;
            } else if (usuario.despacho_recepciones == undefined) {
                sin_permisos = false;
            }
            if (usuario.despacho_recepciones_orden_compra) {
                sin_permisos = false;
                return <Redirect to="/recepciones_de_ordenes_de_compra" />;
            } else if (usuario.despacho_recepciones_orden_compra == undefined) {
                sin_permisos = false;
            }
            if (usuario.orden_compra) {
                sin_permisos = false;
                return <Redirect to="/ordenes_de_compra" />;
            } else if (usuario.orden_compra == undefined) {
                sin_permisos = false;
            }
            if (usuario.orden_online) {
                sin_permisos = false;
                return <Redirect to="/ordenes_online" />;
            } else if (usuario.orden_online == undefined) {
                sin_permisos = false;
            }

            if (tipo_punto_venta == PVN) {
                if (usuario.punto_venta) {
                    sin_permisos = false;
                    return <Redirect to="/punto_de_venta" />;
                } else if (usuario.punto_venta == undefined) {
                    sin_permisos = false;
                }
            }
            if (tipo_punto_venta == PVMCE) {
                if (usuario.punto_venta_mostrador_caja) {
                    if (usuario.punto_venta_mostrador) {
                        sin_permisos = false;
                        return <Redirect to="/punto_de_venta/mostrador" />;
                    } else if (usuario.punto_venta_mostrador == undefined) {
                        sin_permisos = false;
                    }
                    if (usuario.punto_venta_caja) {
                        sin_permisos = false;
                        return <Redirect to="/punto_de_venta/caja" />;
                    } else if (usuario.punto_venta_caja == undefined) {
                        sin_permisos = false;
                    }
                }
            }
            if (usuario.producto) {
                sin_permisos = false;
                return <Redirect to="/productos" />;
            } else if (usuario.producto == undefined) {
                sin_permisos = false;
            }
            if (usuario.gasto) {
                sin_permisos = false;
                return <Redirect to="/gastos" />;
            } else if (usuario.gasto == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_balance_general) {
                sin_permisos = false;
                return <Redirect to="/reporte_balance_general" />;
            } else if (usuario.reporte_balance_general == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_apertura_cierre) {
                sin_permisos = false;
                return <Redirect to="/reporte_apertura_cierre_caja" />;
            } else if (usuario.reporte_apertura_cierre == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_bodega) {
                sin_permisos = false;
                return <Redirect to="/reporte_bodega" />;
            } else if (usuario.reporte_bodega == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_venta) {
                sin_permisos = false;
                return <Redirect to="/reporte_ventas" />;
            } else if (usuario.reporte_venta == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_devolucion) {
                sin_permisos = false;
                return <Redirect to="/reporte_devoluciones" />;
            } else if (usuario.reporte_devolucion == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_cuenta_cobrar) {
                sin_permisos = false;
                return <Redirect to="/reporte_cuenta_por_cobrar" />;
            } else if (usuario.reporte_cuenta_cobrar == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_gasto) {
                sin_permisos = false;
                return <Redirect to="/reporte_gastos" />;
            } else if (usuario.reporte_gasto == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_productos_mas_vendidos) {
                sin_permisos = false;
                return <Redirect to="/reporte_productos_mas_vendidos" />;
            } else if (usuario.reporte_productos_mas_vendidos == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_mejores_clientes) {
                sin_permisos = false;
                return <Redirect to="/reporte_mejores_clientes" />;
            } else if (usuario.reporte_mejores_clientes == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_descuentos_recargos) {
                sin_permisos = false;
                return <Redirect to="/reporte_descuentos_y_recargos" />;
            } else if (usuario.reporte_descuentos_recargos == undefined) {
                sin_permisos = false;
            }
            if (usuario.reporte_ganancia_bruta) {
                sin_permisos = false;
                return <Redirect to="/reporte_ganancia_bruta" />;
            } else if (usuario.reporte_ganancia_bruta == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_tienda) {
                sin_permisos = false;
                return <Redirect to="/configuracion/general" />;
            } else if (usuario.conf_tienda == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_usuario) {
                sin_permisos = false;
                return <Redirect to="/configuracion/usuarios" />;
            } else if (usuario.conf_usuario == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_sucursal) {
                sin_permisos = false;
                return <Redirect to="/configuracion/sucursales" />;
            } else if (usuario.conf_sucursal == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_cliente) {
                sin_permisos = false;
                return <Redirect to="/configuracion/clientes" />;
            } else if (usuario.conf_cliente == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_proveedor) {
                sin_permisos = false;
                return <Redirect to="/configuracion/proveedores" />;
            } else if (usuario.conf_proveedor == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_caja) {
                sin_permisos = false;
                return <Redirect to="/configuracion/cajas" />;
            } else if (usuario.conf_caja == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_destino_salida) {
                sin_permisos = false;
                return (
                    <Redirect to="/configuracion/destinos_salida_de_productos" />
                );
            } else if (usuario.conf_destino_salida == undefined) {
                sin_permisos = false;
            }
            if (usuario.conf_adicionales_nota_fel) {
                sin_permisos = false;
                return <Redirect to="/configuracion/adicionales_nota_fel" />;
            } else if (usuario.conf_adicionales_nota_fel == undefined) {
                sin_permisos = false;
            }
            if (usuario.bitacora) {
                sin_permisos = false;
                return <Redirect to="/bitacora" />;
            } else if (usuario.bitacora == undefined) {
                sin_permisos = false;
            }

            if (sin_permisos) {
                return <Redirect to="/sin_permisos" />;
            }
        }
    }

    return (
        <LoadMask loading={loader} light blur radius>
            <div className="mt-5">
                <TituloBorde contenido="Cumpleañeros" />

                <div className="birthday-container">
                    {existen_cumpleneros &&
                        data.map((_usuario, index) => {
                            // const birthday = new Date(_usuario.birthday);
                            const birthday = moment(
                                _usuario.birthday,
                                "YYYY-MM-DD"
                            ).toDate();
                            const today = new Date();

                            const dia = birthday.getDate();
                            const mes = birthday.getMonth();

                            const fecha = new Date(
                                `${mes + 1}/${dia}/${today.getFullYear()}`
                            );

                            const numero_dia = fecha.getDay();
                            const dia_actual = today.getDate();
                            const mes_actual = today.getMonth();

                            const birthday_today =
                                dia == dia_actual && mes == mes_actual;

                            return (
                                <div
                                    className={
                                        birthday_today
                                            ? "card-birthday-today"
                                            : "card-birthday"
                                    }
                                    key={index}
                                >
                                    <div className="card-birthday-header">
                                        <img
                                            src={bunting}
                                            className="bunting"
                                            alt=""
                                            srcset=""
                                        />
                                        {_usuario.foto_cropped ? (
                                            <img
                                                className="birthday-img"
                                                src={_usuario.foto_cropped}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                className="birthday-img"
                                                src={defaultPeril}
                                                alt=""
                                            />
                                        )}
                                        <h5 className="card-birthday-title">
                                            {`${_usuario.first_name} ${_usuario.last_name}`}
                                        </h5>
                                        {birthday_today && (
                                            <img
                                                src={CakeIcon}
                                                className="birthday-icon"
                                                alt=""
                                                srcset=""
                                            />
                                        )}
                                    </div>
                                    <div className="card-birthday-body">
                                        <p>{`${dias[numero_dia]} ${dia}`}</p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {!existen_cumpleneros && (
                    <div className="text-center" style={{ color: "gray" }}>
                        <img
                            src={Cake}
                            className="mb-3 m-0 m-md-3"
                            width="200"
                            style={{ opacity: 0.7 }}
                        />
                        <h1>No hay cumpleañeros</h1>
                    </div>
                )}
            </div>
        </LoadMask>
    );
};

export default Dashboard;
