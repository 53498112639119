import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/notas_credito_prov";
import ListadoNotasCreditoProv from "./ListadoNotasCreditoProv";

const ms2p = (state) => {
    return {
        ...state.reporte_nc_prov,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoNotasCreditoProv);
