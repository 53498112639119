import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { handleActions } from "redux-actions";
import { ToastStore } from "react-toasts";

const endpoint = "movimientos";

export const DATA = "R_CAJA_DATA";
export const LOADER = "R_CAJA_LOADER";
export const SORT = "R_CAJA_SORT";
export const PAGE = "R_CAJA_PAGE";
export const ESTADO_DESCARGA = "R_CAJA_ESTADO_DESCARGA";

export const FECHA_FINAL = "R_CAJA_FECHA_FINAL";
export const FECHA_INICIAL = "R_CAJA_FECHA_INICIAL";
export const SUCURSAL = "R_CAJA_SUCURSAL";
export const TOTALES = "R_CAJA_TOTALES";
export const VENDEDOR = "R_CAJA_VENDEDOR";

// ------------------------------------
// Actions
// ------------------------------------

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();

        const {
            ordering,
            fecha_vencimiento_inicial,
            fecha_vencimiento_final,

            sucursal,
            vendedor,
        } = store.reporte_caja;

        let params = {
            ordering,
            page,
            fecha_inicial: fecha_vencimiento_inicial,
            fecha_final: fecha_vencimiento_final,

            param_sucursal: sucursal,
            vendedor,
        };
        api.get(`${endpoint}/reporte_caja`, params)
            .catch((err) => {
                Swal("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setTotales(resp.extra_data));
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const setTotales = (value) => (dispatch) => {
    dispatch(_setTotales(value));
    // dispatch(listar());
};

export const setSucursal = (value) => (dispatch) => {
    dispatch(_setSucursal(value));
    // dispatch(listar());
};

export const setVendedor = (value) => (dispatch) => {
    dispatch(_setVendedor(value));
    // dispatch(listar());
};

export const aplicarFiltros = () => (dispatch, getStore) => {
    const store = getStore();

    const { sucursal, vendedor } = store.reporte_caja;

    console.log("sucursal", sucursal);
    console.log("vendedor", vendedor);

    if (!sucursal || !vendedor) {
        ToastStore.error("Debe seleccionar sucursal y vendedor");
    } else {
        dispatch(listar());
    }
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_caja = store.reporte_caja;
    if (key === "Inicial") {
        const fecha_vencimiento_inicial = new Date(value);
        const fecha_vencimiento_final = new Date(
            reporte_caja.fecha_vencimiento_final
        );
        // if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_vencimiento_inicial = new Date(
            reporte_caja.fecha_vencimiento_inicial
        );
        const fecha_vencimiento_final = new Date(value);
        if (fecha_vencimiento_final >= fecha_vencimiento_inicial)
            dispatch(setFechaFinal(value));
    }
    // dispatch(listar());
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        ordering,
        fecha_vencimiento_inicial,
        fecha_vencimiento_final,

        sucursal,
        vendedor,
    } = store.reporte_caja;

    let params = {
        ordering,
        fecha_inicial: fecha_vencimiento_inicial,
        fecha_final: fecha_vencimiento_final,

        param_sucursal: sucursal,
        vendedor,
    };

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_reporte_caja`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const limpiarFiltros = () => (dispatch) => {
    dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
    dispatch(
        setTotales({
            total_entradas: 0,
            total_salidas: 0,
            saldo_total: 0,
            total_devolucion_venta_credito: 0,
        })
    );

    dispatch(setVendedor(""));
    dispatch(setSucursal(""));
    dispatch(setData({}));
};
// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setFechaInicial = (fecha_vencimiento_inicial) => ({
    type: FECHA_INICIAL,
    fecha_vencimiento_inicial,
});

export const setFechaFinal = (fecha_vencimiento_final) => ({
    type: FECHA_FINAL,
    fecha_vencimiento_final,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setTotales = (totales) => ({
    type: TOTALES,
    totales,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const _setVendedor = (vendedor) => ({
    type: VENDEDOR,
    vendedor,
});

export const actions = {
    listar,
    setTotales,
    sortChange,
    setFecha,

    setSucursal,
    aplicarFiltros,
    setVendedor,

    descargarListado,
    limpiarFiltros,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_vencimiento_inicial }) => {
        return {
            ...state,
            fecha_vencimiento_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_vencimiento_final }) => {
        return {
            ...state,
            fecha_vencimiento_final,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },

    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales,
        };
    },

    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [VENDEDOR]: (state, { vendedor }) => {
        return {
            ...state,
            vendedor,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    page: 1,
    ordering: "",
    loader: false,
    fecha_vencimiento_inicial: moment().format("YYYY-MM-DD"),
    fecha_vencimiento_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,

    totales: {
        total_entradas: 0,
        total_salidas: 0,
        saldo_total: 0,
        total_devolucion_venta_credito: 0,
    },
    sucursal: "",
    vendedor: "",
};

export default handleActions(reducers, initialState);
