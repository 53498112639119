import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import MarcarEntregadoForm from "./MarcarEntregadoForm";
import MarcarEnviadoForm from "./MarcarEnviadoForm";
// import classNames from "classnames";

const MarcarEnviadoModal = ({
    open,
    es_proceso,
    es_enviado,
    onClose,
    marcarEnviado,
    detalle,
}) => {
    const metodo_envio =
        detalle && detalle.metodo_envio ? detalle.metodo_envio : "---";
    const es_recoger_en_tienda = metodo_envio === "retirar_tienda";

    const handleOnSubmit = (values) => {
        values.es_recoger_en_tienda = es_recoger_en_tienda;
        marcarEnviado(values, es_proceso, onClose);
    };

    console.log("detalle", detalle);

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                classNames={{ modal: "modal-md" }}
                center
            >
                <div
                    style={{ minWidth: "25vw", minHeight: "25vh" }}
                    className="p-4"
                >
                    {/* <h2 className="mb-4 font-weight-bolder">
                        Cambiar datos del Cliente
                    </h2> */}

                    {/* //*** FORM */}
                    {es_proceso ? (
                        <MarcarEnviadoForm
                            onClose={onClose}
                            onSubmit={handleOnSubmit}
                            es_recoger_en_tienda={es_recoger_en_tienda}
                        />
                    ) : (
                        <MarcarEntregadoForm
                            onClose={onClose}
                            onSubmit={handleOnSubmit}
                        />
                    )}
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default MarcarEnviadoModal;
