import React from "react";

import { Form, Field } from "react-final-form";
import { required } from "../../../../../utility/validation";
import { renderField, renderDatePicker } from "Utils/renderField/renderField";

const CambiarCantidadForm = ({ onClose, onSubmit, selectedRow }) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} className="form-validate ">
                        <div className="">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="cantidad">Cantidad</label>
                                <Field
                                    name="cantidad"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    placeholder="Cantidad"
                                    validate={required}
                                />
                            </div>

                            <div className="d-flex flex-md-row justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary mr-3"
                                    disabled={submitting}
                                    onClick={onClose}
                                >
                                    Volver
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default CambiarCantidadForm;
