import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { AsyncSelect, Async } from "react-select";
// import AsyncSelect from 'react-select/async';
import { api } from "api";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment/moment";
import { default as GraficaMovimiento } from "../Grafica";
import FiltrosReportes from "../../../Utils/FiltrosReportes";

const DetalleVenta = (props) => {
    const [fraccion, setFraccion] = useState(null);

    const {
        aplicarFiltros,
        loader,
        sucursal,
        producto,
        data,
        fecha_inicial,
        fecha_final,
        estado_descarga,
        usuario,
        descargarListado,
        sucursal_param,
    } = props;

    const es_global = usuario.es_global; // Si el usuario tiene acceso a todas las sucursales

    const onChangeSucursal = (sucursal) => {
        props.setSucursal(sucursal);
    };

    const onChangeProducto = (producto) => {
        props.setProducto(producto);
    };

    useEffect(() => {
        if (producto) {
            console.log("producto", producto);
            const fraccion = producto.fracciones[0];
            props.setFraccion(fraccion);
            setFraccion(fraccion);
        }
    }, [producto]);

    useEffect(() => {
        if (usuario && usuario.sucursales && !es_global) {
            props.setSucursal(usuario.sucursales[0]);
        }
    }, [usuario]);

    const options = _.cloneDeep(tableOptions);

    //  Bind
    const getSucursales = (search) => {
        return api
            .get("sucursales", { search })
            .catch(() => {})
            .then((data) => {
                return { options: data.results };
            });
    };

    const getProductos = (search) => {
        return api
            .get("productos", { search, kardex: true })
            .catch(() => {})
            .then((data) => {
                return { options: data.results };
            });
    };

    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.listar;
    options.onSortChange = props.sortChange;

    return (
        <div>
            <div className="d-flex flex-column grid-container px-5 pt-4 pb-2 mb-5">
                <h1 className="title-text border-bottom">Filtros</h1>

                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                    <div
                        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                        style={{ flex: "3" }}
                    >
                        {" "}
                        &nbsp;
                        <div style={{ flex: "0.2" }}>
                            <label>Fecha Inicio</label>
                            <DatePicker
                                value={fecha_inicial}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    props.setFecha("Inicial", date);
                                }}
                            />
                        </div>
                        &nbsp; &nbsp;
                        <div style={{ flex: "0.2" }}>
                            <label>Fecha Fin</label>
                            <DatePicker
                                value={fecha_final}
                                minDate={
                                    new Date(
                                        moment(fecha_inicial, "YYYY-MM-DD")
                                    )
                                }
                                onChange={(date) => {
                                    props.setFecha("Final", date);
                                }}
                            />
                        </div>
                        &nbsp; &nbsp;
                        <div style={{ flex: "0.2" }}>
                            <label>Sucursal</label>
                            <Async
                                className="form-control"
                                placeholder="Seleccionar sucursal"
                                onChange={onChangeSucursal}
                                searchPromptText="Escriba para buscar"
                                valueKey={"id"}
                                labelKey={"nombre"}
                                loadOptions={getSucursales}
                                disabled={!es_global}
                                value={
                                    es_global ? props.sucursal : sucursal_param
                                }
                            />
                        </div>
                        &nbsp; &nbsp;
                        <div style={{ flex: "0.2" }}>
                            <label>codigo</label>
                            <Async
                                className="form-control"
                                labelKey="codigo_nombre"
                                searchPromptText="Escriba para buscar"
                                placeholder={"Seleccionar producto"}
                                loadOptions={getProductos}
                                onChange={onChangeProducto}
                                value={props.producto}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8"></div>
                    <div className="flex-1"></div>

                    <div className="flex-8 d-flex justify-content-end">
                        <button
                            className="btn btn-primary px-4"
                            // disabled={estado_descarga}
                            onClick={aplicarFiltros}
                        >
                            <em className="fa fa-filter"></em>
                            &nbsp;Filtrar
                        </button>
                    </div>
                    <div className="flex-1"></div>
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-12">
                    <div className="grid-container">
                        <div className="mb-1 mt-1">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div className="d-flex flex-column flex-sm-row justify-content-between">
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-3 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Código del producto:</b>
                                            {fraccion && producto
                                                ? producto.codigo
                                                : "---"}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                        <b>Nombre del producto:</b>
                                        {producto ? producto.nombre : "---"}
                                    </div>
                                    <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                        <b>Sucursal seleccionada:</b>
                                        {producto && sucursal && sucursal.nombre
                                            ? sucursal.nombre
                                            : "---"}
                                    </div>
                                </div>
                            </div>
                            <GraficaMovimiento />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="grid-container px-4">
                    <div className="d-flex justify-content-between border-bottom mb-3">
                        <h1 className="title-text">Reporte</h1>

                        <button
                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                            disabled={estado_descarga}
                            onClick={() => {
                                descargarListado();
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-download"
                                }`}
                            ></em>
                            &nbsp;Descargar Listado
                        </button>
                    </div>
                    <LoadMask loading={loader} light blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                        >
                            <TableHeaderColumn
                                dataField="creado"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row) => (
                                    <div>{row.creado}</div>
                                )}
                                dataSort
                            >
                                Fecha movimiento
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="codigo_producto"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataSort
                            >
                                Cód. Producto
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="fraccion__producto__nombre"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataSort
                            >
                                Artículo
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="documento_entrada"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataSort
                            >
                                Doc. Entrada
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="documento_salida"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataSort
                            >
                                Doc. Salida
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="descripcion_movimiento"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                // dataFormat={(cell, row) => (
                                //     <div>
                                //         {row.movimiento.concepto || "---"}
                                //     </div>
                                // )}
                                dataSort
                            >
                                Descripción
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cliente_proveedor"
                                tdStyle={BreakLine}
                                isKey
                                thStyle={BreakLine}
                                dataFormat={(cell, row) => {
                                    if (cell) {
                                        return cell;
                                    }

                                    if (row.proveedor) {
                                        return row.proveedor;
                                    }

                                    return "---";
                                }}
                                dataSort
                            >
                                Proveedor/cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="precio_costo"
                                dataFormat={(cell, row) => {
                                    return <RenderMoneda monto={cell} />;
                                }}
                                dataSort
                            >
                                Precio/Costo
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="entrada" dataSort>
                                Entrada
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="salida" dataSort>
                                Salida
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="existencia_final"
                                dataFormat={(cell, row) => (
                                    <div>{row.existencia_final}</div>
                                )}
                                dataAlign="right"
                            >
                                Saldo
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export default DetalleVenta;
