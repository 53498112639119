import { connect } from "react-redux";
import DetailProduct from "./detailProduct";

import { actions } from "../../../../../redux/modules/buscador_producto/buscador_producto";

const ms2p = (state) => ({
    ...state.buscador_producto,
    data: state.buscador_producto.data,
    estado_descarga: state.buscador_producto.estado_descarga,
});

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(DetailProduct);
