import React, { Component, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ClientesForm from "./ClientesForm";
import LoadMask from "Utils/LoadMask/LoadMask";

const CrearActualizar = ({
    crear,
    leer,
    item,
    editar,
    loader,
    crearCliente,
    removerItem,
    match,
}) => {
    const location = useLocation();
    const { id } = useParams();

    // true, si la url contiene ver
    const verDato = location.pathname.includes("ver");

    // true, si la url contiene editar
    const editarDato = location.pathname.includes("editar");

    //Direcciones
    const [direcciones, setDirecciones] = useState({
        contactos: [],
        id_contactos_eliminar: [],
        nuevos_contactos: [],
    });

    const [creditos, setCreditos] = useState({
        credito: [],
        id_credito_eliminar: [],
        nuevos_credito: [],
    });

    //Files
    const [files, setFiles] = useState([]);
    const [oldFiles, setOldFiles] = useState([]);
    const [removeFiles, setRemoveFiles] = useState([]);

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        // Si es actualizar, retornar editar para que crear no se ejecute

        let data_files = [...files];

        if (body.usuario) body.usuario = body.usuario.id;

        // *** Datos de contactos, al editar o agregar un proveedor
        // Si el array de contactos, no esta vacio, agregar los contactos al body
        if (direcciones.contactos.length > 0)
            body.direcciones = direcciones.contactos;

        // Si el array de eliminar, no esta vacio, agregar eliminar al body
        if (direcciones.id_contactos_eliminar.length > 0)
            body.eliminar = direcciones.id_contactos_eliminar;

        // Si el array de nuevo, no esta vacio, agregar nuevo al body
        if (direcciones.nuevos_contactos.length > 0)
            body.nuevo = direcciones.nuevos_contactos;

        if (creditos.credito.length > 0) body.creditos = creditos.credito;

        // Si el array de eliminar, no esta vacio, agregar eliminar al body
        if (creditos.id_credito_eliminar.length > 0)
            body.eliminarC = creditos.id_credito_eliminar;

        // Si el array de nuevo, no esta vacio, agregar nuevo al body
        if (creditos.nuevos_credito.length > 0) {
            // value.sucursal_id = sucursal_id.id;
            // console.log("creditos nuevos", creditos.nuevos_credito);
            creditos.nuevos_credito.map((value) => {
                // console.log("value", value);
                value.sucursal_id = value.sucursal_id.id;
            });
            body.nuevoC = creditos.nuevos_credito;
        }

        let new_files = data_files.map((file) => {
            return { name: file.name };
        });

        if (body.tipo_cliente) body.tipo_cliente = parseInt(body.tipo_cliente);

        body.archivos = new_files;
        body.eliminar_archivos = removeFiles;

        if (editarDato) return editar(id, body, data_files);
        else crearCliente(body, data_files);
    };

    useEffect(() => {
        if (id) {
            leer(id);
        }

        return () => {
            removerItem();
        };
    }, []);

    useEffect(() => {
        if (item && item.archivos) {
            setOldFiles(item.archivos);
        }
        if (item && item.direcciones) {
            setDirecciones({
                ...direcciones,
                contactos: [...item.direcciones],
            });
        }
        if (item && item.creditos) {
            setCreditos({
                ...creditos,
                credito: [...item.creditos],
            });
        }
    }, [item]);

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <ClientesForm
                    direcciones={direcciones}
                    setDirecciones={setDirecciones}
                    creditos={creditos}
                    setCreditos={setCreditos}
                    files={files}
                    editar={editarDato}
                    setFiles={setFiles}
                    oldFiles={oldFiles}
                    setOldFiles={setOldFiles}
                    data={item}
                    removeFiles={removeFiles}
                    setRemoveFiles={setRemoveFiles}
                    onSubmit={onSubmit}
                    loader={loader}
                    match={match}
                />
            </LoadMask>
        </React.Fragment>
    );
};

export default CrearActualizar;
