import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./ProtectedRoute";
import { ToastContainer, ToastStore } from "react-toasts";
import { Login, Recuperar, Resetear } from "./common/components/Login";

import Dashboard from "./common/components/Dashboard";

/* CAJAS */
import CajasListado from "./common/components/Cajas/Listado/CajasListadoContainer";
import CajasListadoPaginado from "./common/components/Cajas/ListadoPaginado";
import CajasCrear from "./common/components/Cajas/Crear/CajasCrearContainer";
import CajasEditar from "./common/components/Cajas/Editar/CajasEditarContainer";
import Apertura from "./common/components/Cajas/Apertura/AperturaContainer";
import Cierre from "./common/components/Cajas/Cierre/Cierre/CierreContainer";
import UltimoCierre from "./common/components/Cajas/Cierre/Impresion/ImpresiónContainer";
import Activa from "./common/components/Cajas/Detalle/DetalleCajaContainer";

/* SUCURSALES */
import SucursalesListado from "./common/components/Sucursales/Listado/SucursalesListadoContainer";
import SucursalesEditar from "./common/components/Sucursales/Editar/SucursalesEditarContainer";
import SucursalesDetalle from "./common/components/Sucursales/Detalle/SucursalesDetalleContainer";

/* CONFIGURACION */
import ConfiguracionEditar from "./common/components/Configuracion/Editar/ConfiguracionEditarContainer";
import {
    Listado as ListadoDestinosSalidaProductos,
    Crear as CrearDestinoSalidaProducto,
    Editar as EditarDestinoSalidaProducto,
} from "./common/components/Configuracion/DestinosSalidaProducto";

import {
    Listado as LiastadoAdicionalesNotaFel,
    Create as CreateAdicionalNotaFel,
} from "./common/components/Configuracion/AdicionalesNotaFEL";
/*  PRODUCTOS  */
import ProductoListado from "./common/components/Producto/Listado/ProductoListadoContainer";
import ProductoCrear from "./common/components/Producto/Crear/ProductoCrearContainer";
import ProductoEditar from "./common/components/Producto/Editar/ProductoEditarContainer";
import ProductoDetalle from "./common/components/Producto/Detalle/ProductoDetalleContainer";
import ImprimirCodigoProducto from "./common/components/Producto/ImprimirCodigo";

import "../assets/fonts/fonts.css";

/* BODEGA */
import {
    Listado as BodegaListado,
    Reajuste as BodegaReajuste,
    Bajas as BodegaBajas,
    ReporteIngreso as BodegaReporteIngreso,
    Ingreso as BodegaIngreso,
    ImpresionProductos as BodegaImpresionProducto,
    SalidaProductos as BodegaSalidaProductos,
} from "./common/components/Bodega";

import ListadoMovimientos from "./common/components/ListadoMovimientos";

/* DEVOLUCIONES */
import {
    CodigoFactura,
    Eleccion as EleccionProductosDevolucion,
    Motivos as MotivosDevolucion,
    VistaPrevia,
    Vale,
} from "./common/components/Devoluciones";

/* BITACORA */
import { DetalleBitacora, ListadoBitacora } from "./common/components/Bitacora";

/* USUARIOS */
import {
    Listado as UsuariosListado,
    Crear as UsuariosCrear,
    Editar as UsuariosEditar,
} from "./common/components/Configuracion/Usuarios";

/* CLIENTES */
import {
    Listado as ClientesListado,
    CrearActualizar as ClientesCrear,
    CrearActualizar as ClientesEditar,
} from "./common/components/Clientes";

/* PROVEEDORES */
import {
    Listado as ProveedoresListado,
    Crear as ProveedoresCrear,
    Editar as ProveedoresEditar,
} from "./common/components/Proveedores";

/* ORDENES ONLINE */
import {
    Listado as ListadoOrdenOnline,
    Detalle as DetalleOrdenOnline,
    Editar as EditarOrdenOnline,
} from "./common/components/OrdenOnline";

/* GASTOS */
import {
    Listado as ListadoGastos,
    Crear as CrearGasto,
    Detalle as DetalleGasto,
} from "./common/components/Gastos";

/* REPORTES */
import ReporteGastos from "./common/components/Reportes/Gastos";
import {
    BalanceGeneral,
    ListadoAperturaCierre,
    ListadoVentas,
    DetalleVenta,
    MejoresClientes,
    ReporteBodega,
    Inversion,
    ResumenReporteBodega,
    ListadoDevoluciones,
    DetalleDevolucion,
    GananciaBruta,
    ListadoReporteDespachos,
    DetalleReporteDespacho,
    HistorialPrecios,
    DetalleMovimientos,
    DetalleMovimientosGeneral,
    HistorialBajasSalidas,
    ListadoReajustes
} from "./common/components/Reportes";
import ResumenReporteGasto from "./common/components/Reportes/Gastos/Resumen";
import ProductosMasVendidos from "./common/components/Reportes/ProductosMasVendidos";

/* PERFIL */
import Perfil from "./common/components/Perfil";

/* CUENTA POR COBRAR */
// import {
//     ListadoCuentaCobrar,
//     ReciboCuentaCobrar,
// } from "./common/components/CuentaCobrar";

import {
    ListadoCuentaCobrar as ReporteCuentaCobrar,
    // DetalleCuentaCobrar,
} from "./common/components/Reportes/CuentaCobrar";

import {
    ListadoReciboCuentaCobrar as ReporteReciboCuentaCobrar,
    // DetalleCuentaCobrar,
} from "./common/components/Reportes/ReciboCuentaCobrar";

import {
    ListadoCuentaCobrar as ReporteCuentaPagar,
    // DetalleCuentaCobrar,
} from "./common/components/Reportes/CuentaPagar";

import {
    ListadoComisiones,
    // DetalleCuentaCobrar,
} from "./common/components/Reportes/Comisiones";

import {
    ListadoComisiones as ListadoComisionesMensual,
    // DetalleCuentaCobrar,
} from "./common/components/Reportes/ComisionVentaMensual";

/* PUNTO DE VENTA */
import PuntoVenta from "./common/components/PuntoVenta";
import PuntoVentaMostrador from "./common/components/PuntoVenta/Mostrador";
import PuntoVentaCaja from "./common/components/PuntoVenta/Caja";

/* DESPACHOS */
import {
    ListadoDespacho,
    CrearDespacho,
    NotaEnvio,
    DetalleDespacho,
    ListadoRecepcionDespacho,
    CrearRecepcionDespacho,
    NotaRecepcionDespacho,
    DetalleRecepcionDespacho,
} from "./common/components/Despacho";

/* COTIZACION */
import { ListadoCotizacion } from "./common/components/Cotizacion";

/* ORDENES DE COMPRA */
import {
    Listado as ListadoOrdenCompra,
    Crear as CrearOrdenCompra,
    TipoPago,
    DetalleOC,
} from "./common/components/OrdenCompra";

// CUENTAS POR PAGAR
import {
    Listado as ListadoCuentaPagar,
    DetalleOC as DetalleCuentaPagar,
    ReciboCuentaPagar,
} from "./common/components/CuentaPagar";

// CUENTAS POR COBRAR
import {
    Listado as ListadoCuentaCobrar,
    DetalleOC as DetalleCuentaCobrar,
    ReciboCuentaCobrar,
} from "./common/components/CuentaCobrar";

import {
    ListadoRecepcionOrdenCompra,
    RecepcionOrdenCompra,
    DetalleRecepcionOrdenCompra,
} from "./common/components/Despacho/RecepcionOrdenCompra";

// ORDENES DE VENTA
import {
    Listado as ListadoOrdenVenta,
    Crear as CrearOrdenVenta,
    Detalle as DetalleOrdenVenta,
    //ListadoEntregaOV,
    //EntregaOV,
    //VisualizacionOV,
} from "./common/components/OrdenVenta";

// Familias
import {
    CrearActualizar as CrearActualizarFamilia,
    Listado as ListarFamilia,
} from "./common/components/Familias";

// Bancos
import Bancos from "./common/components/Bancos/GestionCuentas";
import IngresoDocumentos from "./common/components/Bancos/IngresoDocumentos";
import ReporteDocumentos from "./common/components/Bancos/Reportes";

// Transportes
import Transportes from "./common/components/Transportes";

import { ListadoDescuentoRecargo } from "./common/components/Reportes/DescuentoRecargo";

import PaginaInicio from "./common/components/Login/PaginaInicio";

import Blank from "./common/components/Blank";
import { ListadoReciboCuentaPagar } from "./common/components/Reportes/ReciboCuentaPagar";
import { ListadoArticulosInventario } from "./common/components/Reportes/ArticulosInventario";
import { ListadoNotasCreditoProv } from "./common/components/Reportes/NotasCreditoProveedor";
import { ListadoNotasCreditoCliente } from "./common/components/Reportes/NotasCreditoCliente";
import { ListadoArticulosVendidos } from "./common/components/Reportes/ArticulosVendidos";
import { ListadoArticulosVenta } from "./common/components/Reportes/ArticulosVenta";
import { ListadoEntradas } from "./common/components/Reportes/Entradas";
import { ListadoSalidas } from "./common/components/Reportes/Salidas";
import { ListadoCaja } from "./common/components/Reportes/Caja";
import {ListadoArticulosVendidosPorFamilia} from "./common/components/Reportes/VentasPorFamilia"

module.exports = (
    <div className="container__content">
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
                noBreadcrumbs={true}
                path="/sin_permisos"
                component={Blank}
            />
            <PrivateRoute
                noBreadcrumbs={true}
                path="/dashboard"
                component={Dashboard}
            />
            {/* ORDEN DE VENTA */}
            <PrivateRoute
                exact
                path="/ordenes_de_venta"
                component={ListadoOrdenVenta}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_venta/nueva"
                component={CrearOrdenVenta}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_venta/editar/:id"
                component={CrearOrdenVenta}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_venta/detalle/:id"
                component={DetalleOrdenVenta}
            />
            {/* FAMILIA */}
            <PrivateRoute exact path="/familias" component={ListarFamilia} />
            <PrivateRoute
                exact
                path="/familias/crear"
                component={CrearActualizarFamilia}
            />
            <PrivateRoute
                exact
                path="/familias/:id/editar"
                component={CrearActualizarFamilia}
            />
            {/* CAJAS */}
            {/* <PrivateRoute exact path="/abrir_caja" component={CajasListado} /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/cajas"
                component={CajasListadoPaginado}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/cajas/crear"
                component={CajasCrear}
            /> */}
            {/* <PrivateRoute
                exact
                path="/cajas/detalle_cierre/:id"
                component={UltimoCierre}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/cajas/editar/:id"
                component={CajasEditar}
            /> */}
            {/* <PrivateRoute
                exact
                path="/cajas/apertura/:id"
                component={Apertura}
            /> */}
            {/* <PrivateRoute exact path="/caja_activa/" component={Activa} /> */}
            {/* <PrivateRoute exact path="/caja_activa/cierre" component={Cierre} /> */}
            {/* CLIENTES */}
            {/* <PrivateRoute
                exact
                path="/ventas"
                component={CrearCliente}
            /> */}
            {/* GASTOS */}
            {/* <PrivateRoute exact path="/gastos" component={ListadoGastos} /> */}
            {/* <PrivateRoute
                exact
                path="/gastos/registrar"
                component={CrearGasto}
            /> */}
            {/* <PrivateRoute
                exact
                path="/gastos/detalle/:id"
                component={DetalleGasto}
            /> */}
            {/* VENTAS */}
            {/* <PrivateRoute
                exact
                path="/ventas/"
                component={Venta}
            /> */}
            {/* CUENTAS POR COBRAR */}
            {/* <PrivateRoute
                exact
                path="/cuentas_por_cobrar"
                component={ListadoCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar/detalle/:id"
                component={ReciboCuentaCobrar}
            /> */}
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar"
                component={ListadoCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar/:id"
                component={DetalleCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar/:id/ver_documento_pago"
                component={ReciboCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar/documento/:id/"
                component={ReciboCuentaCobrar}
            />
            {/* DEVOLUCIONES */}
            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente"
                component={CodigoFactura}
            />
            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente/seleccion_de_producto/:id"
                component={EleccionProductosDevolucion}
            />
            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente/seleccion_de_producto/:id/motivos"
                component={MotivosDevolucion}
            />
            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente/seleccion_de_producto/:id/motivos/vale"
                component={VistaPrevia}
            />
            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente/seleccion_de_producto/:id/motivos/vale/:id_devolucion"
                component={Vale}
            />

            <PrivateRoute
                exact
                path="/bodega/nota_credito_cliente/:id/vale/:id_devolucion"
                component={Vale}
            />
            <PrivateRoute
                exact
                path="/bodega/nota_credito/:id/:id_devolucion"
                component={Vale}
            />
            {/* BODEGAS*/}
            <PrivateRoute exact path="/bodega" component={BodegaListado} />
            {/* <PrivateRoute
                exact
                path="/bodega/ingreso_de_mercaderia"
                component={BodegaIngreso}
            /> */}
            <PrivateRoute
                exact
                path="/bodega/resumen_de_ingreso/:id"
                component={BodegaReporteIngreso}
            />
            <PrivateRoute
                exact
                path="/bodega/reajuste"
                component={BodegaReajuste}
            />
            <PrivateRoute
                exact
                path="/bodega/devolucion_compra"
                component={BodegaBajas}
            />
            <PrivateRoute
                exact
                path="/bodega/detalle_orden/:id"
                component={DetalleOC}
            />
            {/* <PrivateRoute
                exact
                path="/bodega/salida_de_producto"
                component={BodegaSalidaProductos}
            /> */}
            {/* <PrivateRoute
                exact
                path="/bodega/impresion_productos"
                component={BodegaImpresionProducto}
            /> */}
            <PrivateRoute
                exact
                path="/bodega/listado_movimientos"
                component={ListadoMovimientos.Listar}
            />
            {/*SUCURSALES*/}
            <PrivateRoute
                exact
                path="/configuracion/sucursales"
                component={SucursalesListado}
            />
            <PrivateRoute
                exact
                path="/sucursales/editar/:id"
                component={SucursalesEditar}
            />
            <PrivateRoute
                exact
                path="/sucursales/detalle/:id"
                component={SucursalesDetalle}
            />
            {/*CONFIGURACION*/}
            {/* <PrivateRoute
                exact
                path="/configuracion/general"
                component={ConfiguracionEditar}
            /> */}
            {/* DESTINOS SALIDA PRODUCTOS */}
            {/* <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos"
                component={ListadoDestinosSalidaProductos}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos/crear"
                component={CrearDestinoSalidaProducto}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos/editar/:id"
                component={EditarDestinoSalidaProducto}
            /> */}
            {/* ADICIONALES NOTA FEL */}
            {/* <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel"
                component={LiastadoAdicionalesNotaFel}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel/crear"
                component={CreateAdicionalNotaFel}
            /> */}
            {/* <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel/editar/:id"
                component={CreateAdicionalNotaFel}
            /> */}
            {/* USUARIOS */}
            <PrivateRoute
                exact
                path="/configuracion/usuarios"
                component={UsuariosListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/usuarios/crear"
                component={UsuariosCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/usuarios/editar/:id"
                component={UsuariosEditar}
            />
            {/* <PrivateRoute
                exact
                path="/configuracion/usuarios/detalle/:id"
                component={SucursalesDetalle}
            /> */}
            {/* BITACORA */}
            <PrivateRoute exact path="/bitacora" component={ListadoBitacora} />
            <PrivateRoute
                exact
                path="/bitacora/detalle/:id"
                component={DetalleBitacora}
            />
            {/* CLIENTES */}
            <PrivateRoute
                exact
                path="/configuracion/clientes"
                component={ClientesListado}
            />
            {/*<DireccionesProvider>*/}
            <PrivateRoute
                exact
                path="/configuracion/clientes/crear"
                component={ClientesCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/clientes/editar/:id"
                component={ClientesEditar}
            />
            {/* PROVEEDORES */}
            <PrivateRoute
                exact
                path="/configuracion/proveedores"
                component={ProveedoresListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/proveedores/crear"
                component={ProveedoresCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/proveedores/editar/:id"
                component={ProveedoresEditar}
            />
            {/* PRODUCTOS */}
            <PrivateRoute exact path="/productos" component={ProductoListado} />
            <PrivateRoute
                exact
                path="/productos/crear"
                component={ProductoCrear}
            />
            <PrivateRoute
                exact
                path="/productos/editar/:id"
                component={ProductoEditar}
            />
            <PrivateRoute
                exact
                path="/productos/detalle/:id"
                component={ProductoDetalle}
            />
            <PrivateRoute
                exact
                path="/busqueda_general/detalle/:id"
                component={ProductoDetalle}
            />
            {/* <PrivateRoute
                exact
                path="/productos/imprimir_codigos/:id"
                component={ImprimirCodigoProducto}
            /> */}
            {/* ---Ver Documento */}
            {/* <PrivateRoute
                exact
                path="/productos/documento/:id"
                component={BodegaReporteIngreso}
            /> */}
            {/* REPORTES */}
            {/* <PrivateRoute
                exact
                path="/reporte_balance_general"
                component={BalanceGeneral}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_gastos"
                component={ReporteGastos}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_devoluciones"
                component={ListadoDevoluciones}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_devoluciones/detalle/:id"
                component={DetalleDevolucion}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_gastos/:id"
                component={ResumenReporteGasto}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_ventas"
                component={ListadoVentas}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_ventas/:id"
                component={DetalleVenta}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_apertura_cierre_caja"
                component={ListadoAperturaCierre}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_apertura_cierre_caja/:id"
                component={UltimoCierre}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_productos_mas_vendidos"
                component={ProductosMasVendidos}
            /> */}
            {/* <PrivateRoute
                exact
                path="/inversion_en_bodega"
                component={Inversion}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_mejores_clientes"
                component={MejoresClientes}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_bodega"
                component={ReporteBodega}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_bodega/:id"
                component={ResumenReporteBodega}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_ganancia_bruta"
                component={GananciaBruta}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_de_despachos"
                component={ListadoReporteDespachos}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_de_despachos/visualizacion/:id"
                component={DetalleReporteDespacho}
            /> */}
            {/* <PrivateRoute
                exact
                path="/reporte_historial_precios"
                component={HistorialPrecios}
            ></PrivateRoute> */}
            <PrivateRoute
                path="/reporte_kardex"
                component={DetalleMovimientos}
            />
            {/* <PrivateRoute
                path="/historial_general_de_inventario"
                component={DetalleMovimientosGeneral}
            /> */}
            {/* <PrivateRoute
                path="/reporte_historial_precios"
                component={HistorialPrecios}
            ></PrivateRoute> */}
            {/* <PrivateRoute
                path="/bajas_y_salidas_por_productos"
                component={HistorialBajasSalidas}
            ></PrivateRoute> */}
            {/* <PrivateRoute
                exact
                path="/reporte_descuentos_y_recargos"
                component={ListadoDescuentoRecargo}
            ></PrivateRoute> */}
            {/* <PrivateRoute
                exact
                path="/reporte_descuentos_y_recargos/:id"
                component={DetalleVenta}
            /> */}
            {/* PERFIL */}
            <PrivateRoute exact path="/perfil" component={Perfil} />
            {/* CUENTA POR COBRAR */}
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_cobrar"
                component={ReporteCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_cobrar/:id"
                component={DetalleCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_cobrar/:id/ver_documento_pago"
                component={ReciboCuentaCobrar}
            />
            {/* CUENTA POR PAGAR */}
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_pagar"
                component={ReporteCuentaPagar}
            />
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_pagar/:id"
                component={DetalleOC}
            />
            {/* REPORTE COMISIONES DE VENTA */}
            <PrivateRoute
                exact
                path="/reporte_comisiones"
                component={ListadoComisiones}
            />

            {/* REPORTE COMISIONES DE VENTA MENSUAL */}
            <PrivateRoute
                exact
                path="/comision_mensual"
                component={ListadoComisionesMensual}
            />

            {/* REPORTE DE RECIBOS DE CUENTAS POR COBRAR */}
            {/* CUENTA POR COBRAR */}
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_cobrar"
                component={ReporteReciboCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_cobrar/:id"
                component={DetalleCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_cobrar/documento/:id"
                component={ReciboCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_cobrar/documento_pago_multiple/:id"
                component={ReciboCuentaCobrar}
            />
            {/* REPORTE RECIBO CUENTA POR PAGAR */}
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_pagar"
                component={ListadoReciboCuentaPagar}
            />
            <PrivateRoute
                exact
                path="/reporte_recibos_cuenta_por_pagar/documento/:id"
                component={ReciboCuentaPagar}
            />
            {/* REPORTE NOTAS CREDITO PROVEEDOR */}
            <PrivateRoute
                exact
                path="/notas_de_credito_al_proveedor"
                component={ListadoNotasCreditoProv}
            />
            <PrivateRoute
                exact
                path="/notas_de_credito_al_proveedor/documento/:id"
                component={BodegaReporteIngreso}
            />
            {/* REPORTE NOTAS CREDITO CLIENTE */}
            <PrivateRoute
                exact
                path="/notas_de_credito_al_cliente"
                component={ListadoNotasCreditoCliente}
            />
            <PrivateRoute
                exact
                path="/notas_de_credito_al_cliente/documento/:id/:id_devolucion"
                component={Vale}
            />

            {/* REPORTE ARTICULOS DE INVENTARIO */}
            <PrivateRoute
                exact
                path="/reporte_articulos_inventario"
                component={ListadoArticulosInventario}
            />

            {/* REPORTE ARTICULOS VENDIDOS */}
            <PrivateRoute
                exact
                path="/articulos_vendidos"
                component={ListadoArticulosVendidos}
            />

            {/* REPORTE ARTICULOS DE VENTA */}
            <PrivateRoute
                exact
                path="/articulos_de_venta"
                component={ListadoArticulosVenta}
            />

            {/* REPORTE VENTAS POR FAMILIA */}
            <PrivateRoute
                exact
                path="/reporte_ventas_por_familia"
                component={ListadoArticulosVendidosPorFamilia}
            />

            {/* REPORTE DE ENTRADAS */}
            <PrivateRoute exact path="/entradas" component={ListadoEntradas} />

            {/* REPORTE DE SALIDAS */}
            <PrivateRoute exact path="/salidas" component={ListadoSalidas} />

            {/* REPORTE DE CAJA */}
            <PrivateRoute exact path="/caja" component={ListadoCaja} />
            {/* REPORTE DE REAJUSTES */}
            <PrivateRoute exact path="/reporte_reajustes" component={ListadoReajustes} />

            {/* PUNTO DE VENTA */}
            {/* <PrivateRoute exact path="/punto_de_venta" component={PuntoVenta} /> */}
            {/* <PrivateRoute
                exact
                path="/punto_de_venta/venta/:id"
                component={DetalleVenta}
            /> */}
            {/* <PrivateRoute
                exact
                path="/punto_de_venta/mostrador"
                component={PuntoVentaMostrador}
            /> */}
            {/* <PrivateRoute
                exact
                path="/punto_de_venta/caja"
                component={PuntoVentaCaja}
            /> */}
            {/* DESPACHOS */}
            <PrivateRoute exact path="/traslados" component={ListadoDespacho} />
            <PrivateRoute
                exact
                path="/traslados/crear"
                component={CrearDespacho}
            />
            <PrivateRoute
                exact
                path="/traslados/nota_de_envio/:id"
                component={NotaEnvio}
            />
            <PrivateRoute
                exact
                path="/traslados/visualizacion/:id"
                component={DetalleDespacho}
            />
            <PrivateRoute
                exact
                path="/traslados/nota_de_recepcion/:id"
                component={NotaRecepcionDespacho}
            />
            {/* RECEPCION DESPACHOS */}
            <PrivateRoute
                exact
                path="/recepciones_de_traslados"
                component={ListadoRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_traslados/recibir/:id"
                component={CrearRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_traslados/nota_de_recepcion/:id"
                component={NotaRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_traslados/visualizacion/:id"
                component={DetalleRecepcionDespacho}
            />
            {/* <PrivateRoute
                exact
                path="/cotizaciones"
                component={ListadoCotizacion}
            /> */}
            <PrivateRoute
                exact
                path="/ordenes_de_compra"
                component={ListadoOrdenCompra}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_compra/nueva"
                component={CrearOrdenCompra}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_compra/nueva/tipo_de_pago"
                component={TipoPago}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_compra/:id"
                component={DetalleOC}
            />
            {/* RECEPCION OC */}
            {/* <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra"
                component={ListadoRecepcionOrdenCompra}
            /> */}
            {/* <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra/recibir/:id"
                component={RecepcionOrdenCompra}
            /> */}
            {/* <PrivateRoute
                exact
                path="/recepciones_de_despachos/nota_de_recepcion/:id"
                component={NotaRecepcionDespacho}
            />*/}
            {/* <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra/visualizacion/:id"
                component={DetalleRecepcionOrdenCompra}
            /> */}
            {/* PROVEEDORES */}
            <PrivateRoute
                exact
                path="/pedidos_en_linea"
                component={ListadoOrdenOnline}
            />
            <PrivateRoute
                exact
                path="/pedidos_en_linea/detalle/:id"
                component={DetalleOrdenOnline}
            />
            <PrivateRoute
                exact
                path="/pedidos_en_linea/editar/:id"
                component={EditarOrdenOnline}
            />
            {/* //*** BANCOS */}
            {/* Gestion de cuentas */}
            <PrivateRoute path="/bancos" exact component={Bancos.Listar} />
            <PrivateRoute
                path="/bancos/crear"
                exact
                component={Bancos.CrearActualizar}
            />
            <PrivateRoute
                path="/bancos/editar/:id"
                component={Bancos.CrearActualizar}
            />
            {/* Ingreso de documentos */}
            <PrivateRoute
                path="/ingreso_documentos"
                exact
                component={IngresoDocumentos.Listar}
            />
            <PrivateRoute
                path="/ingreso_documentos/crear"
                exact
                component={IngresoDocumentos.CrearActualizar}
            />
            <PrivateRoute
                path="/ingreso_documentos/editar/:id"
                component={IngresoDocumentos.CrearActualizar}
            />
            {/* Transportes */}
            <PrivateRoute
                path="/transportes"
                exact
                component={Transportes.Listar}
            />
            <PrivateRoute
                path="/transportes/crear"
                exact
                component={Transportes.CrearActualizar}
            />
            <PrivateRoute
                path="/transportes/editar/:id"
                component={Transportes.CrearActualizar}
            />
            {/* Reportes */}
            <PrivateRoute
                path="/conciliacion_bancaria"
                exact
                component={ReporteDocumentos.Listar}
            />
            <PrivateRoute
                exact
                path="/conciliacion_bancaria/detalle/:id"
                component={ReporteDocumentos.CrearActualizar}
            />
            <Route path="/recuperar_contraseña" component={Recuperar} />
            <Route path="/reestablecer_clave/:token" component={Resetear} />
            {/* Cuentas por pagar */}
            <PrivateRoute
                exact
                path="/cuentas_por_pagar"
                component={ListadoCuentaPagar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_pagar/:id"
                component={DetalleCuentaPagar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_pagar/:id/ver_documento_pago"
                component={ReciboCuentaPagar}
            />
            <Route path="*" component={PaginaInicio} />
        </Switch>
        <ToastContainer
            position={ToastContainer.POSITION.TOP_RIGHT}
            lightBackground
            store={ToastStore}
        />
    </div>
);
