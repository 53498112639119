import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SET_LOADER = "SET_LOADER_TIPO_CLIENTE";
const DATA = "SET_DATA_TIPO_CLIENTE";
const ITEM = "SET_ITEM_TIPO_CLIENTE";
const SET_PAGE = "SET_PAGE_TIPO_CLIENTE";
const SEARCH = "SET_SEARCH_TIPO_CLIENTE";

// Dato que se esta viendo o editando (retrive o update)
const setItem = (item) => ({
    type: ITEM,
    item,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});

export const crear = (data) => (dispatch) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.post("tipo_cliente", data)
        .then((response) => {
            dispatch(push("/configuracion/tipo_cliente"));
            NotificationManager.success(
                "Registro creado exitosamente",
                "Éxito",
                4000
            );
            dispatch(listar());
        })
        .catch((e) => {
            let mensaje = "Error al crear registro";
            if (e.detalle) mensaje = e.detalle;
            NotificationManager.error(mensaje, "Error", 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch({ type: SET_LOADER, loader: true });
        const params = { page };

        const resource = getStore().tipo_cliente;
        params.search = resource.search;

        api.get("tipo_cliente", params)
            .then((response) => {
                if (response) {
                    dispatch({
                        type: DATA,
                        data: response,
                    });
                    dispatch({
                        type: SET_PAGE,
                        page: page,
                    });
                }
            })
            .catch((e) => {
                let mensaje = "Error al obtener registros";
                if (e.body.detalle) mensaje = e.body.detalle;
                NotificationManager.error(mensaje, "Error", 4000);
                dispatch(push("/"));
            })
            .finally(() => {
                dispatch({ type: SET_LOADER, loader: false });
            });
    };

export const leer = (id) => (dispatch) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.get(`${"tipo_cliente"}/${id}`)
        .then((response) => {
            dispatch(setItem(response));
        })
        .catch((e) => {
            let mensaje = "Error al obtener el registor";
            if (e.body.detalle) mensaje = e.body.detalle;
            NotificationManager.error(mensaje, "Error", 4000);
            dispatch(push("/"));
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const editar = (id, data) => (dispatch) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.put(`${"tipo_cliente"}/${id}`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            dispatch(push("/configuracion/tipo_cliente"));
        })
        .catch((e) => {
            let mensaje = "Error en la actualizacion";
            if (e.detalle) mensaje = e.detalle;
            NotificationManager.error(mensaje, "Error", 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch({ type: SET_LOADER, loader: true });
    api.eliminar(`${"tipo_cliente"}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch((e) => {
            let mensaje = "Error en la transacción";
            if (e.detalle) mensaje = e.detalle;
            NotificationManager.error(mensaje, "Error", 4000);
        })
        .finally(() => {
            dispatch({ type: SET_LOADER, loader: false });
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

export const removerItem = () => (dispatch) => {
    dispatch({
        type: ITEM,
        item: {},
    });
};

export const actions = {
    crear,
    listar,
    editar,
    leer,
    eliminar,
    removerItem,
    searchChange,
};

export const reducers = {
    [SET_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    page: 1,
    item: {},
    search: "",
    filtro: "",
};

export default handleActions(reducers, initialState);
