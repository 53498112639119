import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderDateTime } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import Select, { Async } from "react-select";
import { acciones_bitacora } from "utility/variables";
import { api } from "api";
import moment from "moment";
import DatePicker from "Utils/DatePicker";

const Bitacora = (props) => {
    /*componentWillMount() {
        this.props.getBitacoras();
    }*/

    /*componentWillUnmount() {
        this.props.limpiar();
    }*/

    useEffect(() => {
        props.getBitacoras();
        return () => {
            props.limpiar();
        };
    }, []);

    const { loading, data, fecha_inicial, fecha_final } = props;
    const options = _.cloneDeep(tableOptions);

    const getUsuarios = (search) => {
        return api
            .get("usuarios/lista_all", { search })
            .catch(() => {})
            .then((data) => {
                return { options: data };
            });
    };

    options.noDataText = loading ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.getBitacoras;

    return (
        <div className="row">
            <div className="col-12">
                <div className="grid-container">
                    <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                        <div style={{ flex: "1" }}>
                            <h3>
                                <b>Bitácora</b>
                            </h3>
                        </div>
                        <div
                            className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                            style={{ flex: "3" }}
                        >
                            <div style={{ flex: 1 }}>
                                <label>Fecha Inicio</label>
                                <DatePicker
                                    value={fecha_inicial}
                                    maxDate={new Date()}
                                    onChange={(date) => {
                                        props.setFecha("Inicial", date);
                                    }}
                                />
                            </div>
                            &nbsp; &nbsp;
                            <div style={{ flex: 1 }}>
                                <label>Fecha Fin</label>
                                <DatePicker
                                    value={fecha_final}
                                    minDate={
                                        new Date(
                                            moment(fecha_inicial, "YYYY-MM-DD")
                                        )
                                    }
                                    onChange={(date) => {
                                        props.setFecha("Final", date);
                                    }}
                                />
                            </div>
                            &nbsp; &nbsp;
                            <div style={{ flex: 1 }}>
                                <label>Usuario</label>
                                <Async
                                    className="form-control"
                                    labelKey="first_name"
                                    valueKey="id"
                                    key={`usuario ${props.sucursal}`}
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getUsuarios}
                                    placeholder={"Usuario"}
                                    onChange={(e) => {
                                        props.setUsuario(e);
                                    }}
                                    value={props.usuario}
                                />
                            </div>
                            &nbsp; &nbsp;
                            <div style={{ flex: 1 }}>
                                <label>Accion</label>
                                <Select
                                    placeholder="Seleccionar"
                                    isClearable
                                    className="form-control"
                                    options={acciones_bitacora}
                                    valueKey="label"
                                    labelKey="label"
                                    value={props.accion}
                                    onChange={(e) => {
                                        props.setAccion(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <LoadMask loading={loading} light blur>
                        <BootstrapTable
                            data={loading ? [] : data.results}
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                        >
                            <TableHeaderColumn
                                dataField="creado"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row) => {
                                    return <RenderDateTime fecha={cell} />;
                                }}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="usuario"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row) => {
                                    return cell.first_name || cell.email;
                                }}
                            >
                                Usuario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="accion"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                            >
                                Acción
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    return activeFormatter({
                                        ver: "/bitacora/detalle",
                                    })(cell, row);
                                }}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export default Bitacora;
