import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { actions } from "../../../redux/modules/dashboard/dashboard";


const ms2p = (state) => {
    return {
        data: state.dashboard.data,
        loader: state.dashboard.loader,
        usuario: state.usuario.me,
        tipo_punto_venta: state.usuario.tipo_punto_venta,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Dashboard);
