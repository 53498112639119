import { connect } from "react-redux";
import ModalDescuentos from "./ModalDescuentos";
import { generarDescuento } from "../../../../redux/modules/venta/venta";

const ms2p = (state) => {
    return {
        loader_nc: state.devolucion.loader,
    };
};

const md2p = {
    generarDescuento: generarDescuento,
};

export default connect(ms2p, md2p)(ModalDescuentos);
