import React from "react";
import Modal from "react-responsive-modal";
import FormDescuentos from "./FormDescuentos";

const ModalFacturar = ({ modal, loader_nc, setModal, generarDescuento }) => {
    const { open, data } = modal;

    const total_venta = data.total_con_devoluciones || 0;

    const onCloseModal = () => setModal({ open: false, data: {} });

    const onSubmit = (values) => {
        // onCloseModal();
        generarDescuento(data.id, data.id_venta, values);
    };

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => onCloseModal()}
                classNames={{ modal: "modal-md" }}
                center
            >
                <div
                    className="d-flex flex-column align-items-center justify-content-around px-4 py-3"
                    style={{ minWidth: "30vw", minHeight: "25vh" }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <h2 className="mb-3 font-weight-bolder">Descuento</h2>
                        <p className="text-center">
                            Se Aplicara un descuento a la venta <br /> (Q.
                            {total_venta.toFixed(2)} total de la venta)
                        </p>
                    </div>

                    <FormDescuentos
                        total_venta={total_venta}
                        estado_fel={loader_nc}
                        onSubmit={onSubmit}
                        onCloseModal={onCloseModal}
                    />
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ModalFacturar;
