import React, { Component } from "react";
import PropTypes from "prop-types";
import "./step_wizard.css";

export default class StepWizard extends Component {
    static propTypes = {
        steps: PropTypes.array.isRequired,
        active: PropTypes.number.isRequired,
    };

    render() {
        //  State
        const { steps, active } = this.props;
        //  Bind
        return (
            <div className="w-100">
                <ul id="wizardstep">
                    {steps.map((step, index) => {
                        return (
                            <li
                                key={index}
                                className={`${
                                    step.value == active ? "active" : ""
                                } ${step.value < active ? "completed" : ""}`}
                                style={{ "--steps": `${steps.length}` }}
                            >
                                {step.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
