import React from "react";

import "./Carrousell.css";

const Marcas = () => {
    return (
        <div className="item-6">
            <div className="item-slider">
                <div className="item-slide-track">
                    {/* Imagen #1 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #2 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #3 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #4 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #5 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #6 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #7 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #8 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #9 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #10 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #11 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #12 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #13 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #14 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #15 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #16 */}
                    <div className="item-slide" />
                    <div className="item-slide" />

                    {/* Imagen #17 */}
                    <div className="item-slide" />
                    <div className="item-slide" />
                </div>
            </div>
        </div>
    );
};

export default Marcas;
