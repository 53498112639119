import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { icons } from "icons";
import { formatoNombre } from "Utils/CamposComunes";
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import { opcionesDevolucion, opcionesReingreso } from "utility/variables";
import NumberFormat from "react-number-format";
import Encabezado from "../Eleccion/Encabezado";

class Motivos extends Component {
    static propTypes = {
        factura: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalAyuda: false,
            itemsDevolucion: [
                {
                    src: icons.devolucion_p7,
                    alt: "Paso 1",
                },
                {
                    src: icons.devolucion_p8,
                    alt: "Paso 2",
                },
                {
                    src: icons.devolucion_p9,
                    alt: "Paso 3",
                },
                {
                    src: icons.devolucion_p10,
                    alt: "Paso 4",
                },
            ],
        };
    }

    componentWillMount() {}
    closeModalAyuda = () => {
        this.setState({ modalAyuda: false });
    };
    openModalAyuda = () => {
        this.setState({ modalAyuda: true });
    };

    render() {
        const {
            elegidos: productos,
            loader,
            factura,
            total,
            adicionales_seleccionados,
        } = this.props;

        const movimiento = factura.movimiento;
        const cuenta_cobrar = movimiento ? movimiento.cuenta_cobrarpagar : null;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        if (productos.length === 0) {
            return (
                <Redirect
                    to={`/bodega/nota_credito_cliente/seleccion_de_producto/${this.props.match.params.id}`}
                />
            );
        }
        let mostrar_alertas = false;
        if (cuenta_cobrar) {
            if (cuenta_cobrar.saldo < total) mostrar_alertas = true;
        }
        if (factura) {
            if (factura.movimiento.total_cobrar_pagar < total)
                mostrar_alertas = true;
        }

        const { params } = this.props.match;

        return (
            <React.Fragment>
                <div className="col-12">
                    <LoadMask loading={loader} blur light>
                        <div className="d-flex flex-column grid-container px-sm-4 py-sm-5">
                            {/* region ENCABEZADO */}
                            <Encabezado
                                idFactura={params.id}
                                disabled_async={true}
                            />
                            {/* endregion ENCABEZADO */}
                            {/* region SELECCION DE PRODUCTOS A DEVOLVER */}
                            <small className="mt-2 mb-2 text-secondary">
                                Selecciona los motivos por el cual serán
                                devueltos
                            </small>
                            <BootstrapTable
                                data={loader ? [] : productos}
                                remote
                                hover
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="codigo"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="nombre"
                                    dataFormat={formatoNombre}
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="precio"
                                    width="15%"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell) => {
                                        return <RenderMoneda monto={cell} />;
                                    }}
                                >
                                    Precio
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn
                                    dataField=""
                                    width="100px"
                                    dataAlign="right"
                                    // dataField="porcentaje_precio"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <NumberFormat
                                                className="form-control text-right"
                                                placeholder="100 %"
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                value={cell}
                                                suffix={" %"}
                                                onValueChange={(values) => {
                                                    this.props.setParamElegido(
                                                        "porcentaje_precio",
                                                        values.floatValue
                                                            ? values.floatValue
                                                            : 0,
                                                        row
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                >
                                    % Precio
                                </TableHeaderColumn> */}
                                {/* <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="precio_calculado"
                                    width="100px"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderMoneda
                                                monto={cell ? cell : row.precio}
                                            />
                                        );
                                    }}
                                >
                                    Precio real
                                </TableHeaderColumn> */}
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="cantidad"
                                    width="100px"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={(cell, row) => {
                                        return (
                                            <RenderNumero
                                                monto={cell ? cell : row.precio}
                                                // decimalScale={
                                                //     row.producto.a_granel
                                                //         ? 3
                                                //         : 2
                                                // }
                                            />
                                        );
                                    }}
                                >
                                    Cantidad
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="key"
                                    isKey
                                    dataFormat={(cell, row) => {
                                        return (
                                            <select
                                                className={"form-control"}
                                                onChange={(e) => {
                                                    this.props.setParamElegido(
                                                        "motivo",
                                                        e.target.value,
                                                        row
                                                    );
                                                }}
                                                value={row.motivo}
                                            >
                                                {opcionesDevolucion.map(
                                                    (opcion) => {
                                                        return (
                                                            <option
                                                                key={
                                                                    opcion.value
                                                                }
                                                                value={
                                                                    opcion.value
                                                                }
                                                            >
                                                                {opcion.label}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                        );
                                    }}
                                >
                                    Motivo
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField=""
                                    dataFormat={(cell, row) => {
                                        return (
                                            <input
                                                type="text"
                                                autocomplete="off"
                                                className="form-control"
                                                ref={(node) => {
                                                    this.nota = node;
                                                    if (
                                                        this.nota &&
                                                        row.nota !== undefined
                                                    ) {
                                                        this.nota.value =
                                                            row.nota;
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    if (this.nota) {
                                                        this.props.setParamElegido(
                                                            "nota",
                                                            e.target.value,
                                                            row
                                                        );
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                >
                                    Nota
                                </TableHeaderColumn>
                            </BootstrapTable>
                            {/* endregion SELECCION DE PRODUCTOS A DEVOLVER */}
                            <br />
                            <div className="d-flex justify-content-between flex-row ">
                                {mostrar_alertas && (
                                    <div className="flex1 d-flex flex-column text-warning">
                                        {cuenta_cobrar &&
                                            cuenta_cobrar.saldo < total && (
                                                <h5>
                                                    <i className="fa fa-warning mr-3"></i>
                                                    El total es mayor al saldo
                                                    disponible del cliente (
                                                    <RenderMoneda
                                                        monto={
                                                            cuenta_cobrar.saldo
                                                        }
                                                    />
                                                    )
                                                </h5>
                                            )}
                                        {factura &&
                                            factura.movimiento
                                                .total_cobrar_pagar < total && (
                                                <h5>
                                                    <i className="fa fa-warning mr-3"></i>
                                                    El total es mayor al total
                                                    de la venta
                                                </h5>
                                            )}
                                    </div>
                                )}
                                <div
                                    className={`${
                                        mostrar_alertas
                                            ? ""
                                            : "w-100 text-right"
                                    }`}
                                >
                                    {adicionales_seleccionados.map(
                                        (adicional, index) => {
                                            return (
                                                <h6
                                                    className={`${
                                                        index + 1 !=
                                                        adicionales_seleccionados.length
                                                            ? "mb-0"
                                                            : ""
                                                    }`}
                                                    key={index}
                                                >
                                                    {adicional.nombre}:
                                                    <RenderMoneda
                                                        className="ml-2"
                                                        monto={adicional.precio}
                                                    />
                                                </h6>
                                            );
                                        }
                                    )}
                                    <h5>
                                        Total:
                                        <RenderMoneda
                                            className="ml-2"
                                            monto={total}
                                        />
                                    </h5>
                                </div>
                            </div>
                            <br />
                            <div className="d-flex justify-content-center flex-row">
                                <Link
                                    to={`/bodega/nota_credito_cliente/seleccion_de_producto/${this.props.match.params.id}`}
                                    className="btn btn-tertiary m-1 align-self-center"
                                >
                                    <em className="fa fa-arrow-left" /> Atrás
                                </Link>
                                <button
                                    className="btn btn-primary m-1 align-self-center"
                                    onClick={() =>
                                        this.props.generarVale(
                                            this.props.match.params.id
                                        )
                                    }
                                >
                                    <i className="fa fa-check" />
                                    &nbsp;finalizar
                                </button>
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </React.Fragment>
        );
    }
}

export default Motivos;
