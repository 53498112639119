import React, { useState, useEffect, useContext } from "react";

import { Form, Field } from "react-final-form";
import {
    required,
    email,
    composeValidators,
    maxLength,
    minLength,
    nit,
} from "../../../../../utility/validation";
//import { SubFamiliasContext } from "../../../../../context/SubFamiliasContext";
import {
    renderField,
    renderNumber,
    SelectField,
} from "Utils/renderField/renderField";

import SubFamiliaTabla from "./SubFamiliaTabla";

const SubFamiliaForm = ({
    subFamilias,
    setSubFamilia,
    ver = false,
    editar = false,
}) => {
    // Datos del contexto de proveedores
    /*const { subFamilias, setSubFamilia } = useContext(SubFamiliasContext);
    const { familia, nuevas_familias } = subFamilias;

    // Submit al formulario Contactos del proveedor
    const onSubmit = (value, event) => {
        value.id = makeRandomId();

        // Si se esta editando el dato, agregar los datos del formulario al array nuevos_contactos
        if (editar) {
            setSubFamilia({
                ...subFamilias,
                familia: [...familia, value],
                nuevas_familias: [...nuevas_familias, value],
            });
        } else {
            // sino, solo al array contactos
            setSubFamilia({
                ...subFamilias,
                familia: [...familia, value],
            });
        }
        // Limpiar el formulario
        event.reset({
            nombre: "",
        });
    };*/
    const { familia, nuevas_familias } = subFamilias;

    // Generador de un id
    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        composeValidators;
        let id = b.replace(/[-T:.Z]/g, "");
        return `ID${id}`;
    };

    const onSubmit = (value, event) => {
        value.id = makeRandomId();

        if (editar) {
            setSubFamilia({
                ...subFamilias,
                familia: [...familia, value],
                nuevas_familias: [...nuevas_familias, value],
            });
        } else {
            // sino, solo al array contactos
            setSubFamilia({
                ...subFamilias,
                familia: [...familia, value],
            });
        }
        // Limpiar el formulario
        event.reset({
            nombre: "",
        });
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit} //onSubmit
                // initialValues={proveedorDatos}
                render={({ handleSubmit, submitting, form, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <h3 className="contenido-1 border-bottom txt-bold black mb-4 mt-5">
                            Sub-Familia
                        </h3>

                        <div className="d-flex flex-column flex-md-row mb-4">
                            <div className="form-group has-feedback m-2 flex-3">
                                <label htmlFor="address">Nombre *</label>
                                <Field
                                    name="nombre"
                                    component={renderField}
                                    placeholder="Sub-Familia"
                                    type="text"
                                    className="form-control"
                                    validate={composeValidators(required)} //nit
                                    disabled={ver}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                            <div className="form-group has-feedback m-2 flex-3"></div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-end py-4">
                            {!ver && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Agregar Sub-Familia
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
            <SubFamiliaTabla
                subFamilias={subFamilias}
                setSubFamilia={setSubFamilia}
            />
        </React.Fragment>
    );
};

export default SubFamiliaForm;
