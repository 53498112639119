import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "Utils/renderField/renderField";
import { Async } from 'react-select';
import { api } from 'api';
import classNames from "classnames";

const validate = (values) => {
    const errors = {};
    if (values.nit) {
        let nit = values.nit;
        if (!nit) {
            return true;
        }

        var nitRegExp = new RegExp("^[0-9]+(-?[0-9kK])?$");

        if (!nitRegExp.test(nit)) {
            if (nit == "CF" || nit == "cf") {
                return true;
            } else {
                errors.nit = "Nit invalido";
            }
        }

        nit = nit.replace(/-/, "");
        var lastChar = nit.length - 1;
        var number = nit.substring(0, lastChar);
        var expectedCheker = nit
            .substring(lastChar, lastChar + 1)
            .toLowerCase();

        var factor = number.length + 1;
        var total = 0;

        for (var i = 0; i < number.length; i++) {
            var character = number.substring(i, i + 1);
            var digit = parseInt(character, 10);

            total += digit * factor;
            factor = factor - 1;
        }

        var modulus = (11 - (total % 11)) % 11;
        var computedChecker = modulus == 10 ? "k" : modulus.toString();
        if (expectedCheker !== computedChecker) {
            errors.nit = "Nit invalido";
        }
    }
    return errors;
};

const Form = (props) => {
    const {
        leerCliente,
        leerClienteNombre,
        changeDatosClientes,
        disabled,
        estado_movimiento,
        conf_mostrar_referencia,
    } = props;

    const getUsuarios = (search) => {
        return api.get("clientes_list", { search }).catch(() => { }).then((data) => {
            return { options: data.results }
        })
    };

    const change = (e) => {
        const value = e.target.value;

        // buscar el nit solo cuando tiene al menos 5 letras
        if (value.length > 5) {
            leerCliente(value);
        }
        if (props.utiliza_fel && props.conf_habilitar_campo_cliente) {
            if (value === "CF" | value === "cf") {
                props.setEstadoFormCliente(false)
            }
            else {
                props.setEstadoFormCliente(true)
            }
        }
        changeDatosClientes("nit", value);
    };

    const renderSearchSelectI = ({
        input,
        disabled,
        loadOptions,
        valueKey,
        labelKey,
        meta: { touched, error },
    }) => {
        const invalid = touched && error;
        return (
            <React.Fragment>
                <Async
                    disabled={disabled}
                    value={input.value}
                    className={classNames("form-control", {
                        "is-invalid": invalid,
                    })}
                    onChange={(e) => {
                        leerClienteNombre(e.nombre, e.nit);
                        input.onChange(!!e ? e[valueKey] : null);
                    }}
                    placeholder="Seleccionar"
                    searchPromptText="Escriba para buscar"
                    valueKey={valueKey}
                    labelKey={labelKey}
                    loadOptions={loadOptions}
                />
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                        {input.value}
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <form className="mt1">
            <div className="form-group ">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 form-group">
                        <label htmlFor="nit">Nit</label>
                        <Field
                            name="nit"
                            label="Ingrese un nit válido o CF"
                            component={renderField}
                            type="text"
                            className="form-control"
                            onChange={change}
                            disabled={estado_movimiento}
                        />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 form-group">
                        <label htmlFor="nombre">Nombre</label>
                        <Field
                            name="nombre"
                            label="Nombre del cliente"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={disabled}
                            onChange={(e) => {
                                const value = e.target.value;
                                changeDatosClientes("nombre", value);
                            }}

                        />
                    </div>
                    {(props.buscar_nombre && !estado_movimiento) &&
                        //IF
                        (
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                <label htmlFor="nombre">Buscar cliente</label>
                                <Field
                                    name="buscar_nombre"
                                    component={renderSearchSelectI}
                                    loadOptions={getUsuarios}
                                    labelKey="nombre"
                                    valueKey="id_nombre"
                                    className="form-control"
                                />
                            </div>
                        )
                    }
                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="direccion">Dirección*</label>
                        <Field
                            name="direccion"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                        />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <label htmlFor="direccion">Referencia</label>
                        <Field
                            name="referencia"
                            component={renderField}
                            type="text"
                            className="form-control"
                            disabled={estado_movimiento}
                        />
                    </div>
                    {conf_mostrar_referencia == true ?
                        <div className="col-md-4 form-group">
                            <label htmlFor="mostrar_referencia">
                                Imprimir referencia
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="mostrar_referencia"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        : ""
                    }
                </div>
            </div>
        </form>
    );
};

const ClienteVentaForm = reduxForm({
    // a unique name for the form
    form: "ClienteVentaForm",
    validate,
})(Form);

export default ClienteVentaForm;
