import { connect } from 'react-redux';
import { actions, setProveedores } from "../../../../redux/modules/proveedor/proveedor";
import Editar from './ProveedoresEditar'


const ms2p = (state) => {
    return {
        ...state.proveedor,
    };
};

const md2p = { ...actions, setProveedores };

export default connect(ms2p, md2p)(Editar);
