import React from "react";

import { Async } from "react-select";
import Select from "react-select/lib/Select";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";

import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { TIPO_CLIENTE } from "../../../../utility/constantes";
import {
    TIPO_MOVIMIENTO_CP,
    TIPO_MOVIMIENTO_ENTRADAS,
    TIPO_MOVIMIENTO_SALIDAS,
    TIPO_PROVEEDOR,
    TIPO_REPORTE_COMISION,
    TIPO_REPORTE_ENTRADAS,
    TIPOS_REPORTE_REAJUSTES
} from "../../../../utility/variables";
import { useEffect } from "react";

const listado_usuarios = [];
const listado_clientes = [];
const listado_proveedores = [];
const listado_sucursales = [];

const FiltrosReportes = ({
    fecha_inicial,
    fecha_final,
    vendedor,
    usuarioFiltro,
    cliente,
    tipo_cliente,
    tipo_movimiento,

    // state cuentas por pagar
    tipo_proveedor,
    proveedor,

    // state reporte por entradas
    tipo_reporte,
    sucursal,

    setFechaInicial,
    setFechaFinal,
    setVendedor,
    setUsuario,
    setCliente,
    setTipoCliente,
    setTipoMovimiento,
    setTipoReajuste,

    // func cuentas por pagar
    setTipoProveedor,
    setProveedor,

    // func reporte por entradas
    setTipoReporte,
    setSucursal,

    aplicarFiltros = () => {},
    setDataValue = () => {},

    filtroCP = false,
    filtroCC = false,
    filtroComisiones = false,

    usuario = null,
    sucursal_param = null,

    filtroReciboCC = false,
    filtroReciboCP = false,
    filtroNotasCreditoProv = false,
    filtroNotasCreditoCliente = false,
    filtroEntradas = false,
    filtroSalidas = false,
    filtroCaja = false,
    filtroComisionMensual = false,
    filtroReajustes = false
}) => {
    const es_global = usuario ? usuario.es_global : false; // Si el usuario tiene acceso a todas las sucursales

    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        { es_vendedor: 1 }
    );

    const { getOptions: getClientes } = useAsyncSearch(
        "clientes",
        listado_clientes,
        { es_cliente: 1 }
    );

    const { getOptions: getProveedores } = useAsyncSearch(
        "proveedores",
        listado_proveedores
    );

    const { getOptions: getSucursales } = useAsyncSearch(
        "sucursales",
        listado_sucursales
    );

    useEffect(() => {
        if (usuario && usuario.sucursales && !es_global) {
            setSucursal(sucursal_param);
        }
        if (usuario && !es_global) {
            setSucursal(sucursal_param)
        }
    }, [usuario]);

    return (
        <div className="d-flex flex-column grid-container px-5 pt-4 pb-2 mb-5">
            <h1 className="title-text border-bottom">Filtros</h1>

            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                <div className="flex-8">
                    <label htmlFor="">Fecha inicial</label>
                    <DatePicker
                        value={fecha_inicial}
                        maxDate={
                            new Date(
                                moment(
                                    moment().format("YYYY-MM-DD"),
                                    "YYYY-MM-DD"
                                )
                            )
                        }
                        onChange={(date) => {
                            setFechaInicial("Inicial", date);
                        }}
                    />
                </div>
                <div className="flex-1"></div>
                <div className="flex-8">
                    <label htmlFor="">Fecha final</label>
                    <DatePicker
                        value={fecha_final}
                        minDate={new Date(moment(fecha_inicial, "YYYY-MM-DD"))}
                        onChange={(date) => {
                            setFechaFinal("Final", date);
                        }}
                    />
                </div>
                <div className="flex-1"></div>
            </div>

            {/* //* FILTRO DE CUENTAS POR COBRAR & RECIBO DE CUENTAS POR COBRAR */}
            {(filtroCC ||
                filtroReciboCC ||
                filtroNotasCreditoCliente ||
                filtroCaja ||
                filtroComisionMensual) && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        {/* //** FILTRO POR VENDEDOR SI ES FILTRO DE RECIBO DE CUENTAS POR COBRAR */}
                        {filtroReciboCC ||
                        filtroNotasCreditoCliente ||
                        filtroCaja ||
                        filtroComisionMensual ? (
                            <React.Fragment>
                                <label>Vendedor</label>
                                <Async
                                    className="form-control"
                                    labelKey="first_name"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getUsuarios}
                                    placeholder={"Vendedor"}
                                    onChange={(e) => {
                                        setVendedor(e ? e.id : "");
                                    }}
                                    value={vendedor}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <label>Tipo de cliente</label>
                                <Select
                                    placeholder="Seleccionar"
                                    isClearable
                                    className="form-control"
                                    options={TIPO_CLIENTE}
                                    valueKey="value"
                                    labelKey="label"
                                    value={tipo_cliente}
                                    onChange={(e) => {
                                        return setTipoCliente(e ? e : "");
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="flex-1"></div>

                    <div className="flex-8">
                        {filtroCaja || filtroComisionMensual ? (
                            <React.Fragment>
                                <label>Sucursal</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getSucursales}
                                    placeholder={"Sucursal"}
                                    disabled={!es_global && sucursal_param}
                                    onChange={(e) => {
                                        setSucursal(e ? e.id : "");
                                    }}
                                    value={
                                        es_global ? sucursal : sucursal_param
                                    }
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <label>Cliente</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getClientes}
                                    placeholder={"Cliente"}
                                    onChange={(e) => {
                                        setCliente(e);
                                    }}
                                    value={cliente}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {/* //* FILTRO DE CUENTAS POR PAGAR */}
            {(filtroCP ||
                filtroReciboCP ||
                filtroNotasCreditoProv ||
                filtroEntradas ||
                filtroSalidas) && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        {filtroReciboCP ||
                        filtroNotasCreditoProv ||
                        filtroEntradas ||
                        filtroSalidas ? (
                            <React.Fragment>
                                <label>Vendedor</label>
                                <Async
                                    className="form-control"
                                    labelKey="first_name"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getUsuarios}
                                    placeholder={"Vendedor"}
                                    onChange={(e) => {
                                        setVendedor(e ? e.id : "");
                                    }}
                                    value={vendedor}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <label>Tipo de proveedor</label>
                                <Select
                                    placeholder="Seleccionar"
                                    isClearable
                                    className="form-control"
                                    options={TIPO_PROVEEDOR}
                                    valueKey="value"
                                    labelKey="label"
                                    value={tipo_proveedor}
                                    onChange={(e) => {
                                        return setTipoProveedor(e ? e : "");
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="flex-1"></div>

                    <div className="flex-8">
                        {filtroEntradas ||
                        filtroCP ||
                        filtroNotasCreditoProv ||
                        filtroReciboCP ? (
                            <React.Fragment>
                                <label>Proveedor</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getProveedores}
                                    placeholder={"Proveedor"}
                                    onChange={(e) => {
                                        setProveedor(e ? e.id : "");
                                    }}
                                    value={proveedor}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <label>Cliente</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getClientes}
                                    placeholder={"Cliente"}
                                    onChange={(e) => {
                                        setCliente(e ? e.id : "");
                                    }}
                                    value={cliente}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {/* //* FILTRO DE CUENTAS POR PAGAR & FILTRO CUENTAS POR COBRAR*/}
            {(filtroCC || filtroCP) && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <React.Fragment>
                        <div className="flex-8">
                            <label>Vendedor</label>
                            <Async
                                className="form-control"
                                labelKey="first_name"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getUsuarios}
                                placeholder={"Vendedor"}
                                onChange={(e) => {
                                    setVendedor(e ? e.id : "");
                                }}
                                value={vendedor}
                            />
                        </div>
                        <div className="flex-1"></div>
                    </React.Fragment>

                    <div className="flex-8">
                        <label>Tipo Movimiento</label>
                        <MultiSelect
                            options={TIPO_MOVIMIENTO_CP || []}
                            value={tipo_movimiento}
                            onChange={setTipoMovimiento}
                            labelledBy="Select"
                        />
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {/* //* FILTRO DE REPORTE POR COMISIONES */}
            {filtroComisiones && (
                <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                    <div className="flex-8">
                        <label>Tipo de reporte</label>
                        <Select
                            placeholder="Seleccionar"
                            isClearable={false}
                            isSearchable={false}
                            className="form-control"
                            options={TIPO_REPORTE_COMISION}
                            valueKey="value"
                            labelKey="label"
                            value={tipo_movimiento}
                            onChange={(e) => {
                                setDataValue({});
                                return setTipoMovimiento(e ? e : "");
                            }}
                        />
                    </div>
                    <div className="flex-1"></div>

                    <div className="flex-8">
                        <label>Vendedor</label>
                        <Async
                            className="form-control"
                            labelKey="first_name"
                            valueKey="id"
                            searchPromptText="Escriba para buscar"
                            loadOptions={getUsuarios}
                            placeholder={"Vendedor"}
                            onChange={(e) => {
                                setVendedor(e ? e.id : "");
                            }}
                            value={vendedor}
                        />
                    </div>
                    <div className="flex-1"></div>
                </div>
            )}

            {(filtroEntradas || filtroSalidas) && (
                <React.Fragment>
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                        <div className="flex-8">
                            <label>Tipo de reporte</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable={false}
                                isSearchable={false}
                                className="form-control"
                                options={TIPO_REPORTE_ENTRADAS}
                                valueKey="value"
                                labelKey="label"
                                value={tipo_reporte}
                                onChange={(e) => {
                                    return setTipoReporte(e ? e.id : "");
                                }}
                            />
                        </div>
                        <div className="flex-1"></div>

                        <div className="flex-8">
                            <label>Tipo Movimiento</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={
                                    filtroEntradas
                                        ? TIPO_MOVIMIENTO_ENTRADAS
                                        : TIPO_MOVIMIENTO_SALIDAS
                                }
                                valueKey="value"
                                labelKey="label"
                                value={tipo_movimiento}
                                onChange={(e) => {
                                    return setTipoMovimiento(e ? e.value : "");
                                }}
                            />
                        </div>
                        <div className="flex-1"></div>
                    </div>

                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                        <div className="flex-8">
                            <label>Sucursal</label>
                            <Async
                                className="form-control"
                                labelKey="nombre"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getSucursales}
                                placeholder={"Sucursal"}
                                onChange={(e) => {
                                    setSucursal(e ? e.id : "");
                                }}
                                value={es_global ? sucursal : sucursal_param}
                                disabled={!es_global && sucursal_param}
                            />
                        </div>
                        <div className="flex-1"></div>

                        <div className="flex-8"></div>
                        <div className="flex-1"></div>
                    </div>
                </React.Fragment>
            )}
            {/* FILTRO DE REPORTE DE REAJUSTES */}
            {(filtroReajustes) && (
                <React.Fragment>
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                        <div className="flex-8">
                            <label>Sucursal</label>
                            <Async
                                className="form-control"
                                labelKey="nombre"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getSucursales}
                                placeholder={"Sucursal"}
                                disabled={!es_global && sucursal_param}
                                onChange={e => {
                                    setSucursal(e ? e.id : "")
                                }}
                                value={
                                    es_global ? sucursal : sucursal_param
                                }
                            />
                        </div>
                        <div className="flex-1"/>
                        <div className="flex-8">
                            <label>Usuario</label>
                            <Async
                                className="form-control"
                                labelKey="first_name"
                                valueKey="id"
                                searchPromptText="Escriba para buscar"
                                loadOptions={getUsuarios}
                                placeholder={"Usuario"}
                                onChange={e => {
                                    setUsuario(e ? e.id : "")
                                }}
                                value={usuarioFiltro}
                            />
                        </div>
                        <div className="flex-1"/>
                    </div>
                    <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                        <div className="flex-8">
                            <label>Tipo de reporte</label>
                            <Select
                                placeholder="Seleccionar"
                                isClearable
                                className="form-control"
                                options={TIPOS_REPORTE_REAJUSTES}
                                valueKey="value"
                                labelKey="label"
                                value={tipo_reporte}
                                onChange={e => {
                                    return setTipoReajuste(e ? e.id : 1)
                                }}
                            />
                        </div>
                        <div className="flex-1"/>
                        <div className="flex-8"/>
                        <div className="flex-1"/>
                    </div>
                </React.Fragment>
            )}

            <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-4 mt-3">
                <div className="flex-8"></div>
                <div className="flex-1"></div>

                <div className="flex-8 d-flex justify-content-end">
                    <button
                        className="btn btn-primary px-4"
                        // disabled={estado_descarga}
                        onClick={() =>
                            aplicarFiltros(filtroReciboCC || filtroReciboCP)
                        }
                    >
                        <em className="fa fa-filter"></em>
                        &nbsp;Filtrar
                    </button>
                </div>
                {/* <div className="flex-1"></div> */}
            </div>
        </div>
    );
};

export default FiltrosReportes;
