import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { FiltrosReportes } from "../../../Utils/FiltrosReportes";
import { formatoTrueFalse } from "../../../Utils/CamposComunes";

const ListadoCaja = ({
    // state
    loader,
    data,
    fecha_vencimiento_inicial,
    fecha_vencimiento_final,
    estado_descarga,
    sucursal,
    vendedor,
    page,
    totales,

    usuario = null,
    sucursal_param = null,

    // bind
    setFecha,
    descargarListado,
    aplicarFiltros,
    setSucursal,
    setVendedor,
    limpiarFiltros,
    listar,
    sortChange,
}) => {
    //  OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando..." : tableOptions.noDataText,
        onPageChange: listar,
        onSortChange: sortChange,
        page: page,
    };

    useEffect(() => {
        limpiarFiltros();
        return () => {
            limpiarFiltros();
        };
    }, []);

    const {
        total_entradas,
        total_salidas,
        saldo_total,
        total_devolucion_venta_credito,
    } = totales;

    // console.log(totales);
    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        sucursal={sucursal}
                        vendedor={vendedor}
                        usuario={usuario}
                        sucursal_param={sucursal_param}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setSucursal={setSucursal}
                        setVendedor={setVendedor}
                        aplicarFiltros={aplicarFiltros}
                        filtroCaja
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    const esReporteRecibo = true;
                                    descargarListado(esReporteRecibo);
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="fecha_movimiento"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="usuario"
                                    dataFormat={(cell) => cell.first_name}
                                >
                                    Vendedor
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo">
                                    Movimiento
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="documento_afectado_dev">
                                    Doc. afectado/dev.
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="documento_afectado_des">
                                    Doc. afectado/com.
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="entradas"
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderCurrency
                                            value={cell}
                                            className={
                                                cell > 0
                                                    ? "font-weight-bold"
                                                    : ""
                                            }
                                        />
                                    )}
                                >
                                    Entrada
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="salidas"
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderCurrency
                                            value={cell}
                                            className={
                                                cell > 0
                                                    ? "font-weight-bold"
                                                    : ""
                                            }
                                        />
                                    )}
                                >
                                    Salida
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="devolucion_venta_credito"
                                    dataAlign="right"
                                    dataFormat={(cell) => (
                                        <RenderCurrency
                                            value={cell}
                                            className={
                                                cell > 0
                                                    ? "font-weight-bold"
                                                    : ""
                                            }
                                        />
                                    )}
                                >
                                    Notas de dev.
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="forma_pago"
                                    dataAlign="right"
                                >
                                    Tipo de pago
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="documento_pago"
                                    dataAlign="right"
                                >
                                    Documento
                                </TableHeaderColumn>

                                {/* <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="total"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Total
                                </TableHeaderColumn> */}

                                <TableHeaderColumn
                                    dataField="id"
                                    width="0"
                                    isKey
                                    dataAlign="center"
                                ></TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>

                        {/* Totales */}
                        <div className="d-flex flex-column flex-md-row justify-content-center">
                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>T. Entradas:</strong>
                                </label>
                                <label>
                                    <RenderCurrency value={total_entradas} />
                                </label>
                            </div>

                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>T. Salidas:</strong>
                                </label>
                                <label>
                                    <RenderCurrency value={total_salidas} />
                                </label>
                            </div>

                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>T. Notas de dev.</strong>
                                </label>
                                <label>
                                    <RenderCurrency
                                        value={total_devolucion_venta_credito}
                                    />
                                </label>
                            </div>

                            <div>
                                <label>
                                    <strong>Gran total:</strong>
                                </label>
                                <label>
                                    <RenderCurrency value={saldo_total} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoCaja.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoCaja;
