import React from "react";
import Form from "./ProveedoresCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import { withRouter } from "react-router-dom";
import useCrearEditarProveedor from "../../../hooks/useCrearEditarProveedor";

const Crear = ({ loader, crear, match, item, location, contactos, setProveedores }) => {
    const {
        files,
        oldFiles,
        onChangeFile,
        onRemoveOldFile,
        onRemoveFile,
        onSubmit,
    } = useCrearEditarProveedor({ crear, match, item, location, contactos, setProveedores });
    return (
        <div className="row">
            <div className="col-sm-12">
                <LoadMask loading={loader} light blur radius>
                    <Form
                        onSubmit={onSubmit}
                        files={files}
                        onChangeFile={onChangeFile}
                        onRemoveFile={onRemoveFile}
                        onRemoveOldFile={onRemoveOldFile}
                        oldFiles={oldFiles}
                    />
                </LoadMask>
            </div>
        </div>
    );
};

export default withRouter(Crear);
