import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import LoadMask from "Utils/LoadMask/LoadMask";
import Barcode from 'react-barcode';
import { PrintContainer, PrintComponent } from "Utils/Print";

class ImprimirCodigoProducto extends Component {
    static propTypes = {
        leer: PropTypes.func.isRequired,
        producto: PropTypes.object.isRequired,
    }
    constructor(props) {
        super(props);
        this.state = {
            tiene_fracciones: false,
            tiene_codigo: false,
            fraccion: null,
            cantidad: 40,
            cod_x_pagina: 40,
            cod_x_fila: 5,
            paginas: [{
                no_pagina: 1,
                detalle: []
            }],
            opcion: '10',
            no_copias: 1
        };
    }

    /**
     * Funcion para generar la estructura de hoja de impresion de codigos
     */
    estructuraPaginas = (tiene_codigo, fraccion, cantidad, opcion) => {
        const {cod_x_pagina, cod_x_fila } = this.state;
        let total_cod_pagina = cod_x_pagina;

        if (opcion === "20") {
            total_cod_pagina = cod_x_pagina / 2;
        }
        let paginas = [];
        if (cantidad){
            const no_paginas = Math.ceil(parseInt(cantidad)/total_cod_pagina)
            if (tiene_codigo && cantidad > 0) {
                for(let i=0; i<no_paginas; i++) {
                    let estructura = {
                        no_pagina: i+1,
                        detalle: []
                    }
                    paginas.push(estructura)
                }

                const filas = Math.ceil(parseInt(cantidad)/cod_x_fila);
                const filas_x_pagina = total_cod_pagina/cod_x_fila;
                for(let x=0; x<filas; x++) {
                    const fila = x+1;
                    const no_pagina = Math.ceil((fila)/filas_x_pagina);

                    if ((fila * cod_x_fila) < cantidad) {
                        let fila_list = []
                        for(let y=0; y<cod_x_fila; y++) {
                            fila_list.push({codigo_barras:fraccion.codigo_barras, descripcion: fraccion.descripcion_codigo_barras})
                        }
                        paginas[no_pagina-1].detalle.push(fila_list);
                    }else {
                        const dif = parseInt(cantidad) - (((fila-1) * cod_x_fila));
                        let fila_list = []
                        for(let y=0; y<dif; y++) {
                            fila_list.push({codigo_barras:fraccion.codigo_barras, descripcion: fraccion.descripcion_codigo_barras})
                        }
                        paginas[no_pagina-1].detalle.push(fila_list);
                    }
                }

                this.setState({paginas})
            }
        }
    }

    /**
     * Funcion para cambiar la cantidad de codigos a imprimir
     */
    cambioCantidad = (cantidad) => {
        this.setState({cantidad})

        const { fraccion, tiene_codigo } = this.state
        //  SE ARMAN LAS PAGINAS
        this.estructuraPaginas(tiene_codigo, fraccion, cantidad, "30")
    }

    /**
     * Funcion para cambiar el numero de copias de acurdo a
     * la opcion seleccionada
     */
    cambioCopias = (no_copias, opcion) => {
        let cantidad = 0;
        this.setState({no_copias});

        const { fraccion, tiene_codigo, cod_x_pagina } = this.state;

        //  SE CALCULA LA CANTIDAD DE CODIGOS
        if (opcion === "10") {
            cantidad = cod_x_pagina * no_copias;
        } else if (opcion === "20") {
            cantidad = (cod_x_pagina/2) * no_copias
        }
        //  SE ARMAN LAS PAGINAS
        this.estructuraPaginas(tiene_codigo, fraccion, cantidad, opcion)
    }

    /**
     * Funcion para setear la opcion y regenerar la hoja de impresion
     */
    cambioOpcion = (opcion) => {
        const {no_copias} = this.state;
        this.setState({opcion});

        if (opcion === "10" || opcion === "20") {
            this.cambioCopias(no_copias, opcion);
        } else {
            this.cambioCantidad(5);
        }
    }

    /**
     * Funcion para cambiar la fraccion y setear el codigo a imprimir
     */
    cambioFraccion = (fraccion, opcion) => {
        const tiene_codigo = fraccion.codigo_barras ? true:false;
        const { cantidad } = this.state;
        this.setState({
            fraccion,
            tiene_codigo,
        });

        this.estructuraPaginas(tiene_codigo, fraccion, cantidad, opcion);
    }

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.producto !== this.props.producto) {
            const producto = nextProps.producto;
            const { cantidad } = this.state;
            if (producto.fracciones) {
                console.log(producto.fracciones);
                if (producto.fracciones.length) {
                    const fraccion = producto.fracciones[0];
                    const tiene_codigo = producto.fracciones[0].codigo_barras ? true:false
                    this.setState({
                        fraccion,
                        tiene_fracciones: producto.tiene_fracciones,
                        tiene_codigo
                    })
                    this.estructuraPaginas(tiene_codigo, fraccion, cantidad, "10");
                }
            }
        }
    }


    render() {
        // state
        const { producto, loader, print_state } = this.props;
        const { paginas, tiene_fracciones, tiene_codigo, fraccion, cantidad, opcion, no_copias } =  this.state;
        // bind

        return (
            <div className="row">
                {print_state.flag && ( <PrintComponent/> )}
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <LoadMask loading={loader} dark blur>
                        <div className="grid-container mt1 mb1">
                            <div className="grid-titulo padding-15">Impresión de código de barras</div>
                            <div className="grid-cuerpo padding-15  p-sm-0 pt-sm-1 pb-sm-1">
                                <div className="col-sm-12 mt1 naranja">
                                    <h3><strong>{producto.nombre}</strong></h3>
                                </div>
                                {
                                    producto &&(
                                        <div>
                                            <div className="col-sm-12 form-group mt1 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start">
                                                {
                                                    tiene_fracciones ? (
                                                        <div style={{flex: "0.8", marginBottom:"15px", marginRight: "20px"}}>
                                                            <label>Fracción</label>
                                                            <div>
                                                                <Select
                                                                    isClearable
                                                                    className="form-control"
                                                                    options={producto.fracciones}
                                                                    valueKey="id"
                                                                    labelKey="nombre"
                                                                    value={fraccion}
                                                                    onChange ={(e) => {
                                                                        this.cambioFraccion(e ? e: '', opcion)
                                                                    }}/>
                                                            </div>
                                                        </div>
                                                    ): null
                                                }

                                                {/* OPCIONES */}
                                                <div className="d-flex flex-column" style={{flex: "1.6", marginBottom:"15px"}}>
                                                    <label>Opciones</label>
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                            <label className="negro-dark2 font-weight-normal">
                                                                <input name="gasto_fijo" type="radio" value="10" checked={opcion === "10"} onChange={()=>{
                                                                    this.cambioOpcion("10");
                                                                }}/>
                                                                <span />
                                                                Hoja carta
                                                            </label>
                                                        </div>
                                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                            <label className="negro-dark2 font-weight-normal">
                                                                <input name="gasto_fijo" type="radio" value="20" checked={opcion === "20"} onChange={()=>{
                                                                    this.cambioOpcion("20");
                                                                }}/>
                                                                <span />
                                                                Media carta
                                                            </label>
                                                        </div>
                                                        <div className="radio c-radio c-radio-nofont d-flex ml-10">
                                                            <label className="negro-dark2 font-weight-normal">
                                                                <input name="gasto_fijo" type="radio" value="30" checked={opcion === "30"} onChange={()=>{
                                                                    this.cambioOpcion("30");
                                                                }}/>
                                                                <span />
                                                                Personalizado
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (opcion === '10' || opcion === '20') && (
                                                        <div style={{flex: "0.8"}}>
                                                            <label>No. copias</label>
                                                            <div className="col-sm-10" style={{padding: "0"}}>
                                                                <input autocomplete="off" className="form-control text-right" type="number" value={no_copias} onChange={(e) => {
                                                                    this.cambioCopias(e.target.value, opcion);
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    opcion === '30' && (
                                                        <div style={{flex: "0.8"}}>
                                                            <label>Cantidad de códigos</label>
                                                            <div className="col-sm-10" style={{padding: "0"}}>
                                                                <input className="form-control text-right" type="number" value={cantidad} onChange={(e) => {
                                                                    this.cambioCantidad(e.target.value);
                                                                }}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="col-sm-12 form-group mt1 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start">
                                                {
                                                    <div style={{flex: "0.5", marginBottom:"15px"}}>
                                                        {
                                                            tiene_codigo ? (
                                                                <div>
                                                                    <label>Código de barras</label>
                                                                    {
                                                                        fraccion && (
                                                                            fraccion.codigo_barras.length > 20 && (
                                                                                <div className="w-100">
                                                                                    <b className="text-warning fnt-12">
                                                                                        <em className="fa fa-exclamation-triangle mr-1"></em>
                                                                                        Para una mejor impresión, el código de barras del producto debe tener un máximo de 20 caracteres.
                                                                                    </b>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    }
                                                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                                                        <Barcode value={fraccion.codigo_barras} marginBottom={2}/>
                                                                        <b style={{color:"#000", font:"16px monospace"}}>{fraccion.descripcion_codigo_barras}</b>
                                                                    </div>
                                                                </div>
                                                            ): (
                                                                <div className="text-danger">
                                                                    <strong>El producto o fracción no tiene un código de barras asignado.</strong>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    )
                                }

                                {/* FOOTER */}
                                <div className="row mb footer-container">
                                    <div className="col-sm-12 text-center">
                                        <Link className="btn btn-tertiary mr" to="/productos/"><i className="fa fa-arrow-left" aria-hidden="true" > </i> Regresar</Link>
                                        <button
                                            disabled={!tiene_codigo}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print('codigoBarrasProducto');
                                                //printActions.print('codigoBarrasProducto')
                                            }}
                                            className="btn btn-primary mt-lg"
                                            type={'button'}
                                        >
                                            <em className="fa fa-print" /> Imprimir
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* PAGINAS */}
                        <PrintContainer name='codigoBarrasProducto' className="print-space">
                            {
                                paginas.map((pagina, index)=> {
                                    return(
                                        <div key={index} className="hoja-carta print-only">
                                            <div className="d-flex flex-column imp-pb-2" style={{height: "100%"}}>
                                                {
                                                    pagina.detalle.map((fila, ind) => {
                                                        return(
                                                            <div key={ind} className="d-flex flex-row align-items-center row-codigos">
                                                                {
                                                                    fila.map((col, idx) => {
                                                                        return (
                                                                            <div key={idx} className={`d-flex flex-row justify-content-center align-items-center container-item-codigo ${idx===4 ? "pl-40": ""}`}>
                                                                                <div className="item-codigo d-flex flex-column align-items-center justify-content-center">
                                                                                    <Barcode width={1} height={60} fontSize={12} value={col.codigo_barras} marginBottom={2}/>
                                                                                    <span style={{color:"#000", font:"7px monospace"}}>{col.descripcion}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })

                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </PrintContainer>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ImprimirCodigoProducto;
