import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";

export default class Nota extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
        tipo: PropTypes.oneOf(["Ingreso", "Venta", "Baja"]).isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const {
            sucursal,
            movimiento,
            usuario,
            utiliza_fel,
            conf_mostrar_precio_impresion,
        } = this.props;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const datos_fel = movimiento ? movimiento.datos_fel : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const { footerText, tipo } = this.props;
        let total_baja = 0;
        let total_baja_impresion = 0;

        return (
            <div
                className={`print-shadow ${
                    tipo == "Venta" ? "vista-recibo-80mm" : ""
                }`}
            >
                <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                    {utiliza_fel ? (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                {datos_empresa.nombre_comercial}
                            </b>
                        </div>
                    ) : (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                <img
                                    src={require("../../../../../assets/img/remocar-logo.png")}
                                    width="25%"
                                />
                            </div>
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    {datos_empresa.nombre}
                                </b>
                            </div>
                        </div>
                    )}
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.nombre}</b>
                    </div>
                    <div className="w-100 text-center">
                        <b className="text-uppercase">{sucursal.direccion}</b>
                    </div>
                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            <b className="text-uppercase">
                                Tel. {sucursal.telefono}
                            </b>
                        </div>
                    )}
                    {utiliza_fel && (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                <b className="text-uppercase">
                                    Nit. {datos_empresa.nit}
                                </b>
                            </div>
                        </div>
                    )}

                    {tipo && (
                        <div className="w-100 text-center text-uppercase">
                            {tipo}
                        </div>
                    )}
                </div>
                {utiliza_fel && datos_fel && (
                    <div className="w-100">
                        {datos_fel.estado_factura == FACTURADO && (
                            <div className="w-100">
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column">
                                        <b>Serie</b>
                                        {datos_fel.serie ? datos_fel.serie : ""}
                                    </div>
                                    <div className="flex1 d-flex flex-column">
                                        <b>Doc. No.</b>
                                        {datos_fel.numero
                                            ? datos_fel.numero
                                            : ""}
                                    </div>
                                </div>
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column">
                                        <b>UUID</b>
                                        {datos_fel.uuid ? datos_fel.uuid : ""}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className="d-flex flex-column justify-content-between my-4">
                    <div className="d-flex flex-row fnt-12 mb05">
                        {tipo === "Venta" && (
                            <div className="flex1 d-flex flex-column">
                                <b>Código de venta</b>
                                {movimiento.codigo_venta
                                    ? movimiento.codigo_venta
                                    : ""}
                            </div>
                        )}
                        <div className="flex1 d-flex flex-column">
                            <b>Fecha y hora</b>
                            <RenderDateTime value={movimiento.creado} />
                        </div>
                        {tipo === "Salida De Producto" && (
                            <div className="flex1 d-flex flex-column">
                                <b>Destino de salida</b>
                                {movimiento.destino_salida
                                    ? movimiento.destino_salida
                                    : ""}
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-row fnt-12 mb05">
                        <div className="flex1 d-flex flex-column">
                            <b>Concepto</b>
                            {movimiento.concepto}
                        </div>
                    </div>
                    <div className="d-flex flex-row fnt-12 mb">
                        {usuario ? (
                            <div className="flex1 d-flex flex-column">
                                <b>Usuario</b>
                                {usuario}
                            </div>
                        ) : null}
                    </div>

                    {movimiento.usuario && tipo == "Baja" && (
                        <div className="d-flex flex-row fnt-12 mb05">
                            <div className="flex1 d-flex flex-column">
                                <b>Usuario</b>
                                {movimiento.usuario.first_name}
                            </div>
                        </div>
                    )}

                    {movimiento.proveedor && tipo == "Baja" && (
                        <div className="d-flex flex-row fnt-12 mb05">
                            <div className="flex1 d-flex flex-column">
                                <b>Proveedor</b>
                                {movimiento.proveedor}
                            </div>
                        </div>
                    )}

                    {movimiento.numero_documento && tipo == "Baja" && (
                        <div className="d-flex flex-row fnt-12 mb05">
                            <div className="flex1 d-flex flex-column">
                                <b>Numero de documento</b>
                                {movimiento.numero_documento}
                            </div>
                        </div>
                    )}

                    {movimiento.numero_documento && tipo == "Baja" && (
                        <div className="d-flex flex-row fnt-12 mb05">
                            <div className="flex1 d-flex flex-column">
                                <b>Correlativo</b>
                                {movimiento.codigo}
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className="d-flex flex-row justify-content-between fnt-16"
                    style={{ borderTop: "dotted 1px", paddingTop: "10px" }}
                >
                    <b className="flex-1 mx-1">CÓDIGO</b>
                    <b className="flex-1 mx-1">ARTÍCULOS</b>
                    <b className="flex1 mx-1 text-right">
                        {tipo === "Reajuste" ? "CANTIDAD ANTES" : ""}
                    </b>
                    {tipo == "Baja" && (
                        <b className="flex1 mx-1 text-right">COSTO</b>
                    )}
                    <b className="flex1 mx-1 text-right">
                        {tipo === "Reajuste" ? "CANTIDAD DESPUÉS" : "CANTIDAD"}
                    </b>
                    {tipo == "Baja" && (
                        <b className="flex1 mx-1 text-right">SUBTOTAL</b>
                    )}
                    {tipo == "Baja" && conf_mostrar_precio_impresion && (
                        <b className="flex1 mx-1 text-right">SUBTOTAL</b>
                    )}
                </div>
                {detalles &&
                    detalles.map((detalle) => {
                        total_baja += parseFloat(
                            (detalle.precio * detalle.cantidad).toFixed(2)
                        );
                        total_baja_impresion += parseFloat(
                            (detalle.costo * detalle.cantidad).toFixed(2)
                        );
                        return (
                            <div className="d-flex mb fnt-11" key={detalle.id}>
                                {!detalle.es_vale ? (
                                    <div className="width100">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                <div
                                                    className={
                                                        tipo == "Baja"
                                                            ? "flex-1 px-2"
                                                            : "flex-1 px-2"
                                                    }
                                                >
                                                    {`
                                                        ${
                                                            detalle.fraccion
                                                                .producto.codigo
                                                                ? detalle
                                                                      .fraccion
                                                                      .producto
                                                                      .codigo
                                                                : ""
                                                        }
                                                        `}
                                                    {tipo == "Baja" &&
                                                        conf_mostrar_precio_impresion && (
                                                            <div>
                                                                <span className="mr-2">
                                                                    Precio:
                                                                </span>
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.precio
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                                <div
                                                    className={
                                                        tipo == "Baja"
                                                            ? "flex-2 px-2"
                                                            : "flex-1 px-2"
                                                    }
                                                >
                                                    {detalle.producto}
                                                </div>
                                                {tipo == "Baja" && (
                                                    <div className="flex-1 mr-2 text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.costo
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {tipo === "Reajuste" ? (
                                                    <React.Fragment>
                                                        <div className="flex-1 px-2 text-right">
                                                            {detalle.fraccion
                                                                .producto
                                                                .a_granel ? (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.existencias_antes
                                                                    }
                                                                    decimalScale={
                                                                        3
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            ) : (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.existencias_antes
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="flex-1 px-2 text-right">
                                                            {detalle.fraccion
                                                                .producto
                                                                .a_granel ? (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.existencias_despues
                                                                    }
                                                                    decimalScale={
                                                                        3
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            ) : (
                                                                <RenderNumber
                                                                    value={
                                                                        detalle.existencias_despues
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="flex-1 px-2 text-right">
                                                        {detalle.fraccion
                                                            .producto
                                                            .a_granel ? (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                decimalScale={3}
                                                                className={
                                                                    "ml-auto"
                                                                }
                                                            />
                                                        ) : (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                decimalScale={2}
                                                                className={
                                                                    "ml-auto"
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {tipo == "Baja" &&
                                                    conf_mostrar_precio_impresion && (
                                                        <div className="flex-1 px-2 text-right">
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.precio *
                                                                    detalle.cantidad
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                                {tipo == "Baja" && (
                                                    <div className="flex-1 mr-2 text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.costo *
                                                                detalle.cantidad
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* region DESCUENTOS SOBRE CADA PRODUCTO */}
                                        {detalle.precio_original &&
                                            detalle.desc_porcentaje > 0 && (
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex flex-row pl-20">
                                                        Descuento (
                                                        {
                                                            detalle.desc_porcentaje
                                                        }
                                                        %)
                                                    </div>
                                                    <div className="d-flex flex-row pl-20">
                                                        <span className="mr-1">
                                                            -{" "}
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.desc_monto
                                                                }
                                                            />
                                                        </span>
                                                        <div className="mr-1">
                                                            X
                                                        </div>
                                                        {detalle.fraccion
                                                            .producto
                                                            .a_granel ? (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                decimalScale={3}
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        ) : (
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        )}
                                                        <span className="ml-auto">
                                                            -{" "}
                                                            <RenderCurrency
                                                                value={
                                                                    detalle.cantidad *
                                                                    detalle.desc_monto
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        {/* endregion DESCUENTOS SOBRE CADA PRODUCTO */}
                                    </div>
                                ) : (
                                    <div className="width100">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row">
                                                Vale de descuento{" "}
                                                {detalle.vale.codigo}
                                            </div>
                                            <div className="d-flex flex-row">
                                                <RenderCurrency
                                                    value={
                                                        detalle.sub_total * -1
                                                    }
                                                />{" "}
                                                X 1
                                                <RenderCurrency
                                                    value={
                                                        detalle.sub_total * -1
                                                    }
                                                    className={"ml-auto"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                {movimiento.reciduo_redondeo != 0 && (
                    <div className="width100 mb">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex3 flex-row">
                                Redondeo
                            </div>
                            <div className="d-flex flex1 flex-row align-items-center justify-content-end">
                                <RenderCurrency
                                    value={movimiento.reciduo_redondeo}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {tipo === "Venta" && (
                    <div className="fnt-16">
                        {movimiento.tipo_pago == pago_total && (
                            <div className="d-flex flex-row justify-content-between">
                                <b>PAGO RECIBIDO</b>
                                <b>
                                    <RenderCurrency
                                        value={
                                            movimiento.monto_efectivo +
                                            movimiento.monto_tarjeta
                                        }
                                        className={"ml-auto"}
                                    />
                                </b>
                            </div>
                        )}
                        {movimiento.vuelto_efectivo !== null &&
                            movimiento.vuelto_efectivo !== 0 &&
                            movimiento.tipo_pago == pago_total && (
                                <div className="d-flex flex-row justify-content-between">
                                    <b>VUELTO</b>
                                    <b>
                                        <RenderCurrency
                                            value={movimiento.vuelto_efectivo}
                                            className={"ml-auto"}
                                        />
                                    </b>
                                </div>
                            )}
                        {/* {utiliza_fel ? (
                            <div className="w-100"></div>
                        ) : (
                            <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
                                <span>ESTE DOCUMENTO</span>
                                <span>NO ES UNA FACTURA</span>
                            </div>
                        )} */}
                    </div>
                )}
                {tipo == "Baja" && conf_mostrar_precio_impresion && (
                    <div className="fnt-16">
                        <div className="d-flex flex-row justify-content-between">
                            <b>TOTAL</b>
                            <b>
                                <RenderCurrency
                                    value={total_baja != 0 ? total_baja : 0}
                                />
                            </b>
                        </div>
                    </div>
                )}
                {tipo == "Baja" && (
                    <div className="fnt-16">
                        <div className="d-flex flex-row justify-content-between">
                            <b>TOTAL</b>
                            <b>
                                <RenderCurrency
                                    value={
                                        total_baja_impresion != 0
                                            ? total_baja_impresion
                                            : 0
                                    }
                                />
                            </b>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
