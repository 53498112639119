import React, { useEffect, useState } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";

import { TablaOC } from "./ComponentesListadoOC";
import moment from "moment";
import DatePicker from "Utils/DatePicker";

import { Async } from "react-select";
import useAsyncSearch from "../../../hooks/useAsyncSearch";
import { TIPO_MOVIMIENTO_CP_SIMPLIFICADO } from "../../../../utility/variables";
import Select from "react-select/lib/Select";

import { RenderCurrency } from "Utils/renderField/renderReadField";
import ModalCobros from "./ModalCobros";
import ModalFacturar from "./ModalFacturar";

const listado_clientes = [];
const listado_usuarios = [];
const ListadoOrdenCompra = (props) => {
    const [totalPagar, setTotalPagar] = useState(0);
    const [disabledModal, setdisabledModal] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        openSecond: false,
        data: {},
    });

    useEffect(() => {
        props.listarOC();
    }, []);

    // State
    const {
        tab,
        datos_listado,
        loader,
        search_oc,
        tipo_movimiento,
        cliente,
        cuenta_pagar,
        seleccionados,
        vendedor,
    } = props;
    // Bind
    const {
        changeTab,
        listarOC,
        sortChangeOC,
        buscarOC,
        setFecha,
        setFechaOCF,
        estado_descarga,
        estado_descarga2,
        descargarListadoOCA,
        descargarListadoOCF,
        fecha_compra_final,
        fecha_compra_inicial,
        fecha_final_compra_finalizada,
        fecha_inicial_compra_finalizada,
        setTipoMovimientoValue,
        setClienteValue,
        setCuentasPagar,
        setSeleccionadosValue,
        setVendedor,
        limpiar,
    } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page_oc;
    options.onPageChange = listarOC;
    options.onSortChange = sortChangeOC;

    const { getOptions: getCliente } = useAsyncSearch(
        "clientes",
        listado_clientes
    );

    const { getOptions: getUsuarios } = useAsyncSearch(
        "usuarios",
        listado_usuarios,
        { es_vendedor: 1 }
    );

    useEffect(() => {
        if (cuenta_pagar.length > 0) {
            // Total a pagar, segun las cuentas seleccionadas
            const total = cuenta_pagar.reduce((acc, item) => {
                return acc + item.saldo;
            }, 0);

            setdisabledModal(false);

            setTotalPagar(total);
        } else {
            setdisabledModal(true);
            setTotalPagar(0);
        }
    }, [cuenta_pagar, datos_listado]);

    useEffect(() => {
        return () => {
            limpiar();
        };
    }, []);

    return (
        <div className="row mt">
            {/* Modal Cambiar datos para la facturacion */}
            <ModalFacturar modal={modal} setModal={setModal} />
            <div className="col-12">
                {/* <Link className="btn btn-primary" to="/ordenes_de_compra/nueva/"><em className="fa fa-plus"></em> Agregar Orden Compra</Link> */}
                <div className="grid-container">
                    <div className="grid-titulo">
                        <Toolbar
                            titulo={"Listado"}
                            buscar={buscarOC}
                            search={search_oc}
                        />
                    </div>
                    <div className="my-3">
                        <div className="d-flex flex-column flex-md-row">
                            <div className="flex-3">
                                <label htmlFor="">Fecha inicial</label>
                                <DatePicker
                                    value={fecha_compra_inicial}
                                    maxDate={
                                        new Date(
                                            moment(
                                                moment().format("YYYY-MM-DD"),
                                                "YYYY-MM-DD"
                                            )
                                        )
                                    }
                                    onChange={(date) => {
                                        setFecha("Inicial", date);
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                <label htmlFor="">Fecha final</label>
                                <DatePicker
                                    value={fecha_compra_final}
                                    minDate={
                                        new Date(
                                            moment(
                                                fecha_compra_inicial,
                                                "YYYY-MM-DD"
                                            )
                                        )
                                    }
                                    onChange={(date) => {
                                        setFecha("Final", date);
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                <label>Tipo Movimiento</label>
                                <Select
                                    placeholder="Seleccionar"
                                    isClearable
                                    className="form-control"
                                    options={TIPO_MOVIMIENTO_CP_SIMPLIFICADO}
                                    valueKey="value"
                                    labelKey="label"
                                    value={tipo_movimiento}
                                    onChange={(e) => {
                                        if (e) {
                                            return setTipoMovimientoValue(
                                                e.value
                                            );
                                        }
                                        setTipoMovimientoValue("");
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                <label>Cliente</label>
                                <Async
                                    className="form-control"
                                    labelKey="nombre"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getCliente}
                                    placeholder={"Cliente"}
                                    value={cliente}
                                    onChange={(e) => {
                                        setClienteValue(e);
                                    }}
                                />
                            </div>
                            <div className="flex-1"></div>

                            <div className="flex-3">
                                <label>Vendedor</label>
                                <Async
                                    className="form-control"
                                    labelKey="first_name"
                                    valueKey="id"
                                    searchPromptText="Escriba para buscar"
                                    loadOptions={getUsuarios}
                                    placeholder={"Vendedor"}
                                    onChange={(e) => {
                                        setVendedor(e);
                                    }}
                                    value={vendedor}
                                />
                            </div>
                            <div className="flex-1"></div>
                        </div>
                        {/* <button
                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                            disabled={estado_descarga}
                            onClick={() => {
                                descargarListadoOCA();
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-download"
                                }`}
                            />
                            &nbsp;Descargar Listado
                        </button> */}
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <TablaOC
                            loader={loader}
                            datos_listado={datos_listado}
                            options={options}
                            anular={props.anularODC}
                            cuenta_pagar={cuenta_pagar}
                            setCuentasPagar={setCuentasPagar}
                            seleccionados={seleccionados}
                            setSeleccionados={setSeleccionadosValue}
                            setModal={setModal}
                        />
                    </LoadMask>
                    <div className="d-flex justify-content-between">
                        <ModalCobros
                            total={totalPagar}
                            disabled_modal={disabledModal}
                        />
                        <div className="text-right">
                            <span>Monto total a pagar</span>
                            <h3>
                                <b>
                                    <RenderCurrency
                                        value={totalPagar}
                                        className="text-primary"
                                    />
                                </b>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListadoOrdenCompra;
