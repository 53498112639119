import React, { useEffect, useState } from "react";

import DetailProductForm from "./detailProductForm";

const DetailProduct = ({
    producto,
    leer,
    selected,
    setSelected,
    verificarContrasena,
    cambiarPrecioCosto,
    removerItem,
    closeModalProducts,
}) => {
    useEffect(() => {
        if (selected && selected.product) {
            leer(selected.product);
        }

        return () => {
            setSelected({
                view: "LIST",
                product: undefined,
            });
            removerItem();
        };
    }, []);

    return (
        <React.Fragment>
            <div className="grid-titulo padding-3">Detalle de producto</div>
            <div className="row">
                <div className="col-12">
                    <DetailProductForm
                        closeModalProducts={closeModalProducts}
                        cambiarPrecioCosto={cambiarPrecioCosto}
                        verificarContrasena={verificarContrasena}
                        producto={producto}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default DetailProduct;
