import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";
import { NumerosALetras } from "../../../../utility/convertToLetters";
import { ID_DPI, ID_NIT } from "../../../../utility/variables";

export default class TicketOV extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
        footerText: PropTypes.string.isRequired,
        tipo: PropTypes.oneOf(["Ingreso", "Venta"]).isRequired,
    };
    constructor(props) {
        super(props);
    }

    render() {
        const {
            sucursal,
            movimiento,
            usuario,
            utiliza_fel,
            conf_habilitar_no_factura,
            orden_compra_data = false,
            pagina,
        } = this.props;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const datos_fel = movimiento ? movimiento.datos_fel : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const { footerText, tipo } = this.props;
        let total = 0;

        const tiene_descuento = parseFloat(detalles[0].desc_porcentaje) > 0;
        const porcentaje_descuento = tiene_descuento
            ? parseFloat(detalles[0].desc_porcentaje) / 100
            : 0;
        const es_venta_al_costo = movimiento.venta_costo;
        const tiene_vuelto = movimiento.vuelto_efectivo > 0;
        const pago_recibido = movimiento.pago_recibido > 0;

        const total_facturado =
            movimiento && movimiento.total_facturado
                ? movimiento.total_facturado[pagina - 1]
                : 0;

        const es_agente_retenedor_iva = sucursal.es_agente_retenedor_iva;

        const no_doc_cliente = movimiento.no_doc_cliente;
        const nombre_cliente = movimiento.nombre_cliente;
        const tipo_documento_cliente = movimiento.tipo_documento_cliente;

        console.log("tipo_documento_cliente", movimiento);
        const datos_cliente_cambiados =
            no_doc_cliente && nombre_cliente ? true : false;
        return (
            <div
                className={`print-shadow ${
                    tipo == "Venta" ? "vista-recibo-80mm" : ""
                }`}
            >
                <div className="mt mb d-flex flex-column justify-content-center align-items-center font-weight-bold fnt-12">
                    {datos_empresa.logo_ticket && tipo == "Venta" && (
                        <img
                            className="mb-3"
                            src={datos_empresa.logo_ticket}
                            width="100"
                        />
                    )}
                    <div className="w-100 text-center">
                        <div className="w-100 text-center">
                            <img
                                src={require("../../../../../assets/img/remocar-logo.png")}
                                width="25%"
                            />
                        </div>
                        <div className="text-uppercase font-weight-bold">
                            {datos_empresa.nombre_comercial
                                ? datos_empresa.nombre_comercial.toUpperCase()
                                : ""}
                        </div>
                        {/* <div className="text-uppercase font-weight-bold">
                            {sucursal.nombre_comercial
                                ? sucursal.nombre_comercial.toUpperCase()
                                : ""}
                        </div> */}
                        <div className="text-uppercase font-weight-bold">
                            {movimiento.nombre_emisor}
                        </div>
                        <div className="text-uppercase font-weight-bold">
                            NIT: {movimiento.nit_emisor}
                        </div>
                        <div className="text-uppercase font-weight-bold">
                            {movimiento.direccion_exacta_emisor}
                        </div>
                        <div>
                            {movimiento.direccion_emisor}
                        </div>
                    </div>

                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center font-weight-normal">
                            Tels. {sucursal.telefono}
                        </div>
                    )}
                    <div className="w-100 text-center font-weight-normal">
                        EMAIL:
                        ventas@remocar.com.gt&nbsp;|&nbsp;wwww.remocar.com.gt
                    </div>

                    {utiliza_fel && (
                        <div className="w-100 text-center">
                            <div className="w-100 text-center">
                                Nit.
                                {datos_empresa.nit}
                            </div>
                        </div>
                    )}

                    {(sucursal.telefono || sucursal.telefono != "") && (
                        <div className="w-100 text-center">
                            FECHA Y HORA{" "}
                            <span className="font-weight-normal">
                                {utiliza_fel && datos_fel ? (
                                    <RenderDateTime
                                        value={datos_fel.fecha_emision}
                                    />
                                ) : (
                                    <RenderDateTime value={movimiento.creado} />
                                )}
                            </span>
                        </div>
                    )}
                    {movimiento.serie && (
                        <div>
                            <div className="font-weight-bold mt-3">
                                {
                                    movimiento.datos_fel ?
                                        'DOCUMENTO TRIBUTARIO ELECTRÓNICO - FEL'
                                    :
                                        movimiento.tipo_venta.toUpperCase()
                                }
                            </div>

                            <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">Serie</span>
                                <span>
                                    {(movimiento.serie &&
                                        movimiento.serie[pagina - 1]) ||
                                        "---"}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <span className="font-weight-bold">No.</span>
                                <span>
                                    {(movimiento.numero &&
                                        movimiento.numero[pagina - 1]) ||
                                        "---"}
                                </span>
                            </div>

                            {/* <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                        Autorización
                                    </span>
                                    <span> {movimiento.uuid || "---"}</span>
                                </div> */}
                            <div className="font-weight-bold d-flex justify-content-between">
                                <span>Correlativo:</span>
                                <span className="font-weight-normal">
                                    {movimiento && movimiento.codigo_venta}
                                </span>
                            </div>

                            <div className="item">
                                <b className="mr-2">Documento POS:</b>
                                <span className="font-weight-normal">
                                    {movimiento.POS || "---"}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {utiliza_fel && datos_fel && (
                    <div className="w-100">
                        {datos_fel.estado_factura == FACTURADO && (
                            <div className="w-100">
                                <div className="d-flex flex-row fnt-12 mb05">
                                    <div className="flex1 d-flex flex-column">
                                        <b>Serie</b>
                                        {datos_fel.serie ? datos_fel.serie : ""}
                                    </div>
                                    <div className="flex1 d-flex flex-column">
                                        <b>Número</b>
                                        {datos_fel.numero
                                            ? datos_fel.numero
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* {movimiento.no_factura && conf_habilitar_no_factura ? (
                    <div className="d-flex flex-column fnt-12 mb">
                        <div className="flex1 d-flex flex-column">
                            <b>No. Factura</b>
                            {movimiento.no_factura}
                        </div>
                    </div>
                ) : (
                    ""
                )} */}
                <div className="d-flex flex-row fnt-12 mb05">
                    {tipo !== "Ingreso" && (
                        <div className="flex1 d-flex flex-column">
                            <b>Tipo de venta</b>
                            {movimiento.tipo_venta}
                        </div>
                    )}
                </div>
                <div className="d-flex flex-row fnt-12 mb05">
                    {tipo !== "Ingreso" && (
                        <div className="flex1 d-flex flex-column">
                            <b>Cliente</b>
                            {datos_cliente_cambiados
                                ? nombre_cliente
                                : movimiento.nombre_cambiado
                                ? movimiento.nombre_cambiado
                                : movimiento.cliente_proveedor
                                ? movimiento.cliente_proveedor.nombre
                                    ? movimiento.cliente_proveedor.nombre
                                    : "-----"
                                : "-----"}
                        </div>
                    )}
                    {tipo === "Ingreso" && (
                        <div className="flex1 d-flex flex-column">
                            <b>Proveedor</b>
                            {orden_compra_data
                                ? orden_compra_data.proveedor.nombre
                                : ""}
                        </div>
                    )}
                    <div className="flex1 d-flex flex-column">
                        <b>
                            {tipo_documento_cliente === ID_NIT
                                ? "NIT"
                                : tipo_documento_cliente === ID_DPI
                                ? "DPI "
                                : "DOC EXT "}
                        </b>
                        {datos_cliente_cambiados
                            ? no_doc_cliente
                            : movimiento.no_doc_cambiado
                            ? movimiento.no_doc_cambiado
                            : movimiento.cliente_proveedor
                            ? movimiento.cliente_proveedor.nit
                                ? movimiento.cliente_proveedor.nit
                                : "CF"
                            : "CF"}
                    </div>
                </div>
                <div className="d-flex flex-row fnt-12 mb">
                    <div className="flex1 d-flex flex-column mb05">
                        <b>Dirección</b>
                        {movimiento.cliente_proveedor
                            ? movimiento.cliente_proveedor.direccion
                                ? movimiento.cliente_proveedor.direccion
                                : "Ciudad"
                            : "Ciudad"}
                    </div>
                    {tipo === "Ingreso" && (
                        <div className="flex1 d-flex flex-column">
                            <b>Realizado por</b>
                            {orden_compra_data
                                ? orden_compra_data.emitida_por.first_name
                                : ""}
                        </div>
                    )}
                    {usuario ? (
                        <div className="flex1 d-flex flex-column">
                            <b>Vendedor</b>
                            {usuario}
                        </div>
                    ) : null}
                </div>
                {movimiento.referencia ? (
                    <div className="d-flex flex-column fnt-12 mb">
                        <div className="flex1 d-flex flex-column mb05">
                            <b>Referencia</b>
                            {movimiento.referencia}
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {/* //** ---------------BODY */}
                <div
                    className={`grid-ticket ${tiene_descuento ? "grid-4" : ""}`}
                >
                    <b className="flex-1 pl-2 pr-2">CÓDIGO</b>
                    <b className="flex-2">ARTÍCULOS</b>
                    {tiene_descuento && <b className="flex-1">DESCUENTO</b>}
                    <b className="flex-2 d-flex justify-content-end">
                        SUB TOTAL
                    </b>

                    {detalles.map((detalle, key) => {
                        const precio_descuento =
                            detalle.precio_original -
                            detalle.precio_original * porcentaje_descuento;

                        const end = pagina * 15;
                        const start = end - 15;

                        const index = key + 1;
                        if (index > start && index <= end) {
                            return (
                                <React.Fragment key={detalle.id}>
                                    {!detalle.es_vale && (
                                        <React.Fragment>
                                            {/* //** -------------------CODIGO */}
                                            <div className="flex-1 d-flex justify-content-start pl-2 pr-2 fnt-11">
                                                {detalle.fraccion.producto
                                                    ? `${detalle.fraccion.producto.codigo}`
                                                    : ""}
                                            </div>

                                            {/* //** --------------- ARTICULOS */}
                                            <div className="flex-2 d-flex flex-column fnt-11">
                                                <span>{detalle.producto}</span>
                                                <div className="d-flex flex-row max-size-txt">
                                                    <RenderCurrency
                                                        value={
                                                            es_venta_al_costo
                                                                ? detalle.costo
                                                                : detalle.precio_original &&
                                                                  detalle.desc_porcentaje
                                                                ? detalle.precio_original
                                                                : detalle.precio
                                                        }
                                                        className={"mr-1"}
                                                    />
                                                    <div className="mr-1">
                                                        X
                                                    </div>
                                                    {detalle.fraccion.producto
                                                        .a_granel ? (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            decimalScale={3}
                                                            className={"mr-1"}
                                                        />
                                                    ) : (
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            {/* //** -------------------DESCUENTO */}
                                            {tiene_descuento && (
                                                <div className="flex-1 fnt-11">
                                                    Q.{" "}
                                                    {detalle.precio_original *
                                                        porcentaje_descuento}
                                                </div>
                                            )}

                                            {/* //** -------------------SUB TOTAL */}
                                            <div className="flex-2 d-flex justify-content-end align-items-end fnt-11">
                                                {/* {detalle.precio_original &&
                                                detalle.desc_porcentaje ? (
                                                    <RenderCurrency
                                                        value={
                                                            detalle.cantidad *
                                                            (es_venta_al_costo
                                                                ? detalle.costo
                                                                : tiene_descuento
                                                                ? precio_descuento
                                                                : detalle.precio_original)
                                                            // detalle.precio_original
                                                        }
                                                        className={"ml-auto"}
                                                    />
                                                ) : (
                                                    <RenderCurrency
                                                        value={
                                                            detalle.cantidad *
                                                            (es_venta_al_costo
                                                                ? detalle.costo
                                                                : tiene_descuento
                                                                ? precio_descuento
                                                                : detalle.precio_original)
                                                            // detalle.precio_original
                                                        }
                                                        className={"ml-auto"}
                                                    />
                                                )} */}
                                                {detalle.precio_original &&
                                                detalle.desc_porcentaje ? (
                                                    <RenderCurrency
                                                        value={
                                                            detalle.cantidad *
                                                            detalle.precio_original
                                                        }
                                                        className={"ml-auto"}
                                                    />
                                                ) : (
                                                    <RenderCurrency
                                                        value={
                                                            detalle.sub_total
                                                        }
                                                        className={"ml-auto"}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            );
                        }
                    })}
                </div>

                <div
                    className="d-flex flex-row justify-content-between fnt-12"
                    style={{ borderTop: "dotted 1px", paddingTop: "15px" }}
                >
                    <b>{footerText}</b>
                    <b>
                        <RenderCurrency
                            value={total_facturado}
                            className={"ml-auto"}
                        />
                    </b>
                </div>
                {tipo === "Venta" && (
                    <div className="fnt-12">
                        {movimiento.tipo_pago == pago_total ? (
                            <div className="d-flex flex-row justify-content-between">
                                {pago_recibido && (
                                    <React.Fragment>
                                        <b>PAGO RECIBIDO</b>
                                        <b>
                                            <RenderCurrency
                                                value={
                                                    total_facturado +
                                                        movimiento.vuelto_efectivo ||
                                                    0
                                                }
                                                className={"ml-auto"}
                                            />
                                        </b>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex flex-row justify-content-between">
                                <b>ANTICIPO</b>
                                <b>
                                    <RenderCurrency
                                        value={movimiento.pago_recibido}
                                        className={"ml-auto"}
                                    />
                                </b>
                            </div>
                        )}
                        {tiene_vuelto && movimiento.tipo_pago == pago_total && (
                            <div className="d-flex flex-row justify-content-between">
                                <b>VUELTO</b>
                                <b>
                                    <RenderCurrency
                                        value={movimiento.vuelto_efectivo}
                                        className={"ml-auto"}
                                    />
                                </b>
                            </div>
                        )}

                        {/* {utiliza_fel ? (
                            <div className="w-100 mt-3">
                                {datos_fel && (
                                    <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                        {datos_fel.frase_fel}
                                    </div>
                                )}
                                {datos_fel &&
                                    (datos_fel.tipo == "FCAM" ||
                                        datos_fel.tipo == "FCAP") && (
                                        <div className="d-flex flex-row fnt-12 mb05">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>COMPLEMENTOS</b>
                                                {datos_fel.tipo == "FCAM" ? (
                                                    <p className="text-uppercase">
                                                        Abonos de Factura
                                                        Cambiaria
                                                    </p>
                                                ) : (
                                                    <p className="text-uppercase">
                                                        Abonos de Factura
                                                        Cambiaria de Pequeño
                                                        Contribuyente
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {datos_fel && (
                                    <div className="w-100">
                                        <div className="d-flex flex-row fnt-12 mb05">
                                            <div className="flex1 d-flex flex-column align-items-center text-center">
                                                <b>AUTORIZACIÓN FEL</b>
                                                {datos_fel.uuid
                                                    ? datos_fel.uuid
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row fnt-12">
                                            <div className="flex1 d-flex flex-column align-items-center">
                                                <b>FECHA CERTIFICACIÓN</b>
                                                <RenderDateTime
                                                    value={
                                                        datos_fel.fechaCertificacion
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
        
                                <div className="d-flex flex-row fnt-12 mt-3">
                                    <div className="flex1 d-flex flex-column align-items-center">
                                        <b>CERTIFICADOR</b>
                                        INFILE, S.A. -- NIT: 12521337
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column fnt-14 align-items-center text-center mt-5 mb-2">
 
                            </div>
                        )} */}

                        <div className="d-flex justify-content-center">
                            SUJETO A PAGOS TRIMESTRALES ISR
                            {es_agente_retenedor_iva
                                ? " / AGENTE RETENEDOR DE IVA"
                                : ""}
                        </div>

                        <div className="info-ov">
                            <div className="info-ov-item">
                                <div className="title"></div>
                                <div className="info-ov-item-body">
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="footer-text flex3 ">
                                            <div>
                                                {movimiento &&
                                                    movimiento.paginas && (
                                                        <React.Fragment>
                                                            <b className="mr-2">
                                                                EN LETRAS:
                                                            </b>{" "}
                                                            <span>
                                                                {NumerosALetras(
                                                                    total_facturado
                                                                )}
                                                            </span>{" "}
                                                        </React.Fragment>
                                                    )}
                                            </div>
                                            {/* <div>
                                                <b className="mr-2">NOTAS:</b>
                                                <span>
                                                    LA COMPANIA SE RESERVA EL
                                                    DERECHO DE ACEPTAR O NO
                                                    DEVOLUCIONES DE MERCADERIA
                                                    QUE AMPARE ESTA FACTURA, LAS
                                                    QUE DE NINGUNA MANERA SERAN
                                                    ACEPTADAS DESPUES DE 5 DIAS
                                                    DE DE LA FECHA DE LA MISMA.
                                                    TODA DEVOLUCION QUEDA SUJETA
                                                    A UN 10% DE DESCUENTO SI
                                                    ESTA FACTURA NO ESTA
                                                    CANCELADA EN LA FECHA
                                                    INDICADA. DEVENGARA UN 3% DE
                                                    INTERES MENSUAL.
                                                </span>
                                            </div> */}
                                            <div>
                                                <b className="mr-2">
                                                    CERTIFICADOR: MEGAPRINT,
                                                    S.A. NIT: 50510231
                                                </b>
                                            </div>
                                            <div>
                                                <b className="mr-2">
                                                    AUTORIZACION:{" "}
                                                    {(movimiento.uuid &&
                                                        movimiento.uuid[
                                                            pagina - 1
                                                        ]) ||
                                                        "---"}
                                                </b>
                                            </div>
                                            <div>
                                                <b className="mr-2">
                                                    FECHA CERTIFICACIÓN:{" "}
                                                    {(movimiento.fecha_certificacion &&
                                                        movimiento
                                                            .fecha_certificacion[
                                                            pagina - 1
                                                        ]) ||
                                                        "---"}
                                                </b>
                                            </div>
                                        </div>

                                        {/* <div className="item text-right total-ov font-weight-bold">
                                            {movimiento && movimiento.paginas && (
                                                <React.Fragment>
                                                    TOTAL &nbsp;
                                                    <RenderCurrency
                                                        value={total_facturado}
                                                        className={"ml-auto"}
                                                    />
                                                </React.Fragment>
                                            )}
                                            {tiene_descuento &&
                                            !movimiento.venta_costo ? (
                                                <div>
                                                    DESCUENTO APLICADO:{" "}
                                                    {
                                                        movimiento.porcentaje_descuento_recargo
                                                    }
                                                    %
                                                </div>
                                            ) : (
                                                movimiento.venta_costo && (
                                                    <div>
                                                        VENTA EFECTUADA AL COSTO
                                                    </div>
                                                )
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
