import React, { useEffect, useState } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";

import ListadoOV from "../ListadoOV";
import ModalDescuentos from "../ModalDescuentos";

const CodigoFactura = (props) => {
    const [modalDescuento, setModalDescuento] = useState({
        open: false,
        data: {},
    });

    useEffect(() => {
        props.limpiarDatos();
    }, []);

    const { regresar } = props;
    return (
        <React.Fragment>
            <ModalDescuentos
                modal={modalDescuento}
                setModal={setModalDescuento}
            />
            <div className="col-12">
                <div className="grid-container p-1 p-sm-3 p-md-5 d-flex flex-column justify-content-center align-items-center">
                    {/* <h3
                            className="font-weight-bold"
                            style={{ marginRight: "35px" }}
                        >
                            Seleccione la Orden de venta a devolver
                        </h3> */}
                    <LoadMask loading={props.loader} blur light>
                        <ListadoOV
                            modalDescuento={modalDescuento}
                            setModalDescuento={setModalDescuento}
                        />
                    </LoadMask>

                    <div className="d-flex justify-content-start w-100">
                        <button
                            className="btn btn-outline-primary m-1"
                            onClick={regresar}
                        >
                            <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                            />{" "}
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CodigoFactura;
