import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { RenderCurrency } from "Utils/renderField/renderReadField";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReportes } from "../../../Utils/FiltrosReportes";
import ReporteContadoTabla from "./ReporteContadoTabla";
import ReporteCreditoTabla from "./ReporteCreditoTabla";

const ListadoComisiones = ({
    //state
    loader,
    fecha_vencimiento_inicial,
    fecha_vencimiento_final,
    estado_descarga,

    cliente,
    vendedor,
    tipo_movimiento,
    total_comisiones,

    // bind
    setFecha,
    descargarListado,
    aplicarFiltros,
    setDataValue,

    setCliente,
    setVendedor,
    setTipoMovimiento,
}) => {
    useEffect(() => {
        return () => {
            setDataValue({});
        };
    }, []);

    return (
        <div className="mb-5">
            <div className="row">
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={cliente}
                        vendedor={vendedor}
                        tipo_movimiento={tipo_movimiento}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={setCliente}
                        setVendedor={setVendedor}
                        setTipoMovimiento={setTipoMovimiento}
                        aplicarFiltros={aplicarFiltros}
                        setDataValue={setDataValue}
                        filtroComisiones
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text mt-3">
                                Reporte{" "}
                                <span className="font-weight-normal text-secondary">
                                    {tipo_movimiento &&
                                    tipo_movimiento.value == 1
                                        ? "(Comisiones al crédito)"
                                        : tipo_movimiento &&
                                          tipo_movimiento.value == 2
                                        ? "(Comisiones al contado)"
                                        : ""}
                                </span>
                            </h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            {tipo_movimiento && tipo_movimiento.value == 1 && (
                                <ReporteCreditoTabla />
                            )}
                            {tipo_movimiento && tipo_movimiento.value == 2 && (
                                <ReporteContadoTabla />
                            )}
                            <div className="d-flex justify-content-between">
                                <h1 className="title-text">Total</h1>
                                <RenderCurrency
                                    value={total_comisiones}
                                    className="title-text"
                                />
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoComisiones.propTypes = {
    listarReporte: PropTypes.func.isRequired,
};

export default ListadoComisiones;
