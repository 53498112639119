import { handleActions } from "redux-actions";
import _ from "lodash";
import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { change as changeForm } from "redux-form";
import { actions as printActions } from "Utils/Print";
import {
    actions as ventaActions,
    setBuscador,
    setCotizacionCargada,
} from "../venta/venta";
import { actions as usuarioActions } from "../usuario/usuario";
// ------------------------------------
// Constants
// ------------------------------------
export const LOADER = "MOSTRADOR_LOADER";
export const LOADER_CLIENTE = "MOSTRADOR_LOADER_CLIENTE";
export const SET_CAJA = "MOSTRADOR_SET_CAJA";
export const SET_NIT_CLIENTE = "MOSTRADOR_SET_NIT_CLIENTE";
export const SET_VER_IMPRESION = "MOSTRADOR_SET_VER_IMPRESION";
export const SET_NOMBRE_CLIENTE = "MOSTRADOR_SET_NOMBRE_CLIENTE";
export const SET_CORREO_CLIENTE = "MOSTRADOR_SET_CORREO_CLIENTE";
export const SET_VENTA_COTIZACION = "MOSTRADOR_SET_VENTA_COTIZACION";
const endpoint_clientes = "clientes";
const endpoint = "cotizaciones";

// ------------------------------------
// Actions
// ------------------------------------
export const leerCliente = (nit) => (dispatch) => {
    dispatch(setLoaderCliente(true));
    // esta accion lee un cliente a partir de su nit
    // para cargarlo a una venta, no confundir con leer()
    api.get(`${endpoint_clientes}/${nit}/por_nit/`)
        .catch((err) => {
            if (err.detail) {
                if (err.detail == "El servicio de Infile no esta disponible") {
                    ToastStore.error(err.detail);
                }
            } else {
                ToastStore.error(
                    "No fue posible obtener información del cliente"
                );
            }
        })
        .then((data) => {
            // cliente encontrado
            if (data) {
                dispatch(setNombreCliente(data.nombre));
                dispatch(setCorreoCliente(data.correo));
                dispatch(
                    changeForm("ClienteCotizacionForm", "nombre", data.nombre)
                );
                dispatch(
                    changeForm("ClienteCotizacionForm", "correo", data.correo)
                );
            }
        })
        .finally(() => {
            dispatch(setLoaderCliente(false));
        });
};

export const changeDatosClientes = (campo, value) => (dispatch) => {
    if (campo === "nit") {
        dispatch(setNitCliente(value));
    } else if (campo === "nombre") {
        dispatch(setNombreCliente(value));
    } else {
        dispatch(setCorreoCliente(value));
    }
};

export const enviarACaja = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const { cotizacion_cargada } = store.venta;
    const productos = store.venta.seleccionados;

    dispatch(setLoader(true));
    if (cotizacion_cargada) {
        api.put(`${endpoint}/${cotizacion_cargada.id}/enviar_caja`, {
            productos,
            ...datos,
        })
            .catch((err) => {
                if (err) {
                    Swal("ERROR", err.detail, "error");
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, espere un momento y vuelva a intentar",
                        "error"
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    dispatch(usuarioActions.getMe());
                    dispatch(limpiarDetalles());
                    dispatch(setPrimeraCaja(resp.caja));
                    ToastStore.success("Venta enviada a caja exitosamente");
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        api.post(endpoint, { productos, enviado_caja: true, ...datos })
            .catch((err) => {
                if (err) {
                    Swal("ERROR", err.detail, "error");
                } else {
                    Swal(
                        "ERROR",
                        "Ha ocurrido un error, espere un momento y vuelva a intentar",
                        "error"
                    );
                }
            })
            .then((data) => {
                if (data) {
                    // let data_venta_cotizacion = data;
                    // dispatch(setVentaCotizacion(data_venta_cotizacion));
                    dispatch(usuarioActions.getMe());
                    dispatch(limpiarDetalles());
                    dispatch(setPrimeraCaja(data.caja));
                    ToastStore.success("Venta enviada a caja exitosamente");
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
};

export const enviarCotizacion = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const productos = store.venta.seleccionados;
    dispatch(setLoader(true));
    api.post(endpoint, { productos, enviado_caja: false, ...datos })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(setVerImpresion(true));
                dispatch(setPrimeraCaja(data.caja));
                // ToastStore.success("Cotizacion generada exitosamente");
                // printActions.print('ticketCotizacion');
                // dispatch(usuarioActions.getMe());
                // dispatch(limpiarDetalles())
                // ToastStore.success("Venta enviada a caja exitosamente");
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actualizarCotizacion = (datos) => (dispatch, getStore) => {
    const store = getStore();
    const { seleccionados, cotizacion_cargada } = store.venta;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${cotizacion_cargada.id}`, {
        productos: seleccionados,
        enviado_caja: false,
        ...datos,
    })
        .catch((err) => {
            if (err) {
                Swal(
                    "ERROR",
                    err.detail ||
                        "Error al actializar la cotización, intenta más tarde",
                    "error"
                );
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, espere un momento y vuelva a intentar",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                let data_venta_cotizacion = data;
                dispatch(setVentaCotizacion(data_venta_cotizacion));
                dispatch(setVerImpresion(true));
                dispatch(setPrimeraCaja(data.caja));
                // printActions.print('ticketCotizacion');
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const limpiarDetalles = () => (dispatch) => {
    // Se limpian y se listan otra vez los productos
    dispatch(ventaActions.setSeleccionados([]));
    dispatch(setBuscador(""));
    dispatch(setCotizacionCargada(null));
    dispatch(ventaActions.listar());
};

const getPrimeraCaja = () => (dispatch, getStore) => {
    const store = getStore();
    const { caja } = store.punto_venta_mostrador;
    if (!caja) {
        api.get("cajas", { search: "", solo_esta_sucursal: true })
            .catch(() => {})
            .then((data) => {
                if (data) {
                    const cajas = data.results;
                    dispatch(setPrimeraCaja(cajas[0]));
                }
            });
    }
};

const finalizarCotizacion = () => (dispatch) => {
    dispatch(usuarioActions.getMe());
    dispatch(limpiarDetalles());
    dispatch(setVentaCotizacion(null));
    dispatch(setVerImpresion(false));
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setNombreCliente = (nombre_cliente) => ({
    type: SET_NOMBRE_CLIENTE,
    nombre_cliente,
});
export const setNitCliente = (nit_cliente) => ({
    type: SET_NIT_CLIENTE,
    nit_cliente,
});
export const setCorreoCliente = (correo_cliente) => ({
    type: SET_CORREO_CLIENTE,
    correo_cliente,
});

export const setVentaCotizacion = (venta_cotizacion) => ({
    type: SET_VENTA_COTIZACION,
    venta_cotizacion,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setPrimeraCaja = (caja) => ({
    type: SET_CAJA,
    caja,
});

export const setVerImpresion = (ver_impresion) => ({
    type: SET_VER_IMPRESION,
    ver_impresion,
});
export const setLoaderCliente = (loader_cliente) => ({
    type: LOADER_CLIENTE,
    loader_cliente,
});

export const actions = {
    leerCliente,
    enviarACaja,
    getPrimeraCaja,
    setPrimeraCaja,
    limpiarDetalles,
    enviarCotizacion,
    changeDatosClientes,
    finalizarCotizacion,
    actualizarCotizacion,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [SET_NOMBRE_CLIENTE]: (state, { nombre_cliente }) => {
        return {
            ...state,
            nombre_cliente,
        };
    },
    [SET_NIT_CLIENTE]: (state, { nit_cliente }) => {
        return {
            ...state,
            nit_cliente,
        };
    },
    [SET_CORREO_CLIENTE]: (state, { correo_cliente }) => {
        return {
            ...state,
            correo_cliente,
        };
    },
    [SET_VENTA_COTIZACION]: (state, { venta_cotizacion }) => {
        return {
            ...state,
            venta_cotizacion,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_CAJA]: (state, { caja }) => {
        return {
            ...state,
            caja,
        };
    },
    [SET_VER_IMPRESION]: (state, { ver_impresion }) => {
        return {
            ...state,
            ver_impresion,
        };
    },
    [LOADER_CLIENTE]: (state, { loader_cliente }) => {
        return {
            ...state,
            loader_cliente,
        };
    },
};

// ------------------------------------
// Initial state
// ------------------------------------
export const initialState = {
    nombre_cliente: "",
    nit_cliente: "",
    correo_cliente: "",
    venta_cotizacion: null,
    loader: false,
    caja: null,
    ver_impresion: false,
    loader_cliente: false,
};
export default handleActions(reducers, initialState);
