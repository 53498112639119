import React, { useEffect } from "react";
import { icons } from "icons";

import { envio, factura, credito, cotizacion } from "utility/constantes";

import { activeFormatter } from "Utils/Acciones/Acciones";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link } from "react-router-dom";

import descuento_icono from "../../../../../assets/img/iconos/descuento-icono.svg";
import descuento_disabled from "../../../../../assets/img/iconos/descuento-disabled.svg";

export const TablaOV = (props) => {
    useEffect(() => {
        return () => {
            props.limpiarOV();
        };
    }, []);

    return (
        <BootstrapTable
            data={props.loader ? [] : props.datos_listado.results}
            remote
            pagination
            hover
            fetchInfo={{ dataTotalSize: props.datos_listado.count }}
            options={props.options}
        >
            <TableHeaderColumn
                dataField="creado"
                dataFormat={(cell) => <RenderDate value={cell} />}
                dataSort
            >
                Fecha
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="codigo"
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell ? (
                                <span className="font-weight-bold">
                                    {cell ? cell : "------------"}
                                </span>
                            ) : (
                                <span className="font-weight-bold ">
                                    {"------------"}
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Codigo
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="tipo_venta"
                dataFormat={(cell) => {
                    return (
                        //envio, factura, credito, cotizacion
                        <div>
                            {cell == envio ? (
                                <span className="font-weight-bold text-success">
                                    ENVIO
                                </span>
                            ) : cell == factura ? (
                                <span className="font-weight-bold text-success">
                                    FACTURA
                                </span>
                            ) : cell == credito ? (
                                <span className="font-weight-bold text-success">
                                    CREDITO
                                </span>
                            ) : cell == cotizacion ? (
                                <span className="font-weight-bold text-success">
                                    COTIZACION
                                </span>
                            ) : (
                                <span className="font-weight-bold text-danger">
                                    "----------"
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Tipo de venta
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="pago_finalizado"
                dataFormat={(cell) => {
                    return (
                        //envio, factura, credito, cotizacion
                        <div>
                            {cell ? (
                                <img
                                    style={{ margin: "0", height: "1.5rem" }}
                                    src={icons.check}
                                    alt="Finalizado"
                                />
                            ) : (
                                <img
                                    style={{ margin: "0", height: "1.5rem" }}
                                    src={icons.reloj}
                                    alt="Pendiente"
                                />
                            )}
                        </div>
                    );
                }}
            >
                Pago
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="cliente_proveedor"
                dataFormat={(cell) => {
                    return (
                        <div>
                            {cell ? (
                                <span className="font-weight-bold">
                                    {cell.nombre ? cell.nombre : "------------"}
                                </span>
                            ) : (
                                <span className="font-weight-bold ">
                                    {"------------"}
                                </span>
                            )}
                        </div>
                    );
                }}
            >
                Cliente
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="usuario"
                dataFormat={(cell) => {
                    if (cell) {
                        return cell.first_name;
                    }
                    return "---";
                }}
            >
                Vendedor
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="facturado"
                dataFormat={(cell, row) => {
                    // if (row.tipo_venta != envio && row.tipo_venta != credito) {
                    //     return "---";
                    // }
                    return (
                        <span
                            className="font-weight-bold"
                            style={{ color: cell ? "green" : "red" }}
                        >
                            {cell ? "Si" : "No"}
                        </span>
                    );
                }}
            >
                Facturado
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="total_con_devoluciones"
                dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                dataAlign="right"
            >
                Total
            </TableHeaderColumn>

            <TableHeaderColumn
                dataAlign="right"
                dataField="saldo"
                dataFormat={(cell, row) => {
                    if (row.tipo_venta == credito) {
                        return <RenderCurrency value={cell ? cell : 0} />;
                    }
                    return <span>---</span>;
                }}
            >
                Saldo
            </TableHeaderColumn>

            <TableHeaderColumn
                dataField="porcentaje_descuento_nc"
                dataAlign="right"
            >
                Descuento
            </TableHeaderColumn>

            {!props.anulados ? (
                <TableHeaderColumn
                    dataField="id_venta"
                    isKey
                    dataAlign="center"
                    width="170px"
                    dataFormat={(cell, row) => {
                        return (
                            <React.Fragment>
                                <Link
                                    to={`/bodega/nota_credito_cliente/seleccion_de_producto/${cell}`}
                                >
                                    <img
                                        onClick={(e) => {
                                            // e.preventDefault();
                                            // extra.retimbradoFEL(id);
                                        }}
                                        className="action-img"
                                        title="Generar devolución"
                                        src={icons.next}
                                        alt="Generar devolución"
                                        style={{ maxWidth: "22px" }}
                                    />
                                </Link>

                                <img
                                    onClick={
                                        row.descuento_nc
                                            ? () => {}
                                            : () =>
                                                  props.setModalDescuento({
                                                      data: row,
                                                      open: true,
                                                  })
                                    }
                                    style={{
                                        cursor: row.descuento_nc
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    className="action-img"
                                    title="Descuento"
                                    src={
                                        row.descuento_nc
                                            ? descuento_disabled
                                            : descuento_icono
                                    }
                                    alt="Descuento"
                                    width="17"
                                />
                            </React.Fragment>
                        );
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            ) : (
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    dataFormat={(cell, row) => {
                        return activeFormatter({
                            ver: "/ordenes_de_venta/detalle",
                        })(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            )}
        </BootstrapTable>
    );
};
