import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderDateTime,
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import { pago_total, FACTURADO } from "utility/variables";

import moment from "moment";

import NumerosALetras from "../../../../utility/convertToLetters";

import "./index.css";
import { ID_DPI, ID_NIT } from "../../../../utility/variables";
export default class ReciboOV extends Component {
    static propTypes = {
        sucursal: PropTypes.object.isRequired,
        movimiento: PropTypes.object.isRequired,
        footerText: PropTypes.string.isRequired,
        tipo: PropTypes.oneOf(["Ingreso", "Venta"]).isRequired,
    };
    constructor(props) {
        super(props);
    }

    percentage(percent, total) {
        return ((percent / 100) * total).toFixed(2);
    }

    render() {
        const {
            sucursal,
            movimiento,
            usuario,
            utiliza_fel,
            conf_habilitar_no_factura,
            orden_compra_data = false,
            pagina,
        } = this.props;
        const datos_empresa = sucursal.datos_empresa
            ? sucursal.datos_empresa
            : {};
        const datos_fel = movimiento ? movimiento.datos_fel : {};
        const detalles = movimiento ? movimiento.detalles : [];
        const { footerText, tipo } = this.props;
        //let total =movimiento ? movimiento.total : 0;
        const total = movimiento ? movimiento.total : 0;
        const total_facturado =
            movimiento && movimiento.total_facturado
                ? movimiento.total_facturado[pagina - 1]
                : 0;

        const img_encabezado = require("assets/img/encabezado-hojas.png");

        let fin_items = pagina * 15;

        const fecha =
            utiliza_fel && datos_fel
                ? datos_fel.fecha_emision
                : movimiento.fecha_actual_factura;

        const tiene_descuento = parseFloat(detalles[0].desc_porcentaje) > 0;

        const es_agente_retenedor_iva = sucursal.es_agente_retenedor_iva;
        const tiene_vuelto = movimiento.vuelto_efectivo > 0;
        const pago_recibido = movimiento.pago_recibido > 0;

        const no_doc_cliente = movimiento.no_doc_cliente;
        const nombre_cliente = movimiento.nombre_cliente;
        const tipo_documento_cliente = movimiento.tipo_documento_cliente;

        const datos_cliente_cambiados =
            no_doc_cliente && nombre_cliente ? true : false;

        return (
            <div id="IMPRESION-HOJA-OV" className="w-100">
                <div className="print-shadow hoja-carta">
                    {/* ENCABEZADO */}
                    {/*<div className="encabezado-principal">
                        <div className="encabezado-item-logo"></div>
                        <div className="encabezado-item-datos"></div>
                        <div className="encabezado-item-fel"></div>
        </div>*/}
                    <div className="encabezado">
                        {/*<div className="flex3 item-img">
                            <img src={img_encabezado} alt="encabezado-ov" />
    </div>*/}
                        <div className="flex4 d-flex align-items-start justify-content-between">
                            <div className="item bg-transparent ml-4 text-center">
                                {/*
                                item bg-transparent
                                <b>Método de pago</b>
                                <p className="m-0">Contado</p>*/}
                                <img
                                    src={img_encabezado}
                                    alt="encabezado-ov"
                                    width="200"
                                />
                                <div className="font-weight-bold mt-3">
                                    Dia &nbsp;&nbsp;&nbsp; Mes
                                    &nbsp;&nbsp;&nbsp; Año
                                </div>
                                <div className="">
                                    <span className="ml-4 mr-2">
                                        {moment(fecha, "YYYY/MM/DD").date()}{" "}
                                    </span>
                                    <span className="ml-4 mr-2">
                                        {moment(fecha, "YYYY/MM/DD").month() +
                                            1}{" "}
                                    </span>
                                    <span className="ml-3 mr-3">
                                        {moment(fecha, "YYYY/MM/DD").year()}
                                    </span>
                                </div>
                            </div>
                            <div className="item bg-transparent text-center flex2">
                                {/* flex2*/}
                                <div className="font-weight-bold">
                                    {datos_empresa.nombre_comercial
                                        ? datos_empresa.nombre_comercial.toUpperCase()
                                        : ""}
                                </div>

                                <div className="mt-2 encabezado-item-datos font-weight-bold">
                                    {movimiento.nombre_emisor}
                                </div>

                                <div className="mt-3 font-weight-bold">
                                    NIT: {movimiento.nit_emisor}
                                </div>

                                <div>
                                    {movimiento.direccion_exacta_emisor}
                                </div>
                                <div>{movimiento.direccion_emisor}</div>
                                <div>
                                    Tels:{" "}
                                    {sucursal.telefono ||
                                        "7772-2013 / 7772-2493"}
                                </div>
                                <div className="encabezado-item-datos mt-2">
                                    email:
                                    ventas@remocar.com.gt&nbsp;|&nbsp;wwww.remocar.com.gt
                                </div>
                            </div>
                            <div className="item bg-transparent ml-5" style={{fontSize: '12px'}}>
                                <div className="font-weight-bold mt-3">
                                    {
                                        movimiento.datos_fel ?
                                            (
                                                <React.Fragment>
                                                    <p className="p-0 m-0">FACTURA</p>
                                                    <p>DOCUMENTO TRIBUTARIO ELECTRÓNICO - FEL</p>
                                                </React.Fragment>
                                            )
                                        :
                                            <p className="p-0 m-0" style={{fontSize: '15px'}}>{movimiento.tipo_venta}</p>
                                    }
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className="font-weight-bold">
                                        Serie
                                    </span>
                                    <span>
                                        {(movimiento.serie &&
                                            movimiento.serie[pagina - 1]) ||
                                            "---"}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="font-weight-bold">
                                        No.
                                    </span>
                                    <span>
                                        {(movimiento.numero &&
                                            movimiento.numero[pagina - 1]) ||
                                            "---"}
                                    </span>
                                </div>
                                {/* <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                        Autorización
                                    </span>
                                    <span> {movimiento.uuid || "---"}</span>
                                </div> */}
                                <div className="font-weight-bold d-flex justify-content-between">
                                    <span>Correlativo:</span>
                                    <span className="font-weight-normal">
                                        {movimiento.codigo_venta}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* INFO*/}
                    <div className="info-ov">
                        <div className="info-ov-item">
                            <div className="title"></div>
                            <div className="info-ov-item-body">
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="item">
                                        <b className="ml-3 mr-2">Cliente:</b>
                                        <span>
                                            {datos_cliente_cambiados
                                                ? nombre_cliente
                                                : movimiento.nombre_cambiado
                                                ? movimiento.nombre_cambiado
                                                : movimiento.cliente_proveedor
                                                ? movimiento.cliente_proveedor
                                                      .nombre
                                                    ? movimiento
                                                          .cliente_proveedor
                                                          .nombre
                                                    : "-----"
                                                : "-----"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <b className="mr-2">Vendedor:</b>
                                        <span>{usuario}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1 mb-1">
                                    <div className="item">
                                        <b className="ml-3 mr-2">Direccion:</b>
                                        <span>
                                            {movimiento.cliente_proveedor
                                                ? movimiento.cliente_proveedor
                                                      .direccion
                                                    ? movimiento
                                                          .cliente_proveedor
                                                          .direccion
                                                    : movimiento.direccion
                                                    ? movimiento.direccion
                                                    : "Ciudad"
                                                : "Ciudad"}
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1 mb-1">
                                    <div className="item">
                                        <b className="ml-3 mr-2">
                                            {tipo_documento_cliente === ID_NIT
                                                ? "NIT"
                                                : tipo_documento_cliente ===
                                                  ID_DPI
                                                ? "DPI"
                                                : "DOC EXT"}
                                            :
                                        </b>
                                        <span>
                                            {datos_cliente_cambiados
                                                ? no_doc_cliente
                                                : movimiento.no_doc_cambiado
                                                ? movimiento.no_doc_cambiado
                                                : movimiento.cliente_proveedor
                                                ? movimiento.cliente_proveedor
                                                      .nit
                                                    ? movimiento
                                                          .cliente_proveedor.nit
                                                    : "CF"
                                                : "CF"}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <b className="mr-2">Documento POS:</b>
                                        <span>{movimiento.POS || "---"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="info-ov ">
                        <div className="info-ov-item">
                            <div className="title"></div>
                        </div>
                    </div>
                    {/* PRODUCTOS */}
                    <div className="productos-ov impresion-tabla">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th
                                        className="text-center"
                                        style={{ width: "60px" }}
                                    >
                                        CANTIDAD
                                    </th>
                                    <th
                                        className="text-left"
                                        style={{ minWidth: "160px" }}
                                    >
                                        CODIGO
                                    </th>
                                    <th
                                        style={{
                                            width: "1400px",
                                        }}
                                    >
                                        DESCRIPCION
                                    </th>
                                    <th
                                        className="text-right"
                                        style={{ width: "200px" }}
                                    >
                                        UNIDAD
                                    </th>
                                    {tiene_descuento &&
                                        movimiento.venta_costo == false && (
                                            <th
                                                className="text-center"
                                                style={{ width: "120px" }}
                                            >
                                                DESCUENTO
                                            </th>
                                        )}
                                    <th
                                        className="text-right"
                                        style={{ width: "200px" }}
                                    >
                                        SUBTOTAL
                                    </th>
                                    <th
                                        className="text-right"
                                        style={{
                                            width: "110px !important",
                                        }}
                                    >
                                        IMPUESTO
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {detalles &&
                                    detalles
                                        .slice(fin_items - 15, fin_items)
                                        .map((detalle, idx) => {
                                            //total = total + detalle.sub_total;
                                            return (
                                                <tr key={idx}>
                                                    <td
                                                        scope="row"
                                                        className="text-center"
                                                    >
                                                        {
                                                            <RenderNumber
                                                                value={
                                                                    detalle.cantidad
                                                                }
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />
                                                        }
                                                    </td>
                                                    <td className="text-left">
                                                        {` ${
                                                            detalle.fraccion
                                                                .producto
                                                                ? `${detalle.fraccion.producto.codigo} `
                                                                : ""
                                                        } `}
                                                    </td>
                                                    <td className="text-left max-size-txt">
                                                        {`${detalle.producto} `}
                                                    </td>
                                                    <td className="text-left">
                                                        <RenderCurrency
                                                            value={
                                                                tipo ===
                                                                "Ingreso"
                                                                    ? detalle.costo
                                                                    : detalle.precio_original &&
                                                                      detalle.desc_porcentaje
                                                                    ? detalle.precio_original
                                                                    : detalle.precio
                                                            }
                                                            className={"mr-1"}
                                                        />
                                                    </td>
                                                    {tiene_descuento &&
                                                        movimiento.venta_costo ==
                                                            false && (
                                                            <React.Fragment>
                                                                <td className="text-left">
                                                                    {"Q."}
                                                                    {this.percentage(
                                                                        movimiento.porcentaje_descuento_recargo,
                                                                        tipo ===
                                                                            "Ingreso"
                                                                            ? detalle.costo
                                                                            : detalle.precio_original &&
                                                                              detalle.desc_porcentaje
                                                                            ? detalle.precio_original
                                                                            : detalle.precio
                                                                    )}
                                                                </td>
                                                                <td className="text-left">
                                                                    {"Q."}
                                                                    {(
                                                                        (
                                                                            detalle.cantidad *
                                                                            detalle.precio_original
                                                                        ).toFixed(
                                                                            2
                                                                        ) -
                                                                        (
                                                                            this.percentage(
                                                                                movimiento.porcentaje_descuento_recargo,
                                                                                tipo ===
                                                                                    "Ingreso"
                                                                                    ? detalle.costo
                                                                                    : detalle.precio_original &&
                                                                                      detalle.desc_porcentaje
                                                                                    ? detalle.precio_original
                                                                                    : detalle.precio
                                                                            ) *
                                                                            detalle.cantidad
                                                                        ).toFixed(
                                                                            2
                                                                        )
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                            </React.Fragment>
                                                        )}
                                                    {!tiene_descuento ? (
                                                        <td className="text-left">
                                                            {detalle.precio_original &&
                                                            detalle.desc_porcentaje ? (
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.cantidad *
                                                                        detalle.precio_original
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            ) : (
                                                                <RenderCurrency
                                                                    value={
                                                                        detalle.sub_total
                                                                    }
                                                                    className={
                                                                        "ml-auto"
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                    ) : (
                                                        movimiento.venta_costo ==
                                                            true && (
                                                            <td className="text-left">
                                                                {detalle.precio_original &&
                                                                detalle.desc_porcentaje ? (
                                                                    <RenderCurrency
                                                                        value={
                                                                            detalle.cantidad *
                                                                            detalle.precio_original
                                                                        }
                                                                        className={
                                                                            "ml-auto"
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <RenderCurrency
                                                                        value={
                                                                            detalle.sub_total
                                                                        }
                                                                        className={
                                                                            "ml-auto"
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                        )
                                                    )}
                                                    <td className="text-left">
                                                        <RenderCurrency
                                                            value={this.percentage(
                                                                12,
                                                                detalle.sub_total
                                                            )}
                                                            className={"mr-1"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                            </tbody>
                        </table>
                    </div>
                    <div className="line text-center font-weight-bold">
                        SUJETO A PAGOS TRIMESTRALES ISR
                        {es_agente_retenedor_iva
                            ? " / AGENTE RETENEDOR DE IVA"
                            : ""}
                    </div>
                    {/* FOOTER */}
                    <div className="info-ov ">
                        <div className="info-ov-item">
                            <div className="title"></div>
                            <div className="info-ov-item-body">
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="footer-text flex3 ">
                                        <div>
                                            {movimiento &&
                                                movimiento.paginas && (
                                                    <React.Fragment>
                                                        <b className="mr-2">
                                                            EN LETRAS:
                                                        </b>{" "}
                                                        <span>
                                                            {NumerosALetras.NumerosALetras(
                                                                total_facturado
                                                            )}
                                                        </span>{" "}
                                                    </React.Fragment>
                                                )}
                                        </div>
                                        <div>
                                            <b className="mr-2">NOTAS:</b>
                                            <span>
                                                LA COMPANIA SE RESERVA EL
                                                DERECHO DE ACEPTAR O NO
                                                DEVOLUCIONES DE MERCADERIA QUE
                                                AMPARE ESTA FACTURA, LAS QUE DE
                                                NINGUNA MANERA SERAN ACEPTADAS
                                                DESPUES DE 5 DIAS DE DE LA FECHA
                                                DE LA MISMA. TODA DEVOLUCION
                                                QUEDA SUJETA A UN 10% DE
                                                DESCUENTO SI ESTA FACTURA NO
                                                ESTA CANCELADA EN LA FECHA
                                                INDICADA. DEVENGARA UN 3% DE
                                                INTERES MENSUAL.
                                            </span>
                                        </div>
                                        <div>
                                            <b className="mr-2">
                                                CERTIFICADOR: MEGAPRINT, S.A.
                                                NIT: 50510231
                                            </b>
                                        </div>
                                        <div>
                                            <b className="mr-2">
                                                AUTORIZACION:{" "}
                                                {(movimiento.uuid &&
                                                    movimiento.uuid[
                                                        pagina - 1
                                                    ]) ||
                                                    "---"}
                                            </b>
                                        </div>
                                        <div>
                                            <b className="mr-2">
                                                FECHA CERTIFICACIÓN:{" "}
                                                {(movimiento.fecha_certificacion &&
                                                    movimiento
                                                        .fecha_certificacion[
                                                        pagina - 1
                                                    ]) ||
                                                    "---"}
                                            </b>
                                        </div>
                                    </div>

                                    <div className="item text-right total-ov font-weight-bold">
                                        {movimiento && movimiento.paginas && (
                                            <React.Fragment>
                                                TOTAL &nbsp;
                                                <RenderCurrency
                                                    value={total_facturado}
                                                    className={"ml-auto"}
                                                />
                                                {movimiento.tipo_pago ==
                                                pago_total ? (
                                                    <div className="d-flex flex-row justify-content-end">
                                                        {pago_recibido && (
                                                            <React.Fragment>
                                                                <b>
                                                                    PAGO
                                                                    RECIBIDO
                                                                </b>
                                                                <b>
                                                                    <RenderCurrency
                                                                        value={
                                                                            total_facturado +
                                                                                movimiento.vuelto_efectivo ||
                                                                            0
                                                                        }
                                                                        className={
                                                                            "ml-2"
                                                                        }
                                                                    />
                                                                </b>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-row justify-content-end">
                                                        <b>ANTICIPO</b>
                                                        <b>
                                                            <RenderCurrency
                                                                value={
                                                                    movimiento.pago_recibido
                                                                }
                                                                className={
                                                                    "ml-2"
                                                                }
                                                            />
                                                        </b>
                                                    </div>
                                                )}
                                                {tiene_vuelto &&
                                                    movimiento.tipo_pago ==
                                                        pago_total && (
                                                        <div className="d-flex flex-row justify-content-end">
                                                            <b>VUELTO</b>
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        movimiento.vuelto_efectivo
                                                                    }
                                                                    className={
                                                                        "ml-2"
                                                                    }
                                                                />
                                                            </b>
                                                        </div>
                                                    )}
                                            </React.Fragment>
                                        )}
                                        {tiene_descuento &&
                                        !movimiento.venta_costo ? (
                                            <div>
                                                DESCUENTO APLICADO:{" "}
                                                {
                                                    movimiento.porcentaje_descuento_recargo
                                                }
                                                %
                                            </div>
                                        ) : (
                                            movimiento.venta_costo && (
                                                <div>
                                                    VENTA EFECTUADA AL COSTO
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="no_pagina d-flex flex-row justify-content-center imp-pt-2">
                        <span className="text-center">Pag.{pagina}</span>
                    </div>
                </div>
            </div>
        );
    }
}

/*

                                    <tbody>
                                        {pagina.detalles.map((detalle, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td scope="row">
                                                        <span>
                                                            <b>
                                                                {
                                                                    detalle.producto
                                                                }
                                                            </b>
                                                            <div>
                                                                {detalle
                                                                    .fraccion
                                                                    .producto
                                                                    .a_granel
                                                                    ? detalle
                                                                          .fraccion
                                                                          .unidad_de_medida
                                                                          .nombre
                                                                    : detalle
                                                                          .fraccion
                                                                          .nombre}
                                                            </div>
                                                        </span>
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderNumber
                                                            value={
                                                                detalle.cantidad
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.precio
                                                            }
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <RenderCurrency
                                                            value={
                                                                detalle.sub_total
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody> */
