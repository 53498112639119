import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _ from "lodash";
import { RenderCurrency, RenderDateTime } from "Utils/renderField/renderReadField";
import { FACTURADO } from "utility/variables";
import { PrintContainer, PrintComponent } from "Utils/Print";
import { opcionesDevolucion } from "utility/variables";
import LoadMask from "Utils/LoadMask/LoadMask";

class Vale extends Component {
    static propTypes = {
        elegidos: PropTypes.array.isRequired,
        empresa: PropTypes.string.isRequired,
        // loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getDevolucion(
            this.props.match.params.id_devolucion,
            this.props.match.params.id
        );
        this.props.getFactura(this.props.match.params.id);
    }

    getMotivo(motivo) {
        try {
            return _.find(opcionesDevolucion, { value: parseFloat(motivo) })
                .label;
        } catch (error) {
            return "";
        }
    }

    render() {
        const { devolucion, empresa, print_state, utiliza_fel, loader, match, factura } =
            this.props;
        const { movimiento } = devolucion;
        const { detalles } = movimiento;
        const sucursal = movimiento.sucursal ? movimiento.sucursal : {};
        const datos_empresa =
            sucursal && sucursal.datos_empresa ? sucursal.datos_empresa : {};

        const nota_credito_fel = devolucion.nota_credito_fel
            ? devolucion.nota_credito_fel
            : null;

        const { path } = match;
        const en_listado_mov = path.includes("nota_credito/");
        const en_nc_cliente = path.includes("notas_de_credito_al_cliente");
        const en_nota_credito_cliente = path.includes("nota_credito_cliente");

        const es_nc_por_devolucion =
            nota_credito_fel && nota_credito_fel.nc_descuento;


        return (
            <div className="col-12">
                {print_state.flag && <PrintComponent />}
                <LoadMask loading={loader} blur>
                    <div className="grid-container p-1 p-sm-3 p-md-5 d-flex flex-column text-center">
                        <div
                            className="col-lg-12 col-md-12 col-sm-12"
                            style={{ zIndex: "1" }}
                        >
                            <div className="mt1 text-right">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.print("valeDevolucion");
                                        //printActions.print('valeDevolucion')
                                    }}
                                    className="btn btn-secondary mt-lg"
                                    type={"button"}
                                >
                                    <em className="fa fa-print" /> Imprimir
                                </button>
                            </div>
                        </div>
                        <div
                            className="col-lg-12 col-md-12 col-sm-12 mt-10"
                            style={{ zIndex: "0" }}
                        >
                            <PrintContainer
                                name="valeDevolucion"
                                className="impresion-venta px-4"
                            >
                                <div
                                    className="print-shadow mr-auto ml-auto text-center"
                                    style={{ fontSize: "80%" }}
                                >
                                    <div className="mt mb d-flex flex-column justify-content-center align-items-center fnt-12">
                                        <div className="w-100 text-center">
                                            <img
                                                src={require("../../../../../../assets/img/remocar-logo.png")}
                                                width="25%"
                                            />
                                        </div>
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {empresa || ""}
                                            </b>
                                        </div>
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {movimiento.nombre_emisor}
                                            </b>
                                        </div>
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                NIT: {movimiento.nit_emisor}
                                            </b>
                                        </div>
                                        <div className="w-100 text-center">
                                            <b className="text-uppercase">
                                                {sucursal.direccion}
                                            </b>
                                        </div>

                                        <div className="w-100 text-center text-uppercase">
                                            {sucursal.municipio &&
                                                sucursal.departamento ? (
                                                    `${sucursal.municipio}, ${sucursal.departamento}`
                                                ) : (
                                                    <span>&nbsp;</span>
                                                )}
                                        </div>
                                        {(sucursal.telefono ||
                                            sucursal.telefono != "") && (
                                                <div className="w-100 text-center">
                                                    Tels. {sucursal.telefono}
                                                </div>
                                            )}
                                        <div className="flex1 d-flex flex-column">
                                            <b>Fecha y hora de emisión</b>
                                            <RenderDateTime value={devolucion.movimiento.creado} />
                                        </div>
                                        {nota_credito_fel && (
                                            <div className="w-100 text-center mt-3">
                                                <div className="w-100 text-center">
                                                    <b className="text-uppercase">
                                                        NOTA DE CRÉDITO
                                                    </b>
                                                </div>
                                                <div className="w-100 text-center">
                                                    <b className="text-uppercase">
                                                        SUJETO A PAGOS TRIMESTRALES ISR
                                                        {
                                                            sucursal.es_agente_retenedor_iva
                                                            ? " / AGENTE RETENEDOR DE IVA"
                                                            : ""
                                                        }
                                                    </b>
                                                </div>
                                                <div className="w-100 text-center">
                                                    <b className="text-uppercase">
                                                        FEL - DOCUMENTO
                                                        TRIBUTARIO ELECTRÓNICO
                                                    </b>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column flex-md-row my-4">
                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <b>Correlativo</b>
                                            {devolucion.movimiento.codigo ||
                                                "---"}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row my-4">
                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <b>Cliente</b>
                                            {devolucion.cliente.nombre}
                                        </div>

                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <b>NIT</b>
                                            {devolucion.cliente.nit || "CF"}
                                        </div>

                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <b>Usuario</b>
                                            {devolucion.usuario || "---"}
                                        </div>
                                        <div className="flex-1 d-flex flex-column align-items-start">
                                            <b>Direccion</b>
                                            {devolucion.direccion || "---"}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row my-4">
                                        <div
                                            className="w-100 mb-2"
                                            style={{ borderBottom: "dotted 1px" }}
                                        />
                                    </div>
                                    {utiliza_fel && nota_credito_fel && (
                                        <div className="w-100 text-left mt-3">
                                            {nota_credito_fel.estado_factura ==
                                                FACTURADO && (
                                                    <div className="w-100">
                                                        <div className="d-flex flex-row fnt-12 mb05">
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Autorización</b>
                                                                {nota_credito_fel.uuid
                                                                    ? nota_credito_fel.uuid
                                                                    : ""}
                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Serie</b>
                                                                {nota_credito_fel.serie
                                                                    ? nota_credito_fel.serie
                                                                    : ""}
                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Número</b>
                                                                {nota_credito_fel.numero
                                                                    ? nota_credito_fel.numero
                                                                    : ""}
                                                                <div className="flex1 d-flex flex-column">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row fnt-12 mb05">
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Autorización de Origen</b>
                                                                {nota_credito_fel.uuid_anterior
                                                                    ? nota_credito_fel.uuid_anterior
                                                                    : ""}
                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Serie Origen</b>
                                                                {nota_credito_fel.serie_anterior
                                                                    ? nota_credito_fel.serie_anterior
                                                                    : ""}
                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Número Origen</b>
                                                                {nota_credito_fel.numero_anterior
                                                                    ? nota_credito_fel.numero_anterior
                                                                    : ""}
                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Fecha de emisión de origen</b>
                                                                {nota_credito_fel.emision_anterior
                                                                    ? <RenderDateTime value={nota_credito_fel.emision_anterior} />
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row fnt-12 mb05">
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>CERTIFICADOR</b>
                                                                MEGAPRINT, S.A.

                                                            </div>
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>NIT CERTIFICADOR</b>
                                                                    50510231
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row-fnt-12 mb05">
                                                            <div className="flex1 d-flex flex-column">
                                                                <b>Motivo del ajuste</b>
                                                                {nota_credito_fel.motivo_ajuste}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="d-flex flex-column flex-md-row my-4">
                                                <div
                                                    className="w-100 mb-2"
                                                    style={{ borderBottom: "dotted 1px" }}
                                                />
                                            </div>
                                            <div className="flex1 d-flex flex-column mb-3">
                                                <b>Descuento aplicado</b>
                                                {
                                                    nota_credito_fel.descuento_aplicado
                                                }
                                                %
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex flex-column">
                                        {/* <div className="border-dotted ml-5 mr-5 mt-2 text-center">
                                        <h2 className="m-2">
                                            <strong>{devolucion.codigo}</strong>
                                        </h2>
                                    </div> */}
                                        {/* {utiliza_fel && nota_credito_fel ? (
                                        <small className="mb-5 mt-1">
                                            Ingrese este código para utilizar la
                                            nota de crédito como método de pago
                                        </small>
                                    ) : (
                                        <small className="mb-5 mt-1">
                                            Ingrese este código para realizar el
                                            descuento en la venta
                                        </small>
                                    )} */}

                                        <div className="d-flex flex-row justify-content-between">
                                            {utiliza_fel && nota_credito_fel ? (
                                                <strong>
                                                    NOTA DE CRÉDITO POR
                                                </strong>
                                            ) : (
                                                <strong>
                                                    VALE DE DEVOLUCIÓN POR
                                                </strong>
                                            )}

                                            <RenderCurrency
                                                value={movimiento.total}
                                                className={"font-weight-bold"}
                                            />
                                        </div>
                                        <div className="d-flex flex-row justify-content-between mt-2 mb-2">
                                            <div className="font-weight-bold flex-1 text-left">
                                                CANTIDAD
                                            </div>
                                            <div className="font-weight-bold flex-1 text-left">
                                                CODIGO
                                            </div>
                                            <div className="font-weight-bold flex-1 text-left">
                                                DESCRIPCION
                                            </div>
                                            <div className="font-weight-bold flex-1 text-left">
                                                PRECIO
                                            </div>
                                            <div className="font-weight-bold flex-1 text-left">
                                                SUBTOTAL
                                            </div>
                                            <div className="font-weight-bold flex-1 text-left">
                                                IMPUESTOS
                                            </div>
                                        </div>
                                        <div>
                                            {detalles.map((elegido) => {
                                                if (elegido.fraccion != null) {
                                                    return (
                                                        <div
                                                            className="d-flex flex-row justify-content-between mt-2 mb-2"
                                                            key={elegido.id}
                                                        >
                                                            <div className="flex-1 text-left">
                                                                {elegido.cantidad}
                                                            </div>
                                                            <div className="flex-1 text-left">
                                                                {elegido.codigo}
                                                            </div>
                                                            <div className="flex-1 text-left">
                                                                {elegido.producto}
                                                            </div>
                                                            <RenderCurrency
                                                                className={"flex-1 text-left"}
                                                                value={elegido.precio}
                                                            />
                                                            <RenderCurrency
                                                                className={"flex-1 text-left"}
                                                                value={elegido.sub_total}
                                                            />
                                                            <RenderCurrency
                                                                className={"flex-1 text-left"}
                                                                value={elegido.sub_total * 0.12}
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div
                                                            className="d-flex flex-row justify-content-between mt-2 mb-2"
                                                            key={elegido.id}
                                                        >

                                                            <RenderCurrency
                                                                className={
                                                                    "ml-3"
                                                                }
                                                                value={
                                                                    elegido.precio
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div
                                        className="w-100 mb-2"
                                        style={{ borderBottom: "dotted 1px" }}
                                    />
                                    <div className="d-flex flex-row justify-content-between">
                                        {utiliza_fel && nota_credito_fel ? (
                                            <div>Total</div>
                                        ) : (
                                            <div>Vale por devolución</div>
                                        )}

                                        <RenderCurrency
                                            value={movimiento.total}
                                        />
                                    </div>
                                    {utiliza_fel && (
                                        <div className="w-100 mt-3">
                                            {/* DENOMINACION */}
                                            {/* {nota_credito_fel && (
                                            <div className="d-flex flex-row justify-content-center fnt-14 mb-3 text-center font-weight-bold text-uppercase">
                                                {nota_credito_fel.frase_fel}
                                            </div>
                                        )} */}
                                            {/* {nota_credito_fel && (
                                            <div className="d-flex flex-row fnt-12 mb05">
                                                <div className="flex1 d-flex flex-column align-items-center">
                                                    <b>COMPLEMENTOS</b>

                                                    <p className="text-uppercase m-0 p-0">
                                                        Fecha emisión doc
                                                        origen:&nbsp;
                                                        {factura &&
                                                        factura.movimiento &&
                                                        factura.movimiento
                                                            .datos_fel
                                                            ? moment(
                                                                  factura
                                                                      .movimiento
                                                                      .datos_fel
                                                                      .creado
                                                              ).format(
                                                                  "DD/MM/YYYY"
                                                              )
                                                            : ""}
                                                    </p>
                                                    <p className="text-uppercase m-0 p-0">
                                                        Número autorización doc
                                                        origen:&nbsp;
                                                        {factura &&
                                                        factura.movimiento &&
                                                        factura.movimiento
                                                            .datos_fel
                                                            ? factura.movimiento
                                                                  .datos_fel
                                                                  .uuid
                                                            : ""}
                                                    </p>
                                                    <p className="text-uppercase m-0 p-0">
                                                        Motivo de ajuste:
                                                        Devolucion de productos
                                                    </p>
                                                </div>
                                            </div>
                                        )} */}
                                            {/* {nota_credito_fel && (
                                                <div className="w-100">
                                                    <div className="d-flex flex-row fnt-12 mb05">
                                                        <div className="flex1 d-flex flex-column align-items-center">
                                                            <b>AUTORIZACIÓN FEL</b>
                                                            {nota_credito_fel.uuid
                                                                ? nota_credito_fel.uuid
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row fnt-12">
                                                        <div className="flex1 d-flex flex-column align-items-center">
                                                            <b>
                                                                FECHA CERTIFICACIÓN
                                                            </b>
                                                            <RenderDateTime
                                                                value={
                                                                    nota_credito_fel.fechaCertificacion
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )} */}

                                            {/* DATOS CERTIFICADOR */}
                                            {/* {nota_credito_fel && (
                                            <div className="d-flex flex-row fnt-12 mt-3">
                                                <div className="flex1 d-flex flex-column align-items-center">
                                                    <b>CERTIFICADOR</b>
                                                    MEGAPRINT, S.A. NIT:
                                                    50510231
                                                </div>
                                            </div>
                                        )} */}
                                        </div>
                                    )}
                                </div>
                            </PrintContainer>
                        </div>
                        {!en_nc_cliente && (
                            <div className="d-flex justify-content-center flex-row mt-3">
                                <Link
                                    to={
                                        en_listado_mov
                                            ? "/bodega/listado_movimientos/"
                                            : en_nota_credito_cliente
                                                ? "/bodega/nota_credito_cliente"
                                                : "/bodega/nota_credito_cliente/"
                                    }
                                    className="btn btn-primary m-1 align-self-center"
                                >
                                    {en_listado_mov ? (
                                        "Regresar"
                                    ) : (
                                        <React.Fragment>
                                            <i className="fa fa-check" />
                                            &nbsp;Finalizar
                                        </React.Fragment>
                                    )}
                                </Link>
                            </div>
                        )}
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default Vale;
