import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { FiltrosReportes } from "../../../Utils/FiltrosReportes";
import ResumenCuentaCobrar from "../Resumen";

const ListadoCuentaCobrar = (props) => {
    useEffect(() => {
        return () => {
            props.limpiarFiltros();
        };
    }, []);

    // state
    const {
        loader,
        data,
        marcar_entrega,
        filtro,
        total,
        fecha_vencimiento_inicial,
        fecha_vencimiento_final,
        estado_descarga,

        tipo_cliente,
        vendedor,
        tipo_movimiento,
    } = props;
    // bind
    const {
        filtroChange,
        setFecha,
        descargarListado,
        aplicarFiltros,

        setTipoCliente,
        setVendedor,
        setTipoMovimiento,
    } = props;

    //  OPCIONES
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.listar;
    options.onSortChange = props.sortChange;
    console.log("options", data.results);
    return (
        <div className="mb-5">
            <div className="row">
                {/* RESUMEN */}
                <div className="col-12">
                    <ResumenCuentaCobrar />
                </div>
                {/* RESUMEN FIN */}

                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={props.cliente}
                        tipo_cliente={tipo_cliente}
                        vendedor={vendedor}
                        tipo_movimiento={tipo_movimiento}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={props.setCliente}
                        setTipoCliente={setTipoCliente}
                        setVendedor={setVendedor}
                        setTipoMovimiento={setTipoMovimiento}
                        aplicarFiltros={aplicarFiltros}
                        filtroCC
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>

                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="fecha_vencimiento"
                                    dataFormat={cell => <RenderDate value={cell} />}
                                >
                                    Fecha de vencimiento
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="codigo_venta">
                                    Codigo de venta
                                </TableHeaderColumn>
                                
                                <TableHeaderColumn dataField="factura_venta">
                                    Correlativo Factura
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="ov_pendiente"
                                    dataFormat={(cell, row) => {
                                        if (
                                            row.ov_pendiente &&
                                            row.ov_vencida
                                        ) {
                                            return (
                                                <span className="font-weight-bold text-danger">
                                                    Vencido
                                                </span>
                                            );
                                        }
                                        return (
                                            <div>
                                                {!cell ? (
                                                    <span className="font-weight-bold text-success">
                                                        Finalizado
                                                    </span>
                                                ) : (
                                                    <span className="font-weight-bold txt-yellow">
                                                        Pendiente
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                >
                                    Estado pago
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="cliente"
                                    dataFormat={(cell) => cell.nombre}
                                >
                                    Cliente
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="total"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Total
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="saldo"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Saldo
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="10%"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return activeFormatter({
                                            ver: "/reporte_cuenta_por_cobrar",
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoCuentaCobrar.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoCuentaCobrar;
