import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";

const CuentasPorPagarTable = ({ data, total }) => {
    // prop de total, viene de la cuenta por pagar (componente padre)
    return (
        <div className="border-top">
            <div className="d-flex mt-3">
                <h4 className="m-0 font-weight-bold">
                    Listado de cuentas por pagar
                </h4>
                <div className="flex-1 d-flex align-items-center justify-content-end mb-3">
                    <h4 className="m-0 mr-2 mr-lg-4 font-weight-bold">
                        Total a pagar:
                    </h4>
                    <h4 className="m-0 font-weight-bold text-primary">
                        <RenderCurrency value={total} />
                    </h4>
                </div>
            </div>
            <div className="responsive-table">
                <BootstrapTable data={data} hover striped>
                    <TableHeaderColumn
                        dataField="fecha"
                        dataFormat={(cell) => <RenderDate value={cell} />}
                        // dataSort
                    >
                        Fecha de venta
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_fin_pago"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.tipo_pago ? (
                                        <RenderDate value={cell} />
                                    ) : (
                                        <span>-----</span>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Fecha de vencimiento
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="modificado"
                        dataFormat={(cell, row) => {
                            return (
                                <div>
                                    {row.pago_finalizado ? (
                                        <RenderDate value={cell} />
                                    ) : (
                                        <span>-----</span>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Fecha de Pago
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor__nombre"
                        dataFormat={(cell, row) => row.numero_documento}
                    >
                        No. Documento
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pago_finalizado"
                        dataFormat={(cell, row) => {
                            if (row.oc_vencida && !row.pago_finalizado) {
                                return (
                                    <span className="font-weight-bold text-danger">
                                        Vencido
                                    </span>
                                );
                            }
                            return (
                                <div>
                                    {cell ? (
                                        <span className="font-weight-bold text-success">
                                            Finalizado
                                        </span>
                                    ) : (
                                        <span className="font-weight-bold txt-yellow">
                                            Pendiente
                                        </span>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Estado pago
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proveedor__nombre"
                        dataFormat={(cell, row) => (
                            <div>{row.proveedor.nombre}</div>
                        )}
                    >
                        Proveedor
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataAlign="right"
                        dataField="total"
                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="right"
                        dataField="saldo"
                        dataFormat={(cell) => <RenderCurrency value={cell} />}
                    >
                        Saldo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        width="0"
                    ></TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    );
};

export default CuentasPorPagarTable;
