import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { RenderDate } from "Utils/renderField/renderReadField";
import { TabProductos, TabAbonosCuentaPagar } from "./ComponentesOrdenCompra";
import PagoOrdenCompraForm from "./PagoOrdenCompraForm";
import { Link } from "react-router-dom";
import { PrintContainer, PrintComponent } from "Utils/Print";
import Recibo from "Utils/Recibo";
import Nota from "Utils/Nota";

export class DetalleOrdenCompra extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        marcarEntregaOC: PropTypes.func.isRequired,
        marcarPagoFinalizadoOC: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { ver_buscador: false };
        this.state = {
            tab: 0,
        };
    }

    componentWillMount() {
        this.props.getOC(this.props.match.params.id);
        this.props.listarMovimientos();
    }

    cambioTab = (tabVal) => {
        this.setState({ tab: tabVal });
    };

    closeModal = () => {
        this.props.setOpenModalPago(false);
        this.props.limpiarDatosPago();
        this.props.listarMovimientos();
    };
    abrirModalPago = () => {
        this.props.setOpenModalPago(true);
    };
    submit = (data) => {
        const idOC = this.props.match.params.id;
        this.props.registrarPagoAbono(idOC);
    };

    render() {
        // State
        const {
            loader,
            movimiento,
            orden_compra,
            page_retiros,
            loader_retiros,
            retiros,
            fecha_inicial,
            fecha_final,
            movimiento_caja,
            pago_pendiente,
            open_modal_pago,
            print_state,
            codigo_cobro,
        } = this.props;
        // Bind
        const {
            finalizarOC,
            marcarEntregaOC,
            marcarPagoFinalizadoOC,
            listarMovimientos,
            sortChangeRetiros,
            changeFecha,
            setMovimientoCaja,
            registrarPagoAbono,
            setCodigoCobro,
        } = this.props;
        const data_movimiento = movimiento
            ? movimiento
            : { detalles: [], usuario: {} };
        const sucursal = orden_compra ? orden_compra.sucursal : {};

        return (
            <div className="row">
                {print_state.flag && <PrintComponent />}
                <div className="col-12">
                    <div className="grid-container mb-0">
                        <div className="px-3 pt-3 d-flex flex-column mb-1">
                            <div style={{ flex: "1" }}>
                                <h3>
                                    <b>Detalle orden de venta</b>
                                </h3>
                            </div>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <div
                                className={`col-12 d-flex flex-column align-items-center ${
                                    !orden_compra && "justify-content-center"
                                }`}
                                style={{ minHeight: "140px" }}
                            >
                                {orden_compra ? (
                                    <div className="w-100">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Cliente</b>
                                                    {orden_compra.cliente_proveedor
                                                        ? orden_compra
                                                              .cliente_proveedor
                                                              .nombre
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Fecha</b>
                                                    {orden_compra.fecha}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Código venta</b>
                                                    {orden_compra.codigo
                                                        ? orden_compra.codigo
                                                        : "-----"}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Nota</b>
                                                    {orden_compra.descripcion ||
                                                        "---"}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Estado pago</b>
                                                    {!orden_compra.ov_pendiente ? (
                                                        <span className="text-success font-weight-bold">
                                                            Finalizado
                                                        </span>
                                                    ) : !orden_compra.ov_venciada &&
                                                      orden_compra.ov_pendiente ? (
                                                        <span className="text-warning font-weight-bold">
                                                            Pendiente
                                                        </span>
                                                    ) : (
                                                        <span className="text-danger font-weight-bold">
                                                            Vencido
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Sucursal de recepción</b>
                                                    {orden_compra.sucursal
                                                        ? orden_compra.sucursal
                                                              .nombre
                                                        : ""}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Fecha límite de pago</b>
                                                    <span className="primary font-weight-bold">
                                                        {
                                                            orden_compra.fecha_fin_pago
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">
                                                <div className="contenedor-campos-oc border-bottom">
                                                    <b>Vendedor</b>
                                                    {orden_compra.usuario
                                                        ? orden_compra.usuario
                                                              .first_name
                                                        : ""}
                                                </div>
                                            </div>
                                            {orden_compra.documento && (
                                                <div className="d-flex align-items-center">
                                                    <a
                                                        className="btn btn-primary h-50"
                                                        href={
                                                            orden_compra.documento
                                                        }
                                                        download
                                                    >
                                                        Descargar docmuento
                                                    </a>
                                                </div>
                                            )}
                                            {orden_compra.ov_pendiente ==
                                                false && (
                                                <div className="d-flex align-items-center pl-3">
                                                    <Link to="ver_documento_pago">
                                                        <button className="btn btn-primary h-50">
                                                            Ver documento pago
                                                        </button>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                        {/* <button className="mt-3 d-lg-none d-block btn btn-secondary" disabled={orden_compra.estado == 20} onClick={() => finalizarOC(orden_compra.id)}>FINALIZAR</button> */}
                                        <div className="mt-3 row margin-x-15-neg">
                                            <Tabs
                                                className="content-tabs"
                                                selectedIndex={this.state.tab}
                                                onSelect={(tabIndex) =>
                                                    this.cambioTab(tabIndex)
                                                }
                                            >
                                                <TabList className="tab_list d-flex flex-row align-items-center">
                                                    {/* <Tab
                                                        className="content-tab-venta py-2 px-3 text-center"
                                                        selectedClassName="content-tab-venta-active"
                                                    >
                                                        PAGOS
                                                    </Tab> */}
                                                    <Tab
                                                        className="content-tab-venta py-2 px-3 text-center"
                                                        selectedClassName="content-tab-venta-active"
                                                    >
                                                        ABONO
                                                    </Tab>

                                                    {/* <button
                                                        className="mx-3 d-none d-lg-block btn btn-secondary btn-finalizar"
                                                        disabled={
                                                            orden_compra.estado ==
                                                            20
                                                        }
                                                        onClick={() =>
                                                            finalizarOC(
                                                                orden_compra.id
                                                            )
                                                        }
                                                    >
                                                        FINALIZAR
                                                    </button> */}
                                                </TabList>

                                                {/* TAB DE LISTADO DE ABONOS A LA CUENTA POR PAGAR */}
                                                {/* <TabPanel>
                                                    <TabAbonosCuentaPagar
                                                        orden_compra={
                                                            orden_compra
                                                        }
                                                        marcarPagoFinalizadoOC={
                                                            marcarPagoFinalizadoOC
                                                        }
                                                        pagar={
                                                            this.abrirModalPago
                                                        }
                                                        revertir={
                                                            this.props
                                                                .reveritirMarcarPagoFinalizadoOC
                                                        }
                                                    />
                                                </TabPanel> */}
                                                <TabPanel>
                                                    <TabAbonosCuentaPagar
                                                        orden_compra={
                                                            orden_compra
                                                        }
                                                        marcarPagoFinalizadoOC={
                                                            marcarPagoFinalizadoOC
                                                        }
                                                        pagar={
                                                            this.abrirModalPago
                                                        }
                                                        revertir={
                                                            this.props
                                                                .reveritirMarcarPagoFinalizadoOC
                                                        }
                                                        vista_abonos={true}
                                                        registrarPagoAbono={
                                                            registrarPagoAbono
                                                        }
                                                        codigo_cobro={
                                                            codigo_cobro
                                                        }
                                                        setCodigoCobro={
                                                            setCodigoCobro
                                                        }
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-12">
                                        <h3 className="m-0 text-center">
                                            No se encontro la orden de compra
                                        </h3>
                                    </div>
                                )}
                            </div>
                        </LoadMask>

                        {/* MODAL DE ABONO A CUENTA POR PAGAR */}
                        <Modal
                            open={open_modal_pago}
                            onClose={this.closeModal}
                            classNames={{ modal: "modal-md" }}
                        >
                            <div className="p-2" style={{ maxWidth: "600px" }}>
                                <div className="row">
                                    <div className="col-12 mt-2 grid-titulo">
                                        Pago orden de compra
                                    </div>
                                    <PagoOrdenCompraForm
                                        saldo={
                                            orden_compra
                                                ? orden_compra.saldo
                                                : 0
                                        }
                                        pago_pendiente={pago_pendiente}
                                        movimiento_caja={movimiento_caja}
                                        data={retiros}
                                        loader={loader_retiros}
                                        fecha_inicial={fecha_inicial}
                                        fecha_final={fecha_final}
                                        page={page_retiros}
                                        changeFecha={changeFecha}
                                        sortChange={sortChangeRetiros}
                                        listarRetiros={listarMovimientos}
                                        onSubmit={this.submit}
                                        close={this.closeModal}
                                        esOrdenDeCompra={true}
                                        setMovimientoCaja={setMovimientoCaja}
                                        initialValues={{
                                            tipo_abono: "1000",
                                            forma_pago: "EFE",
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal>

                        <div className="col-md-12 mt-4">
                            <PrintContainer name="orden">
                                <Recibo
                                    {...this.props}
                                    movimiento={
                                        orden_compra ? orden_compra : {}
                                    }
                                    sucursal={sucursal}
                                    tipo={"Ingreso"}
                                    footerText={"Costo Total"}
                                />
                            </PrintContainer>
                        </div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.print("orden");
                                //printActions.print('gastoCompraprod')
                                // window.print();
                            }}
                            className="btn btn-secondary mt-3"
                            type={"button"}
                        >
                            <em className="fa fa-print" /> Imprimir
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default DetalleOrdenCompra;
