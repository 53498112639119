import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/movimientos";
import DetalleMovimientos from "./DetalleMovimientos";

const ms2p = (state) => {
    return {
        ...state.reporte_movimientos,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(DetalleMovimientos);
