import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { ToastStore } from "react-toasts";
import Swal from "sweetalert2";

import { api } from "api";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
export const REPORTE_DOC = "REPORTE_REPORTE_DOC";
export const REPORTE = "REPORTE_REPORTE";
export const PAGE = "REPORTE_PAGE";
export const BUSCADOR = "REPORTE_BUSCADOR";
export const SORT = "REPORTE_SORT";
export const LOADER = "REPORTE_LOADER";
export const FECHA_INICIAL = "REPORTE_FECHA_INICIAL";
export const FECHA_FINAL = "REPORTE_FECHA_FINAL";
export const USUARIO = "REPORTE_USUARIO";
export const ESTADO_DESCARGA = "REPORTE_ESTADO_DESCARGA";
export const BANCO = "REPORTE_BANCO";

export const constants = {};

const endpoint = "ingreso_documentos";
// ------------------------------------
// Actions
// ------------------------------------
export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const reporte_documentos = store.reporte_documentos;
        const { ordering, search, fecha_final, fecha_inicial, usuario, banco } =
            reporte_documentos;

        // Obtener el id del usuario, si este existe
        const usuario_id = usuario ? usuario.id : "";

        // Obtener el id del banco, si este existe
        const banco_id = banco ? banco.id : "";

        api.get(endpoint, {
            page,
            ordering,
            search,
            fecha_final,
            fecha_inicial,
            usuario: usuario_id,
            reporte: true,
            banco: banco_id,
        })
            .then((data) => {
                if (data) {
                    dispatch(setDocumentos(data));
                }
                dispatch(setPage(page));
            })
            .catch((err) => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_documentos.page;
    dispatch(listar(page));
};

export const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data)
        .then((resp) => {
            ToastStore.success("Datos almacenados correctamente");
            dispatch(push("/reporte_documentos"));
        })
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`, { reporte: true })
        .then((resp) => {
            dispatch(setDocumentoLeer(resp));
        })
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/${id}`, data)
        .then((resp) => {
            ToastStore.success("Datos almacenados correctamente");
            dispatch(push("/reporte_documentos"));
        })
        .catch((err) => {
            const msj = err.error
                ? err.error
                : "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then((data) => {
            ToastStore.success("Registro eliminado.");
            dispatch(listar());
        })
        .catch((err) => {
            const msj = err.error
                ? err.error
                : "Ha ocurrido un error, verifique los datos y vuelva a intentar.";
            Swal("ERROR", msj, "error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_documentos = store.reporte_documentos;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_documentos.fecha_final);
        // if (fecha_final >= fecha_inicial)
        dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_documentos.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const setUsuario = (usuario) => (dispatch) => {
    dispatch(_setUsuario(usuario));
    dispatch(listar());
};

export const setBanco = (banco) => (dispatch) => {
    dispatch(_setBanco(banco));
    dispatch(listar());
};

// *** Generar reporte excel
export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const reporte_documentos = store.reporte_documentos;
    const { ordering, search, fecha_final, fecha_inicial, usuario, banco } =
        reporte_documentos;

    // Obtener el id del usuario, si este existe
    const usuario_id = usuario ? usuario.id : "";

    // Obtener el id del banco, si este existe
    const banco_id = banco ? banco.id : "";

    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel`, {
        ordering,
        search,
        fecha_final,
        fecha_inicial,
        usuario: usuario_id,
        banco: banco_id,
        reporte: true,
    })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(esperarDescarga(data.id));
            }
        })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setEstadoDescarga(false));
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    dispatch(setEstadoDescarga(true));
    function listener() {
        api.get(`${endpoint}/estado_descarga`, { id })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };
                    dispatch(descargaArchivo(context));
                }
            })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = error.observaciones;
                }

                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .finally(() => {
                dispatch(setEstadoDescarga(false));
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// Limpiar filtros
export const limpiar = () => (dispatch) => {
    dispatch(setFechaInicial(moment().format("YYYY-MM-DD")));
    dispatch(setFechaFinal(moment().format("YYYY-MM-DD")));
    dispatch(setUsuario(""));
    dispatch(setBanco(""));
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setDocumentos = (data) => ({
    type: REPORTE_DOC,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setDocumentoLeer = (ingreso_documentos) => ({
    type: REPORTE,
    ingreso_documentos,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const _setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

export const _setBanco = (banco) => ({
    type: BANCO,
    banco,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    eliminar,
    crear,
    editar,
    leer,
    setFecha,
    setUsuario,
    descargarListado,
    setBanco,
    limpiar,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [REPORTE_DOC]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [REPORTE]: (state, { ingreso_documentos }) => {
        return {
            ...state,
            ingreso_documentos,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [BANCO]: (state, { banco }) => {
        return {
            ...state,
            banco,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    ingreso_documentos: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    usuario: "",
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
    banco: "",
};

export default handleActions(reducers, initialState);
