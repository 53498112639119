import React from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import useCrearEditar from "../../../hooks/useCrearEditar";
import TransportesForm from "./TransportesForm";

const CrearActualizar = ({
    crear,
    leer,
    transportes,
    editar,
    loader,
    match,
}) => {
    const { intitalData, titulo, verDato, editarDato, onSubmit } =
        useCrearEditar({ crear, leer, item: transportes, editar, match });

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="">
                    <TransportesForm
                        intitalData={intitalData}
                        onSubmit={onSubmit}
                        ver={verDato}
                        editar={editarDato}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CrearActualizar;
