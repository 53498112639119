import React, { Component } from "react";
import { icons } from "icons";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions } from "Utils/tableOptions";
import { TIPO_CLIENTE } from "../../../../utility/constantes";
import { ExpandColumn, ProductosSubTabla } from "./SubTablaComponents";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatoTrueFalse } from "../../Utils/CamposComunes";
import { initial_state_cliente } from "../../../../redux/modules/venta/venta";

export default class ListadoClientes extends Component {
    render() {
        //  State
        const { loader, search, page, data, tipo_venta } = this.props;
        //  Bind
        const {
            cerrar,
            buscar,
            listar,
            abrirModalCliente,
            seleccionarCliente,
            sucursal,
            setDatosCliente,
        } = this.props;

        //  Options
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = page;
        options.onPageChange = listar;
        const isExpandableRow = () => true;
        return (
            <div className="w-100">
                <div className="" style={{ borderRadius: "0", border: "0" }}>
                    <div className="grid-titulo">
                        <div className="px-3 pb-2">Buscar Cliente</div>
                    </div>
                    {/* BUSCADOR - px-3 */}
                    <div className="d-flex mb-1 py-2">
                        <div className="toolbar-search flex3 ml-1  mr-0">
                            <div
                                className="w-100"
                                style={{
                                    position: "relative",
                                }}
                            >
                                <input
                                    id="buscar"
                                    type="text"
                                    name="buscar"
                                    placeholder="Buscar"
                                    autoFocus={true}
                                    ref={(node) => {
                                        this.buscar = node;
                                        if (this.buscar) {
                                            this.buscar.value = search;
                                        }
                                    }}
                                    onChange={() => {
                                        if (this.buscar) {
                                            buscar(this.buscar.value);
                                        }
                                    }}
                                    autoComplete="off"
                                    className="form-control"
                                />
                                <span className={`fa fa-search icon-search`} />
                            </div>
                        </div>
                        <button
                            className="ml-1 btn btn-primary flex1"
                            onClick={(e) => {
                                e.preventDefault();
                                cerrar();
                                abrirModalCliente();
                            }}
                        >
                            Nuevo
                        </button>

                        {/* <a
                            className="ml-1 btn btn-primary flex1  "
                            target="_blank"
                            href="/#/configuracion/clientes/crear/"
                        >
                            <em className="fa fa-plus"></em> Nuevo
                        </a>*/}
                    </div>
                    {/* LISTADO */}
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote
                            pagination
                            hover
                            expandableRow={isExpandableRow}
                            expandComponent={ProductosSubTabla}
                            expandColumnOptions={{
                                expandColumnVisible: true,
                                expandColumnComponent: ExpandColumn,
                                columnWidth: 50,
                            }}
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            bodyContainerClass="r-encajar-listado-productos"
                        >
                            <TableHeaderColumn dataField="id" width="3rem">
                                ID
                            </TableHeaderColumn>

                            <TableHeaderColumn dataField="nombre">
                                Nombre
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="telefono">
                                Teléfono
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="correo">
                                Correo
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="tipo_cliente"
                                dataSort
                                width="10rem"
                                dataFormat={(data) => {
                                    if (data != null)
                                        return (
                                            <div className="">
                                                {
                                                    TIPO_CLIENTE.find(
                                                        (cli) => cli.id === data
                                                    ).label
                                                }
                                            </div>
                                        );
                                    else return <div className="">data</div>;
                                }}
                            >
                                Tipo Cliente
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="nit" width="80px">
                                nit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tiene_credito_sucursal"
                                dataFormat={formatoTrueFalse}
                            >
                                Tiene Crédito
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                dataAlign="center"
                                width="80px"
                                dataFormat={(cell, row) => {
                                    // recorrer objeto row.creditos
                                    // si el id de la sucursal es igual a la sucursal seleccionada
                                    // mostrar el boton de seleccionar
                                    // si no mostrar el boton de seleccionar deshabilitado
                                    let tieneCredito = false;

                                    if (tipo_venta != 30000) {
                                        tieneCredito = true;
                                    } else {
                                        Object.keys(row.creditos).map((key) => {
                                            const _sucursal =
                                                row.creditos[key].sucursal_id;

                                            const limite_credito =
                                                row.creditos[key]
                                                    .limite_credito;

                                            const dias_credito =
                                                row.creditos[key].dias_credito;

                                            const credito_utilizado =
                                                row.creditos[key]
                                                    .credito_utilizado;

                                            const dias_tomados =
                                                row.creditos[key].dias_tomados <
                                                0
                                                    ? row.creditos[key]
                                                          .dias_tomados * -1
                                                    : row.creditos[key]
                                                          .dias_tomados;

                                            if (
                                                _sucursal == sucursal &&
                                                limite_credito >
                                                    credito_utilizado &&
                                                dias_credito > dias_tomados
                                            ) {
                                                tieneCredito = true;
                                            }
                                        });
                                    }

                                    return tieneCredito ? (
                                        <div className="w-100 d-flex align-items-center justify-content-center">
                                            <img
                                                className="icon-img"
                                                style={{ width: "21px" }}
                                                onClick={(e) => {
                                                    setDatosCliente(
                                                        initial_state_cliente
                                                    );
                                                    e.persist();
                                                    seleccionarCliente(row);
                                                }}
                                                src={icons.check}
                                                title="Seleccionar"
                                                alt="Seleccionar"
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-100 d-flex align-items-center justify-content-center">
                                            <img
                                                className="icon-img"
                                                style={{
                                                    width: "21px",
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                }}
                                                src={icons.check}
                                                title="Seleccionar"
                                                alt="Seleccionar"
                                            />
                                        </div>
                                    );
                                }}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        );
    }
}
