import _ from "lodash";
import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import {
    renderField,
    renderSwitch,
    renderPercent,
    renderTextArea,
    renderDatePicker,
    renderSelectField,
    renderFieldReadOnly,
} from "Utils/renderField/renderField";

import { tipos_venta } from "utility/constantes";
import { ListaProductosOV } from "../camposOV";

//Validation
const validate = (values) => {
    const errors = {};

    if (
        !values.venta_costo &&
        values.descuentos &&
        !values.porcentaje_descuento
    ) {
        errors.porcentaje_descuento = "Campo requerido";
    }

    if (!values.tipo_venta) {
        errors.tipo_venta = "Campo requerido";
    }

    if (!values.descuentos) {
        values.descuentos = false;
        values.porcentaje_descuento = 0;
    }

    return errors;
};

const Form = (props) => {
    // State
    const { data_form, descuentos, venta_costo, porcentaje_descuento } = props;
    //  Bind
    const {
        anteriorPaso,
        nuevaSucursal,
        siguientePaso,
        buscarSucursal,
        listarSucursales,
        seleccionarSucursal,
        handleSubmit,
    } = props;

    //  Const
    const {
        loader_sucursal,
        search_sucursal,
        page_sucursal,
        data_sucursal,
        modal_listado_sucursal,
        modal_sucursal,
        seleccionados,
        total,
        total_costo,
    } = props;

    useEffect(() => {
        if (total) {
            props.dispatch(change("wizardCrearOVForm", "total", total));
        }
        if (total_costo) {
            props.dispatch(
                change("wizardCrearOVForm", "total_costo", total_costo)
            );
        }
        if (descuentos) {
            props.dispatch(
                //venta_costo
                change(
                    "wizardCrearOVForm",
                    "porcentaje_descuento",
                    porcentaje_descuento
                ),
                change("wizardCrearOVForm", "venta_costo", false)
            );
        }
        return () => {};
    }, [total, total_costo, descuentos]);

    return (
        <React.Fragment>
            <div className="row">
                {!props.realiza_descuentos && !props.realiza_venta_costo && (
                    <div className="col-12">
                        <ListaProductosOV
                            seleccionados={seleccionados}
                            total={total}
                            porcentaje_descuento={
                                porcentaje_descuento ? porcentaje_descuento : 5
                            }
                            descuento={descuentos ? descuentos : false}
                            total_costo={total_costo ? total_costo : 0}
                            venta_costo={venta_costo ? venta_costo : false}
                        />
                    </div>
                )}
                <div className="col-12">
                    <form onSubmit={handleSubmit}>
                        <div className="form-validate mb-lg">
                            <div className="grid-container">
                                {props.realiza_descuentos &&
                                    props.realiza_venta_costo && (
                                        <div className="grid-titulo padding-10">
                                            Punto de venta
                                        </div>
                                    )}
                                <div className="pl-lg-5 pr-lg-5 pt-lg-2 pb-lg-2 p-0 pt-1 pb-1">
                                    {/*<div className="row">
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label htmlFor="fecha_entrega">
                                                Nit
                                            </label>
                                            <div className="d-flex flex-column pr-0">
                                                {(data_form && (
                                                    <React.Fragment>
                                                        No.{" "}
                                                        {data_form.nit_cliente}
                                                    </React.Fragment>
                                                )) ||
                                                    "--------"}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label htmlFor="tipo_entrega">
                                                Cliente
                                            </label>
                                            <div
                                                className="d-flex flex-column pr-0"
                                                style={{ maxWidth: "650px" }}
                                            >
                                                {(data_form &&
                                                    data_form.cliente) ||
                                                    "--------"}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 form-group">
                                            <label htmlFor="tipo_entrega">
                                                Fecha
                                            </label>
                                            <div
                                                className="d-flex flex-column pr-0"
                                                style={{ maxWidth: "650px" }}
                                            >
                                                {(data_form &&
                                                    data_form.fecha && (
                                                        <span>
                                                            {moment(
                                                                data_form.fecha
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </span>
                                                    )) ||
                                                    "--------"}
                                            </div>
                                        </div>
                                    </div>*/}

                                    {props.realiza_descuentos && (
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                                <label htmlFor="tipo_entrega">
                                                    Descuentos
                                                </label>
                                                <div
                                                    className="d-flex flex-column pr-0"
                                                    style={{
                                                        maxWidth: "650px",
                                                    }}
                                                >
                                                    <div className="d-flex mt-1">
                                                        <label className="mr-2">
                                                            No
                                                        </label>
                                                        <Field
                                                            name="descuentos"
                                                            component={
                                                                renderSwitch
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                            disabled={
                                                                venta_costo
                                                                    ? venta_costo
                                                                    : false
                                                            }
                                                        />
                                                        <label className="ml-2">
                                                            Si
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                                <label htmlFor="tipo_entrega">
                                                    Cantidad de descuento
                                                </label>
                                                <div
                                                    className="d-flex flex-column pr-0"
                                                    style={{
                                                        maxWidth: "650px",
                                                    }}
                                                >
                                                    <Field
                                                        name="porcentaje_descuento"
                                                        component={
                                                            renderPercent
                                                        }
                                                        decimalScale={0}
                                                        maxValue={15}
                                                        placeholder="%"
                                                        suffix={"%"}
                                                        type="number"
                                                        className="form-control"
                                                        disabled={
                                                            venta_costo
                                                                ? venta_costo
                                                                : descuentos
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {props.realiza_venta_costo && (
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6 form-group">
                                                <label htmlFor="encargado">
                                                    Venta al costo
                                                </label>

                                                <div className="d-flex">
                                                    <label className="mr-2 mt-1">
                                                        No
                                                    </label>
                                                    <Field
                                                        name="venta_costo"
                                                        component={renderSwitch}
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                    <label className="ml-2">
                                                        Si
                                                    </label>
                                                </div>
                                                {/*<label htmlFor="fecha_entrega">
                                                Tipo de venta
                                            </label>
                                            <div className="d-flex flex-column pr-0">
                                                <Field
                                                    name="tipo_venta"
                                                    component={
                                                        renderSelectField
                                                    }
                                                    options={tipos_venta}
                                                    className="form-control"
                                                    item_vacio={true}
                                                />
                                            </div>*/}
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 form-group"></div>
                                        </div>
                                    )}
                                    {/* Buttons */}
                                    {(props.realiza_descuentos ||
                                        props.realiza_venta_costo) && (
                                        <div className="row d-flex align-items-center justify-content-center">
                                            <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                                <button
                                                    type="button"
                                                    className="btn btn-tertiary mr-1"
                                                    onClick={anteriorPaso}
                                                >
                                                    Regresar
                                                </button>
                                                <button
                                                    className="btn btn-primary ml-1"
                                                    type="submit"
                                                >
                                                    Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {!props.realiza_descuentos &&
                                        !props.realiza_venta_costo && (
                                            <div className="row d-flex align-items-center justify-content-center w-100">
                                                <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                                    <button
                                                        type="button"
                                                        className="btn btn-tertiary mr-1"
                                                        onClick={anteriorPaso}
                                                    >
                                                        Regresar
                                                    </button>
                                                    <button
                                                        className="btn btn-primary ml-1"
                                                        type="submit"
                                                    >
                                                        Siguiente
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {(props.realiza_descuentos || props.realiza_venta_costo) && (
                    <div className="col-12">
                        <ListaProductosOV
                            seleccionados={seleccionados}
                            total={total}
                            porcentaje_descuento={
                                porcentaje_descuento ? porcentaje_descuento : 5
                            }
                            descuento={descuentos ? descuentos : false}
                            total_costo={total_costo ? total_costo : 0}
                            venta_costo={venta_costo ? venta_costo : false}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

let PuntoVenta = reduxForm({
    form: "wizardCrearOVForm", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: false, // this is needed!!
    validate,
})(Form);

const selector = formValueSelector("wizardCrearOVForm");
PuntoVenta = connect((state) => {
    const data_form = state.form.wizardCrearOVForm.values;
    const tipo_venta = selector(state, "tipo_venta");
    const descuentos = selector(state, "descuentos");
    const porcentaje_descuento = selector(state, "porcentaje_descuento");
    const venta_costo = selector(state, "venta_costo");

    if (data_form.venta_costo == true) {
        data_form.descuentos = false;
        data_form.porcentaje_descuento = 0;
    }
    //if (data_form.descuentos == true) {
    //    data_form.venta_costo = false;
    //    data_form.porcentaje_descuento = 5;
    //}

    return {
        data_form,
        tipo_venta,
        descuentos,
        porcentaje_descuento: porcentaje_descuento ? porcentaje_descuento : 5,
        venta_costo,
        initialValues: {
            descuentos: false,
        },
    };
})(PuntoVenta);

export default PuntoVenta;
