import React, { useEffect, useState } from "react";

import { PrintContainer, PrintComponent } from "Utils/Print";
import ReciboPago from "Utils/Recibo/ReciboPago";

export const DetalleOrdenCompra = (props) => {
    const [sucursal, setSucursal] = useState("");
    const { documentos_pago, print_state, match, getDocumentosPagos, print } =
        props;
    const esVerDocumentoPago = match.path.includes("ver_documento_pago");

    useEffect(() => {
        if (match.path.includes("documento_pago_multiple")) {
            getDocumentosPagos(match.params.id, false);
        } else {
            getDocumentosPagos(match.params.id, !esVerDocumentoPago);
        }
    }, []);

    useEffect(() => {
        if (documentos_pago) {
            let sucursal_data = documentos_pago[0]
                ? documentos_pago[0].sucursal
                : {};
            setSucursal(sucursal_data);
        }
    }, [documentos_pago]);

    const documento_pago_multiple =
        documentos_pago && documentos_pago[0].documento_pago_multiple;

    const enReciborPorPagar = match.path.includes(
        "reporte_recibos_cuenta_por_pagar"
    );

    return (
        <div className="row">
            {documentos_pago && (
                <div className="col-md-12 mt-4">
                    <PrintContainer name="orden">
                        {sucursal && (
                            <ReciboPago
                                {...props}
                                movimiento={
                                    documentos_pago ? documentos_pago : {}
                                }
                                sucursal={sucursal}
                                tipo={"Ingreso"}
                                footerText={"Costo Total"}
                                documento_pago_multiple={
                                    documento_pago_multiple
                                }
                                enReciborPorPagar={enReciborPorPagar}
                            />
                        )}
                    </PrintContainer>
                </div>
            )}
            {print_state.flag && <PrintComponent />}

            <button
                onClick={(e) => {
                    e.preventDefault();
                    print("orden");
                }}
                className="btn btn-secondary mt-3"
                type={"button"}
            >
                <em className="fa fa-print" /> Imprimir
            </button>
        </div>
    );
};
export default DetalleOrdenCompra;
