import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";

import { tableOptions } from "Utils/tableOptions";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { standardActions } from "Utils/Grid/StandardActions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
//import { standardActions } from "Utils/Grid/StandardActions";
//import Search from "Utils/Search/Search";
//import Grid from "Utils/Grid";
//import TituloBorde from "Utils/TituloBorde/TituloBorde";

const ListaFamilias = ({
    listar,
    page,
    data = [],
    loader,
    onSortChange,
    eliminar,
    sortChange,
    buscar,
    search,
}) => {
    useEffect(() => {
        listar(page);
    }, []);

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = listar;
    options.onSortChange = sortChange;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-end">
                        <Link
                            className="btn btn-secondary mb-3 flex-md-0 flex-1"
                            to="/familias/crear/"
                        >
                            Crear familia
                        </Link>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo="Listado"
                                buscar={buscar}
                                search={search}
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                trStyle={(row, rowIdx) => {
                                    return {
                                        backgroundColor:
                                            rowIdx % 2 === 0
                                                ? "#FFFFFF"
                                                : "#F8F8F8",
                                    };
                                }}
                            >
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="porcentaje_mayorista"
                                    dataSort
                                    dataFormat={(data) => {
                                        return <div className="">{data} %</div>;
                                    }}
                                >
                                    Porcentaje mayorista
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="porcentaje_minorista"
                                    dataSort
                                    dataFormat={(data) => {
                                        return <div className="">{data} %</div>;
                                    }}
                                >
                                    Porcentaje Minorista
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="porcentaje_utilidad"
                                    dataSort
                                    dataFormat={(data) => {
                                        return <div className="">{data} %</div>;
                                    }}
                                >
                                    Porcentaje distribuidor
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="panel_pedidos"
                                    dataSort
                                    dataFormat={(data) => {
                                        if (data == true)
                                            return <div className="">Si</div>;
                                        if (data == false)
                                            return <div className="">No</div>;
                                    }}
                                >
                                    Panel de pedidos
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={standardActions({
                                        editar: "/familias",
                                        // ver: "grids",
                                        eliminar,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListaFamilias;
