import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "Utils/renderField/renderTableField";

const ProductosSubTabla = (row) => {
    return (
        <div className="border border-primary p-2 mt-2 mb-3 bg-blanco">
            <BootstrapTable data={row.detalles} remote={false}>
                <TableHeaderColumn dataField="codigo" dataSort={false} isKey>
                    Código
                </TableHeaderColumn>

                <TableHeaderColumn dataField="nombre_producto" dataSort={false}>
                    Producto
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="cantidad"
                    dataSort={false}
                    dataAlign="right"
                >
                    Cantidad
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="precio_unitario"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Costo Unitario
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="precio_total"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                    dataAlign="right"
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default ProductosSubTabla;
