import React, { useEffect } from "react"

import { tableOptions } from "Utils/tableOptions"
import { FiltrosReportes } from "../../../Utils/FiltrosReportes"
import LoadMask from "../../../Utils/LoadMask/LoadMask"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import { RenderCurrency, RenderDate } from "../../../Utils/renderField/renderReadField"
import { ExpandColumn, ProductosSubTabla } from "./ReporteReajustesTabla/SubTablaComponents"

const ListadoReajustes = ({
    // State variables
    loader,
    data,
    estado_descarga,
    usuario,
    fecha_inicial,
    fecha_final,
    sucursal,
    usuario_filtro,
    tipo_reajuste,
    page,
    totales,

    // bind
    setFecha,
    descargarListado,
    aplicarFiltros,
    setSucursal,
    setUsuario,
    setTipoReajuste,
    limpiarFiltros,
    listar
}) => {
    // OPCIONES
    const options = {
        ...tableOptions,
        noDataText: loader ? "Cargando ..." : tableOptions.noDataText,
        onPageChange: listar,
        page: page
    }

    const isExpandableRow = () => true

    useEffect(() => {
        limpiarFiltros()
        return () => {
            limpiarFiltros()
        }
    }, [])
    return (
        <div className="mb-5">
            <div className="row">
                {/* FILTROS */}
                <div className="col-12">
                    <FiltrosReportes
                        usuario={usuario}
                        fecha_inicial={fecha_inicial}
                        fecha_final={fecha_final}
                        sucursal={sucursal}
                        tipo_reporte={tipo_reajuste}
                        usuarioFiltro={usuario_filtro}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setSucursal={setSucursal}
                        setUsuario={setUsuario}
                        aplicarFiltros={aplicarFiltros}
                        setTipoReajuste={setTipoReajuste}
                        filtroReajustes
                    />
                </div>
            </div>

            <div className="col-12">
                <div className="grid-container px-4">
                    <div className="d-flex justify-content-between border-bottom mb-3">
                        <h1 className="title-text">Reporte</h1>
                        <button
                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                            disabled={estado_descarga}
                            onClick={() => {
                                descargarListado()
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-download"
                                }`}
                            />
                            &nbsp;Descargar Listado
                        </button>
                    </div>
                    <LoadMask loading={loader} light blur>
                        <BootstrapTable
                            data={loader ? [] : data.results }
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            expandableRow={isExpandableRow}
                            expandComponent={ProductosSubTabla}
                            expandColumnOptions={{
                                expandColumnVisible: true,
                                expandColumnComponent: ExpandColumn,
                                columnWidth: 50
                            }}
                        >
                            <TableHeaderColumn
                                dataField="codigo"
                                isKey
                            >
                                Correlativo
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_movimiento"
                                dataFormat={cell => <RenderDate value={cell} />}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="concepto"
                            >
                                Comentario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="usuario"
                            >
                                Usuario
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataFormat={(cell, row) => {
                                    let total_productos = 0
                                    row.detalles.forEach(detalle => {
                                        total_productos += detalle.cantidad_ajustada
                                    })
                                    return total_productos
                                }}
                            >
                                Productos
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataFormat={(cell, row) => {
                                    let total_costo = 0
                                    row.detalles.forEach(detalle => {
                                        total_costo += detalle.subtotal
                                    })
                                    return <RenderCurrency value={total_costo} />
                                }}
                            >
                                Total
                            </TableHeaderColumn>
                        </BootstrapTable>
                        
                        <div className="d-flex flex-column flex-md-row justify-content-center">
                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>Total de inventario reajustado: </strong>
                                </label>
                                <label>
                                    { totales.total_reajustado }
                                </label>
                            </div>

                            <div className="mr-0 mr-md-4">
                                <label>
                                    <strong>Total de costo reajustado: </strong>
                                </label>
                                <label>
                                    <RenderCurrency
                                        value={totales.total_costo_reajustado}
                                    />
                                </label>
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    )
}

export default ListadoReajustes