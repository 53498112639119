import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import TableProducts from "./Table/index";
import DetailProduct from "./Detail/index";
//import CreateUpdateProduct from "./Create/index";

import "./modalProduct.css";

const ModalProducts = ({ state = {}, closeModalProducts }) => {
    const [selected, setSelected] = useState({
        view: "LIST", //"LIST", //"CREATE"
        product: undefined,
    });

    return (
        <React.Fragment>
            <Modal
                classNames={{ modal: "detailProductModal" }}
                open={state.showModalProduct}
                onClose={() => {
                    closeModalProducts();
                }}
            >
                <div className="p-0 grid-clientes-ov">
                    {/* style={{ maxWidth: "500px" }} */}
                    <div className="grid-titulo padding-15"></div>

                    {selected && selected.view == "LIST" && (
                        <TableProducts
                            selected={selected}
                            setSelected={setSelected}
                            closeModalProducts={closeModalProducts}
                        />
                    )}

                    {selected && selected.view == "DETAIL" && (
                        <div>
                            <DetailProduct
                                selected={selected}
                                setSelected={setSelected}
                                closeModalProducts={closeModalProducts}
                            />
                        </div>
                    )}
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ModalProducts;
