import React, { useEffect, useState } from "react";
import Toolbar from "Utils/Toolbar/Toolbar";

import LoadMask from "Utils/LoadMask/LoadMask";
import { TablaOV } from "./ComponentesListadoOV";
import { tableOptions } from "Utils/tableOptions";

import Filtros from "../../Utils/Filtros";

const ListadoOrdenVenta = (props) => {
    const [modal, setModal] = useState({
        open: false,
        openSecond: false,
        data: {},
    });

    const [open, setOpen] = useState({
        open: false,
        printFunction: () => {},
        type: 0,
    });

    //  State
    const {
        page_ov,
        data_ov = [],
        search_ov,
        loader_ov,
        utiliza_fel,
        factura_personalizada,
        // filtros
        fecha_inicial,
        fecha_final,
        vendedor,
        cliente,
        tipo_venta,
    } = props;
    //  Bind
    const {
        anular,
        buscarOV,
        listarOV,

        sortChangeOV,
        retimbradoFEL,
        seleccionImpresionOFEL,

        // filtros
        setFechaFinal,
        setFechaInicial,
        setVendedor,
        setCliente,
        setTipoVenta,
    } = props;

    //  Options
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader_ov ? "Cargando..." : options.noDataText;
    options.page = page_ov;
    options.onPageChange = listarOV;
    options.onSortChange = sortChangeOV;

    useEffect(() => {
        props.listarOV();
    }, []);

    return (
        <React.Fragment>
            <div className="grid-titulo">
                <Toolbar
                    titulo={"Listado"}
                    buscar={buscarOV}
                    search={search_ov}
                />
            </div>
            <div>
                <Filtros
                    fecha_inicial={fecha_inicial}
                    fecha_final={fecha_final}
                    vendedor={vendedor}
                    cliente={cliente}
                    tipo_venta={tipo_venta}
                    setFechaInicial={setFechaInicial}
                    setFechaFinal={setFechaFinal}
                    setVendedor={setVendedor}
                    setCliente={setCliente}
                    setTipoVenta={setTipoVenta}
                    fitro_tipo_venta
                />
            </div>
            <LoadMask loading={loader_ov} dark blur>
                <TablaOV
                    loader={loader_ov}
                    datos_listado={data_ov}
                    options={options}
                    utiliza_fel={utiliza_fel}
                    factura_personalizada={factura_personalizada}
                    anular={anular}
                    retimbradoFEL={retimbradoFEL}
                    seleccionImpresionOFEL={seleccionImpresionOFEL}
                    limpiarOV={props.limpiarOV}
                    open={open}
                    setOpen={setOpen}
                    modal={modal}
                    setModal={setModal}
                    modalDescuento={props.modalDescuento}
                    setModalDescuento={props.setModalDescuento}
                />
            </LoadMask>
        </React.Fragment>
    );
};

export default ListadoOrdenVenta;
