import React, { useState, useEffect } from "react";
import _ from "lodash";
import { api } from "api";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
    required,
    composeValidators,
    email,
    minLength,
    maxLength,
    integer,
    username,
    isEmpty,
    dpi,
} from "../../../../../utility/validation";

import {
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderNumber,
    renderInteger,
    renderSwitch,
    renderPercent,
    //AsyncSelectField,
    renderAsyncSelectField,
    renderCurrency,
    SelectField,
} from "Utils/renderField/renderField";
import UpdateProduct from "./updateProduct";

const DetailProductForm = ({
    usuariosDatos,
    onSubmit,
    view = false,
    update = false,
    id,
    data,
    selected,
    setSelected,
    producto,
    verificarContrasena,
    cambiarPrecioCosto,
    closeModalProducts,
}) => {
    const [fracciones, setFracciones] = useState(null);
    const [familia, setFamilia] = useState(null);

    useEffect(() => {
        if (producto.fracciones) {
            if (producto.fracciones[0]) setFracciones(producto.fracciones[0]);
        }
        if (producto.subfamilia) {
            if (producto.subfamilia.familia)
                setFamilia(producto.subfamilia.familia);
        }
        //fracciones = producto.fracciones[0];
    }, [producto]);

    return (
        <React.Fragment>
            <div className="col-sm-12">
                <div>
                    <div className="d-flex flex-column flex-md-row mb-2">
                        {(producto &&
                            producto.imagen &&
                            producto.imagen != null && (
                                <div className="form-group has-feedback m-2 flex-3">
                                    <img
                                        src={producto.imagen}
                                        alt="200x200"
                                        className="img-thumbnail"
                                    />
                                </div>
                            )) || (
                            <div className="form-group has-feedback m-2 flex-3"></div>
                        )}
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className="mt-1 label-view" htmlFor="name">
                                Nombre
                            </label>
                            <div className="mb-1 input-view">
                                {producto.nombre}
                            </div>
                            <br />

                            <label className="mt-1 label-view" htmlFor="name">
                                Codigo
                            </label>
                            <div className="mb-1 input-view">
                                {producto.codigo}
                            </div>
                            <br />

                            <label className="mt-1 label-view" htmlFor="name">
                                Precio venta
                            </label>
                            <div className="mb-1 input-view">
                                Q. {/*producto.precio*/}
                                {(producto.precio_costo &&
                                    producto.precio_de_mostrador && (
                                        <React.Fragment>
                                            {producto.precio_de_mostrador}
                                        </React.Fragment>
                                    )) ||
                                    (producto.precio && (
                                        <React.Fragment>
                                            {producto.precio}
                                        </React.Fragment>
                                    ))}
                            </div>
                            <br />
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    </div>

                    <div className="d-flex flex-column flex-md-row mb2">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Equivalente 1
                            </label>
                            <div className="mb-2 input-view">
                                {producto.codigo_equivalente_1
                                    ? producto.codigo_equivalente_1
                                    : "--------"}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Equivalente 2
                            </label>
                            <div className="input-view">
                                {producto.codigo_equivalente_2
                                    ? producto.codigo_equivalente_2
                                    : "--------"}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Equivalente 3
                            </label>

                            <div className="input-view">
                                {producto.codigo_equivalente_3
                                    ? producto.codigo_equivalente_3
                                    : "--------"}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    </div>

                    {/* Costo */}
                    <div className="d-flex flex-column flex-md-row mb-3">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Familia padre
                            </label>

                            <div className="input-view">
                                {familia && (
                                    <React.Fragment>
                                        {familia.nombre}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Costo producto $
                            </label>
                            <div className="input-view">
                                ${" "}
                                {producto && producto.costo_dolares && (
                                    <React.Fragment>
                                        {producto.costo_dolares || "--------"}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Costo producto Q
                            </label>
                            <div className="input-view">
                                Q{" "}
                                {fracciones && fracciones.costo && (
                                    <React.Fragment>
                                        {fracciones.costo}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    </div>
                    <div className="d-flex flex-column flex-md-row mb-5">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Entrada
                            </label>

                            <div className="input-view">-------</div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Salida
                            </label>

                            <div className="input-view">-------</div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label htmlFor="name" className="label-view">
                                Costo anterior
                            </label>

                            <div className="input-view">
                                {console.log("costo anterior", fracciones.costo_anterior)}
                                {(fracciones && fracciones.costo && (
                                    <React.Fragment>
                                        Q {fracciones.costo}
                                    </React.Fragment>
                                )) ||
                                    "-------"}
                            </div>
                        </div>
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    </div>

                    {/* Fomr Update Price */}
                    <UpdateProduct
                        cambiarPrecioCosto={cambiarPrecioCosto}
                        verificarContrasena={verificarContrasena}
                        setSelected={setSelected}
                        producto={producto}
                        fracciones={fracciones}
                        closeModalProducts={closeModalProducts}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default DetailProductForm;
