import React from "react";
import { icons } from "icons";
import NumberFormat from "react-number-format";
import LoadMask from "Utils/LoadMask/LoadMask";
import { formatoNombre } from "Utils/CamposComunes";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
    RenderMoneda,
    RenderNumero,
    RenderDate,
    RenderDateTime,
} from "Utils/renderField/renderTableField";
import {
    RenderNumber,
    RenderCurrency,
} from "Utils/renderField/renderReadField";
import { FACTURADO, PROCESO, ANULADA, ERROR } from "variables";
import { validarConfDecimales } from "../Utils/validaConfiguracionDecimal";

export function formatoCantidad(props) {
    return (cell, producto, extra, index) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, cantidad - 1);
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.cantidad}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarCantidad(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, cantidad + 1);
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}

export function formatoPrecio(props) {
    return (cell, producto, extra, index) => {
        // return (
        //     <div>
        //         <select
        //             className="form-control"
        //             value={producto.modelo_precio}
        //             onChange={(event) => {
        //                 const value = event.target.value;
        //                 props.asignarPrecio(index, value);
        //             }}
        //             style={{ padding: "6px 2px", height: "35px" }}
        //         >
        //             {producto.listado_precios.map((precio) => {
        //                 return (
        //                     <option key={precio.id} value={precio.value}>
        //                         {props.pais == "GT" ? "Q " : "$ "}
        //                         {precio.value} - {precio.nombre}
        //                     </option>
        //                 );
        //             })}
        //         </select>
        //     </div>
        // );

        return (
            <div className="d-flex justify-content-center align-items-center">
                <NumberFormat
                    disabled={producto.es_precio_cliente}
                    className="form-control text-right"
                    placeholder="0"
                    decimalScale={
                        props.conf_empresa
                            ? validarConfDecimales(props.conf_empresa)
                            : 2
                    }
                    fixedDecimalScale={true}
                    value={producto.precio ? producto.precio : 0}
                    thousandSeparator={true}
                    prefix={props.pais == "GT" ? "Q " : "$ "}
                    style={{ padding: "6px 4px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarPrecio(index, val);
                    }}
                />
                <span
                    className={`text-info ml-2 ${
                        producto.es_precio_cliente ? "visible" : "invisible"
                    }`}
                    title="Precio fijado para
                    el cliente"
                >
                    <i className="fa fa-info-circle fnt-18"></i>
                </span>
            </div>
        );
    };
}

export function formatoSubtotal(cell, producto) {
    let subtotal = 0;
    if (producto.cantidad && producto.precio) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(producto.precio);
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}

export function formatoSubtotalVentaCosto(cell, producto) {
    let subtotal = 0;
    if (producto.cantidad && producto.costo) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(producto.costo);
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}

export function formatoReadPrecio(cell, producto) {
    return (
        <div>
            <div>
                <RenderCurrency value={cell} />
            </div>
        </div>
    );
}

export function formatoDescuento(cell, producto, porcentaje_descuento) {
    let subtotal = 0;
    if (producto.cantidad && producto.precio) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(producto.precio);
    }
    let percent = ((porcentaje_descuento * subtotal) / 100).toFixed(2);
    return (
        <div>
            <span className="font-weight-bold">
                {percent ? (
                    <RenderCurrency value={percent} />
                ) : (
                    "----------------"
                )}
            </span>
        </div>
    );
}

export function formatoDescuentoSubtotal(cell, producto, porcentaje_descuento) {
    let subtotal = 0;
    let percent = 0;
    let subtotal_descuento = 0;
    if (producto.cantidad && producto.precio) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(producto.precio);
    }
    percent = ((porcentaje_descuento * subtotal) / 100).toFixed(2);

    subtotal_descuento = (subtotal - percent).toFixed(2);

    return (
        <div>
            {subtotal && percent ? (
                <RenderCurrency value={subtotal_descuento} />
            ) : (
                "----------------"
            )}
        </div>
    );
}

export function totalOV(
    venta_costo,
    total,
    total_costo,
    descuento,
    porcentaje_descuento
) {
    if (descuento && !venta_costo) {
        let percent = 0;
        let total_descuento = 0;
        percent = ((porcentaje_descuento * total) / 100).toFixed(2);

        total_descuento = (total - percent).toFixed(2);

        return (
            <div>
                {percent && total_descuento ? (
                    <RenderCurrency value={total_descuento} />
                ) : (
                    "----------------"
                )}
            </div>
        );
    }
    if (!venta_costo & !descuento) return <RenderCurrency value={total} />;
    if (venta_costo) return <RenderCurrency value={total_costo} />;
}

export const ListaProductosOV = (props) => {
    //  State
    const {
        seleccionados,
        total,
        total_costo = 0,
        venta_costo = false,
        descuento,
        porcentaje_descuento = 5,
    } = props;
    //  Options
    const options = _.cloneDeep(tableOptions);
    options.noDataText = "Seleccione un producto para agregar al listado";

    return (
        <div className="grid-container contenedor-lectura-productos-ov">
            <div className="encabezado-lista-productos-ov">
                <h5 className="mb-0 font-weight-bold">
                    Productos de la Orden de venta
                </h5>
            </div>
            <div
                className="r-encajar backgr-naranja-degradado"
                style={{ padding: "0 2px" }}
            >
                <BootstrapTable
                    data={!seleccionados.length ? [] : seleccionados}
                    hover
                    options={options}
                    containerStyle={{ backgroundColor: "#FFF" }}
                    bodyStyle={{ minHeight: "200px" }}
                >
                    <TableHeaderColumn width="0" isKey dataField="id">
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producto"
                        dataFormat={(col) => {
                            if (col && col.codigo) {
                                return col.codigo;
                            }
                            return "---";
                        }}
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Codigo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="producto"
                        dataFormat={formatoNombre}
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Producto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cantidad"
                        dataAlign="right"
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Cantidad
                    </TableHeaderColumn>
                    {venta_costo && (
                        <TableHeaderColumn
                            dataField="costo"
                            dataAlign="right"
                            dataFormat={formatoReadPrecio}
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                        >
                            Precio costo
                        </TableHeaderColumn>
                    )}

                    {descuento && !venta_costo && (
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="right"
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataFormat={(cell, producto) => {
                                return formatoDescuento(
                                    cell,
                                    producto,
                                    porcentaje_descuento
                                );
                            }}
                        >
                            Descuento
                        </TableHeaderColumn>
                    )}

                    {!venta_costo && !descuento && (
                        <TableHeaderColumn
                            dataField="precio"
                            dataAlign="right"
                            dataFormat={formatoReadPrecio}
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                        >
                            Precio
                        </TableHeaderColumn>
                    )}

                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="right"
                        dataFormat={
                            !venta_costo
                                ? formatoSubtotal
                                : formatoSubtotalVentaCosto
                        }
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Subtotal
                    </TableHeaderColumn>
                    {descuento && !venta_costo && (
                        <TableHeaderColumn
                            dataField="precio"
                            dataAlign="right"
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataFormat={(cell, producto) => {
                                return formatoDescuentoSubtotal(
                                    cell,
                                    producto,
                                    porcentaje_descuento
                                );
                            }}
                        >
                            Subtotal Descuento
                        </TableHeaderColumn>
                    )}
                </BootstrapTable>
            </div>
            <div className="footer-lista-productos-oc">
                <h4 className="mb-0">Total</h4>
                <h4 className="mb-0">
                    {totalOV(
                        venta_costo,
                        total,
                        total_costo,
                        descuento,
                        porcentaje_descuento
                    )}
                </h4>
            </div>
        </div>
    );
};

export const accionesReciboFactura = (id, extra) => {
    const data = extra.data;
    const utiliza_fel = extra.utiliza_fel;
    const seleccionImpresionOFEL = extra.seleccionImpresionOFEL;
    const timbrado = data.datos_fel
        ? data.datos_fel.estado_factura == FACTURADO
            ? true
            : false
        : false;
    const datos_anulacion = data.datos_fel
        ? data.datos_fel.datos_anulacion && data.datos_fel.respuesta_anulacion
            ? true
            : false
        : false;

    const open = extra.open;
    const setOpen = extra.setOpen;

    //console.log("extra", extra.data.id_venta);
    // const impresion_recibo_caja = data.movimiento
    //     ? data.movimiento.tipo_pago == 200 || data.movimiento.tipo == 80
    //         ? true
    //         : false
    //     : false;
    const impresion_recibo_caja = false;
    return (
        <span>
            {utiliza_fel && (
                <img
                    onClick={(e) => {
                        e.preventDefault();
                        setOpen({
                            ...open,
                            open: true,
                            printFunction: () =>
                                seleccionImpresionOFEL(id, "facturaFelListaOV"),
                        });
                    }}
                    className="action-img"
                    title="Reimprimir factura"
                    src={icons.fel}
                    alt="Reimprimir factura"
                    style={{ maxWidth: "22px" }}
                />
            )}
            <img
                onClick={(e) => {
                    e.preventDefault();
                    setOpen({
                        ...open,
                        open: true,
                        printFunction: () =>
                            seleccionImpresionOFEL(
                                extra.data.id_venta,
                                extra.data.tipo_venta,
                                "impresionOVListaOV"
                            ),
                    });
                }}
                className="action-img"
                title="Reimprimir OV"
                src={icons.print}
                alt="Reimprimir OV"
                style={{ maxWidth: "19.5px" }}
            />
            {utiliza_fel && !timbrado && !datos_anulacion && (
                <img
                    onClick={(e) => {
                        e.preventDefault();
                        extra.retimbradoFEL(id);
                    }}
                    className="action-img"
                    title="Retimbrar factura"
                    src={icons.timbrar_factura}
                    alt="Retimbrar factura"
                    style={{ maxWidth: "22px" }}
                />
            )}
        </span>
    );
};

export const TabProductos = (props) => {
    // State
    const { orden_venta, loader } = props;
    // Bind

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <div className="w-100 col-12 pt-3 pb-2">
            {/* <button className="mb-4 btn btn-secondary" disabled={orden_venta.estado_inventario == 300 || orden_venta.estado == 20} onClick={() => marcarEntregaOC(orden_venta.id)}>Finalizar Entrega</button> */}
            <BootstrapTable
                data={loader ? [] : orden_venta.detalles}
                hover
                options={options}
            >
                <TableHeaderColumn width="0" dataField="id" isKey>
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="codigo">Código</TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                <b>{row.producto}</b>
                            </span>
                        );
                    }}
                >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        let a_granel = false;
                        try {
                            a_granel = row.fraccion.producto.a_granel;
                        } catch (e) {
                            a_granel = false;
                        }
                        return (
                            <RenderNumero
                                monto={cell}
                                decimalScale={a_granel ? 3 : 2}
                            />
                        );
                    }}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <RenderMoneda
                                monto={row.es_vale ? cell * -1 : cell}
                            />
                        );
                    }}
                >
                    Precio
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="cantidad_recibido"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return <RenderNumero monto={cell ? cell : 0} />;
                    }}
                >
                    Cantidad recibido
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="sub_total"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return <RenderMoneda monto={cell} />;
                    }}
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
            {/* <div className="border-bottom w-100 pl-3 pr-3 mt-2 d-flex justify-content-between flex-row">
                <h5>
                    <b>Total</b>
                </h5>
                <h5>
                    <b>
                        <RenderCurrency value={orden_venta.total} />
                    </b>
                </h5>
            </div> */}
        </div>
    );
};

export const TabPagos = (props) => {
    // State
    const { data, loader, page, saldo, activo } = props;
    // Bind
    const { list, openModalPago, anularPartida, getDatosPago } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = page;
    options.onPageChange = list;

    const accionesAdicionales = (id) => {
        return (
            <span>
                <img
                    className="action-img"
                    style={{ maxWidth: "22px" }}
                    src={icons.recibo}
                    alt="Reimprimir recibo de pago"
                    title="Reimprimir recibo de pago"
                    onClick={(e) => {
                        e.preventDefault();
                        getDatosPago(id);
                    }}
                />
            </span>
        );
    };

    return (
        <div className="w-100 col-12 pt-3 pb-2">
            <button
                className="btn btn-primary mb-3"
                disabled={saldo <= 0 || !activo}
                onClick={openModalPago}
            >
                Agregar pago
            </button>
            <LoadMask loading={loader} light blur radius>
                <BootstrapTable
                    data={loader ? [] : data.results}
                    remote
                    pagination
                    hover
                    fetchInfo={{
                        dataTotalSize: data.count,
                    }}
                    options={options}
                >
                    <TableHeaderColumn width="0%" dataField="id" isKey>
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha"
                        width="100px"
                        dataFormat={(cell, row) => (
                            <RenderDate
                                className={`${
                                    !row.activo ? "text-underline" : ""
                                }`}
                                fecha={cell}
                            />
                        )}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="movimiento__no_documento"
                        width="180px"
                        dataFormat={(cell, row) => (
                            <span
                                className={`${
                                    !row.activo ? "text-underline" : ""
                                }`}
                            >
                                {row.movimiento.no_documento}
                            </span>
                        )}
                    >
                        No. Documento
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="movimiento__descripcion"
                        dataFormat={(cell, row) => (
                            <div
                                className={`descripcion-cuenta ${
                                    !row.activo ? "text-underline" : ""
                                }`}
                            >
                                {row.movimiento.descripcion}
                            </div>
                        )}
                    >
                        Descripción
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="debe"
                        dataAlign="right"
                        width="110px"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                className={`${
                                    !row.activo ? "text-underline" : ""
                                }`}
                                monto={cell}
                            />
                        )}
                    >
                        Pago
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="haber"
                        dataAlign="right"
                        width="110px"
                        dataFormat={(cell, row) => (
                            <RenderMoneda monto={cell} />
                        )}
                    >
                        Haber
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                        dataField="saldo"
                        dataAlign="right"
                        width="110px"
                        dataFormat={(cell, row) => (
                            <RenderMoneda
                                className={`${
                                    !row.activo ? "text-underline" : ""
                                }`}
                                monto={cell * -1}
                            />
                        )}
                    >
                        Saldo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="110px"
                        dataFormat={(cell, row) => {
                            if (!row.activo) {
                                return <span></span>;
                            } else {
                                return activeFormatter({
                                    anular: anularPartida,
                                    adicional: accionesAdicionales,
                                })(cell, row);
                            }
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </BootstrapTable>
            </LoadMask>
        </div>
    );
};

export const TablaProductosDetalleOV = (props) => {
    // State
    const { orden_venta, loader } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    return (
        <div className="w-100 pt-3 pb-2">
            <BootstrapTable
                data={loader ? [] : orden_venta.detalles}
                hover
                options={options}
            >
                <TableHeaderColumn width="0%" dataField="id" isKey>
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombre"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                <b>{row.producto}</b>
                                <div>
                                    {row.fraccion.producto.a_granel
                                        ? row.unidad_de_medida.nombre
                                        : row.fraccion.nombre}
                                </div>
                            </span>
                        );
                    }}
                >
                    Producto
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        let a_granel = false;
                        try {
                            a_granel = row.fraccion.producto.a_granel;
                        } catch (e) {
                            a_granel = false;
                        }
                        return (
                            <RenderNumero
                                monto={cell}
                                decimalScale={a_granel ? 3 : 2}
                            />
                        );
                    }}
                >
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cantidad_recibido"
                    dataAlign="right"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        let a_granel = false;
                        try {
                            a_granel = row.fraccion.producto.a_granel;
                        } catch (e) {
                            a_granel = false;
                        }
                        return (
                            <RenderNumero
                                monto={cell ? cell : 0}
                                decimalScale={a_granel ? 3 : 2}
                            />
                        );
                    }}
                >
                    Cantidad entregado
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export const TabEntregas = (props) => {
    // State
    const { orden_venta, loader } = props;
    // Bind
    const { seleccionImpresionEntrega } = props;

    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;

    const accionesAdicionales = (id) => {
        return (
            <span>
                <img
                    onClick={(e) => {
                        e.preventDefault();
                        seleccionImpresionEntrega(id);
                    }}
                    className="action-img"
                    title="Reimprimir comprobante"
                    src={icons.recibo}
                    alt="Reimprimir comprobante"
                    style={{ maxWidth: "22px" }}
                />
            </span>
        );
    };

    return (
        <div className="w-100 col-12 pt-3 pb-2">
            {/* <button className="mb-4 btn btn-secondary" disabled={orden_venta.estado_inventario == 300 || orden_venta.estado == 20} onClick={() => marcarEntregaOC(orden_venta.id)}>Finalizar Entrega</button> */}
            <BootstrapTable
                data={loader ? [] : orden_venta.entregas}
                hover
                options={options}
            >
                <TableHeaderColumn width="0%" dataField="id" isKey>
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="creado"
                    width="140px"
                    dataFormat={(cell, row) => <RenderDateTime fecha={cell} />}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="recepcionado_por"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    width="200px"
                    dataFormat={(cell, row) => {
                        return (
                            <span>
                                {row.recepcionado_por
                                    ? row.recepcionado_por.first_name
                                    : row.usuario.first_name}
                            </span>
                        );
                    }}
                >
                    Entregado por
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="concepto"
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    dataFormat={(cell, row) => {
                        return <span>{cell ? cell : ""}</span>;
                    }}
                >
                    Nota
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="110px"
                    dataFormat={(cell, row) => {
                        return activeFormatter({
                            adicional: accionesAdicionales,
                        })(cell, row);
                    }}
                >
                    Acciones
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export function formatoCantidadDetail(props) {
    return (cell, producto, extra, index) => {
        return (
            <div className="d-flex flex-row">
                <button
                    className="btn btn-cantidad-detalle btn-menos"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, cantidad - 1);
                    }}
                    type="button"
                >
                    <img src={icons.menos} alt="-" />
                </button>
                <NumberFormat
                    className="form-control bodega-ingreso text-right"
                    placeholder="0"
                    decimalScale={producto.producto.a_granel ? 3 : 0}
                    fixedDecimalScale={
                        producto.producto.a_granel ? true : false
                    }
                    value={producto.cantidad}
                    thousandSeparator={true}
                    prefix={""}
                    style={{ padding: "2px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarCantidad(producto, val);
                    }}
                />
                <button
                    className="btn btn-cantidad-detalle btn-mas"
                    onClick={() => {
                        const cantidad = producto.cantidad
                            ? producto.cantidad
                            : 0;
                        props.asignarCantidad(producto, cantidad + 1);
                    }}
                    type="button"
                >
                    <img src={icons.mas} alt="+" />
                </button>
            </div>
        );
    };
}

export function formatoPrecioDetail(props) {
    return (cell, producto, extra, index) => {
        // return (
        //     <div>
        //         <select
        //             className="form-control"
        //             value={producto.modelo_precio}
        //             onChange={(event) => {
        //                 const value = event.target.value;
        //                 props.asignarPrecio(index, value);
        //             }}
        //             style={{ padding: "6px 2px", height: "35px" }}
        //         >
        //             {producto.listado_precios.map((precio) => {
        //                 return (
        //                     <option key={precio.id} value={precio.value}>
        //                         {props.pais == "GT" ? "Q " : "$ "}
        //                         {precio.value} - {precio.nombre}
        //                     </option>
        //                 );
        //             })}
        //         </select>
        //     </div>
        // );

        return (
            <div className="d-flex justify-content-center align-items-center">
                <NumberFormat
                    disabled={producto.es_precio_cliente}
                    className="form-control text-right"
                    placeholder="0"
                    decimalScale={
                        props.conf_empresa
                            ? validarConfDecimales(props.conf_empresa)
                            : 2
                    }
                    fixedDecimalScale={true}
                    value={producto.precio ? producto.precio : 0}
                    thousandSeparator={true}
                    prefix={props.pais == "GT" ? "Q " : "$ "}
                    style={{ padding: "6px 4px" }}
                    onValueChange={(values) => {
                        let val = "";
                        if (
                            values.value != "" &&
                            values.value != undefined &&
                            values.value != null
                        ) {
                            val = producto.producto.a_granel
                                ? parseFloat(values.value)
                                : parseInt(values.value);
                        }
                        props.asignarPrecio(index, val);
                    }}
                />
                <span
                    className={`text-info ml-2 ${
                        producto.es_precio_cliente ? "visible" : "invisible"
                    }`}
                    title="Precio fijado para
                    el cliente"
                >
                    <i className="fa fa-info-circle fnt-18"></i>
                </span>
            </div>
        );
    };
}
