import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/articulos_inventario";
import ListadoArticulosInventario from "./ListadoArticulosInventario";

const ms2p = (state) => {
    return {
        ...state.reporte_articulos_inventario,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoArticulosInventario);
