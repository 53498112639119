import React from "react";
import Card from "./Card";
import TableCreditos from "./TableCreditos";

const ResumenCuentaCobrar = () => {
    return (
        <div className="d-flex flex-column flex-lg-row grid-container p-0 m-0 mb-5">
            {/* <div className="flex-1">
                <TableCreditos />
            </div> */}
            <div className="flex-1 d-flex flex-column flex-lg-row justify-content-center align-items-center ml-3 py-3">
                <Card creditosVigentes />
                <Card creditosVencer mt />
                <Card creditosVencidos mt />
            </div>
        </div>
    );
};

export default ResumenCuentaCobrar;
