import { connect } from "react-redux";
import DetalleOrdenVenta from "./DetalleOrdenVenta";
import { actions } from "../../../../redux/modules/orden_venta";
import { print } from "../../../../redux/modules/print/print";

const ms2p = (state) => {
    return {
        ...state.orden_venta,
        print_state: state.print,
        utiliza_fel: state.usuario.utiliza_fel,
        factura_personalizada: state.usuario.factura_personalizada,
    };
};

const md2p = { ...actions, print };

export default connect(ms2p, md2p)(DetalleOrdenVenta);
