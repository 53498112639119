import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/salidas";
import ListadoSalidas from "./ListadoSalidas";

const ms2p = (state) => {
    return {
        ...state.reporte_salidas,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListadoSalidas);
