import { connect } from "react-redux";
import DetalleOrdenCompra from "./DetalleOrdenCompra";
import { actions } from "../../../../redux/modules/cuenta_cobrar/cuenta_cobrar";
import { print } from "../../../../redux/modules/print/print";

const ms2p = (state) => {
    return {
        ...state.cuenta_cobrar,
        print_state: state.print,
    };
};

const md2p = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
};

export default connect(ms2p, md2p)(DetalleOrdenCompra);
