import { api } from "api";
import moment from "moment";
import PropTypes from "prop-types";
import { Async } from "react-select";
import React, { Component, useEffect } from "react";
import DatePicker from "Utils/DatePicker";
import LoadMask from "Utils/LoadMask/LoadMask";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import { RenderCurrency, RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// import ResumenCuentaCobrar from "../Resumen";
import { FiltrosReportes } from "../../../Utils/FiltrosReportes";

const ListadoReciboCuentaPagar = (props) => {
    useEffect(() => {
        props.limpiarFiltros();
        return () => {
            props.limpiarFiltros();
        };
    }, []);

    // state
    const {
        loader,
        data,
        marcar_entrega,
        filtro,
        total,
        fecha_vencimiento_inicial,
        fecha_vencimiento_final,
        estado_descarga,

        tipo_proveedor,
        proveedor,
        tipo_movimiento,
        vendedor,
    } = props;
    // bind
    const {
        filtroChange,
        setFecha,
        descargarListado,
        aplicarFiltros,

        setTipoProveedor,
        setProveedor,
        setTipoMovimiento,
        setVendedor,
    } = props;

    const getCliente = (search) => {
        return api
            .get("clientes", { search })
            .catch(() => {})
            .then((data) => {
                return { options: data.results };
            });
    };

    //  OPCIONES
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader ? "Cargando..." : options.noDataText;
    options.page = props.page;
    options.onPageChange = props.listar;
    options.onSortChange = props.sortChange;

    return (
        <div className="mb-5">
            <div className="row">
                {/* RESUMEN */}
                {/* <div className="col-12">
                        <ResumenCuentaCobrar />
                    </div> */}
                {/* RESUMEN FIN */}

                {/* FILTRO */}
                <div className="col-12">
                    <FiltrosReportes
                        fecha_inicial={fecha_vencimiento_inicial}
                        fecha_final={fecha_vencimiento_final}
                        cliente={props.cliente}
                        tipo_proveedor={tipo_proveedor}
                        proveedor={proveedor}
                        tipo_movimiento={tipo_movimiento}
                        vendedor={vendedor}
                        setFechaInicial={setFecha}
                        setFechaFinal={setFecha}
                        setCliente={props.setCliente}
                        setTipoProveedor={setTipoProveedor}
                        setProveedor={setProveedor}
                        setTipoMovimiento={setTipoMovimiento}
                        setVendedor={setVendedor}
                        aplicarFiltros={aplicarFiltros}
                        filtroCP
                    />
                </div>

                <div className="col-12">
                    <div className="grid-container px-4">
                        <div className="d-flex justify-content-between border-bottom mb-3">
                            <h1 className="title-text">Reporte</h1>

                            <button
                                className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                disabled={estado_descarga}
                                onClick={() => {
                                    descargarListado();
                                }}
                            >
                                <em
                                    className={`fa ${
                                        estado_descarga
                                            ? "fa-spinner fa-pulse"
                                            : "fa-download"
                                    }`}
                                ></em>
                                &nbsp;Descargar Listado
                            </button>
                        </div>
                        <LoadMask loading={loader} light blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                remote
                                pagination
                                hover
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="fecha_fin_pago"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div>
                                                {row.tipo_pago ? (
                                                    <RenderDate value={cell} />
                                                ) : (
                                                    <span>-----</span>
                                                )}
                                            </div>
                                        );
                                    }}
                                >
                                    Fecha de vencimiento
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="proveedor__nombre"
                                    dataFormat={(cell, row) => (
                                        <div>{row.proveedor.nombre}</div>
                                    )}
                                >
                                    Proveedor
                                </TableHeaderColumn>

                                <TableHeaderColumn dataField="no_orden">
                                    Codigo de compra
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="pago_finalizado"
                                    dataFormat={(cell, row) => {
                                        if (
                                            row.oc_vencida &&
                                            !row.pago_finalizado
                                        ) {
                                            return (
                                                <span className="font-weight-bold text-danger">
                                                    Vencido
                                                </span>
                                            );
                                        }
                                        return (
                                            <div>
                                                {cell ? (
                                                    // <img
                                                    //     style={{ margin: "0", height: "1.5rem" }}
                                                    //     src={icons.check}
                                                    //     alt="Finalizado"
                                                    // />
                                                    <span className="font-weight-bold text-success">
                                                        Finalizado
                                                    </span>
                                                ) : (
                                                    // <img
                                                    //     style={{ margin: "0", height: "1.5rem" }}
                                                    //     src={icons.reloj}
                                                    //     alt="Pendiente"
                                                    // />
                                                    <span className="font-weight-bold txt-yellow">
                                                        Pendiente
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                >
                                    Estado pago
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="saldo"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Monto Pendiente
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="total"
                                    dataFormat={(cell) => (
                                        <RenderCurrency value={cell} />
                                    )}
                                >
                                    Monto Total
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField="id"
                                    width="10%"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    isKey
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return activeFormatter({
                                            ver: "/reporte_cuenta_por_pagar",
                                        })(cell, row);
                                    }}
                                >
                                    Acciones
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        </div>
    );
};

ListadoReciboCuentaPagar.propTypes = {
    listar: PropTypes.func.isRequired,
};

export default ListadoReciboCuentaPagar;
