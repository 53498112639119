import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMask/LoadMask";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { icons } from "icons";
import { formatoNombre } from "Utils/CamposComunes";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { formatoCantidad } from "../CamposDevoluciones";
import Encabezado from "./Encabezado";
class Eleccion extends Component {
    static propTypes = {
        factura: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            elegidos: props.elegidos,
            seleccionar_todos: props.seleccion_todos,
            itemsDevolucion: [
                {
                    src: icons.devolucion_p3,
                    alt: "Paso 1",
                },
                {
                    src: icons.devolucion_p4,
                    alt: "Paso 2",
                },
                {
                    src: icons.devolucion_p5,
                    alt: "Paso 3",
                },
            ],
        };
        this.toggleSeleccionado = this.toggleSeleccionado.bind(this);
        this.elegido = this.elegido.bind(this);
    }

    toggleSeleccionado(producto) {
        const temp = _.find(this.state.elegidos, { key: producto.key });
        if (temp) {
            const index = this.state.elegidos.indexOf(temp);
            const elegidos = this.state.elegidos;
            elegidos.splice(index, 1);
            this.setState({ elegidos });
            this.props.setElegidos(elegidos);
        } else {
            if (!producto.cantidad) {
                producto.cantidad = 1;
            } else {
                if (producto.cantidad > producto.disponible) {
                    producto.cantidad = producto.disponible;
                }
                if (producto.cantidad < 0) {
                    producto.cantidad = 0;
                }
            }
            if (producto.cantidad > 0) {
                producto.porcentaje_precio = 100;
                producto.precio_calculado = producto.precio;
                producto.codigo = producto.codigo;
                this.setState({ elegidos: [...this.state.elegidos, producto] });
                this.props.setElegidos([...this.state.elegidos, producto]);
            }
        }
    }

    asignaCantiad = (producto, valor) => {
        const elegidos = _.cloneDeep(this.state.elegidos);
        const item = _.find(elegidos, { key: producto.key });
        const index = elegidos.indexOf(item);
        const cantidad = valor != null && valor != "" ? parseInt(valor) : 0;
        if (valor == null || valor == "") {
            this.toggleSeleccionado(producto);
            return false;
        }
        if (cantidad < 0) {
            this.toggleSeleccionado(producto);
            return false;
        } else {
            if (cantidad > item.disponible) {
                item.cantidad = item.disponible;
            } else {
                item.cantidad = cantidad;
            }
        }
        elegidos.splice(index, 1, item);
        this.setState({ elegidos });
        this.props.asignaCantiad(producto, valor);
    };

    getCantidad = (key) => {
        const item = _.find(this.state.elegidos, { key });
        if (item) {
            return item.cantidad
                ? item.producto.a_granel
                    ? parseFloat(item.cantidad)
                    : parseInt(item.cantidad)
                : null;
        } else {
            return null;
        }
    };

    elegido(key) {
        return !!_.find(this.state.elegidos, { key });
    }

    selleccionarTodos = () => {
        const { seleccionar_todos } = this.state;
        const nuevo_estado = !seleccionar_todos;
        const { factura } = this.props;
        let elegidos = [];
        if (nuevo_estado) {
            const productos = factura ? _.cloneDeep(factura.productos) : [];
            productos.forEach((producto, i) => {
                producto.key = i;
                producto.porcentaje_precio = 100;
                producto.precio_calculado = producto.precio;
                elegidos.push(producto);
            });
        }
        this.setState({ seleccionar_todos: nuevo_estado, elegidos: elegidos });
        this.props.setElegidos(elegidos);
    };

    componentWillMount() {
        if (!this.props.factura.id) {
            this.props.getFactura(this.props.match.params.id);
        } else if (this.props.factura.id !== this.props.match.params.id) {
            this.props.getFactura(this.props.match.params.id);
        }
        this.props.getAdicionales();
    }

    render() {
        const { factura, loader, total } = this.props;
        const { seleccionar_todos } = this.state;
        const movimiento = factura.movimiento;
        const cuenta_cobrar = movimiento ? movimiento.cuenta_cobrarpagar : null;
        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        factura.productos.forEach((producto, i) => {
            producto.key = i;
        });

        let mostrar_alertas = false;
        if (cuenta_cobrar) {
            if (cuenta_cobrar.saldo < total) mostrar_alertas = true;
        }
        if (factura) {
            if (factura.movimiento.total_cobrar_pagar < total) {
                mostrar_alertas = true;
            }
        }

        const { params } = this.props.match;

        return (
            <React.Fragment>
                <div className="col-12">
                    <LoadMask loading={loader} blur light>
                        <div className="d-flex flex-column grid-container p-1 px-sm-4 py-sm-5">
                            {/* region ENCABEZADO */}
                            <Encabezado idFactura={params.id} />
                            {/* endregion ENCABEZADO */}

                            {/* <div className="flex-1"></div> */}

                            {/* region SELECCION DE PRODUCTOS A DEVOLVER */}
                            <small className="mt-2 mb-2 text-secondary">
                                Selecciona los productos que serán devueltos
                            </small>

                            <BootstrapTable
                                data={loader ? [] : factura.productos}
                                remote
                                hover
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField="codigo"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                >
                                    Código
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="nombre"
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={formatoNombre}
                                >
                                    Producto
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="disponible"
                                    dataFormat={(cell, row) => {
                                        if (row.producto.a_granel) {
                                            return (
                                                <RenderNumber
                                                    value={cell}
                                                    decimalScale={3}
                                                />
                                            );
                                        } else {
                                            return (
                                                <RenderNumber
                                                    value={cell}
                                                    // decimalScale={2}
                                                />
                                            );
                                        }
                                    }}
                                >
                                    Cantidad disponible
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="150px"
                                    dataAlign="right"
                                    dataField="cantidad"
                                    dataFormat={formatoCantidad({
                                        asignaCantiad: this.asignaCantiad,
                                        elegido: this.elegido,
                                        seleccionar: this.toggleSeleccionado,
                                        getCantidad: this.getCantidad,
                                    })}
                                >
                                    Cantidad
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataAlign="right"
                                    dataField="precio"
                                    dataFormat={(cell) => {
                                        return <RenderMoneda monto={cell} />;
                                    }}
                                >
                                    Precio
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="key"
                                    isKey
                                    width="140px"
                                    tdStyle={{
                                        ...BreakLine,
                                        position: "relative",
                                    }}
                                    dataAlign="center"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div className="checkbox c-checkbox">
                                                <label className="needsclick">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.elegido(
                                                            row.key
                                                        )}
                                                        onChange={() =>
                                                            this.toggleSeleccionado(
                                                                row
                                                            )
                                                        }
                                                    />
                                                    <span className="fa fa-check" />
                                                </label>
                                            </div>
                                        );
                                    }}
                                >
                                    <p className="mt-3 mb-0">Seleccionar</p>
                                    <div className="checkbox c-checkbox">
                                        <label className="needsclick">
                                            <input
                                                type="checkbox"
                                                checked={seleccionar_todos}
                                                onChange={(e) => {
                                                    this.selleccionarTodos();
                                                    // this.toggleSeleccionado(row)
                                                }}
                                            />
                                            <span
                                                className="fa fa-check white"
                                                title="Seleccionar todos"
                                            />
                                        </label>
                                    </div>
                                </TableHeaderColumn>
                            </BootstrapTable>
                            {/* endregion SELECCION DE PRODUCTOS A DEVOLVER */}
                            <br />
                            {/* ADICIONALES NOTA DE CREDITO */}
                            {/* {utiliza_fel && (
                                <div className="mt-2 mb-2">
                                    Adicionales para la nota de crédito
                                </div>
                            )}
                            {utiliza_fel && (
                                <BootstrapTable
                                    data={
                                        loader_adicionales
                                            ? []
                                            : data_adicionales.results
                                    }
                                    remote
                                    hover
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        isKey
                                        dataField="id"
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="tipo"
                                        dataFormat={(cell, row) => (
                                            <div>
                                                {cell == 10 ? (
                                                    <span>Monto fijo</span>
                                                ) : (
                                                    <span>
                                                        Porcentaje sobre el
                                                        total de la factura
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    >
                                        Tipo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="valor"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => (
                                            <div>
                                                {row.tipo == 10 ? (
                                                    <RenderMoneda
                                                        monto={cell}
                                                    />
                                                ) : (
                                                    <span>
                                                        <RenderPorcentaje
                                                            monto={cell}
                                                            decimalScale={2}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    >
                                        Valor
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="right"
                                        dataFormat={(cell, row) => (
                                            <div>
                                                <RenderMoneda
                                                    monto={precioAdicional(
                                                        row.id
                                                    )}
                                                />
                                            </div>
                                        )}
                                    >
                                        Precio
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="key"
                                        width="140px"
                                        tdStyle={{
                                            ...BreakLine,
                                            position: "relative",
                                        }}
                                        dataAlign="center"
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div className="checkbox c-checkbox">
                                                    <label className="needsclick">
                                                        <input
                                                            type="checkbox"
                                                            checked={elegidoAdicional(
                                                                row.id
                                                            )}
                                                            onChange={() =>
                                                                seleccionarAdicional(
                                                                    row
                                                                )
                                                            }
                                                        />
                                                        <span className="fa fa-check" />
                                                    </label>
                                                </div>
                                            );
                                        }}
                                    >
                                        Seleccionar
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            )} */}
                            {/* {utiliza_fel && <br />} */}
                            <div className="d-flex justify-content-between flex-row ">
                                {mostrar_alertas && (
                                    <div className="flex1 d-flex flex-column text-warning">
                                        {cuenta_cobrar &&
                                            cuenta_cobrar.saldo < total && (
                                                <h5>
                                                    <i className="fa fa-warning mr-3"></i>
                                                    El total es mayor al saldo
                                                    disponible del cliente (
                                                    <RenderMoneda
                                                        monto={
                                                            cuenta_cobrar.saldo
                                                        }
                                                    />
                                                    )
                                                </h5>
                                            )}
                                        {factura &&
                                            factura.movimiento
                                                .total_cobrar_pagar < total && (
                                                <h5>
                                                    <i className="fa fa-warning mr-3"></i>
                                                    El total es mayor al total
                                                    de la venta
                                                </h5>
                                            )}
                                    </div>
                                )}
                                <h3
                                    className={`${
                                        mostrar_alertas
                                            ? ""
                                            : "w-100 text-right"
                                    }`}
                                >
                                    Total:
                                    <RenderMoneda
                                        className="ml-2"
                                        monto={total}
                                    />
                                </h3>
                            </div>
                            <br />
                            <div className="d-flex justify-content-center flex-row">
                                <Link
                                    to={"/bodega/nota_credito_cliente/"}
                                    className="btn btn-tertiary m-1 align-self-center"
                                >
                                    <img src={icons.cancelar} alt="" />
                                    Cancelar
                                </Link>
                                <button
                                    className="btn btn-primary m-1 align-self-center"
                                    onClick={() => {
                                        this.props.elegir(
                                            this.state.elegidos,
                                            this.props.match.params.id,
                                            seleccionar_todos
                                        );
                                    }}
                                >
                                    <i className="fa fa-check" />
                                    &nbsp;Siguiente
                                </button>
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </React.Fragment>
        );
    }
}

export default Eleccion;
