import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { renderFieldCheck } from "../../../../../Utils/renderField/renderField";
import { validateFirefoxBrowser } from "../../../../../Utils/validations";
//
const FieldContenedor = ({ nombre_permiso, permisos }) => {
    const [isFirefoxBrowser, setIsFirefoxBrowser] = useState(false);

    useEffect(() => {
        const validate_browser = validateFirefoxBrowser();
        setIsFirefoxBrowser(validate_browser);
    }, []);

    return (
        <React.Fragment>
            <div
                className={`${
                    !isFirefoxBrowser
                        ? "container_permiso"
                        : "container_permiso-flex"
                } w-100`}
            >
                {Object.keys(permisos).map((permiso, index) => {
                    const { titulo, name, subItems } = permisos[permiso];

                    if (Object.keys(subItems).length > 0) {
                        return (
                            <div
                                className="d-flex flex-column pt-3"
                                key={index}
                            >
                                <div className="d-flex">
                                    <Field
                                        name={name}
                                        component={renderFieldCheck}
                                    />
                                    <label>{titulo}</label>
                                </div>
                                <div className="d-flex flex-column pl-4">
                                    {Object.keys(subItems).map(
                                        (subItem, index) => {
                                            const { titulo, name } =
                                                subItems[subItem];
                                            return (
                                                <div
                                                    className="d-flex flex-row w-100"
                                                    key={index}
                                                >
                                                    <div className="d-flex">
                                                        <Field
                                                            name={name}
                                                            component={
                                                                renderFieldCheck
                                                            }
                                                        />
                                                        <label>{titulo}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div className="d-flex py-3" key={index}>
                            <Field name={name} component={renderFieldCheck} />
                            <label>{titulo}</label>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default FieldContenedor;
