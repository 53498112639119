import _ from "lodash";
import { api } from "api";
import React, { useEffect } from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import DatePicker from "Utils/DatePicker";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderDate, RenderCurrency } from "Utils/renderField/renderReadField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderSearchSelect,
    renderField,
    renderSelectField,
    renderDatePicker,
} from "Utils/renderField/renderField";
import { formatoNombre } from "Utils/CamposComunes";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";
// VALIDACIONES CUSTOMS
const validate = (values) => {
    const errors = {};
    // if (!values.sucursal_destino) errors.sucursal_destino = "Campo requerido";
    if (!values.banco) errors.banco = "Campo requerido";
    if (!values.plazo_pago) errors.plazo_pago = "Campo requerido";
    if (!values.fecha_limite) errors.fecha_limite = "Campo requerido";

    return errors;
};

export function formatoCosto(cell, producto, costo_importacion) {
    // Dependiendo de si el costo de importacion es mayor a 0 (origen = importacion), el costo a calcular cambia
    const costo =
        costo_importacion > 0 ? producto.costo_dolares : producto.costo;

    const costo_final = parseFloat(costo).toFixed(2);

    return (
        <div>
            <div>
                {costo_importacion > 0 ? "$ " : "Q "} {costo_final}
            </div>
        </div>
    );
}

export function formatoSubtotal(
    _,
    producto,
    costo_importacion_value,
    tipo_cambio_value = 0
) {
    // Dependiendo de si el costo de importacion es mayor a 0 (origen = importacion), el subtotal a calcular cambia
    const costo =
        costo_importacion_value > 0
            ? producto.costo_dolares * tipo_cambio_value
            : producto.costo;
    const calculo_porcentaje = (costo_importacion_value || 0) / 100;

    let subtotal = 0;
    if (producto.cantidad && costo && calculo_porcentaje <= 0) {
        subtotal = parseFloat(producto.cantidad) * parseFloat(costo);
    } else if (producto.cantidad && costo && calculo_porcentaje > 0) {
        const subtotal_porcentaje =
            parseFloat(producto.cantidad) *
            parseFloat(costo) *
            calculo_porcentaje;
        subtotal =
            parseFloat(producto.cantidad) * parseFloat(costo) +
            subtotal_porcentaje;
    }
    return (
        <div>
            <div>
                <RenderCurrency value={subtotal} />
            </div>
        </div>
    );
}

const listaSucursales = [];
const listado_bancos = [];

const Form = (props) => {
    // State
    const {
        seleccionados,
        plazoPagoValue,
        fechaLimiteValue,
        loader,
        total,
        costo_importacion_value,
        tipo_cambio_value,
        tipo_compra_value,
        detalle_proveedor,
    } = props;
    // Bind
    const {
        handleSubmit,
        cambioPlazo,
        seleccionFechaLimite,
        asignarDatosTipoPago,
    } = props;

    const getSucursales = (search) => {
        return api
            .get("sucursales", { search, filtro_sucursal: true })
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listaSucursales, { id: item.id }))
                        listaSucursales.push(item);
                });
                return { options: listaSucursales };
            });
    };
    const options = _.cloneDeep(tableOptions);
    options.noDataText = loader
        ? "Cargando..."
        : "Seleccione un producto para agregar al listado";

    const goBack = () => {
        props.history.goBack();
    };

    let plazos = [
        {
            id: 1,
            value: "inmediato",
            label: "Inmediato",
        },
    ];

    const plazos_proveedor = [
        {
            id: 1,
            value: detalle_proveedor.dias_credito,
            label: `${detalle_proveedor.dias_credito} días`,
        },
    ];

    // Listar las cuentas bancarias
    const { getOptions: getBancos } = useAsyncSearch("bancos", listado_bancos);

    // Set el plazo de pago
    useEffect(() => {
        cambioPlazo(
            tipo_cambio_value === "1"
                ? plazos[0].value
                : plazos_proveedor[0].value
        );
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="slide-container row" style={{ margin: "0" }}>
                <div className="col-12 col-lg-6">
                    <LoadMask loading={loader} light blur radius>
                        <div className="grid-container">
                            <div className="d-flex justify-content-between pr-5">
                                <div className="grid-titulo">Tipo de pago</div>
                                <span
                                    className="position-absolute font-weight-bold primary"
                                    style={{ right: 15, top: 15 }}
                                >
                                    {tipo_compra_value === "1"
                                        ? "Al Crédito"
                                        : "Al Contado"}
                                </span>
                            </div>
                            <div className="pl-lg-5 pr-lg-5 pt-lg-2 pb-lg-2 p-0 pt-1 pb-1">
                                <div className="row">
                                    {tipo_compra_value === "2" && (
                                        <div className="col-12 form-group">
                                            <label htmlFor="banco">
                                                No. Cuenta
                                            </label>
                                            <Field
                                                name="banco"
                                                component={renderSearchSelect}
                                                loadOptions={getBancos}
                                                labelKey="banco_cuenta"
                                                valueKey="id"
                                                className="form-control"
                                                // disabled={ver}
                                            />
                                        </div>
                                    )}
                                    <div className="col-12 col-lg-6 form-group">
                                        <label htmlFor="plazo_pago">
                                            Plazo para pagar
                                        </label>
                                        <div
                                            className="d-flex flex-column pr-0"
                                            style={{ maxWidth: "650px" }}
                                        >
                                            <Field
                                                name="plazo_pago"
                                                component={renderSelectField}
                                                options={
                                                    tipo_compra_value === "1"
                                                        ? plazos_proveedor
                                                        : plazos
                                                }
                                                className="form-control"
                                                item_vacio={false}
                                                onChange={(e) => {
                                                    cambioPlazo(e.target.value);
                                                }}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {plazoPagoValue == "personalizado" ? (
                                        <div className="col-12 col-md-6 col-lg-6 form-group">
                                            <label htmlFor="fecha_limite">
                                                Fecha límite
                                            </label>
                                            <div className="d-flex flex-column pr-0">
                                                <DatePicker
                                                    value={
                                                        tipo_compra_value ===
                                                        "1"
                                                            ? fechaLimiteValue
                                                            : new Date().getTime()
                                                    }
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        seleccionFechaLimite(
                                                            date
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-md-6 col-lg-6 form-group">
                                            <label htmlFor="fecha_limite">
                                                Fecha límite
                                            </label>
                                            <div
                                                className="p-1"
                                                style={{
                                                    borderBottom:
                                                        "1px solid  #878787",
                                                }}
                                            >
                                                <RenderDate
                                                    value={
                                                        tipo_compra_value ===
                                                        "1"
                                                            ? fechaLimiteValue
                                                            : new Date().getTime()
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="align-items-center mt0 padding-10 d-flex justify-content-around flex-row flex-sm-row flex-md-row flex-lg-row flex-xl-row">
                                        <button
                                            type="button"
                                            className="btn btn-tertiary mr-1"
                                            onClick={() => {
                                                asignarDatosTipoPago();
                                                goBack();
                                            }}
                                        >
                                            <img src={icons.cancelar} alt="" />
                                            Cancelar
                                        </button>
                                        <button
                                            className="btn btn-primary ml-1"
                                            type="submit"
                                        >
                                            <i className="fa fa-check mr" />
                                            Finalizar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="grid-container contenedor-lectura-productos-oc">
                        <div className="encabezado-lista-productos-oc">
                            <h5
                                style={{
                                    color: "black",
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    flex: "5",
                                }}
                            >
                                Productos de la Orden de compra
                            </h5>
                        </div>
                        <div className="r-encajar" style={{ padding: "0 2px" }}>
                            <LoadMask loading={loader} light blur radius>
                                <BootstrapTable
                                    data={loader ? [] : seleccionados}
                                    hover
                                    options={options}
                                    containerStyle={{ backgroundColor: "#FFF" }}
                                    bodyStyle={{ minHeight: "200px" }}
                                >
                                    <TableHeaderColumn
                                        width="0%"
                                        isKey
                                        dataField="id"
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="producto"
                                        dataFormat={formatoNombre}
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Producto
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="cantidad"
                                        dataAlign="right"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Cantidad
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="costo"
                                        dataAlign="right"
                                        dataFormat={(cell, producto) =>
                                            formatoCosto(
                                                cell,
                                                producto,
                                                costo_importacion_value
                                            )
                                        }
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Costo
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="right"
                                        dataFormat={(_, row) =>
                                            formatoSubtotal(
                                                _,
                                                row,
                                                costo_importacion_value,
                                                tipo_cambio_value
                                            )
                                        }
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Subtotal
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                        <div className="footer-lista-productos-oc">
                            <h4 className="mb-0">Total</h4>
                            <h4 className="mb-0">
                                <RenderCurrency value={total} />
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

let OCTipoPago = reduxForm({
    form: "OCTipoPago",
    validate,
})(Form);

const selector = formValueSelector("OCTipoPago");
OCTipoPago = connect((state) => {
    const plazoPagoValue = selector(state, "plazo_pago");
    const fechaLimiteValue = selector(state, "fecha_limite");
    return {
        plazoPagoValue,
        fechaLimiteValue,
    };
})(OCTipoPago);

export default OCTipoPago;
