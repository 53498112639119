import { connect } from "react-redux"
import { actions } from "../../../../../redux/modules/reportes/reajustes"
import ListadoReajustes from "./ListadoReajustes"

const ms2p = state => {
    return {
        ...state.reporte_reajustes,
        usuario: state.usuario.me,
        sucursal_param: state.usuario.sucursal
    }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(ListadoReajustes)